import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GraphQLFundDataService,
  FundDataServiceParams,
} from './graphql-fund-data.service';
import { DocumentNode } from '@apollo/client/core';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { RiskReturnProfileDTO } from '@products/types/dto/risk-return-profile.dto.type';
import { RiskReturnProfileMapper } from '@products/utils/mappers/risk-return-profile.type.mapper';
import { RiskReturnProfile } from '@products/models/risk-return-profile';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('RiskReturnProfileDataService');

@Injectable({
  providedIn: 'root',
})
export class RiskReturnProfileDataService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private mapperFactory: MapperFactory,
    private translateService: TranslateService
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<RiskReturnProfile> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((data) => {
        const riskReturnProfileDto: RiskReturnProfileDTO[] = data?.data
          ?.RiskReturnProfile?.charts?.riskreturnprofile
          ? data.data.RiskReturnProfile.charts.riskreturnprofile
          : null;
        const mapperParams: MapperParams = {
          taxonomy: data.data.Portfolio.webprdcttaxonomy,
          config: this.siteConfigService,
          fundId: fundDataServiceParams.fundId,
          translateService: this.translateService,
          includeSoftClosed: true,
        };

        return riskReturnProfileDto
          ? this.mapperFactory
              .createMapper(RiskReturnProfileMapper, mapperParams)
              .toDomain(riskReturnProfileDto)
          : null;
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
    };
  }
}
