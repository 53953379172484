<div edsCol edsSubGrid="layout-{{ layoutType }}" edsSubGridGap="md">
  <div class="column-1" *ngIf="fundItems && fundItems.length > 0">
    <eds-search-panel
      panelId="funds"
      [leftTitle]="fundHeader.leftTitle"
      [rightTitle]="fundHeader.rightTitle"
      [viewMoreBtnText]="fundHeader.viewMoreBtnText"
      [viewMoreBtnStyle]="this.viewMoreBtnStyle"
      (viewMoreTrigger)="onViewMore($event)"
    >
      <ng-container *ngFor="let fund of fundItems">
        <eds-thumbnail-search
          class="full-results__search-thumbnail"
          [hasBorders]="false"
          [hasShadows]="false"
          [headingLevel]="4"
          [layoutCompact]="true"
          [fundFamily]="fund.fundFamily"
          [fundName]="fund.fundName"
          [shareClasses]="fund.shareClasses"
          [titleLink]="fund.fundLink"
          (shareclassClicked)="goToFund($event)"
          (onLinkClick)="onLinkClicked($event, fund, 'Investment Options')"
          [titleLight]="false"
          titleTheme="search"
          [additionalShareclassesLabel]="additionalShareClassLabel"
          [viewAllShareClassesLabel]="viewAllShareClassesLabel"
          [viewLessShareClassesLabel]="viewLessShareClassesLabel"
        >
        </eds-thumbnail-search>
      </ng-container>
    </eds-search-panel>
  </div>
  <div class="column-2" *ngIf="literatureItems && literatureItems.length > 0">
    <eds-search-panel
      panelId="literature"
      [leftTitle]="literatureHeader.leftTitle"
      [rightTitle]="literatureHeader.rightTitle"
      [viewMoreBtnText]="literatureHeader.viewMoreBtnText"
      [viewMoreBtnStyle]="this.viewMoreBtnStyle"
      (viewMoreTrigger)="onViewMore($event)"
    >
      <ng-container *ngFor="let item of literatureItems">
        <eds-thumbnail
          class="full-results__search-thumbnail"
          [hasBorders]="false"
          [hasShadows]="false"
          [headingLevel]="2"
          [docTypeSrc]="item.docTypeSrc"
          [ctaText]="item.ctaText"
          [layoutSearch]="true"
          [title]="item.title"
          [body]="item.body"
          [hasSearchIcon]="true"
          [noTitleColor]="true"
          titleTheme="search"
          [charCount]="true"
        >
          <div buttons>
            <div edsFlexbox edsPadding edsPaddingTop="xs">
              <div *ngIf="!item.downloadNotAvailble; else noDownloadBlock">
                <button
                  [hasIcon]="true"
                  edsButton="primary"
                  size="compact"
                  (click)="downloadDocument(item)"
                >
                  <span> {{ "literature.download" | translate }}</span>
                </button>
              </div>
              <div>
                <button
                  *ngIf="
                    checkCart(item) ||
                    (!checkCart(item) && !isSiteInternational)
                  "
                  [edsButton]="'secondary'"
                  size="compact"
                  [noMargin]="true"
                  (click)="updateCart(item)"
                >
                  <span *ngIf="checkCart(item)">{{
                    "literature.lit-sticky-delete-button" | translate
                  }}</span>
                  <!-- WDE-5309 Hiding the Add to Cart Button for International Sites -->
                  <span *ngIf="!checkCart(item) && !isSiteInternational">{{
                    "literature.Add-to-cart" | translate
                  }}</span>
                </button>
              </div>
            </div>

            <div
              *ngIf="item.hardcopyNotAvailable"
              edsPadding
              edsPaddingTop="xs"
            >
              <small>
                {{ "literature.cart-hardcopy-alert-body-msg" | translate }}
              </small>
            </div>
          </div>
        </eds-thumbnail>
      </ng-container>
    </eds-search-panel>
  </div>

  <div class="column-3" *ngIf="generalItems && generalItems.length > 0">
    <eds-search-panel
      panelId="pages"
      [leftTitle]="generalHeader.leftTitle"
      [rightTitle]="generalHeader.rightTitle"
      [viewMoreBtnText]="generalHeader.viewMoreBtnText"
      [viewMoreBtnStyle]="this.viewMoreBtnStyle"
      (viewMoreTrigger)="onViewMore($event)"
    >
      <ng-container *ngFor="let item of generalItems">
        <eds-thumbnail
          class="full-results__search-thumbnail"
          [hasBorders]="false"
          [hasShadows]="false"
          [headingLevel]="2"
          [layoutSearch]="true"
          [docTypeSrc]="item.docTypeSrc"
          [title]="item.title"
          [body]="item.body"
          [titleLink]="item.titleLink"
          [hasSearchIcon]="true"
          [noTitleColor]="true"
          titleTheme="search"
          [charCount]="true"
          (linkClick)="onLinkClicked($event, item, 'General')"
        >
        </eds-thumbnail>
      </ng-container>
    </eds-search-panel>
  </div>
</div>
<ng-template #noDownloadBlock>
  <div edsPadding edsPaddingRight="sm" edsPaddingTop="xs">
    <span>{{
      "literature.cart-download-unavailable-alert-body-msg" | translate
    }}</span>
  </div>
</ng-template>
