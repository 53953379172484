import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { Logger } from '@utils/logger';
import { CardContent, CardLinkClickEvent } from '@types';
import { CardsService } from '@services/cards.service';

const logger = Logger.getLogger('GermanCalculatorService');

interface ContentBlock {
  contentType: string;
  cardCompound?: Array<CardContent>;
  sectionId?: string;
  sectionType?: string;
  spacingBottom?: string;
}

@Component({
  selector: 'ft-media-card-block',
  templateUrl: './media-card-block.component.html',
})
export class MediaCardBlockComponent implements OnInit {
  /**
   * Bloomreach page object
   */
  @Input() page: Page;

  /**
   * Content block content
   */
  @Input() contentBlock: ContentBlock;

  public spacingBottom: string;
  public content: ContentBlock;
  public thumbnailCards: Array<CardContent>;

  constructor(private cardsService: CardsService) {
    /// Constructor placeholder
  }
  ngOnInit(): void {
    logger.debug('Section type', this.contentBlock.sectionType);
    this.spacingBottom = this.contentBlock?.spacingBottom;
    this.content = this.cardsService.formatDataForMediaCards(
      this.contentBlock,
      this.page
    );
    if (
      this.content?.cardCompound &&
      (this.contentBlock.sectionType === 'thumbnail_cards' ||
        this.contentBlock.sectionType === 'thumbnail_cards_compact')
    ) {
      this.thumbnailCards = this.cardsService.formatCardURL(
        this.cardsService.formatCards(this.content?.cardCompound),
        this.page
      );
    }
  }

  public setDownloadLocation(event: CardLinkClickEvent) {
    this.cardsService.setDownloadLocation(event);
  }
}
