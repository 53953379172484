import { Component, Input, OnInit } from '@angular/core';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { Page } from '@bloomreach/spa-sdk';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-layout-e-vertical-tabs-sidebar',
  templateUrl: './layout-e-vertical-tabs-sidebar.component.html',
  styleUrls: ['./layout-e-vertical-tabs-sidebar.component.scss'],
})
export class LayoutEVerticalTabsSidebarComponent implements OnInit {
  /**
   * Bloomreach page object
   */
  @Input() numberOfTabs: number;
  @Input() page!: Page;
  @Input() mapping;

  @Input() isWide: boolean;

  /**
   * Component configuration
   */
  public configuration: BrPageComponent['configuration'];

  public isLowerContentWide: boolean;
  public tabs: Array<{ label?: string; labelID?: string }> = [];
  public tabIndex: Array<number> = [];
  public tabArea = 'tab-area-';

  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
    this.tabs = Array(this.numberOfTabs).fill(this.numberOfTabs);
  }

  ngOnInit(): void {
    if (this.page?.getComponent(`${this.tabArea}1`)) {
      for (let i = 1; i <= this.numberOfTabs; i++) {
        const tabLabel = this.page
          .getComponent(`${this.tabArea}${i}`)
          ?.getComponent('tab-label')
          ?.getModels()?.TabLabel;
        if (tabLabel?.label || this.isEditMode) {
          this.tabs.push(tabLabel);
          this.tabIndex.push(i);
        }
      }
    }
    this.isLowerContentWide =
      'true' ===
      this.page?.getComponent('lower-content')?.getParameters()?.wideContainer;
  }
}
