import { ApolloQueryResult } from '@apollo/client/core';
import { ISODate } from '@frk/eds-components';
import { CalcTypeStd } from '@types';
import { PortfolioDTO } from './portfolio.dto.type';

export type ProductOverviewQueryResult = ApolloQueryResult<{
  Overview: ProductDTO;
}>;

export interface ProductDTO {
  fundid?: string | null;
  fundname?: string | null;
  cntrycode?: string | null;
  fundNameForSorting?: string | null; // TODO: this property doesn't exist in gql!
  fundcatg?: string | null;
  fundumbrlla?: string | null;
  vehicletype?: string | null;
  invvehcile?: string | null;
  invvehcilestd?: string | null;
  region?: string | null;
  invstmntgroup?: string | null;
  etftype?: string | null;
  invstmntcatg?: string | null;
  assetclass?: string | null;
  strategy?: string | null;
  methodology?: string | null;
  fundfamily?: string | null;
  invmangr?: string | null;
  sfdrcategory?: string | null;
  basecurrcode?: string | null;
  producttype?: string | null;
  webprdcttaxonomy?: string | null;
  shareclass?: (ShareClassDTO | null)[] | null;
  investmentteam?: (InvestmentTeamDTO | null)[] | null;
  portfolio?: PortfolioDTO | null;
  aum?: AumDto | null;
  amfcategory?: string | null;
}

export interface AumDto {
  avg12mnthaum?: string | null;
  totnetassets?: string | null;
  asofdate?: string | null;
}

export interface ShareClassDTO {
  identifiers?: IdentifiersDTO | null;
  shclname?: string | null;
  prmryshclind?: string | null;
  incepdt?: string | null;
  incepdtstd?: ISODate | null;
  perfincdt?: string | null;
  perfincdtstd?: ISODate | null;
  charges?: ChargesDTO | null;
  nav?: NAVDTO | null;
  performance?: PerformanceDTO | null;
  bmassoc?: (BMAssocDTO | null)[] | null;
  bmperformance?: BMPerformanceDTO | null;
  distribution?: (DistributionDTO | null)[] | null;
  shclfeestructr?: string | null;
  shclcurr?: string | null;
  yields?: YieldsDTO | null;
  ratings?: RatingsDTO;
  distributionindicator?: string | null;
  dividendfreq?: string | null;
  capgainfreq?: string | null;
  srrivalue?: string | null;
  riskstatssummary?: (RiskStatsSummaryDTO | null)[] | null;
  riskstatistics?: RiskStatisticsDTO | null;
  exchanges?: (ExchangeDTO | null)[] | null;
  saleschargebreakpoints?: (SalesChargeBreakPointDTO | null)[] | null;
  tax?: UsTaxDTO | null;
  premiumdiscountanalysis?: (PremiumDiscountElevatedDTO | null)[] | null;
  federaltaxid?: string | null;
  producttype?: string | null;
  etfclass?: string | null;
  riskclassificationtxt?: string | null;
}

export interface IdentifiersDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  taid?: string | null;
  ticker?: string | null;
  cusip?: string | null;
  isin?: string | null;
  reuterssym?: string | null;
  bloomberg?: string | null;
  sedol?: string | null;
  mexid?: string | null;
  apir?: string | null;
  arsn?: string | null;
  anbimacode?: string | null;
  vln?: string | null;
  intradaynav?: string | null;
  rfi?: string | null;
  adm?: string | null;
  dsc?: string | null;
  iress?: string | null;
  iressinavcode?: string | null;
  fundbmticker?: string | null;
  wkn?: string | null;
  nasdaqxnms?: string | null;
  iopv?: string | null;
  frontend?: string | null;
  lowload?: string | null;
  mlidentifier?: string | null;
}

export interface ChargesDTO {
  expratnet?: string | null;
  expratgross?: string | null;
  cdsc?: string | null;
  maxinitchrg?: string | null;
  annchrg?: string | null;
  waivertype?: string | null;
  waiverenddate?: string | null;
  prfrmncefee?: string | null;
  fundadminfee?: string | null;
  feeasofdate?: string | null;
  ongoingcharge?: string | null;
  asofdate?: string | null;
  mer?: string | null;
  managementfee?: string | null;
}

export interface NAVDTO {
  currcode?: string | null;
  navdate?: string | null;
  navvalue?: string | null;
  navchngvalue?: string | null;
  navchngpct?: string | null;
  mtmnav?: string | null;
  navmtmdiffpct?: string | null;
  intradaynav?: string | null;
  intradaynavdt?: string | null;
  ytdtotretatnav?: string | null;
  offerpricevalue?: string | null;
  redemptionprice?: string | null;
  mktpricedt?: string | null;
  mktpricevalue?: string | null;
  mktchngval?: string | null;
  mktchngpct?: string | null;
  ytdtotretatmktprice?: string | null;
  dailyvolume?: string | null;
  distribrateatnav?: string | null;
  distribrateatpop?: string | null;
  navhigh52week?: string | null;
  navhigh52weekdate?: string | null;
  navlow52week?: string | null;
  navlow52weekdate?: string | null;
  mktpricehigh52week?: string | null;
  mktpricehigh52weekdate?: string | null;
  mktpricelow52week?: string | null;
  mktpricelow52weekdate?: string | null;
  highnavdateytd?: string | null;
  lownavdateytd?: string | null;
  highnavvalueytd?: string | null;
  lownavvalueytd?: string | null;
  curryr1highnav?: string | null;
  curryr2highnav?: string | null;
  curryr3highnav?: string | null;
  curryr4highnav?: string | null;
  curryr5highnav?: string | null;
  curryr6highnav?: string | null;
  curryr7highnav?: string | null;
  curryr8highnav?: string | null;
  curryr9highnav?: string | null;
  curryr1lownav?: string | null;
  curryr2lownav?: string | null;
  curryr3lownav?: string | null;
  curryr4lownav?: string | null;
  curryr5lownav?: string | null;
  curryr6lownav?: string | null;
  curryr7lownav?: string | null;
  curryr8lownav?: string | null;
  curryr9lownav?: string | null;
  curryr1highdate?: string | null;
  curryr1lowdate?: string | null;
  curryr2highdate?: string | null;
  curryr2lowdate?: string | null;
  curryr3highdate?: string | null;
  curryr3lowdate?: string | null;
  curryr4highdate?: string | null;
  curryr4lowdate?: string | null;
  curryr5highdate?: string | null;
  curryr5lowdate?: string | null;
  curryr6highdate?: string | null;
  curryr6lowdate?: string | null;
  curryr7highdate?: string | null;
  curryr7lowdate?: string | null;
  curryr8highdate?: string | null;
  curryr8lowdate?: string | null;
  curryr9highdate?: string | null;
  curryr9lowdate?: string | null;
  moneyfundnav?: string | null;
  moneyfundasofdate?: string | null;
  moneyfundnavchngvalue?: string | null;
  moneyfundmktnav?: string | null;
  masterfundnav?: string | null;
  dailyliquidassetratio?: string | null;
  weeklyliquidassetratio?: string | null;
  medianbidaskspread?: string | null;
  netshareholdervalue?: string | null;
  bidaskspread?: string | null;
  premiumdiscountavg?: string | null;
  premiumdiscountpct?: string | null;
  applicationprice?: string | null;
}

export interface PerformanceDTO {
  month_end?: (PerformanceCurrentDTO | null)[] | null;
  quarter_end?: (PerformanceCurrentDTO | null)[] | null;
  snapshot?: (PerformanceSnapshotDTO | null)[] | null;
}

export interface PerformanceCurrentDTO {
  currcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  calcname?: string | null;
  calcnamestd?: CalcTypeStd | null;
  avgannret1yr?: string | null;
  avgannret2yr?: string | null;
  avgannret3yr?: string | null;
  avgannret4yr?: string | null;
  avgannret5yr?: string | null;
  avgannret7yr?: string | null;
  avgannret10yr?: string | null;
  avgannret15yr?: string | null;
  avgannret20yr?: string | null;
  avgannret25yr?: string | null;
  avgannret35yr?: string | null;
  avgannretytd?: string | null;
  avgannretactinc?: string | null;
  cummret1mth?: string | null;
  cummret3mth?: string | null;
  cummret6mth?: string | null;
  cummret1yr?: string | null;
  cummret2yr?: string | null;
  cummret3yr?: string | null;
  cummret5yr?: string | null;
  cummret10yr?: string | null;
  cummret15yr?: string | null;
  cummretytd?: string | null;
  cummretactinc?: string | null;
  calendaryrret1yr?: string | null;
  calendaryrret2yr?: string | null;
  calendaryrret3yr?: string | null;
  calendaryrret4yr?: string | null;
  calendaryrret5yr?: string | null;
  calendaryrret6yr?: string | null;
  calendaryrret7yr?: string | null;
  calendaryrret8yr?: string | null;
  calendaryrret9yr?: string | null;
  calendaryrret10yr?: string | null;
  discrannualrollyear1?: string | null;
  discrannualrollyear2?: string | null;
  discrannualrollyear3?: string | null;
  discrannualrollyear4?: string | null;
  discrannualrollyear5?: string | null;
  discrannualrollyear6?: string | null;
  discrannualrollyear7?: string | null;
  discrannualrollyear8?: string | null;
  discrannualrollyear9?: string | null;
  discrannualrollyear10?: string | null;
  perfstatus?: string | null;
  performance1yr?: string | null;
  performance2yr?: string | null;
  performance3yr?: string | null;
  performance4yr?: string | null;
  performance5yr?: string | null;
  performance6yr?: string | null;
  performance7yr?: string | null;
  performance8yr?: string | null;
  performance9yr?: string | null;
  performance10yr?: string | null;
}

export interface PerformanceSnapshotDTO {
  currcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  calcname?: string | null;
  calcnamestd?: CalcTypeStd | null;
  avgannret1yr?: string | null;
  avgannret2yr?: string | null;
  avgannret3yr?: string | null;
  avgannret4yr?: string | null;
  avgannret5yr?: string | null;
  avgannret7yr?: string | null;
  avgannret10yr?: string | null;
  avgannret15yr?: string | null;
  avgannret20yr?: string | null;
  avgannret25yr?: string | null;
  avgannret35yr?: string | null;
  avgannretytd?: string | null;
  avgannretactinc?: string | null;
  cummret1mth?: string | null;
  cummret3mth?: string | null;
  cummret6mth?: string | null;
  cummret1yr?: string | null;
  cummret2yr?: string | null;
  cummret3yr?: string | null;
  cummret5yr?: string | null;
  cummret10yr?: string | null;
  cummret15yr?: string | null;
  cummretytd?: string | null;
  cummretactinc?: string | null;
  calendaryrret1yr?: string | null;
  calendaryrret2yr?: string | null;
  calendaryrret3yr?: string | null;
  calendaryrret4yr?: string | null;
  calendaryrret5yr?: string | null;
  calendaryrret6yr?: string | null;
  calendaryrret7yr?: string | null;
  calendaryrret8yr?: string | null;
  calendaryrret9yr?: string | null;
  calendaryrret10yr?: string | null;
  discrannualrollyear1?: string | null;
  discrannualrollyear2?: string | null;
  discrannualrollyear3?: string | null;
  discrannualrollyear4?: string | null;
  discrannualrollyear5?: string | null;
  discrannualrollyear6?: string | null;
  discrannualrollyear7?: string | null;
  discrannualrollyear8?: string | null;
  discrannualrollyear9?: string | null;
  discrannualrollyear10?: string | null;
}
export interface YieldsDTO {
  stdsecyield30day?: string | null;
  stdsecyield30daywowaiver?: string | null;
  stdyieldasofdate30day?: string | null;
  curryield7day?: string | null;
  curryield7daywowaiver?: string | null;
  effctvyield7day?: string | null;
  effctvyield7daywowaiver?: string | null;
  sectaxequyieldall?: string | null;
  taxequivyieldwowaiver?: string | null;
  dividendyield?: string | null;
  asofdate?: string | null;
}

export interface RatingsDTO {
  mscatg?: string | null;
  msrtngoverall?: string | null;
  msrtng3yr?: string | null;
  msrtng5yr?: string | null;
  msrtng10yr?: string | null;
  mnthendnooffnds3yr?: string | null;
  mnthendnooffnds5yr?: string | null;
  mnthendnooffnds10yr?: string | null;
  asofdate?: string | null;
  ms3yrhybrid?: string | null;
  ms5yrhybrid?: string | null;
  ms10yrhybrid?: string | null;
  msoverallhybrid?: string | null;
  mseqstylbox?: string | null;
  msfistylbox?: string | null;
  msstylboxtyp?: string | null;
  msstyleboxhist?: string | null;
  msportfoliodate?: string | null;
  rtganalizyeffdt?: string | null;
  rtnganalizycategory?: string | null;
  rtganalizyqnt36mo?: string | null;
  rtganalizyqnt12mo?: string | null;
}

export interface InvestmentTeamDTO {
  fundid?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  mngrname?: string | null;
  mngrempid?: string | null;
  mngrorder?: string | null;
  strtdtfundmngmt?: string | null;
  strtdtfundmngmtstd?: string | null;
  mngrdesignation?: string | null;
  mngrcity?: string | null;
  yrssvc?: string | null;
  yrsindustry?: string | null;
  yrssvceffdte?: string | null;
  cfa?: string | null;
  mba?: string | null;
  phd?: string | null;
  cpa?: string | null;
  frm?: string | null;
}

export interface BMAssocDTO {
  bmname?: string | null;
}

export interface BMPerformanceDTO {
  month_end?: (BMPerformanceCurrentDTO | null)[] | null;
  quarter_end?: (BMPerformanceCurrentDTO | null)[] | null;
  snapshot?: (BMPerformanceSnapshotDTO | null)[] | null;
}

export interface BMPerformanceCurrentDTO {
  bmname?: string | null;
  bmlabel?: string | null;
  bmcurrcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  calcname?: string | null;
  calcnamestd?: string | null;
  bmavgannret1yr?: string | null;
  bmavgannret2yr?: string | null;
  bmavgannret3yr?: string | null;
  bmavgannret4yr?: string | null;
  bmavgannret5yr?: string | null;
  bmavgannret7yr?: string | null;
  bmavgannret10yr?: string | null;
  bmavgannret15yr?: string | null;
  bmavgannret20yr?: string | null;
  bmavgannret25yr?: string | null;
  bmavgannret35yr?: string | null;
  bmavgannretytd?: string | null;
  bmavgannretactinc?: string | null;
  bmcummret1mth?: string | null;
  bmcummret3mth?: string | null;
  bmcummret6mth?: string | null;
  bmcummret1yr?: string | null;
  bmcummret3yr?: string | null;
  bmcummret5yr?: string | null;
  bmcummret10yr?: string | null;
  bmcummret15yr?: string | null;
  bmcummretytd?: string | null;
  bmcummretactinc?: string | null;
  bmdiscrannualrollyr1?: string | null;
  bmdiscrannualrollyr2?: string | null;
  bmdiscrannualrollyr3?: string | null;
  bmdiscrannualrollyr4?: string | null;
  bmdiscrannualrollyr5?: string | null;
  bmdiscrannualrollyr6?: string | null;
  bmdiscrannualrollyr7?: string | null;
  bmdiscrannualrollyr8?: string | null;
  bmdiscrannualrollyr9?: string | null;
  bmdiscrannualrollyr10?: string | null;
  bmcalendaryrret1yr?: string | null;
  bmcalendaryrret2yr?: string | null;
  bmcalendaryrret3yr?: string | null;
  bmcalendaryrret4yr?: string | null;
  bmcalendaryrret5yr?: string | null;
  bmcalendaryrret6yr?: string | null;
  bmcalendaryrret7yr?: string | null;
  bmcalendaryrret8yr?: string | null;
  bmcalendaryrret9yr?: string | null;
  bmcalendaryrret10yr?: string | null;
  performance1yr?: string | null;
  performance2yr?: string | null;
  performance3yr?: string | null;
  performance4yr?: string | null;
  performance5yr?: string | null;
  performance6yr?: string | null;
  performance7yr?: string | null;
  performance8yr?: string | null;
  performance9yr?: string | null;
  performance10yr?: string | null;
}

export interface BMPerformanceSnapshotDTO {
  bmname?: string | null;
  bmlabel?: string | null;
  bmcurrcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  calcname?: string | null;
  calcnamestd?: string | null;
  bmavgannret1yr?: string | null;
  bmavgannret2yr?: string | null;
  bmavgannret3yr?: string | null;
  bmavgannret4yr?: string | null;
  bmavgannret5yr?: string | null;
  bmavgannret7yr?: string | null;
  bmavgannret10yr?: string | null;
  bmavgannret15yr?: string | null;
  bmavgannret20yr?: string | null;
  bmavgannret25yr?: string | null;
  bmavgannret35yr?: string | null;
  bmavgannretytd?: string | null;
  bmavgannretactinc?: string | null;
  bmcummret1mth?: string | null;
  bmcummret3mth?: string | null;
  bmcummret6mth?: string | null;
  bmcummret1yr?: string | null;
  bmcummret3yr?: string | null;
  bmcummret5yr?: string | null;
  bmcummret10yr?: string | null;
  bmcummret15yr?: string | null;
  bmcummretytd?: string | null;
  bmcummretactinc?: string | null;
  bmdiscrannualrollyr1?: string | null;
  bmdiscrannualrollyr2?: string | null;
  bmdiscrannualrollyr3?: string | null;
  bmdiscrannualrollyr4?: string | null;
  bmdiscrannualrollyr5?: string | null;
  bmcalendaryrret1yr?: string | null;
  bmcalendaryrret2yr?: string | null;
  bmcalendaryrret3yr?: string | null;
  bmcalendaryrret4yr?: string | null;
  bmcalendaryrret5yr?: string | null;
  bmcalendaryrret6yr?: string | null;
  bmcalendaryrret7yr?: string | null;
  bmcalendaryrret8yr?: string | null;
  bmcalendaryrret9yr?: string | null;
  bmcalendaryrret10yr?: string | null;
}

export interface DistributionDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  currcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  capgainpayabledate?: string | null;
  capgainrecorddate?: string | null;
  capgainexdivdate?: string | null;
  capgainreinvestmentdate?: string | null;
  capgainamtshrtterm?: string | null;
  capgainamtlngterm?: string | null;
  incmdistrecorddate?: string | null;
  incmdistpayabledate?: string | null;
  divpaidoutnetdistincm?: string | null;
  divpaidoutcapital?: string | null;
  incmdistexdivdate?: string | null;
  reinvstmntdate?: string | null;
  incmdistamt?: string | null;
  taxexemptincmamt?: string | null;
  taxequivdistrate?: string | null;
  reinvestmentamt?: string | null;
  totaldistamtmth?: string | null;
  capgainreinvstmntamt?: string | null;
  returnofcapitalamt?: string | null;
  totaldistamtdaily?: string | null;
  estimateflag?: string | null;
}

export interface RiskStatsSummaryDTO {
  asofdatestd?: ISODate | null;
  asofdate?: string | null;
  fundid?: string | null;
  shclcode?: string | null;
  currcode?: string | null;
  calcname?: string | null;
  bmnumber?: string | null;
  bmname?: string | null;
  bmlabel?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  disporder?: string | null;
  elemnamestd?: string | null;
  elemname?: string | null;
  elemval1yr?: string | null;
  elemval2yr?: string | null;
  elemval3yr?: string | null;
  elemval4yr?: string | null;
  elemval5yr?: string | null;
  elemval6yr?: string | null;
  elemval7yr?: string | null;
  elemval8yr?: string | null;
  elemval9yr?: string | null;
  elemval10yr?: string | null;
  elemval15yr?: string | null;
  elemval20yr?: string | null;
  elemvalactinc?: string | null;
}

export interface RiskStatisticsDTO {
  asofdate?: string;
  bullmkttotabvbm2yr?: string;
  bullmkttotblwbm2yr?: string;
  bullmkttotnom2yr?: string;
  bullmkthitratepct2yr?: string;
  bullmktavgexc2yr?: string;
  bearmkttotabvbm2yr?: string;
  bearmkttotblwbm2yr?: string;
  bearmkttotnom2yr?: string;
  bearmkthitratepct2yr?: string;
  bearmktavgexc2yr?: string;
  totabvbm2yr?: string;
  totblwbm2yr?: string;
  totoftotal2yr?: string;
  tothitratepct2yr?: string;
  totavgexcpct2yr?: string;
  avgexcabvbm2yr?: string;
  avgexcblwbm2yr?: string;
}

export interface ExchangeDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  ticker?: string | null;
  exchngname?: string | null;
  tradecurr?: string | null;
  bloomberg?: string | null;
  reuters?: string | null;
  sedol?: string | null;
}
export interface SalesChargeBreakPointDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: ISODate | null;
  brkpnttyprng?: string | null;
  popbrkpnt?: string | null;
  distribratebrkpnt?: string | null;
  datacategory?: string | null;
  brkpnttypkey?: number | null;
}

export interface UsTaxDTO {
  distribution?: UsTaxDistributionDTO[] | null;
  additional?: UsTaxAdditionalDTO | null;
  state?: UsTaxStateDTO[] | null;
}

export interface UsTaxDistributionDTO {
  fedtaxid: string | null;
  taxyear: string | null;
  recordtype: string | null;
  cusip: string | null;
  recordate: string | null;
  exemptinterestdividends: string | null;
  payabledate: string | null;
  ordinarydividends: string | null;
  qualifieddividends: string | null;
  capgaindisdistributions: string | null;
  unrecapsec1250gain: string | null;
  nontaxableroca: string | null;
  sec199adividends: string | null;
  foriegntaxpaid: string | null;
  interestreceivedeureclaim: string | null;
}

export interface UsTaxAdditionalDTO {
  taxyear: string | null;
  frgnsrcincm: string | null;
  frgnsrcqualifieddivinc: string | null;
  directusgovtobligations: string | null;
  specifiedpvtbondinterest: string | null;
  dividendsreceiveddeduction: string | null;
  section163: string | null;
  indianaresdtaxincm: string | null;
  utahresdtaxincm: string | null;
}

export interface UsTaxStateDTO {
  taxyear: string | null;
  statename: string | null;
  statevalue: string | null;
}

export interface PremiumDiscountElevatedDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  periodid?: string | null;
  enddate?: string | null;
  noofdays?: string | null;
  eventtype?: string | null;
}
