import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { AppStateService } from '@services/app-state.service';
import { Page } from '@bloomreach/spa-sdk/';
import { ButtonBlock, ButtonCollection, Link } from '@types';
import { LinkService } from '@services/link.service';
import { AnalyticsService } from '@frk/eds-components';
/**
 * Logger
 */
const logger = Logger.getLogger('ButtonsBlockComponent');

@Component({
  selector: 'ft-buttons-block',
  templateUrl: './buttons-block.component.html',
  styleUrls: ['./buttons-block.component.scss'],
})
export class ButtonsBlockComponent
  extends AbstractBaseComponent
  implements OnInit, OnChanges {
  /**
   * Content to render
   */
  @Input() content: Array<ButtonCollection>;

  buttonsCollection: any;

  @Input() page: Page;

  /**
   * Constructor
   */
  constructor(
    private appStateService: AppStateService,
    private ctaLinkService: LinkService,
    private analyticsService: AnalyticsService
  ) {
    super();
  }

  /**
   * On Init
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngOnChanges(): void {
    this.buttonsCollection = this.content;
    this.buttonsCollection = this.getContent();
  }

  private trackEvent(displayTxt: string, linkUrl: string): void {
    this.analyticsService.trackEvent({
      event: 'linkOrButtonClick',
      detailed_event: 'Link or Button Click',
      event_data: {
        category: '',
        link_id: '',
        link_text: displayTxt,
        link_type: this.analyticsService.getLinkType(linkUrl),
        link_url: linkUrl,
      },
      link_text: displayTxt,
      link_url: linkUrl,
    });
  }

  public checkSignIn(
    event: Event,
    linkCompound: Link,
    downloadLocation?: string
  ): void {
    this.trackEvent(linkCompound?.displayText, linkCompound?.url);
    this.ctaLinkService.setUpdatedLinkData(downloadLocation);
    if (this.ctaLinkService.isLinkSignIn(linkCompound)) {
      this.ctaLinkService.openSignInLink(event, linkCompound);
    }
  }

  private getContent(): Array<ButtonBlock> {
    const contents = this.buttonsCollection.buttons;
    const newContent: Array<ButtonBlock> = [];
    if (contents) {
      for (const content of contents) {
        if (content.linkCompound?.linkCollection?.length) {
          const linkCompound = content.linkCompound.linkCollection[0];
          const widenDocumentParams = this.ctaLinkService.getWidenDocumentParams(
            linkCompound.widenAssetCompound?.widenDocument
          );
          newContent.push({
            ...content,
            linkCompound,
            ctaText: linkCompound?.displayText,
            external: linkCompound?.external,
            externalSuffix: linkCompound?.externalSuffix,
            externalLinkClass: this.getExternalClass(linkCompound),
            target: linkCompound?.target,
            signInRequired: linkCompound?.signInLink,
            hideLockIcon: linkCompound?.hideLockIcon,
            href: this.ctaLinkService.getHref(
              this.page,
              linkCompound,
              this.appStateService?.getHomePageUrl()
            ),
            downloadLocation: this.ctaLinkService.getDownloadLocationHref(
              this.page,
              linkCompound,
              this.appStateService?.getHomePageUrl()
            ),
            dataDocId: widenDocumentParams?.id.shift(),
            noindex: widenDocumentParams?.noindex,
            dataTitle: widenDocumentParams?.document_title,
            externalId: widenDocumentParams?.external_id,
          });
        }
      }
    }
    logger.debug(newContent);
    return newContent;
  }

  private getExternalClass(linkCompound: ButtonBlock): string {
    let externalLink = '';
    if (linkCompound?.external) {
      externalLink = 'external-link';
      if (linkCompound?.externalSuffix) {
        externalLink = externalLink + '--' + linkCompound?.externalSuffix;
      }
      return externalLink;
    }
    return externalLink;
  }
}
