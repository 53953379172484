import {
  Component,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { FundId, PortfolioComponentId } from '@types';
import {
  ExpandCollapseComponent,
  HighchartsThemeService,
  ResponsiveService,
  ScrollService,
} from '@frk/eds-components';
import { EMDASH } from '@products/utils/constants/product.constants';
import { TranslateService } from '@shared/translate/translate.service';
import { Logger } from '@utils/logger';
import cloneDeep from 'lodash/cloneDeep';
import { Observable, Subscription } from 'rxjs';
import { generateUUID } from '@utils/text/string-utils';
import { ViewportScroller } from '@angular/common';

const logger = Logger.getLogger('BarChartComponent');

/**
 * Interface defines a chart data point and optional nested child points
 */
export interface BarChartDataPoint {
  /**
   * e.g. 'Fixed Income'
   */
  label: string;

  /**
   * Formatted Breakdown percent string e.g. '89.24%'
   */
  breakdown?: string;

  /**
   * Full Breakdown percent number value for Highcharts to plot with e.g. 89.24
   */
  breakdownStd?: number;

  /**
   * Formatted Benchmark percent string e.g. '89.24%'
   */
  benchmark?: string;

  /**
   * Full Benchmark percent number value for Highcharts to plot with e.g. 89.24
   */
  benchmarkStd?: number;

  /**
   * Formatted Gross Exposure percent string e.g. '89.24%'
   */
  grossExposure?: string;

  /**
   * Full Gross Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  grossExposureStd?: number;

  /**
   * Formatted Long Exposure percent string e.g. '89.24%'
   */
  longExposure?: string;

  /**
   * Full Long Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  longExposureStd?: number;

  /**
   * Formatted Net Exposure percent string e.g. '89.24%'
   */
  netExposure?: string;

  /**
   * Full Net Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  netExposureStd?: number;

  /**
   * Formatted Short Exposure percent string e.g. '89.24%'
   */
  shortExposure?: string;

  /**
   * Full Short Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  shortExposureStd?: number;

  /**
   * Formatted Target Allocation percent range string e.g. '30% - 70%'
   */
  targetAllocation?: string;

  /**
   * Full Target Allocation percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  targetAllocationStd?: number;

  /**
   * Formatted Actual Allocation percent string e.g. '89.24%'
   */
  actualAllocation?: string;

  /**
   * Full Actual Allocation percent number value for Highcharts to plot with e.g. 89.24
   */
  actualAllocationStd?: number;

  /**
   * Formatted Risk Contribution Allocation percent string e.g. '89.24%'
   */
  riskContributionAllocation?: string;

  /**
   * Formatted Performance Contribution Allocation percent string e.g. '89.24%'
   */
  performanceContributionAllocation?: string;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: BarChartDataPoint[];

  /**
   * contains the unique id for the element
   */
  id?: string;
}

/**
 * Interface defines a table data point and optional nested child points
 */
export interface BarTableDataPoint extends BarChartDataPoint {
  /**
   * color for the legend box. Root nodes only
   */
  pointColor?: string;

  /**
   * contains the id for all the elements control by the specific ariacontrol
   */
  ariaControls?: string;

  /**
   * indicates if this node has nested child nodes
   */
  hasChildren?: boolean;

  /**
   * indicates if this node is expaned i.e. child nodes are visible
   * Note: only applies to nodes with children
   */
  isExpanded?: boolean;

  /**
   * indicates if this table node is visible i.e. parent node is expanded
   * note: doesn't apply to root nodes
   */
  isHidden?: boolean;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: BarTableDataPoint[];
}

export interface BarChartData {
  /**
   * fund id
   */
  fundId: FundId;

  // SectionHeader config ----------------------------------------

  /**
   * Title
   */
  chartTitle: string;

  /**
   * Footnote placement id
   */
  caveatPlacement?: string;

  /**
   * FIMES Footnote placement id
   */
  secondaryCaveatPlacement?: string;

  /**
   * Text or html content for a tooltip
   */
  tooltip?: string;

  /**
   * e.g. `'As of'`
   */
  asOfLabel?: string;

  /**
   * Preformatted date string e.g. `'12 March 2020'`
   */
  asOfDate?: string;

  /**
   * e.g. `'(Market Value)'`
   */
  calculationBasis?: string;

  /**
   * e.g. `'(% of Total)'`
   */
  calculationType?: string;

  /**
   * e.g. `'(updated monthly)'`
   */
  updateFrequency?: string;

  // TODO: is this still used?
  /**
   * Formatted Performance Contribution date range string e.g. '830/11/2020 - 31/12/2020'
   */
  contributionDateRange?: string;

  /**
   * determines if Y axis shows % instead of just value
   */
  isPercent?: boolean;

  // Show/hide config ----------------------------------------

  /**
   * Use to hide chart
   */
  hideChart?: boolean;

  /**
   * Use to hide table
   */
  hideTable?: boolean;

  /**
   * Use to show Exposure type chart (i.e. 2 data series) instead of default options
   */
  showExposureChart?: boolean;

  /**
   * Use to show the percentage bar component
   */
  showTotalPercentageBar?: boolean;

  /**
   * Use to hide the default legend colored squares in the table
   */
  hideTableLegend?: boolean;

  /**
   * Use to hide the default % breakdown column in table
   */
  hideBreakdownCol?: boolean;

  /**
   * Use to show the benchmark column in table
   */
  showBenchmarkCol?: boolean;

  /**
   * Use to show the Duration column in table
   */
  showDurationCol?: boolean;

  /**
   * Use to show the Gross Exposure column in table
   */
  showGrossExposureCol?: boolean;

  /**
   * Use to show the Long Exposure column in table
   */
  showLongExposureCol?: boolean;

  /**
   * Use to show the Net Exposure column in table
   */
  showNetExposureCol?: boolean;

  /**
   * Use to show the Short Exposure column in table
   */
  showShortExposureCol?: boolean;

  /**
   * Use to show the Target Allocation column in table
   */
  showTargetAllocationCol?: boolean;

  /**
   * Use to show the ActualAllocation column in table
   */
  showActualAllocationCol?: boolean;
  /**
   * Use to show the Risk Contributio column in table
   */
  showRiskContributionAllocationCol?: boolean;
  /**
   * Use to show the Performance Contribution column in table
   */
  showPerformanceContributionAllocationCol?: boolean;

  /**
   * Use to set a title on the label column in table
   */
  labelColumnTitle?: string;

  /**
   * Use override the default title ("Fund") on the breakdown column in table
   */
  breakdownColumnTitle?: string;

  // Caveat config ----------------------------------------

  /**
   * Top proximal placement id
   */
  proximalTopPlacement?: string;

  /**
   * Bottom proximal placement id
   */
  proximalBottomPlacement?: string;

  /**
   * Top secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalTopPlacement?: string;

  /**
   * Bottom secondary proximal placement id (for derivatives proximals etc)
   */
  secondaryProximalBottomPlacement?: string;

  // Chart + table data ----------------------------------------

  /**
   * Data to be used in chart and table
   */
  dataPoints: BarChartDataPoint[];

  /**
   * All the data points that can be used to slice and show data
   */
  totalDataPoints?: BarChartDataPoint[];

  /**
   * Data to be used for percentage bar on top 10 charts
   */
  totalPercent?: number;

  /**
   * Data to be used in table
   */
  benchmarkName?: string;

  /**
   * Component id
   */
  componentId?: PortfolioComponentId;
}

export const hasChildren = (point: BarChartDataPoint): boolean =>
  point.children?.length > 0;

export const expandNode = (
  node: BarTableDataPoint,
  recursive = false
): void => {
  // expanding a node sets isExpanded true, and isHidden to false on child nodes
  node.isExpanded = true;
  node.children?.forEach((childNode: BarTableDataPoint): void => {
    childNode.isHidden = false;
    if (recursive) {
      expandNode(childNode, true);
    }
  });
};

export const collapseNode = (
  node: BarTableDataPoint,
  recursive = false
): void => {
  // collapsing a node sets isExpanded false, and isHidden to true on child nodes
  node.isExpanded = false;
  node.children?.forEach((childNode: BarTableDataPoint): void => {
    childNode.isHidden = true;
    if (recursive) {
      collapseNode(childNode, true);
    }
  });
};

export const hasExpandedNodes = (node: BarTableDataPoint): boolean =>
  node.hasChildren &&
  (node.isExpanded || node.children?.some(hasExpandedNodes));

export const hasCollapsedNodes = (node: BarTableDataPoint): boolean =>
  node.hasChildren &&
  (!node.isExpanded || node.children?.some(hasCollapsedNodes));

// constants for calculating chart height
// TODO: these may need reviewed, possibly including responsive service to adapt for different screen sizes
const MIN_CHART_HEIGHT = 250; // minimum height of chart
const MAX_CHART_HEIGHT = 1000; // maximum height of chart
const CHART_BAR_HEIGHT = 24; // height for each horizontal bar in chart
const CHART_OFFSET_HEIGHT = 50; // height of other chart elements e.g. axis and labels

@Component({
  selector: 'ft-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss'],
})
export class BarChartComponent implements OnInit, OnChanges, OnDestroy {
  highcharts;
  private chartInstance: Highcharts.Chart;

  @Input() data: BarChartData;

  // Enable if all the data is shown without expand and collapse
  @Input() defaultShowAll = true;

  public isPopulated = false;
  public chartOptions: Highcharts.Options;
  public hasNestedData = false;
  public tablePoints: BarTableDataPoint[];

  public isExpandCollapseVisible = false;
  public uniqueElementId: string;

  // table is collapsed by default
  public expandDisabled = false;
  public collapseDisabled = true;

  updateChart = false;
  private chartHeight: number;
  isHandled$: Observable<boolean>;
  isMobile$: Observable<boolean>;
  private isHandheldSubscription: Subscription;
  labelsFontSize = '13'; // Default font size for chart labels
  /**
   * controlling the table toggle for expand collapse state
   */
  toggle = false;
  tooltips: { [name: string]: string } = {};

  constructor(
    private translateService: TranslateService,
    private responsiveService: ResponsiveService,
    private highchartsTheme: HighchartsThemeService,
    private cdr: ChangeDetectorRef,
    private viewportScroller: ViewportScroller
  ) {
    this.uniqueElementId = generateUUID();
  }

  ngOnInit(): void {
    this.isHandled$ = this.responsiveService.isHandheld$();
    this.isHandheldSubscription = this.isHandled$?.subscribe((isHandle) => {
      this.labelsFontSize = isHandle ? '11' : '13';
      this.updateChart = true;
    });
    this.isMobile$ = this.responsiveService.isMobile$();

    this.tooltips[
      'products.sector-duration-column-header'
    ] = this.translateService.tooltip('products.sector-duration-column-header');
  }

  ngOnChanges(): void {
    if (this.data) {
      this.data.totalDataPoints = [...this.data.dataPoints];
      import(/* webpackChunkName: "highcharts-async" */ 'highcharts').then(
        (highchartsModule) => {
          highchartsModule.setOptions(this.highchartsTheme.themeOptions);
          require('highcharts/modules/accessibility')(highchartsModule);
          this.highcharts = highchartsModule;

          this.processData(highchartsModule, this.defaultShowAll);
        }
      );
    }
  }

  /**
   * called by Highcharts component after it inits, to pass a reference to the chart instance
   */
  public chartCallback = (chart: Highcharts.Chart): void => {
    this.chartInstance = chart;
  };

  /**
   * this causes the chart to redraw when called.
   * Used to fix dynamic chart size issues
   */
  public resizeChart(): void {
    setTimeout(() => {
      if (this.chartInstance?.options) {
        this.chartInstance?.reflow();
      }
    });
  }

  /**
   * toggles an individual table node (not recursively)
   */
  public togglePoint(point: BarTableDataPoint): void {
    point?.isExpanded ? collapseNode(point, true) : expandNode(point);
    this.updateDisabledStates();
  }

  /**
   * toggleAll all table nodes (Expand | Collapse)
   */
  public toggleAll(toggle: boolean): void {
    this.toggle = !this.toggle;
    toggle
      ? this.tablePoints?.forEach((point: BarTableDataPoint): void => {
          expandNode(point, true);
        })
      : this.tablePoints?.forEach((point: BarTableDataPoint): void => {
          collapseNode(point, true);
        });

    this.updateDisabledStates();
  }

  /**
   * Updates the disabled states which determine whether 'Expand All' and 'Collapse All' buttons are disabled
   */
  private updateDisabledStates(): void {
    this.expandDisabled = !this.tablePoints.some(hasCollapsedNodes); // no collapsed nodes i.e. all nodes are already expanded
    this.collapseDisabled = !this.tablePoints.some(hasExpandedNodes); // no expanded nodes i.e. all nodes are already collapsed
  }

  /**
   * When data is passed into this component, this function processes it into suitable chart and table data
   * Called direct from Entry component, as ngOnInit and ngOnchanges not called when dynamic component
   */
  public processData(highchartsModule, showAll: boolean = false): void {
    // work out if nested data
    this.hasNestedData = this.data.totalDataPoints.some(hasChildren);

    if (!showAll && !this.hasNestedData) {
      this.data.dataPoints = this.data.totalDataPoints
        ?.filter((dataPoint) => dataPoint.breakdownStd)
        .map(this.mapPointData);
      if (
        this.data.dataPoints.length > 0 &&
        this.data.dataPoints.length < this.data.totalDataPoints.length
      ) {
        this.isExpandCollapseVisible = true;
      } else {
        this.isExpandCollapseVisible = false;
        this.data.dataPoints = this.data.totalDataPoints?.map(
          this.mapPointData
        );
      }
    } else {
      this.data.dataPoints = this.data.totalDataPoints?.map(this.mapPointData);
    }

    // calculate chart height
    let h: number =
      this.data.dataPoints.length * CHART_BAR_HEIGHT * 2.5 +
      CHART_OFFSET_HEIGHT;
    h = Math.max(h, MIN_CHART_HEIGHT); // apply min height
    this.chartHeight = h;

    // get data for table
    this.tablePoints = this.getTableData();
    this.toggleAll(this.toggle); // collapse all rows initially
    // get Highcharts Options for chart
    if (this.data.showExposureChart) {
      this.chartOptions = this.getExposureChartOptions(highchartsModule);
    } else if (this.data.showBenchmarkCol) {
      // reduce the chart height for charts a lot of data points
      if (this.data.dataPoints.length > 10) {
        let height: number =
          this.data.dataPoints.length * CHART_BAR_HEIGHT * 1.75 +
          CHART_OFFSET_HEIGHT;
        height = Math.max(height, MIN_CHART_HEIGHT); // apply min height
        this.chartHeight = height;
      }
      this.chartOptions = this.getBenchmarkChartOptions(highchartsModule);
    } else {
      this.chartOptions = this.getDefaultChartOptions(highchartsModule);
    }
    logger.debug('chart options', this.chartOptions);

    // display component
    this.isPopulated = true;

    this.cdr.detectChanges();
  }

  /**
   * This returns the data point with the unique generated id
   */
  private mapPointData = (point: BarChartDataPoint): BarChartDataPoint => ({
    ...point,
    id: `pointExpand_${generateUUID()}`,
    children: point.children ? point.children?.map(this.mapPointData) : [],
  });

  /**
   * This returns (nested) data in a format suitable for generating a table to accompany the chart
   */
  private getTableData(): BarTableDataPoint[] {
    // map point colors
    // console.log(this.data.dataPoints)
    return this.data.dataPoints.map(
      (point: BarChartDataPoint, index: number): BarTableDataPoint => ({
        ...point,
        pointColor: this.highchartsTheme.getPointColor(
          index,
          this.data.dataPoints.length
        ),
        hasChildren: hasChildren(point),
        children: point.children?.map(
          (child: BarChartDataPoint): BarTableDataPoint => ({
            ...child,
            hasChildren: hasChildren(child),
          })
        ),
        ariaControls: point.children
          ?.map((child: BarChartDataPoint): string => child.id)
          .join(' '),
      })
    );
  }

  /**
   * This creates a Highcharts Options object with options used by all types of chart
   */
  private getBaseChartOptions(highchartsModule): Highcharts.Options {
    const valueSuffix: string = this.data.isPercent ? '%' : '';
    return cloneDeep({
      chart: {
        height: this.chartHeight,
        spacingLeft: 0,
        spacingRight: 0,
        marginRight: 30,
        events: {
          render(this) {
            const that: Highcharts.Chart = this;
            setTimeout(() => {
              if (that?.options) {
                that.reflow();
              }
            }, 0);
          },
        },
        reflow: true,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          pointWidth: CHART_BAR_HEIGHT,
        },
      },
      title: {
        text: null,
      },
      xAxis: {
        gridLineDashStyle: 'LongDash',
        categories: this.data.dataPoints.map(
          (point: BarChartDataPoint) => point.label
        ),
        labels: {
          enabled: true,
          style: {
            color: this.highchartsTheme.themeFonts.colors.grey,
            // NGC-8114 removed fontFamily as it seems to be affecting highcharts layout calculations
            // font is already set in highcharts-theme-frk.scss
            fontSize: this.labelsFontSize,
            wordBreak: 'break-all',
            textOverflow: 'allow',
          },
        },
      },
      yAxis: {
        gridLineDashStyle: 'LongDash',
        title: {
          text: null,
        },
        labels: {
          overflow: 'allow',
          style: {
            color: this.highchartsTheme.themeFonts.colors.grey,
            fontFamily: this.highchartsTheme.themeFonts.fontFamily,
            fontSize: this.labelsFontSize,
          },
          distance: 80,
          useHTML: true,
          formatter() {
            // Precision 1 decimal place when max value under 10
            const isSmallAxis =
              this.axis.getExtremes().dataMax < 10 ||
              this.value !== Math.floor(this.value as number);
            return isSmallAxis
              ? `${highchartsModule.numberFormat(this.value, 1)}${valueSuffix}`
              : `${highchartsModule.numberFormat(this.value, 0)}${valueSuffix}`;
          },
        },
      },
    });
  }

  /**
   * This creates a Highcharts Options object suitable for displaying most Portfolio Charts
   */
  private getDefaultChartOptions(highchartsModule): Highcharts.Options {
    const opts: Highcharts.Options = this.getBaseChartOptions(highchartsModule);
    opts.series = [
      {
        type: 'bar',
        colorByPoint: true,
        // return actual allocation if data, otherwise default to breakdown
        // This logic may need strengthened in future
        data: this.data.dataPoints.map((point: BarChartDataPoint) => ({
          name: point.actualAllocation || point.breakdown,
          y: point.actualAllocationStd || point.breakdownStd,
        })),
      },
    ];
    opts.tooltip = {
      formatter() {
        return `${this.point.category}<br/>${this.point.name}`;
      },
    };
    return opts;
  }

  /**
   * This creates a Highcharts Options object suitable for displaying most Portfolio Charts with benchmark Columns
   */
  private getBenchmarkChartOptions(highchartsModule): Highcharts.Options {
    const context: BarChartComponent = this;
    const opts: Highcharts.Options = this.getBaseChartOptions(highchartsModule);
    opts.plotOptions = {
      bar: {
        // bar width halved, because we have twice as many columns
        pointWidth: CHART_BAR_HEIGHT / 2,
      },
    };
    opts.series = [
      {
        type: 'bar',
        // return actual allocation if data, otherwise default to breakdown
        // This logic may need strengthened in future
        data: this.data.dataPoints.map((point: BarChartDataPoint) => ({
          name: point.actualAllocation || point.breakdown || 0,
          y: point.actualAllocationStd || point.breakdownStd || 0,
        })),
      },
      {
        type: 'bar',
        name: this.translateService.instant(
          'products.benchmark',
          this.data.fundId
        ),
        data: this.data.dataPoints.map((point: BarChartDataPoint) => ({
          name: point.benchmark,
          y: point.benchmarkStd,
        })),
        states: {
          hover: {
            brightness: 0,
          },
        },
      },
    ];
    opts.tooltip = {
      formatter() {
        return `
          <strong>${this.points[0].point.category}</strong>
          <br />
          <span style="color:${
            this.points[0].point.color
          }">&#9632;</span> ${context.translateService.instant(
          'products.fund',
          context.data.fundId
        )}: ${this.points[0].point.name}
          <br />
          <span style="color:${
            this.points[1]?.point.color
          }">&#9632;</span> ${context.translateService.instant(
          'products.benchmark',
          context.data.fundId
        )}: ${this.points[1]?.point.name || EMDASH}
        `;
      },
      shared: true,
    };
    return opts;
  }

  /**
   * This creates a Highcharts Options object suitable for displaying 'Exposure' type Portfolio Charts
   * These are charts that have 2 data series
   */
  private getExposureChartOptions(highchartsModule): Highcharts.Options {
    const opts: Highcharts.Options = this.getBaseChartOptions(highchartsModule);
    opts.series = [
      {
        type: 'bar',
        colorByPoint: false,
        name: this.translateService.instant(
          'products.gross-exposure',
          this.data.fundId
        ),
        data: this.data.dataPoints.map((point: BarChartDataPoint) => ({
          name: point.grossExposure,
          y: point.grossExposureStd,
        })),
      },
      {
        type: 'bar',
        colorByPoint: false,
        name: this.translateService.instant(
          'products.net-exposure',
          this.data.fundId
        ),
        data: this.data.dataPoints.map((point: BarChartDataPoint) => ({
          name: point.netExposure,
          y: point.netExposureStd,
        })),
      },
    ];
    opts.tooltip = {
      formatter() {
        return `
          ${this.point.category}<br/>
          <span style="color:${this.point.color}">${this.series.name}</span> ${this.point.name}
        `;
      },
    };
    return opts;
  }

  public handleExpandCollapse(component: ExpandCollapseComponent): void {
    this.processData(this.highcharts, component?.expanded);
    if (!component?.expanded) {
      this.scrollToBarChartTop();
    }
  }

  public scrollToBarChartTop(): void {
    logger.debug('Scroll to Bar Chart unique id', this.uniqueElementId);
    this.viewportScroller.scrollToAnchor(this.uniqueElementId);
  }

  ngOnDestroy() {
    this.isHandheldSubscription?.unsubscribe();
  }
}
