import { CurrencyCode, FundId, ShareClassCode } from '@types';

export interface PricingHistory {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  navDate?: string;
  asOfDate?: string;
  asOfDateStd?: string;
  nav?: string;
  navStd?: string;
  navChangeValue?: string;
  currencyCode?: CurrencyCode;
  navChangePercent?: string;
  applicationPrice?: string;
  redemptionPrice?: string;
  reinvestedNav?: string;
  marketPriceValue?: string;
  marketPriceValueStd?: string;
  marketChangeValue?: string;
  marketChangePercent?: string;
  high52WeekRollingValue?: string;
  high52WeekRollingDate?: string;
  low52WeekRollingValue?: string;
  low52WeekRollingDate?: string;
  discountOrPremiumInception?: string;
  discountOrPremiumAtLastClose?: string;
  discountOrPremiumAtLastCloseStd?: string;
  bidOrAskSpread?: string;
  highMarketPrice52WeekValue?: string;
  highMarketPrice52WeekDate?: string;
  lowMarketPrice52WeekValue?: string;
  lowMarketPrice52WeekDate?: string;
  masterFundNav?: string;
  weeklyLiquidAssetRatio?: string;
  dailyLiquidAssetRatio?: string;
  viiv?: string;
  netShareHolderFlow?: string;
  masterNetShareHolderFlow?: string;
}
