<eds-banner-role-selector-variation-two
  *ngIf="(isVisible$ | async) === true"
  [title]="title"
  [preTitle]="preTitle"
  [summary]="summary"
  [languageOptions]="languageOptions"
  [roles]="roles"
  [isEmbeddedInOtherComponent]="false"
  [imageSrc]="imageSrc"
  [imageTransparentBg]="imageTransparentBg"
  [transparencyRatio]="transparencyRatio"
  theme="primary"
  (roleClicked)="roleClicked($event)"
>
</eds-banner-role-selector-variation-two>
