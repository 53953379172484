import { TranslateService } from '@shared/translate/translate.service';
import {
  LiteratureDocumentListingData,
  FundId,
  ShareClassCode,
  FundShareClassId,
  LiteratureDocument,
} from '@types';

export function nameGenerator(
  data: LiteratureDocumentListingData,
  translateService: TranslateService,
  isCanada: boolean = false
): string {
  const dctermsTypeTranslated = translateService.instant(
    'literature.' + data.literatureData.dctermsType
  );
  if (isShareClassEligible(data) && data.literatureData.showShareClass) {
    if (isCanada) {
      return `${data.product?.fundName} - ${data.literatureData.shareclass.shareClassName}- ${data.literatureData.shareclass.shareClassCurrency} - ${dctermsTypeTranslated}`;
    } else {
      return `${data.product?.fundName} - ${data.literatureData.shareclass.shareClassName} - ${dctermsTypeTranslated}`;
    }
  } else if (isShareClassEligible(data)) {
    return `${data.product?.fundName} - ${dctermsTypeTranslated}`;
  } else if (data.product?.fundName) {
    return `${data.product?.fundName} - ${dctermsTypeTranslated}`;
  }
  return dctermsTypeTranslated;
}
export function fundNameGenerator(
  data: LiteratureDocumentListingData,
  translateService: TranslateService
): string {
  const dctermsTypeTranslated = translateService.instant(
    'literature.' + data.literatureData.dctermsType
  );
  const productsFundName = data.product?.fundName?.trim();
  if (data.literatureData.showShareClass && isShareClassEligible(data)) {
    return productsFundName
      ? `${productsFundName} ${dctermsTypeTranslated} - ${data.literatureData.shareclass.shareClassName}`
      : `${dctermsTypeTranslated} - ${data.literatureData.shareclass.shareClassName}`;
  } else if (data.literatureData?.fundName) {
    return data.literatureData?.fundName.trim();
  } else if (data.literatureData?.dctermsTitle) {
    return data.literatureData?.dctermsTitle.trim();
  } else {
    return productsFundName
      ? `${productsFundName} ${dctermsTypeTranslated}`
      : dctermsTypeTranslated;
  }
}

export function isShareClassEligible(data: LiteratureDocumentListingData) {
  return (
    data.literatureData.shareclass &&
    data.literatureData.shareclass.shareClassName?.trim() !== '' &&
    data.literatureData.shareclass.shareClassName
      ?.trim()
      ?.toLocaleUpperCase() !== 'SINGLCLASS' &&
    data.literatureData.shareclass.shareClassCode
      ?.trim()
      ?.toLocaleUpperCase() !== 'SINGLCLASS'
  );
}

export function detailsGenerator(
  data: LiteratureDocumentListingData,
  translateService: TranslateService,
  isInternational: boolean
): string {
  if (isInternational) {
    return getDescription(data, translateService);
  } else {
    return translateService.instant(
      'literature.audience_' + getAudience(data?.literatureData)
    );
  }
}

export function getDescription(
  data: LiteratureDocumentListingData,
  translateService: TranslateService
): string {
  const dctermsTypeTranslated = translateService.instant(
    'literature.' + data.literatureData.dctermsType
  );
  if (
    data.literatureData.dctermsDescription &&
    data.literatureData.dctermsDescription.trim() !== ''
  ) {
    return `${data.literatureData.dctermsDescription}`;
  }
  if (data.literatureData.dctermsTitle) {
    return `${data.literatureData.dctermsTitle}`;
  }
  return `${dctermsTypeTranslated}`;
}

export function getAudience(litData: LiteratureDocument): string {
  if (litData?.dctermsAudience?.length === 1) {
    return litData?.dctermsAudience[0];
  } else if (litData?.dctermsAudience.includes('investor')) {
    return 'investor';
  } else if (litData?.dctermsAudience.includes('financial-professionals')) {
    return 'financial-professionals';
  }
  return litData?.dctermsAudience[0];
}

export function documentNameGenerator(
  data: LiteratureDocumentListingData,
  translateService: TranslateService,
  showOnlyTitle?: boolean
): string {
  const dctermsTypeTranslated = translateService.instant(
    'literature.' + data.literatureData.dctermsType
  );
  if (
    data.literatureData.isDuplicateDCTermsType &&
    data.literatureData.dctermsTitle
  ) {
    return data.literatureData.dctermsTitle;
  }
  if (showOnlyTitle) {
    return data.literatureData.dctermsTitle;
  }
  return dctermsTypeTranslated;
}

export function getFirstActiveShareClass(
  oneTISNumbers: string[],
  shareClasses: string[],
  activeShareClasses: string[]
): [activeFund: FundId, activeShareClass: ShareClassCode] {
  let activeFund: FundId;
  let activeShareClass: ShareClassCode;

  let found = false;

  oneTISNumbers.every((fund) => {
    shareClasses.every((shareclass) => {
      if (
        activeShareClasses.includes(
          (fund + '-' + shareclass) as FundShareClassId
        )
      ) {
        activeFund = fund as FundId;
        activeShareClass = shareclass as ShareClassCode;
        found = true;
        return false; // we break loop when first active fund-shareclass found
      }
      return true;
    });
    return !found; // we repeat until ther first active fund-shareclass found
  });

  return [activeFund, activeShareClass];
}
