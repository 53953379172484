import { InfoItem } from '@frk/eds-components';
import { Nominal } from 'simplytyped';

// StatisticId
// although this is just a string, giving it a named (branded) type helps documentation and make typesafe
export type StatisticId = Nominal<string, 'StatisticId'>;

// This is applied to a StatisticId below to create it's associated benchmark id
// e.g. AMT_EXPOSURE-BENCHMARK
// This can appear in StatisticSuppressionSet to hide benchmark stats individually
export const STATISTIC_ID_BENCHMARK_SUFFIX = '-BENCHMARK';

// this isn't a complete list of statistic IDs, just the original defined list
// In general, this NO LONGER NEEDS TO BE MAINTAINED for every statistic
// Stats only need to be defined explicitly if specific processing is required i.e. assetStatistics, positionStatistics or acqStatistics
export const enum StatisticIds {
  ACTIVE_SHARE = 'ACTIVE_SHARE',
  AMT_EXPOSURE = 'AMT_EXPOSURE',
  AVG_CRDT_QUALITY_EXCL_CASH = 'AVG_CRDT_QUALITY_EXCL_CASH',
  AVG_CRDT_QUALITY_EXPOSURE = 'AVG_CRDT_QUALITY_EXPOSURE',
  AVG_CRDT_QUALITY_EXPSRE_EXCSH = 'AVG_CRDT_QUALITY_EXPSRE_EXCSH',
  AVG_CRDT_QUALITY_PCT_COVRD = 'AVG_CRDT_QUALITY_PCT_COVRD',
  AVG_CRDT_QUALITY_RATING = 'AVG_CRDT_QUALITY_RATING',
  AVG_LIFE_WGT_AVG = 'AVG_LIFE_WGT_AVG',
  BISSET_AVG_CRDT_QUALITY_RATING = 'BISSET_AVG_CRDT_QUALITY_RATING',
  CONVX_OAC_PCT_COVRD = 'CONVX_OAC_PCT_COVRD',
  CONVX_OAC_WGT_AVG = 'CONVX_OAC_WGT_AVG',
  CONVX_TO_MATURITY_PCT_COVRD = 'CONVX_TO_MATURITY_PCT_COVRD',
  CONVX_TO_MATURITY_WGT_AVG = 'CONVX_TO_MATURITY_WGT_AVG',
  COUPON_WGT_AVG = 'COUPON_WGT_AVG',
  CURRENT_YIELD_WGT_AVG = 'CURRENT_YIELD_WGT_AVG',
  CPREIF_NBR_PROP = 'CPREIF_NBR_PROP',
  DEBT_EQUITY = 'DEBT_EQUITY',
  DELTA = 'DELTA',
  DISC_MARGIN_MAT = 'DISC_MARGIN_MAT',
  DIVIDEND_YIELD = 'DIVIDEND_YIELD',
  DIVIDEND_YIELD_MEDIAN = 'DIVIDEND_YIELD_MEDIAN',
  DIVIDEND_YIELD_PCT_COVRD = 'DIVIDEND_YIELD_PCT_COVRD',
  DIVIDEND_YIELD_STD = 'DIVIDEND_YIELD_STD',
  DURTN_MOD_MATURITY_WGT_AVG = 'DURTN_MOD_MATURITY_WGT_AVG',
  DURTN_MOD_MATURITY_WGT_AVG_F = 'DURTN_MOD_MATURITY_WGT_AVG_F',
  DURTN_MOD_WORST_WGT_AVG = 'DURTN_MOD_WORST_WGT_AVG',
  DURTN_OAD_WGT_AVG = 'DURTN_OAD_WGT_AVG',
  DURTN_OASD_WGT_AVG = 'DURTN_OASD_WGT_AVG',
  EBITDA_VALUE = 'EBITDA_VALUE',
  EPS_5_YR = 'EPS_5_YR',
  FINANCIAL_LEVERAGE = 'FINANCIAL_LEVERAGE',
  FLOATING_RATE = 'FLOATING_RATE',
  GAMMA = 'GAMMA',
  GROWTH_EST_3_5_YR = 'GROWTH_EST_3_5_YR',
  HIST_EPS_GROWTH_3_YR = 'HIST_EPS_GROWTH_3_YR',
  LEV_FA_FIXED_RATE_NOTES = 'LEV_FA_Fixed Rate Notes',
  FIXED_RATE_LOANS = 'FIXED_RATE_LOANS',
  LEV_FA_LOANS = 'LEV_FA_Loans',
  LEV_FA_PREFERRED_SHARES = 'LEV_FA_Preferred Shares',
  LEV_FA_REVERSE_REPURCHASE_AGREEMENTS = 'LEV_FA_Reverse Repurchase Agreements',
  LOAN_TO_VALUE = 'LOAN_TO_VALUE',
  MANAGED_ASSETS = 'MANAGED_ASSETS',
  MARKET_CAP_AVG = 'MARKET_CAP_AVG',
  MARKET_CAP_MAXIMUM = 'MARKET_CAP_MAXIMUM',
  MARKET_CAP_MAX_SCRTY_NAME = 'MARKET_CAP_MAX_SCRTY_NAME',
  MARKET_CAP_MEDIAN = 'MARKET_CAP_MEDIAN',
  MARKET_CAP_MINIMUM = 'MARKET_CAP_MINIMUM',
  MARKET_CAP_MIN_SCRTY_NAME = 'MARKET_CAP_MIN_SCRTY_NAME',
  MARKET_CAP_WGT_AVG = 'MARKET_CAP_WGT_AVG',
  MARKET_CAP_WGT_AVG_LONG = 'MARKET_CAP_WGT_AVG_LONG',
  MARKET_CAP_WGT_AVG_SHORT = 'MARKET_CAP_WGT_AVG_SHORT',
  MATURITY_PCT_COVRD = 'MATURITY_PCT_COVRD',
  MATURITY_WGT_AVG = 'MATURITY_WGT_AVG',
  MRTG_DLLR_ROLLS = 'MRTG_DLLR_ROLLS',
  NBR_OF_BONDS = 'NBR_OF_BONDS',
  NBR_OF_CONVERTIBLE_ISSUERS = 'NBR_OF_CONVERTIBLE_ISSUERS',
  NBR_OF_EQUITY_ISSUERS = 'NBR_OF_EQUITY_ISSUERS',
  NBR_OF_FI_ISSUERS = 'NBR_OF_FI_ISSUERS',
  NBR_OF_ISSUERS = 'NBR_OF_ISSUERS',
  NBR_OF_LONG_SECURITIES = 'NBR_OF_LONG_SECURITIES',
  NBR_OF_SECURITIES_EXCL_CASH = 'NBR_OF_SECURITIES_EXCL_CASH',
  NBR_OF_SECURITIES_INCL_CASH = 'NBR_OF_SECURITIES_INCL_CASH',
  NBR_OF_SHORT_SECURITIES = 'NBR_OF_SHORT_SECURITIES',
  NET_MARGIN = 'NET_MARGIN',
  NUMBER_OF_SECURITIES = 'NUMBER_OF_SECURITIES',
  OPERATING_MARGIN = 'OPERATING_MARGIN',
  PORTFOLIO_TURNOVER = 'PORTFOLIO_TURNOVER',
  PORTFOLIO_TURNOVER_RATIO_12M = 'PORTFOLIO_TURNOVER_RATIO_12M',
  PREMIUM = 'PREMIUM',
  PRICE_TO_BVALUE_PCT_COVRD = 'PRICE_TO_BVALUE_PCT_COVRD',
  PRICE_TO_BVALUE_MEDIAN = 'PRICE_TO_BVALUE_MEDIAN',
  PRICE_TO_BVALUE_WGT_AVG = 'PRICE_TO_BVALUE_WGT_AVG',
  PRICE_TO_CFLOW_MEDIAN = 'PRICE_TO_CFLOW_MEDIAN',
  PRICE_TO_CFLOW_WGT_AVG = 'PRICE_TO_CFLOW_WGT_AVG',
  PRICE_TO_EARN_FIN_YR_1 = 'PRICE_TO_EARN_FIN_YR_1',
  PRICE_TO_EARN_FIN_YR_2 = 'PRICE_TO_EARN_FIN_YR_2',
  PRICE_TO_EARN_GROWTH_FIN_YR_1 = 'PRICE_TO_EARN_GROWTH_FIN_YR_1',
  PRICE_TO_EARN_MEDIAN = 'PRICE_TO_EARN_MEDIAN',
  PRICE_TO_EARN_WGT_AVG = 'PRICE_TO_EARN_WGT_AVG',
  PRICE_WGT_AVG = 'PRICE_WGT_AVG',
  RETURN_ON_ASSETS_ROA = 'RETURN_ON_ASSETS_ROA',
  RETURN_ON_EQUITY = 'RETURN_ON_EQUITY',
  SALES_3_YR = 'SALES_3_YR',
  SPREAD_TO_LIBOR = 'SPREAD_TO_LIBOR',
  TIME_TO_RESET = 'TIME_TO_RESET',
  TOTAL_NET_ASSETS = 'TOTAL_NET_ASSETS',
  VAR1MONTH = 'VAR1MONTH', // Value At Risk NGC-628 (NGC-1177)
  WEIGHTED_AVG_LIFE_DAYS = 'WEIGHTED_AVG_LIFE_DAYS',
  WEIGHTED_AVG_RATING_FACTOR = 'WEIGHTED_AVG_RATING_FACTOR',
  WEIGHTED_YIELD = 'WEIGHTED_YIELD',
  YIELD_TO_MATURITY_WGT_AVG = 'YIELD_TO_MATURITY_WGT_AVG',
  YIELD_TO_WORST_PCT_COVRD = 'YIELD_TO_WORST_PCT_COVRD',
  YIELD_TO_WORST_WGT_AVG = 'YIELD_TO_WORST_WGT_AVG',
}

export interface Statistic extends InfoItem {
  id: StatisticId;
  mainStatisticId?: StatisticId; // If this is a BM stat, links the main stat
  benchmarkId?: string;
  benchmarkName?: string;
}

export interface PortfolioCharsHistory extends InfoItem {
  asOfDate?: string;
  asOfDateStd?: string;
  elementName?: string;
  elementDisplayName?: string;
  elememtTranslatedName?: string;
  elementValueStd?: string;
  elementValueLocal?: string;
  frequency?: string;
}

// this adds a benchmark statistic id for each basic stat defined
const reduceBenchmark = (stats: StatisticId[], stat: string): StatisticId[] => {
  stats.push(stat as StatisticId);
  stats.push((stat + STATISTIC_ID_BENCHMARK_SUFFIX) as StatisticId);
  return stats;
};

// statistics that go in the Assets box
// also helps to maintain the order for CEF
export const assetStatistics: StatisticId[] = [
  StatisticIds.AVG_CRDT_QUALITY_PCT_COVRD,
  StatisticIds.FINANCIAL_LEVERAGE,
  StatisticIds.LEV_FA_LOANS,
  StatisticIds.LEV_FA_FIXED_RATE_NOTES,
  StatisticIds.FIXED_RATE_LOANS,
  StatisticIds.LEV_FA_PREFERRED_SHARES,
  StatisticIds.LEV_FA_REVERSE_REPURCHASE_AGREEMENTS,
  StatisticIds.MANAGED_ASSETS,
  StatisticIds.MRTG_DLLR_ROLLS,
  StatisticIds.TOTAL_NET_ASSETS,
].reduce(reduceBenchmark, []);

// statistics that go in the Positions box
export const positionStatistics: StatisticId[] = [
  StatisticIds.NBR_OF_BONDS,
  StatisticIds.NBR_OF_CONVERTIBLE_ISSUERS,
  StatisticIds.NBR_OF_EQUITY_ISSUERS,
  StatisticIds.NBR_OF_FI_ISSUERS,
  StatisticIds.NBR_OF_ISSUERS,
  StatisticIds.NBR_OF_LONG_SECURITIES,
  StatisticIds.NBR_OF_SECURITIES_INCL_CASH,
  StatisticIds.NBR_OF_SECURITIES_EXCL_CASH,
  StatisticIds.NBR_OF_SHORT_SECURITIES,
  StatisticIds.NUMBER_OF_SECURITIES,
  StatisticIds.CPREIF_NBR_PROP,
].reduce(reduceBenchmark, []);

// statistics that affect ACQ FIMES rules
export const acqStatistics: StatisticId[] = [
  StatisticIds.AVG_CRDT_QUALITY_EXCL_CASH as StatisticId,
  StatisticIds.AVG_CRDT_QUALITY_EXPOSURE as StatisticId,
  StatisticIds.AVG_CRDT_QUALITY_EXPSRE_EXCSH as StatisticId,
  StatisticIds.AVG_CRDT_QUALITY_RATING as StatisticId,
];
