import { Directive, HostBinding, Input, OnInit } from '@angular/core';

/**
 * A convinence directive for applying styling to a button.
 *
 * [See demo](../../?path=/story/directives-button--basic)
 *
 * Example:
 * ```html
 * <button ftButton>A button</button>
 * <button ftButton="secondary">A secondary button</button>
 * <button ftButton="tertiary">A tertiary button</button>
 * ```
 */
@Directive({
  selector: '[ftButton]',
})
export class ButtonDirective implements OnInit {
  /**
   * sets the button type
   */
  @Input() ftButton:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'outline'
    | 'invert';
  /**
   * Specify the size of the button
   */
  @Input() size: 'normal' | 'small' | 'large' | 'full';

  // a whole lot of HostBindings ... this way we don't have to touch the elementRef directly
  @HostBinding('class.ft__btn--primary') get primaryButton() {
    return this.ftButton === 'primary';
  }
  @HostBinding('class.ft__btn--secondary') get secondaryButton() {
    return this.ftButton === 'secondary';
  }
  @HostBinding('class.ft__btn--tertiary') get tertiaryButton() {
    return this.ftButton === 'tertiary';
  }
  @HostBinding('class.ft__btn--outline') get outlineButton() {
    return this.ftButton === 'outline';
  }
  @HostBinding('class.ft__btn--invert') get invertButton() {
    return this.ftButton === 'invert';
  }
  @HostBinding('class.ft__btn--sm') get smallSize() {
    return this.size === 'small';
  }
  @HostBinding('class.ft__btn') get normalSize() {
    return this.size === 'normal';
  }
  @HostBinding('class.ft__btn--lg') get largeSize() {
    return this.size === 'large';
  }
  @HostBinding('class.ft__btn--full') get FullWidthSize() {
    return this.size === 'full';
  }

  @HostBinding('class.ft__btn--skeleton') @Input() skeleton = false;

  ngOnInit() {
    if (!this.ftButton) {
      this.ftButton = 'primary';
    }
    if (!this.size) {
      this.size = 'normal';
    }
  }
}
