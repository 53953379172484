import { Component, Input } from '@angular/core';
import { InfoItem } from '@frk/eds-components';
import { FundItem, FundThumbnail } from '@search/interfaces/search.interface';

@Component({
  selector: 'ft-fund-manager',
  templateUrl: './fund-manager.component.html',
  styleUrls: [
    '../../exact-match.component.scss',
    './fund-manager.component.scss',
  ],
})
export class FundManagerComponent {
  @Input() fundItems: Array<FundItem> = [];
  @Input() fundsThumbnails: Array<FundThumbnail> = [];

  managerName = 'Edward D. Perks CFA';
  managerIntro = 'Chief Investment Officer | President, Franklin Advisers, Inc';

  bioContent1 = `Emerging market countries are incredibly diverse, culturally,
  geographically, and economically but typically share some key
  characteristics: fast economic growth, low debt, large consumer
  bases and vast resources.`;

  bioContent2 = `Many investors are attracted to emerging markets due to their
  potential for growth. Increasingly, emerging markets are being
  recognized as robust consumer markets and sources of technological
  innovation in their own right.`;

  infoItem: Array<InfoItem> = [
    {
      label: 'Location',
      val: 'San Mateo, California, US',
    },
    {
      label: 'Tenure',
      val: '1992',
    },
  ];
}
