<ng-container *ngIf="!isWide">
  <main edsSpacing="md">
    <ng-container brComponent="lead-content"></ng-container>

    <!-- set z-index: 4; so sticky tabs are above ag grids -->
    <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
      <ng-container brComponent="sticky-content"></ng-container>
    </div>

    <div edsGrid>
      <div class="container container--wide">
        <!-- title-content components place -->
        <div edsRow>
          <div edsCol>
            <ng-container brComponent="title-content"></ng-container>
          </div>
        </div>
        <!-- Responsive Layout components place -->
        <ng-container [ngTemplateOutlet]="responsiveLayout"></ng-container>
        <!-- Additional full-width width components place -->
        <div edsRow>
          <div edsCol>
            <ng-container brComponent="full-width-content"></ng-container>
          </div>
        </div>
        <!-- Additional 50x50% width components place -->
        <div edsRow>
          <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 6 }">
            <ng-container brComponent="half-left-content"></ng-container>
          </div>
          <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 6 }">
            <ng-container brComponent="half-right-content"></ng-container>
          </div>
        </div>
      </div>
    </div>
    <!-- lower-content components place -->
    <ng-container brComponent="lower-content"></ng-container>
  </main>
</ng-container>

<ng-container *ngIf="isWide">
  <main edsSpacing="md">
    <ng-container brComponent="lead-content"></ng-container>

    <!-- set z-index: 4; so sticky tabs are above ag grids -->
    <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
      <ng-container brComponent="sticky-content"></ng-container>
    </div>

    <div edsGrid>
      <!-- title-content components place -->
      <div edsRow>
        <div edsCol>
          <ng-container brComponent="title-content"></ng-container>
        </div>
      </div>
      <!-- Responsive Layout components place -->
      <ng-container [ngTemplateOutlet]="responsiveLayout"></ng-container>
      <!-- Additional full-width width components place -->
      <div edsRow>
        <div edsCol>
          <ng-container brComponent="full-width-content"></ng-container>
        </div>
      </div>
      <!-- Additional 50x50% width components place -->
      <div edsRow>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 8, l: 6 }">
          <ng-container brComponent="half-left-content"></ng-container>
        </div>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 8, l: 6 }">
          <ng-container brComponent="half-right-content"></ng-container>
        </div>
      </div>
    </div>
    <!-- lower-content components place -->
    <ng-container brComponent="lower-content"></ng-container>
  </main>
</ng-container>

<ng-template #responsiveLayout>
  <div edsRow>
    <div edsCol edsSpacing="md">
      <div class="two-cols-extended__grid-responsive">
        <div class="two-cols-extended__grid-responsive__promo-content">
          <ng-container brComponent="promo-content"></ng-container>
        </div>
        <div class="two-cols-extended__grid-responsive__main-content">
          <ng-container brComponent="main-content"></ng-container>
        </div>
        <div class="two-cols-extended__grid-responsive__side-content">
          <ng-container brComponent="side-content"></ng-container>
        </div>
      </div>
    </div>
  </div>
</ng-template>
