import { RiskStatistics } from '@products/models/risk-statistics';
import { RiskStatisticsDTO } from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class RiskStatisticsMapper extends Mapper<RiskStatistics> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(riskStatisticsDTO: RiskStatisticsDTO): RiskStatistics {
    return {
      asOfDate: riskStatisticsDTO.asofdate,
      bullMarketAboveTheBenchmark2yr: riskStatisticsDTO.bullmkttotabvbm2yr,
      bullMarketBelowTheBenchmark2yr: riskStatisticsDTO.bullmkttotblwbm2yr,
      bullMarketTotal2yr: riskStatisticsDTO.bullmkttotnom2yr,
      bullMarketHitRate2yr: riskStatisticsDTO.bullmkthitratepct2yr,
      bullMarketAverageAccess2yr: riskStatisticsDTO.bullmktavgexc2yr,
      bearMarketAboveTheBenchmark2yr: riskStatisticsDTO.bearmkttotabvbm2yr,
      bearMarketBelowTheBenchmark2yr: riskStatisticsDTO.bearmkttotblwbm2yr,
      bearMarketTotal2yr: riskStatisticsDTO.bearmkttotnom2yr,
      bearMarketHitRate2yr: riskStatisticsDTO.bearmkthitratepct2yr,
      bearMarketAverageAccess2yr: riskStatisticsDTO.bearmktavgexc2yr,
      totalMarketAboveTheBenchmark2yr: riskStatisticsDTO.totabvbm2yr,
      totalMarketBelowTheBenchmark2yr: riskStatisticsDTO.totblwbm2yr,
      totalMarketOfTotal2yr: riskStatisticsDTO.totoftotal2yr,
      totalMarketHitRate2yr: riskStatisticsDTO.tothitratepct2yr,
      totalMarketAverageAccess2yr: riskStatisticsDTO.totavgexcpct2yr,
      averageExcessMarketAboveTheBenchmark2yr: riskStatisticsDTO.avgexcabvbm2yr,
      averageExcessMarketBelowTheBenchmark2yr: riskStatisticsDTO.avgexcblwbm2yr,
    };
  }
}
