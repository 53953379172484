<main edsSpacing="xl">
  <!-- Breadcrumb -->

  <div>
    <ng-container brComponent="lead-content"></ng-container>
  </div>

  <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 2' : ''">
    <ng-container brComponent="sticky-content"></ng-container>
  </div>

  <ng-container
    *ngIf="!isTitleContentWide"
    brComponent="title-content"
  ></ng-container>
  <div edsGrid edsSpacing="xl" *ngIf="isTitleContentWide">
    <div class="container container--wide">
      <ng-container brComponent="title-content"></ng-container>
    </div>
  </div>

  <div edsGrid *ngIf="page && (tabs?.length > 0 || isEditMode)">
    <div class="container" [class.container--wide]="isWide">
      <div edsRow>
        <!-- If preview -->
        <div
          *ngIf="page && isEditMode"
          edsSpacing="xl"
          class="one-col-tabbed__preview-width"
        >
          <ng-container
            *ngFor="let tab of tabs; let i = index"
            brComponent="tab-area-{{ tabIndex[i] }}"
          ></ng-container>
        </div>
        <!-- Not preview -->
        <eds-tabs
          edsCol
          *ngIf="page && !isEditMode"
          [followFocus]="true"
          [isNavigation]="false"
          [cacheActive]="true"
          [fullWidth]="true"
          dropdownTheme="handheld"
        >
          <ng-container *ngFor="let tab of tabs; let i = index">
            <eds-tab
              *ngIf="tab"
              heading="Tab"
              [heading]="tab?.label"
              [tabId]="tab?.labelID"
            >
              <ng-container
                brComponent="tab-area-{{ tabIndex[i] }}"
              ></ng-container>
            </eds-tab>
          </ng-container>
        </eds-tabs>
        <!-- / Not preview -->
      </div>
    </div>
  </div>

  <ng-container
    *ngIf="!isLowerContentWide"
    brComponent="lower-content"
  ></ng-container>
  <div *ngIf="isLowerContentWide" edsGrid edsSpacing="xl">
    <div class="container container--wide">
      <ng-container brComponent="lower-content"></ng-container>
    </div>
  </div>
</main>
