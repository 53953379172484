<eds-simple-modal [modalId]="modalIdEnum" aria-label="Close">
  <div modal-header>
    <ng-container *ngIf="dialogId === 'cartModal'">
      <eds-title [level]="3" [size]="'h4'" [isLight]="true" [theme]="'dark'">
        {{ "literature.lit-you-have" | translate }} {{ gridData.length }}
        <ng-container *ngIf="gridData.length > 1; else oneItemTemplate">
          {{ "literature.items-in-cart" | translate }}
        </ng-container>
        <ng-template #oneItemTemplate>
          {{ "literature.item-in-cart" | translate }}
        </ng-template>
      </eds-title>
    </ng-container>
  </div>

  <div modal-body>
    <ng-container *ngIf="dialogId === 'cartModal'">
      <p
        *ngIf="isMandatoryAvailable"
        style="font-weight: bold; font-style: normal; color: #e60000"
      >
        {{ "literature.cart-mandatory-alert-body-msg" | translate }}
      </p>
      <p>{{ "literature.lit-cart-modal-subtitle" | translate }}</p>
      <table class="table table--secondary">
        <thead class="table__thead">
          <tr class="table__tr">
            <th class="table__th">
              <span>{{
                "literature.lit-listing-column-name" | translate
              }}</span>
            </th>
            <th class="table__th text-align-right">
              <span>{{
                "literature.lit-listing-column-action" | translate
              }}</span>
            </th>
          </tr>
        </thead>
        <tbody class="table__body">
          <tr class="table__tr" *ngFor="let data of gridData">
            <td class="table__td">
              <div edsFlexbox>
                <div edsPadding edsPaddingRight="md" class="image-width">
                  <ng-container *ngIf="data.imageUrl; else icon">
                    <eds-image
                      [imgSrc]="data.imageUrl"
                      altText="altText"
                      class="spacingWrapper"
                    >
                    </eds-image>
                  </ng-container>
                  <ng-template #icon>
                    <eds-icon
                      type="pdf"
                      size="large"
                      [addTitle]="'literature.pdf-icon' | translate"
                    ></eds-icon>
                  </ng-template>
                </div>
                <div>
                  {{ data.fundName }}
                </div>
              </div>
            </td>
            <td class="table__td text-align-right">
              <eds-link-item
                edsSpacing="md"
                title="{{ 'literature.lit-sticky-delete-button' | translate }}"
                (click)="deleteDocument(data.docId, $event)"
              >
              </eds-link-item>
            </td>
          </tr>
        </tbody>
      </table>
    </ng-container>
  </div>

  <div modal-footer>
    <div edsFlexbox justifyContent="flex-end">
      <button edsButton="secondary" size="normal" (click)="close()">
        <span>{{ "literature.lit-continue-browse" | translate }}</span>
      </button>
      <button edsButton="primary" size="normal" (click)="goToCart()">
        <span> {{ "literature.lit-view-cart" | translate }}</span>
      </button>
    </div>
  </div>
</eds-simple-modal>
