import {
  Directive,
  HostBinding,
  Input,
  OnInit,
  ElementRef,
} from '@angular/core';

/**
 * A convinence directive for adding a unique id to a div for marekto intergration.
 *
 * [See demo](../../?path=/story/directives-marketo--basic)
 *
 * Example:
 * ```html
 * <div ftMarketo="cparam">Content to replace</div>
 * ```
 */
@Directive({
  selector: '[ftMarketo]',
})
export class MarketoDirective implements OnInit {
  // Used to pass the cparams object
  @Input() ftMarketo: any;

  constructor(private el: ElementRef) {}

  ngOnInit(): void {
    // Read the marketo id from the cparams object
    if (this.ftMarketo.marketoId) {
      this.el.nativeElement.id = this.ftMarketo.marketoId;
    }
  }
}
