<ng-container [brManageMenuButton]="menuModel" *ngIf="isEditMode">
</ng-container>

<div *ngIf="isLoggedIn" edsGrid edsSpacing="xxl">
  <div edsgrid>
    <div class="container--wide">
      <div
        edsSubGrid="{{
          layoutConfig?.topsection?.length > 1 ? 'layout-3' : 'layout-0'
        }}"
        edsSubGridGap="md"
        edsSubGridRowGap="md"
        edsSpacing="md"
      >
        <ng-container
          [ngTemplateOutlet]="cards"
          [ngTemplateOutletContext]="{
            cardIds: layoutConfig?.topsection,
            area: 'topsection'
          }"
        ></ng-container>
      </div>

      <div edsSubGrid="layout-1" edsSubGridGap="md" edsSubGridRowGap="md">
        <!-- Column One-->
        <div>
          <ng-container
            [ngTemplateOutlet]="cards"
            [ngTemplateOutletContext]="{
              cardIds: layoutConfig?.leftcolumn,
              area: 'leftcolumn'
            }"
          ></ng-container>
        </div>
        <!-- Column Two-->
        <div>
          <ng-container
            [ngTemplateOutlet]="cards"
            [ngTemplateOutletContext]="{
              cardIds: layoutConfig?.rightcolumn,
              area: 'rightcolumn'
            }"
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Reuseable Templates-->
<ng-template #cards let-area="area" let-cardIds="cardIds">
  <ng-container *ngFor="let id of cardIds" [ngSwitch]="id">
    <!-- Fav Funds -->
    <ng-template
      *ngSwitchCase="'fav-funds'"
      [ngTemplateOutlet]="favFundsTemplate"
    ></ng-template>

    <ng-template
      *ngSwitchCase="'hypotheticals'"
      [ngTemplateOutlet]="hypotheticals"
    ></ng-template>

    <ng-template
      *ngSwitchCase="'accounts'"
      [ngTemplateOutlet]="accounts"
    ></ng-template>

    <ng-template
      *ngSwitchCase="'sales-team'"
      [ngTemplateOutlet]="salesTeam"
    ></ng-template>

    <ng-template
      *ngSwitchCase="'my-literature'"
      [ngTemplateOutlet]="literature"
    ></ng-template>

    <ng-container
      *ngSwitchCase="'pcs-widget'"
      [ngTemplateOutlet]="interactiveWidgets"
      [ngTemplateOutletContext]="{
        component: component,
        page: page
      }"
    ></ng-container>

    <ng-container
      *ngSwitchDefault
      [ngTemplateOutlet]="featureCard"
      [ngTemplateOutletContext]="{ id: id, area: area }"
    ></ng-container>
  </ng-container>
</ng-template>

<!-- My Funds -->
<ng-template #favFundsTemplate>
  <div [class.my-dashboard--grey]="background === 'grey'">
    <eds-card-dashboard
      [card]="favFundsCard"
      [fullHeight]="true"
      (menuItemSelectedWithIndexOutput)="selectedIndex($event)"
      (btnSubscribe)="openPPSSPage($event)"
    >
      <eds-card-dashboard-favorite-funds
        [tableProperties]="favFundsTableProperties"
        [favoriteFundData]="favFunds"
        (btnRemoveFund)="removeFavFunds($event)"
        [rightAlignLink]="true"
        [enableScrolling]="true"
      ></eds-card-dashboard-favorite-funds>
    </eds-card-dashboard>
  </div>
</ng-template>

<!-- Hypotheticals -->
<ng-template #hypotheticals>
  <div [class.my-dashboard--grey]="background === 'grey'">
    <eds-card-dashboard
      [card]="savedHypotheticalsCard"
      [fullHeight]="true"
      [isNewWindow]="true"
      [externalLink]="true"
      externalSuffix="leaving-tools"
    >
      <eds-card-dashboard-saved-hypotheticals
        [hypotheticalTableProperties]="hypotheticalTableProperties"
        [hypotheticalsData]="visibleHypotheticalsData"
        [isNewWindow]="true"
        [externalLink]="true"
        externalSuffix="leaving-tools"
        [enableScrolling]="true"
      >
      </eds-card-dashboard-saved-hypotheticals>
    </eds-card-dashboard>
  </div>
</ng-template>

<!-- My Literature -->
<ng-template #literature>
  <div [class.my-dashboard--grey]="background === 'grey'">
    <!-- Reuse Shared Cart Modal -->
    <ft-literature-dialog
      [dialogId]="ModalId['Cart_Modal']"
      [modalIdEnum]="modalId"
      (dialog)="closeDialog(modalId)"
      (deleteCartDoc)="deleteFromCart($event)"
      [gridData]="dialogData"
    ></ft-literature-dialog>

    <eds-card-dashboard
      [card]="literatureCard"
      [fullHeight]="true"
      (btnSubscribe)="openHistoryPage($event)"
    >
      <eds-card-dashboard-literature
        [literatureTableProperties]="literatureTableProperties"
        [toggleButtons]="toggleButtons"
        [documentData]="literatureDocumentData"
        (btnAddToCart)="addToCart($event)"
        (btnDownloadDocument)="downloadDocument($event)"
        [enableScrolling]="true"
      ></eds-card-dashboard-literature>
    </eds-card-dashboard>
  </div>
</ng-template>

<!-- Accounts -->
<ng-template #accounts>
  <div *ngIf="accountAccess" [class.my-dashboard--grey]="background === 'grey'">
    <eds-card-dashboard [card]="dashboardAccCard" [fullHeight]="true">
      <eds-card-dashboard-accounts
        [dashboardAccountLinks]="accLinksInfo"
        [dashboardAccountData]="accData"
      ></eds-card-dashboard-accounts>
    </eds-card-dashboard>
  </div>
</ng-template>

<!-- Sales Team -->
<ng-template #salesTeam>
  <div [class.my-dashboard--grey]="background === 'grey'">
    <ft-sales-team [parentLabels]="labels"></ft-sales-team>
  </div>
</ng-template>

<!-- Feature Card -->
<ng-template #featureCard let-area="area" let-id="id">
  <!-- Custom Cards -->
  <div [class.my-dashboard--grey]="background === 'grey'">
    <eds-card-dashboard
      *ngIf="featureCards[id]"
      [card]="featureCards[id]"
      [fullHeight]="area == 'topsection'"
    >
      <eds-media-card
        [isImg]="false"
        [isIcon]="true"
        [isStacked]="true"
        [imgSrc]="featureCards[id].imageSrc"
        [bodyContent]="featureCards[id].content"
        [linkText]="featureCards[id].link?.linkCollection[0]?.displayText"
        [url]="featureCards[id].link?.linkCollection[0]?.url"
        [isNewWindow]="
          featureCards[id].link?.linkCollection[0]?.target === '_blank'
        "
        [externalLink]="featureCards[id].link?.linkCollection[0]?.external"
      >
      </eds-media-card>
    </eds-card-dashboard>
  </div>
  <!-- Custom Cards -->
</ng-template>

<!-- Interactive widgets -->
<ng-template #interactiveWidgets let-component="component" let-page="page">
  <div edsSpacing="sm">
    <ft-interactive-widgets
      [myDashboardComponent]="component"
      [myDashboardPage]="page"
    ></ft-interactive-widgets>
  </div>
</ng-template>
<!-- Interactive widgets -->

<!-- No Document -->
<div *ngIf="!layoutConfig && isEditMode" class="no-document">
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>{{ componentLabel }}</strong>
    </div>
  </div>
</div>
