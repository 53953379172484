import { Component, OnInit } from '@angular/core';
import { Logger } from '@utils/logger';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { VideoTranscriptDetails } from '@types';

/**
 * Logger
 */
const logger = Logger.getLogger('VideoComponent');

/**
 * Video component
 *
 * This component is used at the top of a template.
 *
 * Selector: `ft-video`
 *
 * Exported as: `VideoComponent`
 */
@Component({
  selector: 'ft-video',
  templateUrl: './video.component.html',
})
export class VideoComponent extends AbstractBaseComponent implements OnInit {
  // Display Options
  paddingVar = 'var(--space-xxl) 0';
  isMultiPlayer = false;
  multipleVideoTranscriptDetails: VideoTranscriptDetails[] = [];
  transcriptTitle: string = null;
  public videoEmbedCode: string;
  public hasVideoShadow = false;
  /**
   * Constructor
   */
  constructor() {
    super();
  }
  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.isMultiPlayer = !!this.component.getModels()?.video?.isMultiPlayer;
    if (this.document) {
      this.populateTranscriptDetails();
    }
    this.getVideoEmbedandVideoShadow();
  }
  /**
   * Get the video and display video shadow  value
   */
  getVideoEmbedandVideoShadow() {
    this.videoEmbedCode = this.component.getModels()?.video?.script;
    if (!this.videoEmbedCode) {
      this.videoEmbedCode = 'Author component to enter video embed code';
    }
    this.hasVideoShadow = this.component.getModels()?.video?.displayVideoshadow;
  }

  /**
   * Populate Transcript title and datails.
   */
  populateTranscriptDetails() {
    const data = this.document?.getData();
    if (data && data?.titleBlock) {
      this.transcriptTitle = data?.displayName;
      if (data?.titleBlock?.length) {
        data.titleBlock.forEach((e: any) => {
          this.multipleVideoTranscriptDetails.push({
            id: e?.title,
            title: e?.title,
            content: e?.preTitle,
          });
        });
      }
    }
  }
}
