import { Component, HostListener, Input, OnInit } from '@angular/core';
import { checkLink } from '@utils/link-utils';
import { AnalyticsService } from '@frk/eds-components';
import { AppStateService } from '@services';
import { LinkService } from '@services/link.service';
interface TextBlockContent {
  content: string;
  type: 'IntroBlock' | 'ParagraphBlock';
}

@Component({
  selector: 'ft-text-block',
  templateUrl: './text-block.component.html',
})
export class TextBlockComponent implements OnInit {
  /**
   * Is Block in a Two Col layout
   */
  @Input()
  isTwoCol: boolean;

  /**
   * Content to render
   */
  @Input()
  content: TextBlockContent;

  /**
   * Column numbers
   */
  public colNumbers: any;

  /**
   * SpaBaseURL
   */
  public spaBaseURL: string;

  /**
   * Modified Links Content
   */
  public spaBaseURLContent: string;

  /**
   * Constructor
   */
  constructor(
    private analytics: AnalyticsService,
    private appStateService: AppStateService,
    private linkService: LinkService
  ) {}

  /**
   * On Init
   */
  ngOnInit(): void {
    if (!this.isTwoCol) {
      // 4 = 100%, 5 = 0.625%, 6 = 50%
      this.colNumbers = { s: 4, m: 5, l: 6 };
    } else {
      this.colNumbers = { s: 'auto', m: 'auto', l: 'auto' };
    }
    this.spaBaseURL = this.appStateService.getSpaBaseUrl();

    // WDE-341 Adding spaBaseURL to the links in the content
    if (this.spaBaseURL) {
      this.spaBaseURLContent = this.linkService.addSpaBaseURLToLinks(
        this.content.content,
        this.spaBaseURL
      );
    }
  }

  /**
   * Rich text links need handled globally to change them
   * to route correctly.
   *
   */
  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    const target: EventTarget = checkLink(event);
    const linkUrl: string =
      target instanceof HTMLAnchorElement ? target.href : '';

    if (
      target instanceof HTMLAnchorElement ||
      target instanceof HTMLButtonElement
    ) {
      // Track analytics events when link with pdf is clicked.
      if (linkUrl.includes('.pdf')) {
        const linkText: string = target.textContent;
        // Set event name to file_download
        this.analytics.trackEvent({
          event: 'file_download',
          detailed_event: 'Download Link Clicked',
          link_text: linkText,
          file_name: linkUrl.substring(linkUrl.lastIndexOf('/') + 1),
          link_url: linkUrl,
          event_data: {
            file_extension: 'pdf',
            file_lit_code: '',
            file_name: linkUrl.substring(linkUrl.lastIndexOf('/') + 1),
            link_url: linkUrl,
            location: '',
          },
        });
      }
    }
  }
}
