import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
// TODO: these services are imported individually until SignInservice is refactored to avoid circular dependency
import { AppStateService } from '@services/app-state.service';
import { ServerCookieService } from '@services/server-cookie.service';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LiteratureAPIMAPIService {
  constructor(
    private httpClient: HttpClient,
    private appStateService: AppStateService,
    private cookieService: ServerCookieService
  ) {}

  postWithToken(
    APIMKeyType: string,
    url: string,
    requestPayLoad: any = null,
    customRequestHeaders: any = null
  ): Observable<any> {
    return this.cookieService.getOauthToken$().pipe(
      switchMap((authToken) => {
        return this.httpClient.post<Observable<any>>(
          url,
          requestPayLoad,
          this.getHttpOptions(APIMKeyType, customRequestHeaders, authToken)
        );
      })
    );
  }

  postWithTokenAndCustomOptions(
    APIMKeyType: string,
    url: string,
    requestPayLoad: any = null,
    customRequestOptions: any = null,
    setAdditionalHeader: any = null
  ) {
    this.cookieService.getOauthToken$().pipe(
      switchMap((authToken) => {
        const literatureAPIMKey =
          APIMKeyType === 'nonAuth'
            ? this.appStateService.getLiteratureAPIMKey()
            : this.appStateService.getLiteratureAuthAPIAPIMKey();
        const requestHeaders = {
          'X-FT-API-KEY': `${literatureAPIMKey}`,
          'Content-Type': 'application/json',
          Authorization: authToken ? `Bearer ${authToken}` : '',
        };
        customRequestOptions.headers = new HttpHeaders({
          ...requestHeaders,
          ...setAdditionalHeader,
        });

        return this.httpClient.post<Observable<any>>(
          url,
          requestPayLoad,
          customRequestOptions
        );
      })
    );
  }

  getWithToken(
    APIMKeyType: string,
    url: string,
    customRequestHeaders: any = null
  ): Observable<any> {
    return this.cookieService.getOauthToken$().pipe(
      switchMap((authToken) => {
        return this.httpClient.get<Observable<any>>(
          url,
          this.getHttpOptions(APIMKeyType, customRequestHeaders, authToken)
        );
      })
    );
  }

  private getHttpOptions(
    APIMKeyType: string,
    customRequestHeaders: any,
    authToken: string
  ) {
    const literatureAPIMKey =
      APIMKeyType === 'auth'
        ? this.appStateService.getLiteratureAuthAPIAPIMKey()
        : this.appStateService.getLiteratureAPIMKey();
    const requestHeaders = {
      'X-FT-API-KEY': `${literatureAPIMKey}`,
      'Content-Type': 'application/json',
      Authorization: authToken ? `Bearer ${authToken}` : '',
    };
    const httpOptions = {
      headers: new HttpHeaders({ ...requestHeaders, ...customRequestHeaders }),
    };
    return httpOptions;
  }
}
