import { Component, OnInit, OnChanges, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { ResponsiveService } from '@frk/eds-components';

import {
  ExactMatch,
  FundThumbnail,
  FundItem,
} from '@search/interfaces/search.interface';

@Component({
  selector: 'ft-exact-match',
  templateUrl: './exact-match.component.html',
  styleUrls: ['./exact-match.component.scss'],
})
export class ExactMatchComponent implements OnInit, OnChanges {
  @Input() exactMatch: ExactMatch;
  /**
   * angular device detection
   */
  isHandheld$: Observable<boolean>;

  disclaimer =
    'The funds listed are for informational purposes only and should not be considered individual investment advice or a recommendation to invest.';

  fundsThumbnails: FundThumbnail[] = [];
  fundItems: FundItem[] = [];
  fundItemsGeneral: FundItem[] = [];

  hasFundsItems = false;

  constructor(public responsiveService: ResponsiveService) {}

  ngOnInit(): void {
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  ngOnChanges(): void {
    this.hasFundsItems =
      this.exactMatch.type === 'thematic_topic' &&
      this.exactMatch?.data[0]?.hits[0]?.columnCombos?.includes('Funds');
  }
}
