import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
} from '@angular/core';

import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Logger } from '@utils/logger';
import { Router } from '@angular/router';
import { ViewModeService } from '@services/view-mode.service';

/**
 * Logger
 */
const logger = Logger.getLogger('ImportantLegalComponent');

/**
 * Interface for the RichText Content
 */
interface RichTextContent {
  content: RichTextValue;
}

interface RichTextValue {
  value: string;
}

/**
 * FT Rich Text component
 *
 * This component is used at the top of a template and has it's own associated document type.
 *
 * Selector: `ft-important-legal`
 *
 * Exported as: `ImportantLegalComponent`
 */
@Component({
  selector: 'ft-important-legal',
  templateUrl: './important-legal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImportantLegalComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * Url of Image ref
   */
  imageUrl: string;

  /**
   * Constructor
   */
  constructor(private viewModeService: ViewModeService) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
  }

  /**
   * Get content from document
   */
  get content() {
    return this.document?.getData<RichTextContent>();
  }
}
