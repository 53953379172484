import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { TranslateService } from '@shared/translate/translate.service';
import {
  ProductDetailConfiguration,
  RiskStatisticsAnalysisState,
  RiskMeasuresState,
} from '@types';
import { Logger } from '@utils/logger';
import { Subscription } from 'rxjs';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { FundPerformanceDisplayComponent } from '../fund-performance-display-component.interface';
import { RiskMeasuresService } from '../services/risk-measures.service';
import { EfficiencyAnalysisService } from '../services/efficiency-analysis.service';
import { RISK__EFFICIENCY_ANALYSIS_INITIAL_STATE } from '../services/fund-performance.config';
import { SiteConfigService } from '@services';
import { cloneDeep } from '@apollo/client/utilities';

const logger = Logger.getLogger('RiskMeasuresComponent');

@Component({
  selector: 'ft-risk-measures',
  templateUrl: './risk-measures.component.html',
  styleUrls: ['./risk-measures.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RiskMeasuresComponent
  extends BaseProductDetailComponent
  implements FundPerformanceDisplayComponent, OnDestroy {
  dataSubscription: Subscription;
  riskEfficiencySubscription: Subscription;
  state: RiskMeasuresState;
  riskEfficiencyAnalysisState: RiskStatisticsAnalysisState;
  isPopulated: boolean;
  tooltips: { [name: string]: string } = {};
  performanceStatusCaveat: string;
  isBrazilSpecificLayout = false;

  constructor(
    private riskMeasuresService: RiskMeasuresService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef,
    private efficiencyAnalysisService: EfficiencyAnalysisService,
    private siteConfig: SiteConfigService
  ) {
    super();
  }

  populate(
    componentConfig: FundPerformanceComponentConfig,
    brConfig: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    this.setTooltips();
    if (this.brConfig.fundId) {
      this.riskMeasuresService.setConfigurationName(
        componentConfig.configurationName
      );
      this.dataSubscription = this.riskMeasuresService.riskMeasureStateSubject.subscribe(
        (newState: RiskMeasuresState) => {
          if (newState.isError) {
            this.isPopulated = false;
          } else {
            this.mapState(newState);
          }
        }
      );

      this.riskMeasuresService.populate(brConfig);
      if (this.siteConfig.isBrazil()) {
        this.populateEfficiencyAnalysis(brConfig);
        this.isBrazilSpecificLayout = true;
      }
    }
    this.cdr.detectChanges();
  }

  private setTooltips() {
    this.tooltips[
      'products.risk-measures-section'
    ] = this.translateService.tooltip('products.risk-measures-section');
    this.tooltips[
      'products.risk-measures-statistics'
    ] = this.translateService.tooltip('products.risk-measures-statistics');
    this.tooltips[
      'products.risk-measures-standard-deviation'
    ] = this.translateService.tooltip(
      'products.risk-measures-standard-deviation'
    );
    this.tooltips[
      'products.risk-measures-sharpe-ratio'
    ] = this.translateService.tooltip('products.risk-measures-sharpe-ratio');
  }

  /**
   * Called when state changes.
   * @param riskMeasuresState the new state returned by distribution service
   */
  mapState(newState: RiskMeasuresState) {
    this.performanceStatusCaveat = newState.perfStatusCaveat;
    if (
      newState &&
      (newState.statistics?.length > 0 ||
        newState.stdDev?.length > 0 ||
        newState.sharpeRatio?.length > 0)
    ) {
      this.state = newState;
      this.state.statistics?.forEach((stats) => {
        this.tooltips[stats.tooltip] = this.translateService.tooltip(
          stats.tooltip
        );
      });
      if (this.state.hideRiskStatisticsBenchmark) {
        let clonedData = cloneDeep(this.state.sharpeRatio);
        this.state.sharpeRatio = clonedData.filter(
          (data) => data.rmName.trim() !== this.state.benchmarkName.trim()
        );
        clonedData = cloneDeep(this.state.stdDev);
        this.state.stdDev = clonedData.filter(
          (data) => data.rmName.trim() !== this.state.benchmarkName
        );
      }

      this.isPopulated = true;
    }
    this.cdr.detectChanges();
  }

  populateEfficiencyAnalysis(brConfig: ProductDetailConfiguration) {
    this.riskEfficiencySubscription = this.efficiencyAnalysisService.riskEfficiencyAnalysis$.subscribe(
      (newState: RiskStatisticsAnalysisState) => {
        this.mapRiskStatsticsState(newState);
      }
    );
    this.efficiencyAnalysisService.populate(brConfig);
  }

  mapRiskStatsticsState(newState: RiskStatisticsAnalysisState) {
    if (newState && !newState.isError && newState?.efficiencyAnalysis?.length) {
      this.riskEfficiencyAnalysisState = { ...newState };
    } else {
      this.riskEfficiencyAnalysisState = RISK__EFFICIENCY_ANALYSIS_INITIAL_STATE;
      this.riskEfficiencyAnalysisState.isError = true;
    }

    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    // clean up subscription to avoid memory leaks
    this.dataSubscription?.unsubscribe();
  }
}
