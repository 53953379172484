import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { WindowService } from '@frk/eds-components';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

/**
 * A  directive for applying stickyHeader to a ag-grid-angular.
 * Example:
 * ```html
 *   <ag-grid-angular
 * stickyHeader
 * ...../>
 * ```
 */
@Directive({
  selector: '[stickyHeader]',
})
export class StickyHeaderDirective implements AfterViewInit, OnDestroy {
  /**
   * sets the button type
   */
  @Input() stickyHeader: boolean;

  private headerElementRef;
  private headerRowElementRef;
  private bodyPortElementRef;
  private headerContainerRef;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private windowService: WindowService
  ) {}
  ngAfterViewInit() {
    this.headerElementRef = this.el.nativeElement.querySelector('.ag-header');
    this.headerContainerRef = this.el.nativeElement.querySelector(
      '.ag-header-container'
    );

    // NGC-13630 : Removed HostListener and added windowService
    this.windowService
      .getScrollObs$(100)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.headerElementRef) {
          this.headerRowElementRef = this.el.nativeElement.querySelectorAll(
            '.ag-header-row-column'
          )[1];
          this.bodyPortElementRef = this.el.nativeElement.querySelector(
            '.ag-body-viewport'
          );
          const headerRowTop = this.headerRowElementRef.style.top;
          const headerRowValue = -this.headerRowElementRef.clientHeight;
          if (
            this.headerElementRef.getBoundingClientRect().top <=
              headerRowValue &&
            this.bodyPortElementRef.getBoundingClientRect().top >
              -this.bodyPortElementRef.getBoundingClientRect().height
          ) {
            this.renderer.setStyle(
              this.headerContainerRef,
              'position',
              'fixed'
            );
            this.renderer.setStyle(
              this.headerContainerRef,
              'top',
              '-' + headerRowTop
            );

            this.renderer.setStyle(this.headerElementRef, 'display', 'block');
            this.renderer.setStyle(
              this.headerRowElementRef,
              'background',
              'white'
            );
            this.renderer.setStyle(this.headerContainerRef, 'height', '0');
          }
          if (
            this.el.nativeElement.getBoundingClientRect().top >
              headerRowValue ||
            this.bodyPortElementRef.getBoundingClientRect().top <
              -this.bodyPortElementRef.getBoundingClientRect().height
          ) {
            this.renderer.setStyle(
              this.headerContainerRef,
              'position',
              'relative'
            );
            this.renderer.setStyle(this.headerContainerRef, 'top', 0);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
