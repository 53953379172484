import {
  Component,
  Input,
  Output,
  OnDestroy,
  EventEmitter,
  Inject,
} from '@angular/core';
import {
  AnalyticsService,
  SearchServiceToken,
  ToggleButton,
} from '@frk/eds-components';
import {
  ResponseHits,
  SEARCH_RESULTS,
} from '@search/interfaces/search.interface';
import { FtSearchService } from '@search/services/ftsearch.service';
import { FullResultsCommonService } from '../full-results/full-results-common.service';

@Component({
  selector: 'ft-search-tabs',
  templateUrl: './search-tabs.component.html',
})
export class SearchTabsComponent implements OnDestroy {
  @Input() updatedQueryText: string;
  @Input() activeTab = 'all';
  @Input() results: []; // TODO - simplify this - pass only items for tabs

  @Input() toggleButtons: ToggleButton[];
  @Output() tabsChanged = new EventEmitter<string>();

  // version of API read from searchService
  apiVer: string;
  loading: boolean;

  constructor(
    private analyticsService: AnalyticsService,
    @Inject(SEARCH_RESULTS) private commonDataService: FullResultsCommonService,
    @Inject(SearchServiceToken) private searchService: FtSearchService
  ) {
    this.searchService.loading$.subscribe((loading: boolean) => {
      this.loading = loading;
    });

    this.searchService.apiVer$.subscribe((apiVer: string) => {
      this.apiVer = apiVer;
    });
  }
  onTabChange(selectedTab: ToggleButton) {
    this.tabsChanged.emit(selectedTab.value);
    const resultCount = this.commonDataService
      .getTotalIetmsCount(selectedTab)
      .toString();
    this.analyticsService.trackEvent({
      event: 'search_tab_change',
      detailed_event: 'Search Tab Change',
      search_category: selectedTab.value,
      search_term: this.updatedQueryText,
      search_num_results: resultCount,
      event_data: {
        search_category: selectedTab.value,
        search_term: this.updatedQueryText,
        search_num_results: resultCount,
      },
    });
  }

  // TODO - this must be moved to service extracting data
  getTotal(response: ResponseHits, type: string) {
    switch (type) {
      case 'funds':
        return response.total;
      case 'literature':
        return response.total.value;
      case 'pages':
        return response.total.value;
    }
  }

  /**
   * unsubscribe on componend destroy
   */
  ngOnDestroy() {
    // this.ftsearch.loading$.unsubscribe();
    // this.ftsearch.apiVer$.unsubscribe();
  }
}
