<div edsRow>
  <div edsCol>
    <eds-pull-quote-avatar
      [pqBody]="content?.quote"
      [hasFooter]="true"
      [pqImgSrc]="content?.profileImageUrl"
      [pqTitle]="content?.quoteName"
      [pqText1]="content?.job"
      [pqText2]="content?.job2"
    >
    </eds-pull-quote-avatar>
  </div>
</div>
