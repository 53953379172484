<eds-simple-modal
  [modalId]="simpleModalId"
  [showClose]="false"
  [attr.aria-label]="'signin.close' | translate"
  role="dialog"
>
  <div modal-header>
    <div [innerHTML]="confirmationHeader"></div>
  </div>
  <div modal-body>
    <div [innerHTML]="confirmationBody"></div>
  </div>

  <div modal-footer>
    <div edsFlexbox justifyContent="space-between">
      <button edsButton="secondary" size="normal" (click)="signOut()">
        <span>{{ "signin.signOut" | translate }}</span>
      </button>
      <button edsButton="primary" size="normal" (click)="close()">
        <span> {{ "signin.stay-signed-in" | translate }}</span>
      </button>
    </div>
  </div>
</eds-simple-modal>
