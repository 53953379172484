import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ClassExclusionService {
  /**
   * Returns true if clicked object dos not contain specific class which need to be excluded from analytics tracking.
   * @param classList - Class List from clicked object
   */
  classExclusion(classList: DOMTokenList): boolean {
    // List of classes excluded from analytics tracking.
    const classesToExclude = ['vjs-big-play-button', 'ft-button-block'];
    return (
      classesToExclude.find((cssClass) => {
        return classList.contains(cssClass);
      }) === undefined
    );
  }
}
