<eds-panel
  edsSpacing="sm"
  [hasBorders]="true"
  [hasBorderRadius]="false"
  [hasPadding]="true"
  *ngFor="let row of infoItems"
>
  <eds-info-table-selection
    *ngFor="let info of row"
    edsSpacing="sm"
    [info]="info"
  ></eds-info-table-selection>
</eds-panel>
