<div edsRow>
  <div edsCol>
    <ng-container *ngIf="content?.title">
      <eds-title
        [title]="content.title"
        size="h5"
        edsSpacing="md"
        theme="basic"
        [isLight]="true"
      >
      </eds-title>
    </ng-container>
    <ng-container *ngIf="content?.summary">
      <div class="ck-editor" edsSpacing="md">
        <div [innerHTML]="content.summary"></div>
      </div>
    </ng-container>

    <div
      *ngIf="content.imageProperties; else stdImg"
      class="image-box"
      edsFlexbox
      alignItems="center"
      justifyContent="center"
    >
      <eds-image
        [imgSrc]="content.imageSrc"
        [altText]="content.altText"
        [ngStyle]="imageStyle"
      ></eds-image>
    </div>

    <ng-template #stdImg>
      <div class="image-block__standard-image">
        <eds-image
          [imgSrc]="content.imageSrc"
          [altText]="content.altText"
        ></eds-image>
      </div>
    </ng-template>

    <ng-container *ngIf="content?.source">
      <div class="ck-editor text-dark-grey" edsPadding edsPaddingTop="md">
        <small>
          <div [innerHTML]="content.source"></div>
        </small>
      </div>
    </ng-container>
  </div>
</div>
