import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

/**
 * utils for creating new moment locales
 * @see https://momentjs.com/docs/#/i18n/
 */

/**
 * clone a moment locale
 * @param parentLocale (note parent is needed to be imported in)
 */
export function cloneMomentLocale(
  childLocale: string,
  parentLocale: string
): void {
  import('dayjs/locale/' + parentLocale).then((localeData) => {
    dayjs.locale(childLocale, { ...localeData });
    const cloneConfig = {
      childLocale,
      parentLocale,
    };
    const clonedMomentLocale = dayjs.updateLocale(childLocale, cloneConfig);
    // TODO: return statement doesn't do anything, as it's inside the callback function
    return clonedMomentLocale;
  });
}

// TODO: not used anywhere?
/**
 * update a existing moment locale
 */
export function updateMomentLocale(localeToUpdate: string, changeConfig) {
  import('dayjs/locale/' + localeToUpdate).then((localeData) => {
    dayjs.locale(localeToUpdate, { ...localeData });
    const updatedMomentLocaleDay = dayjs.updateLocale(
      localeToUpdate,
      changeConfig
    );
  });
  const updatedMomentLocale = dayjs.updateLocale(localeToUpdate, changeConfig);
  return updatedMomentLocale;
}
