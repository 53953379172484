import {
  IdentifierKey,
  Identifiers,
  Performance,
  PerformanceDetail,
  PerformanceStatus,
  ShareClass,
} from '@models';
import {
  CurrencyCode,
  FundId,
  FundIdentifier,
  FundShareClassId,
  ProductType,
  ShareClassCode,
  ShareClassDTO,
  WebProductTaxonomy,
} from '@types';
import { NA, N_A, SINGLECLASS } from '../constants/product.constants';
import { BMAssocsMapper } from './benchmark-associations.type.mapper';
import { BMPerformanceMapper } from './benchmark-performance.type.mapper';
import { ChargesMapper } from './charges.type.mapper';
import { DistributionsMapper } from './distribution.type.mapper';
import { ExchangesMapper } from './exchanges.type.mapper';
import { IdentifiersMapper } from './identifiers.type.mapper';
import { MapperFactory } from './mapper-factory';
import { NavMapper } from './nav.type.mapper';
import { PerformanceMapper } from './performance.type.mapper';
import { PremiumDiscountElevatedMapper } from './premium-discount-elevated.type.mapper';
import { RatingsMapper } from './ratings.type.mapper';
import { RiskStatisticsMapper } from './risk-statistics.mapper';
import { RiskStatsSummariesMapper } from './risk-stats-summaries.type.mapper';
import { SalesChargeBreakPointsMapper } from './sales-charge-break-points.type.mapper';
import { Mapper, MapperParams } from './type.mapper';
import { UsTaxMapper } from './us-tax.type.mapper';
import { YieldsMapper } from './yields.type.mapper';

// determines if product has preliminary data in PerformanceDetail
// NB: only applies to SMAs
// TODO: for now, just check quarterEnd data, as that's what existing code does
// can uncomment monthEnd check later if needed
const hasPreliminaryPerformanceData = (performance: Performance): boolean =>
  performance.quarterEnd?.some(
    (detail: PerformanceDetail): boolean =>
      detail.performanceStatus === PerformanceStatus.PRELIM
  );
// ) || performance.monthEnd?.some(
//   (detail: PerformanceDetail): boolean =>
//     detail.performanceStatus === PerformanceStatus.PRELIM
// );

export class ShareClassMapper extends Mapper<ShareClass> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    shareClassDto: ShareClassDTO,
    productType: ProductType,
    productTaxonomy: WebProductTaxonomy,
    fundCurrencyCode?: string,
    fundName?: string,
    activeList?: string[],
    overrideFlag?: boolean
  ): ShareClass {
    let shareClass: ShareClass;
    const fundId = shareClassDto.identifiers?.fundid as FundId;
    const shareClassCode = shareClassDto.identifiers
      ?.shclcode as ShareClassCode;
    const shareClassKey = (shareClassCode
      ? `${fundId}-${shareClassCode}`
      : fundId) as FundShareClassId;
    const currencyCode = this.mapperParams?.useFundCurrencyCode
      ? fundCurrencyCode
      : shareClassDto?.shclcurr;
    const defaultIdentifierKey: IdentifierKey = this.mapperParams.config.getDefaultIdentifierKey(
      productTaxonomy
    );
    const identifiers: Identifiers = shareClassDto.identifiers
      ? this.createMapper(IdentifiersMapper).toDomain(shareClassDto.identifiers)
      : null;
    let defaultIdentifier: FundIdentifier;

    // WDE-5664: Display 'ticker' in fund URL for "CA-ETF" just like non-SINGLCLASS shareclasses.
    //           Intentionally hardcoded [Ref. BA] as a workaround, as there is taxonomy mismatch between BR and PDS.
    if (
      shareClassDto.etfclass?.toLowerCase() === 'y' &&
      this.mapperParams.config.getPdsCountry() === 'CA'
    ) {
      defaultIdentifier = shareClassDto.identifiers.ticker as FundIdentifier;
    } else {
      if (identifiers) {
        defaultIdentifier = defaultIdentifierKey
          ? (identifiers[defaultIdentifierKey] as FundIdentifier)
          : null;
      } else {
        defaultIdentifier = null;
      }
    }

    if (overrideFlag && activeList.includes(shareClassKey)) {
      shareClass = this.mapShareClass(
        shareClassKey,
        identifiers,
        shareClassDto,
        productType,
        currencyCode,
        shareClassCode,
        defaultIdentifier,
        fundId,
        fundName
      );
    }
    if (
      this.mapperParams.config.isDisplayedShareClass(
        shareClassKey,
        this.mapperParams.includeSoftClosed
      ) &&
      !overrideFlag
    ) {
      shareClass = this.mapShareClass(
        shareClassKey,
        identifiers,
        shareClassDto,
        productType,
        currencyCode,
        shareClassCode,
        defaultIdentifier,
        fundId,
        fundName
      );
    }
    return shareClass;
  }

  mapShareClass(
    shareClassKey: FundShareClassId,
    identifiers: Identifiers,
    shareClassDto: ShareClassDTO,
    productType: ProductType,
    currencyCode: string,
    shareClassCode: ShareClassCode,
    defaultIdentifier: FundIdentifier,
    fundId: FundId,
    fundName: string
  ): ShareClass {
    const performance: Performance = shareClassDto.performance
      ? this.createMapper(PerformanceMapper).toDomain(shareClassDto.performance)
      : null;
    return {
      identifiers,
      fundShareClassId: shareClassKey,
      shareClassName: shareClassDto.shclname,
      primaryShareClass: shareClassDto.prmryshclind === 'Yes',
      inceptionDate: shareClassDto.incepdt,
      inceptionDateStd: shareClassDto.incepdtstd,
      performanceInceptionDate: shareClassDto.perfincdt,
      performanceInceptionDateStd: shareClassDto.perfincdtstd,
      charges: shareClassDto.charges
        ? this.createMapper(ChargesMapper).toDomain(shareClassDto.charges)
        : null,
      nav: shareClassDto.nav
        ? this.createMapper(NavMapper).toDomain(shareClassDto.nav)
        : null,
      performance,
      benchmarkAssociation: shareClassDto.bmassoc
        ? this.createMapper(BMAssocsMapper).toDomain(shareClassDto.bmassoc)
        : null,
      benchmarkPerformance: shareClassDto.bmperformance
        ? this.createMapper(BMPerformanceMapper).toDomain(
            shareClassDto.bmperformance
          )
        : null,
      distribution: shareClassDto.distribution
        ? this.createMapper(DistributionsMapper).toDomain(
            shareClassDto.distribution
          )
        : null,
      isSoftLaunch: this.mapperParams.config.isSoftLaunchShareClass(
        shareClassKey
      ),
      shareClassCurrency: currencyCode as CurrencyCode,
      shareClassFeeStructure: shareClassDto.shclfeestructr as ShareClassCode,
      shareClassCode,
      yields: shareClassDto.yields
        ? this.createMapper(YieldsMapper).toDomain(shareClassDto.yields)
        : null,
      ratings: shareClassDto.ratings
        ? this.createMapper(RatingsMapper).toDomain(shareClassDto.ratings)
        : null,
      // NB: links will only be generated if you pass a valid productType
      link: productType
        ? this.mapperParams.config.getFundLink(
            fundId,
            shareClassCode,
            fundName,
            defaultIdentifier
          )
        : null,
      previewLink: productType
        ? this.mapperParams.config.getFundLink(
            fundId,
            shareClassCode,
            fundName,
            defaultIdentifier,
            true // isLitPreview link
          )
        : null,
      sectionLinks: productType
        ? this.mapperParams.config.getFundSectionLinks(
            fundId,
            shareClassCode,
            fundName,
            defaultIdentifier
          )
        : null,
      distributionIndicator: shareClassDto.distributionindicator
        ? shareClassDto.distributionindicator
        : null,
      dividendFrequency: shareClassDto.dividendfreq
        ? shareClassDto.dividendfreq
        : null,
      capgainFrequency: shareClassDto.capgainfreq
        ? shareClassDto.capgainfreq
        : null,
      riskFactor: shareClassDto.srrivalue ? shareClassDto.srrivalue : null,
      riskStatsSummary: shareClassDto.riskstatssummary
        ? this.createMapper(RiskStatsSummariesMapper).toDomain(
            shareClassDto.riskstatssummary
          )
        : null,
      riskStatistics: shareClassDto.riskstatistics
        ? this.createMapper(RiskStatisticsMapper).toDomain(
            shareClassDto.riskstatistics
          )
        : null,
      exchanges: shareClassDto.exchanges
        ? this.createMapper(ExchangesMapper).toDomain(shareClassDto.exchanges)
        : null,
      salesChargeBreakPoints: shareClassDto.saleschargebreakpoints
        ? this.createMapper(SalesChargeBreakPointsMapper).toDomain(
            shareClassDto.saleschargebreakpoints
          )
        : null,
      tax: shareClassDto.tax
        ? this.createMapper(UsTaxMapper).toDomain(shareClassDto.tax)
        : null,
      federalTaxId: shareClassDto.federaltaxid
        ? shareClassDto.federaltaxid
        : '',
      premiumDiscountElevated: shareClassDto.premiumdiscountanalysis
        ? this.createMapper(PremiumDiscountElevatedMapper).toDomain(
            shareClassDto.premiumdiscountanalysis
          )
        : null,
      isSoftClosed: this.mapperParams.config.isSoftClosedShareClass(
        shareClassKey
      ),
      isSingleShareClass:
        shareClassDto.identifiers?.shclcode === SINGLECLASS ||
        shareClassDto.identifiers?.shclcode === NA ||
        // FIX - NGC-5781:
        // Added below shareClassName condition as well.
        // Since, code and name can be different in case, singlclass.
        shareClassDto.shclname?.toLocaleLowerCase() ===
          SINGLECLASS.toLocaleLowerCase() ||
        shareClassDto.shclname?.toLocaleLowerCase() === N_A.toLocaleLowerCase(),
      productType: shareClassDto.producttype as ProductType,
      isEtfClass: shareClassDto.etfclass
        ? shareClassDto.etfclass.toLowerCase() === 'y'
        : null,
      hasPreliminaryPerformanceStatus: performance
        ? hasPreliminaryPerformanceData(performance)
        : false,

      riskClassificationText: shareClassDto.riskclassificationtxt
        ? shareClassDto.riskclassificationtxt
        : null,
    };
  }
}
