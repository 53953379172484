import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { PerformanceView, ProductDetailConfiguration } from '@products/types';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import {
  FundPerformanceCumulativeHistoricalData,
  FundPerformanceCumulativePeriodEndData,
  FundPerformanceCumulativeState,
  FundPerformanceCumulativeStateConfig,
  PerformanceDateRange,
  PerformanceDateRangeSelection,
} from '@products/types/performance.type';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { TranslateService } from '@shared/translate/translate.service';
import { FundPerformanceCumulativeService } from '../services/fund-performance-cumulative.service';
import cloneDeep from 'lodash/cloneDeep';
import dayjs from 'dayjs';
import {
  AnalyticsService,
  DuetDatePickerErrorMsg,
  ISOYearMonth,
  ToggleButton,
} from '@frk/eds-components';
import {
  ANALYTICS_DATE_EVENT,
  ANALYTICS_DATE_FORMAT,
  ANALYTICS_DETAILED_DATE_EVENT,
  DATE_FORMAT_STD,
  DATE_FORMAT_YEAR_MONTH_STD,
} from '@utils/app.constants';
import {
  getDatePickerErrorMsg,
  momentMonthYearFormat,
} from '@utils/il8n/moment-il8n';
import { PERIODS_MAPPER } from '../services/fund-performance.config';

const logger = Logger.getLogger('FundPerformanceCumulativeComponent');

@Component({
  selector: 'ft-fund-performance-cumulative',
  templateUrl: './fund-performance-cumulative.component.html',
  styleUrls: ['./fund-performance-cumulative.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundPerformanceCumulativeComponent
  extends BaseProductDetailComponent
  implements OnDestroy {
  dataSubscription: Subscription;
  isError = false;
  fundPerformanceData: FundPerformanceCumulativePeriodEndData;
  historicalFundPerformanceData: FundPerformanceCumulativeHistoricalData;
  historicalFundPerformanceDateRange: PerformanceDateRange;
  fundPerformanceConfig: FundPerformanceCumulativeStateConfig;
  periodEndToggle: ToggleButton[];
  chartCalculationToggle: ToggleButton[];
  historicalPeriodEndToggle: ToggleButton[];
  selectedDate: string;
  performanceStatusCaveat: string;
  heading: string;
  tooltips: { [name: string]: string } = {};
  @Input() isdatePickerAnalyticsEnabled = false; // For Disabling Analytics for Date Selection

  public datePickerFormat: string = momentMonthYearFormat();
  public errorMsgObj: DuetDatePickerErrorMsg;
  public minDateStd: ISOYearMonth;
  public maxDateStd: ISOYearMonth;
  public selectedDateStd: ISOYearMonth;

  constructor(
    private fundPerformanceService: FundPerformanceCumulativeService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private analytics: AnalyticsService
  ) {
    super();
    this.errorMsgObj = getDatePickerErrorMsg(this.translateService);
  }

  populate(
    componentConfig: FundPerformanceComponentConfig,
    brConfig: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    this.setTooltips();
    if (this.brConfig.fundId) {
      this.fundPerformanceService.setConfigurationName(
        componentConfig.configurationName
      );
      this.dataSubscription = this.fundPerformanceService.fundPerformanceState$.subscribe(
        (newState: FundPerformanceCumulativeState) => {
          if (newState.isError) {
            this.isError = true;
          } else {
            this.mapState(newState);
          }
          this.changeDetector.detectChanges();
        }
      );
      this.fundPerformanceService.populate(brConfig);
    }
    this.changeDetector.detectChanges();
  }

  private setTooltips() {
    this.tooltips[
      'products.cumulative-total-returns-section'
    ] = this.translateService.tooltip(
      'products.cumulative-total-returns-section'
    );
  }

  onDateRangeSelection(dateRangeSelection: PerformanceDateRangeSelection) {
    this.fundPerformanceService.populateCustomChartData(
      dateRangeSelection.fromDate,
      dateRangeSelection.toDate
    );
  }

  /**
   * Called when state changes.
   * @param fundPerformanceState the new state returned by fund performance service
   */
  private mapState(fundPerformanceState: FundPerformanceCumulativeState) {
    this.performanceStatusCaveat = fundPerformanceState.perfStatusCaveat;
    this.heading = fundPerformanceState.heading;
    if (fundPerformanceState.config.periodEnd === PerformanceView.QUARTERLY) {
      this.fundPerformanceData = fundPerformanceState.data.quarterEnd;
    } else {
      this.fundPerformanceData = fundPerformanceState.data.monthEnd;
    }
    this.fundPerformanceData = cloneDeep(this.fundPerformanceData);
    this.fundPerformanceConfig = fundPerformanceState.config;
    this.periodEndToggle = this.getPeriodEnds(
      fundPerformanceState,
      fundPerformanceState.config.periodEnd
    );
    this.chartCalculationToggle = this.getchartCalculations(
      fundPerformanceState
    );
    this.setHistoricalData(fundPerformanceState);
  }

  private setHistoricalData(
    fundPerformanceState: FundPerformanceCumulativeState
  ) {
    if (
      fundPerformanceState.config.historicalPeriodEnd ===
      PerformanceView.QUARTERLY
    ) {
      this.historicalFundPerformanceData = fundPerformanceState.data.quarterEnd;
      this.historicalFundPerformanceDateRange =
        fundPerformanceState.data.quarterEnd.performanceDateRange;
      this.selectedDate = this.historicalFundPerformanceDateRange?.maxDate;
    } else if (
      fundPerformanceState.config.historicalPeriodEnd === PerformanceView.CUSTOM
    ) {
      this.historicalFundPerformanceData =
        fundPerformanceState.data.historicalData;
    } else {
      this.historicalFundPerformanceData = fundPerformanceState.data.monthEnd;
      this.historicalFundPerformanceDateRange =
        fundPerformanceState.data.monthEnd.performanceDateRange;
      this.selectedDate = this.historicalFundPerformanceDateRange?.maxDate;
    }
    this.minDateStd = dayjs(
      this.historicalFundPerformanceDateRange?.minDate,
      DATE_FORMAT_STD
    ).format(DATE_FORMAT_YEAR_MONTH_STD) as ISOYearMonth;
    this.maxDateStd = dayjs(
      this.historicalFundPerformanceDateRange?.maxDate,
      DATE_FORMAT_STD
    ).format(DATE_FORMAT_YEAR_MONTH_STD) as ISOYearMonth;
    this.selectedDateStd = dayjs(this.selectedDate, DATE_FORMAT_STD).format(
      DATE_FORMAT_YEAR_MONTH_STD
    ) as ISOYearMonth;

    this.historicalPeriodEndToggle = this.getPeriodEnds(
      fundPerformanceState,
      fundPerformanceState.config.historicalPeriodEnd
    );
  }

  private getPeriodEnds(
    fundPerformanceState: FundPerformanceCumulativeState,
    activePeriodEnd: string
  ): ToggleButton[] {
    return fundPerformanceState.config.periodEnds
      .map((periodEnd) => {
        const period = PERIODS_MAPPER[periodEnd];
        const hasData = fundPerformanceState.data[period].tableData.some(
          (data) => data !== undefined
        );
        if (hasData) {
          return {
            text: this.translateService.instant(
              `products.${periodEnd}`,
              this.brConfig.fundId
            ),
            active: periodEnd === activePeriodEnd,
            value: periodEnd,
          };
        }
      })
      .filter((period: ToggleButton) => period !== undefined);
  }

  private getchartCalculations(
    fundPerformanceState: FundPerformanceCumulativeState
  ): ToggleButton[] {
    return fundPerformanceState.config.chartCalculationOptions.map(
      (chartCalculation) => {
        return {
          text: this.translateService.instant(
            `products.${chartCalculation}`,
            this.brConfig.fundId
          ),
          active:
            this.fundPerformanceConfig?.chartCalculation === chartCalculation,
          value: chartCalculation,
        };
      }
    );
  }

  /**
   * Update the performance view as per type selected.
   */
  onPeriodEndUpdate(selectedToggleOption: ToggleButton) {
    this.fundPerformanceService.setPeriodEnd(selectedToggleOption.value);
  }

  onHistoricalPeriodEndUpdate(selectedToggleOption: ToggleButton) {
    this.fundPerformanceService.setHistoricalPeriodEnd(
      selectedToggleOption.value
    );
  }

  onChartCalculationUpdate(selectedToggle: ToggleButton) {
    this.fundPerformanceService.setChartCalculation(selectedToggle.value);
  }

  onHistoricalDateSelection(selectedMonth: ISOYearMonth) {
    const date = dayjs(selectedMonth, DATE_FORMAT_YEAR_MONTH_STD);
    this.selectedDate = date.format();
    this.selectedDateStd = dayjs(this.selectedDate, DATE_FORMAT_STD).format(
      DATE_FORMAT_YEAR_MONTH_STD
    ) as ISOYearMonth;
    this.fundPerformanceService.populateHistoricalData(this.selectedDate);
    if (this.isdatePickerAnalyticsEnabled) {
      this.isdatePickerAnalyticsEnabled = false;
      this.analytics.trackEvent({
        event: ANALYTICS_DATE_EVENT,
        detailed_event: ANALYTICS_DETAILED_DATE_EVENT,
        action: 'Start Date',
        label: `${date.format(ANALYTICS_DATE_FORMAT)}`,
        event_data: {
          date_type: ANALYTICS_DATE_FORMAT,
          date_value: `${date.format(ANALYTICS_DATE_FORMAT)}`,
        },
      });
    }
  }

  // enable date picker analytics once user change the date
  onOpenMonthPicker(value: string) {
    if (value === 'open') {
      this.isdatePickerAnalyticsEnabled = true;
    }
  }

  ngOnDestroy() {
    // clean up subscription to avoid memory leaks
    this.dataSubscription?.unsubscribe();
  }
}
