import {
  SpacingModule,
  GridModule,
  TitleModule,
  ButtonModule,
  AvatarModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AvatarBlockComponent } from './avatar-block.component';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [AvatarBlockComponent],
  imports: [
    AvatarModule,
    BrSdkModule,
    ButtonModule,
    CommonModule,
    SpacingModule,
    GridModule,
    TitleModule,
    PipesModule,
  ],
  exports: [AvatarBlockComponent],
  entryComponents: [AvatarBlockComponent],
})

/**
 * Avatar Block Module
 */
export class AvatarBlockModule {}
