<!-- Main content -->
<main edsSpacing="lg">
  <ng-container brComponent="content-container"></ng-container>
  <!-- Lead content (full width content) -->
  <div>
    <ng-container brComponent="lead-content"></ng-container>
  </div>

  <!-- set z-index: 4; so sticky tabs are above ag grids -->
  <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
    <ng-container brComponent="title-content"></ng-container>
  </div>

  <ng-container [ngSwitch]="contentWidth">
    <!-- 8 col -->
    <ng-container *ngSwitchCase="8">
      <div edsGrid>
        <div class="container">
          <ng-container [ngTemplateOutlet]="content"> </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- 10 col -->
    <ng-container *ngSwitchCase="10">
      <div edsGrid>
        <div class="container--wide">
          <ng-container [ngTemplateOutlet]="content"> </ng-container>
        </div>
      </div>
    </ng-container>

    <!-- 12 col -->
    <ng-container *ngSwitchCase="12">
      <ng-container [ngTemplateOutlet]="content"> </ng-container>
    </ng-container>
  </ng-container>

  <ng-template #content>
    <div edsRow edsSpacing="lg">
      <div edsCol edsSpacing="lg">
        <ng-container brComponent="main-content"></ng-container>
      </div>
    </div>
  </ng-template>
</main>

<ng-container brComponent="lower-content"></ng-container>

<div edsGrid>
  <div class="container--wide">
    <div edsRow>
      <div edsCol>
        <ng-container brComponent="lower-content-narrow-width"></ng-container>
      </div>
    </div>
  </div>
</div>
