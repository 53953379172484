import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  AnalyticsService,
  ClearbridgeHighchartsThemeService,
  CpreifHighchartsThemeService,
  CustomDimensionsService,
  EPHighchartsThemeService,
  FootnoteServiceToken,
  FrkHighchartsThemeService,
  FrkSkyHighchartsThemeService,
  HighchartsThemeService,
  NJBestHighchartsThemeService,
  MartinCurrieHighchartsThemeService,
  BrandywineGlobalHighchartsThemeService,
  ProximalServiceToken,
} from '@frk/eds-components';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { SAVER, getSaver } from '@literature/services/saver.provider';
import { APP_BASE_HREF } from '@angular/common';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStateService } from '@services/app-state.service';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Title } from '@angular/platform-browser';
import { CaveatService } from '@products/caveats/services/caveat.service';
import { CookieService } from 'ngx-cookie-service';
import { EnvConfigService } from '@services/env-config.service';
import { FundApiInterceptor } from '@products/utils/fund-api-interceptor';
import { GlobalConfigService } from '@services/global-config-service';
import { ReactiveFormsModule } from '@angular/forms';
import { ResourceApiHeadersInterceptor } from '@shared/utils/resource-api-headers-interceptor';
import { SegmentService } from '@services/segment.service';
import { WidenService } from '@services/widen.service';
import { Brand } from './types/brand.enum';
import { PersonalisationAPIService } from '@services/personalisation-api.service';
import { LaunchDarklyService } from '@services/launch-darkly.service';

const initConfig = (
  envConfig: EnvConfigService,
  appStateService: AppStateService,
  personalisationService: PersonalisationAPIService
) => {
  return async () => {
    await envConfig.loadEnvConfig();
    appStateService.initializeLogging();
    personalisationService.init();
  };
};

// creates a brand specific version of highcharts service, suitable for the current channel
// Will need to update this function if EDS creates more brands in future
const highchartsThemeFactory = (
  appState: AppStateService
): HighchartsThemeService => {
  switch (appState.getBrand()) {
    case Brand.CLEARBRIDGE:
      return new ClearbridgeHighchartsThemeService();
    case Brand.CPREIF:
      return new CpreifHighchartsThemeService();
    case Brand.EP:
      return new EPHighchartsThemeService();
    case Brand.FRK:
    case Brand.FRK20:
      return new FrkHighchartsThemeService();
    case Brand.NJBEST:
      return new NJBestHighchartsThemeService();
    case Brand.MARTIN_CURRIE:
      return new MartinCurrieHighchartsThemeService();
    case Brand.BRANDYWINE_GLOBAL:
      return new BrandywineGlobalHighchartsThemeService();
    default:
      return new FrkSkyHighchartsThemeService();
  }
};

declare const appInitChannelConfig; // appInitChannelConfig is defined in index.html

@NgModule({
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: '/',
    },
    AnalyticsService,
    CookieService,
    CustomDimensionsService,
    EnvConfigService,
    AppStateService,
    PersonalisationAPIService,
    {
      provide: APP_INITIALIZER,
      useFactory: initConfig,
      multi: true,
      deps: [EnvConfigService, AppStateService, PersonalisationAPIService],
    },
    GlobalConfigService,
    SegmentService,
    WidenService,
    LaunchDarklyService,
    Title,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FundApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResourceApiHeadersInterceptor,
      multi: true,
    },
    { provide: FootnoteServiceToken, useExisting: CaveatService },
    { provide: ProximalServiceToken, useExisting: CaveatService },
    { provide: SAVER, useFactory: getSaver },
    {
      provide: HighchartsThemeService,
      useFactory: highchartsThemeFactory,
      deps: [AppStateService],
    },
  ],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    BrSdkModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
