import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  ButtonModule,
  FlexboxModule,
  GridModule,
  PaddingModule,
  SectionTitleModule,
  SignInModule as EdsSignInModule,
  SpacingModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { SignInComponent } from './sign-in.component';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    EdsSignInModule,
    PaddingModule,
    SpacingModule,
    GridModule,
    FlexboxModule,
    SectionTitleModule,
    ButtonModule,
    TranslateModule,
  ],
  exports: [SignInComponent],
})
export class SignInModule {}
