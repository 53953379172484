/**
 * Is a dom element visible on the screen?
 * i.e. depending on scrolling or css.
 */
export function isElementVisible(el: Element, completely = false) {
  const rect = el.getBoundingClientRect();
  const elemTop = rect.top;
  const elemBottom = rect.bottom;

  // Only completely visible elements return true:
  if (completely) {
    // TODO: should be injecting WINDOW reference instead of using global window object directly
    return elemTop >= 0 && elemBottom <= window.innerHeight;
  }
  // TODO: should be injecting WINDOW reference instead of using global window object directly
  const isVisible = elemTop >= 0 && elemBottom <= window.innerHeight;
  // Partially visible elements return true:
  // TODO: should be injecting WINDOW reference instead of using global window object directly
  return elemTop < window.innerHeight && elemBottom >= 0;
}

/**
 * replacement for jquery fadeIn
 * TODO implement properly
 */
export function fadeIn(el: Element) {
  el.setAttribute('style', 'display: block');
}

/**
 * replacement for jquery fadeOut
 * TODO implement properly
 */
export function fadeOut(el: Element) {
  el.setAttribute('style', 'display: none');
}
