<div [edsSpacing]="spacingBottom">
  <div edsGrid>
    <div class="container">
      <div edsRow *ngIf="content?.cardCompound?.length > 0">
        <div edsCol>
          <ng-container *ngIf="contentBlock.sectionType === 'media_cards'">
            <div
              edsRow
              *ngTemplateOutlet="
                mediaCardLayout;
                context: {
                  isStacked: true
                }
              "
            ></div>
          </ng-container>
          <ng-container *ngIf="contentBlock.sectionType === 'media_cards_list'">
            <div edsRow [ngTemplateOutlet]="mediaCardLayout"></div>
          </ng-container>
          <ng-container *ngIf="contentBlock.sectionType === 'media_card_index'">
            <div
              edsRow
              *ngTemplateOutlet="
                mediaCardLayout;
                context: {
                  isWrapped: true
                }
              "
            ></div>
          </ng-container>
          <ng-container
            *ngIf="
              contentBlock.sectionType === 'thumbnail_cards' ||
              contentBlock.sectionType === 'thumbnail_cards_compact'
            "
            ><div edsRow [ngTemplateOutlet]="mediaCardThumbnail"></div
          ></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template
  #mediaCardLayout
  let-isStacked="isStacked"
  let-isWrapped="isWrapped"
>
  <div
    edsSubGrid="layout-0"
    edsSubGridTabletGap="lg"
    edsSubGridTablet="layout-0"
    edsSubGridGap="lg"
    edsSubGridRowGap="none"
  >
    <eds-media-card
      *ngFor="let card of content?.cardCompound"
      [title]="card.title"
      [bodyContent]="card.content"
      [isIcon]="card.isIcon"
      [isStacked]="isStacked"
      [imgSrc]="card.imageSrc"
      [linkText]="card.link?.displayText"
      [url]="card.link?.url"
      [isNewWindow]="card.link?.target === '_blank'"
      [externalLink]="card.link?.external"
      [hasMediaLeft]="!!card.imageSrc"
      [externalSuffix]="card.link?.externalSuffix"
      [wrap]="isWrapped"
      [signInRequired]="card.link?.signInLink | ftIsSignInRequired | async"
      [hideLockIcon]="card.link?.hideLockIcon"
      [signInTooltip]="'common.locked-link-tooltip' | translate"
      [widenParams]="card.widenParams"
    >
    </eds-media-card>
  </div>
</ng-template>

<ng-template #mediaCardThumbnail>
  <div
    edsSubGrid="layout-0"
    edsSubGridTabletGap="lg"
    edsSubGridTablet="layout-0"
    edsSubGridGap="lg"
    edsSubGridRowGap="lg"
  >
    <eds-thumbnail
      *ngFor="let card of thumbnailCards"
      [eventId]="card.eventId"
      [eventParams]="card.eventParams"
      [hasBorders]="false"
      [hasShadows]="false"
      [hasBorderTop]="true"
      [hasBorderBottom]="true"
      [headingLevel]="2"
      [layoutSearch]="false"
      [layoutCompact]="content?.sectionType === 'thumbnail_cards_compact'"
      [title]="card.title"
      [body]="card.content"
      [buttonText]="card.link?.displayText"
      [previewSrc]="
        content?.sectionType === 'thumbnail_cards_compact' ? null : card.imgSrc
      "
      [docTypeSrc]="card.docTypeSrc"
      [href]="card.href"
      [titleLink]="card.href"
      [isNewWindow]="card.link?.target === '_blank'"
      [signInRequired]="card.link?.signInLink | ftIsSignInRequired | async"
      [hideLockIcon]="card.link?.hideLockIcon"
      [signInTooltip]="'common.locked-link-tooltip' | translate"
      [externalLink]="card.link?.external"
      [trackDownloads]="card.link?.enableDownloadTracking"
      [trackImpression]="card.link?.enableImpressionTracking"
      [downloadLocation]="card.link?.downloadLocation"
      (linkClick)="setDownloadLocation($event)"
      [widenParams]="card.widenParams"
    >
    </eds-thumbnail>
  </div>
</ng-template>
