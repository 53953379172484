<div>
  <div edsFlexbox justifyContent="space-between" edsSpacing="xs">
    <div>
      <eds-title
        size="h4"
        theme="primary"
        edsSpacing="xs"
        [title]="managerName"
      >
      </eds-title>
      <p>{{ managerIntro }}</p>
    </div>
    <div>
      <div edsFlexbox justifyContent="flex-end">
        <button class="more-btn" size="compact" edsButton="secondary">
          <span class="sr-only">Visit Bio Page</span>
          <span>Visit Bio Page</span>
        </button>
      </div>
    </div>
  </div>
  <div>
    <div edsCol edsSubGrid="layout-2" edsSubGridGap="lg">
      <div class="column-1">
        <eds-search-panel
          [leftTitle]="'Bio'"
          [viewMoreBtnText]="'Read more'"
          [hasBorders]="false"
          [hasShadows]="false"
        >
          <div edsSpacing="md">
            <eds-image
              [imgSrc]="'../assets/images/home-card.png'"
              [altText]="'Manager-Image'"
            >
            </eds-image>
          </div>
          <p edsPadding edsPaddingLeft="sm" edsSpacing="none">
            {{ bioContent1 }} <br /><br />
            {{ bioContent2 }}
          </p>
        </eds-search-panel>
      </div>
      <div class="column-2">
        <eds-search-panel
          [leftTitle]="'LatestContent'"
          [viewMoreBtnText]="'View more results'"
        >
          <ng-container *ngFor="let fund of fundsThumbnails">
            <eds-thumbnail
              class="exact-match__search-thumbnail"
              [hasBorders]="false"
              [hasShadows]="false"
              [headingLevel]="2"
              [layoutCompact]="true"
              [docTypeSrc]="fund.docTypeSrc"
              [title]="fund.title"
              [titleLink]="fund.titleLink"
              [body]="fund.body"
              [hasSearchIcon]="true"
              [noTitleColor]="true"
            >
            </eds-thumbnail>
          </ng-container>
        </eds-search-panel>
      </div>
      <div class="column-3">
        <div edsSpacing="sm">
          <eds-search-panel [leftTitle]="'Summary'">
            <div
              edsPadding
              edsPaddingTop="md"
              edsPaddingBottom="md"
              edsPaddingLeft="lg"
              edsPaddingRight="lg"
            >
              <eds-info-item *ngFor="let item of infoItem" [info]="item">
              </eds-info-item>
            </div>
          </eds-search-panel>
        </div>
        <div>
          <eds-search-panel
            [leftTitle]="'Funds Managed'"
            [viewMoreBtnText]="'View more funds'"
          >
            <ng-container *ngFor="let fund of fundItems">
              <eds-thumbnail-search
                class="exact-match__search-thumbnail"
                [hasBorders]="false"
                [hasShadows]="false"
                [headingLevel]="2"
                [layoutCompact]="true"
                [fundFamily]="fund.fundFamily"
                [fundName]="fund.fundName"
                [titleLink]="fund.fundLink"
              >
              </eds-thumbnail-search>
            </ng-container>
          </eds-search-panel>
        </div>
      </div>
    </div>
  </div>
</div>
