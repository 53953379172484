import { Component, Input, OnInit } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { TitleTheme } from '@frk/eds-components';

interface ContentBlock {
  contentType?: string;
  items?: any[];
}

@Component({
  selector: 'ft-one-col-block',
  templateUrl: './one-col-block.component.html',
})
export class OneColBlockComponent implements OnInit {
  @Input() component?: BrComponent;
  /**
   * BR SDK page object
   */
  @Input() page: Page;

  /**
   * Content block
   */
  @Input() contentBlock: ContentBlock;

  /**
   * Spacing between items
   */
  @Input() spacing = 'none';

  @Input() titleColorTheme: TitleTheme = 'primary';

  /**
   * On Init
   */
  ngOnInit(): void {}
}
