import { InfoItem } from '@frk/eds-components';
import {
  UsTaxAdditional,
  UsTax,
  UsTaxDistribution,
} from '@products/models/us-tax';
import {
  UsTaxAdditionalDTO,
  UsTaxDistributionDTO,
  UsTaxDTO,
  UsTaxStateDTO,
} from '@types';
import dayjs from 'dayjs';
import { EMDASH } from '../constants/product.constants';
import { Mapper } from './type.mapper';

export class UsTaxMapper extends Mapper<UsTax> {
  toDomain(usTaxDTO: UsTaxDTO): UsTax {
    return {
      distributions: usTaxDTO.distribution
        ? this.createMapper(UsTaxDistributionsMapper).toDomain(
            usTaxDTO.distribution
          )
        : null,
      additionalTaxInfo: usTaxDTO.additional
        ? this.createMapper(UsTaxAdditionalMapper).toDomain(usTaxDTO.additional)
        : null,
      states: usTaxDTO.state
        ? this.createMapper(UsTaxStateMapper).toDomain(usTaxDTO.state)
        : null,
    };
  }
}

export class UsTaxDistributionsMapper extends Mapper<UsTaxDistribution[]> {
  toDomain(usTaxDistributions: UsTaxDistributionDTO[]): UsTaxDistribution[] {
    return usTaxDistributions.map((distribution) => ({
      capGainDistributions: distribution.capgaindisdistributions,
      cusip: distribution.cusip,
      exemptInterestDividends: distribution.exemptinterestdividends,
      federalTaxId: distribution.fedtaxid,
      foriegnTaxPaid: distribution.foriegntaxpaid,
      nonTaxableReturnOfCapital: distribution.nontaxableroca,
      ordinaryDividends: distribution.ordinarydividends,
      payableDate: distribution.payabledate,
      qualifiedDividends: distribution.qualifieddividends,
      recordDate: distribution.recordate
        ? dayjs(distribution.recordate).format('MMM DD')
        : '',
      recordType: distribution.recordtype,
      section199aDividends: distribution.sec199adividends,
      taxYear: distribution.taxyear,
      unrecapturedSection1250Gain: distribution.unrecapsec1250gain,
      interestTaxReclaimRefund: distribution.interestreceivedeureclaim,
    }));
  }
}

export class UsTaxAdditionalMapper extends Mapper<UsTaxAdditional> {
  toDomain(usTaxAdditional: UsTaxAdditionalDTO): UsTaxAdditional {
    return {
      taxYear: usTaxAdditional.taxyear,
      items: [
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-foreign-source-income'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-foreign-source-income'
          ),
          val:
            usTaxAdditional.frgnsrcincm &&
            usTaxAdditional.frgnsrcincm !== EMDASH
              ? usTaxAdditional.frgnsrcincm + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-foreign-source-qualified-dividends'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-foreign-source-qualified-dividends'
          ),
          val:
            usTaxAdditional.frgnsrcqualifieddivinc &&
            usTaxAdditional.frgnsrcqualifieddivinc !== EMDASH
              ? usTaxAdditional.frgnsrcqualifieddivinc + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-direct-us-govt-obligations'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-direct-us-govt-obligations'
          ),
          val:
            usTaxAdditional.directusgovtobligations &&
            usTaxAdditional.directusgovtobligations !== EMDASH
              ? usTaxAdditional.directusgovtobligations + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-specified-private-activity-bond-interest'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-specified-private-activity-bond-interest'
          ),
          val:
            usTaxAdditional.specifiedpvtbondinterest &&
            usTaxAdditional.specifiedpvtbondinterest !== EMDASH
              ? usTaxAdditional.specifiedpvtbondinterest + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-dividends-received-reduction'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-dividends-received-reduction'
          ),
          val:
            usTaxAdditional.dividendsreceiveddeduction &&
            usTaxAdditional.dividendsreceiveddeduction !== EMDASH
              ? usTaxAdditional.dividendsreceiveddeduction + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-section-163'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-section-163'
          ),
          val:
            usTaxAdditional.section163 && usTaxAdditional.section163 !== EMDASH
              ? usTaxAdditional.section163 + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-indiana-resident-taxable-income'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-indiana-resident-taxable-income'
          ),
          val:
            usTaxAdditional.indianaresdtaxincm &&
            usTaxAdditional.indianaresdtaxincm !== EMDASH
              ? usTaxAdditional.indianaresdtaxincm + '%'
              : EMDASH,
        },
        {
          label: this.mapperParams.translateService.instant(
            'products.us-tax-utah-resident-taxable-income'
          ),
          tooltip: this.mapperParams.translateService.tooltip(
            'products.us-tax-utah-resident-taxable-income'
          ),
          val:
            usTaxAdditional.utahresdtaxincm &&
            usTaxAdditional.utahresdtaxincm !== EMDASH
              ? usTaxAdditional.utahresdtaxincm + '%'
              : EMDASH,
        },
      ],
    };
  }
}
export class UsTaxStateMapper extends Mapper<InfoItem[]> {
  toDomain(usTaxStateDTO: UsTaxStateDTO[]): InfoItem[] {
    const states = usTaxStateDTO.map((state) => ({
      label: state.statename,
      val: state.statevalue,
    }));
    return states;
  }
}
