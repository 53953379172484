import { Component, OnInit } from '@angular/core';
import { Reference } from '@bloomreach/spa-sdk';
import { WidenService } from '@services/widen.service';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { removePTags } from '@utils/text/string-utils';
import { AppStateService } from '@services/app-state.service';
import get from 'lodash/get';
import { LinkService } from '@services/link.service';
import { ViewModeService } from '@services/view-mode.service';

interface FeaturedContent {
  content: string;
  preTitle: string;
  title: string;
  imgSrc: string;
  href: string;
  isNewWindow: boolean;
  externalLink: boolean;
  signInRequired?: boolean;
}

@Component({
  selector: 'ft-featured-content-cards',
  templateUrl: './featured-content-cards.component.html',
  styleUrls: [],
})
export class FeaturedContentCardsComponent
  extends AbstractBaseComponent
  implements OnInit {
  cards: FeaturedContent[];
  title: string;
  preTitle: string;

  constructor(
    private widenService: WidenService,
    private appStateService: AppStateService,
    private linkService: LinkService,
    private viewModeService: ViewModeService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit(): void {
    const $ref: Reference = get(this.component?.getModels(), 'document', '');
    const content = this.page?.getContent($ref);
    const model = content?.getData();
    const cards = get(model, 'cards', []);
    this.preTitle = get(model, 'title.preTitle', '');
    this.title = get(model, 'title.title', '');
    this.cards = [];

    cards?.forEach((card: FeaturedContent) => {
      const widenAsset = get(card, 'image.widenAsset', '');
      this.cards.push({
        imgSrc: widenAsset ? this.getImageUrl(JSON.parse(widenAsset).url) : '',
        content: removePTags(card.content),
        title: card.title,
        preTitle: card.preTitle,
        href: this.linkService.getCTALink(
          this.page,
          get(card, 'link.linkCollection[0]'),
          this.appStateService?.getHomePageUrl()
        ),
        isNewWindow:
          get(card, 'link.linkCollection[0].target', '') === '_blank',
        externalLink: get(card, 'link.linkCollection[0].external', false),
        signInRequired: !!get(card, 'link.linkCollection[0].signInLink', ''),
      });
    });
  }

  private getImageUrl(url: string) {
    if (url) {
      return this.widenService.getWidenImageVariantUrl(
        url,
        'original',
        'webp',
        'eds-card-article'
      );
    }
  }
}
