import { Component, Input, OnInit, OnDestroy, Inject } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import {
  AnalyticsService,
  CardIndex,
  DropdownItem,
  LanguageSelector,
  ResponsiveService,
  RoleSelector,
  SimpleModalConfig,
} from '@frk/eds-components';
import {
  LanguageSelectorService,
  RoleSelectorService,
  SegmentService,
  Selector,
} from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import { SegmentId, SimplyLinkItem } from '@types';
import { Logger } from '@utils/logger';
import { Observable, Subject } from 'rxjs';
import { takeUntil, filter, delay } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';

const logger = Logger.getLogger('RollSelectorModalComponent');

@Component({
  selector: 'ft-role-selector-modal',
  templateUrl: './role-selector-modal.component.html',
})
// TODO - ideal solution will be to extend role-selector-banner component here, to avoid duplicate code
export class RoleSelectorModalComponent implements OnInit, OnDestroy {
  @Input() page!: Page;
  public isVisible$: Observable<boolean>;
  public isMobile$: Observable<boolean>;
  public isHandheld$: Observable<boolean>;
  public isSegmentSet$: Observable<boolean>;

  public roles: Array<CardIndex>;

  public siteLanguages: Array<SimplyLinkItem>;
  public languageOptions: LanguageSelector;
  public languageLabels: {};

  public title: string;
  public preTitle: string;
  public summary: string;

  public imageSrc: string;
  public imageTransparentBg = true;
  public transparencyRatio: '40' | '60' | '50' = '50';

  private selectedSegmentId: SegmentId = null;
  private defaultSegmentId: SegmentId = null;
  private unsubscribe$: Subject<void> = new Subject<void>();
  public simpleModalConfig: SimpleModalConfig;

  constructor(
    private roleSelectorService: RoleSelectorService,
    private responsiveService: ResponsiveService,
    private languageSelectorService: LanguageSelectorService,
    private segmentService: SegmentService,
    private analyticsService: AnalyticsService,
    private translateService: TranslateService,
    @Inject(DOCUMENT) readonly documentRef: Document
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.isMobile$();
    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.isVisible$ = this.roleSelectorService.showRoleSelectorModal$();
    this.title = this.roleSelectorService.getBannerTitle();
    this.preTitle = this.roleSelectorService.getBannerPreTitle();
    this.summary = this.roleSelectorService.getBannerSummary();

    this.imageSrc = this.roleSelectorService.getBannerImage();
    this.transparencyRatio = this.roleSelectorService.getTransparencyRatio();
    this.imageTransparentBg = this.roleSelectorService.isBgTransparent();

    this.siteLanguages = this.languageSelectorService.getConvertedLanguages();

    this.isSegmentSet$ = this.segmentService.isSegmentSet$();

    if (this.siteLanguages.length) {
      this.languageOptions = {
        title: this.languageSelectorService.getChannelLanguageLabel(this.page),
        items: this.siteLanguages,
      };
    }

    this.roleSelectorService
      .getRoleSelectorOptions$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((opts: RoleSelector): void => {
        // this.roleSelectorOptions = opts;
        this.defaultSegmentId = opts.preselectedValue.value;
        this.roles = opts.items.map((item: DropdownItem) => {
          return {
            title: item.label,
            summary: item.subLabel,
            theme: 'dark', // this will change to optional in EDS and will be removed
            event: item.value,
          };
        });
      });

    // set modal focus
    this.handleFocusChange();

    this.simpleModalConfig = {
      modalId: 'roleSelectorModal',
      closeBtnLabel: this.translateService.instant('common.close'),
    };
  }

  // modal focus
  private handleFocusChange(): void {
    this.isVisible$
      ?.pipe(
        takeUntil(this.unsubscribe$),
        filter((val) => val), // filters only visible === true, not when we hide modal
        delay(100)
      )
      .subscribe(() => {
        this.documentRef.getElementById('roleSelectorModal')?.focus();
      });
  }

  /**
   * selecting new segment/role
   * @param event selected card with role
   */
  public roleClicked(event: CardIndex): void {
    logger.debug('Role Clicked');
    this.analyticsService.trackEvent({
      event: 'linkOrButtonClick',
      detailed_event: 'Link or Button Click',
      event_data: {
        category: '',
        link_id: '',
        link_text: event.title,
        link_type: this.analyticsService.getLinkType(undefined),
        link_url: '',
      },
      link_text: event.title,
      link_url: '',
    });
    this.selectedSegmentId =
      event && event.event ? (event.event as SegmentId) : this.defaultSegmentId;

    this.roleSelectorService.confirmSelection(
      this.selectedSegmentId,
      Selector.MODAL
    );
    // NGC-14864 - enable scrolling when modal is close. Fixing bug when gateway component is not set.
    this.enableScrolling();
  }

  // prevents modal opening after closed
  public onModalClose(): void {
    this.roleSelectorService.onModalClose();
    this.enableScrolling();
  }

  private enableScrolling(): void {
    const body = this.documentRef.getElementsByTagName(
      'body'
    ) as HTMLCollectionOf<HTMLElement>;
    body[0].style.overflow = 'auto';
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
