import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Optional } from '@angular/core';
import { LOCAL_STORAGE, SESSION_STORAGE, WINDOW } from '@ng-web-apis/common';
import {
  Channel,
  ExpressNumber,
  FundShareClassId,
  GlobalId,
  IAnalyticsDataStore,
  LanguageData,
  SegmentId,
  PersonalisationToken,
  PROFILE_PARTNER_CHANNELS,
} from '@types';
import {
  AUTH_RESPONSE,
  BYPASS,
  DEVICE_RECOGNIZE_STATUS,
  FIRM_TOKEN,
  IS_MFA_COMPLETE,
  PCS_ACCEPT_FLAG,
  PROFILE_COOKIE,
  SECURITY_QUESTION_STATUS,
  SEGMENT_COOKIE,
  USER_FIRM_COOKIE,
  USER_SYS_NUMBER,
  USER_TYPE_COOKIE,
} from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { CookieService } from 'ngx-cookie-service';
import { OktaSessionNames } from '../types/okta.type';
import { AppStateService } from './app-state.service';
import { EnvConfigService } from './env-config.service';
import {
  BypassRole,
  FirmRole,
  IUserProfileInfo,
  ProfileSummary,
} from './profile.interface';
import { ServerCookieService } from './server-cookie.service';

export interface DataWindow extends Window {
  // TODO: define type for dataLayer
  dataLayer?: any[];
  profileData?: ProfileData;
}

export interface ProfileData {
  segment: string;
  isLoggedIn: string;
  testMedallia: boolean;
  globalId?: GlobalId;
  expressNumber?: ExpressNumber;
  profile?: {
    role?: string;
    webExperience?: string;
    firm?: string;
    accountAccess?: string;
    dashboardUrl?: string;
  };
}

export interface ProfileDataKeys {
  segmentKey: string;
  profileKey: string;
  isLoggedInKey: string;
}

export const FT_IDENTITY_TOKEN_KEY = 'ft-identity-token';
const logger = Logger.getLogger('StorageService');

const GLOBAL_VAR_STORE_KEYS: ProfileDataKeys = {
  segmentKey: '',
  profileKey: '',
  isLoggedInKey: '',
};

/**
 * This service is used to store and retrieve data in the browser via local storage and cookies
 * Instead of using cookies or local storage directly, use this service
 * Where cookie and local storage values differ, Local Storage will always take priority
 * Data will be read from LS first, and only check cookie if no value found
 * By default, data will only be written to LS going forward unless specifically set to also back up in cookie
 */

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private channel: Channel;
  checkCookie = false;

  constructor(
    @Inject(WINDOW) private windowRef: DataWindow,
    @Inject(LOCAL_STORAGE)
    @Optional()
    private readonly localStorageRef: Storage,
    @Inject(SESSION_STORAGE)
    @Optional()
    private readonly sessionStorageRef: Storage,
    @Inject(DOCUMENT)
    @Optional()
    private documentRef: Document,
    // TODO: cookie service may be replaced with server cookie service
    private cookieService: CookieService,
    private serverCookieService: ServerCookieService,
    private appState: AppStateService,
    private envConfig: EnvConfigService
  ) {
    this.channel = this.appState.getChannel();
    this.windowRef.profileData = {
      segment: '',
      isLoggedIn: '',
      testMedallia: false,
    };

    // list of variables stored in global variable for easier access from external scripts
    // we use it for Medallia forms
    this.setAnalyticsVarNames();
  }

  private setAnalyticsVarNames() {
    GLOBAL_VAR_STORE_KEYS.segmentKey = this.getSegmentLocalKey();
    GLOBAL_VAR_STORE_KEYS.isLoggedInKey = this.getIsLoggedInLocalKey();
    GLOBAL_VAR_STORE_KEYS.profileKey = this.getProfileSummaryLocalKey();
  }

  /**
   * Sync cookies with localstorage when configuration is loaded.
   * It was moved to method due to issue with loading configuration in service.
   * Used in profile-service.
   */
  public syncCookieOnStartup(): void {
    // Sync cookies when environment configuration is loaded
    // certain cookies get synced on startup
    // NB: this list of cookies may need added to in future
    if (this.channel === 'en-us') {
      this.envConfig.loadEnvConfig().then(() => {
        // sync profile
        this.syncCookie(USER_FIRM_COOKIE);

        // sync segment ie user_role
        this.syncCookie(this.getSegmentLocalKey(), SEGMENT_COOKIE);
        // We need to remove some duplicated cookies during sync.
        // After sync we should have cookies only for 'sharedCookieDomain' domain defined in config.
        // Duplicates for pre, www... should be deleted.
        this.removeDuplicatedCookies(true);
      });
    }
    // Sync cookies required for Smarsh crawler only when localstorage is not set
    const segmentCookieName = this.getSegmentLocalKey();
    if (!this.localStorageRef.getItem(segmentCookieName)) {
      this.syncCookiesForSmarsh(segmentCookieName);
    }
    // Sync userType cookie for Canada. Shared cookie is set on Canada accounts site during sign-in.
    if (this.channel === 'en-ca' || this.channel === 'fr-ca') {
      // First remove local storage cookie to sync cookie set on accounts as priority
      this.remove(USER_TYPE_COOKIE);
      // Sync account cookie with localstorage.
      this.syncCookie(USER_TYPE_COOKIE);
    }
  }

  /*
   * this method is required to synchronize data from local storage
   * to global variable  profileData
   * we use it for Medallia forms
   * for new clients we set and remove values in profileData inside store() and remove() methods
   */
  public syncLocalStorageToGlobalVar(): void {
    if (this.isStorageEnabled()) {
      // get segment
      this.retrieve(GLOBAL_VAR_STORE_KEYS.segmentKey, false).then(
        (value: SegmentId) => {
          this.storeAnalyticsSegment(value, false);
        }
      );

      // get isLoggedIn
      this.retrieve(GLOBAL_VAR_STORE_KEYS.isLoggedInKey, false).then(
        (value: string) => {
          this.storeAnalyticsIsLoggedIn(value, false);
        }
      );

      // get and extract profile data
      this.retrieve(GLOBAL_VAR_STORE_KEYS.profileKey, false).then(
        (value: string) => {
          this.storeAnalyticsProfile(value, false);
        }
      );
    }

    // set profileData.testMedallia value if set in URL
    this.windowRef.profileData.testMedallia = this.documentRef?.location?.search.includes(
      'testMedallia=true'
    );
  }

  private storeAnalyticsSegment(value: string, isJson: boolean) {
    let valueParsed = value;
    if (isJson) {
      try {
        valueParsed = JSON.parse(value);
      } catch {
        logger.debug('json expected');
      }
    }
    this.windowRef.profileData.segment = valueParsed;
  }

  private storeAnalyticsIsLoggedIn(value: string, isJson: boolean) {
    let valueParsed = value;
    if (isJson) {
      try {
        valueParsed = JSON.parse(value);
      } catch {
        logger.debug('json expected');
      }
    }
    this.windowRef.profileData.isLoggedIn = valueParsed;
  }

  // we expect object when read from local storage or json string if called from store()
  private storeAnalyticsProfile(value: string, isJson: boolean) {
    if (!value) {
      this.removeAnalyticsProfile();
      return;
    }

    let valueParsed = value;
    if (isJson) {
      try {
        valueParsed = JSON.parse(value);
      } catch {
        logger.debug('json expected');
      }
    }
    Object.keys(valueParsed).forEach((key) => {
      this.windowRef.profileData['profile_' + key] = valueParsed[key];
    });
  }

  private removeAnalyticsSegment() {
    this.windowRef.profileData.segment = '';
  }

  private removeAnalyticsIsLoggedIn() {
    this.windowRef.profileData.isLoggedIn = '';
  }

  private removeAnalyticsProfile() {
    Object.keys(this.windowRef.profileData).forEach((key) => {
      if (key.startsWith('profile_')) {
        delete this.windowRef.profileData[key];
      }
    });
  }

  private getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  private removeDuplicatedCookies(useServerCookieService: boolean = false) {
    if (useServerCookieService) {
      // Using Server Cookie Service to remove cookies
      // Server Cookie service will remove cookies created with full domain name like www.franklintempleton.com
      // Cookies for domain .franklintempleton.com will not be removed.
      this.serverCookieService.set(USER_FIRM_COOKIE, {
        cookieValue: '',
        cookieTime: 0,
      });
      this.serverCookieService.set(SEGMENT_COOKIE, {
        cookieValue: '',
        cookieTime: 0,
      });
    } else {
      // List of domain prefixes which can have duplicated cookies.
      const domainPrefixes = ['en-us.dev', 'en-us.staging', 'pre', 'www'];
      const domain = this.appState.getCookieDomain();
      domainPrefixes.forEach((pre: string) => {
        this.cookieService.delete(USER_FIRM_COOKIE, '/', pre + domain, true);
        this.cookieService.delete(SEGMENT_COOKIE, '/', pre + domain, true);
      });
    }
  }

  /**
   * Get bypass cookie if exist. This is read-only cookie for "Smarsh" tool. Cookie is not set by application.
   */
  public getBypassCookie(): BypassRole {
    const bypassCookie = this.cookieService.get(BYPASS);
    return bypassCookie as BypassRole;
  }

  /**
   * Remove Bypass cookie for sign-out method.
   */
  public removeBypassCookie(): void {
    this.cookieService.delete(BYPASS);
  }

  /**
   * Remove PCS tool cookie on sign-out.
   */
  public removePcsCookie(): void {
    this.cookieService.delete(PCS_ACCEPT_FLAG);
  }

  /**
   * Set checkCookie value.
   * Allows to change checkCookie value set to false by default.
   * @param useCookies - boolean
   */
  public setCheckCookie(useCookies: boolean): void {
    this.checkCookie = useCookies;
  }

  /**
   * This saves a value to session or local storage
   * If local storage AND cookieKey passed, also backs it up to cookie
   * @param name key to use in storage
   * @param value if not string, value will be stringified
   * @param useSession boolean. use session storage instead of local storage
   * @param cookieKey string. If present, will also write to cookie of this name
   * @param sessionCookie - boolean option to set session or 1y cookie
   */
  public store<T>(
    name: string,
    value: T,
    useSession = false,
    cookieKey?: string,
    sessionCookie?: boolean
  ): void {
    if (this.isStorageEnabled(useSession)) {
      const valString: string = JSON.stringify(value);

      // first we check if stored key name is on our list of keys related to profile, segment or isLoggedIn
      // we check localized version i.e. segment_en-us
      // if value is found, we get key coressponding to that value, for example segmentKey and perform required action
      // duplicating value stored in local storage into profileData attribute segment
      if (Object.values(GLOBAL_VAR_STORE_KEYS).includes(name)) {
        switch (this.getKeyByValue(GLOBAL_VAR_STORE_KEYS, name)) {
          case 'segmentKey':
            this.storeAnalyticsSegment(valString, true);
            break;
          case 'isLoggedInKey':
            this.storeAnalyticsIsLoggedIn(valString, true);
            break;
          case 'profileKey':
            this.storeAnalyticsProfile(valString, true);
            break;
        }
      }

      if (useSession) {
        // store in session storage
        logger.debug('store in session storage', name, value);
        this.sessionStorageRef.setItem(name, valString);
      } else {
        // store in local storage
        logger.debug('store in local storage', name, value);
        this.localStorageRef.setItem(name, valString);

        this.checkCookie = !!cookieKey;
        if (this.checkCookie && cookieKey) {
          // back up value to cookie
          this.setCookie<T>(cookieKey, value, valString, sessionCookie);
        }
      }
    } else {
      logger.error('storage not available');
    }
  }

  /**
   * This retrieves a value from session or local storage
   * If local storage AND cookieKey passed, will try to read value from local storage first, then try cookie if nothing found
   * @param name key to use in storage
   * @param useSession boolean. use session storage instead of local storage
   * @param cookieKey string. If present, will also check cookie of this name
   * @returns value (if found) or null (if not found)
   */
  public retrieve<T>(
    name: string,
    useSession = false,
    cookieKey?: string
  ): Promise<T> {
    if (this.isStorageEnabled(useSession)) {
      return new Promise((resolve, reject) => {
        let jsonString: string | T;
        if (useSession) {
          // get value from session storage
          jsonString = this.sessionStorageRef.getItem(name);
          logger.debug('retrieve from session storage', name, jsonString);
        } else {
          // get value from local storage
          jsonString = this.localStorageRef.getItem(name);
          logger.debug('retrieve from local storage', name, jsonString);
          if (this.checkCookie && jsonString === null) {
            // if value not in local storage, try to get from cookie
            jsonString = this.getCookie<T>(jsonString, cookieKey, name);
          }
        }
        try {
          resolve(JSON.parse(jsonString as string) as T);
        } catch (e) {
          // sometimes json parsing breaks, so just return value as string
          resolve(jsonString as T);
        }
      });
    } else {
      logger.error('storage not available');
    }
  }

  public remove(name: string, useSession = false, cookieKey?: string): void {
    if (this.isStorageEnabled(useSession)) {
      // see explanation how it works in comment inside store() method above
      if (Object.values(GLOBAL_VAR_STORE_KEYS).includes(name)) {
        switch (this.getKeyByValue(GLOBAL_VAR_STORE_KEYS, name)) {
          case 'segmentKey':
            this.removeAnalyticsSegment();
            break;
          case 'isLoggedInKey':
            this.removeAnalyticsIsLoggedIn();
            break;
          case 'profileKey':
            this.removeAnalyticsProfile();
            break;
        }
      }

      if (useSession) {
        // delete session storage
        logger.debug('delete session storage', name);
        this.sessionStorageRef.removeItem(name);
      } else {
        // delete local storage
        logger.debug('delete local storage', name);
        this.localStorageRef.removeItem(name);
        if (this.checkCookie) {
          // delete cookie
          this.removeCookie(cookieKey, name);
        }
      }
    } else {
      logger.error('storage not available');
    }
  }

  public isStorageEnabled(useSession = false): boolean {
    try {
      if (
        typeof Storage !== 'undefined' &&
        (useSession ? this.localStorageRef : this.sessionStorageRef)
      ) {
        return true;
      }
    } catch (e) {}
    return false;
  }

  private setCookie<T>(
    cookieKey: string,
    value: T,
    valString: string,
    sessionCookie: boolean
  ) {
    logger.debug('store in cookie', cookieKey, value);
    // for backwards compatibility, if value is a string then don't convert to json
    const cookieVal: string = typeof value === 'string' ? value : valString;
    this.cookieService.set(
      cookieKey,
      cookieVal,
      // @ts-ignore - works correct with object variable as options
      this.getCookieOptions(sessionCookie)
    );
  }

  private getCookie<T>(
    jsonString: string | T,
    cookieKey: string,
    name: string
  ) {
    jsonString = this.cookieService.get(cookieKey || name);
    // cookieService.get() returns '' if cookie doesn't exist, so change to null
    if (jsonString === '') {
      jsonString = null;
    }
    logger.debug('retrieve from cookie', cookieKey || name, jsonString);
    return jsonString;
  }

  private removeCookie(cookieKey: string, name: string) {
    logger.debug('delete cookie', cookieKey || name);
    // NB: this is a hacky way to delete cookies, but needed for options to match what was set originally
    this.cookieService.set(cookieKey || name, '', {
      ...this.getCookieOptions(),
      expires: -1,
    });
  }

  /**
   * Determines if value exists in local storage (or cookie)
   */
  public isSet(
    name: string,
    useSession = false,
    cookieKey?: string
  ): Promise<boolean> {
    if (this.isStorageEnabled(useSession)) {
      return new Promise((resolve, reject) => {
        let isSet = false;
        if (useSession) {
          // get value from session storage
          if (this.sessionStorageRef.getItem(name) !== null) {
            isSet = true;
          }
          logger.debug('isSet in session storage?', name, isSet);
        } else {
          // get value from local storage
          if (this.localStorageRef.getItem(name) !== null) {
            isSet = true;
          }
          logger.debug('isSet in local storage?', name, isSet);

          if (this.checkCookie && !isSet) {
            // if value not in local storage, try to get from cookie
            if (this.cookieService.get(cookieKey || name) !== '') {
              isSet = true;
            }
            logger.debug('isSet in cookie?', cookieKey || name, isSet);
          }
        }
        resolve(isSet);
      });
    } else {
      logger.error('storage not available');
    }
  }

  /**
   * called to synchronise values between local storage and cookies
   */
  public syncCookie(name: string, cookieKey?: string): void {
    if (this.isStorageEnabled()) {
      const localStorageStr: string = this.localStorageRef.getItem(name);
      const cookieStr: string = this.cookieService.get(cookieKey || name);
      if (
        localStorageStr !== null &&
        (cookieStr === '' || cookieStr !== localStorageStr)
      ) {
        // if cookie not set or different from local storage, set cookie to local storage value
        let parsed: unknown;
        try {
          // In some cases local storage cookies can't be parsed as JSON
          parsed = JSON.parse(localStorageStr);
        } catch (e) {
          logger.debug('localStorageStr not parsable: ', e);
          parsed = localStorageStr;
        }
        const cookieVal: string =
          typeof parsed === 'string' ? parsed : localStorageStr;
        this.cookieService.set(
          cookieKey || name,
          cookieVal,
          // @ts-ignore - works correct with object variable as options
          this.getCookieOptions()
        );
      }
      if (localStorageStr === null && cookieStr !== '') {
        // if local storage not set, copy over cookie value
        this.localStorageRef.setItem(name, cookieStr);
      }
    }
  }

  /**
   * Sync Cookies to local storage for Smarsh crawler
   * For Smarsh, segment and T&C cookie needs to be sync to localstorage
   * @param segmentCookieName - sting
   */
  private syncCookiesForSmarsh(segmentCookieName: string): void {
    const segmentCookieStr: string = this.cookieService.get(segmentCookieName);
    if (segmentCookieStr) {
      this.localStorageRef.setItem(segmentCookieName, segmentCookieStr);
      const termsCookieName = this.getTermsAgreedLocalKey(
        segmentCookieStr as SegmentId
      );
      const termsCookie: string = this.cookieService.get(termsCookieName);
      if (termsCookie) {
        this.localStorageRef.setItem(termsCookieName, termsCookie);
      }
    }
  }

  //////////////////////////
  // shortcut methods below
  //////////////////////////

  // profile methods
  /**
   * Sets local storage cookies for profile
   * @param value - ProfileSummary to set in local storage cookie
   */
  public storeProfileSummary(value: ProfileSummary): void {
    // split out isLoggedIn to store in session, while rest is stored in local storage
    const { isLoggedIn = false, isIdentified = false, ...rest } = value;
    this.storeIsLoggedInSession(isLoggedIn);
    this.store<ProfileSummary>(this.getProfileSummaryLocalKey(), rest);
    this.store<string>(
      USER_FIRM_COOKIE,
      value?.role || '',
      false,
      USER_FIRM_COOKIE
    );
    // setting the user_role cookie for primerica
    if (PROFILE_PARTNER_CHANNELS.includes(this.channel)) {
      this.store<string>(
        this.getSegmentLocalKey(),
        SegmentId.FINANCIAL_PROFESSIONALS,
        false,
        SEGMENT_COOKIE
      );
    }
  }

  public removeProfileSummary(): void {
    this.remove(this.getProfileSummaryLocalKey());
  }

  public storeIsLoggedInSession(value: boolean): void {
    this.store<boolean>(this.getIsLoggedInLocalKey(), value, true);
  }

  /**
   * retrieves a user's profile from local+session storage
   * @param channel if passed, gets for specific channel. Otherwise uses current site channel
   * @returns ProfileSummary object
   */
  public async retrieveProfileSummary(
    channel?: Channel
  ): Promise<ProfileSummary> {
    const storedSummary: ProfileSummary = await this.retrieve<ProfileSummary>(
      this.getProfileSummaryLocalKey(channel)
    );
    // return early if no profile data found
    if (!storedSummary) {
      logger.debug('No profile summary found in storage');
      return null;
    }
    const isLoggedIn: boolean = await this.isLoggedIn();
    const idToken: PersonalisationToken = await this.getIdentityToken();
    const summary: ProfileSummary = {
      isLoggedIn: isLoggedIn || false,
      isIdentified: !!idToken,
      ...storedSummary,
    };
    logger.debug('Profile Summary loaded from storage', summary);
    return summary;
  }

  public isProfileSummarySet(): Promise<boolean> {
    // only checks local storage values. ignores isLoggedIn in session storage
    return this.isSet(this.getProfileSummaryLocalKey());
  }

  // TODO: is this still required?
  public storeProfileParams(value: string): void {
    // TODO: Implement server-cookie service
    this.store<string>(PROFILE_COOKIE, value, false, PROFILE_COOKIE);
  }

  // segment methods
  public storeSegment(
    segmentId: SegmentId,
    multilingual?: LanguageData[]
  ): void {
    this.store<SegmentId>(
      this.getSegmentLocalKey(),
      segmentId,
      false,
      this.getSegmentCookieKey()
    );
    if (multilingual && multilingual.length > 0) {
      multilingual.forEach((languageCode: LanguageData) => {
        this.store<SegmentId>(
          this.getSegmentLanguageKey(languageCode.locale),
          segmentId,
          false,
          this.getSegmentLanguageKey(languageCode.locale)
        );
      });
    }
  }

  public retrieveSegment(): Promise<SegmentId> {
    return this.retrieve<SegmentId>(
      this.getSegmentLocalKey(),
      false,
      this.getSegmentCookieKey()
    );
  }

  public retrieveSegmentStatic(): SegmentId {
    const segmentLocalKey: string = this.getSegmentLocalKey();
    logger.debug('retrieve segment key', segmentLocalKey);
    const segmentId = this.localStorageRef.getItem(segmentLocalKey);
    try {
      return JSON.parse(segmentId as string) as SegmentId;
    } catch (e) {
      return segmentId as SegmentId;
    }
  }

  public removeSegment(multilingual?: LanguageData[]): void {
    this.remove(this.getSegmentLocalKey(), false, this.getSegmentCookieKey());
    if (multilingual && multilingual.length > 0) {
      multilingual.forEach((languageCode: LanguageData) => {
        this.remove(
          this.getSegmentLanguageKey(languageCode.locale),
          false,
          this.getSegmentLanguageKey(languageCode.locale)
        );
      });
    }
  }

  public retrieveFundFavorites(): Promise<FundShareClassId[]> {
    return this.retrieve(this.getFavoriteFundsLocalKey());
  }

  public storeFundFavorites(data: FundShareClassId[]) {
    this.store(this.getFavoriteFundsLocalKey(), data);
  }

  // Analytics Data Sotrage
  public storeAnalyticsData(data: IAnalyticsDataStore): void {
    this.store<IAnalyticsDataStore>(
      this.getAnalyticsPersistantLocalKey(),
      data
    );
  }

  public retrieveAnalyticsData(): Promise<IAnalyticsDataStore> {
    return this.retrieve<IAnalyticsDataStore>(
      this.getAnalyticsPersistantLocalKey()
    );
  }

  public removeAnalyticsData(): void {
    this.remove(this.getAnalyticsPersistantLocalKey());
  }

  public isSegmentSet(): Promise<boolean> {
    return this.isSet(
      this.getSegmentLocalKey(),
      false,
      this.getSegmentCookieKey()
    );
  }

  // terms agreed methods
  public storeTermsAgreed(segmentId: SegmentId, value: boolean): void {
    const key: string = this.getTermsAgreedLocalKey(segmentId);
    this.store<boolean>(key, value, false, key);
  }

  public retrieveTermsAgreed(segmentId: SegmentId): Promise<boolean> {
    return this.retrieve<boolean>(this.getTermsAgreedLocalKey(segmentId));
  }

  public removeTermsAgreed(segmentId: SegmentId): void {
    this.remove(this.getTermsAgreedLocalKey(segmentId));
  }

  public isTermsAgreedSet(segmentId: SegmentId): Promise<boolean> {
    return this.isSet(this.getTermsAgreedLocalKey(segmentId));
  }

  // custom terms agreed methods
  public storeCustomTermsAgreed(termsPath: string, value: boolean): void {
    const key: string = this.getCustomTermsAgreedLocalKey(termsPath);
    this.store<boolean>(key, value, false, key);
  }

  public retrieveCustomTermsAgreed(termsPath: string): Promise<boolean> {
    return this.retrieve<boolean>(this.getCustomTermsAgreedLocalKey(termsPath));
  }

  public removeCustomTermsAgreed(termsPath: string): void {
    this.remove(this.getCustomTermsAgreedLocalKey(termsPath));
  }

  public isCustomTermsAgreedSet(termsPath: string): Promise<boolean> {
    return this.isSet(this.getCustomTermsAgreedLocalKey(termsPath));
  }

  public retrieveToolsCookie(cookieName: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let isToolsCookie = false;
      // Covering -tools and -sso custom modal cookie functionality
      // This allows create custom modal window with cookie functionality by label naming convention
      // If cookie name wil contain word -tool it will check T&C cookie
      // This is also required for existing functionality initially created for Hypo Tools and Social Security Optimizer
      if (cookieName.includes('-tools') || cookieName.includes('-sso')) {
        this.retrieve(cookieName, false).then((cookie) => {
          if (cookie) {
            isToolsCookie = true;
          }
          resolve(isToolsCookie);
        });
      } else {
        resolve(false);
      }
    });
  }

  public async isLoggedIn(): Promise<boolean> {
    return this.retrieve<string | boolean>(
      this.getIsLoggedInLocalKey(),
      true
    ).then(
      (loggedInAsString) =>
        loggedInAsString === 'true' || loggedInAsString === true
    );
  }

  //////////////////////////
  // private methods below
  //////////////////////////

  private getSegmentLocalKey(): string {
    return `segment_${this.channel}`;
  }

  private getSegmentLanguageKey(languageKey: string): string {
    return `segment_${languageKey}`;
  }

  // can optionnally use channel for a different site
  // this is for WDE-2305 to check for Primerica profile on en-us
  private getProfileSummaryLocalKey(channel?: Channel): string {
    return channel ? `profile_${channel}` : `profile_${this.channel}`;
  }

  private getIsLoggedInLocalKey(): string {
    return `isLoggedIn_${this.channel}`;
  }

  private getAnalyticsPersistantLocalKey(): string {
    return `analyticsData_${this.channel}`;
  }

  private getFavoriteFundsLocalKey(): string {
    return `favoriteFunds_${this.channel}`;
  }

  /**
   * If site is US, use SEGMENT_COOKIE (user_role) for legacy cookie
   * For intl sites, returns same as LocalStorage key e.g. segment_en-lu
   */
  private getSegmentCookieKey(): string {
    return this.channel === 'en-us'
      ? SEGMENT_COOKIE
      : this.getSegmentLocalKey();
  }

  private getTermsAgreedLocalKey(segmentId: SegmentId): string {
    return `segment_${this.channel}_${segmentId}_termsagreed`;
  }

  private getCustomTermsAgreedLocalKey(path: string): string {
    const cleanPath = path.replace('/', '').replace(/\//g, '-'); // replace first / with space and other with -
    return `custom_${cleanPath}_termsagreed`;
  }

  /**
   * Get Cookie Options
   * @param sessionCookie - boolean value to set 1y or session cookie
   */
  private getCookieOptions(sessionCookie: boolean = false): object {
    const secure: boolean =
      this.envConfig.getEnvConfig()?.environment !== 'dev';
    const cookieOptions: any = {
      expires: sessionCookie ? 0 : 365,
      path: '/', // Avoid setting separate cookie for each patch
      secure,
      sameSite: 'Lax',
    };
    if (this.getSegmentCookieKey() === SEGMENT_COOKIE) {
      cookieOptions.domain =
        this.documentRef.domain === 'localhost'
          ? this.documentRef.domain
          : this.appState.getCookieDomain();
    }
    return cookieOptions;
  }

  /**
   * Get User sys number from cookie
   * @returns - user sys number as string
   */

  public getUserSysNumber(): string {
    return this.cookieService.get(USER_SYS_NUMBER);
  }

  /**
   * Get device recognization status
   * @returns - recognization status as string
   */
  public getDeviceRecognizeStatus(): string {
    return this.cookieService.get(DEVICE_RECOGNIZE_STATUS);
  }

  /**
   * Get security question status from cookie
   * @returns - security question status as string
   */
  public getSecurityQuestionStatus(): string {
    return this.cookieService.get(SECURITY_QUESTION_STATUS);
  }

  /**
   * Get MFA status from cookie
   * @returns - MFA status as string
   */
  public getMFAStatus(): string {
    return this.cookieService.get(IS_MFA_COMPLETE);
  }

  /**
   * Delete authresponse cookie
   */
  public removeAuthResponseCookie() {
    this.cookieService.delete(AUTH_RESPONSE);
  }

  /**
   * Delete MFA status cookie
   */
  public removeMFAStatusCookie() {
    this.cookieService.delete(IS_MFA_COMPLETE);
  }

  public saveOktaCallbackRoute(route: string): void {
    this.store(OktaSessionNames.CALLBACK_ROUTE, route, true);
  }

  public getOktaCallbackRoute(): Promise<string> {
    return this.retrieve<string>(OktaSessionNames.CALLBACK_ROUTE, true);
  }

  public removeOktaCallbackRoute(): void {
    this.remove(OktaSessionNames.CALLBACK_ROUTE, true);
  }

  public saveOktaProfile(profile: IUserProfileInfo): void {
    this.store(OktaSessionNames.PROFILE, profile, true);
  }

  public getOktaProfile(): Promise<IUserProfileInfo> {
    return this.retrieve<IUserProfileInfo>(OktaSessionNames.PROFILE, true);
  }

  public removeOktaProfile(): void {
    return this.remove(OktaSessionNames.PROFILE, true);
  }

  public saveAuth0CallbackRoute(route: string): void {
    this.store('auth0-callback-route', route, true);
  }

  public getAuth0CallbackRoute(): Promise<string> {
    return this.retrieve<string>('auth0-callback-route', true);
  }

  /**
   * Returns Okta session from session storage
   * @param oktaLoginSessionName - OktaSessionNames enum
   */
  public getInternalOktaLogin(oktaLoginSessionName: OktaSessionNames): boolean {
    return !!this.sessionStorageRef.getItem(oktaLoginSessionName);
  }

  /**
   * Set Okta session in session storage
   * @param oktaLoginSessionName - OktaSessionNames enum
   */
  public setInternalOktaLogin(oktaLoginSessionName: OktaSessionNames): void {
    this.sessionStorageRef.setItem(oktaLoginSessionName, 'true');
  }

  /**
   *
   * @param token identity token added to url in email from Marketo
   */
  public setIdentityToken(token: PersonalisationToken): void {
    this.store(FT_IDENTITY_TOKEN_KEY, token, true);
    this.setCookie(FT_IDENTITY_TOKEN_KEY, token, null, true);
  }

  /**
   * get firm token from local storage for firm partner
   */
  public async getFirmToken(): Promise<FirmRole> {
    return this.retrieve(FIRM_TOKEN);
  }

  /**
   * Store firm token in local storage for firm partner
   * @param firmToken - string
   */
  public setFirmTokenInStorage(firmToken: FirmRole): void {
    this.store(FIRM_TOKEN, firmToken);
  }

  // returns previously stored ft_token value from session storage, or cookie
  // NB: return empty string if none found
  public getIdentityToken(): Promise<PersonalisationToken> {
    return this.retrieve<PersonalisationToken>(
      FT_IDENTITY_TOKEN_KEY,
      true
    ).then((token) => {
      if (!token) {
        return this.cookieService.get(
          FT_IDENTITY_TOKEN_KEY
        ) as PersonalisationToken;
      }
      return token;
    });
  }
  public removeIdentyToken(): void {
    this.remove(FT_IDENTITY_TOKEN_KEY, true);
    this.removeCookie(FT_IDENTITY_TOKEN_KEY, FT_IDENTITY_TOKEN_KEY);
  }
}
