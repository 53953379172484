import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { DynamicModule } from '@services/module-loader.config';
import { ModuleLoaderService } from '@services/module-loader.service';

@Component({
  selector: 'ft-custom-layout',
  templateUrl: './custom-layout.component.html',
  styleUrls: ['./custom-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomLayoutComponent implements OnInit {
  @Input() page!: Page;
  @Input() layout: string;
  public skipTo: boolean;
  @ViewChild('dynamicLayoutCmp', { read: ViewContainerRef, static: false })
  container; // TODO: what is type? what sets the value here?

  layoutComponentModule: Promise<DynamicModule>;

  constructor(
    private moduleLoaderService: ModuleLoaderService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetector: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.page && this.layout) {
      this.changeDetector.detectChanges(); // needed to pick up container ref
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.moduleLoaderService.getCurrentMapping()[this.layout]
      );
      this.container.clear();
      const componentRef = this.container.createComponent(componentFactory);
      componentRef.instance.initialize(this.page);
      this.changeDetector.detectChanges();
    }
  }
}
