import { InfoItem } from '@frk/eds-components';

export interface StickyFooterActionParam {
  buttonLabel: string;
  buttonTheme?: string;
  iconType?: string;
  actionTarget?: string;
  hasAction: boolean;
}

export interface FundCommentaryDocsResponse {
  commentary?: FundCommentaryContent;
  document?: FundCommentaryDocument[];
}
export interface FundCommentaryContent {
  commentaryTitle?: string;
  standardLegaIinformation?: string;
  importantLegalInformation?: string;
  commentaryKeyPoints?: string;
  fullCommentary?: string;
}
export interface FundCommentaryDocument {
  dctermsTitle?: string;
  dctermsAudience?: string[];
  frkOneTISNumber?: string;
  literatureCode?: string;
  fundName?: string;
  description?: string;
  downloadUrl?: string;
  assetType?: string;
  isHistoryData?: boolean;
  publicationDate?: string;
  downloadPdfUrl?: string;
  dctermsType?: string;
  customFilePath?: string;
}

export interface LiteratureInfoItem extends InfoItem {
  value?: string;
  link?: string;
}

export interface DialogConfig {
  title?: string;
  content?: any;
  cancelBtnLabel?: string;
  closeBtnLabel?: string;
  okBtnLabel?: string;
  successUrl?: string;
  hideFooter?: boolean;
}

export interface DownloadRequest {
  literatureCodes: string[];
}

export interface GridProperties {
  autoHeight?: boolean;
  cellClass?: string | string[];
  cellStyle?: any;
  flex?: number;
  maxWidth?: number;
  minWidth?: number;
  wrapText?: boolean;
  sortable?: boolean;
  sortingOrder?: string[];
  sort?: string;
}
