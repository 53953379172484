import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GraphQLFundDataService,
  FundDataServiceParams,
} from './graphql-fund-data.service';
import { DocumentNode } from '@apollo/client/core';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { PerformanceHistoryMapper } from '@products/utils/mappers/performance-history.type.mapper';
import { ChartsDTO } from '@products/types/dto/historical.dto.type';
import { formatLocaleDateToStdDate, getDate } from '@utils/text/date-utils';

const logger = Logger.getLogger('FundPerformanceHistoryService');

@Injectable({
  providedIn: 'root',
})
export class FundPerformanceHistoryService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private mapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<any> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((historyData) => {
        const historyDto: ChartsDTO = historyData?.data?.TimeSeries?.charts
          ? historyData?.data?.TimeSeries?.charts
          : null;
        const mappperParams: MapperParams = {
          config: this.siteConfigService,
          includeSoftClosed: true,
        };

        return historyDto
          ? this.mapperFactory
              .createMapper(PerformanceHistoryMapper, mappperParams)
              .toDomain(historyDto)
          : null;
      })
    );
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
      shareclasscode: fundDataServiceParams.shareClassCode,
      startvalue: fundDataServiceParams.fromDate
        ? this.parseDate(fundDataServiceParams.fromDate)
        : null,
      endvalue: fundDataServiceParams.toDate
        ? this.parseDate(fundDataServiceParams.toDate)
        : null,
    };
  }

  private parseDate(date: string): any {
    const regex = /-/gi;
    return Number(getDate(formatLocaleDateToStdDate(date), 'YYYYMM'));
  }
}
