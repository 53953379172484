<main edsSpacing="md">
  <ng-container brComponent="lead-content"></ng-container>

  <!-- set z-index: 4; so sticky tabs are above ag grids -->
  <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
    <ng-container brComponent="sticky-content"></ng-container>
  </div>

  <div>
    <ng-container brComponent="body-content"></ng-container>
  </div>

  <div edsGrid>
    <div class="container container--wide">
      <div edsRow>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 7 }">
          <ng-container brComponent="top-left-content"></ng-container>
        </div>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 3 }">
          <div class="emea-layout__card-full-height">
            <ng-container brComponent="top-right-content"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <ng-container brComponent="main-content"></ng-container>
  </div>

  <div edsGrid>
    <div class="container container--wide">
      <div edsRow>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 7 }">
          <ng-container brComponent="bottom-left-content"></ng-container>
        </div>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 3 }">
          <div class="emea-layout__card-full-height">
            <ng-container brComponent="bottom-right-content"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div>
    <ng-container brComponent="lower-content"></ng-container>
  </div>
</main>
