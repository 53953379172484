<div class="debug" edsPadding *ngIf="isDebugWidgetEnabled">
  <h4>Debug</h4>
  <eds-expand-collapse
    collapsedButton="Expand Debug"
    expandedButton="Collapse Debug"
    [top]="true"
  >
    <div class="grid">
      <div class="row">
        <div class="col">
          <button
            (click)="clear()"
            title='add"?debug=web-platform" to url again to see again after'
          >
            Clear Settings and Hide
          </button>
          <br />

          <label>
            <input
              type="checkbox"
              [checked]="debugCaveats$ | async"
              (click)="toggleCaveatDebug()"
            />
            Debug Caveats </label
          ><br />

          <label>
            <input
              type="checkbox"
              [checked]="showDummyCaveats$ | async"
              (click)="toggleShowDummyCaveats()"
            />
            Show Dummy Caveats </label
          ><br />

          <label>
            <input
              type="checkbox"
              [checked]="debugPortfolio$ | async"
              (click)="togglePortfolioDebug()"
            />
            Debug Portfolio Components</label
          ><br />

          <label>
            <input
              type="checkbox"
              [checked]="showTooltipKeys$ | async"
              (click)="toggleShowTooltipKeys()"
              [disabled]="showTooltipKeysDisabled"
            />
            Show Tooltip Keys </label
          ><br />

          <label>
            <input
              type="checkbox"
              [checked]="showLabelKeys$ | async"
              (click)="toggleShowLabelKeys()"
              [disabled]="showLabelKeysDisabled"
            />
            Show Label Keys </label
          ><br />
          <label>
            <input
              type="checkbox"
              [checked]="showInsightsData$ | async"
              (click)="toggleShowInsightsData()"
              [disabled]="showInsightsDataDisabled"
            />
            Show Insights Tags</label
          ><br />
          <label>
            <input
              type="checkbox"
              [checked]="showInsightsReport$ | async"
              (click)="toggleShowInsightsReport()"
              [disabled]="showInsightsReportDisable"
            />
            Show Insights Report</label
          ><br />

          <label>
            <input
              type="checkbox"
              [checked]="debugExpandAll$ | async"
              (click)="toggleExpandAll()"
            />
            Expand All Components </label
          ><br />

          <button (click)="showLabels()">Show Loaded Labels</button>

          <br />
          <pre *ngIf="this.labels" class="debug__labels">{{
            this.labels | json
          }}</pre>
        </div>

        <div class="col">
          <h5>Local Storage / Cookies</h5>
          <eds-info-item
            [info]="{ label: 'Segment', val: storedSegmentId }"
          ></eds-info-item>
          <eds-info-item
            [info]="{
              label: 'Profile Summary',
              val: storedProfileSummary | json
            }"
          ></eds-info-item>
          <eds-info-item
            [info]="{ label: 'testProfileId', val: storedTestProfileId }"
          ></eds-info-item>
          <p>
            <em
              >These values don't update automatically, so click 'Refresh' below
              to update</em
            >
          </p>
          <button (click)="refreshCookies()">Refresh</button>
        </div>

        <div class="col">
          <h5>Segment</h5>
          <eds-info-item
            [info]="{
              label: 'Segment',
              val: segmentId$ | async
            }"
          ></eds-info-item>

          <h5>Profile</h5>
          <eds-info-item
            [info]="{
              label: 'is logged in',
              val: isLoggedIn$ | async
            }"
          ></eds-info-item>
          <eds-info-item
            [info]="{
              label: 'Profile role',
              val: (profile$ | async)?.profileInfo?.role
            }"
          ></eds-info-item>

          <h6>Profile Key:</h6>
          <pre>{{ profileKey$ | async }}</pre>
          <h5>Set Profile</h5>
          <select
            class="select-option"
            [(ngModel)]="testProfileId"
            #profileSelector
            (change)="changeProfile(profileSelector.value)"
          >
            <option
              class="option"
              *ngFor="let profile of testProfiles"
              [value]="profile.name"
            >
              {{ profile.name }}
            </option>
          </select>

          <h6>Validated Profile:</h6>
          <pre>{{ profile$ | async | json }}</pre>

          <h6>Unvalidated Profile:</h6>
          <pre>{{ unvalidatedProfile$ | async | json }}</pre>
        </div>

        <div class="col">
          <h5>Page Loading</h5>
          <eds-info-item
            [info]="{
              label: 'Page Ready',
              val: (isPageReady$ | async)
            }"
          ></eds-info-item>
          <!-- TODO: async component monitoring isn't fully implemented yet -->
          <eds-info-item
            [info]="{
              label: 'Async Components Loaded',
              val: (asyncComponentsReady$ | async)
            }"
          ></eds-info-item>
          <eds-info-item
            [info]="{
              label: 'Images Loaded',
              val: (imagesReady$ | async)
            }"
          ></eds-info-item>
          <table>
            <thead>
              <tr>
                <th>Component</th>
                <th>Type</th>
                <th>Status</th>
                <th>Section</th>
                <th>Duration (ms)</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let cmp of pageComponentsStatus$ | async">
                <td>{{ cmp.name }}</td>
                <td>{{ cmp.isAsync ? "async" : "sync" }}</td>
                <td>{{ cmp.status }}</td>
                <td>{{ cmp.sectionId }}</td>
                <td>{{ cmp.duration }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </eds-expand-collapse>
</div>
