<!-- Loop block items through -->
<div
  *ngFor="let item of contentBlock?.items"
  [ngSwitch]="item.contentType"
  [edsSpacing]="spacing"
>
  <ng-template ngSwitchCase="ftcore:TitleBlock">
    <div class="block-item">
      <ft-title-block
        [content]="item"
        [titleColorTheme]="titleColorTheme"
      ></ft-title-block>
    </div>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:IntroBlock">
    <ft-text-block [content]="item"></ft-text-block>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:ParagraphBlock">
    <ft-text-block [content]="item"></ft-text-block>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:ParagraphBlockFullHtml">
    <div edsRow>
      <div
        edsCol
        class="ck-editor"
        [innerHTML]="item?.contentFull?.value | safeHtml"
      ></div>
    </div>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:Profiles">
    <ft-avatar-block [content]="item" [page]="page"></ft-avatar-block>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:Blockquote">
    <div class="block-item">
      <ft-blockquote-block [content]="item"></ft-blockquote-block>
    </div>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:PullQuoteAvatar">
    <div class="block-item">
      <ft-blockquote-avatar-block [content]="item"></ft-blockquote-avatar-block>
    </div>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:ChartBlock">
    <div [innerHTML]="item.chart | safeHtml"></div>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:EmbeddedVideo">
    <eds-video [embedScript]="item.videoEmbedCode"></eds-video>
  </ng-template>
  <ng-template ngSwitchCase="ftcore:tableStyleCompound">
    <ft-table-selection [tableData]="item" [page]="page"></ft-table-selection>
  </ng-template>

  <ng-template ngSwitchCase="ftcore:InteractiveComponentCompound">
    <ft-interactive-content-block
      [component]="component"
      [page]="page"
      [content]="item"
    ></ft-interactive-content-block>
  </ng-template>
  <!-- Default ImageBlock  -->
  <ng-template ngSwitchDefault>
    <!-- Button block-->
    <ng-container *ngIf="item.buttons; else image">
      <ft-buttons-block [content]="item" [page]="page"></ft-buttons-block
    ></ng-container>
    <!-- Image Block-->
    <ng-template #image>
      <ft-image-block [content]="item"></ft-image-block
    ></ng-template>
  </ng-template>
</div>
