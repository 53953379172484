import {
  AfterViewChecked,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  Component as BrComponent,
  Content,
  Menu,
  Page,
} from '@bloomreach/spa-sdk/';
import {
  BreadcrumbItem,
  DialogComponent,
  SpacingValue,
  TitleTheme,
} from '@frk/eds-components';
import { Logger } from '@utils/logger';
import { getModelFromRef } from '@utils/pagemodel-utils';
import get from 'lodash/get';
/**
 * Logger
 */
const logger = Logger.getLogger('AbstractBaseComponent');

/**
 * Base component
 *
 * This is an abstract component used to read common parameters
 *
 */
@Component({
  template: ``,
})
export class AbstractBaseComponent
  implements OnInit, AfterViewChecked, OnChanges {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;

  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  /**
   * The Bloomreach page
   */
  @Input() hideAnimation?: boolean;

  /**
   * Default component padding
   */
  defaultPadding = '0';

  /**
   * Default margin bottom
   */
  defaultMarginBottom = 'var(--space-xxl)';

  /**
   * Last closed Dialog
   */
  closedDialog: DialogComponent;

  /**
   * Link clicked from inside the component
   */
  linkClicked: string;

  /**
   * Breadcrumbs
   */
  breadcrumbs: {
    title: string;
    url: string;
    routerLink: any[];
  }[];

  /**
   * Label marker for preview
   */
  labelIcon: string;

  /**
   * BR Menu Item
   */
  menuItem;

  /**
   * Component Name
   */
  componentName: string;

  /**
   * Component Label
   */
  componentLabel: string;

  /**
   * Temporary placeholder-- asset exists in core only
   */
  localImagePlaceholder = '/assets/icons/placeholder.svg';

  breadcrumbItems: BreadcrumbItem[];

  isEditMode = false;

  /**
   * Constructor
   */
  constructor() {
    // Constructor placeholder
  }

  /**
   * On Changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    // OnChanges placeholder
  }

  /**
   * After view checked
   */
  ngAfterViewChecked(): void {
    // AfterViewChecked placeholder
  }

  /**
   * On initialisation
   */
  ngOnInit() {
    this.componentName = this.component?.getName();
    this.componentLabel = get(this.component, ['model', 'label']);
  }

  /**
   * Get Breadcrumb Items - this should be called on the components where Breadcrumbs are used
   * e.g. Banner, Literature, Articles etc.
   */
  protected setBreadcrumbItems() {
    /**
     * Breadcrumbs from page model response
     */
    this.breadcrumbs = this.component?.getModels()?.breadcrumbs;
    this.breadcrumbItems = this.createBreadcrumbItems(this.breadcrumbs);
  }

  /**
   * Get container from page model
   */
  get container() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get container()`);
    const container = this.component.getParameters().container;
    return container || 'container'; // default to 8 column width
  }

  /**
   * Get document from page model
   */
  get document(): Content {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get document()`);
    const { document } = this.component.getModels<DocumentModels>();
    const content = document && this.page.getContent(document);
    return content?.getData && content; // need to check for getData because if empty it doesnt return a Content object (just a plain one).
  }

  /**
   * Get component background theme
   */
  get background() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get background()`);
    return this.component.getParameters().background;
  }

  /**
   * Return theme
   */
  get theme() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get theme()`);
    return (
      this.component.getParameters().background ||
      this.component.getParameters().theme
    );
  }

  /**
   * IsCondensed used for expanded banner
   */
  get isCondensed() {
    return this.component.getParameters().isCondensed;
  }

  /**
   * Return theme
   */
  get titleTheme(): 'primary' | 'dark' {
    return this.component.getParameters().titleTheme;
  }

  /**
   * Return title Color
   */
  get titleColor(): TitleTheme {
    return this.component?.getParameters()?.titleColor || 'basic';
  }

  /**
   * Has Meta (TODO: Needs Fixed)
   * TODO: Improve to use utility classes
   */
  get hasMeta() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get hasMeta()`);
    let ret = this.document.getMeta();
    if (!ret && this.document) {
      this.document.getData().meta = {};
      ret = this.document.getMeta();
    }
    return ret;
  }

  /**
   * Get margin bottom from component parameters
   */
  get marginBottom(): SpacingValue | 'auto' {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get marginBottom()`);
    return this.component.getParameters().marginBottom;
  }

  /**
   * Remove margin bottom from component parameters
   */
  get removeMarginBottom() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get removeMarginBottom()`);
    return this.component.getParameters().removeMarginBottom;
  }

  get backgroundColor() {
    return this.component.getParameters().background?.toLowerCase();
  }

  /**
   * Get padding from component parameters
   */
  get padding() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get padding()`);
    return this.component.getParameters().padding;
  }

  /**
   * Show breadcrumbs toggle
   */
  get showBreadcrumb() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get showBreadcrumb()`);
    return this.component.getModels()?.showBreadcrumb;
  }

  /**
   * Creates breadcrumbItems for EDS component
   * @param breadcrumbs - Array of breadcrumbs
   */
  createBreadcrumbItems(breadcrumbs): Array<BreadcrumbItem> {
    const breadcrumbItems = [];

    breadcrumbs?.forEach((breadcrumb) => {
      breadcrumbItems.push({
        content: breadcrumb.title,
        href: breadcrumb.url,
        routerLink: [breadcrumb.url],
      });
    });
    return breadcrumbItems;
  }

  /**
   * Read menu from data model
   */
  get menu() {
    // logger.debug(`${this.component?.getName()}: ${get(this.component, ['model', 'label'])}: get menu()`);
    this.menuItem = this.component.getModels<MenuModels>()?.menu;
    return this.menuItem;
  }

  // for overlay button in XM
  get menuModel(): Menu {
    return (getModelFromRef(
      this.page,
      this.component,
      'menu'
    ) as unknown) as Menu;
  }

  /**
   * Get Channel language
   */
  getChannelLanguage() {
    return get(this.page, 'model.channel.info.props["Language"]', 'Language');
  }
}
