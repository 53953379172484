import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ProductDetailLoadingService } from '@products/product-detail-layout/product-detail-loading.service';
import { PdsRequestBody } from '@services';
import { Logger } from '@utils/logger';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { NAVIGATOR } from '@ng-web-apis/common';

const logger = Logger.getLogger('FundApiInterceptor');

@Injectable({ providedIn: 'root' })
export class FundApiInterceptor implements HttpInterceptor {
  constructor(
    private productLoaderService: ProductDetailLoadingService,
    @Inject(NAVIGATOR) readonly navigatorRef: Navigator
  ) {}

  public intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // WDE-4491 quick fix to cope with pds url
    // TODO: in future, could we use the pds api url value from EnvConfig service instead?
    if (
      request.url.indexOf('/pds-graphql/') !== -1 ||
      request.url.indexOf('/api/pds/') !== -1
    ) {
      request = request.clone({
        withCredentials: false,
        headers: new HttpHeaders({
          Accept: 'application/json',
          'Content-Type': 'application/json',
        }),
      });

      this.productLoaderService.addRequest(request);

      const httpEvent: Observable<HttpEvent<PdsRequestBody>> = next
        .handle(request)
        .pipe(share());

      httpEvent.subscribe(
        (event: HttpEvent<PdsRequestBody>): void => {
          if (event instanceof HttpResponse) {
            logger.debug(
              'fund API response while getting pds-graphql: ',
              request.url
            );
            this.productLoaderService.completeRequest(request);
          }
        },
        (err) => {
          logger.error(
            'fund API error while getting pds-graphql: ',
            request.url,
            'request variables: ',
            request.body?.variables,
            'browser online:',
            this.navigatorRef.onLine,
            'err:',
            err
          );
          this.productLoaderService.completeRequest(request);
        },
        () => {
          logger.debug(
            'fund API complete while getting pds-graphql: ',
            request.url
          );
        }
      );

      return httpEvent;
    } else {
      return next.handle(request);
    }
  }
}
