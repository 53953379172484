import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SubscriptionsProductsComponent } from './subscriptions-products/subscriptions-products.component';
import {
  PaddingModule,
  ProgressBarModule,
  SimpleModalModule,
  SpacingModule,
} from '@frk/eds-components';
import { SharedModule } from '@shared/shared.module';
import { AgGridModule } from '@ag-grid-community/angular';
import { FormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { WatchlistCellRendererComponent } from './watchlist-cell-renderer/watchlist-cell-renderer.component';

@NgModule({
  declarations: [
    SubscriptionsProductsComponent,
    WatchlistCellRendererComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AgGridModule,
    FormsModule,
    NgxSpinnerModule,
    PaddingModule,
    ProgressBarModule,
    SimpleModalModule,
    SpacingModule,
  ],
  exports: [SubscriptionsProductsComponent],
})
export class SubscriptionsManageModule {}
