// WIP
// TODO: get definitive list of curency codes
export enum CurrencyCode {
  AUD = 'AUD',
  CAD = 'CAD',
  CHF = 'CHF',
  CNH = 'CNH',
  CZK = 'CZK',
  EUR = 'EUR',
  GBP = 'GBP',
  HKD = 'HKD',
  HUF = 'HUF',
  JPY = 'JPY',
  NOK = 'NOK',
  PLN = 'PLN',
  SEK = 'SEK',
  SGD = 'SGD',
  USD = 'USD',
  ZAR = 'ZAR',
  INR = 'INR',
}
