export enum RefreshRate {
  MONTHLY = 'monthly',
  DAILY = 'daily',
  WEEKLY = 'weekly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
}

export type RefreshRateObj = {
  refreshRate: RefreshRate;
};
