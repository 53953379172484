import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IUserProfileInfo } from '@services/profile.interface';
import { Logger } from '@utils/logger';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

const logger = Logger.getLogger('PCSTooLService');

@Injectable({
  providedIn: 'root',
})
export class PcsToolService {
  constructor(private http: HttpClient) {}

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      logger.error('An error occurred:', error.error);
    } else {
      logger.error(
        `Backend returned code ${error.status}, body was: `,
        error.error
      );
    }
    return throwError(
      'Unable to save terms acceptance; please try again later.'
    );
  }

  saveTermsAcceptance$(
    pcsDomain: string,
    profileData: IUserProfileInfo,
    apiKey: string,
    token: string
  ) {
    const url = `${pcsDomain}/v1/us/common/profiles/users/${profileData.userSysNo}/updateagreementinfo`;
    const data = {
      usrSysNo: profileData.userSysNo,
      userGroup: profileData.userGroup,
      pcsConsentFlag: 'Y',
      userId: profileData.userId,
    };
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
        'X-FT-API-KEY': apiKey,
      }),
    };

    return this.http
      .put(url, data, httpOptions)
      .pipe(catchError(this.handleError));
  }
}
