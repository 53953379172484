/**
 * WARNING: This service has been deprecated in favour of SiteConfigurationService
 * Service to hold global configuration passed from Bloomreach.
 */
import { Inject, Injectable } from '@angular/core';
import { Content, Page, Reference } from '@bloomreach/spa-sdk';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  CommonConfig,
  GlobalConfig,
  LanguageData,
  MultilingualMap,
  SiteParams,
  SiteSearchConfig,
  SiteSearchParams,
} from '@types';
import get from 'lodash/get';
import { setLocale as setMomentLocale } from '@utils/il8n/moment-il8n';
import { setNumberFormatLocale } from '@utils/il8n/number-il8n';
import { LOCATION } from '@ng-web-apis/common';
import { Meta } from '@angular/platform-browser';
import { Logger } from '@utils/logger';
import { Strategy } from '../types/strategy-config.type';

const logger = Logger.getLogger('GlobalConfigService');

@Injectable({
  providedIn: 'root',
})
export class GlobalConfigService {
  config: GlobalConfig = {};
  globalConfigSubject$: BehaviorSubject<GlobalConfig> = new BehaviorSubject<GlobalConfig>(
    this.config
  );

  constructor(
    @Inject(LOCATION) readonly locationRef: Location,
    private meta: Meta
  ) {}

  /**
   * Initialise from first request
   */
  initialiseFromPage(page: Page) {
    let triggerSubject = false;
    const multilingualMap: MultilingualMap[] = page.getComponent().getModels()
      ?.pageData?.multilingualMap;
    if (!this.config.siteConfiguration) {
      // only initialise once
      const locale = page.getChannelParameters().Language;
      if (locale) {
        setMomentLocale(locale);
        setNumberFormatLocale(locale);
        // highcharts locale should be configured on lazy loading of high charts.
      }

      const siteConfigurationRef: Reference = page
        .getComponent('site-configuration')
        ?.getModels()?.siteConfiguration;
      const configContent: Content = page.getContent(siteConfigurationRef);
      if (configContent) {
        this.config.siteConfiguration = JSON.parse(
          configContent.getData().config
        );
        // If maintenance Mode is set in site config redirect to the maintenance page
        if (this.config.siteConfiguration?.site?.maintenanceMode) {
          if (this.config.siteConfiguration?.site?.maintenancePageUrl) {
            this.meta.addTag({
              name: 'prerender-status-code',
              content: '503',
            });
            this.locationRef.href = this.config.siteConfiguration.site.maintenancePageUrl;
          } else {
            logger.error('maintenance page url is not set');
          }
        }
        this.config.siteConfiguration.languages = configContent.getData().multilingual;
        triggerSubject = true;
      }
    }
    const strategyConfigurationRef: Reference = page
      .getComponent('strategy-configuration')
      ?.getModels()?.strategyConfiguration;
    if (!this.config.strategyConfiguration && strategyConfigurationRef) {
      this.setStrategyConfiguration(page, strategyConfigurationRef);
    }
    // Trigger subject when there is no config set or page have multilingual settings
    if (
      multilingualMap?.length > 0 ||
      triggerSubject ||
      this.config?.siteConfiguration?.site?.multilingualMapping
    ) {
      this.config.multilingualMap = multilingualMap;
      this.globalConfigSubject$.next(this.config);
    }
  }

  private setStrategyConfiguration(
    page: Page,
    strategyConfigurationRef: Reference
  ) {
    const configContent: Content | undefined = page.getContent(
      strategyConfigurationRef
    );

    if (configContent) {
      const assetGroup = configContent.getData().assetGroup;

      this.config.strategyConfiguration = assetGroup.map((configuration) => ({
        ...configuration,
        assetClass: configuration.assetClass?.map((asset) => ({
          ...asset,
          strategy: asset.strategy?.map((str) => ({
            ...str,
            strategyPageLink: this.getContent(str.strategyPageLink, page),
            dataFilesCompound: {
              characteristics: this.getContent(
                str.dataFilesCompound?.characteristics,
                page
              ),
              core: this.getContent(str.dataFilesCompound?.core, page),
              performance: this.getContent(
                str.dataFilesCompound?.performance,
                page
              ),
            },
          })),
        })),
      }));
    }
  }

  private getContent(field: any, page: Page): Content {
    if (field) {
      return page.getContent(field);
    }
    return null;
  }

  getGlobalConfigSubject$(): Observable<GlobalConfig> {
    return this.globalConfigSubject$.asObservable();
  }

  /**
   * Returns site languages
   */
  getLanguages(): Array<LanguageData> {
    return get(this.config, 'siteConfiguration.languages', []);
  }

  /**
   * Returns site parameters
   */
  getSiteParams(): SiteParams {
    return get(this.config, 'siteConfiguration.siteParams', {});
  }

  /**
   * Returns general site parameters
   */
  getSiteGeneral(): CommonConfig {
    return get(this.config, 'siteConfiguration.site', {});
  }

  /**
   * Returns search parameters node
   */
  getSearchParameters(): SiteSearchParams {
    return get(this.config, 'siteConfiguration.search', {});
  }

  /**
   * Returns search config node
   */
  getSearchConfig(): SiteSearchConfig {
    return get(this.config, 'siteConfiguration.searchConfig', {});
  }

  /**
   * Returns multilingualMapping flag from site configuration
   * @returns bolean - multilingualMapping flag
   */
  public getMultilingualMapping(): boolean {
    return !!this.config.siteConfiguration.site?.multilingualMapping;
  }
}
