import dayjs from 'dayjs';
import itConfig from 'dayjs/locale/it';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('it-it', { ...itConfig });
export const customItItConfig = dayjs.updateLocale('it-it', {
  ...itConfig,
  months: 'Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre'.split(
    '_'
  ),
  monthsShort: 'Gen_Feb_Mar_Apr_Mag_Giu_Lug_Ago_Set_Ott_Nov_Dic'.split('_'),
  weekdays: 'Domenica_Lunedì_Martedì_Mercoledì_Giovedì_Venerdì_Sabato'.split(
    '_'
  ),
  weekdaysShort: 'Dom_Lun_Mar_Mer_Gio_Ven_Sab'.split('_'),
  formats: {
    ...itConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
