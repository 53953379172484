import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  GraphQLFundDataService,
  FundDataServiceParams,
} from './graphql-fund-data.service';
import { DocumentNode } from '@apollo/client/core';
import { Logger } from '@utils/logger';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { ProductDTO } from '@types';
import { MapperParams } from '@products/utils/mappers/type.mapper';
import { ProductMapper } from '@products/utils/mappers/product.type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { SiteConfigService } from '@services/site-config.service';
import { Product } from '@models';
import {
  formatLocaleDateToStdDate,
  getLastDayOfMonth,
} from '@utils/text/date-utils';
import { TranslateService } from '@shared/translate/translate.service';
import { NjTreasuryProductRaw } from '../../interactive-content/njbest/treasury-managed-price-and-performance/treasury-managed-price-and-performanc.interface';
import { CanadaRiskObject } from '@products/overview/fund-content/fund-content.component';

const logger = Logger.getLogger('FundOverviewService');

@Injectable({
  providedIn: 'root',
})
export class FundOverviewService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private translateService: TranslateService,
    private mapperFactory: MapperFactory
  ) {
    super(apollo, siteConfigService);
  }

  public register(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<Product> {
    return super.register(query, this.getVariables(fundDataServiceParams)).pipe(
      map((productDetail) => {
        const productDto: ProductDTO = productDetail?.data?.Overview
          ? productDetail.data.Overview
          : null;
        const mapperParams: MapperParams = {
          config: this.siteConfigService,
          translateService: this.translateService,
          includeSoftClosed: true,
        };

        return productDto
          ? this.mapperFactory
              .createMapper(ProductMapper, mapperParams)
              .toDomain(productDto)
          : null;
      })
    );
  }

  /**
   * Runs a given graphql query.
   * @param query query to be executed
   * @param fundDataServiceParams params for query
   * @returns Graphql query data
   */
  public registerWithoutMapper(
    query: DocumentNode,
    fundDataServiceParams: FundDataServiceParams
  ): Observable<NjTreasuryProductRaw | CanadaRiskObject> {
    return super.register(query, this.getVariables(fundDataServiceParams));
  }

  /**
   * Return required variables value to graphql query.
   * @param fundDataServiceParams params required for graphql query
   */
  private getVariables(
    fundDataServiceParams: FundDataServiceParams
  ): Record<string, any> {
    return {
      countrycode: this.getCountry(),
      languagecode: this.getLanguage(),
      // countrycode: 'CH', // this.getCountry(),
      // languagecode: 'en_GB', // this.getLanguage(),
      fundid: fundDataServiceParams.fundId,
      shareclasscode: fundDataServiceParams.shareClassCode,
      asofdate: fundDataServiceParams.toDate
        ? this.parseDate(fundDataServiceParams.toDate)
        : undefined,
      year: fundDataServiceParams.year ? fundDataServiceParams.year : undefined,
    };
  }

  private parseDate(date: string): any {
    return Number(
      getLastDayOfMonth(formatLocaleDateToStdDate(date), 'YYYYMMDD')
    );
  }
}
