import { RiskStatsSummary } from '@products/models/risk-stats-summary';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  CurrencyCode,
  FundId,
  RiskStatsSummaryDTO,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class RiskStatsSummariesMapper extends Mapper<RiskStatsSummary[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(riskStatsSummariesDTO: RiskStatsSummaryDTO[]): RiskStatsSummary[] {
    const riskStatsSummaries: RiskStatsSummary[] = [];

    riskStatsSummariesDTO.forEach(
      (riskStatsSummaryDTO: RiskStatsSummaryDTO) => {
        const riskStatsSummary: RiskStatsSummary = this.createMapper(
          RiskStatsSummaryMapper
        ).toDomain(riskStatsSummaryDTO);

        if (riskStatsSummary) {
          riskStatsSummaries.push(riskStatsSummary);
        }
      }
    );

    return riskStatsSummaries;
  }
}

export class RiskStatsSummaryMapper extends Mapper<RiskStatsSummary> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(riskStatsSummaryDTO: RiskStatsSummaryDTO): RiskStatsSummary {
    return {
      asOfDateStd: riskStatsSummaryDTO.asofdatestd,
      asOfDate: riskStatsSummaryDTO.asofdate,
      fundId: riskStatsSummaryDTO.fundid as FundId,
      shareClassCode: riskStatsSummaryDTO.shclcode as ShareClassCode,
      currencyCode: riskStatsSummaryDTO.currcode as CurrencyCode,
      calculationName: riskStatsSummaryDTO.calcname,
      benchmarkNumber: riskStatsSummaryDTO.bmnumber,
      benchmarkName: riskStatsSummaryDTO.bmname,
      benchmarkLabel: riskStatsSummaryDTO.bmlabel,
      countryCode: riskStatsSummaryDTO.cntrycode as ConfigurationCountry,
      languageCode: riskStatsSummaryDTO.langcode as ConfigurationLocale,
      displayOrder: riskStatsSummaryDTO.disporder,
      elementNameStd: riskStatsSummaryDTO.elemnamestd,
      elementName: riskStatsSummaryDTO.elemname,
      elementValueFor1Year: riskStatsSummaryDTO.elemval1yr,
      elementValueFor2Year: riskStatsSummaryDTO.elemval2yr,
      elementValueFor3Year: riskStatsSummaryDTO.elemval3yr,
      elementValueFor4Year: riskStatsSummaryDTO.elemval4yr,
      elementValueFor5Year: riskStatsSummaryDTO.elemval5yr,
      elementValueFor6Year: riskStatsSummaryDTO.elemval6yr,
      elementValueFor7Year: riskStatsSummaryDTO.elemval7yr,
      elementValueFor8Year: riskStatsSummaryDTO.elemval8yr,
      elementValueFor9Year: riskStatsSummaryDTO.elemval9yr,
      elementValueFor10Year: riskStatsSummaryDTO.elemval10yr,
      elementValueFor15Year: riskStatsSummaryDTO.elemval15yr,
      elementValueFor20Year: riskStatsSummaryDTO.elemval20yr,
      elementValueSinceInception: riskStatsSummaryDTO.elemvalactinc,
    };
  }
}
