import { Component, Input, OnInit } from '@angular/core';
import { Logger } from '@utils/logger';
import { Component as BrComponent } from '@bloomreach/spa-sdk/';
import { Page } from '@bloomreach/spa-sdk';
import { BreadcrumbItem } from '@frk/eds-components';
import { ViewModeService } from '@services/view-mode.service';

const logger = Logger.getLogger('BreadcrumbComponent');

/**
 * HTML template follows:
 * https://www.w3.org/TR/wai-aria-practices/examples/breadcrumb/index.html
 *
 */
@Component({
  selector: 'ft-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  breadcrumbs: {
    title: string;
    url: string;
    routerLink: any[];
  }[];
  showBreadcrumb: boolean;
  showShare: boolean;
  breadcrumbItems: BreadcrumbItem[];

  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
  }

  ngOnInit() {
    logger.info('initialized');
    this.breadcrumbs = this.component.getModels()?.breadcrumbs;
    this.showBreadcrumb = this.component.getModels()?.showBreadcrumb;
    this.showShare = this.component.getModels()?.showShare;

    // Convert to EDS format
    this.breadcrumbItems = this.createBreadcrumbItems(this.breadcrumbs);
  }

  /**
   * Creates breadcrumbItems for EDS component
   * @param breadcrumbs - Array of breadcrumbs
   */
  createBreadcrumbItems(breadcrumbs): Array<BreadcrumbItem> {
    const breadcrumbItems = [];

    breadcrumbs.forEach((breadcrumb) => {
      const url = breadcrumb.url !== '' ? breadcrumb.url : '/';
      breadcrumbItems.push({
        content: breadcrumb.title,
        href: url,
        routerLink: [url],
      });
    });
    return breadcrumbItems;
  }
}
