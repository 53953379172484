import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { Portfolio, PortfolioChars } from '@products/models/portfolio';
import { PortfolioCharsDTO, PortfolioDTO } from '@types';

export class PortfolioCharsMapper extends Mapper<PortfolioChars> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(portfolioCharDTO: PortfolioCharsDTO): PortfolioChars {
    const portfolioChar = {
      elementValue: portfolioCharDTO.elemvaluelocal,
      elementName: portfolioCharDTO.elemname,
      elemtTranslatedname: portfolioCharDTO.elemtransname,
    };
    return portfolioChar;
  }
}
