<ng-container *ngIf="!isWide">
  <main edsSpacing="md">
    <ng-container brComponent="lead-content"></ng-container>

    <!-- set z-index: 4; so sticky tabs are above ag grids -->
    <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
      <ng-container brComponent="sticky-content"></ng-container>
    </div>

    <div edsGrid>
      <div class="container container--wide">
        <div edsRow>
          <div edsCol>
            <ng-container brComponent="title-content"></ng-container>
          </div>
        </div>
        <div edsRow>
          <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 7 }">
            <ng-container brComponent="main-content"></ng-container>
          </div>
          <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 6, l: 3 }">
            <ng-container brComponent="side-content"></ng-container>
          </div>
        </div>
      </div>
    </div>

    <ng-container brComponent="lower-content"></ng-container>
  </main>
</ng-container>

<ng-container *ngIf="isWide">
  <main edsSpacing="md">
    <ng-container brComponent="lead-content"></ng-container>

    <!-- set z-index: 4; so sticky tabs are above ag grids -->
    <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
      <ng-container brComponent="sticky-content"></ng-container>
    </div>

    <div edsGrid>
      <div edsRow>
        <div edsCol>
          <ng-container brComponent="title-content"></ng-container>
        </div>
      </div>
      <div edsRow>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 8, l: 8 }">
          <ng-container brComponent="main-content"></ng-container>
        </div>
        <div edsCol edsSpacing="md" [columnNumbers]="{ s: 4, m: 8, l: 4 }">
          <ng-container brComponent="side-content"></ng-container>
        </div>
      </div>
    </div>

    <ng-container brComponent="lower-content"></ng-container>
  </main>
</ng-container>
