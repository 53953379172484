<!-- TODO: Please find a better way of showing/hiding sign in-->
<div [ngClass]="{ 'has-edit-button': page?.isPreview() }">
  <div edsPadding [edsSpacing]="skin.edsSpacing" *ngIf="hasSignIn">
    <!--EDS -->
    <div edsGrid *ngIf="profile && profile.isLoggedIn && hasSignOut">
      <div edsFlexbox justifyContent="flex-end" flexDirection="row">
        <button
          class="ft__btn sign-in-wrapper__sign-in-btn"
          edsButton="primary"
          size="normal"
          ng-keyup="onKeyUp"
          edsSpacing="base"
          (click)="signOut()"
        >
          <span
            class="ft__btn__txt"
            [innerHTML]="'signin.signOut' | translate"
          ></span>
        </button>
      </div>
    </div>
    <div
      edsGrid
      *ngIf="!profile || !profile.isLoggedIn || signIn?.alwaysVisible"
    >
      <div class="container">
        <div edsRow [ngStyle]="{ 'align-items': 'center', display: 'flex' }">
          <div
            edsCol
            [columnNumbers]="skin.addSectionColNumbers"
            *ngIf="skin.name === 'twoCols' || skin.name === 'threeCols'"
          >
            <eds-section-title
              theme="basic"
              [preTitle]="leftPreTitle"
              [title]="leftTitle"
            ></eds-section-title>

            <p [innerHTML]="leftContent"></p>
          </div>
          <!-- Sign in form -->
          <div
            edsCol
            [offsets]="skin.offsets"
            [columnNumbers]="skin.columnNumbers"
          >
            <eds-sign-in
              *ngIf="signInComponentContent"
              paddingSize="lg"
              [hasBorders]="true"
              [signInComponentFields]="signInComponentContent"
              [formAction]="signInFormAction"
              [hiddenInputs]="signInHiddenInputs"
              [isLegacy]="isLegacy"
              [showLegacyContent]="showLegacyContent"
              [isDisabled]="isSubmitButtonDisabled"
              (onSignIn)="onSubmit($event)"
              [hasMaskedIcon]="true"
              [showMaskedText]="'signin.hide' | translate"
              [hideMaskedText]="'signin.show' | translate"
              (fastTrackRegistrationClick)="fastTrackRegistration()"
              (legacyBtnEmiter)="legacyBtnclick($event)"
            >
            </eds-sign-in>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
