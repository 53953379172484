import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import {
  Chip,
  ChipFilter,
  DropdownItem,
  PaginationComponent,
  ResponsiveService,
} from '@frk/eds-components';
import { AppStateService, WindowScrollService } from '@services';
import { LinkService } from '@services/link.service';
import { TranslateService } from '@shared/translate/translate.service';
import { Link } from '@types';
import startCase from 'lodash/startCase';
import { Observable } from 'rxjs';
import { Approval, Courses, Filter } from './../events.type';

@Component({
  selector: 'ft-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.scss'],
})
export class CoursesComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  @ViewChild(PaginationComponent, { static: false })
  paginationComponent: PaginationComponent;

  public totalPages: number;
  public totalRecords: number;
  public pageSize = { value: 6, label: '6' };
  public itemsPerPageOption: DropdownItem[] = [];
  public isMobile$: Observable<boolean>;
  public isHandheld: boolean;
  public pageTitle: string;
  private chipMap: Map<string, boolean>;
  public filters: Filter[] = [];

  public courses: Courses[];
  public filteredCourses: Courses[] = [];
  public chipsFilters: ChipFilter[] = [];
  public visibleCourses: Courses[] = [];

  constructor(
    private linkService: LinkService,
    private appStateService: AppStateService,
    private translateService: TranslateService,
    private responsiveService: ResponsiveService,
    private titleService: Title,
    private cd: ChangeDetectorRef,
    private scrollService: WindowScrollService
  ) {}

  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.isMobile$();
    this.responsiveService.isHandheld$()?.subscribe((isTablet: boolean) => {
      this.isHandheld = isTablet;
    });
    this.pageSize = {
      value: this.component?.getParameters()?.pagination || 6,
      label: this.component?.getParameters()?.pagination || '6',
    };
    this.pageTitle = this.titleService.getTitle();
    this.courses = this.getCourses();
    this.setFilterData();
    this.filteredCourses = [...this.courses];
    this.filters = this.filters.filter((filter) => filter.items.length > 0);
    this.createDropDownNoOfItems();
    this.updatePagination();
  }

  private setFilterData(): void {
    const allCategories: string[] = [];
    const allApprovals: string[] = [];
    const allLanguages: string[] = [];

    this.courses?.forEach((course: Courses) => {
      allCategories.push(...course.categories);
      course.approvals?.forEach((approval) =>
        allApprovals.push(approval.accreditation)
      );
      allLanguages.push(...course.languages);
    });

    this.filters = [
      this.createFilter('accreditation', 'common.accreditation', allApprovals),
      this.createFilter(
        'categories',
        'common.approval-categories',
        allCategories
      ),
      this.createFilter('languages', 'common.languages', allLanguages),
    ];
  }

  private createFilter(name: string, title: string, items: string[]): Filter {
    return {
      name,
      title: this.translateService.instant(title),
      label: this.translateService.instant(title),
      selectedText: 'selected',
      items: this.createFilterDropdown(items),
    };
  }

  private createFilterDropdown(values: string[]): DropdownItem[] {
    return values
      .sort()
      .filter((val, index, self) => self.indexOf(val) === index)
      .map((val) => ({
        label: startCase(val),
        count: '',
        checked: false,
        value: val.trim(),
        id: `option-${val.trim().toLowerCase().replace(/\s+/g, '-')}`,
      }));
  }

  /**
   * Get Events from content
   */
  public getCourses(): Courses[] {
    const document = this.component?.getModels<DocumentModels>()?.document;
    const content = document && this.page.getContent(document);
    const courses =
      content?.getData()?.courseCard?.map((course) => ({
        courseTitle: course.courseTitle,
        description: course.shortDescription,
        approvals: this.getApprovals(course.approvals),
        categories: course.categories,
        languages: course.language,
        links: this.getLinks(course?.button),
      })) || [];

    return courses;
  }
  private getApprovals(inputArray: any[]): Approval[] {
    return (
      inputArray?.map((input) => {
        const [accreditation, value] = input?.split(':');
        return { accreditation: accreditation || '', value: value || '' };
      }) || []
    );
  }

  public addChips(): ChipFilter[] {
    this.chipMap = new Map();
    const selectedFilters = this.filters
      .map((filter) => {
        return {
          ...filter,
          items: filter.items.filter((filterItem) => filterItem.checked),
        };
      })
      .filter((filter) => filter.items.length > 0)
      .map((filter) => {
        this.chipMap.set(filter.name, true);
        return {
          chips: filter.items.map((filterItem) => ({
            label: filter.title,
            value: filterItem.label,
          })),
        };
      });

    return selectedFilters;
  }

  public onSelectFilter(): void {
    this.chipsFilters = this.addChips();
    this.updateFilteredCourses();
  }

  private updateFilteredCourses(): void {
    let categoryFilterSet: Set<string>;
    let languageFilterSet: Set<string>;
    let approvalFilterSet: Set<string>;

    this.filters?.forEach((filter) => {
      if (filter?.name === 'accreditation') {
        approvalFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'categories') {
        categoryFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'languages') {
        languageFilterSet = this.buildFilterSet(filter?.items);
      }
    });
    this.filteredCourses = [
      ...this.courses.filter((course) => {
        const isCategoryValid =
          !this.chipMap.get('categories') ||
          course.categories?.some((category) =>
            categoryFilterSet.has(category)
          );

        const isLanguageValid =
          !this.chipMap.get('languages') ||
          course.languages?.some((language) => languageFilterSet.has(language));

        const isAccreditationValid =
          !this.chipMap.get('accreditation') ||
          course.approvals?.some((approval) =>
            approvalFilterSet.has(approval?.accreditation)
          );
        return isCategoryValid && isLanguageValid && isAccreditationValid;
      }),
    ];
    this.updatePagination();
  }

  private buildFilterSet(filters: DropdownItem[]): Set<string> {
    const checkedFilters = filters?.filter((filter) => filter?.checked);
    if (checkedFilters?.length === 0) {
      return new Set(filters?.map((filter) => filter?.value));
    } else {
      return new Set(checkedFilters?.map((filter) => filter?.value));
    }
  }

  private resetToDefault(): void {
    this.filteredCourses = this.courses;
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: filter?.items?.map((filterItem) => {
          return {
            ...filterItem,
            checked: false,
          };
        }),
      };
    });
    this.chipsFilters = [];
    this.updatePagination();
  }

  public resetFilter($event: any): void {
    this.resetToDefault();
  }

  public chipRemoved(chip: Chip): void {
    const removedFilterGroup = this.filters.find(
      (filter) => filter.title === chip.label
    );
    if (removedFilterGroup) {
      removedFilterGroup?.items?.forEach((item) => {
        if (item?.label === chip?.value) {
          item.checked = false;
        }
      });
      if (!removedFilterGroup?.items?.find((item) => item.checked)) {
        this.chipMap.delete(removedFilterGroup?.name);
      }
    }
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: [...filter.items],
      };
    });
    this.chipsFilters = this.chipsFilters.filter(
      (chipFilter) => chipFilter?.chips?.length > 0
    );
    this.updateFilteredCourses();
  }

  public onDropdownChange(numberOfRowsEvent): void {
    this.pageSize = this.itemsPerPageOption.find(
      (options) => Number(options.label) === numberOfRowsEvent.value
    );
    this.updatePagination();
  }

  public goToPage(pageEvent): boolean {
    if (pageEvent.page && pageEvent.action !== 'load') {
      this.visibleCourses = this.filteredCourses.slice(
        (pageEvent.page - 1) * this.pageSize.value,
        pageEvent.page * this.pageSize.value
      );
      this.scrollService.scrollToAnchor('courseScrollPosition');
      return true;
    }
  }

  /**
   * Get Links
   */
  private getLinks = (linkRef): Link => {
    let links: any = {};
    if (linkRef) {
      linkRef.forEach((link) => {
        if (link.buttonStyle !== 'secondary') {
          links = link?.linkCollection[0];
          if (links) {
            links.presentationLinkURL = this.linkService?.getCTALink(
              this.page,
              link?.linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        } else {
          links.optionalLink = link?.linkCollection[0];
          links.optionalLink.optionalLinkUrl = this.linkService?.getCTALink(
            this.page,
            link?.linkCollection[0],
            this.appStateService?.getSpaBaseUrl()
          );
        }
      });
    }

    return links;
  };

  public updateFilter(category: string): void {
    // this.resetToDefault();
    this.filters
      .find((filter) => filter.name === 'categories')
      .items.find((item) => item.value === category).checked = true;
    this.onSelectFilter();
  }

  /**
   * Creates dropdown options for number of items.
   */
  private createDropDownNoOfItems(): void {
    this.itemsPerPageOption = [6, 9].map((rows) => ({
      value: rows,
      label: String(rows),
    }));
  }

  private setTotalRecords(): void {
    this.totalRecords = this.filteredCourses.length;
  }

  private updatePagination(): void {
    this.setTotalRecords();
    this.totalPages = Math.ceil(this.totalRecords / this.pageSize?.value);
    this.visibleCourses = this.filteredCourses.slice(0, this.pageSize?.value);
    if (this.paginationComponent) {
      this.paginationComponent.activePage = 1;
    }
    this.paginationComponent?.updatePagination(this.totalPages);
    this.cd.detectChanges();
  }
}
