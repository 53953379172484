import { Injectable } from '@angular/core';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { AppStateService } from './app-state.service';
import {
  DataDogConfig,
  EnvConfig,
  EnvConfigService,
  Environments,
} from './env-config.service';
import { ID_TOKEN_PARAM } from './personalisation-api.service';
import { UserAgentService } from './user-agent.service';

declare var OnetrustActiveGroups; // global var set by One Trust

@Injectable({ providedIn: 'root' })
export class DatadogService {
  private logs: any;
  private rumEnabled: boolean; // true if enabled else false.

  constructor(private userAgentService: UserAgentService) {}

  /**
   * Initialised by AppStateService
   */
  public initDataDog(
    envConfigService: EnvConfigService,
    appStateService: AppStateService,
    locationRef: Location
  ): void {
    const envConfig: EnvConfig = envConfigService.getEnvConfig();
    const params = new URLSearchParams(locationRef.search);
    const userOverride = params.get('datadog');
    const env = this.getEnv(envConfigService, envConfig);
    const config: DataDogConfig = envConfig?.datadog;
    if (this.userAgentService.isRealUser()) {
      this.initLogging(config, env, appStateService);
      this.waitForOneTrustGroups(
        userOverride,
        env,
        (rumOk: boolean, recordingOk: boolean) => {
          if (rumOk) {
            this.initRum(
              userOverride,
              config,
              env,
              envConfigService,
              locationRef,
              appStateService,
              recordingOk
            );
          }
        }
      );
    }
  }

  private initRum(
    userOverride: string,
    config: DataDogConfig,
    env: any,
    envConfigService: EnvConfigService,
    locationRef: Location,
    appStateService: AppStateService,
    recordingOk: boolean
  ): void {
    if (config.rumEnabled) {
      this.rumEnabled = true;
      const sessionSampleRate =
        userOverride ||
        envConfigService.isPre() ||
        envConfigService.isContentPreview() ||
        locationRef.search.indexOf(ID_TOKEN_PARAM) > -1
          ? 100
          : config.sampleRate;
      // initialize RUM
      datadogRum?.init({
        applicationId: config.applicationId,
        clientToken: config.token,
        site: config.site,
        service: config.service,
        silentMultipleInit: true,
        env,
        allowedTracingOrigins: [
          'http://' + locationRef.hostname,
          'https://' + locationRef.hostname,
        ],
        sessionSampleRate,
        sessionReplaySampleRate: config.premiumSampleRate,
        trackUserInteractions: config.trackInteractions,
        trackFrustrations: config.trackFrustrations,
        trackResources: true,
        defaultPrivacyLevel: 'mask-user-input',
        version: appStateService.getBuildVersion(),
      });
      datadogRum?.addRumGlobalContext('channel', appStateService.getChannel());
      datadogRum?.setUser({
        name: userOverride || 'anon',
      });
      if (sessionSampleRate > 0 && recordingOk) {
        datadogRum?.startSessionReplayRecording();
      }
    } else {
      this.rumEnabled = false;
    }
  }

  private initLogging(
    config: DataDogConfig,
    env: any,
    appStateService: AppStateService
  ): void {
    if (config?.logsEnabled) {
      this.logs = datadogLogs;
      this.logs?.init({
        clientToken: config.token,
        site: config.site,
        service: config.service,
        env,
        forwardErrorsToLogs: false,
        sampleRate: 100,
      });

      this.logs?.addLoggerGlobalContext(
        'channel',
        appStateService.getChannel()
      );
    }
  }

  private getEnv(
    envConfigService: EnvConfigService,
    envConfig: EnvConfig
  ): Environments {
    // we need to distinguish users from pre and content preview separately in datadog but they share config with Prod
    if (envConfigService.isContentPreview()) {
      return Environments.CONTENT_PREVIEW;
    } else if (envConfigService.isPre()) {
      return Environments.PRE;
    } else {
      return envConfig.environment as Environments;
    }
  }

  /**
   * OneTrust creates a global var to check which categoreis have been consented to.
   * However, it may not have been created by the time the logging is initialized.
   */
  private waitForOneTrustGroups(
    userOverride: string,
    env: Environments,
    callback: (rumOk: boolean, recordingOk: boolean) => void
  ): void {
    if (env !== Environments.PROD || userOverride) {
      callback(true, true);
    } else if (typeof OnetrustActiveGroups !== 'undefined') {
      // set recordingOk to false until privacy review complete
      callback(OnetrustActiveGroups?.indexOf('C0002') > -1, true); // OnetrustActiveGroups?.indexOf('C0004') > -1,
    } else {
      setTimeout(
        () => this.waitForOneTrustGroups(userOverride, env, callback),
        50
      ); // retry in a bit
    }
  }

  public getLogs(): any {
    return this.logs;
  }

  public isRumEnabled(): boolean {
    return this.rumEnabled;
  }

  // sends a custom action to datadog
  public addAction(name: string, data: any): void {
    datadogRum?.addAction(name, data);
  }

  public updateUser(userData: any): void {
    Object.getOwnPropertyNames(userData).forEach((prop) =>
      datadogRum?.setUserProperty(prop, userData[prop])
    );
  }
}
