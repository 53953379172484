import { Injectable } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { LabelCollection, Labels } from '@utils/labels';

@Injectable({
  providedIn: 'root',
})
export class LabelsService {
  constructor() {}

  /**
   * Common labels
   * @param page - page object
   */
  loadCommonLabels(page: Page) {
    const labels = page.getComponent('site-configuration')?.getModels()[
      'ft.core.common'
    ];
    if (labels) {
      Labels.addLabels(LabelCollection.common, labels);
    }
  }

  /**
   * Language labels
   * @param page - page object
   */
  loadLanguageLabels(page) {
    const labels = page.getComponent('site-configuration')?.getModels()[
      'ft.core.languages'
    ];
    if (labels) {
      Labels.addLabels(LabelCollection.languages, labels);
    }
  }

  /**
   * Loads Country labels
   * @param page - page object
   */
  loadCountryLabels(page) {
    const labels = page?.getComponent('site-configuration')?.getModels()[
      'ft.core.countries'
    ];
    if (labels) {
      Labels.addLabels(LabelCollection.countries, labels);
    }
  }

  /**
   * SignIn labels
   * @param page - page object
   */
  loadSignInLabels(page) {
    const labels = page?.getComponent('site-configuration')?.getModels()[
      'ft.core.signin'
    ];
    if (labels) {
      Labels.addLabels(LabelCollection.signIn, labels);
    }
  }

  /**
   * Get Labels
   * @param labelCollection - label from label collection
   */
  initLabels(labelCollection: string) {
    return Labels.getLabels(LabelCollection[labelCollection]);
  }
}
