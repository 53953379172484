import { GridModule, SpacingModule } from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TextBlockComponent } from './text-block.component';
import { PipesModule } from '../../../shared/pipes/pipes.module';

@NgModule({
  declarations: [TextBlockComponent],
  imports: [BrSdkModule, CommonModule, GridModule, SpacingModule, PipesModule],
  exports: [TextBlockComponent],
  entryComponents: [TextBlockComponent],
})

/**
 * Image Block Module
 */
export class TextBlockModule {}
