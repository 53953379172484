import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { TranslateService } from '@shared/translate/translate.service';
import {
  FundPerformanceCalendarYearPeriodEndData,
  FundPerformanceCalendarYearState,
  FundPerformanceCalendarYearStateConfig,
  PerformanceView,
  ProductDetailConfiguration,
} from '@types';
import { FundPerformanceCalendarYearService } from '../services/fund-performance-calendar-year.service';
import { ToggleButton } from '@frk/eds-components';
import { PERIODS_MAPPER } from '../services/fund-performance.config';

const logger = Logger.getLogger('FundPerformanceCalendarYearComponent');

@Component({
  selector: 'ft-fund-performance-calendar-year',
  templateUrl: './fund-performance-calendar-year.component.html',
  styleUrls: ['./fund-performance-calendar-year.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundPerformanceCalendarYearComponent
  extends BaseProductDetailComponent
  implements OnDestroy {
  dataSubscription: Subscription;
  isError = false;
  fundPerformanceData: FundPerformanceCalendarYearPeriodEndData;
  fundPerformanceConfig: FundPerformanceCalendarYearStateConfig;
  periodEndToggle: ToggleButton[];
  performanceStatusCaveat: string;
  heading: string;
  tooltips: { [name: string]: string } = {};

  constructor(
    private fundPerformanceService: FundPerformanceCalendarYearService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService
  ) {
    super();
  }

  populate(
    componentConfig: FundPerformanceComponentConfig,
    brConfig: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    this.setTooltips();
    if (this.brConfig.fundId) {
      this.fundPerformanceService.setConfigurationName(
        componentConfig.configurationName
      );
      this.dataSubscription = this.fundPerformanceService.fundPerformanceState$.subscribe(
        (newState: FundPerformanceCalendarYearState) => {
          if (newState.isError) {
            this.isError = true;
          } else {
            this.mapState(newState);
          }
          this.changeDetector.detectChanges();
        }
      );
      this.fundPerformanceService.populate(brConfig);
    }
    this.changeDetector.detectChanges();
  }

  private setTooltips() {
    this.tooltips[
      'products-calendar-year-returns-section'
    ] = this.translateService.tooltip('products-calendar-year-returns-section');
  }

  /**
   * Called when state changes.
   * @param fundPerformanceState the new state returned by fund performance service
   */
  private mapState(fundPerformanceState: FundPerformanceCalendarYearState) {
    this.performanceStatusCaveat = fundPerformanceState.perfStatusCaveat;
    this.heading = fundPerformanceState.heading;
    if (fundPerformanceState.config.periodEnd === PerformanceView.QUARTERLY) {
      this.fundPerformanceData = fundPerformanceState.data.quarterEnd;
    } else {
      this.fundPerformanceData = fundPerformanceState.data.monthEnd;
    }
    this.fundPerformanceConfig = fundPerformanceState.config;
    this.periodEndToggle = this.getPeriodEnds(fundPerformanceState);
  }

  private getPeriodEnds(
    fundPerformanceState: FundPerformanceCalendarYearState
  ): ToggleButton[] {
    return fundPerformanceState.config.periodEnds
      .map((periodEnd) => {
        const period = PERIODS_MAPPER[periodEnd];
        const hasData = fundPerformanceState.data[period].tableData.some(
          (data) => data !== undefined
        );
        if (hasData) {
          return {
            text: this.translateService.instant(
              `products.${periodEnd}`,
              this.brConfig.fundId
            ),
            active: periodEnd === fundPerformanceState.config.periodEnd,
            value: periodEnd,
          };
        }
      })
      .filter((period: ToggleButton) => period !== undefined);
  }

  /**
   * Update the performance view as per type selected.
   */
  onPeriodEndUpdate(selectedToggleOption: ToggleButton) {
    this.fundPerformanceService.setPeriodEnd(selectedToggleOption.value);
  }

  ngOnDestroy() {
    // clean up subscription to avoid memory leaks
    this.dataSubscription?.unsubscribe();
  }
}
