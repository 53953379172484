<ng-container *ngIf="page">
  <ng-container *ngIf="brConfig?.fundId; else previewBlock">
    <div *ngIf="populated && !hasData">
      <div>
        <h6>
          {{ "products.no-performance-data" | translate: brConfig.fundId }}
        </h6>
      </div>
    </div>
    <div *ngIf="populated && hasData">
      <eds-section-header
        [level]="3"
        theme="basic"
        size="h3"
        [analyticsName]="'products.risk-return-profile-title' | translate"
        [tooltip]="tooltips['products.risk-return-profile-section']"
        edsSpacing="sm"
      >
        {{ "products.risk-return-profile-title" | translate }}
        <eds-footnote
          placement="Risk Return Profile:RiskReturnProfile"
        ></eds-footnote>
      </eds-section-header>

      <eds-proximal
        placement="PerformanceRiskReturnProfileProximalTop"
      ></eds-proximal>

      <ft-scatter-chart
        [xTitle]="'products.risk-return-profile-x' | translate"
        [yTitle]="'products.risk-return-profile-y' | translate"
        [subTitle]="subTitle"
        [points]="points"
        [pointHoverFormat]="pointHoverFormat"
        [xSoftMin]="xSoftMin"
        [ySoftMin]="ySoftMin"
      ></ft-scatter-chart>

      <eds-proximal
        placement="PerformanceRiskReturnProfileProximalBottom"
      ></eds-proximal>
    </div>
  </ng-container>
</ng-container>

<ng-template #previewBlock>
  <h5>Fund Performance Component - Risk Return Profile</h5>
</ng-template>
