import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Events, Speaker } from '../events.type';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';

@Component({
  selector: 'ft-event-entry',
  templateUrl: './event-entry.component.html',
  styleUrls: ['./event-entry.component.scss'],
})
export class EventEntryComponent
  extends AbstractBaseComponent
  implements OnInit {
  public componentVariant = '';

  ngOnInit(): void {
    super.ngOnInit();
    this.componentVariant = this.component?.getParameters()?.componentVariant;
  }
}
