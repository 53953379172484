<main edsSpacing="xl">
  <!-- Breadcrumb -->

  <div>
    <ng-container brComponent="lead-content"></ng-container>
  </div>

  <div [class.one-col-accordion__sticky-content-wrapper]="!isEditMode">
    <ng-container brComponent="sticky-content"></ng-container>
  </div>

  <div>
    <ng-container brComponent="body-content"></ng-container>
  </div>

  <div edsGrid>
    <div class="container container--wide">
      <div edsRow>
        <div edsCol>
          <div edsSubGridGap="sm" edsSubGrid="layout-3">
            <div>
              <ng-container brComponent="main-content"></ng-container>
            </div>

            <div>
              <ng-container brComponent="right-rail"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div edsGrid *ngIf="page && (accordionItems?.length > 0 || isEditMode)">
    <div class="container">
      <div edsRow>
        <!-- If preview -->
        <div *ngIf="page && isEditMode" edsSpacing="xl">
          <ng-container
            *ngFor="let accordionItem of accordionItems; let i = index"
            brComponent="accordion-item-{{ i + 1 }}"
          ></ng-container>
        </div>
        <!-- Not preview -->
        <eds-accordion edsCol *ngIf="page && !isEditMode" edsSpacing="xl">
          <ng-container
            *ngFor="let accordionItem of accordionItems; let i = index"
          >
            <eds-accordion-item
              *ngIf="accordionLabels[i]"
              [title]="accordionLabels[i]"
            >
              <ng-container
                brComponent="accordion-item-{{ i + 1 }}"
              ></ng-container>
            </eds-accordion-item>
          </ng-container>
        </eds-accordion>
        <!-- / Not preview -->
      </div>
    </div>
  </div>

  <div>
    <ng-container brComponent="lower-content"></ng-container>
  </div>
</main>
