import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

export interface SchemaHomeComponentData {
  addressStreetAddress: string;
  addressLocality: string;
  addressRegion: string;
  addressPostalCode: string;
  addressCountry: string;
  contactPointContactType: string;
  contactPointTelephone: string;
  contactPointEmail: string;
  organizationName: string;
  organizationLegalName: string;
  organizationUrl: string;
  organizationLogo: string;
  organizationFoundingDate: string;
  foundersName: string;
  sameAs: string;
}

@Injectable({
  providedIn: 'root',
})

/**
 * Schema Service
 */
export class SchemaService {
  /**
   * Script type - jsonld
   */
  static scriptType = 'application/json+ld';

  /**
   * Website Schema object
   * @param url string
   * @param name string
   */
  static websiteSchema = (url?: string, name?: string) => {
    return {
      '@context': 'http://schema.org',
      '@type': 'website',
      url: url || '',
      name: name || '',
    };
  };

  /**
   * Person Schema object
   * @param url string
   * @param name string
   */
  static personSchema = (name?: string, jobTitle?: string, image?: string) => {
    return {
      '@context': 'http://www.schema.org',
      '@type': 'Person',
      name: name || '',
      image: image || '',
      jobTitle: jobTitle || '',
    };
  };

  /**
   * Constructor
   * @param documentRef Injected document
   */
  constructor(@Inject(DOCUMENT) private documentRef: Document) {}

  /**
   * Organisation Schema object
   * @param schemaHomeComponentData - SchemaHomeComponentData
   */
  public orgSchemaHomepage = (
    schemaHomeComponentData: SchemaHomeComponentData,
    url: string
  ) => {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization',
      name: schemaHomeComponentData?.organizationName || 'Franklin Templeton',
      legalName:
        schemaHomeComponentData?.organizationLegalName ||
        'Franklin Resources, Inc.',
      url: schemaHomeComponentData?.organizationUrl || url,
      logo: this.getLogo(schemaHomeComponentData, url),
      foundingDate: schemaHomeComponentData?.organizationFoundingDate || '1947',
      founders: this.foundersSplit(schemaHomeComponentData),
      address: {
        '@type': 'PostalAddress',
        streetAddress: schemaHomeComponentData?.addressStreetAddress || '',
        addressLocality: schemaHomeComponentData?.addressLocality || '',
        addressRegion: schemaHomeComponentData?.addressRegion || '',
        postalCode: schemaHomeComponentData?.addressPostalCode || '',
        addressCountry: schemaHomeComponentData?.addressCountry || '',
      },
      contactPoint: {
        '@type': 'ContactPoint',
        contactType: schemaHomeComponentData?.contactPointContactType || '',
        telephone: schemaHomeComponentData?.contactPointTelephone || '',
        email: schemaHomeComponentData?.contactPointEmail || '',
      },
      sameAs: schemaHomeComponentData?.sameAs
        ? schemaHomeComponentData.sameAs.split(';')
        : [],
    };
  };

  /**
   * This function removes all structured data from a page,
   * this should be called when a user navigates away from a page.
   * @param structuredDataName - String Structured Data Name to remove
   */
  public removeStructuredData(structuredDataName?: string): void {
    const els = [];
    if (structuredDataName) {
      const dataToRemove: Node[] = Array.from(
        this.documentRef.head.getElementsByClassName(structuredDataName)
      );
      dataToRemove.forEach((element: Node) => {
        this.documentRef.head.removeChild(element);
      });
      return;
    }
    ['structured-data', 'structured-data-profile'].forEach((c) => {
      els.push(...Array.from(this.documentRef.head.getElementsByClassName(c)));
    });
    els.forEach((el) => {
      this.documentRef.head.removeChild(el);
    });
  }

  /**
   * Checks to see if `structured-data`element exists, if the element
   * exists we will inject schema, if not we will create a new element.
   * @param schema - Schema object
   * @param className - Class name to be added to script tag
   */
  public insertSchema(schema: Record<string, any>, className: string): void {
    let script;
    let shouldAppend = false;
    if (this.documentRef.head.getElementsByClassName(className).length) {
      script = this.documentRef.head.getElementsByClassName(className)[0];
    } else {
      script = this.documentRef.createElement('script');
      shouldAppend = true;
    }
    script.setAttribute('class', className);
    script.type = SchemaService.scriptType;
    script.text = JSON.stringify(schema);
    if (shouldAppend) {
      this.documentRef.head.appendChild(script);
    }
  }

  /**
   * Returns path to logo
   * @param schemaHomeComponentData - Schema Home Component Data
   * @param url - Basic page URL string
   */
  private getLogo(
    schemaHomeComponentData: SchemaHomeComponentData,
    url: string
  ): string {
    if (schemaHomeComponentData?.organizationLogo) {
      if (
        /http:\/\/|https:\/\//.test(schemaHomeComponentData.organizationLogo)
      ) {
        return schemaHomeComponentData.organizationLogo;
      }
      return `${url}/binaries${schemaHomeComponentData.organizationLogo}`;
    }
    return 'https://www.franklintempleton.com/assets/images/logos/FT_logo_pos_RGB@2x.png';
  }

  /**
   * Returns Founders array if they are separated with ;
   * @param schemaHomeComponentData - SchemaHomeComponentData
   */
  private foundersSplit(schemaHomeComponentData: SchemaHomeComponentData) {
    if (schemaHomeComponentData?.foundersName) {
      const foundersNameArray = schemaHomeComponentData?.foundersName.split(
        ';'
      );
      const founders = [];
      if (foundersNameArray.length > 0) {
        foundersNameArray.forEach((founder) => {
          founders.push({
            '@type': 'Person',
            name: founder,
          });
        });
      }
      return founders;
    }
    return [
      {
        '@type': 'Person',
        name: 'Rupert H. Johnson, Sr',
      },
    ];
  }
}
