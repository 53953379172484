import { FilterType } from 'src/app/types/shared.type';

export interface FiltersState {
  primaryFilters: FilterType[];
  additionalFilters: FilterType[];
  morningStarFilter?: FilterType;
  clientUseFilter?: FilterType;
  searchKeyword?: FilterType;
  lastAction?: string;
}

export interface ProductTypeFilter {
  label: string;
  key: string;
  options?: string[];
}
// moved to shared types
/* export interface FilterType {
  label: string;
  options?: OptionWithCount[];
  selected?: string[];
  type?: BaseFilter;
}

export interface OptionWithCount {
  value: string;
  count?: number;
  label?: string;
}
 */
