<div [edsSpacing]="spacingBottom">
  <div edsGrid>
    <div class="container">
      <div edsRow>
        <div edsCol>
          <div
            edsSubGrid="layout-1"
            edsSubGridTabletGap="lg"
            edsSubGridTablet="layout-1"
            edsSubGridGap="lg"
            edsSubGridRowGap="lg"
          >
            <eds-thumbnail
              *ngFor="let card of cards"
              [eventId]="card.eventId"
              [eventParams]="card.eventParams"
              [hasBorders]="false"
              [hasShadows]="false"
              [hasBorderTop]="true"
              [hasBorderBottom]="true"
              [headingLevel]="2"
              [layoutSearch]="false"
              [layoutCompact]="
                content?.sectionType === 'thumbnail_cards_compact'
              "
              [title]="card.title"
              [body]="card.content"
              [buttonText]="card.link?.displayText"
              [previewSrc]="
                content?.sectionType === 'thumbnail_cards_compact'
                  ? null
                  : card.imgSrc
              "
              [docTypeSrc]="card.docTypeSrc"
              [href]="card.href"
              [titleLink]="card.href"
              [isNewWindow]="card.link?.target === '_blank'"
              [signInRequired]="
                card.link?.signInLink | ftIsSignInRequired | async
              "
              [hideLockIcon]="card.link?.hideLockIcon"
              [signInTooltip]="'common.locked-link-tooltip' | translate"
              [externalLink]="card.link?.external"
              [trackDownloads]="card.link?.enableDownloadTracking"
              [trackImpression]="card.link?.enableImpressionTracking"
              [downloadLocation]="card.link?.downloadLocation"
              (linkClick)="setDownloadLocation($event)"
              [widenParams]="card.widenParams"
            >
            </eds-thumbnail>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
