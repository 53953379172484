<div class="exact-toggle" *ngIf="showToggle">
  <eds-switch
    [switchId]="'search-exact-full-toggle'"
    [state]="activeToggle"
    valueOne="exact"
    valueTwo="full"
    optionOne="Exact match"
    optionTwo="Full Results"
    [label]="'products.ppss-filter-search' | translate"
    ariaLabelledbyStr="switchLabel"
    labelId="switchLabel"
    [radio]="true"
    (switchPressed)="onToggleChange()"
  ></eds-switch>
</div>
