import { Component, Input } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { BrPageComponent } from '@bloomreach/ng-sdk';

@Component({
  selector: 'ft-equal-cols',
  templateUrl: './equal-cols.component.html',
  styleUrls: ['./equal-cols.component.scss'],
})
export class EqualColsComponent {
  /**
   * Bloomreach page object
   */
  @Input() page!: Page;

  /**
   * Component configuration
   */
  configuration: BrPageComponent['configuration'];
}
