import { Injectable, Inject } from '@angular/core';
import {
  SEARCH_RESULTS,
  FtSearchResponseItem,
  ItemHeader,
  FundData,
  FundDataItem,
  FundItem,
  FtSearchItem,
  ShareClassObject,
  ShareClassObjectEds,
} from '@search/interfaces/search.interface';
import { FullResultsCommonService } from '../../full-results-common.service';
import { SiteConfigService } from '@services/site-config.service';
import { ConfigService } from '@search/services/config.service';
import { TranslateService } from '@shared/translate/translate.service';

import { FundId, FundIdentifier, ShareClassCode, ShareClassName } from '@types';

@Injectable({
  providedIn: 'root',
})
export class FullResultsFundsService {
  isSiteIntl: boolean;
  suppressedShareclasses: ShareClassCode[];

  constructor(
    protected siteConfigService: SiteConfigService,
    protected translateService: TranslateService,
    private configService: ConfigService,
    @Inject(SEARCH_RESULTS) private commonDataService: FullResultsCommonService
  ) {
    this.suppressedShareclasses = this.configService.getSuppressedShareclasses();
  }
  /**
   * generates header labels for fund section in results
   */
  getFundHeader(displayedPage: number): ItemHeader {
    // TODO this should be dynamic, recognize which page is displayed.
    const counter = (displayedPage + 1) * this.commonDataService.resultsPerPage;
    const fundsKey = 'funds';
    const totalRecords = this.commonDataService.totalItems[fundsKey];
    const displayedRecords = Math.min(counter, totalRecords);

    const rightTitleText =
      this.translateService.instant('common.showing') +
      ' ' +
      displayedRecords +
      ' ' +
      this.translateService.instant('common.of') +
      ' ' +
      totalRecords;

    const itemHeader = {
      leftTitle: this.translateService.instant('common.funds-label'),
      rightTitle: displayedRecords > 0 ? rightTitleText : '',
      viewMoreBtnText:
        totalRecords > displayedRecords
          ? this.translateService.instant('common.view-more-funds')
          : '',
    };

    return itemHeader;
  }

  /**
   *
   * gets 'fund' node from results
   */
  getFundItems(): FundItem[] {
    const responseItems = this.commonDataService.results.filter((item) => {
      return item.name === 'funds';
    })[0].response.hits.hits;

    return this.extractFundData(responseItems);
  }

  /**
   *
   * @param responseItems extracts and map fund data from fund node in results
   */
  extractFundData(responseItems: FtSearchItem[]): FundItem[] {
    // TODO allShareClassTickerSymbol is not always available
    const fundItems = responseItems.map((item) => {
      const fundDataSource: FundDataItem =
        item.inner_hits.shareClassesMatched.hits.hits[0]._source;

      const fundObj = {
        fundId: fundDataSource.fundid as FundId,
        shareClass: fundDataSource.shclcode as ShareClassCode,
        fundName: fundDataSource.title,
        identifier: fundDataSource.searchIdentifier as FundIdentifier,
      };

      const fundLink = this.getProductPageLink(fundObj);
      const fundName = this.getFundName(item, fundDataSource);
      const shareClasses = this.getShareClasses(fundObj, fundDataSource);

      const fundItem: FundItem = {
        fundName,
        fundFamily: fundDataSource.assetclass,
        shareClasses,
        fundLink,
      };

      return fundItem;
    });

    return fundItems;
  }
  /**
   *
   */
  getFundName(item: FtSearchItem, fundDataSource: FundDataItem) {
    const fundIdentifierSuffix =
      fundDataSource.searchIdentifier && fundDataSource.searchIdentifier !== '-'
        ? ' - ' + fundDataSource.searchIdentifier
        : '';

    const shareClassSuffix =
      fundDataSource.shclname &&
      !this.suppressedShareclasses.includes(
        fundDataSource.shclcode as ShareClassCode
      )
        ? ' - ' + fundDataSource.shclname
        : '';

    return (
      (item.highlight && item.highlight.title
        ? item.highlight.title[0]
        : fundDataSource.title) +
      shareClassSuffix +
      fundIdentifierSuffix
    );
  }

  /**
   *
   */
  getShareClasses(fundObj: FundData, fundDataSource: FundDataItem) {
    return fundDataSource.allShareClassNameMap
      ? this.getAllShareClasses(fundDataSource, fundObj)
      : this.getSingleShareClass(fundDataSource, fundObj);
  }

  /**
   * returns single shareclass if no map provided in data source and not supressed
   */
  getSingleShareClass(fundDataSource: FundDataItem, fundObj: FundData) {
    return !this.suppressedShareclasses.includes(
      fundDataSource.shclcode as ShareClassCode
    )
      ? [
          this.getShareClassObject(
            fundDataSource.shclname,
            this.getProductPageLink(fundObj),
            fundDataSource.title
          ),
        ]
      : [];
  }

  /**
   * Generates a list of additional shareclasses (all except main NGC-8439)
   */
  getAllShareClasses(
    fundDataSource: FundDataItem,
    fundObj: FundData
  ): ShareClassObjectEds[] {
    // To cover backward compatibility for search data.
    if (Array.isArray(fundDataSource.allShareClassNameMap)) {
      return this.getAllShareClassesFromArray(
        fundDataSource.allShareClassNameMap,
        fundDataSource.shclcode as ShareClassCode,
        fundObj,
        fundDataSource?.title
      );
    }

    return this.getAllShareClassesFromArray(
      fundDataSource.allShareClassNameMap[this.configService.currentSegment],
      fundDataSource.shclcode as ShareClassCode,
      fundObj,
      fundDataSource?.title
    );
  }

  /**
   * Returns Share Class Object array for Eds
   * @param shareClassNameMap - Share Class Objects array
   * @param shareClassCode- Share Class Code
   * @param fundObj - Fund Data object
   */
  private getAllShareClassesFromArray(
    shareClassNameMap: ShareClassObject[],
    shareClassCode: ShareClassCode,
    fundObj: FundData,
    fundTitle: string
  ): ShareClassObjectEds[] {
    return shareClassNameMap
      .filter(
        (shareClassObj: ShareClassObject) =>
          !this.suppressedShareclasses.includes(shareClassObj.code) &&
          shareClassObj.code !== shareClassCode // #NGC-8439
      )
      .map((shareClassObj: ShareClassObject) => {
        fundObj.shareClass = shareClassObj.code as ShareClassCode;
        const fundLink = this.getProductPageLink(fundObj);

        return this.getShareClassObject(
          shareClassObj.name,
          fundLink,
          fundTitle,
          shareClassObj?.curr
        );
      });
  }

  /**
   * TODO use URL service to get fund URL
   */
  getProductPageLink(fundData: FundData): string {
    const fundLink = this.siteConfigService.getFundLink(
      fundData.fundId as FundId,
      fundData.shareClass as ShareClassCode,
      fundData.fundName,
      fundData.identifier as FundIdentifier
    );
    return fundLink;
  }

  /**
   * TODO valudate what shareclass obejct is required for URL service
   * do not change shareclass to shareClass - it is matching EDS component property
   * @param shareClass shareclass string
   * @param link Link
   * @param fundTitle Fund Title
   * @param currencyCode Currency Code
   */
  private getShareClassObject(
    shareclass: ShareClassName,
    link: string,
    fundTitle: string,
    currencyCode?: string
  ): ShareClassObjectEds {
    const shareClassWithCurr = this.getShareClassName(shareclass, currencyCode);
    return {
      shareclass: shareClassWithCurr,
      link,
      ariaTitle: this.getAriaTitle(fundTitle, shareClassWithCurr),
    };
  }

  /**
   * This method will return Share class Name checking for If site is canada then append Currency Code
   * as this requirement is for Canada specific NGC-14510
   * TODO - Write Unit test Case for this method
   */
  private getShareClassName(
    shareclass: ShareClassName,
    currencyCode?: string
  ): ShareClassName {
    return this.siteConfigService.isCanada() && currencyCode
      ? (`${shareclass}-${currencyCode}` as ShareClassName)
      : shareclass;
  }

  /**
   *
   * // TODO - this must be moved to service extracting data
   * // total may be set as component attribute
   */
  getTotal(response: FtSearchResponseItem): number {
    return Number(response.hits.total);
  }

  /**
   * @param fundTitle Fund title
   * @param shareClass Share class name
   */
  private getAriaTitle(fundTitle: string, shareClass: ShareClassName): string {
    return `${fundTitle} ${shareClass}`;
  }
}
