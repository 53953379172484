import {
  ComponentFactory,
  ComponentFactoryResolver,
  NgModule,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { PcsToolComponent } from './pcs-tool.component';
import { InteractiveContentLazyModule } from '../interactive-content-display-component.interface';
import {
  PaddingModule,
  ButtonModule,
  FlexboxModule,
  GridModule,
  SimpleModalModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [PcsToolComponent],
  imports: [
    CommonModule,
    PaddingModule,
    ButtonModule,
    FlexboxModule,
    GridModule,
    SimpleModalModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [PcsToolComponent],
})
export class PcsToolModule implements InteractiveContentLazyModule {
  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  public resolveComponent(): ComponentFactory<PcsToolComponent> {
    return this.componentFactoryResolver.resolveComponentFactory(
      PcsToolComponent
    );
  }
}
