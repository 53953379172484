import { Component, Input, OnInit } from '@angular/core';
import { TabContents } from '../tabs.interface';
import { Page } from '@bloomreach/spa-sdk/';
import { CardContent, CardLinkClickEvent } from '@types';
import { CardsService } from '@services/cards.service';

@Component({
  selector: 'ft-tabs-thumbnails-section',
  templateUrl: './tabs-thumbnails-section.component.html',
})
export class TabsThumbnailsSectionComponent implements OnInit {
  /**
   * Bloomreach page model
   */
  @Input() page: Page;

  /**
   * Page Section content
   */
  @Input() content: TabContents;

  /**
   * Cards Array
   */
  cards: Array<CardContent>;

  /**
   * margin bottom
   */
  spacingBottom: string;

  constructor(private cardsService: CardsService) {}

  ngOnInit() {
    if (this.content?.cardCompound) {
      this.cards = this.cardsService.formatCardURL(
        this.cardsService.formatCards(this.content?.cardCompound),
        this.page
      );
    }
    this.spacingBottom = this.content?.spacingBottom;
  }

  public setDownloadLocation(event: CardLinkClickEvent) {
    this.cardsService.setDownloadLocation(event);
  }
}
