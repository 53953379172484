import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum';
import { WINDOW } from '@ng-web-apis/common';
import { DataWindow, SiteConfigService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import {
  FundId,
  FundShareClassId,
  GlobalId,
  SegmentId,
  ShareClassCode,
  PersonalisationFirmDataDto,
  PersonalisationFundDocs,
  PersonalisationPersonalData,
  PersonalisationPersonalDataDto,
  PersonalisationPersonalDataProduct,
  PersonalisationPersonalDataWithProducts,
  PersonalisationProductRelationship,
  PersonalisationPersonalDataDocuments,
  PersonalisationPersonalDataProductDto,
  PersonalisationToken,
  PersonalisationClientType,
  PlatformQueryParam,
} from '@types';
import { Logger } from '@utils/logger';
import {
  BehaviorSubject,
  combineLatest,
  from,
  Observable,
  of,
  ReplaySubject,
  Subject,
  throwError,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs/operators';
import { USServicingComponentTypes } from '../ft-components/interactive-content/us-servicing/types/us-servicing-config.interface';
import { AppStateService } from './app-state.service';
import { SegmentService } from './segment.service';
import { StorageService } from './storage.service';

const logger = Logger.getLogger('PersonalisationAPIService');

export const ID_TOKEN_PARAM = 'ft_token';

export class PersonalisationMissingTokenError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'PersonalisationMissingTokenError';
  }
}

export class PersonalisationHttpError extends Error {
  code: number;
  constructor(message: string, code) {
    super(message);
    this.name = 'PersonalisationHttpError';
    this.code = code;
  }
}

// params determine what data we want returned.
const personalisationApiParams =
  'includeHeldProducts=true&includefavoriteProducts=true&includeSubscribedDocuments=true&includeSalesTeamCoverage=true';

@Injectable({
  providedIn: 'root',
})
export class PersonalisationAPIService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  private rawData: PersonalisationPersonalDataDto;
  private personalData$: ReplaySubject<PersonalisationPersonalData> = new ReplaySubject<PersonalisationPersonalData>(
    1
  );
  private personalDataWithProducts$: Observable<PersonalisationPersonalDataWithProducts>;

  // emit error+details instead of just boolean
  private invalidTokenError$: ReplaySubject<Error> = new ReplaySubject<Error>(
    1
  );
  private oauthToken$: BehaviorSubject<PersonalisationToken> = new BehaviorSubject<PersonalisationToken>(
    null
  );
  private firmInvestedFunds$: BehaviorSubject<
    PersonalisationPersonalDataProductDto[]
  > = new BehaviorSubject<PersonalisationPersonalDataProductDto[]>(null);

  private isActiveListUpdatedByFirm$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    @Inject(WINDOW) readonly windowRef: DataWindow,
    private http: HttpClient,
    private storageService: StorageService,
    private segmentService: SegmentService,
    private appStateService: AppStateService,
    private siteConfigService: SiteConfigService,
    private translateService: TranslateService
  ) {
    logger.debug('PersonalisationAPIService constructor()');
    // Listen to Personalisation Data changes for FP segment only
    combineLatest([
      this.segmentService.getCurrentSegmentId$(),
      this.getPersonalData$(),
    ])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([segmentId, personalData]) => {
        delete this.windowRef.profileData.globalId;
        delete this.windowRef.profileData.expressNumber;
        if (
          segmentId === SegmentId.FINANCIAL_PROFESSIONALS &&
          personalData.identifiers?.globalId &&
          personalData.identifiers?.expressNumber
        ) {
          this.windowRef.profileData.globalId =
            personalData.identifiers.globalId;
          this.windowRef.profileData.expressNumber =
            personalData.identifiers.expressNumber;
        }
      });
  }

  // called by app module on startup
  public init(): void {
    logger.debug('PersonalisationAPIService init()');
    this.checkIfIdentified();

    // listen for token changes and get data as soon as we have one
    // NB getIdentityToken$() will emit an empty string if nothing set
    this.getIdentityToken$()
      .pipe(
        takeUntil(this.unsubscribe$),
        filter((token) => {
          if (!token) {
            // check that a token is set, and not an empty string
            // this error can be ignored, except on USServicing page where it should be logged
            this.invalidTokenError$.next(
              new PersonalisationMissingTokenError('No token set')
            );
            return false;
          }
          // only emit (and cause http request) if we have a token set
          return true;
        }),
        switchMap(
          (
            token: PersonalisationToken
          ): Observable<PersonalisationPersonalData> =>
            this.getTokenPersonalData$(token)
        )
      )
      .subscribe(
        this.handlePersonalisationApiResponse(''),
        (error: any): void => {
          logger.debug(
            'catch rethrown error from getTokenPersonalData$()',
            error
          );
        }
      );

    this.getPersonalData$()
      .pipe(
        switchMap((personalData: PersonalisationPersonalData) => {
          this.setFirmSpecificFunds(personalData);
          if (
            this.isRIAUser(personalData.clientType) &&
            !personalData.hasPersonalHeldProducts
          ) {
            // Get Firm/Team Invested Funds list only for RIA users when personalized Held Products list is empty.
            const globalId = personalData.teamInvestedFundFlag
              ? personalData.advisorTeamId
              : personalData.firmGlobalId;
            return this.getFirmInvestedFunds$(globalId);
          }
          return of([]);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(
        (firmInvestedFunds: PersonalisationPersonalDataProductDto[]) => {
          this.firmInvestedFunds$.next(firmInvestedFunds);
        }
      );

    // set up WithProducts data after siteconfig has been populated
    this.personalDataWithProducts$ = combineLatest([
      this.getPersonalData$(),
      this.siteConfigService.getIsPopulated$(),
      this.getFirmInvestedFundsSubject$(),
    ]).pipe(
      tap(
        ([data, isPopulated, firmInvestedFunds]: [
          PersonalisationPersonalData,
          boolean,
          PersonalisationPersonalDataProductDto[]
        ]): void => {
          logger.debug(
            'getPersonalDataWithProducts$()',
            isPopulated,
            data,
            firmInvestedFunds
          );
        }
      ),
      filter(
        ([data, isPopulated, firmInvestedFunds]: [
          PersonalisationPersonalData,
          boolean,
          PersonalisationPersonalDataProductDto[]
        ]): boolean => isPopulated
      ),
      map(this.mapProducts),
      tap((data: PersonalisationPersonalDataWithProducts): void => {
        logger.debug('getPersonalDataWithProducts$() after', data);
      }),
      shareReplay(1)
    );
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public callPersonalisationApiForLoggedInUser$(
    token: PersonalisationToken,
    expressNumber: string
  ): Observable<PersonalisationPersonalData> {
    return this.getTokenPersonalData$(token, expressNumber).pipe(
      tap(this.handlePersonalisationApiResponse(expressNumber)),
      switchMap(() => this.getPersonalData$())
    );
  }

  public getPersonalData$ = (): Observable<PersonalisationPersonalData> =>
    this.personalData$.asObservable();

  public getPersonalDataWithProducts$ = (): Observable<PersonalisationPersonalDataWithProducts> =>
    this.personalDataWithProducts$;

  /**
   * map personalData.ftcomRegistered as Observable.
   */
  public isRegistered$ = (): Observable<boolean> => {
    return this.personalData$.pipe(
      map(
        (personalData: PersonalisationPersonalData): boolean =>
          personalData.ftcomRegistered
      )
    );
  };

  /**
   * map personalData.parentFirmGlobalId as Observable.
   */
  public getParentFirmGlobalID$ = (): Observable<GlobalId> => {
    return this.personalData$.pipe(
      map(
        (personalData: PersonalisationPersonalData): GlobalId =>
          personalData.parentFirmGlobalId
      )
    );
  };

  /**
   * NB: this will initially return empty array, then array with fund and share class ids, then array with docs added
   * @returns an observable with funds and associated docs
   */
  public getFundData$ = (): Observable<PersonalisationFundDocs[]> =>
    this.getPersonalDataWithProducts$().pipe(
      map(
        (
          data: PersonalisationPersonalDataWithProducts
        ): PersonalisationFundDocs[] => data.products
      )
    );

  public invalidToken$ = (): Observable<Error> =>
    this.invalidTokenError$.asObservable();

  public getIdentityToken$(): Observable<PersonalisationToken> {
    return from(this.storageService.getIdentityToken());
  }

  /**
   * Check identified users only for FP users.
   * Service-center page is for FP only users.
   */
  public isIdentified$ = (): Observable<boolean> =>
    this.segmentService.getCurrentSegmentId$().pipe(
      takeUntil(this.unsubscribe$),
      switchMap(
        (segmentId: SegmentId): Observable<boolean> => {
          if (segmentId === SegmentId.FINANCIAL_PROFESSIONALS) {
            return this.getIdentityToken$().pipe(map((token) => !!token));
          }
          return of(false);
        }
      )
    );

  /**
   * Set Oauth Token
   * @param token - PersonalisationToken
   */
  public setOauthToken(token: PersonalisationToken): void {
    this.oauthToken$.next(token);
  }

  /**
   * Get Oauth Token
   */
  public getOauthToken$ = (): Observable<PersonalisationToken> =>
    this.oauthToken$.asObservable();

  /**
   * Get Firm Invested Funds
   */
  public getFirmInvestedFundsSubject$ = (): Observable<
    PersonalisationPersonalDataProductDto[]
  > => this.firmInvestedFunds$.asObservable();

  private handlePersonalisationApiResponse = (expressNumber: string) => (
    data: PersonalisationPersonalData
  ): void => {
    logger.debug('mapped personal data', data);
    datadogRum?.setGlobalContextProperty(
      'globalid',
      data?.identifiers?.globalId
    );
    // checking user is logged in or not on the basis of express number
    data.isLoggedIn = expressNumber.length > 0;
    this.personalData$.next(data);
    logger.debug('valid token');
    this.invalidTokenError$.next(null);
  };

  private handlePersonalisationApiError = (
    httpError: HttpErrorResponse
  ): Observable<never> => {
    logger.error('PersonalisationAPI token http error:', httpError);
    // if there ia any error in personalization api, emit it
    if (httpError.error) {
      this.invalidTokenError$.next(
        new PersonalisationHttpError(httpError.message, httpError.status)
      );
      this.invalidateIdentity();
    }
    // otherwise, let error get handled elsewhere
    return throwError(httpError);
  };

  public invalidateIdentity(): void {
    this.storageService.removeIdentyToken();
  }

  private checkIfIdentified(): void {
    const params: URLSearchParams = new URLSearchParams(
      this.windowRef.location.search
    );
    logger.debug('params before', params);
    const urlToken: PersonalisationToken = params.get(
      ID_TOKEN_PARAM
    ) as PersonalisationToken;
    if (urlToken) {
      this.storageService.setIdentityToken(urlToken);

      // assume that if token in url, then user must be an FP, so we force it
      this.segmentService.setSegment(SegmentId.FINANCIAL_PROFESSIONALS, true);

      // remove identity token from url for security
      params.delete(ID_TOKEN_PARAM);
      this.windowRef.history.replaceState(
        null,
        '',
        `${this.windowRef.location.pathname}?${params}${this.windowRef.location.hash}`
      );
    }
    logger.debug('params after', params);
  }

  /**
   * Returns all mapped documents
   * @param documents - Array of Documents
   */
  private mapSubscribedDocuments(
    documents: PersonalisationPersonalDataDocuments[]
  ): PersonalisationPersonalDataDocuments[] | undefined {
    if (documents?.length > 0) {
      return documents.map((doc: PersonalisationPersonalDataDocuments) => {
        return {
          ...doc,
          translatedDocumentType: this.getTranslatedDctermType(
            doc.documentType
          ),
        };
      });
    }
  }

  private getPersonalisationApiUrlForIdentityToken = (
    token: PersonalisationToken
  ): string =>
    `${
      this.appStateService.getPersonalisationApi()?.apiBaseUrl
    }/getClient/tokenId/${encodeURIComponent(
      token
    )}?${personalisationApiParams}`;

  private getPersonalisationApiUrlForExpressNumber = (
    expressNumber: string
  ): string => {
    return this.appStateService.isAuth0Login()
      ? `${
          this.appStateService.getPersonalisationApi()?.apiBaseUrl
        }/v2/getClient/expressNumber/${expressNumber}?${personalisationApiParams}`
      : `${
          this.appStateService.getPersonalisationApi()?.apiBaseUrl
        }/getClient/expressNumber/${expressNumber}?${personalisationApiParams}`;
  };

  // NB: Angular doesn't seem to have a type for http options:
  // https://stackoverflow.com/questions/56602725/why-doesnt-angular-provide-a-type-for-httpclients-options-paramter
  private getHttpOptions = (token?: PersonalisationToken) => {
    const headers: {
      client_id: string;
      client_secret: string;
      Authorization?: PersonalisationToken;
    } = {
      client_id: this.appStateService.getPersonalisationApi()?.clientID,
      client_secret: this.appStateService.getPersonalisationApi()?.clientSecret,
    };
    if (token) {
      headers.Authorization = this.appStateService.isAuth0Login()
        ? (`Bearer ${token}` as PersonalisationToken)
        : token;
      return {
        headers: new HttpHeaders(headers),
      };
    }
    return {
      headers: new HttpHeaders(headers),
    };
  };

  public mapFavoritesFundIds = (
    rawProducts: FundShareClassId[]
  ): PersonalisationPersonalDataProduct[] => {
    const validFunds: Record<string, PersonalisationPersonalDataProduct> = {};
    for (const rawProduct of rawProducts) {
      if (rawProduct) {
        const [fundId, shareClassCode] = rawProduct.split('-');
        if (!fundId || !shareClassCode) {
          logger.warn(` Invalid oneTis code: ${rawProduct}`);
        } else if (!this.siteConfigService.isActiveShareClass(rawProduct)) {
          logger.warn(`Inactive share class: ${rawProduct}`);
        } else {
          validFunds[rawProduct] = {
            fundId: fundId as FundId,
            shareClassCode: shareClassCode as ShareClassCode,
            relationships: ['favorite'],
          };
        }
      }
    }
    return Object.values(validFunds);
  };

  private mapFundIds = (
    relationship: PersonalisationProductRelationship,
    globalId: GlobalId,
    validFunds: Record<string, PersonalisationPersonalDataProduct>,
    rawProducts: PersonalisationPersonalDataProductDto[]
  ): void => {
    for (const rawProduct of rawProducts) {
      if (rawProduct?.oneTis) {
        const [fundId, shareClassCode] = rawProduct.oneTis.split('-');
        // check both fundId and shareClassCode are passed
        // TODO: this could add more checks in future
        if (!fundId || !shareClassCode) {
          logger.warn(
            `globalId: ${globalId}. Invalid oneTis code: ${rawProduct.oneTis}`
          );
        } else if (
          !this.siteConfigService.isActiveShareClass(rawProduct.oneTis)
        ) {
          logger.warn(
            `globalId: ${globalId}. Inactive share class: ${rawProduct.oneTis}`
          );
        } else {
          validFunds[rawProduct.oneTis] = {
            fundId: fundId as FundId,
            shareClassCode: shareClassCode as ShareClassCode,
            relationships: [relationship],
            sortOrder: rawProduct?.sortOrder,
          };
        }
      }
    }
  };

  private mapPersonalData = (
    rawData: PersonalisationPersonalDataDto
  ): PersonalisationPersonalData => {
    const globalId: GlobalId = rawData.identifiers?.globalId;
    const subscribedDocuments: PersonalisationPersonalDataDocuments[] =
      this.mapSubscribedDocuments(rawData.subscribedDocuments) || [];

    return {
      name: rawData.name,
      firstName: rawData.firstName,
      lastName: rawData.lastName,
      email: rawData.email,
      phoneExtension: rawData?.phoneExtension,
      phoneNumber: rawData?.phoneNumber,
      companyName: rawData?.companyName,
      advisorTeamId: rawData?.advisorTeamId,
      advisorTeamName: rawData?.advisorTeamName,
      clientType: rawData?.clientType,
      riaTerrId: rawData?.riaTerrId,
      firmGlobalId: rawData.firmGlobalId,
      parentFirmGlobalId: rawData?.parentFirmGlobalId,
      dbrInfo: {
        dealerNumber: rawData?.dealerNumber,
        branchNumber: rawData?.branchNumber,
        repNumber: rawData?.repNumber,
      },
      ftcomRegistered: rawData.ftcomRegistered === 'Y',
      identifiers: {
        globalId,
        expressNumber: rawData.identifiers?.expressNumber,
      },
      subscribedDocuments,
      teamInvestedFundFlag: rawData.teamInvestedFundFlag === 'Y',
      hasPersonalHeldProducts: rawData.heldProducts?.length > 0,
      focusList: rawData.focusList,
    };
  };

  private mapProducts = ([data, isPopulated, firmInvestedFunds]: [
    PersonalisationPersonalData,
    boolean,
    PersonalisationPersonalDataProductDto[]
  ]): PersonalisationPersonalDataWithProducts => {
    const validFunds: Record<string, PersonalisationPersonalDataProduct> = {};
    this.mapFundIds(
      USServicingComponentTypes.HELD,
      data.identifiers.globalId,
      validFunds,
      this.rawData.heldProducts || []
    );

    // split out held funds only
    const validHeldFunds: Record<
      string,
      PersonalisationPersonalDataProduct
    > = {};
    this.mapFundIds(
      USServicingComponentTypes.HELD,
      data.identifiers.globalId,
      validHeldFunds,
      this.rawData.heldProducts || []
    );
    if (
      firmInvestedFunds &&
      this.isRIAUser(data.clientType) &&
      !data.hasPersonalHeldProducts
    ) {
      const firmFunds: Record<string, PersonalisationPersonalDataProduct> = {};
      this.mapFundIds(
        USServicingComponentTypes.HELD,
        data.identifiers.globalId,
        firmFunds,
        firmInvestedFunds
      );
      return {
        ...data,
        products: Object.values(firmFunds),
        heldProducts: Object.values(validHeldFunds),
        firmProducts: Object.values(firmFunds),
      };
    }
    return {
      ...data,
      products: Object.values(validFunds),
      heldProducts: Object.values(validHeldFunds),
    };
  };

  private getTranslatedDctermType(dctermsType: string): string {
    return dctermsType
      ? this.translateService.instant(`literature.${dctermsType}`)
      : undefined;
  }

  private getTokenPersonalData$ = (
    token: PersonalisationToken,
    expressNumber?: string // only if we have have a ping token
  ): Observable<PersonalisationPersonalData> => {
    const httpRequest = expressNumber // indicates we have a ping token
      ? this.sendPersonalisationApiHttpReqForAccessToken(token, expressNumber)
      : this.sendPersonalisationApiHttpReqForIdentityToken(token);
    // data is combined to make sure siteConfig is loaded before next step
    return httpRequest.pipe(
      tap((data: PersonalisationPersonalDataDto): void => {
        logger.debug('get funds for token', token, data);
        this.rawData = data;
      }),
      map(this.mapPersonalData),
      catchError(this.handlePersonalisationApiError)
    );
  };

  private sendPersonalisationApiHttpReqForIdentityToken(
    token: PersonalisationToken
  ) {
    return this.http.get<PersonalisationPersonalDataDto>(
      this.getPersonalisationApiUrlForIdentityToken(token),
      this.getHttpOptions()
    );
  }

  private sendPersonalisationApiHttpReqForAccessToken(
    token: PersonalisationToken,
    expressNumber: string
  ) {
    return this.http.get<PersonalisationPersonalDataDto>(
      this.getPersonalisationApiUrlForExpressNumber(expressNumber),
      this.getHttpOptions(token)
    );
  }

  /**
   * Get Firm Invested Funds
   * @param globalId - GlobalId
   */
  private getFirmInvestedFunds$ = (
    globalId: GlobalId
  ): Observable<PersonalisationPersonalDataProductDto[]> => {
    return this.callFirm360$(globalId).pipe(
      map(
        (
          response: PersonalisationFirmDataDto
        ): PersonalisationPersonalDataProductDto[] => {
          if (response === null) {
            return [];
          }
          return response.heldProducts;
        }
      )
    );
  };

  public getProductAvailabilityData$(): Observable<PersonalisationFirmDataDto> {
    return this.personalData$.pipe(
      switchMap(
        (
          personalData: PersonalisationPersonalData
        ): Observable<PersonalisationFirmDataDto> => {
          return this.callFirm360$(
            personalData.firmGlobalId,
            PlatformQueryParam.ALL
          );
        }
      )
    );
  }

  /**
   * make the call to firm 360
   * @param globalId - the firm or team globalid
   */
  private callFirm360$(
    globalId: GlobalId,
    platform?: PlatformQueryParam
  ): Observable<PersonalisationFirmDataDto> {
    return combineLatest([
      this.getIdentityToken$(),
      this.getOauthToken$(),
    ]).pipe(
      switchMap(
        ([identityToken, oauthToken]: [
          PersonalisationToken,
          PersonalisationToken
        ]): Observable<PersonalisationFirmDataDto> => {
          logger.debug(identityToken, oauthToken);
          const apiBaseUrl = this.appStateService.getPersonalisationApi()
            ?.apiBaseUrl;
          if (oauthToken) {
            const platformQuery = platform ? `?platform=${platform}` : '';
            return this.http
              .get<PersonalisationFirmDataDto>(
                `${apiBaseUrl}/firm/globalId/${globalId}${platformQuery}`,
                this.getHttpOptions(oauthToken)
              )
              .pipe(catchError((error) => this.firmApiError(error)));
          } else {
            const platformQuery = platform ? `&platform=${platform}` : '';
            return this.http
              .get<PersonalisationFirmDataDto>(
                `${apiBaseUrl}/firm/tokenId/${encodeURIComponent(
                  identityToken
                )}?globalId=${globalId}${platformQuery}`,
                this.getHttpOptions()
              )
              .pipe(catchError((error) => this.firmApiError(error)));
          }
        }
      )
    );
  }

  /**
   * checking the user is RIA or DUALLY both will be treated as RIA user only
   * @param clientType - string
   */
  public isRIAUser(clientType: string): boolean {
    return (
      clientType === PersonalisationClientType.RIA ||
      clientType === PersonalisationClientType.DUALLY
    );
  }

  /**
   * Firm API error handling
   * @param error - Error string
   */
  private firmApiError(error: string): Observable<null> {
    logger.debug('Firm API error:', error);
    return of(null);
  }

  // to get the firm specific funds for the firm
  private setFirmSpecificFunds(
    personalData: PersonalisationPersonalData
  ): void {
    if (this.checkFirmSpecificFundAvailable(personalData.parentFirmGlobalId)) {
      this.callFirm360$(personalData.firmGlobalId, PlatformQueryParam.PRODUCTS)
        ?.pipe(takeUntil(this.unsubscribe$))
        ?.subscribe((data: PersonalisationFirmDataDto) => {
          if (data?.platformProducts?.length > 0) {
            this.siteConfigService.updateActiveListFunds(
              data?.platformProducts
            );
            this.isActiveListUpdatedByFirm$.next(true);
          }
        });
    }
  }

  // to check the parentGlobalFirmId present in the firm specific id
  private checkFirmSpecificFundAvailable = (
    parentFirmGlobalId: GlobalId
  ): boolean =>
    this.appStateService
      .getFirmSpecificFunds()
      .some((firmId: GlobalId) => firmId === parentFirmGlobalId);

  // to check active list of funds has been updated
  public getIsActiveListUpdatedByFirm$(): Observable<boolean> {
    return this.isActiveListUpdatedByFirm$.asObservable();
  }
}
