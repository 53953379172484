import { Component, OnInit, Inject } from '@angular/core';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';

import { FtSearchService } from '@search/services/ftsearch.service';
import { ConfigService } from '@search/services/config.service';
import { FtSearchStatus } from '@search/interfaces/search.interface';
import { SearchServiceToken } from '@frk/eds-components';

@Component({
  selector: 'ft-status',
  templateUrl: './status.component.html',
  styleUrls: ['./status.component.scss'],
})
export class StatusComponent implements OnInit {
  componentDestroyed$: Subject<boolean> = new Subject();

  options = {
    config: {},
  };
  statusJson: FtSearchStatus = {
    hosts: [
      {
        type: '',
        status: '',
      },
    ],
    config: {},
  };

  constructor(
    @Inject(SearchServiceToken) private searchService: FtSearchService,
    private activatedRoute: ActivatedRoute,
    private configService: ConfigService
  ) {
    this.searchService.statusOutput$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(
        (status) => {
          this.statusJson = status;
        },
        (error) => {
          this.statusJson = {
            hosts: [
              {
                type: '',
                status: 'down',
              },
            ],
            config: {},
            error: 'Network error',
          };
        }
      );
  }

  ngOnInit() {
    // this is to recognize query params sent to app and select data source (or another options, like search from url)
    // TODO it needs to be moved to config service
    const dataSourceKey = 'dataSource';
    this.activatedRoute.queryParams
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((params) => {
        this.configService
          .getConfig()
          .pipe(takeUntil(this.componentDestroyed$))
          .subscribe((config) => {
            this.options.config = config;
            this.searchService.getStatus(config);
          });
      });
  }
}
