export enum LabelCollection {
  common,
  languages,
  countries,
  accountsNotification,
  signIn,
}

/**
 * Utility class for shared labels
 */
export class Labels {
  private static labelCollections = new Map();

  public static getLabels(collection: LabelCollection) {
    return this.labelCollections.get(collection);
  }

  public static addLabels(collection: LabelCollection, labels: any) {
    this.labelCollections.set(collection, labels);
  }
}
