<div edsGrid *ngIf="alertService.showAccountsAlert">
  <div class="container--wide">
    <div edsRow edsSpacing edsPadding edsPaddingTop="lg" edsPaddingBottom="lg">
      <div edsCol>
        <eds-notifications
          [hasBorders]="hasBorders"
          [hasBorderRadius]="hasRadius"
          [hasShadows]="hasShadow"
          [hasIcon]="hasIcon"
          [inline]="inline"
          [title]="title"
          [CTAText]="linkInput"
          [body]="body"
          [notificationType]="notificationType"
          [canClose]="canClose"
          [primaryBtnText]="primaryBtnText"
          [secondaryBtnText]="secondaryBtnText"
          [tertiaryBtnText]="tertiaryBtnText"
          (closeEvent)="closeAlert()"
        >
        </eds-notifications>
      </div>
    </div>
  </div>
</div>
