import { InvestmentTeam, SummaryDetail } from '../models';
import { SiteConfig } from '../../../types';
import { Exchange } from '@products/models/exchanges';

export interface OverviewState {
  chargesInfo: OverviewChargesInfo;
  data: OverviewData;
  gridDiv: GridDiv; // structure of grid divisions.
  siteConfig: SiteConfig;
  hasLoadedFullFeed?: boolean;
  isBrazil?: boolean;
  perfIncDateStd?: string;
}

export interface OverviewChargesInfo {
  waiverType?: string;
  waiverEndDate?: string;
}

export interface OverviewData {
  fundInformation: OverviewSection;
  salesCharges: SalesChargesSection;
  identifiers: OverviewSection;
  distributions: OverviewSection;
  tradingCharacteristics: OverviewSection;
  temitKeyDateDetails: OverviewSection;
}

export interface OverviewSection {
  items: SummaryDetail[];
  asOfDate?: string;
  updateFrequency?: string;
}

export interface SalesChargesSection extends OverviewSection {
  perfIncDateStd?: string;
}

export interface FundManagementState {
  data?: FundManagementData;
}
export interface TradingInformationState {
  data?: TradingInformationData;
}
export interface TradingInformationData {
  exchanges: Exchange[];
}

export interface FundManagementData {
  showLinkToBios: boolean;
  showYearsOfExperience: boolean;
  investmentTeam: InvestmentTeam[];
}

export interface FundInformationPanel {
  title: string;
  asOfDate: string;
  updateFrequency?: string;
  items: SummaryDetail[];
  twoColumn: boolean;
  footnote?: string;
  tooltip?: string;
  analyticsName?: string;
  isMifidApplicable?: boolean;
}

export enum GridDiv {
  'ONE',
  'ONE_SPLIT',
  'TWO_EQUAL',
  'TWO_SPLIT',
}

export interface AnalizyRatingState {
  months12?: string;
  months36?: string;
  category?: string;
  effectiveDate?: string;
}
