import { Component, Input } from '@angular/core';
import { Page } from '@bloomreach/spa-sdk';
import { BrPageComponent } from '@bloomreach/ng-sdk';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'ft-two-cols',
  templateUrl: './two-cols.component.html',
  styleUrls: ['./two-cols.component.scss'],
})
export class TwoColsComponent {
  /**
   * Bloomreach page object
   */
  @Input() page!: Page;

  /**
   * isWide
   */
  @Input() isWide = true;

  /**
   * Component configuration
   */
  configuration: BrPageComponent['configuration'];

  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
  }
}
