import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { EnvConfigService } from '@services/env-config.service';
import { Observable } from 'rxjs';
import { SalesTeam, SalesTeamProfileImage } from './sales-team.interface';

@Injectable({
  providedIn: 'root',
})
export class SalesTeamService {
  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    private envConfigService: EnvConfigService
  ) {}

  getSalesTeam$(
    url: string,
    requestPayLoad: any = null,
    authToken: string
  ): Observable<SalesTeam> {
    return this.http.post<SalesTeam>(
      url,
      requestPayLoad,
      this.getHttpOptions(authToken)
    );
  }

  /**
   * Get HTTP options to get sales team list
   * @returns HTTP options object
   */
  private getHttpOptions(authToken: string) {
    const marketingAuthAPIMKey = this.appStateService.getMarketingAuthAPIMKey();
    const dynamicsClientId = this.envConfigService.getEnvConfig()
      .dynamicsClientId;
    const dynamicsClientSecret = this.envConfigService.getEnvConfig()
      .dynamicsClientSecret;

    const httpOptions = {
      headers: new HttpHeaders({
        'X-FT-API-KEY': `${marketingAuthAPIMKey}`,
        'Content-Type': 'application/json',
        'Client-Id': `${dynamicsClientId}`,
        'Client-Secret': `${dynamicsClientSecret}`,
        Authorization: authToken ? `Bearer ${authToken}` : '',
      }),
    };
    return httpOptions;
  }

  /**
   * Get widen asset object for profile image
   * @param employeeId for sales team member
   * @returns widen asset object
   */
  loadProfileImage$(employeeId: string): Observable<SalesTeamProfileImage> {
    if (employeeId) {
      const url = `/rest/documents?name=Profile&param=${employeeId}`;
      return this.http.get<SalesTeamProfileImage>(url);
    }
  }
}
