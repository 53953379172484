import { Pipe, PipeTransform } from '@angular/core';

/**
 * If the content block doesn't have a background add a margin
 * If the content block is not last add a margin
 * If the margin is not defined, default to 'xl'
 * @param block object
 * @param isLast boolean
 */
@Pipe({
  name: 'ftContentBlockMargin',
})
export class ContentBlockMarginPipe implements PipeTransform {
  public transform(block: any, isLast?: boolean): any {
    if (block.background?.toLowerCase() === 'grey' || isLast) {
      return 'none';
    } else {
      return block.marginBottom || 'xl';
    }
  }
}
