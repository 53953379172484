import isEmpty from 'lodash/isEmpty';
import { IUserProfile } from '@services/profile.interface';
import { EnvConfig } from '@services/env-config.service';
import { Logger } from '@utils/logger';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('StringUtils');

export function makeStringNonBreaking(value) {
  return value.replace(/ /g, '\xa0');
}

export function formatString(val = ''): string {
  if (!val || val === '-') {
    return '—';
  }
  return val;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for user input, makes it safe to inject into code
 *
 * follows w3c recommendations
 * https://www.w3.org/TR/2017/REC-html52-20171214/dom.html#text-content
 */
export function normaliseToHtmlSafeString(input) {
  input = input
    .replace(/&/g, '&amp;')
    .replace(/\$/g, '&#36;') // $ added for aurelia template injection aka ${somefunc()}
    .replace(/>/g, '&gt;')
    .replace(/</g, '&lt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;');
  return input;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for user input, makes it safe to inject into regex string
 *
 * follows someone's recommendations
 * https://stackoverflow.com/questions/3446170/escape-string-for-use-in-javascript-regex
 */
export function normaliseToRegexSafeString(input) {
  input = input
    .replace(/[.*+?^${}()|[\]\\:]/g, '\\$&')
    .replace(/ /gi, '[ \xa0]', 'ig');
  return input;
}

/**
 * ONLY USE THIS IF YOU KNOW WHAT YOUR DOING
 *
 * basically for elastic search input that double encodes unicode
 */
export function removeElasticDoubleEncoding(input) {
  if (!input.includes('\\u')) {
    return input;
  }
  return decodeURIComponent(JSON.parse('"' + input.replace(/"/g, '\\"') + '"'));
}

/**
 * Replace tokens like $ENV[] from strings
 * @param input string with tokens
 * @param envConfig Envirionment Config Data
 * @param profile Data
 * @param flatPage Flattened Page Object
 * @returns string without tokens
 */
export function replaceTokens(
  input: string,
  envConfig: EnvConfig,
  profile?: IUserProfile,
  translateService?: TranslateService
): string {
  input = _envTokenReplace(input, envConfig);

  if (translateService) {
    input = _labelTokenReplace(input, translateService);
  }
  /**
   * Check if the profile object is empty, as newPage() of
   * page-container.service runs before profile data is loaded first time.
   */
  if (!isEmpty(profile)) {
    input = _profileTokensReplace(input, profile);
  }

  // To remove/add lock icon in ckeditor contents.
  input = _lockIconReplace(input, profile);

  return input;
}

function _envTokenReplace(input: string, envConfig: EnvConfig) {
  const envRegex = /\$(ENV|CONFIG)(\(|\[)(\w+)(\)|\])/g;
  const token: RegExpMatchArray =
    input.match(envRegex) || input.match(/\$(ENV|CONFIG)/g);
  input = input.replace(envRegex, (m, g1, g2, g3) => {
    return envConfig[g3];
  });
  if (input.match(/\$(ENV|CONFIG)/g) || input.includes('undefined')) {
    logger.error('Environment config tokens misconfiguration', token);
  }
  return input;
}

function _labelTokenReplace(input: string, translateService: TranslateService) {
  const labelRegex = /\$LABEL(\(|\[)(\w+(?:.\w+)*)(\)|\])/g; // Labels with '-' in their keys
  const token: RegExpMatchArray =
    input.match(labelRegex) || input.match(/\$LABEL/g);
  if (token) {
    input = input.replace(labelRegex, (m, g1, g2) => {
      return translateService.instant(g2);
    });
  }
  if (input.includes('$LABEL') || input.includes('undefined')) {
    logger.error('Label tokens misconfiguration', token);
  }
  return input;
}

function _profileTokensReplace(input: string, profile: IUserProfile): string {
  const profileRegex = /\$PROFILE(\(|\[)(\w+)(\)|\])/g;
  // Replace only for full profile
  if (
    profile?.profileInfo?.userId &&
    profile?.profileInfo?.userId !== 'bypassuser'
  ) {
    const token: RegExpMatchArray =
      input.match(profileRegex) || input.match(/\$PROFILE/g);
    input = input.replace(profileRegex, (m, g1, g2) => {
      return profile?.profileInfo[g2];
    });
    if (input.includes('$PROFILE') || input.includes('undefined')) {
      logger.error('Profile tokens misconfiguration', token);
    }
  }
  return input;
}

/**
 * This method will remove lock icon when user is loggedin
 * and vice versa.
 */
function _lockIconReplace(input: string, profile: IUserProfile): string {
  if (input.match('media__link--lock-icon') && profile?.isLoggedIn) {
    input = input.replace('media__link--lock-icon', 'media__profile-unlock');
  }

  if (input.match('media-profile-unlock') && !profile?.isLoggedIn) {
    input = input.replace('media__profile-unlock', 'media__link--lock-icon');
  }

  if (input.match('data-signin-required="true"') && !profile?.isLoggedIn) {
    // Search all the anchor with data-signin-required="true"
    const anchorsWithDataSignInRequired = input.match(
      /(<a[^>]*?)(href="[^"]*")[^>]*?data-signin-required="true"[^"]*"[^>]*/gi
    );

    anchorsWithDataSignInRequired?.forEach((anchor) => {
      // Replace href values with #
      const anchorWithReplacedHref = anchor.replace(
        /href="([^"]*)"/,
        'href="#"'
      );

      input = input.replace(anchor, anchorWithReplacedHref);
    });
  }

  return input;
}

/**
 * Removes <p></p> tags from HTML string
 * @param string with p tags
 * @returns string without p tags
 */
export function removePTags(content: string): string {
  // Just remove P HTML tag
  return content?.replace(/<\/?p[^>]*>/gi, '');
}

/**
 * capitalizes First Letter in each word
 */
export function titleCase(str: string): string {
  const splitStr = str.toLowerCase().split(' ');
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] =
      splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
  }
  // Directly return the joined string
  return splitStr.join(' ');
}
/**
 * converts 999-999-9999 to (999) 999-9999
 */
export function maskUsPhoneNo(input: string): string {
  const usNumberRegex = /(\d{3})-(\d{3})-(\d{4})/;
  if (usNumberRegex.test(input)) {
    const usNumber = input.match(usNumberRegex);
    return `(${usNumber[1]}) ${usNumber[2]}-${usNumber[3]}`;
  } else {
    return input;
  }
}

/**
 * converts 05688 to 5688 and 099 to 099
 */
export function removeLeadingZero(input: string): string {
  input = input.replace(/^0+/, '');
  return +input < 100 ? `0${input}` : input;
}

/**
 * Generates a unique uuid
 * TODO: can this be replaced with the uuid library EDS uses?
 */
export function generateUUID(): string {
  let d = new Date().getTime();
  let d2 = (performance && performance.now && performance.now() * 1000) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    /* tslint:disable:no-bitwise */
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x7) | 0x8).toString(16);
    /* tslint:enable:no-bitwise */
  });
}

export function getFileNameFromUrl(url: string): string {
  if (url) {
    const splitUrl = url?.split('/');
    return splitUrl[splitUrl.length - 1];
  }
  return '';
}

// This function checks if search string includes litcode.
export function isLiteratureCodeWithSpace(litCode: string): boolean {
  return litCode.match(/^[a-zA-Z0-9]{2,5}[ ][a-zA-Z0-9]{1,5}$/)?.length > 0;
}

export function createNavigationString(input: string): string {
  return input?.toLowerCase()?.replace(/\s+/g, '-');
}
