import dayjs from 'dayjs';
import nlConfig from 'dayjs/locale/nl';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('nl-nl', { ...nlConfig });
export const customNlNlConfig = dayjs.updateLocale('nl-nl', {
  ...nlConfig,
  formats: {
    ...nlConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
