<ft-base-modal
  #modal
  [title]="title"
  [closeLabel]="closeLabel"
  [showCloseButton]="showCloseButton"
  [isGrey]="true"
  [closeOnClickToBackground]="closeOnClickToBackground"
  [headerBottomBorder]="headerBottomBorder"
  [size]="size"
  (modalClosed)="processClosedEvent()"
>
  <div class="modal__body" [innerHTML]="content"></div>
  <div class="modal__footer" [ngClass]="{ 'modal__footer--grey': isGrey }">
    <span class="modal__button--left" *ngIf="leftButtonLabel !== undefined">
      <button
        [ftButton]="leftButtonType"
        (click)="handleLeft()"
        type="button"
        [innerHTML]="leftButtonLabel"
      ></button>
    </span>
    <span class="modal__button--right" *ngIf="rightButtonLabel !== undefined">
      <button
        [ftButton]="rightButtonType"
        (click)="handleRight()"
        type="button"
        [innerHTML]="rightButtonLabel"
      ></button>
    </span>
  </div>
</ft-base-modal>
