<div class="column-2" edsTrapFocus>
  <eds-search-panel
    panelId="literature"
    [leftTitle]="literatureHeader.leftTitle"
    [rightTitle]="literatureHeader.rightTitle"
    [hasBorders]="false"
    [hasShadows]="false"
    [viewMoreBtnText]="literatureHeader.viewMoreBtnText"
    (viewMoreTrigger)="onViewMore($event)"
    [viewMoreBtnStyle]="'right'"
    *ngIf="literatureData.length"
  >
    <ng-container>
      <!-- desktop view -->
      <div *ngIf="(isHandheld$ | async) !== true; else mobileBlock">
        <table class="full-results-literature" edsSpacing="lg">
          <thead>
            <tr class="full-results-literature__row">
              <th
                class="full-results-literature__column full-results-literature__column--header sr-only"
              >
                {{ "literature image" | translate }}
              </th>
              <th
                class="full-results-literature__column full-results-literature__column--fund-name"
              >
                <small class="full-results-literature__head-font">
                  {{ literatureHeader.nameLabel }}
                </small>
              </th>
              <th
                *ngIf="showFinra"
                class="full-results-literature__column full-results-literature__column--finra-letter"
              >
                <small class="full-results-literature__head-font">
                  {{ literatureHeader.clientLabel }}
                </small>
              </th>
              <th
                class="full-results-literature__column full-results-literature__column--publication-date"
              >
                <small class="full-results-literature__head-font">
                  {{ literatureHeader.literatureLabel }}
                </small>
              </th>
              <th
                class="full-results-literature__column full-results-literature__column--publication-date"
              >
                <small class="full-results-literature__head-font">
                  {{ literatureHeader.publicationLabel }}
                </small>
              </th>
              <th class="full-results-literature__column sr-only">
                {{ "literature actions" | translate }}
              </th>
              <th class="full-results-literature__column sr-only">
                {{ "literature actions" | translate }}
              </th>
            </tr>
          </thead>
          <tbody class="table__body">
            <tr
              class="full-results-literature__row"
              *ngFor="let data of literatureData"
            >
              <td
                class="full-results-literature__column full-results-literature__column--image"
              >
                <eds-image
                  *ngIf="data.docTypeSrc"
                  class="document-preview__icon"
                  [imgSrc]="data.thumbnailUrl"
                ></eds-image>
                <eds-icon
                  *ngIf="!data.docTypeSrc"
                  [type]="data.documentIcon"
                  size="large"
                  edsPadding
                  edsPaddingLeft="xs"
                ></eds-icon>
              </td>
              <td
                class="full-results-literature__column full-results-literature__column--fund-name"
              >
                <eds-title
                  class="full-results-literature__fund-data"
                  [title]="data.litName"
                  [level]="6"
                  [size]="'h6'"
                  edsSpacing="xs"
                ></eds-title>

                <small class="full-results-literature__fund-detail">
                  {{ data.fundDetail }}</small
                >
              </td>

              <td
                *ngIf="showFinra"
                class="full-results-literature__column full-results-literature__column--finra-letter"
              >
                <div edsFlexbox justifyContent="center">
                  <span edsPadding edsPaddingRight="xs">{{
                    data.clientUseValue
                  }}</span>
                  <eds-link-item
                    *ngIf="data.finraPath"
                    [title]="data.clientName"
                    [useSmallText]="true"
                    [hasIcon]="true"
                    [isNewWindow]="true"
                    type="new-window"
                    size="small"
                    [href]="litFinraUrl + data.litCode"
                  ></eds-link-item>
                </div>
              </td>
              <td
                class="full-results-literature__column full-results-literature__column--lit-code"
              >
                <p
                  edsPadding
                  edsPaddingTop="md"
                  class="full-results-literature__fund-data"
                >
                  {{ data.litCode }}
                </p>
              </td>
              <td
                class="full-results-literature__column full-results-literature__column--lit-code"
              >
                <p
                  edsPadding
                  edsPaddingTop="md"
                  class="full-results-literature__fund-data"
                >
                  {{ data.publicationDate }}
                </p>
              </td>
              <td
                class="full-results-literature__column full-results-literature__column--button"
              >
                <div edsFlexbox>
                  <div *ngIf="!data.downloadNotAvailble; else noDownloadBlock">
                    <button
                      [hasIcon]="true"
                      edsButton="primary"
                      size="compact"
                      (click)="downloadDocument(data)"
                    >
                      <span>{{ data.downloadBtn }}</span>
                      <eds-icon
                        type="download"
                        size="small"
                        edsPadding
                        edsPaddingLeft="xs"
                      ></eds-icon>
                    </button>
                  </div>
                  <div>
                    <!-- WDE-5309 Hiding the Add to Cart Button for International Sites -->
                    <button
                      *ngIf="
                        (!data.noFinraLink || data.customFilePath) &&
                        !isSiteInternational
                      "
                      [hasIcon]="false"
                      edsButton="secondary"
                      size="compact"
                      (click)="updateCart(data)"
                    >
                      <span
                        >{{
                          cartItems.includes(data.litCode) ||
                          cartItems.includes(data.customFilePath)
                            ? this.labelAdded
                            : this.labelAdd
                        }}
                      </span>
                    </button>
                  </div>
                </div>
                <div>
                  <div
                    *ngIf="data.hardcopyNotAvailable"
                    edsPadding
                    edsPaddingTop="sm"
                  >
                    <eds-error-message
                      errorMessage="{{
                        'literature.cart-hardcopy-alert-body-msg' | translate
                      }}"
                      theme="dark"
                    ></eds-error-message>
                  </div>
                </div>
              </td>
              <td class="full-results-literature__column"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- download unavailable -->
      <ng-template #noDownloadBlock>
        <div edsPadding edsPaddingRight="sm" edsPaddingTop="xs">
          <span>{{
            "literature.cart-download-unavailable-alert-body-msg" | translate
          }}</span>
        </div>
      </ng-template>

      <!-- tablet and mobile view -->
      <ng-template #mobileBlock>
        <div edsFlexbox flexDirection="column" edsSpacing="md">
          <eds-panel [hasBorders]="true" *ngFor="let data of literatureData">
            <div
              edsFlexbox
              edsPadding
              edsPaddingTop="lg"
              edsPaddingLeft="lg"
              edsPaddingRight="lg"
              edsPaddingBottom="md"
            >
              <div edsFlexbox flexDirection="column">
                <div class="full-results-literature__fund-detail">
                  <small>{{ data.documentIcon }}</small>
                  <div>
                    <eds-link-item
                      *ngIf="data.litUrl"
                      [href]="data.litUrl"
                      [noTitleColor]="true"
                      [isNewWindow]="false"
                    >
                      <eds-title
                        [title]="data.litName"
                        [level]="6"
                        [size]="'h6'"
                        edsSpacing="xs"
                        theme="search"
                      ></eds-title>
                    </eds-link-item>

                    <eds-title
                      *ngIf="!data.litUrl"
                      [title]="data.litName"
                      [level]="6"
                      [size]="'h6'"
                      edsSpacing="xs"
                      theme="search"
                    ></eds-title>

                    <small class="full-results-literature__fund-detail">{{
                      data.fundDetail
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div
              edsPadding
              edsPaddingTop="md"
              edsPaddingLeft="lg"
              edsPaddingRight="lg"
              edsPaddingBottom="md"
            >
              <eds-info-item [info]="data.fundDetailLabel" *ngIf="showFinra">
                <div edsFlexbox justifyContent="flex-end" alignItems="center">
                  <span edsPadding edsPaddingRight="xs">{{
                    data.clientUseValue
                  }}</span>
                  <eds-link-item
                    *ngIf="data.finraPath"
                    [title]="data.clientName"
                    [useSmallText]="true"
                    [hasIcon]="true"
                    [isNewWindow]="true"
                    type="new-window"
                    size="small"
                    [href]="litFinraUrl + data.litCode"
                  ></eds-link-item>
                </div>
              </eds-info-item>
              <eds-info-item
                *ngFor="let info of data.fundDetails"
                [info]="info"
              >
              </eds-info-item>
            </div>
            <div
              edsFlexbox
              edsPadding
              edsPaddingLeft="lg"
              edsPaddingRightt="lg"
              edsPaddingTop="md"
              edsSpacing="lg"
            >
              <div *ngIf="!data.downloadNotAvailble; else noDownloadBlock">
                <div edsPadding edsPaddingRight="sm">
                  <button
                    [noMargin]="true"
                    [hasIcon]="true"
                    edsButton="primary"
                    size="compact"
                    (click)="downloadDocument(data)"
                  >
                    <span>{{ data.downloadBtn }}</span>
                    <eds-icon
                      type="download"
                      size="small"
                      edsPadding
                      edsPaddingLeft="xs"
                    ></eds-icon>
                  </button>
                </div>
              </div>
              <div>
                <!-- WDE-5309 Hiding the Add to Cart Button for International Sites -->
                <button
                  *ngIf="
                    (!data.noFinraLink || data.customFilePath) &&
                    data.printOnly !== 'Y' &&
                    !isSiteInternational
                  "
                  edsButton="secondary"
                  size="compact"
                  [noMargin]="true"
                  (click)="updateCart(data)"
                >
                  <span
                    >{{
                      cartItems.includes(data.litCode) ||
                      cartItems.includes(data.customFilePath)
                        ? this.labelAdded
                        : this.labelAdd
                    }}
                  </span>
                  <eds-icon
                    type="cart"
                    size="small"
                    edsPadding
                    edsPaddingLeft="xs"
                  ></eds-icon>
                </button>
              </div>
            </div>
            <div
              *ngIf="data.hardcopyNotAvailable"
              edsPadding
              edsPaddingLeft="lg"
            >
              <eds-error-message
                errorMessage="{{
                  'literature.cart-hardcopy-alert-body-msg' | translate
                }}"
                theme="dark"
              ></eds-error-message>
            </div>
          </eds-panel>
        </div>
      </ng-template>
    </ng-container>
  </eds-search-panel>

  <eds-search-panel
    panelId="literature"
    [leftTitle]="literatureHeader.leftTitle"
    [rightTitle]="literatureHeader.rightTitle"
    [viewMoreBtnText]="literatureHeader.viewMoreBtnText"
    (viewMoreTrigger)="onViewMore($event)"
    *ngIf="!literatureData.length"
  >
    <eds-thumbnail-search
      class="full-results__search-thumbnail"
      [hasBorders]="false"
      [hasShadows]="false"
      [headingLevel]="2"
      [layoutCompact]="true"
      [layoutCompactWide]="true"
      fundFamily=""
      [fundName]="noResultsFilters"
      shareClasses=""
      titleLink=""
    >
    </eds-thumbnail-search>
  </eds-search-panel>
</div>
