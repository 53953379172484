import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  DocViewer,
  DocViewerDisableContent,
} from '@marketing/doc-viewer/doc-viewer.component';
import { ModalService } from '@frk/eds-components';
import { ProfileService, StorageService } from '@services';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@shared/translate/translate.service';

@Component({
  selector: 'ft-doc-viewer-modal',
  templateUrl: './doc-viewer-modal.component.html',
  styleUrls: ['./doc-viewer-modal.component.scss'],
})
export class DocViewerModalComponent implements OnInit, OnDestroy {
  // url: document url.
  @Input() url: string;

  // Office - files supported .ppt, .pptx, .doc, .docx, .xls and .xlsx
  // PDF - .PDF
  // URL - PDF
  @Input() viewer: DocViewer = 'url';

  // Disable user interaction on document (works only with 'url' viewer)
  @Input() disableContent?: DocViewerDisableContent = 'none';

  private unsubscribe$: Subject<void> = new Subject<void>();

  private activeDownloadLocationKey = 'current_download_location';

  public isDocument: boolean;

  constructor(
    private modalService: ModalService,
    private profileService: ProfileService,
    private storageService: StorageService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.profileService
      .isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn) => {
        if (isLoggedIn) {
          // Load clicked document/url
          this.storageService
            .retrieve(this.activeDownloadLocationKey)
            .then((locationInfo: string) => {
              if (
                locationInfo &&
                locationInfo !== 'undefined' &&
                locationInfo !== '#'
              ) {
                this.url = locationInfo;
                this.isDocument = locationInfo.indexOf('.widen.net') > -1;
                this.modalService.open('document-loader', {
                  modalId: 'document-loader',
                  type: 'simple',
                  preTitle: this.translateService.instant(
                    'common.docViewer.preTitle'
                  ),
                  title: this.translateService.instant(
                    'common.docViewer.title'
                  ),
                });
              }
            });
        }
      });
  }

  // Close modal after user click on Download Doc / Visit Link
  downloadAndClose(): void {
    this.modalService.close('document-loader');
  }

  clearDownloadLocation(): void {
    // Remove entry from local storage
    this.storageService.remove(this.activeDownloadLocationKey);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
