import { Injectable } from '@angular/core';
import { ColDef } from '@ag-grid-community/core';
import { SubscribedFundsDTO } from '@marketing/subscriptions-manage/subscription-manage.interface';
import snakeCase from 'lodash/snakeCase';
import { Logger } from '@utils/logger';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionsService } from './subscriptions.service';

/**
 * Logger
 */
const logger = Logger.getLogger('SubscriptionsManageComponent');

@Injectable({
  providedIn: 'root',
})
export class SubscriptionProductsService {
  private unSubscribe$: Subject<void> = new Subject<void>();
  private productsUserData$: Subject<SubscribedFundsDTO[]> = new Subject<
    SubscribedFundsDTO[]
  >();
  private loggedIn: boolean;
  private userDataError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  constructor(
    private route: ActivatedRoute,
    private subscriptionsService: SubscriptionsService
  ) {
    // Constructor placeholder.
  }

  /**
   * Return default col definition for products table
   */
  public getDefaultColDefs(): ColDef {
    return {
      sortable: true,
      wrapText: true,
      autoHeight: false,
      resizable: false,
    };
  }

  /**
   * Convert id to snake upper cases
   * @param id - string
   */
  public toSnakeCase(id: string): string {
    return snakeCase(id).toUpperCase();
  }

  /**
   * Set Products User Data
   * @param apiUrl - API URL string
   */
  public setProductsUserData$(apiUrl: string): void {
    let globalId = null;
    this.route.queryParams
      .pipe(
        takeUntil(this.unSubscribe$),
        switchMap((params: { globalId: number }) => {
          globalId = params?.globalId;
          if (!globalId) {
            // Set loggedIn to false when globalId is not set. This is required for MSD integration,
            // where globalID will be set after Okta verification.
            this.loggedIn = false;
            return new Observable(null);
          }
          this.loggedIn = true;
          return this.subscriptionsService.getApiGetRequest$(
            apiUrl + 'globalId/' + globalId
          );
        })
      )
      .pipe(takeUntil(this.unSubscribe$))
      .subscribe(
        (subscribedfunds: SubscribedFundsDTO[]) => {
          this.productsUserData$.next(subscribedfunds);
        },
        (error) => {
          logger.debug(error);
          this.userDataError$.next(true);
        }
      );
  }

  /**
   * Get Products User Data
   */
  public getProductsUserData$(): Observable<SubscribedFundsDTO[]> {
    return this.productsUserData$.asObservable();
  }

  /**
   * Get User Dara Error
   */
  public getUserDaraError$(): Observable<boolean> {
    return this.userDataError$.asObservable();
  }

  /**
   * Get Logged In
   */
  public getLoggedIn(): boolean {
    return !!this.loggedIn;
  }
}
