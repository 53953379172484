<main>
  <section edsGrid edsSpacing="lg">
    <div class="container container--wide">
      <div edsSpacing="md" edsRow edsFlexbox justifyContent="space-between">
        <div edsCol edsSubGridGap="md" edsSubGridTabletGap="md">
          <eds-enhanced-filter
            [filters]="filters"
            [chipsFilter]="chipsFilters"
            [resetText]="'common.reset-filter' | translate"
            (dropdownSelected)="onSelectFilter()"
            (resetTriggered)="resetFilter($event)"
            (chipRemovedTriggered)="chipRemoved($event)"
          ></eds-enhanced-filter>

          <div edsPadding edsPaddingTop="md">
            <div *ngIf="visibleCourses.length > 0" id="courseScrollPosition">
              <ng-container
                [ngTemplateOutlet]="courseCard"
                [ngTemplateOutletContext]="{ courseCards: visibleCourses }"
              ></ng-container>
            </div>

            <!--Pagination-->
            <div edsSpacing="lg"></div>
            <div
              edsFlexbox
              alignItems="center"
              justifyContent="space-between"
              edsSpacing="lg"
              *ngIf="totalRecords > pageSize.value"
            >
              <div edsSpacing="none">
                <eds-pagination
                  [prevPageText]="'common.previousLbl' | translate"
                  [nextPageText]="'common.nextLbl' | translate"
                  [pages]="totalPages"
                  [pageTitle]="pageTitle"
                  (pageChanged)="goToPage($event)"
                  [ariaLabelGoToPage]="'common.ariaLabelGoToPage' | translate"
                  [ariaLabelPageNav]="'common.navigationLandmark' | translate"
                ></eds-pagination>
              </div>
              <!-- Item Per Page Dropdown -->
              <eds-dropdown
                ariaLabel="Dropdown"
                [dropdownId]="'ItemsPerPageBottomDD'"
                [label]="'common.itemsPerPageLbl' | translate"
                [items]="itemsPerPageOption"
                [preselectedValue]="pageSize"
                (dropdownChange)="onDropdownChange($event)"
                edsPadding
                edsPaddingLeft="none"
              ></eds-dropdown>
            </div>
            <!-- Component for no event-->
            <div *ngIf="filteredCourses.length === 0">
              {{ "common.no-course" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<!--
  Course Card Templete
-->
<ng-template #courseCard let-courseCards="courseCards">
  <div edsRow edsSpacing="md">
    <div edsCol>
      <div
        edsSubGrid="layout-2"
        edsSubGridTabletGap="sm"
        edsSubGridTablet="layout-1"
        edsSubGridGap="sm"
        edsSubGridRowGap="sm"
      >
        <div *ngFor="let course of courseCards">
          <ft-course-card
            [course]="course"
            (filterUpdate)="updateFilter($event)"
          ></ft-course-card>
        </div>
      </div>
    </div>
  </div>
</ng-template>
