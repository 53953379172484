import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import {
  AvatarModule,
  ButtonModule,
  DividerModule,
  DropdownModule,
  DynamicTableModule,
  EnhancedFilterModule,
  FlexboxModule,
  GridModule,
  IconModule,
  LinkItemModule,
  PaddingModule,
  PaginationModule,
  PanelModule,
  SpacingModule,
  TableHeaderModule,
  TitleModule,
} from '@frk/eds-components';
import { PipesModule } from '@shared/pipes/pipes.module';
import { TranslateModule } from '@shared/translate/translate.module';
import { CourseCardComponent } from './courses/course-card/course-card.component';
import { CoursesComponent } from './courses/courses.component';
import { EventInfoComponent } from './events/event-info/event-info.component';
import { EventsComponent } from './events/events.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    EventInfoComponent,
    EventsComponent,
    CoursesComponent,
    CourseCardComponent,
  ],
  imports: [
    BrSdkModule,
    CommonModule,
    PanelModule,
    SpacingModule,
    LinkItemModule,
    PaddingModule,
    AvatarModule,
    ButtonModule,
    IconModule,
    TranslateModule,
    PipesModule,
    FlexboxModule,
    EnhancedFilterModule,
    GridModule,
    FlexboxModule,
    PaginationModule,
    DropdownModule,
    TitleModule,
    DividerModule,
    DynamicTableModule,
    TableHeaderModule,
    SharedModule,
  ],
  exports: [
    EventInfoComponent,
    EventsComponent,
    CoursesComponent,
    CourseCardComponent,
  ],
})
export class EventsModule {}
