<ng-container *ngIf="!isExternalLayout; else externalLayout">
  <ng-container *ngIf="page && mapping && configuration">
    <br-page [configuration]="configuration" [mapping]="mapping" [page]="page">
      <ng-template let-page="page">
        <!-- Debug marketo form
      <ft-marketo-form id="xxx"> </ft-marketo-form>-->
        <a
          class="skip-to-content-link print-hidden sr-only"
          tabindex="0"
          href="#top-content"
          #backToTopLink
        >
          {{ "common.skip-to-content" | translate }}
        </a>

        <!-- page-container div -->
        <div class="page page-container">
          <!-- Header -->
          <ng-container brComponent="header-content"></ng-container>
          <!-- / Header -->
          <!-- skip header -->
          <div #mainContent></div>
          <!-- skip header -->

          <!-- Role Selector Modal. Appears when navigating away from a deep linked page if segment not set -->
          <ft-role-selector-modal [page]="page"></ft-role-selector-modal>
          <!-- Sign In Modal. Appears when called from link -->
          <ft-sign-in-modal [page]="page"></ft-sign-in-modal>

          <!-- Confiramtion modal for cart -->
          <ft-confirmation [page]="page"></ft-confirmation>

          <!-- TODO: SEPARATE INTO GENERIC LOADING SCREEN -->
          <ngx-spinner
            name="pageLoadSpinner"
            bdColor="#fff"
            size="medium"
            color="#e8e8e8"
            type="line-scale"
            [fullScreen]="true"
          ></ngx-spinner>
          <!-- SEPARATE INTO GENERIC LOADING SCREEN -->
          <!-- Spinner for Signin Flow -->
          <ngx-spinner
            name="signinSpinner"
            bdColor="rgba(0,0,0,0.6)"
            size="medium"
            color="#fff"
            type="line-scale"
            [fullScreen]="true"
          >
            <p *ngIf="spinnerDetails?.spinnerLoadingText" class="signinSpinner">
              {{ spinnerDetails?.spinnerLoadingText }}
            </p>
          </ngx-spinner>
          <!-- Spinner for Signin Flow -->
          <!-- Alert Component -->
          <ft-accounts-alerts
            [page]="page"
            [notificationDetails]="notificationDetails"
          ></ft-accounts-alerts>

          <!-- Site Alert Component -->
          <ft-site-alert [page]="page"></ft-site-alert>

          <!-- Role Selector Banner. Appears on gateway homepage when segment not set -->
          <ft-role-selector-banner [page]="page"></ft-role-selector-banner>

          <!-- if segment page restricted to segment -->
          <ng-container *ngIf="!segmentOk">
            <div edsGrid>
              <div class="container--wide">
                <div
                  edsRow
                  edsSpacing
                  edsPadding
                  edsPaddingTop="lg"
                  edsPaddingBottom="lg"
                >
                  <div edsCol>
                    <eds-notifications
                      [title]="'common.page-restricted-alert-title' | translate"
                      [body]="'common.page-restricted-alert-body' | translate"
                      [buttonLabel]="
                        'common.page-restricted-role-selector' | translate
                      "
                      [hasIcon]="true"
                      notificationType="warning"
                      [canClose]="false"
                      [hasButton]="true"
                      (buttonClicked)="showRoleSelectorDialog()"
                    ></eds-notifications>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="segmentOk">
            <ng-container [ngSwitch]="layoutName">
              <ft-two-cols
                *ngSwitchCase="'twocol'"
                [page]="page"
                [isWide]="false"
              >
              </ft-two-cols>

              <ft-two-cols
                *ngSwitchCase="'twocolwidecompact'"
                [page]="page"
                [isWide]="true"
              ></ft-two-cols>

              <ft-equal-cols
                *ngSwitchCase="'equalcols'"
                [page]="page"
              ></ft-equal-cols>

              <!-- Tabbed layouts -->
              <ft-one-col-tabbed
                *ngSwitchCase="'onecol-tabs'"
                [numberOfTabs]="6"
                [page]="page"
                [isWide]="isTemit"
              ></ft-one-col-tabbed>

              <ft-one-col-tabbed
                *ngSwitchCase="'onecol-tabs-full-width'"
                [numberOfTabs]="6"
                [page]="page"
                [isWide]="true"
              ></ft-one-col-tabbed>

              <!-- Accordion layouts -->
              <ft-one-col-accordion
                *ngSwitchCase="'onecol-accordion'"
                [numberOfAccordionItems]="15"
                [page]="page"
              ></ft-one-col-accordion>

              <!-- Two Column -->
              <ft-two-cols
                *ngSwitchCase="'twocols'"
                [page]="page"
                [isWide]="false"
              ></ft-two-cols>

              <!-- Two Column Extended-->
              <ft-two-cols-extended
                *ngSwitchCase="'twocols-ext'"
                [page]="page"
              ></ft-two-cols-extended>

              <!-- Product Detail Page -->
              <ft-custom-layout
                *ngSwitchCase="'product-detail'"
                [page]="page"
                layout="productDetailLayout"
              ></ft-custom-layout>

              <!-- US  Index Page -->
              <ft-custom-layout
                *ngSwitchCase="'us-index'"
                [page]="page"
                layout="usIndexLayout"
              ></ft-custom-layout>

              <!-- NJBest Treasury Managed Page -->
              <ft-custom-layout
                *ngSwitchCase="'njbest-treasury-managed-layout'"
                [page]="page"
                layout="njbestTreasuryManagedLayout"
              ></ft-custom-layout>

              <ft-custom-layout
                *ngSwitchCase="'us-capital-gains-layout'"
                [page]="page"
                layout="usCapitalGainsLayout"
              >
              </ft-custom-layout>

              <ft-emea-layout *ngSwitchCase="'emea-layout'" [page]="page">
              </ft-emea-layout>

              <ft-layout-e-vertical-tabs-sidebar
                *ngSwitchCase="'layout-e-verticaltabs-sidebar'"
                [numberOfTabs]="6"
                [isWide]="true"
                [page]="page"
              >
              </ft-layout-e-vertical-tabs-sidebar>

              <ft-alts-layout
                *ngSwitchCase="'alts-homepage-layout'"
                [showHeaderAndFooter]="false"
                [page]="page"
              ></ft-alts-layout>

              <!-- Default to 12 cols -->
              <ft-one-col *ngSwitchDefault [page]="page"></ft-one-col>
            </ng-container>
          </ng-container>

          <!-- New Grid component configuration -->
          <div class="grid" *ngIf="gridLayouts">
            <div
              class="grid-container {{ row.cssClass }}"
              *ngFor="let row of gridLayouts; let i = index"
            >
              <ng-container brComponent="grid-content-{{ i }}"></ng-container>
            </div>
          </div>

          <!--action buttons wrapper for positioning-->
          <div class="action-buttons">
            <!-- Back To Top Button -->
            <div
              class="page-container__back-to-top print-hidden"
              #backToTop
              *ngIf="showButtons"
              [style.bottom]="stickyBackToTopButton"
              edsPadding
              [edsPaddingBottom]="isMobile ? 'sm' : 'lg'"
              [edsPaddingRight]="isMobile ? 'sm' : 'lg'"
              [class.page-container__back-to-top--mobile-padding]="isMobile"
              [ngClass]="{
                'page-container__back-to-top--scroll-padding': hasScrollToFooter
              }"
            >
              <button
                class="page-container__back-to-top-button print-hidden"
                #backToTopLink
                (click)="scrollToTop($event)"
                edsButton="tertiary"
                [size]="isMobile ? 'compact' : 'normal'"
                [style.margin-right]="0"
                [class.page-container__back-to-top-button--extra-padding]="
                  extraPadding
                "
                [ngClass]="{
                  'page-container__back-to-top-button--padding-mobile': mobilePadding
                }"
                data-analytics-no-event="true"
              >
                <eds-icon type="arrow-up" size="normal"></eds-icon>
                <span edsPadding edsPaddingLeft="xs" class="sr-only">
                  {{ "common.back-to-top-button" | translate }}
                </span>
              </button>
            </div>
            <!-- end Back To Top Button -->
          </div>
          <!-- page-container div -->
        </div>
        <!-- Footer -->
        <div #footerScroll id="footer"></div>
        <ng-container brComponent="footer-content"></ng-container>

        <!-- Config goes at the bottom -->
        <ng-container brComponent="page-config"></ng-container>
        <ft-debug [page]="page"></ft-debug>

        <!-- External link dialog -->
        <eds-dialog
          [dialogId]="'external-link'"
          [config]="dialogConfig"
        ></eds-dialog>
        <!-- Custom external link dialog -->
        <eds-dialog
          [dialogId]="'external-link-custom'"
          [config]="dialogConfigCustom"
        ></eds-dialog>

        <ft-doc-viewer-modal></ft-doc-viewer-modal>

        <div ftPageEnd></div>
      </ng-template>
    </br-page>
  </ng-container>
</ng-container>

<!-- Stripped back rendering engine for external libraries -->
<ng-template #externalLayout>
  <ng-container *ngIf="page && mapping && configuration">
    <br-page [configuration]="configuration" [mapping]="mapping" [page]="page">
      <ng-template let-page="page">
        <ft-alts-layout [page]="page"></ft-alts-layout>
      </ng-template>
    </br-page>
  </ng-container>
</ng-template>
