import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidenImgVideoComponent } from './widen-img-video.component';
import { TranslateModule } from '@shared/translate/translate.module';

@NgModule({
  declarations: [WidenImgVideoComponent],
  imports: [CommonModule, TranslateModule],
  exports: [WidenImgVideoComponent],
})
export class WidenImgVideoModule {}
