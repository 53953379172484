<div edsRow>
  <div edsCol>
    <div
      [edsSubGrid]="layout"
      edsSubGridGap="lg"
      [edsSubGridTablet]="(isHandheld$ | async) ? 'layout-1' : layout"
      edsSubGridTabletGap="lg"
    >
      <div
        edsGridItemOrder
        [itemOrderDesktop]="leftItemOrderDesktop"
        [itemOrderTablet]="leftItemOrderTablet"
        [itemOrderMobile]="leftItemOrderMobile"
        [ngStyle]="
          (isMobile$ | async) ? {} : { 'align-self': verticalAlignment }
        "
      >
        <!-- First Col -->
        <ng-container
          *ngTemplateOutlet="
            blockItems;
            context: {
              items: contentBlock.items
            }
          "
        ></ng-container>
      </div>
      <div
        edsGridItemOrder
        [itemOrderDesktop]="rightItemOrderDesktop"
        [itemOrderTablet]="rightItemOrderTablet"
        [itemOrderMobile]="rightItemOrderMobile"
        [ngStyle]="
          (isMobile$ | async) ? {} : { 'align-self': verticalAlignment }
        "
      >
        <!-- Second Col -->
        <ng-container
          *ngTemplateOutlet="
            blockItems;
            context: {
              items: contentBlock.itemsTwo
            }
          "
        ></ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #blockItems let-items="items">
  <!-- Loop block items through -->
  <div
    *ngFor="let item of items"
    [ngSwitch]="item.contentType"
    [edsSpacing]="spacing"
  >
    <ng-template ngSwitchCase="ftcore:TitleBlock">
      <ft-title-block
        [content]="item"
        [isTwoCol]="true"
        [titleColorTheme]="titleColorTheme"
      ></ft-title-block>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:IntroBlock">
      <ft-text-block [content]="item"></ft-text-block>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:ParagraphBlock">
      <ft-text-block [content]="item"></ft-text-block>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:ParagraphBlockFullHtml">
      <div edsRow>
        <div
          edsCol
          class="ck-editor"
          [innerHTML]="item?.contentFull?.value | safeHtml"
        ></div>
      </div>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:Profiles">
      <ft-avatar-block
        [content]="item"
        [page]="page"
        [isTwoCol]="true"
      ></ft-avatar-block>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:Blockquote">
      <div class="block-item">
        <ft-blockquote-block [content]="item"></ft-blockquote-block>
      </div>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:PullQuoteAvatar">
      <div class="block-item">
        <ft-blockquote-avatar-block
          [content]="item"
        ></ft-blockquote-avatar-block>
      </div>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:ChartBlock">
      <div [innerHTML]="item.chart | safeHtml"></div>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:EmbeddedVideo">
      <eds-video [embedScript]="item.videoEmbedCode"></eds-video>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:tableStyleCompound">
      <ft-table-selection [tableData]="item" [page]="page"></ft-table-selection>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:InteractiveComponentCompound">
      <ft-interactive-content-block
        [component]="component"
        [page]="page"
        [content]="item"
      ></ft-interactive-content-block>
    </ng-template>
    <ng-template ngSwitchCase="ftcore:CardGroupCompound">
      <ft-media-card-block
        [contentBlock]="item"
        [page]="page"
      ></ft-media-card-block>
    </ng-template>
    <!-- Default ImageBlock  -->
    <ng-template ngSwitchDefault>
      <!-- Button block-->
      <ng-container *ngIf="item.buttons; else image">
        <ft-buttons-block [content]="item" [page]="page"></ft-buttons-block>
      </ng-container>
      <!-- Image Block-->
      <ng-template #image>
        <div class="block-item" edsSpacing="md">
          <ft-image-block [content]="item"></ft-image-block>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>
