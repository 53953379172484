import dayjs from 'dayjs';
import ptConfig from 'dayjs/locale/nl';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('pt-pt', { ...ptConfig });
export const customPtPtConfig = dayjs.updateLocale('pt-pt', {
  ...ptConfig,
  formats: {
    ...ptConfig.formats,
    L: 'DD.MM.YYYY',
  },
});
