import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDocViewerModule } from 'ngx-doc-viewer2';
import { DocViewerComponent } from './doc-viewer.component';
import { GridModule, SpacingModule } from '@frk/eds-components';
import { SignInModule } from '@shared/sign-in/sign-in.module';
import {
  ComponentMapping,
  DynamicModule,
} from '@services/module-loader.config';

@NgModule({
  declarations: [DocViewerComponent],
  imports: [
    CommonModule,
    NgxDocViewerModule,
    GridModule,
    SignInModule,
    SpacingModule,
  ],
  exports: [DocViewerComponent],
})
export class DocViewerModule implements DynamicModule {
  getComponentMapping(): ComponentMapping {
    return {
      'Document Viewer': DocViewerComponent,
    };
  }
}
