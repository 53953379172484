import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { HighchartsThemeService } from '@frk/eds-components';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('ScatterChartComponent');

/**
 * Interface defines  legends for ScattterChart
 */
export interface ScatterLegend {
  /**
   * color for the legend box.
   */
  pointColor?: string;

  /**
   * labels for legends
   */
  label?: string;
}

@Component({
  selector: 'ft-scatter-chart',
  templateUrl: './scatter-chart.component.html',
  styleUrls: ['./scatter-chart.component.scss'],
})
export class ScatterChartComponent implements OnInit, OnChanges {
  highcharts;
  chartOptions: Highcharts.Options;

  legends: ScatterLegend[];
  @Input() yTitle: string;
  @Input() xTitle: string;
  @Input() title: string;
  @Input() subTitle: string;
  @Input() points: Highcharts.SeriesScatterOptions[];
  @Input() pointHoverFormat: string;
  @Input() xSoftMin: number;
  @Input() ySoftMin: number;

  constructor(private highchartsTheme: HighchartsThemeService) {}

  ngOnInit(): void {
    import(/* webpackChunkName: "highcharts-async" */ 'highcharts').then(
      (highchartsModule) => {
        highchartsModule.setOptions(this.highchartsTheme.themeOptions);
        this.highcharts = highchartsModule;
      }
    );
  }

  ngOnChanges(): void {
    this.legends = this.extractLegends();
    this.chartOptions = this.getChartOptions();
    logger.debug('chartOptions', this.chartOptions);
  }

  private extractLegends(): ScatterLegend[] {
    if (this.points?.length) {
      return this.points.map(
        (
          point: Highcharts.SeriesScatterOptions,
          index: number
        ): ScatterLegend => ({
          pointColor: this.highchartsTheme.getBenchmarkPointColor(
            index,
            this.points.length
          ),
          label: point.name,
        })
      );
    }
  }

  getChartOptions(): Highcharts.Options {
    if (this.points) {
      return {
        legend: {
          enabled: false,
        },
        chart: {
          type: 'scatter',
        },
        title: {
          text: '',
        },
        yAxis: {
          softMin: this.ySoftMin,
          gridLineDashStyle: 'LongDash',
          title: {
            text: this.yTitle,
            x: -10,
            style: {
              color: this.highchartsTheme.themeFonts.colors.grey,
              fontSize: this.highchartsTheme.themeFonts.size.small,
              fontFamily: this.highchartsTheme.themeFonts.fontFamilyNormal,
            },
          },
          labels: {
            style: {
              color: this.highchartsTheme.themeFonts.colors.grey,
              fontSize: this.highchartsTheme.themeFonts.size.p,
              fontFamily: this.highchartsTheme.themeFonts.fontFamily,
            },
          },
        },
        xAxis: {
          softMin: this.xSoftMin,
          gridLineDashStyle: 'LongDash',
          type: 'linear',
          title: {
            text: this.xTitle,
            y: 10,
            style: {
              color: this.highchartsTheme.themeFonts.colors.grey,
              fontSize: this.highchartsTheme.themeFonts.size.small,
              fontFamily: this.highchartsTheme.themeFonts.fontFamilyNormal,
            },
          },
          labels: {
            y: 30,
            style: {
              color: this.highchartsTheme.themeFonts.colors.grey,
              fontSize: this.highchartsTheme.themeFonts.size.p,
              fontFamily: this.highchartsTheme.themeFonts.fontFamily,
            },
          },
        },
        plotOptions: {
          scatter: {
            marker: {
              symbol: 'square',
              radius: 10,
            },
          },
        },
        tooltip: {
          enabled: true,
          pointFormat: this.pointHoverFormat,
        },
        series: this.points,
      };
    }
    return null;
  }
}
