export interface HistoricalDTO {
  pricing?: (PricingHistoryDTO | null)[] | null;
  distribution?: (DistributionHistoryDTO | null)[] | null;
  charts?: ChartsDTO;
  premiumDiscount?: (PremiumDiscountDTO | null)[] | null;
  attribution?: AttributionDTO | null;
  moneyfundliquidity?: (MoneyFundLiquidityDTO | null)[] | null;
}

export interface PricingHistoryDTO {
  fundid?: string | null;
  shclcode?: string | null;
  navdate?: string | null;
  asofdate?: string | null;
  asofdatestd?: string | null;
  nav?: string | null;
  navstd?: string | null;
  navchngval?: string | null;
  currcode?: string | null;
  navchngpct?: string | null;
  applicationprice?: string | null;
  redemptionprice?: string | null;
  reinvestednav?: string | null;
  mktpriceval?: string | null;
  mktpricevalstd?: string | null;
  mktchngval?: string | null;
  mktchngpct?: string | null;
  high52weekrollingval?: string | null;
  high52weekrollingdate?: string | null;
  low52weekrollingval?: string | null;
  low52weekrollingdate?: string | null;
  discountorpremiumincep?: string | null;
  discountorpremiumatlc?: string | null;
  discountorpremiumatlcstd?: string | null;
  bidoraskspread?: string | null;
  highmktprice52weekval?: string | null;
  highmktprice52weekdate?: string | null;
  lowmktprice52weekval?: string | null;
  lowmktprice52weekdate?: string | null;
  masterfundnav?: string | null;
  weeklyliquidassetratio?: string | null;
  dailyliquidassetratio?: string | null;
  viiv?: string | null;
  netshareholderflow?: string | null;
  masternetshareholderflow?: string | null;
}

export interface DistributionHistoryDTO {
  fundid?: string | null;
  shclcode?: string | null;
  shclfeestructr?: string | null;
  shclcurr?: string | null;
  invstmntcategory?: string | null;
  currcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: string | null;
  incmdistexdivdate?: string | null;
  incmdistexpayabledate?: string | null;
  incmdistrecorddate?: string | null;
  capgainpayabledate?: string | null;
  capgainrecorddate?: string | null;
  capgainexdivdate?: string | null;
  capgainreinvestmentdate?: string | null;
  reinvstmntdate?: string | null;
  incmdistamt?: string | null;
  capgainamtlongterm?: string | null;
  capgainamtshortterm?: string | null;
  reinvstmntamt?: string | null;
  exdivnavval?: string | null;
  recdtnavval?: string | null;
  returnofcapitalamt?: string | null;
  totaldistamtmth?: string | null;
  totaldistamtdaily?: string | null;
  distribcnt?: string | null;
  taxexemptincmamt?: string | null;
  longtermcapgainsytd?: string | null;
  shorttermcapgainsytd?: string | null;
  incmdistamtytd?: string | null;
  capgainreinvstmntamt?: string | null;
  capgainfreq?: string | null;
  shcldistribcode?: string | null;
  divpaidoutnetdistincm?: string | null;
  divpaidoutcapital?: string | null;
}

export interface ChartsDTO {
  cummperfhist?: ChartTypeDTO;
}

export interface ChartTypeDTO {
  _10k?: CummPerfHistTimePeriodDTO;
  _100?: CummPerfHistTimePeriodDTO;
}

export interface CummPerfHistTimePeriodDTO {
  sinceinception?: CummPerfHistCategoryDTO;
  _1yr?: CummPerfHistCategoryDTO;
  _3yr?: CummPerfHistCategoryDTO;
  _5yr?: CummPerfHistCategoryDTO;
  _10yr?: CummPerfHistCategoryDTO;
  _15yr?: CummPerfHistCategoryDTO;
  custom?: CummPerfHistCategoryDTO;
}

export interface CummPerfHistCategoryDTO {
  fund?: FundCummPerfHistDTO[];
  benchmark?: BMCummPerfHistCategoryDTO;
}

export interface FundCummPerfHistDTO {
  asofdatestd?: string | null;
  asofdate?: string | null;
  fundid?: string | null;
  shclcode?: string | null;
  currcode?: string | null;
  calcname?: string | null;
  cummmonthlyretactstd?: string | null;
  cummmonthlyretcalcstd?: string | null;
  cummmonthlyretcalc?: string | null;
  period?: string | null;
  charttype?: string | null;
}

export interface BMCummPerfHistCategoryDTO {
  primary: BMCummPerfHistDTO[];
  secondary: BMCummPerfHistDTO[];
}

export interface BMCummPerfHistDTO {
  asofdatestd?: string | null;
  asofdate?: string | null;
  fundid?: string | null;
  shclcode?: string | null;
  currcode?: string | null;
  calcname?: string | null;
  bmacctnum?: string | null;
  bmlabel?: string | null;
  cummmonthlyretactstd?: string | null;
  cummmonthlyretcalcstd?: string | null;
  cummmonthlyretcalc?: string | null;
  period?: string | null;
  charttype?: string | null;
}

export interface PremiumDiscountDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  asofdate?: string | null;
  totdaysinperiod?: string | null;
  daysatnav?: string | null;
  daysatpremium?: string | null;
  daysatdiscount?: string | null;
  greatestpremiumstd?: string | null;
  greatestpremiumlocal?: string | null;
  greatestpremiumdatestd?: string | null;
  greatestpremiumdatelocal?: string | null;
  greatestdiscountstd?: string | null;
  greatestdiscountlocal?: string | null;
  greatestdiscountdatestd?: string | null;
  greatestdiscountdatelocal?: string | null;
  mktvalrangetype?: string | null;
  mktvalrangedesc?: string | null;
  noofdays?: string | null;
  noofdaysstd?: string | null;
  groupsortorder?: string | null;
  dispsortorder?: string | null;
  mktvalrangefrom?: string | null;
  mktvalrangeto?: string | null;
}

export interface MoneyFundLiquidityDTO {
  fundid?: string | null;
  shclcode?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  currcode?: string | null;
  asofdatestd?: string | null;
  asofdate?: string | null;
  shadownav?: string | null;
  masterfundnav?: string | null;
  amrweeklyliquidassetratio?: string | null;
  amrdailyliquidassetratio?: string | null;
  amrweeklyliquidassetamt?: string | null;
  amrdailyliquidassetamt?: string | null;
  mktweeklyliquidassetratio?: string | null;
  mktdailyliquidassetratio?: string | null;
  mktweeklyliquidassetamt?: string | null;
  mktdailyliquidassetamt?: string | null;
  nawp?: string | null;
  matwgtavg?: string | null;
  lifewgtavg?: string | null;
  totsharesoutstanding?: string | null;
  netassets?: string | null;
  mkttotnetassets?: string | null;
}

export interface AttributionDTO {
  country: (AttributionCountryDTO | null)[] | null;
  sector: (AttributionSectorDTO | null)[] | null;
}

export interface AttributionBaseDTO {
  fundid?: string | null;
  fundname?: string | null;
  currency?: string | null;
  startdate?: string | null;
  enddate?: string | null;
  startdatestd?: string | null;
  enddatestd?: string | null;
  benchmark?: string | null;
  portavgweight?: string | null;
  portreturn?: string | null;
  portcontrib?: string | null;
  bmavgweight?: string | null;
  bmreturn?: string | null;
  bmcontrib?: string | null;
  variationavgweight?: string | null;
  variationreturn?: string | null;
  variationcontrib?: string | null;
  alloceffectlocal?: string | null;
  selecteffectlocal?: string | null;
  interacteffectlocal?: string | null;
  totaleffectlocal?: string | null;
  currencyeffect?: string | null;
  alloceffect?: string | null;
  selecteffect?: string | null;
  interacteffect?: string | null;
  totaleffect?: string | null;
  portcurreturn?: string | null;
  portcontriblocal?: string | null;
  portreturnlocal?: string | null;
  todatestd?: string | null;
  fromdatestd?: string | null;
  todate?: string | null;
  fromdate?: string | null;
  levelno?: string | null;
  noofmonths?: string | null;
  calctype?: string | null;
  bmreturnlocal?: string | null;
}

export interface AttributionCountryDTO extends AttributionBaseDTO {
  region?: string | null;
  country?: string | null;
  fperiod?: string | null;
}

export interface AttributionSectorDTO extends AttributionBaseDTO {
  sector?: string | null;
  industry?: string | null;
}
