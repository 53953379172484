import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class StickyFooterService {
  public footerActive$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public footerActiveLogInItem$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  /**
   * Sends updates when the sticky footer is updated on the site
   * @returns Status of sticky footer
   */
  getFooterStatus$(): Observable<boolean> {
    return this.footerActive$.asObservable();
  }
  /**
   * Sends updates when the sticky footer is updated on the site
   * @returns Status of sticky footer
   */
  getFooterMobileStatus$(): Observable<boolean> {
    return this.footerActiveLogInItem$.asObservable();
  }

  /**
   * Will return true if sticky footer is present and vice versa.
   * @param value is sticky footer open of closed based on a boolean value
   */
  public updateStickyFooter(value: boolean): void {
    this.footerActive$.next(value);
  }
  /**
   * Will return true if sticky footer is present and vice versa.
   * @param value is sticky footer open of closed based on a boolean value
   */
  public updateStickyFooterDialogData(value: boolean): void {
    this.footerActiveLogInItem$.next(value);
  }
}
