import dayjs from 'dayjs';
import enCaConfig from 'dayjs/locale/en-ca';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(updateLocale);
dayjs.extend(localeData);

dayjs.locale('en-ca', { ...enCaConfig });
export const customEnCaConfig = dayjs.updateLocale('en-ca', {
  ...enCaConfig,
  formats: {
    ...enCaConfig.formats,
    L: 'MM/DD/YYYY',
    LL: 'MMMM D, YYYY',
  },
});
