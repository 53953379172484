import { Nominal } from 'simplytyped';

// although these are just strings, giving them named (branded) types helps documentation and make typesafe

// individual fund identifier types
export type APIR = Nominal<string, 'APIR'>;
export type ARSN = Nominal<string, 'ARSN'>;
export type Bloomberg = Nominal<string, 'Bloomberg'>;
export type CUSIP = Nominal<string, 'CUSIP'>;
export type FundId = Nominal<string, 'FundId'>; // Internal (Onetis) id used for each fund
export type FundNumber = Nominal<string, 'FundNumber'>; // NB: this is different from FundId
export type ISIN = Nominal<string, 'ISIN'>;
export type MEXID = Nominal<string, 'MEXID'>;
export type ReutersNumber = Nominal<string, 'ReutersNumber'>;
export type RFI = Nominal<string, 'RFI'>;
export type SEDOL = Nominal<string, 'SEDOL'>;
export type Ticker = Nominal<string, 'Ticker'>;
export type Valoren = Nominal<string, 'Valoren'>;
export type VLN = Nominal<string, 'VLN'>;
export type WKN = Nominal<string, 'WKN'>;
export type TAID = Nominal<string, 'TAID'>;

// catch-all fund identifier type
export type FundIdentifier =
  | APIR
  | ARSN
  | Bloomberg
  | CUSIP
  | FundId
  | FundNumber
  | ISIN
  | MEXID
  | ReutersNumber
  | RFI
  | SEDOL
  | Ticker
  | Valoren
  | VLN
  | WKN
  | TAID;
