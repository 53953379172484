import { Distribution } from '@products/models/distribution';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  CurrencyCode,
  DistributionDTO,
  FundId,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class DistributionsMapper extends Mapper<Distribution[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(distributionsDTO: DistributionDTO[]): Distribution[] {
    const distributions: Distribution[] = [];

    distributionsDTO.forEach((distributionDTO: DistributionDTO) => {
      const distribution: Distribution = this.createMapper(
        DistributionMapper
      ).toDomain(distributionDTO);

      if (distribution) {
        distributions.push(distribution);
      }
    });

    return distributions;
  }
}

export class DistributionMapper extends Mapper<Distribution> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(distributionDTO: DistributionDTO): Distribution {
    return {
      fundId: distributionDTO.fundid as FundId,
      shareClassCode: distributionDTO.shclcode as ShareClassCode,
      countryCode: distributionDTO.cntrycode as ConfigurationCountry,
      languageCode: distributionDTO.langcode as ConfigurationLocale,
      currencyCode: distributionDTO.cntrycode as CurrencyCode,
      distributionAsOfDate: distributionDTO.asofdate,
      distributionAsOfDateStd: distributionDTO.asofdatestd,
      capitalGainPayableDate: distributionDTO.capgainpayabledate,
      capitalGainRecordDate: distributionDTO.capgainrecorddate,
      capitalGainExDividendDate: distributionDTO.capgainexdivdate,
      capitalGainReInvestmentDate: distributionDTO.capgainreinvestmentdate,
      shortTermCapitalGain: distributionDTO.capgainamtshrtterm,
      longTermCapitalGain: distributionDTO.capgainamtlngterm,
      incomeDistributionRecordDate: distributionDTO.incmdistrecorddate,
      incomeDistributionPayableDate: distributionDTO.incmdistpayabledate,
      divDistributionIncome: distributionDTO.divpaidoutnetdistincm,
      dividendCapital: distributionDTO.divpaidoutcapital,
      divNetDistributableIncome: distributionDTO.divpaidoutnetdistincm,
      divPaidCapital: distributionDTO.divpaidoutcapital,
      incomeDistributionExDividendDate: distributionDTO.incmdistexdivdate,
      reInvestmentDate: distributionDTO.reinvstmntdate,
      incomeDistributionAmount: distributionDTO.incmdistamt,
      taxableExemptIncomeAmount: distributionDTO.taxexemptincmamt,
      taxEquivalentDistributionRate: distributionDTO.taxequivdistrate,
      reInvestmentAmount: distributionDTO.reinvestmentamt,
      totalDistributionAmountMonthly: distributionDTO.totaldistamtmth,
      capitalGainReInvestmentAmount: distributionDTO.capgainreinvstmntamt,
      returnOfCapitalAmount: distributionDTO.returnofcapitalamt,
      totalDistributionAmountDaily: distributionDTO.totaldistamtdaily,
      estimateFlag: distributionDTO.estimateflag,
      capitalGains: distributionDTO.capgainamtlngterm,
      distributionAsOfDateYear: distributionDTO.asofdatestd
        ? distributionDTO.asofdatestd?.split('-')[0]
        : null,
    };
  }
}
