import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ApolloClientOptions, InMemoryCache } from '@apollo/client/core';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import {
  AccordionModule,
  AgThemeFrkModule,
  AvatarModule,
  BackgroundModule,
  BannerModule,
  BannerStandardComponent,
  BreadcrumbsModule,
  ButtonModule,
  CheckboxModule,
  ChipsModule,
  CssColsModule,
  CtaModule,
  DatePickerComponent,
  DatePickerModule,
  DuetDatePickerComponent,
  DuetDatePickerModule,
  DebugServiceToken,
  DialogModule,
  DividerModule,
  DropdownModule,
  ErrorMessageModule,
  ExpandCollapseModule,
  FlexboxModule,
  FlyoutMenuModule,
  FooterModule,
  FootnoteModule,
  GridModule,
  HeaderModule,
  IconModule,
  ImageModule,
  InfoItemModule,
  LegalModule,
  LinkItemModule,
  LinkListItemModule,
  LinkModule,
  NotificationsModule,
  PaddingModule,
  PaginationModule,
  PanelModule,
  ProximalModule,
  RatingModule,
  SearchModule,
  SearchServiceToken,
  SectionHeaderModule,
  SectionTitleModule,
  SignInModule as EdsSignInModule,
  SimpleModalModule,
  SpacingModule,
  SummaryItemModule,
  SwitchModule,
  TabsModule,
  TextInputModule,
  ThumbnailModule,
  TitleModule,
  ToggleModule,
  ToggleV2Module,
  TooltipModule,
  TrapFocusModule,
  TrapFocusDirective,
  StickyFooterModule,
} from '@frk/eds-components';
import { GatewayModalComponent } from '@marketing/gateway-modal/gateway-modal.component';
import { SEARCH_RESULTS } from '@search/interfaces/search.interface';
import { FullResultsCommonService } from '@search/search-results/full-results/full-results-common.service';
import { FtSearchService } from '@search/services/ftsearch.service';
import { SiteSearchModule } from '@search/site-search.module';
import { AppStateService, DebugService } from '@services';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { HighchartsChartModule } from 'highcharts-angular';
import { AbstractBaseComponent } from './abstract-base/abstract-base.component';
import { StickyHeaderDirective } from './ag-grid-helpers/sticky-header/sticky-header.directive';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { BaseModalComponent } from './base-modal/base-modal.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ConfirmationModalModule } from './confirmation-modal/confirmation-modal.module';
import { DebugComponent } from './debug/debug.component';
import { ButtonDirective } from './directives/button/button.directive';
import { MarketoDirective } from './directives/marketo/marketo.directive';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { ImportantLegalComponent } from './important-legal/important-legal.component';
import { ModalComponent } from './modal/modal.component';
import { PipesModule } from './pipes/pipes.module';
import { ProductTooltipComponent } from './product-tooltip/product-tooltip.component';
import { ScatterChartComponent } from './scatter-chart/scatter-chart.component';
import { SignInModalModule } from './sign-in-modal/sign-in-modal.module';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignInModule } from './sign-in/sign-in.module';
import { SocialShareComponent } from './social-share/social-share.component';
import { TranslateModule } from './translate/translate.module';
import { VisualHintComponent } from './visual-hint/visual-hint.component';
import { VisualHintModule } from './visual-hint/visual-hint.module';
import { OktaLoginCallbackComponent } from './okta-login-callback/okta-login-callback.component';
import { LottieModule } from 'ngx-lottie';
import { Auth0LoginCallbackComponent } from './auth0-login-callback/auth0-login-callback.component';
import { Auth0LogoutCallbackComponent } from './auth0-logout-callback/auth0-logout-callback.component';
import { UsServicingRegistrationModalModule } from '../interactive-content/us-servicing/us-servicing-registration-modal/us-servicing-registration-modal.module';

export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web');
}

let count = 0;

export function createApolloOptions(
  httpLink: HttpLink,
  appStateService: AppStateService
): ApolloClientOptions<any> {
  // op (operation name) is added to url to help debugging
  // id is added to url to provide a unique id for request
  // neither url param is used by apollo
  return {
    link: httpLink.create({
      uri: (operation) =>
        `${appStateService.getPdsApiUrl()}&op=${
          operation.operationName
        }&id=${count++}`,
    }),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  };
}

// Components
@NgModule({
  imports: [
    AgThemeFrkModule,
    AvatarModule,
    BackgroundModule,
    BannerModule,
    BreadcrumbsModule,
    BrSdkModule,
    ButtonModule,
    CommonModule,
    CssColsModule,
    CtaModule,
    DatePickerModule,
    DuetDatePickerModule,
    DialogModule,
    ExpandCollapseModule,
    CheckboxModule,
    FlexboxModule,
    FlyoutMenuModule,
    FooterModule,
    GridModule,
    HeaderModule,
    HighchartsChartModule,
    IconModule,
    InfoItemModule,
    ImageModule,
    LegalModule,
    LinkModule,
    NotificationsModule,
    PaddingModule,
    PanelModule,
    ProximalModule,
    RouterModule,
    SiteSearchModule,
    SectionHeaderModule,
    EdsSignInModule,
    SpacingModule,
    SummaryItemModule,
    TitleModule,
    TooltipModule,
    FormsModule,
    ReactiveFormsModule,
    SimpleModalModule,
    TranslateModule,
    VisualHintModule,
    SignInModule,
    ThumbnailModule,
    PipesModule,
    FootnoteModule,
    SignInModalModule,
    ConfirmationModalModule,
    ErrorMessageModule,
    SectionTitleModule,
    TrapFocusModule,
    StickyFooterModule,
    LottieModule.forRoot({ player: playerFactory }),
    UsServicingRegistrationModalModule,
  ],
  declarations: [
    AbstractBaseComponent,
    BarChartComponent,
    BaseModalComponent,
    BreadcrumbComponent,
    ButtonDirective,
    DebugComponent,
    FooterComponent,
    HeaderComponent,
    ImportantLegalComponent,
    MarketoDirective,
    ModalComponent,
    ProductTooltipComponent,
    GatewayModalComponent,
    SocialShareComponent,
    ScatterChartComponent,
    StickyHeaderDirective,
    OktaLoginCallbackComponent,
    Auth0LoginCallbackComponent,
    Auth0LogoutCallbackComponent,
  ],
  exports: [
    AbstractBaseComponent,
    AccordionModule,
    AgThemeFrkModule,
    AvatarModule,
    BackgroundModule,
    BannerModule,
    BannerStandardComponent,
    BarChartComponent,
    BaseModalComponent,
    BreadcrumbsModule,
    ButtonDirective,
    ButtonModule,
    ChipsModule,
    CssColsModule,
    CtaModule,
    CheckboxModule,
    DatePickerComponent,
    DuetDatePickerComponent,
    DebugComponent,
    DialogModule,
    DividerModule,
    DropdownModule,
    ExpandCollapseModule,
    FlexboxModule,
    FootnoteModule,
    GridModule,
    HighchartsChartModule,
    IconModule,
    ImageModule,
    InfoItemModule,
    LinkItemModule,
    LinkListItemModule,
    LinkModule,
    MarketoDirective,
    // MarketoFormComponent,
    ModalComponent,
    PaddingModule,
    PaginationModule,
    PanelModule,
    ProductTooltipComponent,
    ProximalModule,
    RatingModule,
    ReactiveFormsModule,
    ScatterChartComponent,
    SearchModule,
    SectionHeaderModule,
    SignInModule,
    SimpleModalModule,
    SpacingModule,
    SummaryItemModule,
    SwitchModule,
    TextInputModule,
    TitleModule,
    ToggleModule,
    ToggleV2Module,
    TooltipModule,
    TranslateModule,
    VisualHintComponent,
    // TODO: To remove. Temporarily added for segmentation workaround
    GatewayModalComponent,
    SocialShareComponent,
    ThumbnailModule,
    TabsModule,
    PipesModule,
    SignInModalModule,
    ConfirmationModalModule,
    ErrorMessageModule,
    SectionTitleModule,
    StickyHeaderDirective,
    NotificationsModule,
    TrapFocusModule,
    TrapFocusDirective,
    StickyFooterModule,
  ],
  providers: [
    // order matters below
    { provide: DebugService, useClass: DebugService },
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApolloOptions,
      deps: [HttpLink, AppStateService, DebugService],
    },
    { provide: DebugServiceToken, useExisting: DebugService }, // for EDS
    { provide: SearchServiceToken, useClass: FtSearchService },
    { provide: SEARCH_RESULTS, useClass: FullResultsCommonService },
    DatePipe, // for literatture-ag-grid-helper service
  ],
})
export class SharedModule {}

export const SharedComponentMapping = {
  // 'Important Legal': ImportantLegalComponent  // Navigation
  Breadcrumbs: BreadcrumbComponent,
  Header: HeaderComponent,
  Footer: FooterComponent,
  'Sign In': SignInComponent,
};
