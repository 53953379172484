import {
  ConfigurationCountry,
  ConfigurationLocale,
  FundId,
  PortfolioComponentId,
} from '@types';

export interface Portfolio {
  assetAllocation?: PortfolioAllocation;
  geographicAllocation?: PortfolioAllocation;
  sectorAllocation?: PortfolioAllocation;
  holdings?: PortfolioHolding[];
  fullHoldings?: PortfolioHolding[];
  topHoldings?: PortfolioTopHoldingTable[];
  topTenHoldingsMoneyFunds?: PortfolioTopTenHoldingMoneyFunds[];
  dailyHoldings?: PortfolioDailyHolding[];
  portfoliochars?: PortfolioChars[];
}

export interface PortfolioAllocation {
  /**
   * PortfolioComponentId enum value. Used to check suppression
   */
  componentId: PortfolioComponentId;

  /**
   * If set, Caveats should use this value instead of componentId for footnote placement prefix
   * It should match the component id configured in BR i.e.e a 'base' component id
   */
  caveatComponentId?: string;

  /**
   * e.g. "asset-allocation". Used to define label, caveat, tooltip keys etc
   */
  baseLabelKey: string;

  /**
   * used by K2 Asset Class Expsoure, Geographic Exposure and Security Exposure to display Exposure type charts
   */
  showExposureChart?: boolean;

  /**
   * used by K2 Sector Exposure to hide chart (i.e. display table only)
   */
  hideChart?: boolean;

  /**
   * e.g. "Asset Allocation" as supplied by PDS
   */
  allocationHeading?: string;

  /**
   * Preformatted date string e.g. `'12 March 2020'`
   */
  asOfDate?: string;

  /**
   * Formatted Performance Contribution date range string e.g. '30/11/2020 - 31/12/2020'
   */
  contributionDateRange?: string;

  /**
   * Currently "MV" or "EXP"
   * Used in FIMES keys
   * This appears to be a shortened version of "Market Value" or "Notional Exposure"
   */
  allocationFlag?: string | null;

  /**
   * e.g. `'(Market Value)'`
   */
  calculationBasis?: string;

  /**
   * e.g. `'(% of Total)'`
   */
  calculationType?: string;

  /**
   * e.g. `'(updated monthly)'`
   */
  updateFrequency?: string;

  /**
   * Data to be used for percentage bar on top 10 charts
   */
  totalPercent?: number;

  data: PortfolioAllocationDataPoint[];

  // fimes rule
  hasDerivatives?: boolean | null;
  hasCurrency?: boolean | null;

  /**
   * Use override the default title ("Fund") on the breakdown column in table
   */
  breakdownColumnTitle?: string;
  /**
   * Data to be used for Benchmark Name
   */
  benchmarkName?: string;
}

export interface PortfolioAllocationDataPoint {
  /**
   * e.g. 'Indonesia'
   */
  label: string;

  /**
   * parent label, used for nesting e.g. 'Asia'
   */
  parent?: string;

  /**
   * Formatted Breakdown string e.g. '89.24%'
   */
  breakdown?: string;

  /**
   * Full Breakdown number value for Highcharts to plot with e.g. 89.24
   */
  breakdownStd?: number;

  /**
   * Formatted Benchmark string e.g. '89.24%'
   */
  benchmark?: string;

  /**
   * Full Benchmark number value for Highcharts to plot with e.g. 89.24
   */
  benchmarkStd?: number;

  /**
   * Formatted Duration Contribution string e.g. '1.31 years'
   */
  duration?: string;

  /**
   * Formatted Gross Exposure percent string e.g. '89.24%'
   */
  grossExposure?: string;

  /**
   * Full Gross Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  grossExposureStd?: number;

  /**
   * Formatted Long Exposure percent string e.g. '89.24%'
   */
  longExposure?: string;

  /**
   * Full Long Exposure percent number value for Highcharts to plot with e.g. 89.24
   * NB: probably not used
   */
  longExposureStd?: number;

  /**
   * Formatted Net Exposure percent string e.g. '89.24%'
   */
  netExposure?: string;

  /**
   * Full Net Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  netExposureStd?: number;

  /**
   * Formatted Short Exposure percent string e.g. '89.24%'
   */
  shortExposure?: string;

  /**
   * Full Short Exposure percent number value for Highcharts to plot with e.g. 89.24
   */
  shortExposureStd?: number;

  /**
   * Formatted Target Allocation percent range string e.g. '30% - 70%'
   */
  targetAllocation?: string;

  /**
   * Full Target Allocation percent number value for Highcharts to plot with e.g. 89.24
   */
  targetAllocationStd?: number;

  /**
   * Formatted Actual Allocation percent string e.g. '89.24%'
   */
  actualAllocation?: string;

  /**
   * Full Actual Allocation percent number value for Highcharts to plot with e.g. 89.24
   */
  actualAllocationStd?: number;

  /**
   * Formatted Risk Contribution Allocation percent string e.g. '89.24%'
   */
  riskContributionAllocation?: string;

  /**
   * Formatted Performance Contribution Allocation percent string e.g. '89.24%'
   */
  performanceContributionAllocation?: string;

  /**
   * (optional) child data points
   * only applies to tables
   */
  children?: PortfolioAllocationDataPoint[];

  /**
   * (optional) sort order of point
   */
  order?: number;
}

export interface PortfolioHoldingBase {
  fundId?: FundId;
  countryCode?: ConfigurationCountry;
  languageCode?: ConfigurationLocale;
  asOfDate?: string;
  asOfDateStd?: string;
  updateFrequency?: string;
  allocationFlag?: string;
  hasDerivatives?: boolean;
}

export interface PortfolioHolding extends PortfolioHoldingBase {
  securityTicker?: string;
  isinSecurityNumber?: string;
  securityName?: string;
  industryCode?: string;
  sectorSysNumber?: string;
  sectorName?: string;
  sectorNameStd?: string;
  sectorLevelNumber?: string;
  percentageOfNetAssets?: string;
  percentageOfNetAssetsStd?: string;
  marketValue?: string;
  notionalMarketValue?: string;
  quantityShrpar?: string;
  securityExpirationDate?: string;
  assetClassCategory?: string;
  marketCurrency?: string;
  sedolNumber?: string;
  securityNumber?: string;
  cusipNumber?: string;
  originalCouponRate?: string;
  contracts?: string;
  unrealizedGainLossBase?: string;
  finalMaturityDate?: string;
  investmentCategory?: string;
}

export interface PortfolioTopHoldingTable extends PortfolioHoldingBase {
  holdingName?: string;
  geoCode?: string;
  sectorName?: string;
  breakdownPercentage?: string;
  breakdownPercentageStd?: string;
  calculationBasis?: string;
  calculationType?: string;
  bmname?: string;
}

export type PortfolioHedgeManagerAllocations = {
  asOfDate?: string | null;
  updateFrequency?: string | null;
  strategies: PortfolioHedgeManagerAllocationStrategy[];
};

export type PortfolioHedgeManagerAllocationStrategy = {
  strategy: string;
  totalAllocation: string;
  totalAllocationStd: number;
  allocations: PortfolioHedgeManagerAllocation[];
};

export type PortfolioHedgeManagerAllocation = {
  managerRoster?: string;
  allocation?: string;
  allocationStd?: number;
  displayName?: string;
  shortDescription?: string;
  longDescription?: string;
  imageSrc?: string;
};
export interface PortfolioTopTenHoldingMoneyFunds extends PortfolioHoldingBase {
  securityName?: string;
  countryOfIssue?: string;
  holdingValue?: string;
  holdingValueStd?: string;
  maturityDate?: string;
  maturityDateStd?: string;
  assetType?: string;
  assetTypeStd?: string;
}

export interface PortfolioDailyHolding extends PortfolioHoldingBase {
  securityName?: string;
  percentageOfNetAssets?: string;
  percentageOfNetAssetsStd?: string;
}

export interface PortfolioChars {
  elementName?: string | null;
  elementValue?: string | null;
  elemtTranslatedname?: string | null;
}
