import { Component, Input, OnChanges } from '@angular/core';
import { Events, Speaker } from '../../events.type';

@Component({
  selector: 'ft-event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent implements OnChanges {
  @Input() event: Events;

  public displayLogo = false;
  public displayTwoProfile = false;
  public profiles: Speaker[] = [];

  public ngOnChanges(): void {
    if (this.event) {
      this.profiles = this.event?.speakers?.filter(
        (speaker: Speaker) =>
          speaker?.profileThumbnail && speaker?.profileThumbnail !== 'undefined'
      );
      this.displayLogo =
        this.event.logoUrl &&
        (this.profiles?.length === 0 || this.profiles?.length > 2);
      this.displayTwoProfile =
        this.profiles?.length === 2 ||
        (!this.event.logoUrl && this.profiles?.length > 2);
    }
  }
}
