import { Injectable, OnDestroy } from '@angular/core';
import { NavigationService } from '@frk/eds-components';
// TODO: these services are imported individually until SignInservice is refactored to avoid circular dependency
import { AppStateService } from '@services/app-state.service';
import { IUserProfile } from '@services/profile.interface';
import { ProfileService } from '@services/profile.service';
import { SegmentService } from '@services/segment.service';
import { SiteConfigService } from '@services/site-config.service';
import { UserRole } from '@services/profile.interface';
import {
  LiteratureConfig,
  LiteratureListingConfiguration,
  LiteraturePageLayoutConfig,
  SegmentId,
} from '@types';
import { SH } from '@utils/app.constants';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

interface LiteratureUrls {
  litDetailUrl: string;
  litOrderCheckoutUrl: string;
  litOrderHistoryDetailUrl: string;
  orderConfirm: string;
  orderHistoryList: string;
  orderSuccess: string;
  orderCheckoutAddress: string;
  litListing: string;
  myLiteratureLandingUrl: string;
  manageEmailPreference: string;
}
/**
 * prepare the global configuration for literature
 */
@Injectable({ providedIn: 'root' })
export class LiteratureGlobalConfigService implements OnDestroy {
  public navigationUrls: LiteratureUrls;
  public isInternational: boolean;
  public isCanada: boolean;
  public isLuxembourg: boolean;
  public dateFormat: string;
  public literatureConfig: LiteratureConfig;
  public literatureDownloadExcelIdentifiers: string[];
  public literatureLanguageMapping: string[];
  private literaturePageLayoutConfig: LiteraturePageLayoutConfig;
  private documentMappingConfiguration: Map<string, string>;
  private userProfile: IUserProfile;
  unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private siteConfig: SiteConfigService,
    private navService: NavigationService,
    private profileService: ProfileService,
    private segmentService: SegmentService,
    private appStateService: AppStateService
  ) {
    this.siteConfig.getIsPopulated$().subscribe((isPopulate: boolean) => {
      if (isPopulate) {
        this.literatureConfig = this.siteConfig.literature;
        this.loadLiteratureConfiguration();
      }
    });

    this.profileService
      .getUserProfile$()
      ?.pipe(
        takeUntil(this.unsubscribe$),
        filter((profile: IUserProfile): boolean => {
          if (profile?.isLoggedIn) {
            return !!profile?.profileInfo?.userId;
          } else {
            return true;
          }
        })
      )
      .subscribe((data: IUserProfile) => {
        this.userProfile = data;
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  /**
   * load the all navigation urls, pageType configuration
   * load filters configuration
   * access siteConfig , profile Serive, segment service to load all config
   */
  private loadLiteratureConfiguration(): void {
    this.navigationUrls = {
      litDetailUrl: this.getSpaBaseUrl(this.literatureConfig?.litDetailUrl),
      litListing: this.getSpaBaseUrl(this.siteConfig.literature?.litListing),
      litOrderCheckoutUrl: this.getSpaBaseUrl(
        this.literatureConfig?.litOrderCheckoutUrl
      ),
      litOrderHistoryDetailUrl: this.getSpaBaseUrl(
        this.literatureConfig?.litOrderHistoryDetailUrl
      ),
      orderCheckoutAddress: this.getSpaBaseUrl(
        this.literatureConfig?.orderCheckoutAddress
      ),
      orderConfirm: this.getSpaBaseUrl(this.literatureConfig?.orderConfirm),
      orderSuccess: this.getSpaBaseUrl(this.literatureConfig?.orderSuccess),
      orderHistoryList: this.getSpaBaseUrl(
        this.literatureConfig?.orderHistoryList
      ),
      myLiteratureLandingUrl: this.getSpaBaseUrl(
        this.literatureConfig?.myLiteratureLandingUrl
      ),
      manageEmailPreference: this.getSpaBaseUrl(
        this.literatureConfig?.manageEmailPreference
      ),
    };
    this.literatureDownloadExcelIdentifiers = this.siteConfig.literature?.downloadExcelIdentifiers;
    this.literatureLanguageMapping = this.siteConfig.literature?.languageMapping;
    this.isInternational = this.siteConfig.isSiteInternational();
    this.isCanada = this.siteConfig.isCanada();
    this.isLuxembourg = this.siteConfig.isLuxembourg();
    this.dateFormat = this.siteConfig.common?.dateFormat;
    this.documentMappingConfiguration = new Map<string, string>();
    if (this.literatureConfig.criteriaType) {
      this.literatureConfig.criteriaType.forEach((criteria) => {
        criteria.documentTypeDisplay?.forEach((docType) => {
          this.documentMappingConfiguration.set(
            docType,
            criteria.criteriaTypeName
          );
        });
      });
    }
  }

  public getCmsBaseUrl(): string {
    return this.appStateService?.getCmsBaseUrl();
  }

  public getDamUrlParam() {
    return this.appStateService?.getDamUrlParam();
  }
  public getLiteratureDownloadExcelIdentifiers(): string[] {
    return this.literatureDownloadExcelIdentifiers;
  }

  public getLiteratureLanguageMapping(): string[] {
    return this.literatureLanguageMapping;
  }
  /**
   *
   * @param url - all literature uls
   * @returns urls with base url
   */
  private getSpaBaseUrl(url: string) {
    if (this.appStateService?.getSpaBaseUrl) {
      // guard needed for unit tests!!!
      return `${this.appStateService.getSpaBaseUrl()}/${url}`;
    }
    return url;
  }
  /**
   *
   * @returns Page Type based configuration-for criteria mapping
   * criteria is configured in BR(fundId/shareclass/umbrella level)
   * this mapping tells the at which level documets would be visible
   */
  public getDocumentMappingConfiguration(): Map<string, string> {
    return this.documentMappingConfiguration;
  }
  /**
   *
   * @param url - any navigation basd url
   * use eds nav service to navigate
   */
  public navigateToUrl(url: string): void {
    this.navService.navigateByUrl(url);
  }
  /**
   *
   * @returns user profile object
   */
  public getUserProfile(): IUserProfile {
    return this.userProfile;
  }
  /**
   *
   * @returns boolean flag based on current user is logged In and role is not shareholder
   * This rule is used to show/hide some details on listing pages
   */

  IsUserLoggedInNotShareHolder(): boolean {
    return (
      this.userProfile?.isLoggedIn &&
      UserRole[this.userProfile?.profileInfo?.role] !== SH.toLocaleLowerCase()
    );
  }
  /**
   *
   * @returns Literature Listinng page config according to page type setup from BR
   */

  public getLiteratureListingConfig(): LiteratureListingConfiguration {
    if (this.literaturePageLayoutConfig) {
      return this.literatureConfig?.pageType?.find(
        (item: LiteratureListingConfiguration) =>
          item.pageTypeName === this.literaturePageLayoutConfig.pageType
      );
    }
    return;
  }
  /**
   *
   * @param literaturePageLayoutConfig from BR
   * set the page type based config for listing page
   */

  public setLiteratureListingConfig(
    literaturePageLayoutConfig: LiteraturePageLayoutConfig
  ) {
    this.literaturePageLayoutConfig = literaturePageLayoutConfig;
  }

  public checkAudienceFromOverrideList(): boolean {
    return this.appStateService
      .useLiteratureOverrideListFor()
      ?.includes(this.appStateService.getChannel());
  }
  public getSegmentId(): SegmentId {
    return this.segmentService.getCurrentSegmentId();
  }

  // verifies if document type requires redirect to previe instwed direct download
  public requiresPreview(docType: string): boolean {
    return this?.literatureConfig?.documentsWithPreview?.includes(docType);
  }
}
