import { DOCUMENT, ViewportScroller } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { WindowOffset, WindowService } from '@frk/eds-components';
import { WINDOW } from '@ng-web-apis/common';
import { AsyncPageSection } from '@services';
import { Logger } from '@utils/logger';
import {
  BehaviorSubject,
  NEVER,
  Observable,
  of,
  ReplaySubject,
  Subject,
  timer,
} from 'rxjs';
import {
  concatMap,
  delay,
  delayWhen,
  distinctUntilChanged,
  expand,
  filter,
  map,
  share,
  switchMap,
  takeUntil,
  withLatestFrom,
} from 'rxjs/operators';
import { AsyncContentManagerService } from './async-content-manager.service';

const logger = Logger.getLogger('WindowScrollService');

/**
 * Used to hold id and location of page section
 */
interface OrderedSection {
  /**
   * element id for the section
   */
  id: string;
  /**
   * distance between element top and page top
   */
  top: number;
}

interface ScrollRequest {
  // anchor id to scroll to
  anchor: string;
  // if anchor doesn't exist in DOM yet, e.g. tab within section
  // instead try to scroll to section
  sectionAnchor?: string;
  // minimum delay (in ms) before executing scroll
  minDelay: number;
}

export interface ScrollResult {
  anchor: string;
  success: boolean;
}

const SCROLL_OFFSET = 16;
const SCROLL_DELAY = 2000;

// 2000ms. ScrollSpy functionality will be suppressed for 2s after a sticky tab is clicked
const SCROLL_SUPPRESS_DURATION = 2000;
// rough offset when calculating current section for ScrollSpy functionality
const SCROLL_SPY_OFFSET = 80;

// used to create an observable that returns false after a delay
const delayedFalse = () => of(false).pipe(delay(SCROLL_SUPPRESS_DURATION));

// when source emits true, expand() cause an additional false emit after a specificed delay
// i.e. the true emit opens the gate, and this causes it to close after a delay
const autoOffAfterDelay = (val: boolean) =>
  of(val).pipe(expand((x) => (x ? delayedFalse() : NEVER)));

/**
 * A service that provides various helper methods for dealing with page scrolling
 */
@Injectable({
  providedIn: 'root',
})
export class WindowScrollService implements OnDestroy {
  private suppressScrollEvents$: BehaviorSubject<boolean> = new BehaviorSubject(
    false
  );
  private suppressScrollObs$: Observable<boolean>;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private scrollObs$: Observable<WindowOffset>;

  private yScrollOffset = 0;

  // stream of scroll requests, that can be delayed until components are loaded
  private scrollRequests$: Subject<ScrollRequest> = new Subject<ScrollRequest>();

  // stream of completed scroll requests, that contain the anchor and result (completed or cancelled)
  private completedScrollRequests$: Observable<ScrollResult> = new Observable<ScrollResult>();

  /**
   * Stream of (throttled) window scroll events
   */
  scroll$: ReplaySubject<WindowOffset>;

  /**
   * NB: services only have ngOnDestroy() hook, so this will do setup work
   */
  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) private documentRef: Document,
    private viewportScroller: ViewportScroller,
    private windowService: WindowService,
    private acm: AsyncContentManagerService
  ) {
    this.scroll$ = new ReplaySubject();
    this.scrollObs$ = this.windowService.getScrollObs$(100); // throttles by 100ms
    this.scrollObs$.pipe(takeUntil(this.unsubscribe$)).subscribe(this.scroll$);

    // this observable acts as a suppression gate
    // suppression is turned on by a true emit, and will be followed by a false emit after a delay
    // switchMap() cancels any previous true emits, so we don't have race conditions
    this.suppressScrollObs$ = this.suppressScrollEvents$.pipe(
      switchMap(autoOffAfterDelay)
    );
    // this subscribe is just for logging purposes
    this.suppressScrollObs$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((suppress: boolean): void => {
        logger.debug('suppress scroll spy', suppress);
      });

    // listen for scroll requests
    // if necessary, wait for components to finish loading before scrolling
    this.completedScrollRequests$ = this.scrollRequests$.pipe(
      takeUntil(this.unsubscribe$),
      // delay scroll while page/section/images are loading
      delayWhen(this.anchorTriggerFnc),
      // add in a small additional delay to allow DOM to update first
      delayWhen((req: ScrollRequest) => timer(req.minDelay)),
      // concatMap() means we still return an Observable after performing scroll
      concatMap(this.performScroll)
    );

    // this subscribe is just for logging purposes
    this.completedScrollRequests$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: ScrollResult): void => {
        logger.debug(
          `async scroll to ${result.anchor} ${
            result.success ? 'completed' : 'failed'
          }`
        );
      });
  }

  /**
   * unsubscribes from pageScroll service when service is destroyed
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public setYOffset(height: number): void {
    this.yScrollOffset = height + SCROLL_OFFSET;
    this.viewportScroller.setOffset([0, this.yScrollOffset]);
    logger.debug('set ViewportScroller offset', this.yScrollOffset);
  }

  // returns the current viewportScroller offset
  public getYOffset(): number {
    return this.yScrollOffset;
  }

  /**
   * components should call this when they are created
   */
  public stickyTabsResize(cmpId: string, height: number): void {
    logger.debug('tabs resized', cmpId, height);
    this.setYOffset(height);
  }

  /**
   * call this to suppress scroll spy events (i.e. getCurrentSection$())
   * while scrolling has been triggered by link click
   */
  public suppressScrollSpy(): void {
    logger.debug('suppressScrollSpy()');
    this.suppressScrollEvents$.next(true);
  }

  /**
   * Watches an array of html elements, and returns a stream that updates as the user scrolls, indicating which element the user is
   * currently viewing
   * @param ids Array of html element ids
   * @param (optional) offsetVal Space at top of screen to allow for when calculating the current section. E.g. height of a sticky header
   * @param (optional) suppressor$ observable that can suppress scroll events. Used to stop events firing when scrolling due to tab clicked
   */
  public getCurrentSection$(
    ids: string[],
    offsetVal?: number,
    suppressor$?: Observable<boolean>
  ): Observable<string> {
    logger.debug('getCurrentSection$() ids:', ids);
    return this.windowService.getScrollObs$().pipe(
      withLatestFrom(
        suppressor$ || this.suppressScrollObs$,
        this.windowService.isPageBottom$()
      ),
      filter(
        ([scrollOffset, isSuppressed, isPageBottom]: [
          WindowOffset,
          boolean,
          boolean
        ]): boolean => {
          return !isSuppressed;
        }
      ),
      map(
        ([scrollOffset, isSuppressed, isPageBottom]: [
          WindowOffset,
          boolean,
          boolean
        ]): string => {
          // recalculate sections each time
          // this may not be efficient, but they may not exist initially
          const sections: OrderedSection[] = ids
            .reduce((prev: OrderedSection[], id: string): OrderedSection[] => {
              const el: HTMLElement = this.documentRef.getElementById(id);
              if (el) {
                prev.push({
                  id,
                  top: el.offsetTop,
                });
              }
              return prev;
            }, [])
            .sort(
              (a: OrderedSection, b: OrderedSection): number => b.top - a.top
            ); // IMPORTANT: reverse order
          // logger.debug('sections:', isSuppressed, scrollOffset.y, sections);
          if (sections.length < 1) {
            // no sections found on page, so return null
            return null;
          }
          if (isPageBottom) {
            // return last section id
            return sections[0].id;
          }
          let offset: number;
          if (offsetVal) {
            offset = offsetVal;
          } else {
            offset = SCROLL_SPY_OFFSET + this.getYOffset();
          }
          //  find intersecting section
          const section: OrderedSection = sections.find(
            (s: OrderedSection): boolean => s.top <= scrollOffset.y + offset
          );
          if (section) {
            // return intersecting section
            return section.id;
          }
          // we must be above the first section, so just return first section
          // remember the array order is reversed, so first on page is last in array
          return sections[sections.length - 1].id;
        }
      ),
      distinctUntilChanged(),
      share()
    );
  }

  /*
    scrolls to top of page
    in future, this may delay scrolling if images/content or data is loading
  */
  public scrollToTop(): void {
    // TODO: add delay for in-progress components?
    // TODO: could return Promise for when scroll finished?
    logger.debug('new scroll to top request');
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  /*
    scrolls to an anchor on page e.g. <div id="someAnchor">...</div>
    the actual scrolling can be delayed while images/content or data is loading
  */
  public scrollToAnchor(anchor: string, minDelay = 10): void {
    const section: AsyncPageSection = this.acm.getSectionFromId(anchor);
    const req: ScrollRequest = {
      anchor,
      minDelay,
      // if section found, return it's parent id
      // otherwise use original anchor (e.g. page doesn't have sections)
      sectionAnchor: section?.id || anchor,
    };
    logger.debug('new scroll request', req);
    this.scrollRequests$.next(req);

    // TODO: return Observable with ScrollResult for this anchor
  }

  // does the actual scroll to destination anchor on page
  // scrolling can be offset to account for sticky tabs on page
  private performScroll = (req: ScrollRequest): Observable<ScrollResult> => {
    // NB: viewportScroller.scrollToAnchor() appears to be buggy, so we use viewportScroller.scrollToPosition() instead
    let targetElement: HTMLElement = this.documentRef.getElementById(
      req.anchor
    );
    if (!targetElement) {
      // couldn't find target in DOM
      // instead try to scroll to parent section
      targetElement = this.documentRef.getElementById(req.sectionAnchor);
    }
    if (targetElement) {
      const scrollDistance: number =
        this.windowRef.scrollY +
        targetElement.getBoundingClientRect().top -
        this.getYOffset();
      logger.debug(
        'async page performScroll',
        this.windowRef.scrollY,
        targetElement.getBoundingClientRect().top,
        this.getYOffset(),
        scrollDistance,
        req.anchor,
        req.minDelay
      );
      this.suppressScrollEvents$.next(true);
      this.viewportScroller.scrollToPosition([0, scrollDistance]);
      return of({ anchor: req.anchor, success: true }).pipe(
        delay(SCROLL_DELAY)
      );
    }
    logger.debug(
      'async performScroll target not found!',
      req.anchor,
      req.minDelay
    );
    return of({ anchor: req.anchor, success: false });
  };

  // this is used inside a delayWhen() operator to delay until the anchor is ready for scrolling to
  private anchorTriggerFnc = (req: ScrollRequest): Observable<boolean> =>
    this.acm.getAnchorReady$(req.anchor).pipe(filter((v) => v));
}
