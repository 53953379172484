<ng-container [ngSwitch]="componentVariant">
  <ng-container *ngSwitchCase="'Events'">
    <ft-events [page]="page" [component]="component"></ft-events>
  </ng-container>
  <ng-container *ngSwitchCase="'Courses'">
    <ft-courses [page]="page" [component]="component"></ft-courses>
  </ng-container>
  <ng-container *ngSwitchDefault>
    <ft-events [page]="page" [component]="component"></ft-events>
  </ng-container>
</ng-container>
