import { Injectable, Inject } from '@angular/core';
import { FtSearchService } from './ftsearch.service';
import { SearchServiceToken } from '@frk/eds-components';

export interface Filter {
  type: string;
  name: string;
}
export interface FilterEntry {
  name: string;
  filters: string[];
}

@Injectable({
  providedIn: 'root',
})
export class FiltersService {
  activeFilters: string[] = []; // TODO define this type
  filterTypes: string[];

  // TODO GET THIS FROM CONFIG
  // configurations for version 1 (old dev) and v2 (cloud) API
  filterConfig: FilterEntry[];

  filterConfigV2 = [
    {
      name: 'funds',
      filters: ['funds_producttype', 'funds_invmangr', 'funds_assetclass'],
    },
    {
      name: 'literature',
      filters: ['literature_lit_content_type', 'literature_investmentManager'],
    },
    { name: 'pages', filters: ['pages_pageTypeCustom'] },
  ];

  // this needs to be taken from Elastic config or API header
  // http://172.24.32.214/config/_search
  filtersMatching = {};

  filtersMatchingV2 = {
    funds_assetclass: 'exact',
    funds_producttype: 'exact',
    funds_invmangr: 'exact',
    pages_pageTypeCustom: 'exact',
    literature_lit_content_type: 'exact',
    literature_investmentManager: 'exact',
  };

  /**
   * filter names for API version 1 and version 2
   * we assign it to filterLabels after recognizing API version
   */
  filterLabels: {};

  filterLabelsV2 = {
    funds_assetclass: 'Asset Class',
    funds_producttype: 'Product Type',
    funds_invmangr: 'Investment Manager',
    pages_pageTypeCustom: 'Article Type',
    literature_lit_content_type: 'Literature Type',
    literature_investmentManager: 'Investment Manager',
  };

  constructor(
    @Inject(SearchServiceToken) private searchService: FtSearchService
  ) {
    // TODO - we no longer use apiver so it is undefined.
    // it may be usefull if we decide to upgrade Api, so not removing, but it can be considered
    this.searchService.apiVer$.subscribe(() => {
      this.filterLabels = this.filterLabelsV2;
      this.filterConfig = this.filterConfigV2;
      this.filtersMatching = this.filtersMatchingV2;
      this.filterTypes = Object.keys(this.filterLabels);

      this.initializeFilters();
    });
  }

  /**
   * filters initialisation
   * we set empty selections for all available filters
   */
  initializeFilters() {
    // setup empty activeFilters or copy existing active filters
    // TODO this can be probably simplified and refactored
    this.filterTypes.forEach(
      (filter) =>
        (this.activeFilters[filter] =
          filter in this.activeFilters ? this.activeFilters[filter] : [])
    );
  }

  /**
   * returns json object to be passed to search API
   */
  getFilters(): string {
    return this.processFilters(this.activeFilters);
  }

  /**
   * returns array of active (selected) filters
   */
  getActiveFilters(): string[] {
    return this.activeFilters;
  }

  /**
   * additional method fixing inconsistent filter names in API input and output
   */
  processFilters(filtersMap: string[]): string {
    // TODO - use reduce for both ?
    const activeMapped = Object.keys(filtersMap)
      .filter((key) => filtersMap[key].length > 0)
      .map((key) => {
        // TODO fix for missing .exact in search - this will be fixed in backend
        let keyFixed = key;

        // example: if key is funds_producttype we need to sent to API producttype.exact as filter
        if (key in this.filtersMatching) {
          keyFixed =
            key.substr(key.indexOf('_') + 1) + '.' + this.filtersMatching[key];
        }

        return { fieldName: keyFixed, fieldValue: filtersMap[key] };
      });

    return JSON.stringify(activeMapped);
  }

  /**
   * returns number of selected options for every filter
   */
  countFilters(): number {
    let filtersCount = 0;

    this.filterTypes.forEach((filter) => {
      if (this.activeFilters[filter].length > 0) {
        filtersCount++;
      }
    });

    return filtersCount;
  }

  /**
   * method resets filters to default value
   * it returns number of active filters before clear
   * we need this information to call API after tab is changed with filters selected
   * filters needs to be clear if tab is changed
   */
  resetFilters(): number {
    // we don't need to reset filters if they were not initialized
    if (!this.filterTypes) {
      return 0;
    }
    let filtersCount = 0;

    this.filterTypes.forEach((filter) => {
      if (this.activeFilters[filter]?.length > 0) {
        filtersCount++;
      }
    });

    // we may need to reset all filters (icluding these not active in search)
    // NGC-9072 issue describes scenario when filters are overwritten by 'insights' component
    Object.keys(this.activeFilters).forEach((filter: string) => {
      this.activeFilters[filter] = [];
    });

    return filtersCount;
  }

  /**
   * Adds new Active Filter after selected from Dropdowns component
   * @param type type of filter
   * @param value selected values of filter
   */
  public addFilter(
    type: string,
    value: string | { label: string; count: number; value: string }[]
  ) {
    if (Array.isArray(value)) {
      this.activeFilters[type] = value.map((filter) => filter.value);
    } else {
      this.activeFilters[type].push(value);
    }
  }

  /**
   * Removes filter from Active Filters
   * @param type type of filter
   * @param value selected values of filter
   */
  public removeFilter(type: string, value: string) {
    this.activeFilters[type] = this.activeFilters[type].filter(
      (e) => e !== value
    );
  }
}
