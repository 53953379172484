import {
  Component,
  EventEmitter,
  Inject,
  Input,
  Output,
  OnInit,
  OnChanges,
  OnDestroy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from '@angular/core';

import { DOCUMENT, ViewportScroller } from '@angular/common';

import { Subject, Observable } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

import { WINDOW } from '@ng-web-apis/common';
import { Logger } from '@utils/logger';

import { FtSearchService } from '@search/services/ftsearch.service';
import { FiltersService } from '@search/services/filters.service';
import { ConfigService } from '@search/services/config.service';
import { SiteConfigService } from '@services/site-config.service';
import { getFirstActiveShareClass } from '@literature/literature-utils';

import {
  SEARCH_RESULTS,
  FtSearchResponse,
  ItemHeader,
} from '@search/interfaces/search.interface';
import { FullResultsFundsService } from './sections/full-results-funds/full-results-funds.service';
import { FullResultsLiteratureService } from './sections/full-results-literature/full-results-literature.service';
import { FullResultsGeneralService } from './sections/full-results-general/full-results-general.service';
import { FullResultsCommonService } from './full-results-common.service';

import { MyliteratureService } from '@literature/literature-dashboard/services/myliterature.service';
import { LiteratureDataService } from '@literature/services/literature-data.service';
import { LiteratureGlobalConfigService } from '@literature/services/literature-global-config.service';
import { CartHandlerService } from '@literature/services/cart-handler.service';

import { TranslateService } from '@shared/translate/translate.service';

import {
  SearchServiceToken,
  ResponsiveService,
  ModalService,
  NavigationService,
  ToggleButton,
  AnalyticsService,
} from '@frk/eds-components';
import {
  CartActionPerformed,
  FundId,
  FundIdentifier,
  LiteratureDocumentListingData,
  LiteratureDocumentListingState,
  SegmentId,
  ShareClassCode,
} from '@types';
import { EMDASH } from '@products/utils/constants/product.constants';
import { LiteratureData } from '@search/interfaces/search.interface';
import { createNavigationString } from '@utils/text/string-utils';
const logger = Logger.getLogger('SearchFullResults');

const IMAGE_TYPE = 'image';
const SERVICE_TYPE = 'literatureUS';
const FINRA_TYPE = 'finra';
@Component({
  selector: 'ft-full-results',
  templateUrl: './full-results.component.html',
  styleUrls: ['./full-results.component.scss'],
  providers: [MyliteratureService],
})
export class FullResultsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() updatedQueryText: string;
  @Input() results: FtSearchResponse[];
  @Input() aggregations: {};
  @Input() activeTab = 'all';
  @Input() searchType: 'search' | 'literature' = 'search';
  @Input() displayedPage = 0;
  @Input() searchTerm: string;
  @Output() viewMoreClicked = new EventEmitter<{
    page: number;
    tabName: string;
  }>();
  @Output() filtered = new EventEmitter<number>();
  @ViewChild('searchScroll') scrollPoint: ElementRef;

  // delay for viewMore click - to handle scroll
  debouncer$: Subject<number> = new Subject<number>();
  componentDestroyed$: Subject<boolean> = new Subject();

  // flag for tracking clicks - to handle scroll after viewMore click
  clickedElemBottom = false;

  // local flag for displaying loading animation/progress bar (future feature)
  loading = false;

  // local apiVersion for different data mapping
  apiVer: string;

  // style for viewMore button
  viewMoreBtnStyle = 'right';

  // tabs configuration
  toggleButtons: ToggleButton[];

  // data for templates
  fundHeader: ItemHeader;
  literatureHeader: ItemHeader;
  generalHeader: ItemHeader;
  fundItems: object[]; // TODO set proper type
  literatureItems: object[]; // TODO set proper type
  generalItems: object[]; // TODO set proper type

  // this needs to be unified - there is separate data feed for All, LiteratureDesktop, Literature Mobile
  literatureData: object[];
  fundData: object[];

  // literature URLs base
  litFinraUrl: string;
  litDownloadUrl: string;
  litThumbnailsUrl: string;

  // no resultc for single section
  noResultsFilters: string;

  currentSegment: SegmentId;

  public isSiteIntl: boolean;

  // responsive service properties
  isMobile$: Observable<boolean>;
  isHandheld$: Observable<boolean>;

  cartItems = [];
  labelAdded: string;
  labelAdd: string;
  additionalShareClassLabel: string;
  viewAllShareClassesLabel: string;
  viewLessShareClassesLabel: string;
  constructor(
    protected filters: FiltersService,
    protected responsiveService: ResponsiveService,
    protected fundDataService: FullResultsFundsService,
    protected searchLiteratureDataService: FullResultsLiteratureService,
    protected generalDataService: FullResultsGeneralService,
    protected myLitratureService: MyliteratureService,
    protected cartHandlerService: CartHandlerService,
    protected literatureDataService: LiteratureDataService,
    protected literatureGlobalConfigService: LiteratureGlobalConfigService,
    protected modalService: ModalService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected navigationService: NavigationService,
    protected configService: ConfigService,
    protected siteConfigService: SiteConfigService,
    protected translateService: TranslateService,
    protected analyticsService: AnalyticsService,
    private viewportScroller: ViewportScroller,
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(SEARCH_RESULTS) public commonDataService: FullResultsCommonService,
    @Inject(DOCUMENT) private documentRef: Document,
    @Inject(SearchServiceToken) public searchService: FtSearchService
  ) {
    // initialize path for literature download
    this.litThumbnailsUrl = this.literatureDataService?.getDownloadUrl(
      IMAGE_TYPE
    );
    this.litFinraUrl = this.literatureDataService?.getDownloadUrl(FINRA_TYPE);
    this.litDownloadUrl = this.literatureDataService?.getDownloadUrl(
      SERVICE_TYPE
    );
    this.isSiteIntl = this.siteConfigService?.isSiteInternational();

    // loading flag subscription
    this.searchService?.loading$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((loading: boolean) => {
        this.loading = loading;
      });

    // api version subscription
    this.searchService?.apiVer$
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((apiVer: string) => {
        this.apiVer = apiVer;
      });

    // delay after filter selection to not refresh filters before multiple elemens are selected
    this.debouncer$
      .pipe(takeUntil(this.componentDestroyed$), debounceTime(300))
      .subscribe((value) => this.filtered.emit(value));

    // message if no results found in a single tab, funds, literature or general
    this.noResultsFilters = this.translateService?.instant(
      'common.no-results-filters'
    );
    this.additionalShareClassLabel = this.translateService?.instant(
      'common.additional-share-class'
    );
    this.viewAllShareClassesLabel = this.translateService?.instant(
      'common.view-all-share-class'
    );
    this.viewLessShareClassesLabel = this.translateService?.instant(
      'common.view-less-share-class'
    );
  }
  /**
   *
   */
  // TODO set type
  downloadDocument(data) {
    let litDownloadUrl = data.litPath;
    // analytics to be added
    if ((!data.litCode || data.litCode === EMDASH) && !data.litPath) {
      return;
    }

    if (data.litCode && data.litCode !== EMDASH) {
      litDownloadUrl = this.litDownloadUrl + data.litCode;
    } else if (
      (!data?.litCode || data?.litCode === EMDASH) &&
      data?.customFilePath
    ) {
      // WDE-5879 handling if no litcode
      litDownloadUrl = this.litDownloadUrl + data?.customFilePath;
    }

    // Check if document requires 'preview'
    if (this.literatureGlobalConfigService?.requiresPreview(data.litType)) {
      if (data.oneTisNumber?.length === 1) {
        const activeShareClasses = this.siteConfigService.product.general
          .activeList;

        let activeFund: FundId;
        let activeShareClass: ShareClassCode;

        [activeFund, activeShareClass] = getFirstActiveShareClass(
          data.oneTisNumber,
          data.shclcode,
          activeShareClasses
        );

        // we assign default value in case there is no active shareclass - error will be triggered from lit-preview
        if (!activeFund || !activeShareClass) {
          activeFund = data.oneTisNumber[0] as FundId;
          activeShareClass = data.shclcode[0] as ShareClassCode;
        }

        const litDownloadUrlChk = this.siteConfigService.getFundLink(
          activeFund,
          activeShareClass,
          data.fund_name[0],
          data.ticker_symbol[0] as FundIdentifier,
          true
        );

        if (litDownloadUrlChk) {
          litDownloadUrl =
            litDownloadUrlChk + '#' + createNavigationString(data?.litType);
        }
      }
    }

    this.myLitratureService.downloadDocument(litDownloadUrl);
  }
  /**
   *
   */
  addToCart(data) {
    const cartData: LiteratureDocumentListingData = {
      literatureData: {
        docId: data.litCode !== EMDASH ? data.litCode : data.customFilePath,
      },
    };
    this.cartHandlerService.addToCart(
      [cartData],
      CartActionPerformed.ADDED_FROM_SEARCH
    );
  }

  /**
   *
   */
  ngOnInit(): void {
    this.isMobile$ = this.responsiveService.isMobile$();
    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.labelAdded = this.translateService.instant('literature.Added-to-cart');
    this.labelAdd = this.translateService.instant('literature.Add-to-cart');

    // get cart state and open cart modal
    this.cartHandlerService
      .getCartItems()
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((item: LiteratureDocumentListingState) => {
        this.cartItems = item.cartItems.map((doc) => doc.docId);
      });
  }

  /**
   * // TODO fix this scroll
   */
  ngOnChanges() {
    // read data from services
    this.getComponentsData();
    this.currentSegment = this.configService.currentSegment as SegmentId; // after NGC-7874 is completed, this can be simplified

    // TODO - refactor this
    if (this.clickedElemBottom) {
      // this is to scroll to the bottom after viewMore was clicked.
      // And to do this only after it was clicked, not after tab changed or new search
      setTimeout(() => this.scrollToBottom(), 250);
    }
  }
  checkRules(literatureDoc) {
    literatureDoc.forEach((data: LiteratureData) => {
      data.thumbnailUrl = this.getThumbnailUrl(data.docTypeSrc);

      const missingLitCode = !data.litCode || data.litCode === EMDASH;

      // no Download button
      if ((missingLitCode && !data.litPath) || data.printOnly === 'Y') {
        data.downloadNotAvailble = true;
      }

      // no Add To Cart button
      if (missingLitCode) {
        data.noFinraLink = true;
      }

      if (
        // removing this condition for now - some documents showing issue with that - comment from literature download component
        data.isOrdItself === 'N' ||
        data.isInv === 'N'
      ) {
        data.hardcopyNotAvailable = true;
      }
    });
    return literatureDoc;
  }
  /**
   * gets data from services
   */

  getComponentsData() {
    this.commonDataService.setResults(this.results);
    this.toggleButtons = this.commonDataService.getToggleButtons(
      this.activeTab,
      this.apiVer
    );

    // simplifying map for validating conditions
    const availableButtons: string[] = this.toggleButtons.map(
      (button) => button.value
    );

    if (availableButtons.includes('funds')) {
      this.fundHeader = this.fundDataService.getFundHeader(this.displayedPage);
      this.fundItems = this.fundDataService.getFundItems();
    }

    if (availableButtons.includes('literature')) {
      this.literatureHeader = this.searchLiteratureDataService.getLiteratureHeader(
        this.displayedPage
      );
      this.literatureItems = this.searchLiteratureDataService.getLiteratureItems();
      // TODO this needs to be unified with literatureItems - view for literature full page
      this.literatureData = this.searchLiteratureDataService.getLiteratureData();
      this.literatureItems = this.checkRules(this.literatureItems);
      this.literatureData = this.checkRules(this.literatureData);
    }

    if (availableButtons.includes('pages')) {
      this.generalHeader = this.generalDataService.getGeneralHeader(
        this.displayedPage
      );
      this.generalItems = this.generalDataService.getGeneralItems();
    }
  }

  /**
   *
   */
  scrollToBottom() {
    const windowHeight = this.windowRef.innerHeight;
    const fullSearchBottomElem = this.documentRef.getElementById(
      'searchResultsBottom'
    );

    const scrollPosition = fullSearchBottomElem.offsetTop - windowHeight + 150; // additional offset to move a little from screen bottom
    logger.debug('search scroll pos: ' + scrollPosition);

    if (this.scrollPoint) {
      // TODO: refactor scrolling to use WindowScrollService
      const element = this.scrollPoint.nativeElement;
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }

    this.clickedElemBottom = false;
  }

  /**
   *
   */
  onEmitFiltered(page: number) {
    this.debouncer$.next(page);
  }

  /**
   *
   */
  onViewMore($tabName: string) {
    this.activeTab = $tabName;
    this.viewMoreClicked.emit({ page: this.displayedPage, tabName: $tabName });
    this.displayedPage += 1;
    this.clickedElemBottom = true;
  }

  /**
   *
   */
  onTabChange(tab: string) {
    this.activeTab = tab;

    // we need to reset filters when filters was selected and tab is changed
    if (this.filters.resetFilters() > 0) {
      this.filtered.emit(0);
    }
  }

  /**
   * provides absolute thumbnail URL from relative or empty string
   * @param thumbnailUrl relative image path
   */
  getThumbnailUrl(thumbnailUrl: string): string {
    if (!thumbnailUrl) {
      return '';
    }

    // see src\app\ft-components\literature\services\literature-data.service.ts - mapLiteratureUrl()
    // we don't need more than 150px in literature table on search page,
    // however Anu said, that image quality was not enough so they decided to pass 500
    if (thumbnailUrl.toLowerCase().startsWith('http')) {
      const url = new URL(thumbnailUrl);
      url.searchParams.append('w', '500');
      url.searchParams.append('quality', '90');

      return url.toString();
    }

    return this.litThumbnailsUrl + thumbnailUrl;
  }

  /**
   * navigates to product page / link
   * @param fundIdentifier navigates to product page
   */
  goToFund(fundLink: string) {
    this.navigationService.navigateByUrl(fundLink);
  }

  /**
   *
   */
  ngOnDestroy() {
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
