<!-- New create/edit button -->
<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <!-- edit button -->
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>

  <!-- Render Content -->
  <div edsGrid *ngIf="content">
    <div [ngClass]="isSmallContainer ? 'container' : 'container--wide'">
      <div edsSubGrid="layout-1" edsSubGridGap="md">
        <div edsFlexbox alignItems="center" edsSpacing="xl">
          <div>
            <eds-title
              edsSpacing="sm"
              [isLight]="true"
              theme="dark"
              *ngIf="content?.videotitle"
              >{{ content.videotitle }}</eds-title
            >
            <div
              *ngIf="content?.summary?.value"
              [innerHTML]="content.summary.value | safeHtml"
            ></div>
            <a
              edsButton="primary"
              [href]="content?.link?.url"
              [title]="content?.link?.title"
              [ngClass]="content?.externalLinkClass"
              [attr.data-signin-required]="content?.link?.signInLink"
              [target]="content?.link?.target"
              >{{ content?.link?.displayText }}</a
            >
          </div>
        </div>
        <div>
          <eds-video [embedScript]="content?.videoscript"></eds-video>
        </div>
      </div>
    </div>
  </div>

  <!-- No Document -->
  <div *ngIf="!content && isEditMode" class="no-document">
    <div edsGrid class="no-document__component">
      <div class="no-document__overlay">
        Click to configure <strong>{{ componentLabel }}</strong>
      </div>
    </div>
  </div>
</div>
