import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MediaCardBlockComponent } from '@marketing/content-blocks/media-card-block/media-card-block.component';
import { MediaCardModule, SpacingModule } from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [MediaCardBlockComponent],
  imports: [
    CommonModule,
    SpacingModule,
    MediaCardModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [MediaCardBlockComponent],
})
export class MediaCardBlockModule {}
