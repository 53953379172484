import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import {
  ProductDetailConfiguration,
  YearOption,
  FundPerformanceMonthlyTotalReturnsState,
  FundPerformanceMonthlyTotalReturnsStateData,
  FundPerformanceMonthlyTotalReturnsAnnualData,
} from '@types';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { FundPerformanceDisplayComponent } from '../fund-performance-display-component.interface';
import { FundPerformanceMonthlyTotalReturnsService } from '../services/fund-performance-monthly-total-returns.service';
import { Observable, Subscription } from 'rxjs';
import { ResponsiveService, ToggleButton } from '@frk/eds-components';
import { generateUUID } from '@utils/text/string-utils';
import { Logger } from '@utils/logger';
import { TranslateService } from '@shared/translate/translate.service';

const logger = Logger.getLogger('FundPerformanceMonthlyTotalReturnsComponent');
@Component({
  selector: 'ft-fund-performance-monthly-total-returns',
  templateUrl: './fund-performance-monthly-total-returns.component.html',
  styleUrls: ['./fund-performance-monthly-total-returns.component.scss'],
})
export class FundPerformanceMonthlyTotalReturnsComponent
  extends BaseProductDetailComponent
  implements FundPerformanceDisplayComponent, OnInit, OnDestroy {
  public populated: boolean;
  public tableData: FundPerformanceMonthlyTotalReturnsStateData = {};
  public isHandheld$: Observable<boolean>;
  public isSiteIntl = true;
  public shareClass: string;
  public yearOptionList: YearOption[] = [];
  public yearOptionSelected: string;
  public toggleButtons: ToggleButton[] = [];
  public selectedTab = '';
  public id = `pricingChartDropDown${generateUUID()}`;
  public currentAsOfDate: string;
  public highChartOptions: Highcharts.Options;
  public isPopulated: boolean;
  private dataSubscription: Subscription[] = [];

  // pagination helpers
  monthlyTotalReturnsTableTotalPages = 0;

  constructor(
    private monthlyTotalReturnsService: FundPerformanceMonthlyTotalReturnsService,
    protected responsiveService: ResponsiveService,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  ngOnInit() {
    this.populated = false;
  }

  populate(
    componentConfig?: FundPerformanceComponentConfig,
    brConfig?: ProductDetailConfiguration,
    component?: BrComponent,
    page?: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    this.toggleButtons = [
      {
        active: true,
        text: this.translateService.instant('products.distribution-table-tab'),
        value: 'table',
      },
      {
        active: false,
        text: this.translateService.instant('products.distribution-chart-tab'),
        value: 'chart',
      },
    ];
    if (this.brConfig.fundId) {
      // Fetching data initially
      this.dataSubscription.push(
        this.monthlyTotalReturnsService.fundPerformanceState$.subscribe(
          (newState: FundPerformanceMonthlyTotalReturnsState) => {
            if (newState.isError) {
              this.isPopulated = false;
            } else {
              this.mapState(newState);
            }
            this.cdr.detectChanges();
          }
        )
      );

      // Subscribing to the current page
      this.dataSubscription.push(
        this.monthlyTotalReturnsService
          .getCurrentPage$()
          .subscribe((monthlyReturnsTableData) => {
            this.mapPaginatedRecords(monthlyReturnsTableData);
            this.cdr.detectChanges();
          })
      );

      // Subscribing to the number of pages
      this.dataSubscription.push(
        this.monthlyTotalReturnsService
          .getNumberOfPages$()
          .subscribe((totalPages) => {
            this.monthlyTotalReturnsTableTotalPages = totalPages;
            this.cdr.detectChanges();
          })
      );
    }
    this.monthlyTotalReturnsService.populate(brConfig);

    this.selectedTab = this.toggleButtons.find(
      (toggleButton: ToggleButton) => toggleButton.active
    )?.value;
  }

  private mapPaginatedRecords(
    monthlyTotalReturnsData: FundPerformanceMonthlyTotalReturnsAnnualData[]
  ): void {
    if (monthlyTotalReturnsData) {
      this.tableData.annualData = monthlyTotalReturnsData;
    }
  }

  /**
   * Populated variables and data utilized by table and chart component.
   * @param monthlyTotalReturnsData Data that we receive from service, mapped to usable format.
   */
  private mapState(
    monthlyTotalReturnsData: FundPerformanceMonthlyTotalReturnsState
  ): void {
    if (monthlyTotalReturnsData) {
      this.shareClass = monthlyTotalReturnsData?.data?.shareClass;
      this.currentAsOfDate = monthlyTotalReturnsData?.data?.currentAsOfDate;
      this.highChartOptions = monthlyTotalReturnsData?.highChartOptions;
      this.cdr.detectChanges();
    }

    if (monthlyTotalReturnsData?.selectedTab) {
      this.toggleButtons.forEach((toggleButton: ToggleButton) => {
        toggleButton.active =
          monthlyTotalReturnsData?.selectedTab === toggleButton.value;
        this.selectedTab = toggleButton.active
          ? toggleButton.value
          : this.selectedTab;
      });
    }
  }

  /**
   * Switches between Table/Chart options
   * @param selectedOption incoming toggle option from UI
   */
  public onToggleActive(selectedOption: ToggleButton): void {
    this.monthlyTotalReturnsService.updateToggle(selectedOption.value);
  }

  /**
   * Trigger page change with the page number selected.
   * @param pageNumber page number selected
   */
  public onPageChanged(pageNumber: number): void {
    this.monthlyTotalReturnsService.goToPage(pageNumber);
  }

  /**
   * unsubscribing to subscriptions when not in use to prevent memory leaks
   */
  ngOnDestroy(): void {
    this.dataSubscription?.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }
}
