<eds-panel
  [hasPadding]="true"
  [hasBorders]="true"
  [hasShadows]="true"
  paddingSize="sm"
  [fullHeight]="true"
>
  <div
    edsFlexbox
    justifyContent="space-between"
    flexDirection="column"
    class="course-card"
  >
    <div>
      <eds-title
        [title]="course?.courseTitle"
        size="h6"
        edsSpacing="xs"
        class="course-title"
      ></eds-title>
      <eds-divider edsSpacing="md"></eds-divider>
      <div
        edsSpacing="xs"
        class="description"
        [innerHTML]="course?.description"
      ></div>

      <div
        edsSubGrid="layout-1"
        edsSubGridTablet="layout-1"
        edsSubGridMobile="layout-1"
        edsSubGridGap="xs"
        edsSubGridMobileGap="xs"
      >
        <div>
          <eds-title
            [title]="'common.approvals' | translate"
            size="h6"
            edsSpacing="xs"
          ></eds-title>
          <eds-dynamic-table
            [colDefs]="colDefs"
            [rowData]="course?.approvals"
            tableTheme="primary"
            [showPagination]="false"
            [padding]="'none'"
            [rowTemplate]="customRowTemplate"
            headerPadding="none"
          ></eds-dynamic-table>
        </div>
        <div>
          <eds-title
            [title]="'common.categories' | translate"
            size="h6"
            edsSpacing="xs"
          ></eds-title>
          <ul class="list--unstyled">
            <li
              *ngFor="let category of course?.categories"
              edsPadding
              edsPaddingBottom="none"
            >
              <eds-link-item
                data-analytics-no-event="true"
                [title]="category"
                (click)="updateFilter($event, category)"
                >{{ category }}</eds-link-item
              >
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div *ngIf="course?.links">
      <eds-divider edsSpacing="xs"></eds-divider>
      <!--Buttons-->
      <div class="course-card__action" edsPadding edsPaddingTop="xs">
        <a
          *ngIf="course?.links?.displayText"
          edsButton="primary"
          class="course-card__action-item course-card__action-item--primary"
          [href]="course?.links?.presentationLinkURL"
          size="normal"
          [attr.data-signin-required]="
            course?.links?.signInLink | ftIsSignInRequired | async
          "
          [attr.data-signin-custom-intro]="course?.links?.signInIntroTextLabel"
          [attr.data-signin-custom-content-text]="
            course?.links?.signInContentTextLabel
          "
          [class.external-link]="course?.links?.external"
          [target]="course?.links?.target"
          data-analytics-no-event="true"
        >
          <span>{{ course?.links?.displayText }}</span>
          <eds-icon
            *ngIf="course?.links?.signInLink && !course?.links?.hideLockIcon"
            edsPadding
            edsPaddingLeft="xs"
            edsTooltip
            tooltipType="medium"
            tooltipPositionDefault="top"
            tooltipBehavior="fixed"
            size="small"
            type="lock"
          ></eds-icon>
        </a>

        <a
          *ngIf="course?.links?.optionalLink"
          size="normal"
          edsButton="secondary"
          [href]="course?.links.optionalLink?.optionalLinkUrl"
          [class.external-link]="course?.links?.optionalLink?.external"
          [target]="course?.links?.optionalLink?.target"
          data-analytics-no-event="true"
          [attr.data-signin-required]="
            course?.links.optionalLink?.signInLink | ftIsSignInRequired | async
          "
          [attr.data-signin-custom-intro]="
            course?.links.optionalLink?.signInIntroTextLabel
          "
          [attr.data-signin-custom-content-text]="
            course?.links?.optionalLink?.signInContentTextLabel
          "
          class="course-card__action-item course-card__action-item--secondary"
        >
          <span>{{ course?.links?.optionalLink?.displayText }}</span>
          <eds-icon
            *ngIf="
              course?.links?.optionalLink?.signInLink &&
              !course?.links?.optionalLink?.hideLockIcon
            "
            edsPadding
            edsPaddingLeft="xs"
            edsTooltip
            tooltipType="medium"
            tooltipPositionDefault="top"
            tooltipBehavior="fixed"
            size="small"
            type="lock"
          ></eds-icon>
        </a>
      </div>
    </div>
  </div>
</eds-panel>
<!--Approval and categories-->
<ng-template #customRowTemplate let-data>
  <tr class="dynamic-table__tr">
    <td class="dynamic-table__td dynamic-table__td--padding-none">
      {{ data.accreditation }}
    </td>
    <td class="dynamic-table__td dynamic-table__td--padding-none">
      {{ data.value }}
    </td>
  </tr>
</ng-template>
