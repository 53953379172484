import {
  ConfigurationCountry,
  ConfigurationLocale,
  FundId,
  ShareClassCode,
} from '@types';

export interface SalesChargeBreakPoint {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  countryCode?: ConfigurationCountry;
  languageCode?: ConfigurationLocale;
  asOfDate?: string;
  asOfDateStd?: string;
  breakPointYearPercentage?: string;
  popBreakPoint?: string;
  distributionRateBreakPoint?: string;
  dataCategory?: string;
  breakPointTypeKey?: number;
}
