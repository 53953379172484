import { AppStateService } from '@services/app-state.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Logger } from '@utils/logger';
import { Observable, of, Subscription } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { OAUTH_TOKEN } from '@utils/app.constants';
const logger = Logger.getLogger('ServerCookieService');

@Injectable({
  providedIn: 'root',
})
export class ServerCookieService {
  private oauthToken: string; // cache value as used in multiple places.

  constructor(
    private appStateService: AppStateService,
    private http: HttpClient
  ) {}

  /**
   * Set cookie in Cookie API
   * @param cookieName - Cookie name string
   * @param cookieParameters - Cookie parameter JSON object
   */
  public set(
    cookieName: string,
    cookieParameters: CookieParameters
  ): Subscription {
    return this.http
      .post(
        this.appStateService.getCookieApiUrl() + cookieName,
        cookieParameters
      )
      .subscribe(
        (response) => logger.info(response),
        (error) => logger.info(error)
      );
  }

  /**
   * Get cookie from Cookie API
   * @param cookieName - Cookie name string
   */
  public getCookieValue$(cookieName: string): Observable<string> {
    const url = this.appStateService.getCookieApiUrl() + cookieName;
    let value: string;
    return this.http.get(url).pipe(
      map((response: { cookieValue: string }) => {
        value = response?.cookieValue;
        logger.debug(value);
        return value;
      })
    );
  }

  public getOauthToken$(): Observable<string> {
    if (this.oauthToken) {
      return of(this.oauthToken);
    }
    return this.getCookieValue$(OAUTH_TOKEN).pipe(
      take(1),
      tap((token) => (this.oauthToken = token))
    );
  }

  public clearOauthToken(): void {
    this.oauthToken = undefined;
  }

  public setOauthToken(token: string) {
    this.oauthToken = token;
  }
}

export interface CookieParameters {
  cookieValue?: string;
  cookieTime?: number;
  userSysNo?: string;
  role?: number;
  rememberMe?: number;
}
