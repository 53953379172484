import dayjs from 'dayjs';
import enGbConfig from 'dayjs/locale/en-gb';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('en-ko', { ...enGbConfig });
export const customEnKoConfig = dayjs.updateLocale('en-ko', {
  ...enGbConfig,
  formats: {
    ...enGbConfig.formats,
    L: 'DD/MM/YYYY',
  },
});
