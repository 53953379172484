import { Component, OnInit, Inject, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LOCATION } from '@ng-web-apis/common';
import { AnalyticsService } from '@frk/eds-components';

const FACEBOOK_APP_ID = '140586622674265';

@Component({
  selector: 'ft-social-share',
  templateUrl: './social-share.component.html',
})
export class SocialShareComponent implements OnInit {
  linkedInSharer = 'https://www.linkedin.com/shareArticle?mini=true&';
  facebookSharer =
    'https://www.facebook.com/dialog/share?app_id=' + FACEBOOK_APP_ID + '&';
  twitterSharer = 'https://twitter.com/share?';

  linkedInUrl: string;
  facebookUrl: string;
  twitterUrl: string;
  emailUrl: string;

  pageTitle: string;
  currentUrl: string;
  pageDec = '';

  @Input() pageType: string;
  /**
   * Event Id used in edsButton directive
   */
  eventId = 'share';

  /**
   * Custom parameters to be used in edsButton directive
   */
  eventParams: { shared_item: string };

  constructor(
    @Inject(LOCATION) readonly locationRef: Location,
    @Inject(DOCUMENT) private documentRef: Document,
    private analytics: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.collectPageInfo();
    this.generateFacbookUrl();
    this.generateLinkedInUrl();
    this.generateTwitterUrl();
    this.generateEmailUrl();
    this.createEventParams();
  }

  /**
   * Parses params and converts them to event object
   */
  createEventParams(): void {
    this.eventParams = { shared_item: this.locationRef.href };
  }

  collectPageInfo() {
    this.pageTitle = this.documentRef.title.replace(/\|/g, '%7C');
    this.currentUrl = this.locationRef.href;

    const ogTag = this.documentRef.querySelector(
      'meta[property="og:description"]'
    );
    const descTag = this.documentRef.querySelector(
      'meta[property="description"]'
    );

    // Metadata
    if (ogTag !== undefined && ogTag !== null) {
      this.pageDec = ogTag.getAttribute('content');
    } else if (descTag !== undefined && descTag !== null) {
      this.pageDec = descTag.getAttribute('content');
    }
  }

  /**
   * Generate the Facebook URL using the share url and page information
   */
  generateFacbookUrl() {
    this.facebookUrl =
      this.facebookSharer +
      'href=' +
      encodeURIComponent(this.currentUrl) +
      '&picture=' +
      '&title=' +
      this.pageTitle +
      '&description=' +
      this.pageDec;
  }

  /**
   * Generate the LinkedIn URL using the share url and page information
   */
  generateLinkedInUrl() {
    this.linkedInUrl =
      this.linkedInSharer +
      'url=' +
      encodeURIComponent(this.currentUrl) +
      '&title=' +
      this.pageTitle +
      '&summary=' +
      this.pageDec;
  }

  /**
   * Generate the Twitter URL using the share url and page information
   */
  generateTwitterUrl() {
    this.twitterUrl =
      this.twitterSharer +
      'text=' +
      this.pageTitle +
      '&url=' +
      encodeURIComponent(this.currentUrl);
  }

  /**
   * Generate the Email URL using the mailto: and page information
   */
  generateEmailUrl() {
    this.emailUrl =
      'mailto:?subject=' + this.pageTitle + '&body=' + this.currentUrl;
  }

  // NGC-13522 : Analytics Fix - Outstanding issues with Lightbox tracking missing label / Not firing
  // We need to fire two analytics events for same component click, currently we don't have any method in eds-link component to
  // fire two events simultaneously. Hence, making this change to fire "lightbox_display" event from the
  // core-site-apps. The "share" event will be fired from eds component as before.
  /**
   * Generate lightbox_display event for analytics tracking
   * @param clickedButton facebook/twitter/Linkedin/Mail
   */
  fireAnalyticsEvent(clickedButton: string) {
    this.analytics.trackEvent({
      event: 'share',
      detailed_event: 'Share Click',
      event_data: {
        content_type: this.pageType,
        item_id: clickedButton,
        method: 'click',
      },
      item_id: clickedButton,
      method: 'click',
    });

    this.analytics.trackEvent({
      event: 'lightbox_display',
      action: 'click',
      label: clickedButton,
    });

    this.analytics.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'click',
        heading: clickedButton,
      },
    });
  }
}
