import { HighchartsThemeService } from '@frk/eds-components';
import {
  BMPerformanceDetail,
  PerformanceDetail,
  PerformanceStatus,
  Product,
  ShareClass,
} from '@models';
import { EMDASH } from '@products/utils/constants/product.constants';
import { ProfileService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import {
  ConfigurationId,
  CurrencyCode,
  FundPerformanceAnnualizedTableData,
  FundPerformanceCalendarYearTableData,
  FundPerformanceCumulativeTableData,
  FundPerformanceDiscreteReturnsTableData,
  ShareClassCode,
} from '@types';
import { numericValueCompare } from '@utils/sort-utils';
import { parseNumber } from '@utils/text/number-utils';
import get from 'lodash/get';
import kebabCase from 'lodash/kebabCase';
import { Observable } from 'rxjs';

export class BaseFundPerformanceService {
  protected static benchmarkPerformancePlacements: string[] = [
    'PrimaryBenchmarkPerformance',
    'SecondaryBenchmarkPerformance',
  ];

  protected static fundPerformancePlacement = 'NAVPerformance';

  private configurationName: ConfigurationId;

  constructor(
    protected translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {}

  isLoggedIn$(): Observable<boolean> {
    return this.profileService.isLoggedIn$();
  }

  setConfigurationName(configurationName: ConfigurationId) {
    this.configurationName = configurationName;
  }

  getConfigurationName(): ConfigurationId {
    return this.configurationName;
  }

  protected extractBasicShareClassFromPerformanceRow(
    performanceData: PerformanceDetail,
    fundName: string,
    shareClass: ShareClass
  ) {
    const currency = this.extractCurrencyFromPerformanceRow(
      performanceData,
      shareClass
    );
    const name = this.extractNameFromPerformanceRow(
      performanceData,
      currency,
      fundName,
      shareClass
    );
    const inceptionDate = shareClass.performanceInceptionDate;

    const feeStructure = shareClass.shareClassFeeStructure;
    return {
      name,
      currency,
      inceptionDate,
      feeStructure,
    };
  }

  private extractCurrencyFromPerformanceRow(
    performanceData: PerformanceDetail,
    shareClass: ShareClass
  ): string {
    let currency = shareClass.shareClassCurrency;
    const shareClassCode = shareClass.identifiers.shareClassCode;
    if (
      shareClass.isSingleShareClass ||
      (shareClassCode.toString().length > 4 &&
        shareClassCode.toString().substr(-3) !== currency)
    ) {
      currency = performanceData.currencyCode;
    }
    return currency;
  }

  private extractNameFromPerformanceRow(
    performanceData: PerformanceDetail,
    currencyCode: string,
    fundName: string,
    shareClass: ShareClass
  ) {
    let name;
    if (
      shareClass.identifiers.shareClassCode &&
      fundName &&
      shareClass.isSingleShareClass
    ) {
      name = `${fundName}`;
    } else {
      const rawShareClassName = `${
        shareClass.shareClassName || performanceData.calcTypeStd
      }`;
      name = this.formatShareClassName(rawShareClassName, currencyCode);
    }
    return name;
  }

  private formatShareClassName(
    shareClassName: string,
    currency: string
  ): string {
    if (!shareClassName || !currency) {
      return shareClassName;
    }
    return shareClassName.replace(new RegExp('-' + currency), '');
  }

  protected compareBenchmarks(
    bm1: BMPerformanceDetail,
    bm2: BMPerformanceDetail,
    benchmarkOrder: string[]
  ): number {
    if (benchmarkOrder.length > 0) {
      const indexBm1 = benchmarkOrder.indexOf(bm1.benchmarkLabel);
      const indexBm2 = benchmarkOrder.indexOf(bm2.benchmarkLabel);
      if (indexBm1 === -1 && indexBm2 === -1) {
        return numericValueCompare(
          bm1.benchmarkLabel,
          bm2.benchmarkLabel,
          null,
          null,
          false
        );
      }
      if (indexBm1 === -1) {
        return 1;
      }
      if (indexBm2 === -1) {
        return -1;
      }
      return indexBm1 - indexBm2;
    }
    return numericValueCompare(
      bm1.benchmarkLabel,
      bm2.benchmarkLabel,
      null,
      null,
      false
    );
  }

  protected isDataNotAvailable(data: string) {
    return !data || data === EMDASH;
  }

  protected convertTableToChartData(data: any, chartColumns: string[]): any[] {
    const newChart = [];
    Object.keys(data).forEach((key) => {
      const obj = data[key];
      const chartRow = [];
      chartColumns.forEach((column) => {
        let val = get(obj, column, null);
        let decimals;
        let seperator;
        if (val) {
          const stripDecimals = val.toString().match(/[,.]([0-9]+)%?$/);
          decimals = val !== EMDASH ? stripDecimals[1].length : 0;
          seperator = val.includes(',') ? ',' : '.';
          val = parseNumber(val);
        }
        if (!get(obj, 'hideOnChart', false)) {
          const label = get(obj, 'calcTypeLabel');
          const suffix = label
            ? ` ${this.translateService.instant(label)}`
            : '';

          chartRow.push({
            name: `${get(obj, 'name')}${suffix} (%)`,
            y: val ? val : null,
            decimals,
            seperator,
          });
        }
      });
      if (chartRow.length > 0) {
        newChart.push(chartRow);
      }
    });
    return newChart;
  }

  protected convertToHighchartsColumnSeries(chartData): any[] {
    if (!chartData) {
      return [];
    }
    const seriesDataInHighchartFormat = [];
    for (let key = 0; key < chartData.length; key++) {
      const chartItem = {
        data: chartData[key],
        type: 'column',
        name: get(chartData, `${key}.0.name`),
        _colorIndex: key,
      };
      seriesDataInHighchartFormat[key] = chartItem;
    }
    return seriesDataInHighchartFormat;
  }

  protected findShareClassByShareClassCode(
    product: Product,
    shareClassCode: ShareClassCode
  ): ShareClass {
    return product?.shareClasses?.find(
      (shareclass) => shareclass?.identifiers?.shareClassCode === shareClassCode
    );
  }

  protected addChartColors(
    rows:
      | FundPerformanceCumulativeTableData[]
      | FundPerformanceDiscreteReturnsTableData[]
      | FundPerformanceCalendarYearTableData[]
      | FundPerformanceAnnualizedTableData[]
  ) {
    let index = 0;
    rows.forEach((row) => {
      if (!row?.hideOnChart) {
        row.pointColor = this.highchartsTheme.getBenchmarkPointColor(
          index++,
          rows.length
        );
      } else {
        row.pointColor = null;
      }
    });
  }

  protected isPerformanceDataAvailable(shareClass: ShareClass): boolean {
    if (
      shareClass?.performance?.monthEnd?.length > 0 ||
      shareClass?.benchmarkPerformance?.monthEnd?.length > 0 ||
      shareClass?.performance?.quarterEnd?.length > 0 ||
      shareClass?.benchmarkPerformance?.quarterEnd?.length > 0
    ) {
      return true;
    }
    return false;
  }

  protected getProductTypeLabel(productType: string): string {
    for (const type in ConfigurationId) {
      if (ConfigurationId[type] === productType) {
        return kebabCase(type);
      }
    }
    return '';
  }

  /**
   * Get performance status caveat name based upon PDS flag.
   * In case no flag, return null.
   * @param currentShareClass currently selected share class
   */
  protected getPerfStatusCaveat(currentShareClass: ShareClass): string {
    return currentShareClass?.performance.quarterEnd[0]?.performanceStatus ===
      PerformanceStatus.FINAL
      ? 'PPSSPerformanceFinalTop'
      : currentShareClass?.performance.quarterEnd[0]?.performanceStatus ===
        PerformanceStatus.PRELIM
      ? 'PPSSPerformancePreEliminaryTop'
      : null;
  }

  protected getBemchmarkPerformancePlacement(index: number): string {
    const palcement =
      BaseFundPerformanceService.benchmarkPerformancePlacements[index];
    return palcement || `BenchmarkPerformance${index + 1}`;
  }

  protected filterPerformanceArrayByCurrencyCode(
    performanceArray: PerformanceDetail[],
    currencyCode: CurrencyCode
  ): PerformanceDetail[] {
    return performanceArray.filter(
      (perf) => perf.currencyCode === currencyCode
    );
  }

  protected filterOutCalcType(
    performanceArray: PerformanceDetail[],
    calcTypeStd: string
  ): PerformanceDetail[] {
    return performanceArray.filter((perf) => perf.calcTypeStd !== calcTypeStd);
  }
}
