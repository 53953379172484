<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <!-- edit button -->
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <ng-container *ngIf="isHandheld$ | async">
    <eds-floating-action-button
      [menuItems]="menuItems"
      [showButtons]="true"
      [moreContentText]="'common.fba-more-content' | translate"
    >
    </eds-floating-action-button>
  </ng-container>
</div>

<!-- No Document -->
<div *ngIf="!document && isEditMode" class="no-document" edsPadding>
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>{{ componentLabel }}</strong>
    </div>
  </div>
</div>
