import { DatePipe } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
// TODO: these services are imported individually until SignInservice is refactored to avoid circular dependency
import { AppStateService, LiteratureFlow } from '@services/app-state.service';
import {
  IUserProfile,
  IUserProfileInfo,
  LoginSource,
} from '@services/profile.interface';
import { PageMonitor } from '@services/page-monitor';
import { ProfileService } from '@services/profile.service';
import { SegmentService } from '@services/segment.service';
import {
  CartDetails,
  EmailDocsRequestBody,
  FileExtension,
  FundComentaryContent,
  FundCommentaryDocument,
  FundId,
  GlobalId,
  LiteratureContentDocument,
  LiteratureData,
  LiteratureDocument,
  LiteratureDocumentData,
  LiteratureDocumentListingData,
  LiteratureHistoryData,
  LiteratureRequestBodyProfile,
  MailFormValues,
  MailRequestBody,
  MailResponseError,
  MailResponseObject,
  SegmentId,
  ShareClassCode,
} from '@types';
import { FA, FP } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import cloneDeep from 'lodash/cloneDeep';
import { asyncScheduler, Observable, of, Subject } from 'rxjs';
import {
  catchError,
  filter,
  shareReplay,
  switchMap,
  tap,
  retry,
  takeUntil,
} from 'rxjs/operators';
import { LiteratureApiService } from './api-services/literature-api.service';
import { LiteratureGlobalConfigService } from './literature-global-config.service';
import { TranslateService } from '@shared/translate/translate.service';
import { NA } from '@products/utils/constants/product.constants';
import { hasValue } from '@products/utils/mappers/mapper.utils';
import dayjs from 'dayjs';
import { PersonalisationAPIService, SiteConfigService } from '@services';
import { PRIMERICA_PARENT_FIRM_GLOBAL_ID } from '@literature/const/literature-data-const';

const logger = Logger.getLogger('LiteratureDataService');

@Injectable({
  providedIn: 'root',
})
export class LiteratureDataService implements OnDestroy {
  private emailBaseUrl: string;
  private profileData: IUserProfile;
  private cachedObservables: Map<
    string,
    Observable<LiteratureData>
  > = new Map();
  private cachedFundComentaryContentObservables: Map<
    string,
    Observable<FundComentaryContent>
  > = new Map();
  private mailDetails: MailFormValues;
  private mailResponseDetails: MailResponseObject;
  private mailResponseError: MailResponseError;
  private isInternational: boolean;
  private unsubscribe$: Subject<void> = new Subject<void>();
  private parentFirmGlobalId: GlobalId;

  constructor(
    private appStateService: AppStateService,
    private segmentService: SegmentService,
    private profileService: ProfileService,
    private literatureGlobalConfig: LiteratureGlobalConfigService,
    private literatureApiService: LiteratureApiService,
    private translateService: TranslateService,
    private personalisationService: PersonalisationAPIService,
    public dateFormatter: DatePipe,
    private siteConfigService: SiteConfigService
  ) {
    this.personalisationService
      .getParentFirmGlobalID$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((parentFirmGlobalId: GlobalId) => {
        this.setParentFirmGlobalId(parentFirmGlobalId);
      });
  }

  public setParentFirmGlobalId(parentFirmGlobalId: GlobalId) {
    this.parentFirmGlobalId = parentFirmGlobalId;
  }

  public getProfileData(): IUserProfile {
    return this.profileData;
  }

  public setProfileData(profileData: IUserProfile) {
    this.profileData = profileData;
  }

  private getApiData(
    useCache: boolean,
    params: Map<string, string>,
    usePOST = false // if true, do POST instead of GET request
  ): Observable<LiteratureData> {
    this.isInternational = this.literatureGlobalConfig.isInternational;
    const url = this.getUrl(params, usePOST);
    let postBody = null;
    if (usePOST) {
      postBody = this.getPostBody(params);
    }
    logger.debug('literature document data url: ', url);
    if (useCache && this.cachedObservables.has(url)) {
      return this.cachedObservables.get(url);
    }
    const req = PageMonitor.startAjaxRequest(url);
    const liData$ = this.appStateService.getUseAPIM(
      LiteratureFlow.LITERATURE_DATA
    )
      ? this.literatureApiService
          .getLiteratureDataAPIMFlow(url, this.getHeaders())
          .pipe(
            tap(
              () => PageMonitor.endAjaxRequest(req),
              (err) => {
                logger.error(
                  '[literatureData] Received error from backend request. Please try to reload this page or try later.' +
                    JSON.stringify(err) +
                    ' [error status]: ' +
                    err.status
                );
              }
            ),
            shareReplay(1),
            retry(1),
            catchError((errorResponse) => {
              logger.error(
                '[literatureData] error while getting data from backend service (after 1 retry):' +
                  JSON.stringify(errorResponse) +
                  ' [error status]: ' +
                  errorResponse.status
              );
              return of(null);
            })
          )
      : this.literatureApiService
          .getLiteratureData(url, this.getHeaders(), postBody)
          .pipe(
            tap(
              () => PageMonitor.endAjaxRequest(req),
              (err) => {
                logger.error(
                  '[literatureData] Received error from backend request. Please try to reload this page or try later.' +
                    JSON.stringify(err) +
                    ' [error status]: ' +
                    err.status
                );
              }
            ),
            shareReplay(1),
            retry(1),
            catchError((errorResponse) => {
              logger.error(
                '[literatureData] error while getting data from backend service (after 1 retry):' +
                  JSON.stringify(errorResponse) +
                  ' [error status]: ' +
                  errorResponse.status
              );
              return of(null);
            })
          );

    if (useCache) {
      this.cachedObservables.set(url, liData$);
      asyncScheduler.schedule(() => {
        this.cachedObservables.delete(url);
      }, 10000);
    }
    return liData$;
  }

  // TODO: fix return type
  private getHeaders() {
    if (this.appStateService.getUseParentFirmGlobalIdForLiterature()) {
      return {
        parentFirmGlobalId: this.getParentFirmGlobalId(),
        'Content-Type': 'application/json',
      };
    } else {
      return {
        dealerNo: this.profileData?.profileInfo?.dealerNo || '',
        'Content-Type': 'application/json',
      };
    }
  }

  private getParentFirmGlobalId(): GlobalId {
    if (this.siteConfigService.isPrimerica()) {
      return PRIMERICA_PARENT_FIRM_GLOBAL_ID;
    } else {
      return this.parentFirmGlobalId || ('' as GlobalId);
    }
  }

  // TODO: fix return type. Can this be deleted?
  private postEmailDocumentsAndObserveEvents(
    url: string,
    requestBody: EmailDocsRequestBody
  ): Observable<any> {
    return this.literatureApiService.postEmailDocumentsAndObserveEvents(
      url,
      requestBody,
      {
        reportProgress: true,
        observe: 'events',
      }
    );
  }

  // TODO: fix return type
  private postEmailDocuments(
    url: string,
    requestBody: EmailDocsRequestBody
  ): Observable<any> {
    if (this.appStateService.getUseAPIM(LiteratureFlow.LITERATURE_EMAIL_DOCS)) {
      const emailDocsAPIMUrl = `${this.appStateService.getLiteratureEmailDocsAPIMUrl()}?channel=${this.appStateService.getChannel()}`;
      return this.literatureApiService.postEmailDocumentsAPIMFlow(
        emailDocsAPIMUrl,
        requestBody
      );
    } else {
      return this.literatureApiService.postEmailDocuments(url, requestBody);
    }
  }

  // TODO: fix return type
  public emailDocuments(
    recipients: string[],
    litCodes: string[]
  ): Observable<any> {
    this.emailBaseUrl = `${this.appStateService.getLiteratureBaseApiUrl()}/email?channel=${this.appStateService.getChannel()}`;

    const requestBody: EmailDocsRequestBody = {
      emailIds: recipients,
      literatureCodes: litCodes,
    };
    return this.postEmailDocuments(this.emailBaseUrl, requestBody);
  }

  public mailDocument(
    mailRequestBody: MailRequestBody,
    checkoutMode?: string
  ): Observable<MailResponseObject> {
    mailRequestBody.emailOrderStatus = true;

    if (this.appStateService.getUseAPIM(LiteratureFlow.SUBMIT_ORDER)) {
      let url = '';
      if ('download' === checkoutMode) {
        url = `${this.appStateService.getLiteratureBaseApiUrl()}/order?checkoutMode=download`;
      } else {
        url = this.appStateService.getSubmitOrderAPIMUrl();
      }

      return this.literatureApiService.mailDocumentAPIMFlow(
        url,
        mailRequestBody
      );
    } else {
      let url = '';
      if ('download' === checkoutMode) {
        url = `${this.appStateService.getLiteratureBaseApiUrl()}/order?checkoutMode=download`;
      } else {
        url = `${this.appStateService.getLiteratureBaseApiUrl()}/order`;
      }

      return this.literatureApiService.mailDocument(url, mailRequestBody);
    }
  }

  public setPostMailResponse(responseData: MailResponseObject): void {
    this.mailResponseDetails = responseData;
  }

  public setPostMailError(responseData: MailResponseError): void {
    this.mailResponseError = responseData;
  }

  public getPostMailError(): MailResponseError {
    return this.mailResponseError;
  }

  public getPostMailResponse(): MailResponseObject {
    return this.mailResponseDetails;
  }

  public getMailDetails(): MailFormValues {
    return this.mailDetails;
  }

  public setMailDetails(formData: MailFormValues): void {
    this.mailDetails = formData;
  }

  public getMailFormValue(): MailFormValues {
    return this.mailDetails;
  }

  /**
   *
   * @param literatureDocument modified featured literature doc
   * @param literatureContentDocument literature documents
   * @returns literature document contents to be displayed
   */
  public getContentLiteratureDocumentsForDisplay(
    literatureDocument: LiteratureDocument[],
    literatureContentDocument: LiteratureContentDocument[]
  ): LiteratureDocument[] {
    const literatureDocumentMap: Map<string, LiteratureDocument> = new Map();
    const literatureContentDocumentsMap: Map<
      string,
      LiteratureContentDocument
    > = new Map();
    const mappedLiteratureDocument = [];
    if (literatureContentDocument) {
      literatureContentDocument.forEach((item) => {
        literatureContentDocumentsMap.set(item.literatureCode, item);
      });
      literatureDocument.forEach((docItem) => {
        if (literatureContentDocumentsMap.has(docItem.docId)) {
          const litContentDoc = literatureContentDocumentsMap.get(
            docItem.docId
          );
          if (!!litContentDoc.title) {
            docItem.dctermsTitle = litContentDoc.title;
          }
          if (!!litContentDoc.description) {
            docItem.dctermsDescription = litContentDoc.description;
          }
          if (!!litContentDoc.url) {
            docItem.href = litContentDoc.url;
          } else {
            docItem.href = `${this.literatureGlobalConfig?.navigationUrls?.litDetailUrl}/${docItem.docId}`;
          }
        }
        literatureDocumentMap.set(docItem.docId, docItem);
      });
      literatureContentDocument.forEach((item) => {
        if (literatureDocumentMap.has(item.literatureCode)) {
          mappedLiteratureDocument.push(
            literatureDocumentMap.get(item.literatureCode)
          );
        }
      });
    }
    return mappedLiteratureDocument;
  }

  public literatureUserProfileMapper(
    profileInfo: IUserProfileInfo
  ): LiteratureRequestBodyProfile {
    return {
      userId: profileInfo.userId,
      role: profileInfo.role,
      webExperience: profileInfo.webExperience,
      displayName: profileInfo.displayName,
      firm: profileInfo.firm,
      addressLine1: profileInfo.addressLine1,
      city: profileInfo.city,
      state: profileInfo.state,
      zip: profileInfo.zip,
      phoneNumber: profileInfo.phoneNumber,
      email: profileInfo.email,
      businessKey: profileInfo.businessKey,
      userSysNo: profileInfo.userSysNo,
      loginName: profileInfo.loginName,
      dealerNumber: profileInfo.dealerNo,
    };
  }

  public getTranslatedDctermType(dctermsType: string): string {
    return dctermsType
      ? this.translateService.instant(`literature.${dctermsType}`)
      : undefined;
  }

  public literatureMapper(data: LiteratureDocumentData): LiteratureDocument {
    return {
      frkOneTISNumber: data.frkOneTISNumber
        ? data.frkOneTISNumber
            .split(',')
            // trim any whitespace off the fund id
            .map((fundId: string): FundId => fundId.trim() as FundId)
        : [],
      // Intialization of unique rowId with docId in which later fundId+shareClassCode+fundUmbrela
      // will be added into this.
      rowId: this.getDocId(data),
      docId: this.getDocId(data),
      href: data.href,
      dctermsTitle: data.dctermsTitle,
      translatedDctermsType: this.getTranslatedDctermType(data.dctermsType),
      dctermsDescription: data.dctermsDescription,
      dctermsAudience: data.dctermsAudience,
      dctermsType: data.dctermsType,
      publicationDate: data.publicationDate,
      // doc considered new if publicationDate less than 30 days old
      isNew: dayjs().isBefore(dayjs(data.fileUploadDate).add(30, 'day'), 'day'),
      frkShareClassCode: data.frkShareClassCode
        ? (data.frkShareClassCode
            .split(',')
            .map((shareClass) => shareClass?.trim()) as ShareClassCode[])
        : [],
      literatureHref: data.literatureHref,
      frkReferenceDate: data.frkReferenceDate
        ? data.frkReferenceDate
        : data.publicationDate,
      frkReferenceYear: data.frkReferenceDate
        ? this.getYear(data.frkReferenceDate)
        : this.getYear(data.publicationDate),
      fileExtension: data.fileExtension
        ? FileExtension[data.fileExtension.split(' ')[0].toLocaleLowerCase()]
        : this.assignExtension(data),
      frkFundGroupCode: data.frkFundGroupCode,
      cmsExternalLink: data.cmsExternalLink,
      nextUpdateDate: data.nextUpdateDate,
      isPublicDoc:
        data?.dctermsAudience?.includes('public') ||
        data?.dctermsAudience?.includes('investor')
          ? 'Yes'
          : 'No',
      clientUse:
        data.isClientUse !== undefined
          ? data.isClientUse
            ? 'Yes'
            : 'No'
          : data.isClientUse,
      topic: data.topic ? data.topic.split(',') : [],
      subTopic: data.subTopic ? data.subTopic : '',
      downloadUrl: data.downloadUrl,
      fundName: data.fundName ? data.fundName : '',
      availableInCart: false,
      thumbnailPath: data.thumbnailPath,
      imageUrl: null,
      finraLink: null,
      assetCategory: data.assetCategory ? data.assetCategory : '',
      isOrdItself: data.isOrdItself,
      isInvestor: data.isInvestor,
      mandatoryMaterial: data.mandatoryMaterial,
      fileSize: data.fileSize,
      description: data.description,
      isInv: data.isInv,
      keywords: data.keywords,
      blueSkyStates: data.blueSkyStates,
      restrictedDealer: data.restrictedDealer,
      isWidenData: data.thumbnailPath?.indexOf('https') > -1,
      litPathPrevious: data.litPathPrevious,
      customFilePath: data.customFilePath,
      orderDate: data.orderDate,
      dctermsLanguage: data.dctermsLanguage || [],
      moreLink: data.moreLink,
      isDealerUse: data?.isDealerUse === 'Y' ? true : false,
      showShareClass: false,
      languageFilterValues: [],
      pdfNumberingAppID: data.pdfNumberingAppID,
      printOnly: data?.printOnly,
      isHistoryData: data?.isHistoryData,
      isSelected: false,
      externalId: data?.externalId,
      documentSourceSystem: data?.dcsourceSystem,
      availableForLoggedInUser: data?.availableForLoggedInUser,
      suppressIndexing: data?.suppressIndexing === 'Yes',
      fileUploadDate: data?.fileUploadDate,
      approvedFirm: data?.approvedFirm,
      firmSpecific: data?.firmSpecific,
      specificFirm: data?.specificFirm,
      specificFirmIDs: data?.specificFirmIDs,
      approvedFirms: data?.approvedFirms,
      applicableFirms: data?.applicableFirms,
      approvedFirmIDs: data?.approvedFirmIDs,
      restrictedFirms: data?.restrictedFirms,
      restrictedFirmIDs: data?.restrictedFirmIDs,
    };
  }

  // NGC-13511 - fileExtension property missing from backend for institutional investor's documents
  // As fileExtension aren't available for INTL site documents, icon mismatch for excel files occured with
  // pdfs. To solve this adding conditions so that it scans download Url from
  // the document and send the available fileExtension. If there are no downloadUrl available then by
  // default PDF icon will be sent.
  /**
   *
   * @param data Literature document data whose fileExtension is missing
   * @returns Appropriate file Extension of data
   */
  public assignExtension(data: LiteratureDocumentData): string {
    const downloadUrl = data.downloadUrl;
    if (downloadUrl) {
      if (downloadUrl.includes('.pdf')) {
        return FileExtension.pdf;
      } else if (
        downloadUrl.includes('.doc') ||
        downloadUrl.includes('.docx')
      ) {
        return FileExtension.doc;
      } else if (downloadUrl.includes('.pptx')) {
        return FileExtension.pptx;
      } else if (downloadUrl.includes('.oft')) {
        return FileExtension.oft;
      } else if (
        downloadUrl.includes('.xls') ||
        downloadUrl.includes('.xlsx') ||
        downloadUrl.includes('.xlsm')
      ) {
        return FileExtension.xls;
      } else {
        return FileExtension.pdf;
      }
    }
    return FileExtension.pdf;
  }

  public getYear(date: string): string {
    if (date) {
      return this.dateFormatter.transform(date, 'MM/dd/yyyy').split('/')[2];
    }
    return '';
  }

  // public getIsPublicDoc(audiences: string[]): string {
  //   if (audiences.includes('public') || audiences.includes('investor')) {
  //     return 'Yes';
  //   }
  //   return 'No';
  // }

  public getCommentaryDownloadDocUrl(doc: FundCommentaryDocument): string {
    let url = '';
    if (this.isInternational) {
      url = `${this.getDownloadUrl('listing')}${doc.customFilePath}`;
    } else {
      url = `${this.getDownloadUrl('literatureUS')}${doc.customFilePath}`;
    }
    return url;
  }

  public getDocId(data: LiteratureDocumentData): string {
    if (this.isInternational) {
      return data.documentId || data.customFilePath;
    }
    return data.customFilePath;
  }

  // TODO: mapper functions should return a new value {object} - not alter existing data
  public mapLiteratureUrl(
    data: LiteratureDocument,
    isRegulatoryLiteratureAudit = false
  ): void {
    if (this.isInternational) {
      if (data.pdfNumberingAppID) {
        data.pdfDownloadLink = `${this.getDownloadUrl('pdfnumbering')}${
          data.literatureHref
        }`;
      } else {
        data.pdfDownloadLink = data.cmsExternalLink
          ? data.cmsExternalLink
          : `${this.getDownloadUrl('listing')}${data.literatureHref}`;
      }
    } else {
      if (data.thumbnailPath) {
        if (data.isWidenData || data.thumbnailPath?.indexOf('https') > -1) {
          data.imageUrl = `${data.thumbnailPath}&w=500&quality=90`;
        } else {
          data.imageUrl = `${this.getDownloadUrl('image')}${
            data.thumbnailPath
          }`;
        }
      }
      data.finraLink = `${this.getDownloadUrl('finra')}${data.docId}`;
      // add for widen non client use data
      data.pdfDownloadLink = `${this.getDownloadUrl(
        'literatureUS',
        isRegulatoryLiteratureAudit
      )}${data.customFilePath}`;
    }
  }

  public isSiteIntl(): boolean {
    return this.isInternational;
  }
  // created a common method which will add audience all literature related
  // API's .By default this will add current segement Id,
  // also overwite Audience  if segmentOverrideList is set from BR.
  public setAudienceParams(
    params: Map<string, string>,
    usePost?: boolean
  ): void {
    // Audience new flow
    const segmentId = this.segmentService.getCurrentSegmentId();
    let audience;
    if (segmentId) {
      // For Post Api call , we need audience in array.
      audience = usePost ? [segmentId] : segmentId;
    }

    // First to check in segmentOverrideList then fundDocumentSegmentOverrideList.
    const segmentOverride =
      this.literatureGlobalConfig
        ?.getLiteratureListingConfig()
        ?.segmentOverrideList?.toString() ||
      this.literatureGlobalConfig?.literatureConfig?.fundDocumentSegmentOverrideList?.toString();
    if (segmentOverride) {
      audience = usePost ? segmentOverride?.split(',') : segmentOverride;
    }
    if (audience) {
      params.set('audience', audience);
    }
  }

  public loadData(
    params: Map<string, string>,
    useCache = false,
    useProfile = false,
    usePOST = false // if true, do POST instead of GET request
  ): Observable<LiteratureData> {
    return this.getLiteratureApiData(params, useCache, useProfile, usePOST);
  }

  public getLiteratureApiData(
    params: Map<string, string>,
    useCache,
    useProfile,
    usePOST // if true, do POST instead of GET request){,
  ): Observable<LiteratureData> {
    if (useProfile) {
      return this.getProfileData$()?.pipe(
        switchMap((profileData) => {
          this.setProfileData(profileData);
          return this.getApiData(useCache, cloneDeep(params), usePOST);
        })
      );
    } else {
      return this.getApiData(useCache, cloneDeep(params), usePOST);
    }
  }

  public getProfileData$(): Observable<IUserProfile> {
    return this.profileService.getUserProfile$()?.pipe(
      filter((profile: IUserProfile): boolean => {
        if (profile.isLoggedIn) {
          return !!profile?.profileInfo?.userId;
        } else {
          return true;
        }
      })
    );
  }

  public isLiteratureAddOnAccount(
    literatureCode: string,
    literatureContentDocuments: LiteratureContentDocument[]
  ): LiteratureContentDocument {
    return literatureContentDocuments?.find(
      (item) => item.literatureCode === literatureCode
    );
  }

  private getUrl(params: Map<string, string>, usePOST = false): string {
    let url: string;

    if (this.appStateService.getUseAPIM(LiteratureFlow.LITERATURE_DATA)) {
      url = this.appStateService.getLiteratureBaseAPIMUrl();
    } else {
      url = this.appStateService.getLiteratureApiUrl();
    }

    if (usePOST) {
      // channel and params are passed in body when POSTing
      return url;
    }

    return (
      url +
      '?channel=' +
      this.appStateService.getChannel() +
      this.getParams(params)
    );
  }

  private getParams(paramsMap: Map<string, string>): string {
    // Audience switch for new and old flow
    this.addActorParams(paramsMap);

    let paramUrl = '';
    if (paramsMap) {
      paramsMap.delete('componentName');
      for (const entry of paramsMap.entries()) {
        paramUrl = paramUrl + '&' + entry[0] + '=' + entry[1];
      }
    }
    return paramUrl;
  }

  private getPostBody(paramsMap: Map<string, any>): object {
    paramsMap.set('channel', this.appStateService.getChannel());
    this.setAudienceParams(paramsMap, true);

    paramsMap.set('cache', 'true');
    return Object.fromEntries(paramsMap);
  }

  private addActorParams(paramsMap: Map<string, string>): void {
    const segmentId = this.segmentService.getCurrentSegmentId();
    if (
      SegmentId.FINANCIAL_PROFESSIONALS === segmentId &&
      this.profileData?.isLoggedIn
    ) {
      paramsMap.set('loggedIn', 'y');
    }
    this.setAudienceParams(paramsMap);
  }

  public getDownloadUrl(
    type: string,
    isRegulatoryLiteratureAudit = false
  ): string {
    if ('finra' === type) {
      return this.appStateService.getLiteratureDownloadFinraUrl();
    } else if ('literatureUS' === type && isRegulatoryLiteratureAudit) {
      return this.appStateService.getLiteratureDownloadPreviewUrl();
    } else if ('literatureUS' === type) {
      return this.appStateService.getLiteratureDownloadUrl();
    } else if ('base' === type) {
      return this.appStateService.getLiteratureBaseApiUrl();
    } else if ('listing' === type) {
      return this.appStateService.getLiteratureDownloadBaseUrl();
    } else if ('preview' === type) {
      return this.appStateService.getLiteratureDownloadPreviewUrl();
    } else if ('image' === type) {
      return this.appStateService.getLiteratureImageUrl();
    } else if ('pdfnumbering' === type) {
      return this.appStateService.getLiteratureDownloadAutoNumberingUrl();
    }
  }

  /**
   * Get literature history
   * @param profileInfo profile data
   * @returns  Literature history
   */
  public getLiteratureHistory(
    profileInfo: IUserProfileInfo
  ): Observable<LiteratureHistoryData> {
    const url = `${this.appStateService.getLiteratureBaseApiUrl()}/literatureHistory`;
    const profile = this.literatureUserProfileMapper(profileInfo);

    return this.literatureApiService.getLiteratureHistory(url, profile);
  }

  /**
   * map LiteratureDocumentData to LiteratureDocumentListingData
   * @param litHistDetail LiteratureDocumentData[]
   * @returns LiteratureDocumentListingData[]
   */
  public mapToLiteratureDocument(
    litHistDetail: LiteratureDocumentData[]
  ): LiteratureDocumentListingData[] {
    const returnData: LiteratureDocumentListingData[] = [];
    for (const litData of litHistDetail) {
      const literatureData = this.literatureMapper(litData);
      this.mapLiteratureUrl(literatureData);
      returnData.push({ literatureData });
    }
    return returnData;
  }

  /**
   * get literature details page link
   * @param url string
   * @returns string
   */
  public getLiteratureDetailLink(doc: LiteratureDocument): void {
    doc.literatureDetailUrl = `${this.literatureGlobalConfig.navigationUrls?.litDetailUrl}/${doc.docId}`;
  }

  /**
   * Delete Literature Document
   * @param profileInfo: IUserProfileInfo
   * @returns response
   */
  public deleteLiteratureDocument(
    profileInfo: IUserProfileInfo,
    litCode: string
  ): Observable<any> {
    const url = `${this.appStateService.getLiteratureBaseApiUrl()}/removeLiterature`;
    const profile = this.literatureUserProfileMapper(profileInfo);
    const remLitDetail = [
      {
        litCode,
        count: 0,
      },
    ];

    const requestHeaders = { ...profile, remLitDetail };
    return this.literatureApiService.deleteLiteratureDocument(
      url,
      requestHeaders
    );
  }

  public prepareDataToPlaceOrder(data: CartDetails[]) {
    if (!this.canOrderPlaced()) {
      return;
    }

    // WDE-3624: Before mapping details for email/download, check if the same is valid or not.
    if (
      !(
        this.profileData?.profileInfo &&
        this.isProfileInfoValid(this.profileData.profileInfo)
      )
    ) {
      return;
    }

    const fullName = this.profileData?.profileInfo.displayName.split(' ');
    const mailBody: MailRequestBody = {
      deliverTo: 'me',
      firstName: fullName[0],
      lastName: fullName[fullName.length - 1],
      zip: this.profileData?.profileInfo.zip,
      city: this.profileData?.profileInfo.city,
      addressLine1: this.profileData?.profileInfo.addressLine1 || NA,
      addressLine2: '',
      emailAddress: this.profileData?.profileInfo.email,
      workPhone: this.profileData?.profileInfo.phoneNumber,
      stateCode: this.profileData?.profileInfo.state,
      cartItems: data,
      userInfo: this.literatureUserProfileMapper(this.profileData.profileInfo),
      mailStateCode: this.profileData?.profileInfo.state,
    };
    this.mailDocument(mailBody, 'download').subscribe(
      (response) => logger.info(response),
      () => logger.info('unable to place order')
    );
  }

  /**
   * WDE-3624: Check mandatory fields in profile info to have valid value or not.
   * @param profileInfo profile information
   * @returns boolean true or false
   */
  private isProfileInfoValid(profileInfo: IUserProfileInfo): boolean {
    const requiredFields: (keyof IUserProfileInfo)[] = [
      'firstName',
      'lastName',
      'addressLine1',
      'zip',
      'city',
      'businessKey',
    ];

    return (
      requiredFields.every((field) => hasValue(profileInfo, field)) &&
      !profileInfo.businessKey.startsWith('PF') // A valid profile shouldn't have business key (express no.) starting with 'PF'.
    );
  }

  /**
   * check order can be placed only when user is Logged-In and role is FA or FP in case of document downloaded or email is sent
   * @returns boolean flag based logged-In user role
   */
  private canOrderPlaced(): boolean {
    return (
      this.profileData?.isLoggedIn &&
      !(this.profileData?.loginSource === LoginSource.BYPASS) &&
      (this.profileData?.profileInfo?.role === FA ||
        this.profileData?.profileInfo?.role === FP)
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * TODO: can this be deleted now?
   * @param paramMap for new flow(widen)- commentary json url, for old-flow(teamsite) - fundId
   * @param useCache defaults to false
   * @param isContentFromTS boolean flag to switch between old and new flow
   * @returns latest fund commentary content
   * As part of NGC-13387, isContentFromTS parameter can be cleaned keeping the new flow
   */
  private getFundCommentaryContent(
    paramMap: any,
    useCache = false
  ): Observable<FundComentaryContent> {
    const url = paramMap;
    if (useCache && this.cachedFundComentaryContentObservables.has(url)) {
      return this.cachedFundComentaryContentObservables.get(url);
    }
    const headers = new HttpHeaders({
      'X-FT-API-KEY': this.appStateService.getFundCommentaryKey(),
      accept: 'application/*',
    });
    const req = PageMonitor.startAjaxRequest(url);
    const FundCommentaryContent$ = this.literatureApiService
      .getFundCommentaryContent(url, headers)
      .pipe(
        tap(() => PageMonitor.endAjaxRequest(req)),
        shareReplay(1)
      );
    if (useCache) {
      this.cachedFundComentaryContentObservables.set(
        url,
        FundCommentaryContent$
      );
      asyncScheduler.schedule(() => {
        this.cachedFundComentaryContentObservables.delete(url);
      }, 10000);
    }
    return FundCommentaryContent$;
  }

  /**
   * TODO: can this be deleted now?
   * @param paramMap fundId
   */
  private getContentUrl(paramMap: Map<string, string>): string {
    return (
      this.appStateService.getCommentaryContentUrl() +
      '?' +
      this.getParams(cloneDeep(paramMap))
    );
  }
}
