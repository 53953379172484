import {
  FundId,
  ShareClassCode,
  Ticker,
  ISIN,
  SEDOL,
  Bloomberg,
  TAID,
  CUSIP,
  ReutersNumber,
} from '@types';

// TODO: should we create more FundIdentifiers for string types below?
export interface Identifiers {
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  ticker?: Ticker;
  isin?: ISIN;
  sedol?: SEDOL;
  bloomberg?: Bloomberg;
  taid?: TAID;
  cusip?: CUSIP;
  reuterSystem?: ReutersNumber;
  countryCode?: string;
  languageCode?: string;
  mexId?: string;
  apir?: string;
  arsn?: string;
  anbimaCode?: string;
  vln?: string;
  intradayNav?: string;
  rfi?: string;
  adm?: string;
  dsc?: string;
  iress?: string;
  iressinavCode?: string;
  fundbmTicker?: string;
  wkn?: string;
  nasdaqxnms?: string;
  iopv?: string;
  frontend?: string;
  lowload?: string;
  mlidentifier?: string;
}

export type IdentifierKey = keyof Identifiers;
