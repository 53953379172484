export interface Charges {
  expenseRatioNet?: string;
  expenseRatioGross?: string;
  cdsc?: string;
  maxIntialSalesCharge?: string;
  annualCharge?: string;
  waiverType?: string;
  waiverEndDate?: string;
  performanceFeeCharge?: string;
  maintananceCharge?: string;
  feeAsOfDate?: string;
  ongoingCharge?: string;
  asOfDate?: string;
  managementFee?: string;
  mer?: string;
}
