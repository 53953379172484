import {
  Component,
  ChangeDetectorRef,
  Renderer2,
  Inject,
  OnInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Card, CardState } from '@frk/eds-components';
import { IUserProfile } from '@services/profile.interface';
import {
  SalesTeamCardLabel,
  SalesTeam,
  CoverageTeam,
  SalesTeamProfileImage,
  ApptUserInfoDto,
} from './sales-team.interface';
import { ProfileService } from '@services/profile.service';
import { ServerCookieService } from '@services/server-cookie.service';
import { EnvConfigService } from '@services/env-config.service';
import { SalesTeamService } from './sales-team.service';
import { Subject } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';
import {
  FP,
  SR_ADVISOR_CONSULTANT,
  ADVISOR_CONSULTANT,
} from '@utils/app.constants';
import cloneDeep from 'lodash/cloneDeep';
import { Logger } from '@utils/logger';

/**
 * Logger
 */
const logger = Logger.getLogger('SalesTeamComponent');
/**
 * FT Sales team component
 *
 * This component is used display sales team card to FP users
 *
 * Selector: `ft-sales-team`
 *
 * Exported as: `SalesTeamComponent`
 */
@Component({
  selector: 'ft-sales-team',
  templateUrl: './sales-team.component.html',
  styleUrls: ['./sales-team.component.scss'],
})
export class SalesTeamComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  /**
   * Get the labels from parent component
   */
  @Input() parentLabels: SalesTeamCardLabel;
  /**
   * Sales team card object data
   */
  salesTeamCard: Card;

  /**
   * Show sales team card
   */
  showSalesTeamCard = false;

  /**
   * Logged in user profile data object;
   */
  profile: IUserProfile;

  /**
   * Sales team member collection member
   */
  salesTeamList: CoverageTeam[] = [];

  /**
   * Online scheduling id for appointment
   */
  onlineSchedulingId: string;

  /**
   * Label for CTA button of appointment booking
   */
  onlineSchedulingCTAText: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  /**
   * Constructor
   */
  constructor(
    private profileService: ProfileService,
    private serverCookieService: ServerCookieService,
    private envConfigService: EnvConfigService,
    private salesTeamService: SalesTeamService,
    private changeDetectorRef: ChangeDetectorRef,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) readonly documentObj: Document
  ) {
    super();
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();

    // Check if labels are availables from parent component
    if (this.parentLabels) {
      this.loadStaticLabels(this.parentLabels);
    } else {
      this.loadStaticLabels(
        this.component?.getModels<SalesTeamCardLabel>()['ft.core.dashboard']
      );
    }

    this.profileService
      .getUserProfile$()
      ?.pipe(takeUntil(this.unsubscribe$))
      .subscribe((profileData: IUserProfile): void => {
        this.profile = profileData;
        if (this.profile.isLoggedIn && this.profile?.profileInfo?.role === FP) {
          this.showSalesTeamCard = true;
        }
        this.getSalesTeamData();
      });
  }

  /**
   * Load static labels from resource bundle to sales team card object
   * @param labelData - Data from resource bundle
   */
  loadStaticLabels(labelData: SalesTeamCardLabel): void {
    if (labelData) {
      this.salesTeamCard = {
        title: labelData.salesCardTitle,
        ctaLink: '',
        ctaText: labelData.salesBookButton,
        body: labelData.salesCardDescription,
        footer: labelData.salesCardFooter,
        state: CardState.Loading,
        warningImgSrc:
          'https://franklintempletonprod.widen.net/content/alpwkbmxpi/original/ft-global-icon-communication-org.svg',
        warningCardBody: labelData.salesWarningCardBody,
        warningBtnLabel: labelData.salesWarningBtnLabel,
      };
      this.onlineSchedulingCTAText = labelData.salesBookButton;
    }
  }

  /**
   * Get sales team data from APIM marketing API
   * along with profile images from bloomrech rest API
   */
  getSalesTeamData(): void {
    this.serverCookieService
      .getOauthToken$()
      .pipe(
        takeUntil(this.unsubscribe$),
        switchMap((authToken: string) => {
          return this.salesTeamService.getSalesTeam$(
            `${this.envConfigService.getEnvConfig().salesTeamListUrl}${
              this.profile.profileInfo.userSysNo
            }`,
            {
              businessKey: this.profile.profileInfo.businessKey,
            },
            authToken
          );
        })
      )
      .subscribe(
        (salesteamResponse: SalesTeam) => {
          const salesTeam: CoverageTeam[] =
            salesteamResponse?.result?.coverageTeam;
          let SACTeamMembers: CoverageTeam[];
          let ACTeamMembers: CoverageTeam[];
          let teamWithSchedulingId: CoverageTeam[];
          if (salesTeam) {
            SACTeamMembers = this.getFilteredTeamMember(
              salesTeam,
              SR_ADVISOR_CONSULTANT
            );

            ACTeamMembers = this.getFilteredTeamMember(
              salesTeam,
              ADVISOR_CONSULTANT
            );

            this.salesTeamList = [...ACTeamMembers, ...SACTeamMembers];

            teamWithSchedulingId = ACTeamMembers.filter(
              (teamMember: CoverageTeam) => {
                return teamMember.onlineSchedulingId !== '';
              }
            );

            if (teamWithSchedulingId?.length) {
              this.onlineSchedulingId =
                teamWithSchedulingId[0].onlineSchedulingId;
              this.salesTeamCard.ctaText = this.onlineSchedulingCTAText;
            } else {
              delete this.salesTeamCard.ctaText;
            }

            this.salesTeamList.forEach((teamMember: CoverageTeam) => {
              this.salesTeamService
                .loadProfileImage$(teamMember.employeeId)
                .pipe(takeUntil(this.unsubscribe$))
                .subscribe((profiles: SalesTeamProfileImage) => {
                  const profileWithImage: any = profiles.items?.find(
                    (profile) => profile.profileImage?.widenAsset
                  );
                  if (profileWithImage) {
                    profileWithImage.profileImage.widenAsset = JSON.parse(
                      profileWithImage.profileImage.widenAsset
                    );
                    teamMember.profileImage =
                      profileWithImage.profileImage.widenAsset.url;
                  }
                  this.changeDetectorRef.detectChanges();
                });
            });
            if (this.salesTeamList.length > 0) {
              this.salesTeamCard.state = CardState.Basic;
            } else {
              delete this.salesTeamCard.ctaText;
              this.salesTeamCard.state = CardState.Empty;
            }
          } else {
            delete this.salesTeamCard.ctaText;
            this.salesTeamCard.state = CardState.Empty;
          }
          this.changeDetectorRef.detectChanges();
        },
        () => {
          delete this.salesTeamCard.ctaText;
          this.salesTeamCard.state = CardState.Empty;
          this.changeDetectorRef.detectChanges();
        }
      );
  }

  /**
   * @param teamMemberList team member array
   * @param role team member role for filter
   * @returns filtered team member array
   */
  getFilteredTeamMember(
    teamMemberList: CoverageTeam[],
    role: string
  ): CoverageTeam[] {
    let tempMemberList: CoverageTeam[];
    let finalMemberList: CoverageTeam[];
    teamMemberList.forEach((teamMember: CoverageTeam) => {
      teamMember.name = `${teamMember.firstName} ${teamMember.lastName}`;
      if (teamMember.role === SR_ADVISOR_CONSULTANT) {
        delete teamMember.telephone;
      }
    });

    tempMemberList = teamMemberList.filter((teammember: CoverageTeam) => {
      return teammember.role === role;
    });

    if (tempMemberList.length < 2) {
      finalMemberList = cloneDeep(tempMemberList);
    } else {
      finalMemberList = tempMemberList.filter((teamMember: CoverageTeam) => {
        return teamMember.primaryTerritory === true;
      });
    }
    return finalMemberList;
  }

  /**
   * Bind btnsubscribe event emitter to create appointment data object
   * and submit to book appointment API.
   */
  bookPhoneConsultation() {
    const request: ApptUserInfoDto = {
      /**
       * resourceId is case sensitive vendor url expects lowercase id which
       * is mapped to "OSTID" in legacy Account Dashboard
       * intWhId is coming in uppercase mapped to "advisorConsultantId"
       */
      resourceId: this.onlineSchedulingId.toLocaleLowerCase(),
      attendee_person_firstName: this.profile?.profileInfo?.displayName,
      attendee_person_lastName: '',
      attendee_phone_phoneNumber: this.profile?.profileInfo?.phoneNumber,
      attendee_email: this.profile?.profileInfo?.email,
    };
    this.postInNewTab(
      request,
      this.envConfigService.getEnvConfig().bookApptBaseUrl
    );
  }

  /**
   * Fires a post request and opens url in a new tab
   * @param obj - request obj
   * @param url - request url
   */
  private postInNewTab(obj: ApptUserInfoDto, url: string) {
    const mapForm: HTMLFormElement = this.renderer2.createElement('form');
    // target _blank to open in a new tab
    this.renderer2.setProperty(mapForm, 'target', '_blank');
    this.renderer2.setProperty(mapForm, 'method', 'POST');
    this.renderer2.setProperty(mapForm, 'action', url);
    Object.keys(obj).forEach((param) => {
      const mapInput = this.renderer2.createElement('input');
      this.renderer2.setProperty(mapInput, 'type', 'hidden');
      this.renderer2.setProperty(mapInput, 'name', param);
      this.renderer2.setAttribute(mapInput, 'value', obj[param]);
      this.renderer2.appendChild(mapForm, mapInput);
    });
    this.renderer2.appendChild(this.documentObj.body, mapForm);
    mapForm.submit();
    // remove the temporary element once form has been submitted
    this.renderer2.removeChild(this.documentObj.body, mapForm);
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
