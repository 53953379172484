import { Component, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { AppStateService } from '@services/app-state.service';
import { Logger } from '@utils/logger';
import { VideoContentBlocks } from '@types';
import { LinkService } from '@services/link.service';
import { ViewModeService } from '@services/view-mode.service';
/**
 * Logger
 */
const logger = Logger.getLogger('VideoTextBlockComponent');

@Component({
  selector: 'ft-video-text-block',
  templateUrl: './video-text-block.component.html',
})
export class VideoTextBlockComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * Video content block document
   */
  content: VideoContentBlocks;

  isSmallContainer: boolean;

  /**
   * Constructor
   */
  constructor(
    private appStateService: AppStateService,
    private linkService: LinkService,
    private viewModeService: ViewModeService
  ) {
    super();
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * Angular init hook
   */
  ngOnInit(): void {
    super.ngOnInit();
    this.content = this.getContent();
    this.isSmallContainer = this.getContainerSize();
  }

  /**
   * Get document content
   * @returns - document content
   */
  getContent(): VideoContentBlocks {
    const content = this.document?.getData<VideoContentBlocks>();
    if (content?.link?.linkCollection?.length) {
      content.link = content.link.linkCollection[0];
      content.link.url = this.linkService.getCTALink(
        this.page,
        content.link,
        this.appStateService?.getHomePageUrl()
      );
      content.externalLinkClass = this.getExternalLink(
        content.link?.external,
        content.link?.externalSuffix
      );
    }
    return content;
  }

  /**
   * Check if small container is required
   * @returns - boolean value for container size
   */
  getContainerSize(): boolean {
    return this.component.getParameters().smallContainer;
  }

  /**
   * Set external link class
   * @param external - is link external true|false
   * @param externalSuffix - suffix to add to external link
   */
  private getExternalLink(external: boolean, externalSuffix: string): string {
    if (external) {
      const externalLink = 'external-link';
      if (externalSuffix) {
        // Adding external link suffix
        return externalLink + '--' + externalSuffix;
      }
      return externalLink;
    }
  }
}
