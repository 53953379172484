import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { AnalyticsService, ResponsiveService } from '@frk/eds-components';
import { EMAIL_PATTERN } from '@literature/const/literature-data-const';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import { LiteratureGlobalConfigService } from '@literature/services/literature-global-config.service';
import { SiteConfigService } from '@services';
import { TranslateService } from '@shared/translate/translate.service';
import { LiteratureDocument, SelectNotifications } from '@types';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';

@Component({
  selector: 'ft-literature-dialog',
  templateUrl: './literature-dialog.component.html',
  styleUrls: ['./literature-dialog.component.scss'],
})
export class LiteratureDialogComponent implements OnInit, OnChanges {
  @Input() gridData: LiteratureDocument[] = [];
  @Input() dialogId: string;
  @Input() multiSelectDialogData = [];
  @Input() modalIdEnum: any;
  @Input() dialogMandatoryMaterials: LiteratureDocument[];
  @Input() dynamicBodyTextToAppend: string;
  @Input() confirmSelectionCount: number;
  @Output() dialog = new EventEmitter<string>();
  @Output() deleteDoc = new EventEmitter<string>();
  @Output() deleteCartDoc = new EventEmitter<string>();
  @Output() downloadStart = new EventEmitter();
  @Output() emailStart = new EventEmitter();
  @Output() modalClose = new EventEmitter();
  @Output() backEmail = new EventEmitter();
  @Output() continueEmail = new EventEmitter<any>();
  @Output() emptyCart = new EventEmitter<string>();
  @Output() removeMandatoryItems = new EventEmitter();
  @Output() modalClosedInternally = new EventEmitter<string>();
  @Output() selectAll = new EventEmitter<SelectNotifications>();

  isDisabled = false;
  isMandatoryAvailable = false;
  recipientArrayForm: FormGroup;
  mandatoryMaterialMap: Map<string, LiteratureDocument>;
  clientNotApprovedMsg: string;
  hardCopyNotAvailableTitle: string;
  mandatoryMaterialMsg: string;
  isHandheld$: Observable<boolean>;
  recipients = new FormArray([
    new FormGroup({
      recipientEmailId: new FormControl('', [
        Validators.required,
        Validators.pattern(EMAIL_PATTERN),
      ]),
    }),
  ]);
  modalHeaderCommonTableSubTitleExp: boolean;
  modalBodyCommonTableExp: boolean;
  modalBodyCommonTextExp: boolean;
  modalHeaderCommonTableSubTitle;
  isSiteIntl: boolean;
  modalBodyCommonTableData;
  modalBodyCommonTextData;
  modalFooterCommonData;
  size;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private literatureConfig: LiteratureGlobalConfigService,
    private translateService: TranslateService,
    private cartService: CartHandlerService,
    private responsiveService: ResponsiveService,
    protected analyticsService: AnalyticsService,
    private spinner: NgxSpinnerService,
    private siteConfigService: SiteConfigService
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.prepareLabels();
    this.checkMandatoryDocAvailablity();
    this.assignModalData();
    if (
      this.dialogId === 'selectionAllModal' ||
      this.dialogId === 'maxSelectionLimit'
    ) {
      this.size = 'small';
    }
    if (this.dialogId === 'cartModal') {
      this.spinner.show('cart-spinner');
    }
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {
    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.recipientArrayForm = new FormGroup({
      recipients: this.recipients,
    });

    this.isSiteIntl = this.siteConfigService.isSiteInternational();
  }

  private trackCartCompleteEvent(cartType: string): void {
    const litCodes = [];
    const fundNames = [];

    this.multiSelectDialogData?.forEach((data) => {
      litCodes.push(data.rowId);
      fundNames.push(data.name);
    });

    this.analyticsService.trackEvent({
      event: 'cart_complete',
      detailed_event: 'Cart Complete',
      event_data: {
        cart_type: cartType,
        file_lit_code: litCodes.join('|'),
        file_name: fundNames.join('|'),
      },
    });
  }

  emailContinue = () => {
    this.continueEmail.emit(this.recipientArrayForm.value);
    this.recipientArrayForm.reset();
    this.recipients.clear();
  };

  clearCart(event: MouseEvent): void {
    event.preventDefault();
    this.emptyCart.emit(this.dialogId);
    this.closeModal();
  }

  addRecipients() {
    if (this.getFormArray().length < 4) {
      this.getFormArray().push(
        new FormGroup({
          recipientEmailId: new FormControl('', [
            Validators.required,
            Validators.pattern(EMAIL_PATTERN),
          ]),
        })
      );
    }
    // disable add email recipient button
    if (this.getFormArray().length === 4) {
      this.isDisabled = true;
    }
  }

  deleteRecipient(event: MouseEvent, i: number) {
    event.preventDefault();
    this.getFormArray().removeAt(i);
    // remove disabled attr when user starts removing recepients
    this.isDisabled = false;
  }

  track(item: FormControl, index: number) {
    return index;
  }

  getFormArray(): FormArray {
    return this.recipientArrayForm.get('recipients') as FormArray;
  }

  translateKey(key: string): string {
    return this.translateService.instant(key);
  }

  validatorFunction(requiredFlag: boolean, formFieldName: string) {
    if (requiredFlag) {
      return this.translateKey(
        `literature.email-form-error-${formFieldName}-required`
      );
    } else {
      // for other validations except required check
      return this.translateKey(
        `literature.email-form-error-${formFieldName}-data`
      );
    }
  }

  checkMandatoryDocAvailablity(): void {
    this.isMandatoryAvailable = false;
    for (const data of this.gridData) {
      if (data.isMandatoryMaterial) {
        this.isMandatoryAvailable = true;
        break;
      }
    }
  }
  deleteCartDocument = (rowId: string, fundName: string, event: MouseEvent) => {
    event.preventDefault();
    this.deleteCartDoc.emit(rowId);
    this.checkMandatoryDocAvailablity();
    if (this.gridData?.length === 0) {
      this.closeModal();
    }
    this.analyticsService.trackEvent({
      event: 'cart_engagement',
      action: 'remove from cart',
      file_name: fundName,
      file_lit_code: rowId,
    });

    // GA4 new analytic event
    this.analyticsService.trackEvent({
      event: 'cart_remove',
      detailed_event: 'Cart Remove',
      event_data: {
        cart_type: 'Add To Cart',
        file_lit_code: rowId,
        file_name: fundName,
      },
    });

    this.changeDetectorRef.detectChanges();
  };

  startDownload = () => {
    this.trackCartCompleteEvent('Download');
    this.downloadStart.emit();
  };

  closeModal = () => {
    this.modalClose.emit();
  };

  handleEdsClose(event: string) {
    this.modalClosedInternally.emit(event);
  }

  startEmail = () => {
    this.trackCartCompleteEvent('Email');
    this.emailStart.emit();
  };

  emailBack = () => {
    this.backEmail.emit();
  };

  emptyCartAction = () => {
    this.emptyCart.emit(this.dialogId);
  };

  removeMandatoryMaterial = () => {
    this.removeMandatoryItems.emit();
  };

  confirmSelectionClick = () => {
    this.selectAll.emit(SelectNotifications.CURRENT);
  };

  selectAllClick = () => {
    this.selectAll.emit(SelectNotifications.ALL);
  };

  deselectedDownloadDocs = (
    modalId: string,
    rowId: string,
    fundName: string,
    event: MouseEvent
  ) => {
    event.preventDefault();
    this.analyticsService.trackEvent({
      event: 'cart_remove',
      detailed_event: 'Cart Remove',
      event_data: {
        cart_type: modalId === 'downloadModal' ? 'Download' : 'Email',
        file_lit_code: rowId,
        file_name: fundName,
      },
    });
    this.deleteDoc.emit(rowId);
  };

  close = () => {
    this.dialog.emit(this.modalIdEnum);
  };

  goToCart = () => {
    this.dialog.emit(this.modalIdEnum);
    this.literatureConfig.navigateToUrl(
      this.literatureConfig.navigationUrls?.litOrderCheckoutUrl
    );
  };
  assignModalData(): void {
    const commonTableSubTitleModals = [
      'recipientsDetailModal',
      'emailModal',
      'downloadModal',
      'cartModal',
    ];
    this.modalHeaderCommonTableSubTitleExp = commonTableSubTitleModals.includes(
      this.dialogId
    );

    const modalCommonTableBody = ['emailModal', 'downloadModal', 'cartModal'];
    this.modalBodyCommonTableExp = modalCommonTableBody.includes(this.dialogId);

    const modalCommonTableBodyText = [
      'downloadProgressModal',
      'successModal',
      'emailFailureModal',
      'downloadFailureModal',
      'emailSentModal',
      'successEmailModal',
      'maxCartLimit',
      'emptyCartDailog',
      'emptyCart',
      'cancelOrder',
    ];
    this.modalBodyCommonTextExp = modalCommonTableBodyText.includes(
      this.dialogId
    );
    this.modalHeaderCommonTableSubTitle = {
      textData: {
        labels: {
          cartModal: 'lit-cart-modal-subtitle',
          downloadModal: 'lit-sticky-download-modal-subtitle',
          emailModal: 'lit-sticky-mail-modal-subtitle',
          recipientsDetailModal: 'lit-sticky-mail-progress-modal-subtitle',
        },
      },
    };
    this.modalBodyCommonTableData = {
      tableData: {
        cartModal: {
          modalId: 'cartModal',
          labels: {
            subtitle: 'lit-cart-modal-subtitle',
            nameHeader: 'lit-listing-column-name',
            actionHeader: 'lit-listing-column-action',
            linkLabel: 'lit-sticky-delete-button',
          },
          gridData: this.checkDocumentRules(this.gridData),
          clickHandler: this.deleteCartDocument,
        },
        downloadModal: {
          modalId: 'downloadModal',
          labels: {
            subtitle: 'lit-sticky-download-modal-subtitle',
            nameHeader: 'lit-listing-column-name',
            actionHeader: 'lit-listing-column-action',
            linkLabel: 'lit-sticky-delete-button',
          },
          gridData: this.multiSelectDialogData,
          clickHandler: this.deselectedDownloadDocs,
        },
        emailModal: {
          modalId: 'emailModal',
          labels: {
            subtitle: 'lit-sticky-mail-modal-subtitle',
            nameHeader: 'lit-listing-column-name',
            actionHeader: 'lit-listing-column-action',
            linkLabel: 'lit-sticky-delete-button',
          },
          gridData: this.multiSelectDialogData,
          clickHandler: this.deselectedDownloadDocs,
        },
      },
    };

    this.modalBodyCommonTextData = {
      textData: {
        labels: {
          downloadProgressModal: 'lit-sticky-download-progress-message',
          successModal: 'lit-sticky-download-success-message',
          emailFailureModal: 'lit-sticky-email-failure-message',
          downloadFailureModal: 'lit-sticky-download-failure-message',
          emailSentModal: 'email-success-message',
          successEmailModal: 'lit-sticky-mail-success-message',
          maxCartLimit: 'lit-sticky-max-cart-limit-message',
          emptyCartDailog: 'your-order-removed',
          emptyCart: 'mail-confirm-modal-description',
          cancelOrder: 'mail-confirm-modal-cancel-order-description',
        },
      },
    };

    this.modalFooterCommonData = {
      footerData: {
        emptyCart: {
          justifyContent: 'space-between',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.emptyCartAction,
              label: 'mail-cancel-order-button',
            },
            {
              edsButtonType: 'secondary',
              size: 'compact',
              clickhandler: this.close,
              label: 'mail-return-checkout',
            },
          ],
        },
        cancelOrder: {
          justifyContent: 'space-between',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.emptyCartAction,
              label: 'mail-cancel-order-button',
            },
            {
              edsButtonType: 'secondary',
              size: 'compact',
              clickhandler: this.close,
              label: 'mail-return-checkout',
            },
          ],
        },
        mandatoryDailog: {
          justifyContent: 'space-between',
          buttonData: [
            {
              edsButtonType: 'secondary',
              size: 'normal',
              clickhandler: this.close,
              label: 'cancel',
            },
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.removeMandatoryMaterial,
              label: 'remove-item',
            },
          ],
        },
        emptyCartDailog: {
          justifyContent: 'space-between',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'compact',
              clickhandler: this.emptyCartAction,
              label: 'cancel-your-order',
            },
            {
              edsButtonType: 'secondary',
              size: 'compact',
              clickhandler: this.close,
              label: 'return-to-checkout',
            },
          ],
        },
        cartModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'secondary',
              size: 'normal',
              clickhandler: this.close,
              label: 'lit-continue-browse',
            },
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.goToCart,
              label: 'lit-view-cart',
            },
          ],
        },
        downloadModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.startDownload,
              label: 'lit-sticky-download-button',
            },
          ],
        },
        emailModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.startEmail,
              label: 'lit-sticky-add-recipient-button',
            },
          ],
        },
        successModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        emailFailureModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        downloadFailureModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        emailSentModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        successEmailModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        maxCartLimit: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
        recipientsDetailModal: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'secondary',
              size: 'normal',
              clickhandler: this.emailBack,
              label: 'lit-sticky-back-button',
            },
            {
              edsButtonType: 'primary',
              size: 'normal',
              clickhandler: this.emailContinue,
              label: 'lit-sticky-continue-button',
              checkForDisable: true,
            },
          ],
        },
        selectionAllModal: {
          justifyContent: 'flex-start',
          buttonData: [
            {
              edsButtonType: 'secondary',
              size: 'compact',
              clickhandler: this.confirmSelectionClick,
              label: 'lit-confirm-selection-button',
              secondLabel: `( ${
                this.confirmSelectionCount
              } ${this.translateService.instant(
                'literature.lit-items-label'
              )} )`,
            },
            {
              edsButtonType: 'primary',
              size: 'compact',
              clickhandler: this.selectAllClick,
              label: 'lit--select-all-button',
            },
          ],
        },
        maxSelectionLimit: {
          justifyContent: 'flex-end',
          buttonData: [
            {
              edsButtonType: 'primary',
              size: 'small',
              clickhandler: this.closeModal,
              label: 'lit-sticky-close-button',
            },
          ],
        },
      },
    };
  }

  prepareLabels() {
    this.clientNotApprovedMsg = this.translateService.instant(
      `literature.client-not-approved`
    );
    this.hardCopyNotAvailableTitle = this.translateService.instant(
      `literature.cart-hardcopy-alert-title`
    );
    this.mandatoryMaterialMsg = `${this.translateService.instant(
      `literature.cart-mandatory-alert-detail-msg`
    )}`;
  }

  checkDocumentRules(gridData: LiteratureDocument[]): LiteratureDocument[] {
    this.mandatoryMaterialMap = new Map();
    for (const data of gridData) {
      // prepare mandatory material mapping with its parent documents
      if (data.isMandatoryMaterial) {
        data.mandatoryMaterial.forEach((docId: string) => {
          this.mandatoryMaterialMap.set(docId, data);
        });
      }
    }
    for (const entry of this.mandatoryMaterialMap.entries()) {
      this.gridData.forEach((data: LiteratureDocument) => {
        if (entry[0] === data.docId) {
          data.mandatoryMaterialPrimary = entry[1].fundName;
        }
      });
    }
    return gridData;
  }
}
