// TODO: Change to EDS icons - most of them are BW in v.0.0.35. Instagram icon missing
// /assets/icons/ft-global-icon-facebook.svg
// /assets/icons/ft-global-icon-linkedin.svg
// /assets/icons/ft-global-icon-youtube.svg
// /assets/icons/ft-global-icon-twitter.svg
// /assets/icons/ft-global-icon-instagram.svg
/**
 * Social media icons configuration
 */
export enum SocialMediaIcons {
  FACEBOOK = '/assets/icons/fb-icon-16.png',
  LINKEDIN = '/assets/icons/linkedin-16.png',
  YOUTUBE = '/assets/icons/youtube-16.png',
  TWITTER = '/assets/icons/twitter-16.png',
  INSTAGRAM = '/assets/icons/instagram-16.png',
  WECHAT = '/assets/icons/wechat-icon-16.png',
  DEFAULT = '/assets/icons/ft-global-icon-chat.svg',
}

/**
 * Returns social media icon base on menu name
 * @param name - name from menu item
 */
export function getSocialImg(name: string) {
  const smName = name.toUpperCase();
  if (smName.includes('FACEBOOK')) {
    return SocialMediaIcons.FACEBOOK;
  }
  if (smName.includes('LINKEDIN')) {
    return SocialMediaIcons.LINKEDIN;
  }
  if (smName.includes('YOUTUBE')) {
    return SocialMediaIcons.YOUTUBE;
  }
  if (smName.includes('TWITTER')) {
    return SocialMediaIcons.TWITTER;
  }
  if (smName.includes('INSTAGRAM')) {
    return SocialMediaIcons.INSTAGRAM;
  }
  if (smName.includes('WECHAT')) {
    return SocialMediaIcons.WECHAT;
  }
  return SocialMediaIcons.DEFAULT;
}
