import { Component } from '@angular/core';
import { ViewModeService } from '@services/view-mode.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'Franklin Templeton';
  isEditMode = false;

  constructor(private viewModeService: ViewModeService) {
    this.isEditMode = this.viewModeService.isEditMode();
  }
}
