import { YieldsDTO } from '@types';
import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from './mapper-factory';
import { Yields } from '@models';

export class YieldsMapper extends Mapper<Yields> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(yieldsDto: YieldsDTO): Yields {
    return {
      secYieldThrtyDay: yieldsDto.stdsecyield30day,
      secYieldThrtyDayWoWaiver: yieldsDto.stdsecyield30daywowaiver,
      stdYieldThirtyDayAsofDate: yieldsDto.stdyieldasofdate30day,
      currentYield7Day: yieldsDto.curryield7day,
      currentYield7DayWithoutWaiver: yieldsDto.curryield7daywowaiver,
      effectiveYield7Day: yieldsDto.effctvyield7day,
      effectiveYield7DayWithoutWaiver: yieldsDto.effctvyield7daywowaiver,
      taxableEquivalentYield: yieldsDto.sectaxequyieldall,
      taxableEquivalentYieldWithoutWaiver: yieldsDto.taxequivyieldwowaiver,
      dividendYield: yieldsDto.dividendyield,
      asOfDate: yieldsDto.asofdate,
    };
  }
}
