<!-- New create/edit button -->
<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="new-Code-document"
    folderTemplateQuery="new-Code-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>

  <div [ngClass]="{ 'has-edit-button': isEditMode }">
    <div *ngIf="document && content$ | async" edsGrid>
      <div [class]="container">
        <div edsRow>
          <div edsCol [innerHTML]="content$ | async"></div>
        </div>
      </div>
    </div>

    <div
      *ngIf="!document && isEditMode"
      edsGrid
      class="no-document no-document__component"
    >
      <div class="no-document__overlay">
        Click to configure <strong>Code Component</strong>
      </div>
    </div>
  </div>
</div>
