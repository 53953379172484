import { SpacingModule } from '@frk/eds-components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InteractiveContentBlockComponent } from './interactive-content-block.component';

@NgModule({
  declarations: [InteractiveContentBlockComponent],
  imports: [[CommonModule, SpacingModule]],
  exports: [InteractiveContentBlockComponent],
  entryComponents: [InteractiveContentBlockComponent],
})

/**
 * Interactive Content Block Module
 */
export class InteractiveContentBlockModule {}
