import { HttpRequest } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { PdsRequestBody } from '@services';
import { getParamFromUrl } from '@utils/link-utils';
import { Logger } from '@utils/logger';
import { merge, Observable, ReplaySubject, Subject } from 'rxjs';
import { filter, mapTo, scan, takeUntil, tap } from 'rxjs/operators';

const logger = Logger.getLogger('ProductDetailLoadingService');

export interface ProductDataRequest {
  // unique id to match request
  id: string;
  // request url
  url: string;
  // true if request has finished, either success or error
  isComplete: boolean;
  // gql operation name
  operationName?: string;
}

export interface ProductSectionStatus {
  sectionId: string;
  operations?: string[];
  // array of gql requests related to this section
  requests: ProductDataRequest[];
  // true if there are requests, and not all are complete
  isLoading: boolean;
  // when true section is ready to be scrolled to (deep linked)
  // requires this and previous sections to not be loading
  // for most sections, also requires at least one request
  isSectionReady: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class ProductDetailLoadingService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  // stream that fires when a PDS request starts
  private pdsRequestStart$: Subject<ProductDataRequest> = new Subject<ProductDataRequest>();

  // stream that fires when a PDS request completes
  private pdsRequestComplete$: Subject<ProductDataRequest> = new Subject<ProductDataRequest>();

  // stream fires when section loading status is reset
  private reset$: Subject<void> = new Subject<void>();

  // stream of section loading status updates
  private sectionsStatus$: ReplaySubject<ProductSectionStatus[]>;

  constructor() {
    // this.pdsRequestStart$.subscribe((request: ProductDataRequest) =>
    //   logger.debug('pdsRequestStart$()', request)
    // );
    // this.pdsRequestComplete$.subscribe((request: ProductDataRequest) =>
    //   logger.debug('pdsRequestComplete$()', request)
    // );

    // NB: consider piping delay to pdsRequestComplete$ stream if running too fast
    this.sectionsStatus$ = new ReplaySubject<ProductSectionStatus[]>(1);
    merge(this.pdsRequestStart$, this.pdsRequestComplete$, this.reset$)
      .pipe(
        takeUntil(this.unsubscribe$),
        scan(this.updateStatus, this.getDefaultStatus())
      )
      .subscribe(this.sectionsStatus$);

    // subscription for logging only
    this.sectionsStatus$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((status: ProductSectionStatus[]) => {
        logger.debug('sectionsStatus$', status);
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public isSectionReady$(sectionId: string): Observable<boolean> {
    logger.debug('isSectionReady$()', sectionId);
    return this.sectionsStatus$.pipe(
      tap((sections): void => {
        logger.debug('isSectionReady$() filter', sectionId, sections);
      }),
      filter(
        (sections: ProductSectionStatus[]): boolean =>
          sections.find(
            (section: ProductSectionStatus): boolean =>
              section.sectionId === sectionId
          )?.isSectionReady
      ),
      mapTo(true),
      tap((): void => {
        logger.debug('isSectionReady$() ready', sectionId);
      })
    );
  }

  public addRequest(req: HttpRequest<PdsRequestBody>) {
    logger.debug('addRequest', req);
    this.pdsRequestStart$.next({
      id: getParamFromUrl(req.url, 'id'),
      url: req.url,
      isComplete: false,
      operationName: req.body?.operationName,
    });

    // log if request didn't match any operations at all
    // this will help find any new requests we should be monitoring
    if (!this.isOperationFound(req.body?.operationName)) {
      logger.debug('operation not found', req.body?.operationName);
    }
  }

  public completeRequest(req: HttpRequest<PdsRequestBody>) {
    logger.debug('completeRequest', req);
    this.pdsRequestComplete$.next({
      id: getParamFromUrl(req.url, 'id'),
      url: req.url,
      isComplete: true,
      operationName: req.body?.operationName,
    });
  }

  // call when new product page
  public resetSections(): void {
    this.reset$.next();
  }

  private updateStatus = (
    prevState: ProductSectionStatus[],
    request: ProductDataRequest
  ): ProductSectionStatus[] => {
    logger.debug('updateStatus', prevState, request);

    if (!request) {
      // if no request, this must be a reset action
      // return the original status
      return this.getDefaultStatus();
    }

    if (!request.isComplete) {
      // return updated state for request start
      return prevState.map(this.mapStartRequest(request));
    }

    // return updated state for request complete
    // first map() updates requests and isLoading prop for each section
    // second map() calculates isSectionReady for each section based on new state
    // NB: needs to be done in two steps, so looking at updated data
    return prevState
      .map(this.mapCompleteRequest(request))
      .map(this.mapSectionReady);
  };

  private mapStartRequest = (request: ProductDataRequest) => (
    section: ProductSectionStatus
  ): ProductSectionStatus => {
    // updates section immutably

    const requests: ProductDataRequest[] = [...section.requests];
    if (section.operations?.includes(request.operationName)) {
      // this section matches request, so add the request
      requests.push(request);
    }
    const isLoading: boolean = requests.some(
      (r: ProductDataRequest): boolean => !r.isComplete
    );
    return {
      ...section,
      isLoading,
      requests,
    };
  };

  // updates the requests and isLoading prop for section based on incoming complete request
  private mapCompleteRequest = (request: ProductDataRequest) => (
    section: ProductSectionStatus
  ): ProductSectionStatus => {
    // updates section immutably

    // update section's requests
    const requests: ProductDataRequest[] = section.requests.map(
      this.mapSectionRequest(request)
    );

    // section is loading if it has unfinished requests
    const isLoading: boolean = requests.some(
      (r: ProductDataRequest): boolean => !r.isComplete
    );

    return {
      ...section,
      isLoading,
      requests,
    };
  };

  // this updates the isSectionReady prop of the section
  private mapSectionReady = (
    section: ProductSectionStatus,
    outerIndex: number,
    prevState: ProductSectionStatus[]
  ): ProductSectionStatus => {
    // updates section immutably

    const hasPreviousLoadingSections: boolean = this.hasPreviousLoadingSections(
      prevState,
      outerIndex
    );

    const requestsAreComplete: boolean = this.areSectionRequestsComplete(
      section
    );

    // section is ready if it all it's requests are complete
    // AND none of the previous sections are currently loading
    const isSectionReady: boolean =
      !hasPreviousLoadingSections && requestsAreComplete;

    return {
      ...section,
      isSectionReady,
    };
  };

  // updates request's isComplete property if it matches incoming request
  private mapSectionRequest = (newRequest: ProductDataRequest) => (
    existingRequest: ProductDataRequest
  ): ProductDataRequest => {
    let isComplete: boolean = existingRequest.isComplete;
    if (
      existingRequest.operationName === newRequest.operationName &&
      existingRequest.id === newRequest.id
    ) {
      // mark request as complete if it matches the operation name and id
      isComplete = true;
    }
    return {
      ...existingRequest,
      isComplete,
    };
  };

  // returns true if one or more sections above this one on the page are still loading
  private hasPreviousLoadingSections = (
    prevState: ProductSectionStatus[],
    thisSectionIndex: number
  ): boolean =>
    prevState.some(
      (s: ProductSectionStatus, innerIndex: number): boolean =>
        s.isLoading && innerIndex <= thisSectionIndex
    );

  // returns true if all section's requests are complete
  // if section has no operations, returns true (as nothing to load)
  // otherwise, need at least one request registered
  private areSectionRequestsComplete = (
    section: ProductSectionStatus
  ): boolean => {
    if (!section.operations || section.operations.length === 0) {
      // section doesn't have any associated data operations
      return true;
    }

    if (section.requests && section.requests.length > 0) {
      // section has requests started. Determine if all are complete
      return section.requests.every(
        (r: ProductDataRequest): boolean => r.isComplete
      );
    }

    // othewise return false, as no requests started yet
    return false;
  };

  // returns true if operation belongs to one of the defined sections
  private isOperationFound = (operationName: string): boolean => {
    const sections: ProductSectionStatus[] = this.getDefaultStatus();
    return !!sections.find((section: ProductSectionStatus): boolean =>
      section.operations?.includes(operationName)
    );
  };

  // this is the default initial sections status
  private getDefaultStatus = (): ProductSectionStatus[] => {
    // WARNING: section order must match order in template
    const defaultStatus: ProductSectionStatus[] = [
      {
        // header isn't a real sticky tab section
        // instead it's used to monitor general data requests
        // and make subsequent sections wait if they're loading
        sectionId: 'header',
        operations: ['Labels', 'ProductDetail', 'FundTitle', 'CaveatMapping'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'overview',
        operations: [
          'AnalizyRating',
          'FundContent',
          'FundFavorite',
          'FundHeaderOverview',
          'FundManagement',
          'FundOverviewSummary',
          'TradingInfo',
        ],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'performance',
        operations: [
          'AnnualizedSnapshot',
          'Annualized',
          'CalendarYear',
          'CumulativeTimeSeries',
          'CumulativeSnapshot',
          'Cumulative',
          'CustomCumulativeTimeSeries',
          'Discrete',
          'RiskReturnOverview',
          'RiskReturnProfile',
          'RiskStatsSummary',
        ],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        // attribution will only load pds data if logged in
        sectionId: 'attribution',
        operations: ['Attribution'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'portfolio',
        operations: [
          'AssetAllocation',
          'CouponAllocation',
          'CurrencyAllocation',
          'FiAllocation',
          'GeographicAllocation',
          'HedgeAllocation',
          'Holdings',
          'ImpactPillar',
          'ManagerAllocation',
          'MarketCapAllocation',
          'QualityAllocation',
          'RiskReturnAnalysis',
          'SectorAllocation',
          'SecurityExposure',
          'PortfolioStatistics',
          'TopExposure',
          'TopHoldings',
          'TopTenHoldings',
          'Impactfocus',
        ],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'distributions',
        operations: ['Distribution', 'DistributionHistory', 'UsTax'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'pricing',
        operations: ['PricesHistory', 'Pricing', 'PremiumDiscount'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        // no pds data to load
        sectionId: 'commentary',
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        // no pds data to load
        sectionId: 'press-releases',
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'documents',
        operations: ['FundDocuments', 'FundDocumentsAll'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        // no pds data to load
        sectionId: 'marketing-content',
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
      {
        sectionId: 'morningstar-rated-funds',
        operations: ['SimilarFundListing'],
        isLoading: false,
        isSectionReady: false,
        requests: [],
      },
    ];
    logger.debug('getDefaultStatus()', defaultStatus);
    return defaultStatus;
  };
}
