<div class="doc-viewer-modal">
  <eds-simple-modal
    [modalId]="'document-loader'"
    (onClose)="clearDownloadLocation()"
    size="large"
    attr.aria-label="{{ 'common.close' | translate }}"
    role="dialog"
  >
    <div modal-body>
      <ng-container *ngIf="isDocument; else loadUrlContent">
        <ngx-doc-viewer
          [url]="url"
          [viewer]="viewer"
          [disableContent]="disableContent"
          [edsSpacing]="'lg'"
          class="doc-viewer-modal__doc-viewer"
        ></ngx-doc-viewer>
      </ng-container>
      <ng-template #loadUrlContent>
        <p>
          {{ "common.docViewer.loadUrlCopy" | translate }}
        </p>
      </ng-template>
    </div>
    <div modal-footer>
      <div class="text-align-right">
        <a
          [edsButton]="'primary'"
          [size]="'compact'"
          [href]="url"
          [noMargin]="true"
          (click)="downloadAndClose()"
          target="_blank"
          >{{ "common.docViewer.downloadButtonLabel" | translate }}</a
        >
      </div>
    </div>
  </eds-simple-modal>
</div>
