import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ExactToggleComponent } from './search-results/exact-toggle/exact-toggle.component';
import { SearchFiltersComponent } from './search-results/search-filters/search-filters.component';
import { SearchResultsComponent } from './search-results/search-results.component';
import { SearchTabsComponent } from './search-results/search-tabs/search-tabs.component';
import { SearchComponent } from './search.component';

import { ExactMatchComponent } from './search-results/exact-match/exact-match.component';
import { FundDetailsComponent } from './search-results/exact-match/sections/fund-details/fund-details.component';
import { FundManagerComponent } from './search-results/exact-match/sections/fund-manager/fund-manager.component';
import { GeneralComponent } from './search-results/exact-match/sections/general/general.component';
import { StatusComponent } from './search-results/exact-match/sections/status/status.component';

import { FullResultsComponent } from './search-results/full-results/full-results.component';
import { FullResultsAllComponent } from './search-results/full-results/sections/full-results-all/full-results-all.component';
import { FullResultsFundsComponent } from './search-results/full-results/sections/full-results-funds/full-results-funds.component';
import { FullResultsGeneralComponent } from './search-results/full-results/sections/full-results-general/full-results-general.component';
import { FullResultsLiteratureComponent } from './search-results/full-results/sections/full-results-literature/full-results-literature.component';

import { LiteratureDialogModule } from '@literature/literature-dialog/literature-dialog.module';

import { TranslateModule } from '@shared/translate/translate.module';
import { TranslatePipe } from '@shared/translate/translate.pipe';

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { NgxSpinnerModule } from 'ngx-spinner';

import { HighchartsChartModule } from 'highcharts-angular';

/* EDS Components */
import {
  AvatarModule,
  BannerModule,
  ButtonModule,
  DropdownModule,
  ErrorMessageModule,
  ExpandCollapseModule,
  FlexboxModule,
  GridModule,
  IconModule,
  ImageModule,
  InfoItemModule,
  LinkItemModule,
  PaddingModule,
  PanelModule,
  SearchBarModule,
  SearchPanelModule,
  SimpleModalModule,
  SpacingModule,
  SummaryItemModule,
  SwitchModule,
  ThumbnailModule,
  TitleModule,
  ToggleModule,
  ToggleV2Module,
  TrapFocusModule,
} from '@frk/eds-components';
import { EmptySearchComponent } from './search-results/empty-search/empty-search.component';
import { PipesModule } from '@shared/pipes/pipes.module';

@NgModule({
  declarations: [
    ExactMatchComponent,
    ExactToggleComponent,
    FundDetailsComponent,
    FundManagerComponent,
    FullResultsAllComponent,
    FullResultsComponent,
    FullResultsFundsComponent,
    FullResultsGeneralComponent,
    FullResultsLiteratureComponent,
    GeneralComponent,
    SearchResultsComponent,
    SearchComponent,
    SearchFiltersComponent,
    SearchTabsComponent,
    StatusComponent,
    EmptySearchComponent,
  ],
  imports: [
    LiteratureDialogModule,
    AvatarModule,
    BannerModule,
    ButtonModule,
    CommonModule,
    DropdownModule,
    ErrorMessageModule,
    ExpandCollapseModule,
    FlexboxModule,
    GridModule,
    HighchartsChartModule,
    IconModule,
    ImageModule,
    InfoItemModule,
    LinkItemModule,
    NgxSkeletonLoaderModule,
    NgxSpinnerModule,
    PaddingModule,
    PanelModule,
    SearchBarModule,
    SearchPanelModule,
    SpacingModule,
    SummaryItemModule,
    SwitchModule,
    ThumbnailModule,
    TitleModule,
    ToggleModule,
    TranslateModule,
    SimpleModalModule,
    ToggleV2Module,
    TrapFocusModule,
    PipesModule,
  ],
  exports: [SearchComponent, TranslatePipe],
  providers: [],
})
export class SiteSearchModule {}
