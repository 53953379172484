import isEmpty from 'lodash/isEmpty';
import isObjectLike from 'lodash/isObjectLike';
import some from 'lodash/some';

/**
 * deep empty check, with fast forking if not empty
 * @param collection  The collection to iterate over.
 * @return Returns true if any element passes the predicate check, else false.
 */
export function isEmptyDeep(collection: any): boolean {
  return !isNotEmptyDeep(collection);
}

/**
 * deep not empty check, with fast forking if not empty
 * @param collection  The collection to iterate over.
 * @return Returns true if any element has data, else false.
 */
export function isNotEmptyDeep(collection): boolean {
  if (isEmpty(collection)) {
    return false;
  }
  return some(collection, (obj, key) => {
    if (key === '__debug') {
      return false;
    }
    if (isObjectLike(obj)) {
      return isNotEmptyDeep(obj);
    }
    return !isEmpty(obj);
  });
}

/**
 * performs a base merge of two objects, ignoring array merging
 */
export function mergeConfig<T>(baseObject: T, configObject: T): T {
  for (const key in configObject) {
    if (configObject.hasOwnProperty(key)) {
      const existingValue = baseObject[key];
      const newValue = configObject[key];
      if (typeof existingValue === 'object' && !Array.isArray(existingValue)) {
        baseObject[key] = mergeConfig(existingValue, newValue);
      } else {
        baseObject[key] = configObject[key];
      }
    }
  }
  return baseObject;
}

/**
 * performs a string split
 */
export function splitField(field: string): string[] {
  const valueAsArray =
    field === ''
      ? []
      : field.split(',').map((val: string): string => val.replace(/_C_/g, ','));
  return valueAsArray;
}

/**
 * Recursively go through configuration and convert any field ending with _csv to an array.
 */
export function convertCsvFields<T>(baseObject: T): T {
  // TODO: not sure if this should clone the object first, rather than mutating the original object
  if (Array.isArray(baseObject)) {
    baseObject.forEach((child) => {
      convertCsvFields(child);
    });
  } else if (typeof baseObject === 'object') {
    Object.getOwnPropertyNames(baseObject).forEach((propName) => {
      if (propName.endsWith('_csv')) {
        // logger.debug(`converting ${propName} (${baseObject[propName]} to array`, baseObject);
        const newPropName = propName.substring(0, propName.length - 4);
        baseObject[newPropName] = splitField(baseObject[propName]);
        delete baseObject[propName];
      } else if (
        typeof baseObject[propName] === 'object' ||
        Array.isArray(baseObject[propName])
      ) {
        convertCsvFields(baseObject[propName]);
      }
    });
  }
  return baseObject;
}
