<div *ngIf="document && content" [ngClass]="{ 'has-edit-button': isEditMode }">
  <div edsGrid>
    <div class="container">
      <div edsRow>
        <div edsCol>
          <eds-title *ngIf="content.formName" edsSpacing="md">{{
            content.formName
          }}</eds-title>
          <eds-notifications
            *ngIf="isMailSent"
            [hasBorders]="true"
            [hasBorderRadius]="true"
            [hasShadows]="false"
            [title]="'customForm.success-notification-title' | translate"
            [body]="'customForm.success-notification-body' | translate"
            notificationType="confirm"
            [canClose]="true"
            edsSpacing="lg"
          >
          </eds-notifications>
          <form [formGroup]="formDetails" *ngIf="!isMailSent">
            <ng-container *ngFor="let field of fields">
              <!-- Text Field -->
              <ng-container *ngIf="field.type === 'TEXT_FIELD'">
                <div edsSpacing="lg">
                  <eds-text-input
                    type="text"
                    [placeholder]="field.expandHint ? '' : field.hint"
                    [label]="field.label"
                    [name]="field.name"
                    [required]="field.mandatory"
                    [(value)]="field.initialValue"
                    [invalid]="field.invalid"
                    [invalidText]="field.invalidText"
                    [hasHelperText]="field.expandHint"
                    [helpText]="field.hint"
                    (inputTextChange)="setFieldValues($event, field)"
                  ></eds-text-input>
                </div>
                <!--  -->
              </ng-container>

              <!-- DROPDOWN button
                TODO  [dropdownId]="'items.id'"  because it was 'id' previously and there is no 'id attribute' - to verify -->
              <ng-container *ngIf="field.type === 'DROPDOWN'">
                <div edsSpacing="lg">
                  <eds-dropdown
                    [label]="field.label"
                    [name]="field.name"
                    [dropdownId]="'items.id'"
                    [items]="items"
                    [titleText]="field.expandHint ? '' : field.hint"
                    [preselectedValue]="field.expandHint ? 'field.label' : ''"
                    [required]="field.mandatory"
                    [invalid]="field.invalid"
                    [invalidText]="field.invalidText"
                    [helpText]="field.expandHint ? field.hint : ''"
                    (dropdownChange)="setFieldValues($event.value, field)"
                  ></eds-dropdown>
                </div>
              </ng-container>

              <!-- Radio Group button -->
              <ng-container *ngIf="field.type === 'RADIOGROUP'">
                <div edsSpacing="lg">
                  <eds-radio-group
                    [label]="field.label"
                    [required]="field.mandatory"
                    [helpText]="field.hint"
                    (radioChange)="setFieldValues($event.value, field)"
                    name="{{ field.label }}"
                  >
                    <eds-radio
                      *ngFor="let radio of radioItems"
                      [id]="radio.id"
                      [value]="radio.value"
                      labelName="{{ radio.label }}"
                    >
                    </eds-radio>
                  </eds-radio-group>
                </div>
              </ng-container>

              <!-- Date Field -->
              <ng-container *ngIf="field.type === 'DATE_FIELD'">
                <div edsSpacing="lg" edsFlexbox alignItems="center">
                  <eds-duet-date-picker
                    type="day"
                    id="date.id"
                    [label]="field.label"
                    [placeholder]="field.label"
                    [format]="field.dateformat | uppercase"
                    [removeMargin]="true"
                    (selected)="setFieldValues($event, field)"
                    [calendarHeading]="
                      'common.date-picker-calendar-heading' | translate
                    "
                    [closeLabel]="'common.date-picker-close' | translate"
                    [prevMonthLabel]="
                      'common.date-picker-previous-button' | translate
                    "
                    [nextMonthLabel]="
                      'common.date-picker-next-button' | translate
                    "
                    [monthSelectLabel]="
                      'common.date-picker-select-month' | translate
                    "
                    [yearSelectLabel]="
                      'common.date-picker-select-year' | translate
                    "
                    [selectedDateMessage]="
                      'common.date-picker-selected-date' | translate
                    "
                    [buttonLabel]="'common.date-picker-button' | translate"
                  ></eds-duet-date-picker>
                </div>
              </ng-container>

              <!-- Text Area -->
              <ng-container *ngIf="field.type === 'TEXTAREA'">
                <div edsSpacing="lg">
                  <eds-text-area
                    [label]="field.label"
                    [placeholder]="field.expandHint ? '' : field.hint"
                    [required]="field.mandatory"
                    [rows]="field.rows"
                    [cols]="field.cols"
                    [name]="field.name"
                    [invalid]="field.invalid"
                    [invalidText]="field.invalidText"
                    [hasHelperText]="field.expandHint"
                    [helpText]="field.hint"
                    aria-label="textArea"
                    (inputTextChange)="setFieldValues($event, field)"
                  ></eds-text-area>
                </div>
              </ng-container>
            </ng-container>

            <!--Captcha-->
            <ng-container>
              <div edsSpacing="lg">
                <re-captcha
                  name="recaptcha"
                  required
                  (resolved)="handleCaptcha($event)"
                  [siteKey]="siteKey"
                ></re-captcha>
              </div>
            </ng-container>

            <!-- Submit Button -->
            <div edsSpacing="lg">
              <button
                edsButton
                (click)="submitCustomForm()"
                [disabled]="formDetails.invalid || isFormSubmitted"
              >
                {{ content.buttonLabel }}
              </button>
            </div>
          </form>
          <eds-notifications
            *ngIf="isErrorResponse"
            [hasBorders]="true"
            [hasBorderRadius]="true"
            [hasShadows]="false"
            [title]="'customForm.error-notification-title' | translate"
            [body]="'customForm.error-notification-body' | translate"
            notificationType="error"
            [canClose]="true"
            edsSpacing="lg"
          >
          </eds-notifications>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- no document -->
<div *ngIf="!document && isEditMode" class="no-document">
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>Custom Form</strong>
    </div>
  </div>
</div>
