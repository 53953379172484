import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: 'ftDashIfEmpty',
})
export class DashIfEmptyPipe implements PipeTransform {
  transform(val?: any): string {
    // COREWEB-1883 translate '-' to m-dash
    if (val === '-') {
      return '—';
    }
    return val || val === 0 ? String(val) : '—';
  }
}
