import {
  CurrencyCode,
  FundSectionLink,
  FundShareClassId,
  ProductType,
  ShareClassCode,
} from '@types';
import { BMAssociation } from './benchmark-association';
import { BMPerformance } from './benchmark-performance';
import { Charges } from './charges';
import { Distribution } from './distribution';
import { Exchange } from './exchanges';
import { Identifiers } from './identifiers';
import { NAV } from './nav';
import { Performance } from './performance';
import { PremiumDiscountElevated } from './premium-discount';
import { Ratings } from './ratings';
import { RiskStatistics } from './risk-statistics';
import { RiskStatsSummary } from './risk-stats-summary';
import { SalesChargeBreakPoint } from './sales-charge-break-points';
import { UsTax } from './us-tax';
import { Yields } from './yields';

export interface ShareClass {
  identifiers?: Identifiers;
  shareClassName?: string;
  shareClassCode?: ShareClassCode;
  fundShareClassId?: FundShareClassId;
  primaryShareClass?: boolean;
  inceptionDate?: string;
  inceptionDateStd?: string;
  performanceInceptionDate?: string;
  performanceInceptionDateStd?: string;
  charges?: Charges;
  nav?: NAV;
  performance?: Performance;
  benchmarkAssociation?: BMAssociation[];
  benchmarkPerformance?: BMPerformance;
  distribution?: Distribution[];
  isSoftLaunch?: boolean;
  shareClassFeeStructure?: ShareClassCode; // TODO: is this correct type?
  shareClassCurrency?: CurrencyCode;
  yields?: Yields;
  ratings?: Ratings;
  link?: string;
  previewLink?: string;
  sectionLinks?: FundSectionLink[];
  distributionIndicator?: string;
  dividendFrequency?: string;
  capgainFrequency?: string;
  riskFactor?: string;
  riskStatistics?: RiskStatistics;
  riskStatsSummary?: RiskStatsSummary[];
  exchanges?: Exchange[];
  salesChargeBreakPoints?: SalesChargeBreakPoint[];
  isSoftClosed?: boolean;
  isSingleShareClass?: boolean;
  tax?: UsTax;
  premiumDiscountElevated?: PremiumDiscountElevated[];
  federalTaxId?: string;
  productType?: ProductType;
  isEtfClass?: boolean;
  hasPreliminaryPerformanceStatus?: boolean;
  managementFee?: string;
  riskClassificationText?: string;
  mer?: string;
  isFavoriteFund?: boolean;
  isHeldFund?: boolean;
}
