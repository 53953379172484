<!--div [class]="theme">
  <div edsGrid [edsSpacing]="spacingBottom">
  <div class="container" [ngClass]="{
  'container--wide': isWide
  }">
    <div edsRow>
      <div edsCol>
        <ng-container *ngIf="signInRequired; else showDocumentViewer">
          <p>{{ signInCustomIntro }}</p>
          <ft-sign-in
            [inputParameters]="{
              skin: 'Basic 2 Columns',
              legacy: false,
              signOut: false
            }"
          ></ft-sign-in>
        </ng-container>
        <ng-template #showDocumentViewer>
          <ngx-doc-viewer
            [url]="url"
            [viewer]="viewer"
            [disableContent]="disableContent"
            style="width: 100%; height: 50vh"
          ></ngx-doc-viewer>
        </ng-template>
      </div>
    </div>
  </div>
</div>
</div-->
<div [class]="theme" class="doc-viewer">
  <div edsGrid [edsSpacing]="'md'">
    <div
      class="container"
      [ngClass]="{
        'container--wide': isWide
      }"
    >
      <div edsRow>
        <div edsCol>
          <ng-container *ngIf="signInRequired; else showDocumentViewer">
            <p>{{ signInCustomIntro }}</p>
            <ft-sign-in
              [inputParameters]="{
                skin: 'Basic 2 Columns',
                legacy: false,
                signOut: false
              }"
            ></ft-sign-in>
          </ng-container>
          <!-- Doc Viewer -->
          <ng-template #showDocumentViewer>
            <div edsSpacing="md" class="doc-viewer__presentation-wrapper">
              <span
                class="doc-viewer__hide-download-menu"
                *ngIf="hideDownloadOption"
              ></span>
              <ngx-doc-viewer
                [url]="location"
                [viewer]="'office'"
                [disableContent]="disableContent"
                style="width: 100%; height: 50vh"
              ></ngx-doc-viewer>
            </div>
            <div edsSpacing="md">
              <ngx-doc-viewer
                [url]="
                  'https://franklintempletonprod.widen.net/content/tshruxwhah/original/full-anatomy-of-recession.pdf'
                "
                [viewer]="'pdf'"
                [disableContent]="disableContent"
                style="width: 100%; height: 50vh"
              ></ngx-doc-viewer>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
