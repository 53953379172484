<div edsGrid>
  <div edsRow>
    <div edsCol columns="{'l': '12', 'm': '8', 's': '4'}">
      <eds-title [title]="title" size="h3" edsSpacing="lg"></eds-title>
      <p>{{ subTitle }}</p>
      <highcharts-chart
        *ngIf="highcharts && chartOptions"
        [Highcharts]="highcharts"
        [options]="chartOptions"
        style="width: 100%; height: 400px; display: block"
      ></highcharts-chart>
      <div edsPadding edsPaddingTop="sm" edsBackground theme="white">
        <table class="table table--secondary col s4 m8 l12">
          <tr class="table__tr">
            <td *ngFor="let legend of legends; let i = index">
              <span class="table__legend" edsSpacing
                ><span
                  class="table__legend-box"
                  [style.background]="legend.pointColor"
                ></span
                >{{ legend.label }}</span
              >
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
