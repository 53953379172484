export const MUTUAL_FUNDS = 'Mutual Funds';
export const SMA = 'Managed Accounts';
export const VIP = 'Variable Insurance';
export const VIP_LOGGED = 'Variable Insurance Logged';
export const PORTFOLIOS_529 = '529 Portfolios';
export const CLOSED_END = 'Closed-End Funds';
export const ETF = 'ETFs';
export const MONEY_MARKET = 'Money Market Funds';
export const INTERVAL_FUNDS = 'Interval Funds';
export const DEFAULT = 'default';
