import {
  GridModule,
  PaddingModule,
  SpacingModule,
  VideoModule,
} from '@frk/eds-components';

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { BrSdkModule } from '@bloomreach/ng-sdk';
import { TableSelectionModule } from '@marketing/table-selection/table-selection.module';
import { PipesModule } from '@shared/pipes/pipes.module';
import { AvatarBlockModule } from '../avatar-block/avatar-block.module';
import { BlockQuoteAvatarBlockModule } from '../blockquote-avatar-block/blockquote-avatar-block.module';
import { BlockQuoteBlockModule } from '../blockquote-block/blockquote-block.module';
import { ButtonsBlockModule } from '../buttons-block/buttons-block.module';
import { ImageBlockModule } from '../image-block/image-block.module';
import { InteractiveContentBlockModule } from '../interactive-content-block/interactive-content-block.module';
import { TextBlockModule } from '../text-block/text-block.module';
import { TitleBlockModule } from '../title-block/title-block.module';
import { OneColBlockComponent } from './one-col-block.component';

@NgModule({
  declarations: [OneColBlockComponent],
  imports: [
    AvatarBlockModule,
    BlockQuoteBlockModule,
    ButtonsBlockModule,
    ImageBlockModule,
    TextBlockModule,
    TitleBlockModule,
    BrSdkModule,
    CommonModule,
    RouterModule,
    GridModule,
    PaddingModule,
    SpacingModule,
    PipesModule,
    VideoModule,
    TableSelectionModule,
    BlockQuoteAvatarBlockModule,
    InteractiveContentBlockModule,
  ],
  exports: [OneColBlockComponent],
  entryComponents: [OneColBlockComponent],
})

/**
 * Image Block Module
 */
export class OneColBlockModule {}
