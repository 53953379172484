import dayjs from 'dayjs';
import enConfig from 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
dayjs.extend(updateLocale);

dayjs.locale('en-ch', { ...enConfig });
/**
 * English Swiss Locale
 * -> American English Words and Numbers
 * -> Swiss German Formatting
 */
export const customEnChConfig = dayjs.updateLocale('en-ch', {
  formats: {
    ...enConfig.formats,
    LT: 'HH:mm',
    LTS: 'HH:mm:ss',
    L: 'DD.MM.YYYY',
    LL: 'D. MMMM YYYY',
    LLL: 'D. MMMM YYYY HH:mm',
    LLLL: 'dddd, D. MMMM YYYY HH:mm',
  },
});
