import { DOCUMENT } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import {
  AnalyticsService,
  LanguageSelector,
  SignInComponentFieldsInfo,
  SignInEvent,
  SimpleModalComponent,
  SimpleModalConfig,
} from '@frk/eds-components';
import { WINDOW } from '@ng-web-apis/common';
import {
  GatewayModalService,
  LanguageSelectorService,
  ProfileService,
  SignInService,
} from '@services';
import { SignInInterface } from '@shared/sign-in/sign-in.interface';
import { Segment, SegmentId } from '@types';
import { Logger } from '@utils/logger';
import jsonpointer from 'jsonpointer';
import get from 'lodash/get';
import { Observable, Subscription } from 'rxjs';

const logger = Logger.getLogger('GatewayModalComponent');

export interface TermsAccepted {
  segmentId: SegmentId;
  accepted: boolean;
}

export interface CustomTermsAccepted {
  customTermsPath: string;
  accepted: boolean;
}

@Component({
  selector: 'ft-gateway-modal',
  templateUrl: './gateway-modal.component.html',
  styleUrls: ['./gateway-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GatewayModalComponent implements OnInit, OnDestroy {
  @Input() component!: BrComponent;
  @Input() page!: Page;
  public segmentId: SegmentId;
  public segment: Segment;
  public content = {};
  public title = {};
  public requestSubscription: Subscription;
  public modalConfig: SimpleModalConfig;
  public signInComponentContent: SignInComponentFieldsInfo;
  public signInForm: FormGroup;
  public termsMustRead = false;
  public termsWithSignIn = false;
  public isTermsAccepted = true;
  public isCustomTermsAccepted = true;
  public languageOptions: LanguageSelector;
  public signInError: boolean;
  public hasScrollToBottom = false;
  public signInParams: SignInInterface;
  public customTermsPath: string; // path for custom T&C modal content

  @Output() termsAccepted = new EventEmitter<TermsAccepted>();
  @Output() customTermsAccepted = new EventEmitter<CustomTermsAccepted>(); // TO DO - check if we can use output above

  @ViewChild('modal', { static: false }) modal: SimpleModalComponent;

  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    @Inject(DOCUMENT) readonly documentRef: Document,
    private gatewayModalService: GatewayModalService,
    private analyticsService: AnalyticsService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private signInService: SignInService,
    private languageSelectorService: LanguageSelectorService
  ) {}

  ngOnInit(): void {
    this.setLanguageOptions();
    const customTermsDocumentPath = this.component?.getModels()?.gateway
      ?.document;
    this.customTermsPath = this.gatewayModalService.getRelativeTermsPath(
      customTermsDocumentPath
    );
  }

  ngOnDestroy(): void {
    if (this.requestSubscription) {
      this.requestSubscription?.unsubscribe();
    }
  }

  public acceptTerms(): void {
    logger.debug('Accept Terms for', this.customTermsPath ?? this.segmentId);
    // When terms has sign-in submit form before accept and close
    if (this.termsWithSignIn) {
      this.setSignInForm();
      this.gatewayModalService.onSubmit(this.signInForm);
      this.signInError = !this.gatewayModalService.getSignInSuccess();
      if (this.signInError) {
        return;
      }
      // TODO: Check if Analytics Service call is required here.
      this.analyticsService.trackEvent({
        event: 'gateway_login_attempt',
        location: 'Terms-Modal',
        link_url: this.getGatewayLoginUrl(),
      });
    }
    this.close();

    // accept custom T&C if path present
    if (this.customTermsPath) {
      this.customTermsAccepted.emit({
        customTermsPath: this.customTermsPath,
        accepted: true,
      });
    }

    // accept default T&C if path not present
    if (!this.customTermsPath) {
      this.termsAccepted.emit({
        segmentId: this.segmentId,
        accepted: true,
      });
    }

    this.analyticsService.trackEvent({
      event: 'lightbox_display',
      action: 'accept',
      label: this.modalConfig?.title,
    });

    this.analyticsService.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'accept',
        heading: this.modalConfig?.title,
      },
    });
  }

  /**
   * Decline terms and back to gateway or jump to decline URL
   */
  public declineTerms(): void {
    logger.debug('Decline Terms for', this.segmentId);
    this.analyticsService.trackEvent({
      event: 'lightbox_display',
      action: 'decline',
      label: this.modalConfig?.title,
    });

    this.analyticsService.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'decline',
        heading: this.modalConfig?.title,
      },
    });

    if (this.segment?.declineUrl) {
      this.windowRef.open(this.segment.declineUrl, '_self');
      return;
    }

    this.close();

    // decline custom T&C if path present
    if (this.customTermsPath) {
      this.customTermsAccepted.emit({
        customTermsPath: this.customTermsPath,
        accepted: false,
      });
    }

    // decline default T&C if path not present
    if (!this.customTermsPath) {
      this.termsAccepted.emit({
        segmentId: this.segmentId,
        accepted: false,
      });
    }
  }

  /**
   * Show modal for specyfic segment
   * @param segment - active segment
   */
  public show(segment: Segment): void {
    this.segment = segment;
    this.segmentId = segment.id;
    this.termsMustRead = !!segment.termsMustRead;
    this.termsWithSignIn = !!segment.termsWithSignIn;
    // NGC-12836 - Show scroll to bottom button only when termsMustRead is configured in site config.
    this.hasScrollToBottom = this.termsMustRead;
    if (this.termsWithSignIn) {
      this.setSignIn();
    }
    if (this.termsMustRead) {
      this.isTermsAccepted = false;
    }
    if (this.title[this.segmentId] !== undefined) {
      this.modal.open();
      this.cdr.detectChanges();
    } else {
      const request: Observable<any> = this.gatewayModalService.getBrConfiguration$(
        `/modals/${this.segmentId}-terms-and-conditions`
      );
      this.requestSubscription = request.subscribe(
        (response) => {
          try {
            const modalDocument = response.getDocument();
            this.content[
              this.segmentId
            ] = this.sanitizer.bypassSecurityTrustHtml(
              modalDocument.getData().content.value
            );
            this.title[this.segmentId] = modalDocument.getData().string || '';
            this.setModalData();
            this.modal.open();
            this.setLangSwitchWrapperClass();
            this.cdr.detectChanges();
          } catch (e) {
            // Bypass users can have no T&S configured. Skip logging.
            if (!this.profileService.isBypass(true)) {
              logger.info(
                'Misconfiguration: segment needs Ts and Cs but no Ts and Cs provided for segment: ' +
                  this.segmentId
              );
            }
          }
        },
        (error) => {
          logger.info('Error getting Ts & Cs', error);
        }
      );
    }
  }

  /**
   * show custom modal for defined
   * @param path string containg path to custom T&C document location
   */
  public showCustom(): void {
    if (this.title[this.customTermsPath] !== undefined) {
      this.modal.open();
      this.cdr.detectChanges();
    } else {
      const request: Observable<any> = this.gatewayModalService.getBrConfiguration$(
        this.customTermsPath
      );

      this.requestSubscription = request.subscribe(
        (response) => {
          try {
            const modalDocument = response.getDocument();
            this.content[
              this.customTermsPath
            ] = this.sanitizer.bypassSecurityTrustHtml(
              modalDocument.getData().content.value
            );
            this.title[this.customTermsPath] =
              modalDocument.getData().string || '';
            this.setModalData(true);
            this.modal.open();
            this.setLangSwitchWrapperClass();
            this.cdr.detectChanges();
          } catch (e) {
            logger.info('Error opening CustomTs & Cs', e);
          }
        },
        (error) => {
          logger.info('Error getting CustomTs & Cs', error);
        }
      );
    }
  }

  /**
   * Close modal
   */
  public close(): void {
    this.modal.close();
    this.cdr.detectChanges();
  }

  /**
   * Check for terms accepted checkbox
   * @param event - boolean
   */
  public onCheckedChange(event: boolean): void {
    this.isTermsAccepted = event;
  }

  /**
   * Set Language options for language selector
   */
  private setLanguageOptions(): void {
    this.languageOptions = this.languageSelectorService.getLanguageOptions(
      this.page
    );
  }

  /**
   * Set Sign-In form for modal
   */
  private setSignIn(): void {
    this.signInParams = {
      legacy: false,
      signOut: false,
      skin: 'basic',
      showLegacyContent: false,
      overrideHideLoginForm: true,
    };
    this.signInForm = this.signInService.signInFormInit(this.formBuilder);
    this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
      this.signInParams
    );
    // Set parameters which shouldn't be display to null.
    this.signInComponentContent = this.gatewayModalService.cleanGatewaySignIn(
      this.signInComponentContent
    );
  }

  /**
   * Set sign in form base on sign in terms modal form ID
   * It is required to submit data during terms accepted
   */
  private setSignInForm(): void {
    const signInModal: HTMLElement = this.documentRef.getElementById(
      'signInTermsModal'
    );
    const signInInput: HTMLCollectionOf<HTMLInputElement> = signInModal.getElementsByTagName(
      'input'
    );
    const signIn: SignInEvent = {
      userName: signInInput.namedItem('userName').value,
      password: signInInput.namedItem('password').value,
      rememberMe: false,
    };
    this.signInForm.value.userId = signIn.userName;
    this.signInForm.value.password = signIn.password;
  }

  /**
   * This sets modal-header__wrapper--lang-switch class in Modal header.
   * Language selector is not covered by Modal EDS component.
   * We need this until we will have language switch in EDS Simple Modal component.
   */
  private setLangSwitchWrapperClass() {
    if (this.languageOptions) {
      const headerWrapper = this.documentRef.getElementsByClassName(
        'modal-header__wrapper'
      );
      logger.debug(headerWrapper);
      headerWrapper
        .item(0)
        .setAttribute('class', 'modal-header__wrapper--lang-switch');
    }
  }

  /**
   * Set modal data
   */
  private setModalData(isCustom: boolean = false): void {
    this.modalConfig = {
      modalId: isCustom ? 'customTermsModal' : 'termsModal',
      closeBtnLabel: '',
      title: isCustom
        ? this.title[this.customTermsPath]
        : this.title[this.segmentId],
      themeTitle: 'dark',
      // TODO: check if logo image is needed
      // headerLogo: {
      //   imgSrc: 'assets/images/logos/FT_logo_pos_0119.png',
      //   altText: 'header logo',
      // },
    };
    this.analyticsService.trackEvent({
      event: 'lightbox_display',
      action: 'Auto Open',
      label: this.modalConfig.title,
    });

    this.analyticsService.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'Auto Open',
        heading: this.modalConfig.title,
      },
    });
  }

  /**
   * Get Gateway login URL
   */
  private getGatewayLoginUrl(): string {
    return this.windowRef.location.href;
  }

  private getContentFromResponse(pageModelApiResponse): any {
    const docId = get(pageModelApiResponse, [
      'page',
      'models',
      'document',
      '$ref',
    ]);
    return jsonpointer.get(pageModelApiResponse, docId);
  }
}
