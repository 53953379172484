import { Component, Input, OnInit } from '@angular/core';
import { WidenService } from '@services';

interface Content {
  contentType: string;
  quote: string;
  profileImg?: ProfileImage;
  profileImageUrl?: string;
  quoteName: string;
  job?: string;
  job2?: string;
}

interface ProfileImage {
  widenDocument?: string;
  displayName?: string;
}

@Component({
  selector: 'ft-blockquote-avatar-block',
  templateUrl: './blockquote-avatar-block.component.html',
})
export class BlockquoteAvatarBlockComponent implements OnInit {
  /**
   * Content to render
   */
  @Input()
  content: Content;

  /**
   * Constructor
   */
  constructor(private widenService: WidenService) {}

  /**
   * On Init
   */
  ngOnInit(): void {
    if (this.content?.profileImg) {
      this.content.profileImageUrl = this.widenService.getWidenImageVariantUrl(
        this.widenService.getWidenAssetUrl(
          this.content.profileImg?.widenDocument
        ),
        'original',
        'webp'
      );
    }
  }
}
