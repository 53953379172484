import {
  Component,
  Input,
  Output,
  OnInit,
  OnChanges,
  EventEmitter,
} from '@angular/core';
import { FullResultsComponent } from '../../full-results.component';
import { ItemHeader } from '@search/interfaces/search.interface';
import { EMDASH } from '@products/utils/constants/product.constants';
import { CartActionPerformed } from '@types';
import { LinkClickEvent } from '@frk/eds-components';

@Component({
  selector: 'ft-full-results-all',

  templateUrl: './full-results-all.component.html',
  styleUrls: [
    '../../full-results.component.scss',
    './full-results-all.component.scss',
  ],
})
export class FullResultsAllComponent
  extends FullResultsComponent
  implements OnChanges {
  @Input() fundHeader: ItemHeader;
  @Input() literatureHeader: ItemHeader;
  @Input() generalHeader: ItemHeader;

  @Input() fundItems: object[]; // TODO set proper type
  @Input() literatureItems; // TODO set proper type
  @Input() generalItems: object[]; // TODO set proper type

  @Input() isSiteInternational?: boolean;
  @Input() searchTerm: string;

  @Output() viewMoreClickedAll = new EventEmitter<string>();
  labelAddToCart: string;
  // by default
  layoutType = 2;

  ngOnChanges() {
    // this modifies number of column if result type is not available
    this.layoutType = this.getLayoutType();
  }
  checkCart(item) {
    if (
      this.cartItems.includes(item.litCode) ||
      this.cartItems.includes(item.customFilePath)
    ) {
      return true;
    }
    if (
      !this.cartItems.includes(item.litCode) &&
      !this.cartItems.includes(item.customFilePath)
    ) {
      return false;
    }
  }
  updateCart(data) {
    const docID = data.litCode !== EMDASH ? data.litCode : data.customFilePath;
    if (this.cartItems.includes(docID)) {
      this.cartHandlerService.removeFromCart(
        [docID],
        CartActionPerformed.DELETED_FROM_SEARCH
      );
      return;
    }
    this.addToCart(data);
  }

  /**
   * calculates how many columns are required to display content
   */
  private getLayoutType() {
    return Math.max(
      (this.fundItems && this.fundItems.length > 0 ? 1 : 0) +
        (this.literatureItems && this.literatureItems.length > 0 ? 1 : 0) +
        (this.generalItems && this.generalItems.length > 0 ? 1 : 0) -
        1,
      0
    );
  }

  /**
   *  this may require refactoring
   * there is an issue with inheritance of Output
   * https://github.com/angular/angular/issues/5415
   *
   */
  onViewMore($tabName: string) {
    this.activeTab = $tabName;
    this.viewMoreClickedAll.emit($tabName);

    // TODO to verify this position after we introduce filters
    // this.clickedElemBottom = document.getElementById($event.target.id).getBoundingClientRect().top + document.documentElement.scrollTop;
  }

  public onLinkClicked(
    event: LinkClickEvent,
    selectedData: any,
    contentType: string
  ): void {
    this.trackSelectorItem(event, selectedData, contentType);
  }

  public trackSelectorItem(
    event: LinkClickEvent,
    selectedData: any,
    contentType: string
  ): void {
    if (contentType === 'Investment Options') {
      this.analyticsService.trackEvent({
        event: 'select_content',
        detailed_event: 'Select Content',
        event_data: {
          content_type: contentType,
          facets: '',
          index: '',
          link_text: selectedData?.fundName,
          link_url: selectedData?.fundLink,
          search_category: 'All',
          search_term: this.searchTerm,
        },
      });
    } else {
      this.analyticsService.trackEvent({
        event: 'select_content',
        detailed_event: 'Select Content',
        event_data: {
          content_type: contentType,
          facets: '',
          index: '',
          link_text: selectedData?.title,
          link_url: selectedData?.titleLink,
          search_category: 'All',
          search_term: this.searchTerm,
        },
      });
    }
  }
}
