import {
  Input,
  Component,
  OnChanges,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  Observable,
  Subject,
  BehaviorSubject,
  of,
  combineLatest,
  Subscription,
} from 'rxjs';
import {
  filter,
  map,
  mergeMap,
  pluck,
  first,
  toArray,
  takeUntil,
  tap,
} from 'rxjs/operators';

import { EMDASH } from '@products/utils/constants/product.constants';
import { SMA, VIP } from '@search/interfaces/products.constants';
import { formatStdDateToStdYear } from '@utils/text/date-utils';
import { ConfigService } from '@search/services/config.service';
import { ProductTypeParameter } from '@products/services/graphql-fund-data.service';
import { Product, Profile, InvestmentTeam } from '@models';
import {
  ConfigurationId,
  FundId,
  ShareClassCode,
  FundManagementState,
  CalcTypeStd,
} from '@types';
import {
  InfoItem,
  NavigationService,
  ResponsiveService,
  AnalyticsService,
  HighchartsThemeService,
} from '@frk/eds-components';
import { HttpClient } from '@angular/common/http';

import { Logger } from '@utils/logger';

import {
  Avatar,
  DocThumbnail,
  ExactMatch,
  NavData,
} from '@search/interfaces/search.interface';

import { FundPerformanceAnnualizedComponent } from '@products/fund-performance/fund-performance-annualized/fund-performance-annualized.component';
import { FundPerformanceComponentConfig } from '@products/fund-performance/fund-performance-component.config';

import fundSummaryDetails from '@graphql/search/fund-summary.graphql';
import fundManagementQuery from '@graphql/search/fund-management.graphql';

import { FundDetailsService, FundInfoElem } from './fund-details.service';

import { FundOverviewService } from '@products/services/fund-overview.service';
import { FundSummaryService } from '@products/services/fund-summary.service';
import { FundManagementService } from '@products/overview/services/fund-management.service';
import { FundPerformanceAnnualizedService } from '@products/fund-performance/services/fund-performance-annualized.service';

import { TranslateService } from '@shared/translate/translate.service';

/**
 * this is required extension, because default asOfDate parameter sent into eds-info-item elment displays date below element,
 * which is not required in search
 */
interface NetAssets extends InfoItem {
  asOf: string;
}

interface InvestmentManagerAvatar extends Avatar {
  employeeId: number;
  profile: Profile;
}

interface PerformanceYtdDetails {
  performance: {
    quarterEnd: {
      performanceAsOfDate: string;
      calcTypeStd: CalcTypeStd;
      cummulativeReturnYearToDate: string;
    }[];
  };
}
interface OverviewData extends Product {
  shareClasses: PerformanceYtdDetails[];
}

const logger = Logger.getLogger('FundDetails');

@Component({
  selector: 'ft-fund-details',
  templateUrl: './fund-details.component.html',
  styleUrls: [
    '../../exact-match.component.scss',
    './fund-details.component.scss',
  ],
})
export class FundDetailsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() exactMatch: ExactMatch;
  /**
   * angular device detection
   */
  isHandheld$: Observable<boolean>;
  private dataSubscription: Subscription;

  fundId: FundId;
  shareClassCode: ShareClassCode;

  layoutType = 'layout-2';
  hasColumnNo2$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  hasColumnNo3$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  fundLink: string;
  fundTitle: string;
  priceAndYtdLabel: string;

  navData$: Observable<NavData>;
  infoItem$: Observable<InfoItem[]>;
  netAssets$: Observable<NetAssets>;

  fundDocuments$: Observable<Array<DocThumbnail>>;

  componentDestroyed$: Subject<boolean> = new Subject();
  investmentTeam$: BehaviorSubject<InvestmentTeam[]> = new BehaviorSubject([]);

  // TODO - check why these labels were not working with Subject...
  fundChartDataLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  indexChartDataLabel$: BehaviorSubject<string> = new BehaviorSubject('');
  chartAsOfDate$: BehaviorSubject<string> = new BehaviorSubject('');

  // documents: DocumentData[] = [];
  fundDescription$: BehaviorSubject<string> = new BehaviorSubject('');

  configurationId = ConfigurationId.GW;
  productType = ProductTypeParameter.MUTUAL_FUNDS; // TODO this must be read from Elastic API
  productTypeElastic: string; // TODO clarify mapping between Elastic and ProductTypeParameter

  priceElements: string[];
  fundInfoElements: FundInfoElem[];
  public hasYearsOfExperience: boolean;
  public yearsOfExperienceLabel: string;
  chartData: {};
  chartDataReady = false;
  updateChart: boolean;

  chartDisclosure = '';
  chartDisclosureLink = '';

  highcharts;
  chartOptions: Highcharts.Options;

  EMDASH = EMDASH; // we can use it in template

  constructor(
    public cd: ChangeDetectorRef,
    protected http: HttpClient,
    private configService: ConfigService,
    protected translateService: TranslateService,
    protected fundOverviewService: FundOverviewService,
    protected fundSummaryService: FundSummaryService,
    protected fundManagementService: FundManagementService,
    protected fundPerformanceService: FundPerformanceAnnualizedService,
    protected navigationService: NavigationService,
    protected analyticsService: AnalyticsService,
    private highchartsTheme: HighchartsThemeService,
    public responsiveService: ResponsiveService,
    public fundDataService: FundDetailsService
  ) {
    // subscribe to fundDescription output
    this.fundDataService.fundDescriptionOutput$
      .pipe(
        first(),
        takeUntil(this.componentDestroyed$), // unsubscribe after reading results
        tap(() => {
          logger.debug('received fund description data');
        })
      )
      .subscribe((fundDescription: {}) => {
        // todo - only one string is needed from response
        this.fundDescription$.next(
          fundDescription[0]?.contentblocks[0]?.content
        );
      });
  }

  ngOnInit() {
    import(/* webpackChunkName: "highcharts-async" */ 'highcharts').then(
      (highchartsModule) => {
        highchartsModule.setOptions(this.highchartsTheme.themeOptions);
        this.highcharts = highchartsModule;

        this.createChartOptions(highchartsModule);
      }
    );

    this.chartDisclosure = this.translateService.instant(
      'common.read-important-information'
    );
    this.chartDisclosureLink = this.translateService.instant(
      'common.read-important-information-link'
    );
    this.isHandheld$ = this.responsiveService.isHandheld$();
  }

  private createChartOptions(highchartsModule) {
    this.chartOptions = {
      title: null,
      series: [],
      chart: {
        events: {
          render(this) {
            const that = this;
            setTimeout(() => {
              that.reflow();
            }, 0);
          },
        },
        reflow: true,
      },
      tooltip: {
        formatter() {
          const decimalKey = 'decimals';
          return `${this.point.name}<br />${highchartsModule.numberFormat(
            this.point.y,
            this.point[decimalKey]
          )}`;
        },
      },
      xAxis: {
        categories: [],
        labels: {
          style: {
            color: this.highchartsTheme.themeFonts.colors.grey,
            fontSize: this.highchartsTheme.themeFonts.size.p,
            fontFamily: this.highchartsTheme.themeFonts.fontFamily,
          },
        },
      },
      yAxis: {
        gridLineDashStyle: 'LongDash',
        minTickInterval: 0.1,
        title: {
          text: null,
        },
        labels: {
          useHTML: true,
          style: {
            color: this.highchartsTheme.themeFonts.colors.grey,
            fontSize: this.highchartsTheme.themeFonts.size.p,
            fontFamily: this.highchartsTheme.themeFonts.fontFamily,
          },
          formatter() {
            return `${highchartsModule.numberFormat(this.value, 1)}`;
          },
        },
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        column: {
          maxPointWidth: 24,
        },
        series: {
          events: {
            legendItemClick() {
              return false;
            },
          },
          animation: {
            duration: 2000,
            easing: 'easeIn',
          },
          dataLabels: {
            enabled: false,
          },
        },
      },
    };
  }

  /**
   *
   */
  ngOnChanges() {
    this.getFundsData();
  }

  /**
   * TODO - make this async
   */
  getFundsData() {
    this.fundDataService.setExactResults(this.exactMatch);

    this.productTypeElastic = this.fundDataService.getProductType();

    this.fundLink = this.fundDataService.getProductPageLink();
    this.fundTitle = this.fundDataService.getFundTitle();
    this.priceAndYtdLabel = this.fundDataService.getPriceAndYtdLabel();

    // NAV section data
    this.navData$ = this.fundDataService.getNavData$();

    // fund info - sales chanrges
    this.infoItem$ = this.fundDataService.getFundInformation$();
    this.fundDocuments$ = this.fundDataService.getFundDocuments$();

    this.fundId = this.fundDataService.fundId;
    this.shareClassCode = this.fundDataService.shareClassCode;
    this.priceElements = this.fundDataService.priceElements;
    this.fundInfoElements = this.fundDataService.fundInfoElements;
    this.yearsOfExperienceLabel = this.translateService.instant(
      'products.years-of-experience'
    );
    this.getFundManagementData();

    // we can get async data only after processing sync data from Elastic, including fundId and shareClass
    this.getFundManagementData();
    this.getTotalNetAssetsData();
    this.getFundDescription();

    this.getPerformanceChartData();
    this.setLayoutType();
  }

  /**
   * reads management team data from async product service
   */
  getFundManagementData() {
    // TODO - consider refactoring to input$ -> output$ pattern
    this.fundOverviewService
      .register(fundManagementQuery, {
        configurationName: this.configurationId,
        fundId: this.fundId,
        shareClassCode: this.shareClassCode,
      })
      .pipe(
        first(),
        takeUntil(this.componentDestroyed$), // unsubscribe after reading results
        pluck('investmentTeam'),
        mergeMap((investmentTeam) => of(...investmentTeam)),
        toArray()
      )
      .subscribe((investmentTeam: InvestmentTeam[]) => {
        if (investmentTeam) {
          // we apply manager pictures
          const managedSinceLabel = this.translateService.instant(
            'products.managed-fund-since'
          );
          investmentTeam.forEach((manager: InvestmentTeam) => {
            this.fundManagementService.loadProfile(manager);

            manager.managerNameWithSuffix =
              manager.managerName +
              this.fundManagementService.getManagerTitleSuffix(manager);

            manager.managedSinceString = manager.startDateFundManagementStd
              ? managedSinceLabel +
                ' ' +
                formatStdDateToStdYear(manager.startDateFundManagementStd)
              : '';
          });
          // we can emit value before populating manager pictures
          this.investmentTeam$.next(investmentTeam);
        }
      });

    // TODO  - whole code needs to be refactored
    // we get investmentTeam twice - in parent loop and again from fundManagementState
    // we can remove one dependency
    this.dataSubscription = this.fundManagementService.fundManagementState
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe((newState: FundManagementState) => {
        this.hasYearsOfExperience = newState.data?.showYearsOfExperience;
        this.cd.detectChanges();
      });
    const brConfig = this.getBrConfig();
    this.fundManagementService.populate(brConfig);
  }

  /**
   * this methods extract Ytd Cumulative performance required by SMA
   */
  getYtdData(overview: OverviewData) {
    // TODO This condition is not perfect.
    // it is to prevent overwriting NAV object for non-SMA funds by SMA data
    // Main reason is, that we read NAV data from Elastic and if needed, ytd cumulative perf data from PDS
    // I wanted to leverage NavData object, to keep structure.
    // we need to verify from config if there is any NAV data needed and copy it from Elastic
    // then add ytd cumulative data or check if selected type has Ytd cumulative only instead of checking productType
    if (this.productTypeElastic !== SMA) {
      return;
    }

    const navElement: NavData = {
      updatedDaily: false,
      asOfDate: '',
      ytdCumNet: '',
      ytdCumGross: '',
      ytdCumPureGross: '',
    };

    overview?.shareClasses[0]?.performance?.quarterEnd.forEach((perfElem) => {
      if (perfElem.calcTypeStd === 'NET') {
        // TODO - we can create pipe for this formatting
        navElement.ytdCumNet = perfElem.cummulativeReturnYearToDate
          ? perfElem.cummulativeReturnYearToDate + '%'
          : '';
        navElement.asOfDate = perfElem.performanceAsOfDate;
      }
      if (perfElem.calcTypeStd === 'GROSS') {
        navElement.ytdCumGross = perfElem.cummulativeReturnYearToDate
          ? perfElem.cummulativeReturnYearToDate + '%'
          : '';
      }
      if (perfElem.calcTypeStd === 'PUREGROSS') {
        navElement.ytdCumPureGross = perfElem.cummulativeReturnYearToDate
          ? perfElem.cummulativeReturnYearToDate + '%'
          : '';
      }
    });
    this.navData$ = of(navElement);
    this.cd.markForCheck();
  }

  /**
   * GET TOTAL NET ASSETS - AUM
   */
  getTotalNetAssetsData() {
    // TODO consider if this can be read from another service
    // TODO - consider refactoring to input$ -> output$ pattern
    this.netAssets$ = this.fundSummaryService
      .register(fundSummaryDetails, {
        configurationName: this.configurationId,
        productType: this.productType,
        fundId: this.fundDataService.fundId,
        shareClassCode: this.fundDataService.shareClassCode,
      })
      .pipe(
        first(),
        takeUntil(this.componentDestroyed$), // unsubscribe after reading results
        tap(([overview, summaryElements]) =>
          this.getYtdData(overview as OverviewData)
        ), // read ytd data if required (SMA funds)
        mergeMap(([overview, summaryElements]) => of(...summaryElements)),
        filter((element) => element.elementNameStd === 'TOTAL_NET_ASSETS'),
        /*
         * alternative way of filtering data
         *  map((item) => item.find((elem) => elem.elementNameStd === 'TOTAL_NET_ASSETS'))
         */
        map((response) => {
          return {
            label: this.translateService.instant('products.total-net-assets'),
            val: response.elementValue,
            asOf: response.asOfDate,
          };
        })
      );
  }

  /**
   * reads fund description from widen/product feed.
   */
  getFundDescription() {
    // get fund description NGC-1622
    const brConfig = this.getBrConfig();

    if (brConfig.fundId) {
      // trigger fundDescription call
      this.fundDataService.getFundDescription();
    }
  }
  /**
   * reads data for chart
   */
  getPerformanceChartData() {
    const fundPerformanceAnnualizedComponent = new FundPerformanceAnnualizedComponent(
      this.fundPerformanceService,
      this.cd,
      this.translateService,
      this.analyticsService
    );

    const brConfig = this.getBrConfig();

    const componentConfig = FundPerformanceComponentConfig.getConfig(
      'Annualized', // TODO this needs to be read from fund config
      'GW' as ConfigurationId
    );
    this.chartDataReady = false;
    combineLatest([
      fundPerformanceAnnualizedComponent.customFundPerformanceData$,
      fundPerformanceAnnualizedComponent.xAxisCategories$,
    ])
      .pipe(
        takeUntil(this.componentDestroyed$),
        tap(() => {
          // clear data before getting new chart
          this.chartOptions.series = [];
          this.hasColumnNo2$.next(false);
        })
      )
      .subscribe(([customFundPerformanceData, xAxisCategories]) => {
        // TODO rework conditions for new funcs and funds without performance
        if (customFundPerformanceData.graphData?.length === 0) {
          return false;
        }

        // this is to prevent displaying performance for non logged VIP
        // as for now, we need to call getPerformanceData to make sure we hide column if user previously searched for another productType
        if (
          this.productTypeElastic === VIP &&
          !this.configService.getIsLoggedIn()
        ) {
          return false;
        }

        if (
          customFundPerformanceData.graphData?.length &&
          customFundPerformanceData.graphData?.length !== 0
        ) {
          this.chartOptions.series = customFundPerformanceData.graphData;

          // add colors, remove unneeded/blocked rows.
          this.getLegendColors(this.chartOptions.series);

          this.chartAsOfDate$.next(
            customFundPerformanceData.performanceDates.asOfDate
          );
          if ('categories' in this.chartOptions.xAxis) {
            // type guard, obnly one xaxis in performance chart
            this.chartOptions.xAxis.categories = xAxisCategories;
          }

          // fund details has 2nd column with chart
          this.hasColumnNo2$.next(true);
          setTimeout(() => {
            this.chartDataReady = true;
            this.updateChart = true;
            this.cd.markForCheck();
          }, 100);
        }

        this.cd.markForCheck();
      });

    fundPerformanceAnnualizedComponent.populate(
      componentConfig,
      brConfig,
      null,
      null
    );
  }

  /**
   *
   * @param rows data rows
   */
  getLegendColors(rows: any) {
    // to define type properly extending SeriesOptionsType
    // I created jira for this already NGC-9596
    let index = 0;
    rows.forEach((row) => {
      if (!row.hideOnChart) {
        row.pointColor = this.highchartsTheme.getBenchmarkPointColor(
          index++,
          rows.length
        );
      } else {
        row.pointColor = null;
      }
    });
  }

  // loads manager picture url from Widen
  // this can be refactored with pattern input$ -> output$ in a service
  // src\app\ft-components\products\overview\services\fund-management.service.ts
  loadProfile(manager: InvestmentManagerAvatar) {
    if (manager.employeeId) {
      const url = `/rest/documents?type=ftcore:Profile&condition=employeeId == ${manager.employeeId}&depth=2`;
      this.http.get(url).subscribe((profiles: any) => {
        const profileWithImage: Profile = profiles.items.find(
          (profile) => profile.profileImage?.widenAsset
        );
        if (profileWithImage) {
          profileWithImage.profileImage.widenAsset = JSON.parse(
            profileWithImage.profileImage.widenAsset
          );
          manager.profile = profileWithImage;
        }
      });
    }
  }

  /**
   * sets layout type based on number of columns
   */
  setLayoutType(): void {
    combineLatest([
      this.hasColumnNo2$,
      this.investmentTeam$,
      this.fundDocuments$,
    ])
      .pipe(takeUntil(this.componentDestroyed$))
      .subscribe(([col2, col3r1, col3r2]) => {
        this.layoutType = 'layout-1';

        // we display 3 columns if 2nd column is present and InvestmentTeam or FundDocuments for col 3 are available.
        // TODO verify if this is called properly and how many times
        if (col2 && (col3r1.length || col3r2.length)) {
          this.layoutType = 'layout-2';
          this.hasColumnNo3$.next(true);
        }
        this.cd.markForCheck();
      });
  }

  /**
   * returns Products Config
   */
  getBrConfig() {
    return {
      componentType: 'Annualized', // TODO - verify this
      fundId: this.fundId,
      shareClassCode: this.shareClassCode,
    };
  }

  /**
   * redirects to fund page
   */
  goToFund(): void {
    this.navigationService.navigateByUrl(this.fundLink);
  }

  goToFundDocuments(): void {
    this.navigationService.navigateByUrl(this.fundLink + '#documents');
  }

  ngOnDestroy(): void {
    // unsubscribe from Observables
    this.componentDestroyed$.next(true);
    this.componentDestroyed$.complete();
  }
}
