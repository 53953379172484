<div
  [ngClass]="{ 'has-edit-button': isEditMode }"
  edsPadding
  [edsPaddingBottom]="padding"
  [edsPaddingTop]="padding"
  [edsSpacing]="marginBottom ? marginBottom : defaultMarginBottom"
>
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="new-RichText-document"
    folderTemplateQuery="new-RichText-folder"
    parameter="document"
    [relative]="true"
    root=""
  >
  </ng-container>

  <div edsGrid>
    <div edsRow>
      <div
        class="ck-editor"
        edsCol
        *ngIf="document && content && content.content"
        [innerHTML]="content.content.value | safeHtml"
      ></div>
    </div>
  </div>
  <div
    *ngIf="!document && isEditMode"
    edsGrid
    class="no-document no-document__component"
  >
    <div class="no-document__overlay">
      Click to configure <strong>Rich Text</strong>
    </div>
  </div>
</div>
