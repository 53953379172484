import dayjs from 'dayjs';
import enConfig from 'dayjs/locale/en';
import updateLocale from 'dayjs/plugin/updateLocale';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(updateLocale);
dayjs.extend(localeData);

dayjs.locale('en-os', { ...enConfig });
export const customEnOsConfig = dayjs.updateLocale('en-os', {
  ...enConfig,
  formats: {
    ...enConfig.formats,
    L: 'MM/DD/YYYY',
  },
});
