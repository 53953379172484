import { Input, Component, OnChanges, ChangeDetectorRef } from '@angular/core';
import {
  FundItem,
  FundThumbnail,
  QuickLinkItem,
} from '@search/interfaces/search.interface';
import { GeneralService } from './general.service';
import { ExactMatch } from '@search/interfaces/search.interface';
import { Logger } from '@utils/logger';

import { NavigationService } from '@frk/eds-components';

const logger = Logger.getLogger('SearchGeneralComponent');

@Component({
  selector: 'ft-search-general',
  templateUrl: './general.component.html',
  styleUrls: ['../../exact-match.component.scss', './general.component.scss'],
})
export class GeneralComponent implements OnChanges {
  @Input() exactMatch: ExactMatch;

  // default layout
  layoutType = 2;

  relatedPages: Array<FundItem> = [];
  fundsThumbnails: Array<FundThumbnail> = [];
  quickLinks: Array<QuickLinkItem> = [];

  sectionTitle: string;
  overview: string;
  fundViewMore: string;
  generalViewMore: string;
  latestContentTitle: string;

  hasRichtextColumn: boolean;
  hasFundsColumn: boolean;
  hasInsightsColumn: boolean;
  hasInsightsQuickLinksColumn: boolean;
  hasQuickLinksColumn: boolean;

  constructor(
    protected navigationService: NavigationService,
    public cd: ChangeDetectorRef,
    public generalDataService: GeneralService
  ) {
    logger.debug('processing general data');
  }

  ngOnChanges() {
    this.generalDataService.setExactResults(this.exactMatch);
    this.getGeneralData();
  }

  getGeneralData() {
    this.layoutType = this.generalDataService.getLayoutType();
    this.sectionTitle = this.generalDataService.getTitle();
    this.overview = this.generalDataService.getOverview();

    this.fundsThumbnails = this.generalDataService.getRelatedFunds();
    this.relatedPages = this.generalDataService.getRelatedPages();
    this.quickLinks = this.generalDataService.getQuickLinks();

    this.fundViewMore = this.generalDataService.getViewMoreFundsLink();
    this.generalViewMore = this.generalDataService.getViewMoreGeneralLink();

    this.hasRichtextColumn = this.generalDataService.hasColumn('Richtext');
    this.hasFundsColumn = this.generalDataService.hasColumn('Funds');
    this.hasInsightsColumn = this.generalDataService.hasColumn('Insights');
    this.hasQuickLinksColumn = this.generalDataService.hasColumn('Quicklinks');
    this.hasInsightsQuickLinksColumn = this.generalDataService.hasColumn(
      'Insights & Quicklinks'
    );

    this.latestContentTitle = this.generalDataService.getLatestContentTitle();

    this.cd.markForCheck();
  }

  /**
   * redirects user to required resource
   * @param panelId panel identifier - funds or generla
   */
  viewMore(panelId: 'fund' | 'general' | '') {
    if (panelId === 'fund') {
      this.navigationService.navigateByUrl(this.fundViewMore);
    }
    if (panelId === 'general') {
      this.navigationService.navigateByUrl(this.generalViewMore);
    }
  }
}
