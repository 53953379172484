<eds-panel
  [fullHeight]="true"
  [hasShadows]="true"
  [hasBorders]="true"
  [hasPadding]="true"
  paddingSize="sm"
>
  <div
    class="event-info"
    edsFlexbox
    flexDirection="column"
    justifyContent="space-between"
  >
    <eds-avatar
      [imgSrc]="profiles[0]?.profileThumbnail"
      [secondImgSrc]="profiles[1]?.profileThumbnail"
      [logoImgSrc]="event?.logoUrl"
      [displayBothImage]="displayTwoProfile"
      [displayLogo]="displayLogo"
      [title]="event?.eventTitle"
      [text1]="event?.label"
      stacked="true"
      edsSpacing="xs"
      size="lg"
    ></eds-avatar>
    <div class="event-info__body">
      <div [innerHTML]="event?.description"></div>
    </div>

    <div
      class="event-info__action"
      edsFlexbox
      edsPadding
      edsPaddingTop="sm"
      [justifyContent]="
        event?.links?.optionalLink ? 'justify-between' : 'flex-start'
      "
    >
      <a
        *ngIf="event?.links?.displayText"
        edsLink
        [edsButton]="'primary'"
        class="event-info__action-item event-info__action-item--primary"
        [href]="event?.links?.presentationLinkURL"
        [size]="'compact'"
        [attr.data-signin-required]="
          event.links?.signInLink | ftIsSignInRequired | async
        "
        [attr.data-signin-custom-intro]="event?.links?.signInIntroTextLabel"
        [attr.data-signin-custom-content-text]="
          event.links?.signInContentTextLabel
        "
        [class.external-link]="event.links?.external"
        [target]="event.links?.target"
        data-analytics-no-event="true"
      >
        <span>{{ event?.links?.displayText }}</span>
        <eds-icon
          *ngIf="event.links?.signInLink && !event?.links?.hideLockIcon"
          edsPadding
          edsPaddingLeft="xs"
          edsTooltip
          tooltipType="medium"
          tooltipPositionDefault="top"
          tooltipBehavior="fixed"
          size="small"
          type="lock"
        ></eds-icon>
      </a>

      <a
        *ngIf="event?.links?.optionalLink"
        edsLink
        [size]="'compact'"
        [edsButton]="'secondary'"
        [href]="event?.links.optionalLink?.optionalLinkUrl"
        [class.external-link]="event?.links?.optionalLink?.external"
        [target]="event?.links?.optionalLink?.target"
        data-analytics-no-event="true"
        [attr.data-signin-required]="
          event?.links.optionalLink?.signInLink | ftIsSignInRequired | async
        "
        [attr.data-signin-custom-intro]="
          event?.links.optionalLink?.signInIntroTextLabel
        "
        [attr.data-signin-custom-content-text]="
          event?.links?.optionalLink?.signInContentTextLabel
        "
        class="event-info__action-item event-info__action-item--secondary"
      >
        <span>{{ event?.links?.optionalLink?.displayText }}</span>
        <eds-icon
          *ngIf="
            event?.links?.optionalLink?.signInLink &&
            !event?.links?.optionalLink?.hideLockIcon
          "
          edsPadding
          edsPaddingLeft="xs"
          edsTooltip
          tooltipType="medium"
          tooltipPositionDefault="top"
          tooltipBehavior="fixed"
          size="small"
          type="lock"
        ></eds-icon>
      </a>
    </div>
  </div>
</eds-panel>
