import { SegmentId } from './segment.type';

export type CaveatCollection = {
  legals: any[];
  proximals: any[];
  footnotes: any[];
  alerts: any[] | any;
};

export type Alert = {
  segments?: SegmentId[];
  ppssMessage: string;
  ppssTooltip: string;
  fundTitleMessage?: string;
  accordionTitle: string;
  accordionContent: string;
  popupTitle: string;
  popupContent: string;
  popupCloseLabel: string;
  popupConfirmLabel: string;
  fl?: string[];
  fscl?: string[];
};

export type Alerts = { [propName: string]: Alert };
