import { Exchange } from '@models';
import {
  ConfigurationCountry,
  ConfigurationLocale,
  SEDOL,
  FundId,
  Bloomberg,
  Ticker,
  ExchangeDTO,
  ShareClassCode,
} from '@types';
import { MapperFactory } from './mapper-factory';
import { Mapper, MapperParams } from './type.mapper';

export class ExchangesMapper extends Mapper<Exchange[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(exchangesDto: ExchangeDTO[]): Exchange[] {
    const exchanges: Exchange[] = [];

    exchangesDto.map((exchangeDto: ExchangeDTO) => {
      const exchange: Exchange = this.createMapper(ExchangeMapper).toDomain(
        exchangeDto
      );

      if (exchange) {
        exchanges.push(exchange);
      }
    });

    return exchanges;
  }
}

export class ExchangeMapper extends Mapper<Exchange> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(exchangesDto: ExchangeDTO): Exchange {
    return {
      fundId: exchangesDto.fundid as FundId,
      shareClassCode: exchangesDto.shclcode as ShareClassCode,
      countryCode: exchangesDto.cntrycode as ConfigurationCountry,
      languageCode: exchangesDto.langcode as ConfigurationLocale,
      ticker: exchangesDto.ticker as Ticker,
      exchangeName: exchangesDto.exchngname,
      tradeCurrency: exchangesDto.tradecurr,
      bloomberg: exchangesDto.bloomberg as Bloomberg,
      reuters: exchangesDto.reuters,
      sedol: exchangesDto.sedol as SEDOL,
    };
  }
}
