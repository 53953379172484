<!-- Add image if asset is not a video -->
<img
  *ngIf="imgVideoAsset?.assetSrc && !imgVideoAsset?.isVideoAsset"
  [src]="imgVideoAsset?.assetSrc"
  [alt]="imgVideoAsset?.assetAltText"
  class="widen-img-video__asset"
/>
<!-- Video content if file extension is MP4 -->
<video
  *ngIf="imgVideoAsset?.assetSrc && imgVideoAsset?.isVideoAsset"
  class="widen-img-video__asset"
  disablePictureInPicture
  controls
  controlsList="nodownload nofullscreen noduration nosubtitles noplaybackrate noping"
  onloadedmetadata="this.muted = true"
  playsinline
  autoplay
  muted
  loop
>
  <source
    [src]="imgVideoAsset?.assetSrc"
    [type]="'video/' + imgVideoAsset?.assetType"
  />
  <ng-container
    *ngIf="
      imgVideoAsset.tarnscriptionTracks &&
      imgVideoAsset.tarnscriptionTracks.length > 0
    "
  >
    <track
      *ngFor="let track of imgVideoAsset.tarnscriptionTracks"
      [label]="track.trackLabel"
      kind="captions"
      [srclang]="track.trackLanguage"
      [src]="track.trackSrc"
      default
    />
  </ng-container>
  <!-- label for browsers without video support -->
  {{ "common.video-not-supported" | translate }}
</video>
