<div edsGrid [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    documentTemplateQuery="new-Staggered-box"
    folderTemplateQuery="new-Staggered-box-folder"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
</div>
<div
  *ngIf="staggeredBoxesContent"
  [id]="staggeredBoxesContent.sectionId"
  [edsSpacing]="marginBottom === 'auto' ? 'xl' : marginBottom"
>
  <eds-staggered-boxes
    [hasSupportTextLeft]="supportTextLeft"
    [hasSupportTextRight]="supportTextRight"
    [hasFourBoxes]="showfourBoxes"
    [pretitle]="staggeredBoxesContent.preTitle"
    [primaryBtnText]="
      staggeredBoxesContent?.link?.linkCollection[0]?.displayText
    "
    [primaryBtnUrl]="staggeredBoxesContent?.link?.linkCollection[0]?.url"
    [primaryBtnExternal]="
      staggeredBoxesContent?.link?.linkCollection[0]?.external
    "
    [primaryBtnExternalSuffix]="
      staggeredBoxesContent?.link?.linkCollection[0]?.externalSuffix
    "
    [primaryBtnTarget]="staggeredBoxesContent?.link?.linkCollection[0]?.target"
    [isSignInRequired]="
      staggeredBoxesContent?.link?.linkCollection[0]?.signInLink
        | ftIsSignInRequired
        | async
    "
    [signInCustomIntro]="
      staggeredBoxesContent?.link?.linkCollection[0]?.signInIntroTextLabel
    "
    [signInCustomContentText]="
      staggeredBoxesContent?.link?.linkCollection[0]?.signInContentTextLabel
    "
    [hasHideLockIcon]="
      staggeredBoxesContent?.link?.linkCollection[0]?.hideLockIcon
    "
    [signInTooltip]="'common.locked-link-tooltip' | translate"
    [trackImpression]="
      staggeredBoxesContent?.link?.linkCollection[0]?.enableImpressionTracking
    "
    [trackDownloads]="
      staggeredBoxesContent?.link?.linkCollection[0]?.enableDownloadTracking
    "
    [title]="staggeredBoxesContent.title"
    [summary]="staggeredBoxesContent.summary"
    [numberOne]="staggeredBoxesContent.cards[0]?.preTitle"
    [headingOne]="staggeredBoxesContent.cards[0]?.title"
    [bodyOne]="staggeredBoxesContent.cards[0]?.content"
    [numberTwo]="staggeredBoxesContent.cards[1]?.preTitle"
    [headingTwo]="staggeredBoxesContent.cards[1]?.title"
    [bodyTwo]="staggeredBoxesContent.cards[1]?.content"
    [numberThree]="staggeredBoxesContent.cards[2]?.preTitle"
    [headingThree]="staggeredBoxesContent.cards[2]?.title"
    [bodyThree]="staggeredBoxesContent.cards[2]?.content"
    [numberFour]="staggeredBoxesContent.cards[3]?.preTitle"
    [headingFour]="staggeredBoxesContent.cards[3]?.title"
    [bodyFour]="staggeredBoxesContent.cards[3]?.content"
    [version]="theme ? theme : 'primary'"
    (linkClick)="
      setDownloadLocation(
        staggeredBoxesContent?.link?.linkCollection[0]?.downloadLocation
      )
    "
  >
  </eds-staggered-boxes>
</div>

<!-- no document -->
<div *ngIf="!staggeredBoxesContent && isEditMode" class="no-document">
  <div edsGrid class="no-document__component">
    <div class="no-document__overlay">
      Click to configure <strong>Staggered Content</strong>
    </div>
  </div>
</div>
