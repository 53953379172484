<!-- <ft-add-to-watchlist-modal></ft-add-to-watchlist-modal> -->
<div
  class="subscription-products"
  [class]="params?.addPadding ? 'subscription-products--padding' : ''"
>
  <div edsPadding edsPaddingBottom="md">
    <button
      edsButton="primary"
      (click)="addToWatchlist()"
      size="compact"
      type="button"
    >
      {{ "subscriptions.add-to-watchlist" | translate }}
    </button>
  </div>
  <form
    #subscriptionForm="ngForm"
    *ngIf="rowData?.length > 0"
    class="subscription-products__form-data"
    [class.subscription-products__form-data--disabled]="isLoading"
  >
    <div edsRow edsSpacing="md">
      <div edsCol>
        <ag-grid-angular
          #subscriptionProductsGrid
          class="ag-theme-frk subscription-products__ag-full-size"
          stickyHeader
          [rowData]="rowData"
          [columnDefs]="columnDefs"
          [defaultColDef]="defaultColDef"
          [multiSortKey]="'ctrl'"
          [frameworkComponents]="frameworkComponents"
          [pagination]="false"
          [suppressHorizontalScroll]="true"
          [suppressRowClickSelection]="true"
          [getRowHeight]="getRowHeight"
          domLayout="autoHeight"
          [suppressRowHoverHighlight]="true"
          [suppressCellSelection]="false"
          [ensureDomOrder]="true"
          [cacheQuickFilter]="true"
          (firstDataRendered)="onFirstDataRendered($event)"
          (gridReady)="onGridReady($event)"
          [enableCellTextSelection]="true"
          overlayNoRowsTemplate="<p class='subscription-products__no-rows'> {{
            'subscriptions.no-data' | translate
          }}</p>"
          overlayLoadingTemplate="<p class='subscription-products__no-rows'> {{
            'subscriptions.load-data' | translate
          }}"
        >
        </ag-grid-angular>
      </div>
    </div>
  </form>
</div>
