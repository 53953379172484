import { Component, Input, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Link, WidenAsset } from '@types';
import { AppStateService, WidenService } from '@services';
import { LinkService } from '@services/link.service';
import uniqBy from 'lodash/uniqBy';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import {
  ChipFilter,
  DropdownItem,
  SelectedFilter,
  Chip,
} from '@frk/eds-components';
import {
  AdditionalEventFilter,
  Events,
  Filter,
  ProfileItem,
  Speaker,
} from './../events.type';
import { TranslateService } from '@shared/translate/translate.service';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';

@Component({
  selector: 'ft-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  @Input() component!: BrComponent;
  @Input() page!: Page;

  public filters: Filter[] = [
    {
      name: 'breakdownSession',
      title: '', // Should be dynamically set
      label: '', //  Should be dynamically set
      selectedText: 'selected',
      items: [],
      showCount: false,
      linkUrl: 'https://www.franklintempleton.com/articles',
      alignment: 'right',
    },
    {
      name: 'speaker',
      title: this.translateService.instant('common.title-speaker'),
      label: this.translateService.instant('common.title-speaker'),
      selectedText: 'selected',
      items: [],
      showCount: false,
      linkUrl: 'https://www.franklintempleton.com/articles',
      // alignment: 'right',
    },
    {
      name: 'assetClass',
      title: this.translateService.instant('common.title-asset-class'),
      label: this.translateService.instant('common.title-asset-class'),
      selectedText: 'selected',
      items: [],
    },
  ];

  public events: Events[];
  public filteredEvents: Events[] = [];
  public speakers: Speaker[] = [];
  public chipsFilters: ChipFilter[] = [];
  public showFilters = false;
  private chipMap: Map<string, boolean>;

  constructor(
    private widenService: WidenService,
    private linkService: LinkService,
    private appStateService: AppStateService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.showFilters = !(this.component?.getParameters()?.hideFilters ?? false);
    this.events = this.getEvents();
    if (this.showFilters) {
      this.setFilterData();
    }
    this.filteredEvents = this.events;
    // get valid filter categories
    this.filters = this.filters?.filter(
      (eventFilter) => eventFilter?.items?.length
    );
  }

  private setFilterData(): void {
    let assetClasses = [];
    let speakers = [];
    let breakdownSessions = [];

    this.events?.forEach((event) => {
      if (event.assetClass) {
        assetClasses.push(event.assetClass);
      }
      event.speakers?.forEach((speaker) => speakers.push(speaker));
      if (event.additionalEventFilter) {
        breakdownSessions.push(event.additionalEventFilter);
      }
    });
    // Set Breakdown Session Title as this can be dynamic
    this.filters[0].title = this.events[0]?.additionalEventFilter?.title;
    this.filters[0].label = this.events[0]?.additionalEventFilter?.title;

    assetClasses = this.assetClassFilters(uniqBy(assetClasses));
    speakers = sortBy(speakers, (user) => {
      return user.label;
    });
    speakers = this.speakerFilters(uniqBy(speakers, 'value'));
    breakdownSessions = this.additionalFilters(
      uniqBy(breakdownSessions, 'value')
    );

    this.filters = this.filters.map((filter) => {
      if (filter?.name === 'speaker') {
        filter.items = speakers;
      } else if (filter?.name === 'assetClass') {
        filter.items = assetClasses;
      } else if (filter?.name === 'breakdownSession') {
        filter.items = breakdownSessions;
      }
      return filter;
    });
  }

  private speakerFilters(speakers: any[]): DropdownItem[] {
    return speakers.map((speaker) => {
      return {
        label: speaker.label,
        count: '',
        checked: false,
        value: speaker?.value?.trim().toLowerCase(),
        id: 'option-' + speaker?.value?.trim().toLowerCase(),
      };
    });
  }

  private assetClassFilters(assetClasses: string[]): DropdownItem[] {
    return assetClasses.map((assetClass) => {
      return {
        label: startCase(assetClass),
        count: '',
        checked: false,
        value: assetClass.trim(),
        id: 'option-' + assetClass.trim(),
      };
    });
  }

  private additionalFilters(
    additionalFilters: AdditionalEventFilter[]
  ): DropdownItem[] {
    return additionalFilters.map((additionalFilter: AdditionalEventFilter) => {
      return {
        label: additionalFilter?.value,
        count: '',
        checked: false,
        value: additionalFilter?.value,
        id:
          'option-' +
          additionalFilter?.value?.trim().toLowerCase().replace(/\s+/g, '-'),
      };
    });
  }

  /**
   * Get Content from BR
   */
  private getContentData() {
    const { document } = this.component.getModels<DocumentModels>();
    const content = document && this.page.getContent(document);
    return content?.getData();
  }

  private getSpeakers(speakers: any[]): Speaker[] {
    const allSpeakers: Speaker[] = [];
    speakers?.forEach((speaker) => {
      if (speaker?.profileRef) {
        allSpeakers.push(this.getSpeakerDetails(speaker?.profileRef));
      }
    });
    return allSpeakers;
  }
  /**
   * Get Profile details
   */
  private getSpeakerDetails(profileRef): Speaker {
    const profileData: ProfileItem = this.page
      .getContent(profileRef)
      .getData<ProfileItem>();

    // Process widen url
    if (profileData?.profileImage) {
      profileData.profileImageUrl = this.widenService.getWidenImageVariantUrl(
        this.widenService.getWidenAssetUrl(
          profileData.profileImage?.widenAsset
        ),
        'original',
        'webp'
      );
    }

    return {
      label: profileData.fullName,
      profileThumbnail: profileData.profileImageUrl,
      value: profileData.name,
    };
  }

  /**
   * Get Session Links
   */
  public getSessionLinks = (linkRef): Link => {
    let sessionLinks: any = {};
    if (linkRef) {
      linkRef.forEach((link) => {
        if (link.buttonStyle !== 'secondary') {
          sessionLinks = link.linkCompound.linkCollection[0];
          if (sessionLinks) {
            sessionLinks.presentationLinkURL = this.linkService?.getCTALink(
              this.page,
              link.linkCompound.linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        } else {
          sessionLinks.optionalLink = link.linkCompound.linkCollection[0];
          if (sessionLinks) {
            sessionLinks.optionalLink.optionalLinkUrl = this.linkService?.getCTALink(
              this.page,
              link.linkCompound.linkCollection[0],
              this.appStateService?.getSpaBaseUrl()
            );
          }
        }
      });
    }

    return sessionLinks;
  };

  /**
   * Get Events from content
   */
  public getEvents(): Events[] {
    const rawEvents =
      this.getContentData()?.sessions?.map((session) => {
        const speakers: Speaker[] = this.getSpeakers(session.speaker);
        if (session?.additionalEventFilter) {
          return {
            eventTitle: session.title,
            description: session.summary.content,
            speakers,
            links: this.getSessionLinks(session.button),
            assetClass: session.assetClass[0],
            additionalEventFilter: {
              title: session?.additionalEventFilter?.filterTitle,
              value: session?.additionalEventFilter?.filterValue,
            },
            logoUrl: this.widenService.getWidenAssetUrl(
              this.getLogo(session.imageLogo)?.widenDocument
            ),
            label: this.getLabels(speakers),
          };
        }

        return {
          eventTitle: session.title,
          description: session.summary.content,
          speakers,
          links: this.getSessionLinks(session.button),
          assetClass: session.assetClass[0],
          logoUrl: this.widenService.getWidenAssetUrl(
            this.getLogo(session.imageLogo)?.widenDocument
          ),
          label: this.getLabels(speakers),
        };
      }) || [];

    return rawEvents;
  }

  private getLabels(speakers: Speaker[]): string {
    return speakers
      .map((speaker) => {
        return speaker?.label;
      })
      .join(' & ');
  }

  private getLogo(imageLogo: any): WidenAsset {
    return imageLogo.find((logo) =>
      this.widenService.getWidenAssetUrl(logo?.widenDocument)
    );
  }

  public addChips(): ChipFilter[] {
    this.chipMap = new Map();
    const item = this.filters
      .map((filter) => {
        return {
          ...filter,
          items: filter.items.filter((filterItem) => filterItem.checked),
        };
      })
      ?.filter((filter) => {
        return filter?.items?.length > 0;
      })
      .map((filter) => {
        this.chipMap.set(filter.name, true);
        return {
          chips: filter.items.map((filterItem) => ({
            label: filter.title, // Mapping Filter Title to Chip Label
            value: filterItem.label, // Mapping filter item label to value
          })),
        };
      });
    return item;
  }

  public onSelectFilter(event: SelectedFilter): void {
    this.chipsFilters = this.addChips();
    this.updateFilteredEvent();
  }

  private updateFilteredEvent(): void {
    let speakerFilterSet: Set<string>;
    let assetClassFilterSet: Set<string>;
    let breakdownSessionFilterSet: Set<string>;

    this.filters?.forEach((filter) => {
      if (filter?.name === 'speaker') {
        speakerFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'assetClass') {
        assetClassFilterSet = this.buildFilterSet(filter?.items);
      } else if (filter?.name === 'breakdownSession') {
        breakdownSessionFilterSet = this.buildFilterSet(filter?.items);
      }
    });

    this.filteredEvents = [
      ...this.events.filter((event) => {
        const isAssetClassValid =
          !this.chipMap.get('assetClass') ||
          !assetClassFilterSet ||
          assetClassFilterSet.has(event.assetClass);
        const areSpeakersValid =
          !this.chipMap.get('speaker') ||
          event.speakers?.some((speaker) =>
            speakerFilterSet.has(speaker?.value)
          );

        if (event.additionalEventFilter) {
          const isBreakdownSessionValid =
            !this.chipMap.get('breakdownSession') ||
            breakdownSessionFilterSet.has(event.additionalEventFilter?.value);
          return (
            isAssetClassValid && areSpeakersValid && isBreakdownSessionValid
          );
        }
        return isAssetClassValid && areSpeakersValid;
      }),
    ];
  }

  private buildFilterSet(filters: DropdownItem[]): Set<string> {
    const checkedFilters = filters?.filter((filter) => filter?.checked);
    if (checkedFilters?.length === 0) {
      return new Set(filters?.map((filter) => filter?.value));
    } else {
      return new Set(checkedFilters?.map((filter) => filter?.value));
    }
  }

  private resetEventToDefault(): void {
    this.filteredEvents = this.events;
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: filter?.items?.map((filterItem) => {
          return {
            ...filterItem,
            checked: false,
          };
        }),
      };
    });
    this.chipsFilters = [];
  }

  public resetFilter($event: any): void {
    this.resetEventToDefault();
  }

  public chipRemoved(chip: Chip): void {
    const removedFilterGroup = this.filters.find(
      (filter) => filter.title === chip.label
    );
    if (removedFilterGroup) {
      removedFilterGroup?.items?.forEach((item) => {
        if (item?.label === chip?.value) {
          item.checked = false;
        }
      });
      if (!removedFilterGroup?.items?.find((item) => item.checked)) {
        this.chipMap.delete(removedFilterGroup?.name);
      }
    }
    this.filters = this.filters.map((filter) => {
      return {
        ...filter,
        items: [...filter.items],
      };
    });
    this.chipsFilters = this.chipsFilters.filter(
      (chipFilter) => chipFilter?.chips?.length > 0
    );
    this.updateFilteredEvent();
  }
}
