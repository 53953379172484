<div>
  <eds-title
    size="h2"
    theme="primary"
    edsSpacing="lg"
    [isLight]="true"
    [title]="sectionTitle"
  >
  </eds-title>
  <div>
    <div edsCol edsSubGrid="layout-{{ layoutType }}" edsSubGridGap="lg">
      <div class="column-1" *ngIf="hasRichtextColumn">
        <eds-title
          size="h4"
          theme="primary"
          edsSpacing="md"
          title="{{ 'common.overview' | translate }}"
        >
        </eds-title>
        <div [innerHTML]="overview"></div>
      </div>
      <div
        class="column-2"
        *ngIf="
          hasInsightsColumn ||
          hasQuickLinksColumn ||
          hasInsightsQuickLinksColumn
        "
      >
        <eds-search-panel
          [leftTitle]="latestContentTitle"
          viewMoreBtnText="{{ 'common.view-more-results' | translate }}"
          [panelId]="generalViewMore !== '' ? 'general' : ''"
          (viewMoreTrigger)="viewMore('general')"
        >
          <ng-container *ngFor="let fund of fundsThumbnails">
            <eds-thumbnail
              class="exact-match__search-thumbnail"
              [hasBorders]="false"
              [hasShadows]="false"
              [headingLevel]="2"
              [layoutCompact]="true"
              [docTypeSrc]="fund.docTypeSrc"
              [title]="fund.title"
              [titleLink]="fund.titleLink"
              [body]="fund.body"
              [hasSearchIcon]="true"
              [noTitleColor]="true"
            >
            </eds-thumbnail>
          </ng-container>

          <ng-container *ngFor="let quickLink of quickLinks">
            <eds-thumbnail
              class="exact-match__search-thumbnail"
              [hasBorders]="false"
              [hasShadows]="false"
              [headingLevel]="2"
              [layoutCompact]="true"
              [title]="quickLink.title"
              [body]="quickLink.subTitle"
              [titleLink]="quickLink.url"
              [docTypeSrc]="quickLink.docTypeSrc"
              [hasSearchIcon]="true"
              [noTitleColor]="true"
            >
            </eds-thumbnail>
          </ng-container>
        </eds-search-panel>
      </div>
      <div class="column-3" *ngIf="hasFundsColumn">
        <div>
          <eds-search-panel
            leftTitle="{{ 'common.funds' | translate }}"
            viewMoreBtnText="{{ 'common.view-more-fund' | translate }}"
            [panelId]="fundViewMore !== '' ? 'fund' : ''"
            (viewMoreTrigger)="viewMore('fund')"
          >
            <ng-container *ngFor="let page of relatedPages">
              <eds-thumbnail-search
                class="exact-match__search-thumbnail"
                [hasBorders]="false"
                [hasShadows]="false"
                [headingLevel]="2"
                [layoutCompact]="true"
                [fundFamily]="page.fundFamily"
                [fundName]="page.fundName"
                [titleLink]="page.fundLink"
              >
              </eds-thumbnail-search>
            </ng-container>
          </eds-search-panel>
        </div>
      </div>
    </div>
  </div>
</div>
