import { Page, Component } from '@bloomreach/spa-sdk';
import { PortfolioHedgeManagerAllocations } from '@models';
import { PortfolioHoldingData } from '@products/portfolio/portfolio-holdings/portfolio-holdings.component';
import { BarChartData } from '@types';
import { StatisticsAndPositionsData } from '.';
import { ProductDetailConfiguration } from './configuration.type';

export type PortfolioDisplayData =
  | BarChartData[]
  | StatisticsAndPositionsData
  | PortfolioHoldingData
  | PortfolioHedgeManagerAllocations;

export interface PortfolioDisplay {
  data: PortfolioDisplayData;
  processData?: () => void;
  resizeChart?: () => void;
  initialize(
    config: ProductDetailConfiguration,
    component: Component,
    page: Page
  ): void;
}

export interface PortfolioDataResponse {
  success: boolean; // false if component suppressed or no holdings
  payload?: PortfolioDisplayData; // should be present if success === true
  message?: string; // should be present if success === false, explaining why e.g. "Chart suppressed" or "no data"
}

export interface PortfolioDownloadableTableRowData {
  isinSecurityNumber: string;
  securityName: string;
  quantityShrpar: string;
  originalCouponRate: string;
  percentageOfNetAssets: string;
  marketValue: string;
  notionalMarketValue: string;
  securityExpirationDate: string;
  assetClassCategory: string;
  marketCurrency: string;
  contracts: string;
}

export interface PortfolioMoneyFundDownloadableTableRowData {
  securityName?: string;
  countryOfIssue?: string;
  holdingValue?: string;
  holdingValueStd?: string;
  maturityDate?: string;
  maturityDateStd?: string;
  assetType?: string;
  assetTypeStd?: string;
}
