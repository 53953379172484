<main edsSpacing="md">
  <ng-container brComponent="lead-content"></ng-container>

  <!-- set z-index: 4; so sticky tabs are above ag grids -->
  <div [style]="!isEditMode ? 'position: sticky; top: 0; z-index: 4' : ''">
    <ng-container brComponent="sticky-content"></ng-container>
  </div>

  <div>
    <ng-container brComponent="body-content"></ng-container>
  </div>

  <ng-container>
    <div edsRow>
      <div edsCol edsSpacing="md">
        <div class="layout-e-vertical-tabs-sidebar__grid-responsive">
          <div
            class="layout-e-vertical-tabs-sidebar__grid-responsive__main-content"
          >
            <ng-container brComponent="main-content"></ng-container>
          </div>
          <div
            class="layout-e-vertical-tabs-sidebar__grid-responsive__right-rail"
          >
            <ng-container brComponent="right-rail"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>

  <div edsGrid *ngIf="page && (tabs?.length > 0 || isEditMode)">
    <div class="container" [class.container--wide]="isWide">
      <div edsRow>
        <!-- If preview -->
        <div
          *ngIf="page && isEditMode"
          class="layout-e-vertical-tabs-sidebar__tab-area"
          edsSpacing="xl"
        >
          <ng-container
            *ngFor="let tab of tabs; let i = index"
            brComponent="{{ tabArea + tabIndex[i] }}"
          ></ng-container>
        </div>
        <!-- Not preview -->
        <eds-tabs
          edsCol
          *ngIf="page && !isEditMode"
          [followFocus]="true"
          [isNavigation]="false"
          [cacheActive]="true"
          [fullWidth]="true"
          dropdownTheme="handheld"
        >
          <ng-container *ngFor="let tab of tabs; let i = index">
            <eds-tab
              *ngIf="tab"
              heading="Tab"
              [heading]="tab?.label"
              [tabId]="tab?.labelID"
            >
              <ng-container
                brComponent="{{ tabArea + tabIndex[i] }}"
              ></ng-container>
            </eds-tab>
          </ng-container>
        </eds-tabs>
        <!-- / Not preview -->
      </div>
    </div>
  </div>

  <div>
    <ng-container *ngIf="!isLowerContentWide" brComponent="lower-content">
    </ng-container>
  </div>
  <div *ngIf="isLowerContentWide" edsGrid edsSpacing="xl">
    <div class="container container--wide">
      <ng-container brComponent="lower-content"></ng-container>
    </div>
  </div>
</main>
