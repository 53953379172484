import { ProductDetailConfiguration } from '@types';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Component, OnInit } from '@angular/core';

export enum ModalId {
  Morningstar_Modal = 'morningstarModal',
  Profile_Modal = 'profileModal',
}

@Component({
  template: ``,
})
export abstract class BaseProductDetailComponent
  extends AbstractBaseComponent
  implements OnInit {
  brConfig: ProductDetailConfiguration;
  component: BrComponent;
  page: Page;

  protected constructor() {
    super();
  }

  ngOnInit(): void {
    this.setBreadcrumbItems();
  }

  public initialize(
    config: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.brConfig = config;
    this.component = component;
    this.page = page;
  }
}
