import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvestmentTeam, Product, Profile } from '@models';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { TranslateService } from '@shared/translate/translate.service';
import {
  ConfigurationId,
  FundManagementState,
  ProductDetailConfiguration,
} from '@types';
import { Logger } from '@utils/logger';
import { forkJoin, Observable, Subject } from 'rxjs';
import fundManagementQuery from '@graphql/overview/fund-management.graphql';
import { SiteConfigService } from '@services/site-config.service';
import { removePTags } from '@utils/text/string-utils';

const logger = Logger.getLogger('FundManagementService');

type ProfileRequests = {
  english: Observable<any>;
  localized?: Observable<any>;
};

@Injectable({
  providedIn: 'root',
})
export class FundManagementService {
  private state: FundManagementState = {};
  private configurationName: ConfigurationId;
  private fundManagementStateSubject$: Subject<FundManagementState>;

  constructor(
    private overviewService: FundOverviewService,
    private translateService: TranslateService,
    private http: HttpClient,
    private siteConfigService: SiteConfigService
  ) {
    this.fundManagementStateSubject$ = new Subject();
  }

  public get fundManagementState(): Subject<FundManagementState> {
    return this.fundManagementStateSubject$;
  }

  /**
   * Call register method of fund overview service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    // Provided dummy fund details to fetch fund overview details.
    this.overviewService
      .register(fundManagementQuery, {
        configurationName: this.getConfigurationName(),
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      })
      .subscribe((product: Product) => {
        if (product) {
          this.mapState(product);
        }
      });
  }

  mapState(product: Product) {
    this.state.data = {
      // NGC-12770: update visibility condition for fund manager bio link.
      showLinkToBios: this.siteConfigService.isShowLinkToBiosStatusValidForFundManagers(
        product.productTaxonomy
      ),
      showYearsOfExperience: this.siteConfigService.isShowYearsOfExperienceValidForFundManagers(
        product.productTaxonomy
      ),
      investmentTeam: product.investmentTeam,
    };
    this.state.data.investmentTeam.forEach((manager) => {
      this.loadProfile(manager);
    });
    this.fundManagementStateSubject$.next(this.state);
  }

  // Interim solution until Fund manager -> funds  linkage can be stored in BR
  loadProfile(manager: InvestmentTeam) {
    if (manager?.employeeId) {
      const url = `/rest/documents?name=Profile&param=${manager.employeeId}`;
      let localizedUrl;

      switch (this.siteConfigService.getPdsLanguage()) {
        case 'pl_PL':
          localizedUrl = `/rest/documents?name=PolishProfile&param=${manager.employeeId}`;
          break;
        case 'es_MX':
          localizedUrl = `/rest/documents?name=SpanishProfile&param=${manager.employeeId}`;
          break;
        default:
        // do nothing
      }

      const reqs: ProfileRequests = { english: this.http.get(url) };
      if (localizedUrl) {
        reqs.localized = this.http.get(localizedUrl);
      }

      forkJoin(reqs).subscribe((profiles: any) => {
        let profileWithImage: Profile;

        if (profiles.localized?.items?.length) {
          // use localizied version if it exists
          profileWithImage = profiles?.localized?.items.find(
            (profile) => profile.profileImage
          );
        } else {
          profileWithImage = profiles?.english?.items.find(
            (profile) => profile?.profileImage
          );
        }
        if (profileWithImage) {
          let newContent = '';
          profileWithImage.profileImage.widenAsset = profileWithImage
            ?.profileImage?.widenAsset
            ? JSON.parse(profileWithImage?.profileImage?.widenAsset)
            : '';
          manager.profile = profileWithImage;
          // NGC-12885: Remove p tags from content, so that empty profiles don't open Fund Manager profile
          newContent = removePTags(
            profileWithImage?.content?.content as string
          );
          if (newContent.length === 0) {
            profileWithImage.content.content = newContent;
          }
          this.fundManagementStateSubject$.next(this.state);
        }
      });
    }
  }

  /**
   *  helper methof adding suffix to managers name
   * @param investmentManager manager entity
   */
  getManagerTitleSuffix(investmentManager: InvestmentTeam): string {
    if (investmentManager.cfa === 'true') {
      return this.translateService.instant('products.cfa');
    } else if (investmentManager.cpa === 'true') {
      return this.translateService.instant('products.cpa');
    } else if (investmentManager.mba === 'true') {
      return this.translateService.instant('products.mba');
    } else if (investmentManager.phd === 'true') {
      return this.translateService.instant('products.phd');
    } else if (investmentManager.frm === 'true') {
      return this.translateService.instant('products.frm');
    } else {
      return '';
    }
  }

  setConfigurationName(configurationName: ConfigurationId) {
    this.configurationName = configurationName;
  }

  getConfigurationName(): ConfigurationId {
    return this.configurationName;
  }
}
