import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { AppStateService } from '@services/app-state.service';
import {
  ACCOUNTS_OFFLINE_FP,
  ACCOUNTS_OFFLINE_SH,
  BLOCK_DASHBOARD_SYSTEM,
  BLOCK_DASHBOARD_USER,
  ENDPOINT,
  FP,
  NO_ACCESS_FP,
  NO_ACCESS_SH,
  SH,
  TRUE,
  UPGRADE_ACCESS_ROUTE,
  UPGRADE_FORM_FP,
  UPGRADE_FORM_SH,
  UPGRADE_NOTNOW,
  NEVER,
  FPGROUP,
  NEVER_FLAG,
  UPGRADE_MESSAGE_FP,
  DONT_REMIND,
  FP_DASHBOARD,
  SH_DASHBIARD,
  NEW_ACCOUNT,
  API_ERROR,
  OPERATION_FAILED,
  USER_GROUP,
  MRK_CONTENT,
} from '@utils/app.constants';
import { Observable } from 'rxjs';
import { AlertService } from './alert.service';
import { Logger } from '@utils/logger';
import { StorageService } from '@services/storage.service';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  AlertObj,
  UpgradePayload,
} from '@accounts/accounts-alerts/accounts-alerts.interface';
import { ProfileService } from '@services/profile.service';
import {
  AccountsAccess,
  IUserProfile,
  IUserProfileInfo,
  ProfileSummary,
} from '@services/profile.interface';
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'If-Modified-Since': '0',
  }),
  withCredentials: true,
  observe: 'response' as 'response',
};
const logger = Logger.getLogger('NotificationService');
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  constructor(
    @Inject(WINDOW) readonly windowRef: Window,
    private appStateService: AppStateService,
    private http: HttpClient,
    private alertService: AlertService,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private profileService: ProfileService
  ) {}
  /**
   * Function to check on load applicaion,
   * the remindUpgrade flag is true or not and
   * show or hide the upgarde notifications
   */
  checkUpgradeOnLoad(profileData: IUserProfile) {
    if (profileData) {
      const profile = profileData;
      this.storageService.isSet(UPGRADE_NOTNOW).then((isSet: boolean): void => {
        this.storageService
          .retrieve<string>(USER_GROUP, true)
          .then((userGroup: string) => {
            if (
              profile.isLoggedIn &&
              profile.profileInfo.remindUpgrade &&
              profile.profileInfo.accountsAccess ===
                AccountsAccess.UPGRADABLE &&
              profile.profileInfo.role === FP &&
              userGroup?.includes(MRK_CONTENT) &&
              !isSet
            ) {
              const notifyPayload: any = {
                notify: true,
                type: UPGRADE_FORM_FP,
              };
              logger.info('checkUpgradeOnLoad: ' + notifyPayload.type);
              this.alertService.setAccountsAlert(notifyPayload);
              // upgrade-form-fp
            }
          });
      });
    }
  }
  /**
   * Function to check on click client accounts,
   * the accountsAccess property is access or not and
   * show the notifications with corresponding info.
   */
  checkUpgardeOnClick(event: any) {
    this.profileService.getUserProfile$().subscribe((profileData) => {
      if (profileData && profileData?.isLoggedIn) {
        logger.info('profileInfo: ' + profileData);
        const profile = profileData.profileInfo;
        const notifyPayload: any = {
          notify: true,
          type: '',
        };
        logger.info(
          'checkUpgardeOnClick.accountsAccess: ' + profile.accountsAccess
        );
        if (profile.accountsAccess === AccountsAccess.SYSTEM_BLOCKED) {
          notifyPayload.type = BLOCK_DASHBOARD_SYSTEM;
          event.preventDefault();
          // block-dashboard-system
        } else if (profile.accountsAccess === AccountsAccess.USER_BLOCKED) {
          notifyPayload.type = BLOCK_DASHBOARD_USER;
          event.preventDefault();
          // block-dashboard-user
        } else if (profile.accountsAccess === AccountsAccess.OFFLINE) {
          if (profile.role === SH) {
            notifyPayload.type = ACCOUNTS_OFFLINE_FP;
            // accounts-offline-fp
          } else {
            notifyPayload.type = ACCOUNTS_OFFLINE_SH;
            // accounts-offline-sh
          }
          event.preventDefault();
        } else if (profile.accountsAccess === AccountsAccess.ACCESS) {
          // if (profile.dashboardUrl) {
          //   this.windowRef.location.href = profile.dashboardUrl;
          // }
        } else if (profile.accountsAccess === AccountsAccess.NO_ACCESS) {
          if (profile.role === SH) {
            notifyPayload.type = NO_ACCESS_SH;
            // no-access-sh
          } else {
            notifyPayload.type = NO_ACCESS_FP;
            // no-access-fp
          }
          event.preventDefault();
        } else if (profile.accountsAccess === AccountsAccess.UPGRADABLE) {
          this.storageService
            .retrieve<string>(USER_GROUP, true)
            .then((userGroup: string) => {
              if (userGroup?.includes(MRK_CONTENT)) {
                this.storageService
                  .isSet(UPGRADE_NOTNOW)
                  .then((isSet: boolean): void => {
                    logger.info('isSet ' + isSet);
                    if (!isSet) {
                      if (profile.role === FP) {
                        notifyPayload.type = profile?.remindUpgrade
                          ? UPGRADE_FORM_FP
                          : DONT_REMIND;
                        logger.info(
                          'notifyPayload.type: ' + notifyPayload.type
                        );
                        // upgrade-form-fp
                      } else {
                        notifyPayload.type = UPGRADE_FORM_SH;
                        // upgrade-form-sh
                      }
                    } else {
                      notifyPayload.type = DONT_REMIND;
                      // dont-remind-me
                    }
                    this.alertService.setAccountsAlert(notifyPayload);
                  });
                event.preventDefault();
              }
            });
        }
        logger.info('notifyPayload: ' + notifyPayload.type);
        this.alertService.setAccountsAlert(notifyPayload);
      }
    });
  }
  /**
   * Function for trigger notnow button in upgarde notification
   */
  notNow() {
    this.storageService.store<string>(UPGRADE_NOTNOW, TRUE);
    this.alertService.showAccountsAlert = false;
    this.alertService.clearAccountsAlert();
  }
  /**
   * Function for trigger open a new account page
   */
  newAccount() {
    this.alertService.showAccountsAlert = false;
    this.alertService.clearAccountsAlert();
    this.windowRef.location.href = `${this.appStateService.getProfileDomain()}${NEW_ACCOUNT}`;
  }
  /**
   * Function for trigger never and upgrade button in upgarde notification
   */
  upgrade(flag: string) {
    let isProfileInfoAvailable = false;
    this.profileService.getUserProfile$().subscribe((profileRes) => {
      let profileInfo: IUserProfileInfo;
      let profile: IUserProfile;
      logger.info('isProfileInfoAvailable: ' + isProfileInfoAvailable);
      if (!isProfileInfoAvailable) {
        isProfileInfoAvailable = true;
        if (profileRes) {
          profile = profileRes;
          profileInfo = profileRes.profileInfo;
        }
        const payload: UpgradePayload = {
          userId: profileInfo?.userId,
          userSysNo: profileInfo?.userSysNo,
        };
        if (flag === NEVER) {
          this.storageService.store<string>(UPGRADE_NOTNOW, TRUE);
          payload.upgradeRemindFlag = NEVER_FLAG;
          this.setUpgradePayload(payload, flag, profile);
        } else if (profileInfo.role === FP && profileInfo.challengeQuestions) {
          payload.userGroup = FPGROUP;
          this.setUpgradePayload(payload, flag, profile);
        } else {
          this.windowRef.location.href = `${this.appStateService.getProfileDomain()}${UPGRADE_ACCESS_ROUTE}`;
        }
        logger.info('isProfileInfoAvailable set: ' + isProfileInfoAvailable);
      }
    });
  }
  /**
   * Function for upgrade the marketing to accounts
   */
  public setUpgradePayload(
    payload: UpgradePayload,
    flag: string,
    profileData: IUserProfile
  ): void {
    this.spinner.show();
    logger.info('setUpgradePayload flag: ' + flag);
    this.upgradeAccess(payload).subscribe((response: any) => {
      this.alertService.showAccountsAlert = false;
      this.alertService.clearAccountsAlert();
      if (response.body.result.message === OPERATION_FAILED) {
        logger.info(
          'setUpgradePayload OPERATION_FAILED: ' + response.body.result.message
        );
        const alertPayload: AlertObj = {
          apiType: true,
          type: API_ERROR,
        };
        this.alertService.setAccountsAlert(alertPayload);
      } else {
        logger.info(
          'setUpgradePayload OPERATION_Status: ' + response.body.result.message
        );
        if (flag !== NEVER) {
          const notifyPayload: AlertObj = {
            notify: true,
            type: UPGRADE_MESSAGE_FP,
          };
          this.alertService.setAccountsAlert(notifyPayload);
          // upgraded-fp
          profileData.profileInfo.accountsAccess = AccountsAccess.ACCESS;
          const dashboardUrl =
            profileData.profileInfo.role === FP
              ? `${this.appStateService.getAccountsDomain()}${FP_DASHBOARD}`
              : `${this.appStateService.getAccountsDomain()}${SH_DASHBIARD}`;
          profileData.profileInfo.dashboardUrl = dashboardUrl;
        } else {
          profileData.profileInfo.remindUpgrade = false;
        }
        const summary: ProfileSummary = {
          isLoggedIn: profileData.isLoggedIn,
          source: profileData.loginSource,
          // isIdentified: this.profile.isIdentified ? 'true' : 'false',
          role: profileData.profileInfo.role,
          webExperience: profileData.profileInfo.webExperience,
          firm: profileData.profileInfo.firm,
          accountsAccess: profileData.profileInfo.accountsAccess,
          dashboardUrl: profileData.profileInfo.dashboardUrl,
        };
        this.storageService.storeProfileSummary(summary);
        // this.profileService.setProfileFromAccounts(profileData);
        logger.info(
          'setUpgradePayload profileData: ' +
            profileData.profileInfo.accountsAccess
        );
      }
    });
  }
  /**
   * Function for upgrade the marketing to accounts
   */
  public upgradeAccess(payload: UpgradePayload): Observable<any> {
    const upgradeAccessUrl = `${this.appStateService.getAccountsApiUrl()}${
      ENDPOINT.upgradeAccess.link
    }`;
    return new Observable((observer) => {
      this.http.put(upgradeAccessUrl, payload, httpOptions).subscribe(
        (res: any) => {
          this.spinner.hide();
          observer.next(res);
          observer.complete();
        },
        (exception: any) => {
          this.spinner.hide();
          this.alertService.showAccountsAlert = false;
          this.alertService.clearAccountsAlert();
          observer.error(exception);
        }
      );
    });
  }
}
