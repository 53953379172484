export type ProfileImage = {
  image?: any;
  widenAsset: string | any;
};

export type Content = {
  content?: any;
  value?: any;
};

export type Profile = {
  profileImage?: ProfileImage;
  fullName?: string;
  designations?: string;
  teamOrSim?: string;
  timeInfund?: string;
  tenure?: string;
  location?: string;
  content?: Content;
};

export interface InvestmentTeam {
  fundId?: string;
  employeeId?: string;
  countryCode?: string;
  languageCode?: string;
  managerName?: string;
  managerNameWithSuffix?: string;
  managedSinceString?: string;
  managerOrder?: number;
  startDateFundManagement?: string;
  startDateFundManagementStd?: string;
  managerCity?: string;
  managerDesignation?: string;
  yearsOfService?: string;
  yearsInIndustry?: string;
  yearsOfServiceEffectiveDate?: string;
  cfa?: string;
  mba?: string;
  phd?: string;
  cpa?: string;
  frm?: string;
  profile?: Profile;
}
