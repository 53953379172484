import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import {
  AdditionalLegacyLink,
  AnalyticsService,
  CountryHub,
  FlyoutMenu,
  HiddenInput,
  ModalService,
  SignInComponentFieldsInfo,
  SignInEvent,
} from '@frk/eds-components';
import { CartHandlerService } from '@literature/services/cart-handler.service';
import {
  getBreakpointType,
  isExternalLink,
  openInNewTab,
  setMenuIcon,
  overwriteTitle,
} from '@marketing/marketing-utils';
import {
  AccessRegisterStatus,
  AppStateService,
  GlobalConfigService,
  IUserProfile,
  LanguageSelectorService,
  LoginSource,
  ProfileService,
  RoleSelectorService,
  SegmentService,
  SignInService,
  SiteConfigService,
  FastTrackRegistrationService,
  EnvConfigService,
  WidenService,
  PersonalisationAPIService,
} from '@services';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import {
  AnalyticsLogin,
  SignInInterface,
} from '@shared/sign-in/sign-in.interface';
import { TranslateService } from '@shared/translate/translate.service';
import {
  CommonConfig,
  Segment,
  SegmentId,
  SimplyLinkItem,
  SiteParams,
} from '@types';
import { ANGULAR_HOOK, MYFUNDS_HOOK } from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { getMenus, getModelFromRef } from '@utils/pagemodel-utils';
import find from 'lodash/find';
import get from 'lodash/get';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  GlobalItem,
  HeaderCards,
  HeaderDocument,
  SignInNavItems,
  SiteNavItem,
} from './header.interfaces';
import { SearchComponent } from '../../../ft-search/search.component';
import { SkiptoHeaderService } from '@services/skipto-header.service';
import { Router } from '@angular/router';
import { ViewModeService } from '@services/view-mode.service';
import { replaceTokens } from '@utils/text/string-utils';
import { LinkService } from '@services/link.service';

const logger = Logger.getLogger('HeaderComponent');

/**
 * Header Component
 */
@Component({
  selector: 'ft-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent
  extends AbstractBaseComponent
  implements OnInit, OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();
  public isSubmitButtonDisabled = false;

  public globalItems: GlobalItem[] = [];

  /**
   * Site config
   */
  private siteParams: SiteParams;
  private siteGeneral: CommonConfig;

  /**
   * Header Logo
   */
  public headerLogo = '/assets/images/logos/FT_logo_pos_RGB@2x.png';
  public mobileHeaderLogo = '';
  public hasWideLogo: boolean;
  /**
   * Header Logo Alt
   */
  public logoAltText: string;
  public homeUrl: string;
  public cartSize = 0;
  // private isCartEnabled = false; // doesn't appear to be used anymore
  public siteNavData: SiteNavItem[];

  /**
   * current segment data
   */
  private hasMoreThanOneVisibleSegment: boolean;
  private currentSegment: Segment;
  public currentSegmentLabel: string;

  /**
   * Header microsite Logo
   */
  public isMicrosite: boolean;
  private hasTextLogo: boolean;
  public micrositeTextLogo = '';
  public micrositeLogoHref = '';

  // Sign In
  public hasSignInBtn = false;
  public isLogIn = false;
  private profile: IUserProfile;
  private signInForm: FormGroup;
  public signInNavItems: SignInNavItems[] = [];
  public signInComponentContent: SignInComponentFieldsInfo;
  public signInFormAction = '';
  public showLegacySignInOption: boolean;
  public showLegacyContent = true;
  public signInHiddenInputs: HiddenInput[];
  public signInParams: SignInInterface;
  public signedInUserName: string;
  public signedInUserNav: Array<FlyoutMenu> = [];
  public accountsNavName: string;
  public accountsNav: Array<FlyoutMenu> = [];
  /**
   * TODO Flag and params deciding if search needs to be displayed
   * hasSearch will be read from config.
   * TBD
   */
  // Search
  public hasSearch = false;
  public isSearchActive = false;
  @ViewChild(SearchComponent, { static: false })
  searchComponent: SearchComponent;
  // private searchPlaceholder = 'Search Franklin Templeton'; // doesn't appear to be used anymore
  // private searchClearText = 'Clear'; // doesn't appear to be used anymore
  public searchFieldId = 'site-search';

  // Header Style
  public headerStyle: 'basic' | 'standard' | 'basic-with-login';
  public countryHub: CountryHub;
  private showRoleSelectionLink: boolean;

  public langItems: SimplyLinkItem[] = [];
  // skipTo string
  public skipLinkPath: string;

  public isEditMode = false;
  public isCustomMegaMenu = false;

  public isHideSiteSearchForFirm$: Observable<boolean>;

  /**
   * Constructor
   */
  // prettier-ignore
  constructor( // NOSONAR - disable rule - Maximum allowed Constructor parameters 7. sonarlint(typescript:S107)
    private appStateService: AppStateService,
    private pageConfigService: GlobalConfigService,
    private siteConfigService: SiteConfigService,
    private cartService: CartHandlerService,
    private changeDetection: ChangeDetectorRef,
    private profileService: ProfileService,
    private segmentService: SegmentService,
    private signInService: SignInService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private languageSelectorService: LanguageSelectorService,
    private modalService: ModalService,
    private roleSelectorService: RoleSelectorService,
    private analyticsService: AnalyticsService,
    private skiptoHeaderService: SkiptoHeaderService,
    private router: Router,
    private viewModeService: ViewModeService,
    private fastTrackRegistrationService: FastTrackRegistrationService,
    private envConfigService: EnvConfigService,
    private widenService: WidenService,
    private linkService: LinkService,
    private personalisationService: PersonalisationAPIService
  ) {
    super();
    // TODO: for components initialisation code should usually be in ngOnInit() instead of constructor
    this.siteParams = this.pageConfigService.getSiteParams();
    this.siteGeneral = this.pageConfigService.getSiteGeneral();
    this.headerLogo = get(
      this.siteParams,
      'headerLogo',
      '/assets/images/logos/FT_logo_pos_RGB@2x.png'
    );
    this.mobileHeaderLogo = get(
      this.siteParams,
      'mobileHeaderLogo',
      ''
    );
    this.hasWideLogo = get(
      this.siteParams,
      'hasWideLogo',
      false
    );
    this.logoAltText = get(
      this.siteParams,
      'logoAltText',
      'Franklin Templeton Investments'
    );
    this.homeUrl = this.relativeHomeURL(this.appStateService.getHomePageUrl());
    this.isMicrosite = !!this.siteParams?.isMicrosite;
    this.hasTextLogo =
      typeof this.siteParams?.headerMicrositeTextLogo !== 'undefined' &&
      this.siteParams?.headerMicrositeTextLogo !== '';
    if (this.isMicrosite) {
        if (this.hasTextLogo) {
            this.micrositeTextLogo = this.siteParams?.headerMicrositeTextLogo;
        }
        this.micrositeLogoHref =
        typeof this.siteParams?.headerMicrositeLogoHref !== 'undefined' &&
        this.siteParams?.headerMicrositeLogoHref !== ''
          ? this.siteParams?.headerMicrositeLogoHref
          : this.homeUrl;
    }

    //  WDE-3477
    if (
        this.siteParams?.headerMicrositeLogoHref &&
        typeof this.siteParams?.headerMicrositeLogoHref !== 'undefined' &&
        this.siteParams?.headerMicrositeLogoHref !== ''){
        this.homeUrl = this.siteParams?.headerMicrositeLogoHref;
    }

    this.hasSearch = this.siteGeneral?.searchHeader || false;
    this.isEditMode = this.viewModeService.isEditMode();
  }

  /**
   * Init
   */
  // prettier-ignore
  public ngOnInit(): void { // NOSONAR - disable rule - Maximum allowed Constructor parameters 7. sonarlint(typescript:S107)
    this.labelIcon = this.isEditMode ? '[L]' : '';
    logger.debug('Header initialised');
    this.cartService
      .getCartCount()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((count) => {
        this.cartSize = count;
        this.changeDetection.detectChanges();
      });
    this.initHeaderStyle();

    // role selector link in a header
    this.showRoleSelectionLink = !this.headerStyle?.startsWith('basic');
    this.hasMoreThanOneVisibleSegment =
      this.segmentService.getVisibleSegments()?.length > 1;

    this.currentSegment = this.segmentService.getCurrentSegment();
    this.currentSegmentLabel =
      this.hasMoreThanOneVisibleSegment && this.showRoleSelectionLink
        ? this.currentSegment?.label
        : '';

    /**
     * Initialise sign-in form
     */
    this.showLegacySignInOption =
      this.siteGeneral?.authenticationLegacy || false;
    if (this.appStateService.isSimplyLoginMethod()) {
      this.signInFormAction = this.signInService.getLoginUrl();
      this.signInHiddenInputs = this.signInService.getHiddenInputs(true);
    }
    if (!this.siteConfigService.hideSignWidget()) {
      this.hasSignInBtn = this.appStateService.hasAuthentication();
    }

    // Due to Mega-menu functionality getSiteNavData needs to be initialised once in OnInit
    this.siteNavData = this.getSiteNavData();
    // Enable image cards in mega menu (Putnam)
    this.isCustomMegaMenu = this.currentSegment?.isCustomMegaMenu;

    if (this.isCustomMegaMenu) {
      this.updateHeaderDocInSiteNav();
    }

    logger.debug('processed siteNavData', this.siteNavData);

    if (this.hasSignInBtn) {
      // Load SignIn forms only if sign in is enabled.
      if (this.accountsNav.length === 0) {
        this.loadSignInContentFormLabels();
      }
      this.signInForm = this.signInService.signInFormInit(this.formBuilder);
      this.signInParams = this.getSignInStyle();
      this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
        this.signInParams
      );
      this.signInService
        .getFastTrackRegistration$(this.signInParams, true)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((signIn: SignInInterface) => {
          this.signInComponentContent = this.signInService.getSignInComponentFieldsInfo(
            signIn
          );
        });
      /**
       * Mapping Access Register Status
       */
      this.mapAccessRegisterStatus();
    }
    // listen for accounts unavailable and set error message
    if (this.signInComponentContent) {
      this.profileService
        .isAccountsAvailable$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((message: string) => {
          this.signInComponentContent.errorText = message;
          logger.debug('signin error', message, this.signInComponentContent);
        });
    }
    // end of sign-in code

    // this delays processing until validated user profile has returned
    this.profileService
      .getUserProfile$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((profile: IUserProfile): void => {
        logger.debug('profile set in header', profile);
        this.profile = profile;
        // Show/hide login
        if (this.profile.isLoggedIn && this.hasSignInBtn) {
          this.isLogIn = this.profile.isLoggedIn;
          this.signedInUserName = this.profile.profileInfo?.displayName;
          if (
            !this.appStateService.isSimplyLoginMethod() &&
            !this.appStateService.isOktaLogin() &&
            // If bypass parameter does not exist in URL and user is still bypassed we are not able to set these values.
            !this.profileService.isBypass(true)
          ) {
            // Adding link to profile dashboard base on profile information
            if (this.signInNavItems.length > 0 && this.accountsNav.length === 0) {
              this.signInNavItems[0].href = this.profile.profileInfo?.dashboardUrl;
            } else {
              this.accountsNav.forEach((navItem: FlyoutMenu, navIndex: number) => {
                 if (navItem?.href?.includes(this.appStateService.getAccountsDomain())) {
                  this.accountsNav[navIndex].type = this.translateService.instant('signin.lock');
                  this.accountsNav[navIndex].href = this.profile.profileInfo?.dashboardUrl;
                 }
              });
            }
            // Adding web profile and service-center
            this.signedInUserName = this.profile.profileInfo?.displayName;
            if (this.signedInUserNav.length > 0) {
              this.signedInUserNav.unshift({
                link: this.translateService.instant('signin.profile-label'),
                href:
                  this.appStateService.getProfileDomain() +
                  '/profile/webprofile',
              });
            } else {
              // Check the nav Item in the signInNavItems array,
              // if already exist in the array no need to push
              const signInNavHrefList = this.signInNavItems?.map((element: SignInNavItems) => element.href);
              const href = this.appStateService.getProfileDomain() + '/profile/webprofile';
              if (signInNavHrefList?.indexOf(href) < 0 && this.signedInUserName) {
                this.signInNavItems.push({
                  text: this.signedInUserName,
                  href,
                  iconType: this.translateService.instant('signin.profile'),
                  class: 'dd-privacy-mask',
                  eventParams: {
                    link_text: '[privacy mask]',
                  },
                });
              }
            }
          }
        }
        // Replacing home URL with partner dashboard URL if configured.
        if (this.profile.profileInfo?.firm) {
          this.homeUrl =
            this.profileService.getPartnerDashboardUrl(
              this.profile.profileInfo?.firm
            ) || this.homeUrl;
        }

        this.changeDetection.detectChanges();
      });

    /**
     * skipLinkPath to main-content
     */
    this.skipLinkPath = `${this.router.url}#main-content`;
    /**
     * Get language selector for footer
     */
    this.langItems = this.languageSelectorService.getConvertedLanguages();

    /**
     * Get country hub for gateway
     */
    this.countryHub = this.getCountryHub();

    this.isHideSiteSearchForFirm$ = this.personalisationService.getIsActiveListUpdatedByFirm$();
  }

  /**
   * Mapping Access Register Status for Fast track registration.
   * Placed in header component as status is used in all sign in components and should be read once.
   */
  private mapAccessRegisterStatus(): void {
    // Fast Track Registration is available only for US
    if (this.appStateService.getChannel() === 'en-us') {
      this.fastTrackRegistrationService
        .mapAccessRegisterStatus$()
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((accessStatus: AccessRegisterStatus) => {
          logger.debug('Token To Access Register status:', accessStatus);
          this.profileService.setAccessRegisterStatus$(accessStatus);
        });
    }
  }

  /**
   * Get country hub for gateway
   */
  private getCountryHub(): CountryHub {
    const channelParams = this.page.getChannelParameters();
    return {
      countryHubLink: this.siteParams?.countryHubLink,
      siteName:
        channelParams && channelParams['Site Name']
          ? channelParams['Site Name']
          : '',
    };
  }

  /**
   * Note: this isn't run until profileService returns a validated profile
   */
  private initHeaderStyle(): void {
    const pageMetadata = this.page.getComponent()?.getModels()?.pageData;

    // Header style
    this.headerStyle = pageMetadata?.headerStyle;
  }

  private getSignInStyle(): SignInInterface {
    return {
      legacy: false,
      signOut: true,
      skin: 'basic',
      showLegacyContent: this.showLegacySignInOption,
    };
  }

  private loadSignInContentFormLabels(): void {
    if (
      !this.appStateService.isSimplyLoginMethod() &&
      !this.appStateService.isOktaLogin() &&
      !this.profileService.isBypass(true)
    ) {
      this.signInNavItems = [
        {
          text: this.translateService.instant('signin.accountDashboard'),
          href: '',
          iconType: this.translateService.instant('signin.lock'),
        },
      ];
    }
  }

  /**
   * Get label for language dropdown
   */
  // TODO: no functions in templates. This will re-run every time change detection happens
  get languageLabel(): string {
    const channelLang = this.languageSelectorService.getChannelLanguageLabel(
      this.page
    );
    return this.labelIcon + channelLang;
  }

  /**
   * Get Navigational menu for header
   */
  getSiteNavData(): SiteNavItem[] {
    this.menuItem = getMenus(this.page, this.component);
    const siteNavItems: MenuItem[] = get(this, 'menuItem.siteMenuItems', []);
    logger.debug('getSiteNavData', siteNavItems);

    /**
     * Get global Links for footer
     */
    const globalItems = find(this.menuItem?.siteMenuItems, {
      name: 'Utility Navigation',
    });
    this.globalItems = this.convertGlobal(
      get(globalItems, 'childMenuItems', [])
    );
    this.signedInUserNav = this.mapUtilityFlyoutNavItems(
      'Service Center Navigation'
    );
    this.accountsNav = this.mapUtilityFlyoutNavItems('Accounts');

    return this.convertNavItems(siteNavItems);
  }

  /**
   * Mapping Service Center Navigation if exists
   */
  private mapUtilityFlyoutNavItems(menuItemName: string): Array<FlyoutMenu> {
    return get(
      find(this.menuItem?.siteMenuItems, {
        name: menuItemName,
      }),
      'childMenuItems',
      []
    ).map((menuItem: MenuItem) => {
      return {
        link: menuItem?.name,
        href:
          replaceTokens(
            menuItem?.links?.site?.href,
            this.envConfigService.getEnvConfig()
          ) || '',
        type: '',
      };
    });
  }

  /**
   * Converting BR menu item to EDS GlobalItem interface
   * @param globalItems - BR array to convert
   */
  private convertGlobal(globalItems: Array<MenuItem>): GlobalItem[] {
    if (!globalItems) {
      return [];
    }
    const globalItemsEds = [];
    globalItems.forEach((item: MenuItem) => {
      const hook: string = this.getHook(item, ANGULAR_HOOK);
      const globalItem: GlobalItem = {
        text: item.name,
        href: get(item, 'links.site.href', ''),
        hrefExternal: isExternalLink(item),
        newWindow: openInNewTab(item),
        breakpointType: getBreakpointType(item),
        isCart: this.isChart(item.name),
        iconType: setMenuIcon(item),
        hook,
      };
      globalItemsEds.push(globalItem);
    });
    return globalItemsEds;
  }

  /**
   * Check if menu item contains cart
   * @param name - menu item name
   */
  // TODO: should this be called isCart()?
  private isChart(name: string): boolean {
    return name.toLowerCase().includes('cart');
  }

  /**
   * Converting BR menu item to EDS NavItem interface
   * @param siteNavItems - BR array to convert
   */
  private convertNavItems(siteNavItems: Array<MenuItem>): SiteNavItem[] {
    if (!siteNavItems) {
      return [];
    }
    const siteNavItemsEds: SiteNavItem[] = [];
    const mainNavItems: MenuItem = siteNavItems.find(
      (mainNav) => mainNav.name === 'Main Navigation'
    );
    siteNavItems = mainNavItems?.childMenuItems
      ? mainNavItems.childMenuItems
      : siteNavItems;
    siteNavItems.forEach((item: MenuItem) => {
      if (item.name !== 'Main Navigation') {
        siteNavItemsEds.push(this.navMenuItems(item));
      }
    });
    return siteNavItemsEds;
  }

  /**
   * Returns object for Nav menu
   * @param item - nav item
   */
  private navMenuItems(item: MenuItem): SiteNavItem {
    let link: string = get(item, 'links.site.href', '');
    const hook: string = this.getHook(item, ANGULAR_HOOK);
    const overwriteTitleText = overwriteTitle(item);
    if (hook === MYFUNDS_HOOK) {
      link = this.processMyFunds(link);
    }
    return {
      id: item.name,
      name: item.name,
      active: false,
      link,
      hook,
      hrefExternal: isExternalLink(item),
      breakpointType: getBreakpointType(item),
      newWindow: openInNewTab(item),
      overWriteTitle: overwriteTitleText.length
        ? overwriteTitleText
        : item.name,

      child:
        item.childMenuItems.length > 0
          ? this.convertNavItems(item.childMenuItems)
          : [],
    };
  }

  /**
   * used to extract optional metadata for link
   */
  private getHook(item: MenuItem, paramName: string): string {
    const paramNames: string[] = item.properties?.['hst:parameternames'] || [];
    const paramIndex: number = paramNames.indexOf(paramName);
    if (paramIndex > -1) {
      return item.properties?.['hst:parametervalues'][paramIndex];
    }
  }

  /**
   * Intercepts MyFunds link and redirects to portal if user is logged in
   */
  private processMyFunds(link: string): string {
    let newLink: string = link;
    if (
      this.profile?.isLoggedIn &&
      [LoginSource.OAUTH, LoginSource.DEBUG].includes(this.profile?.loginSource)
    ) {
      switch (this.segmentService.getCurrentSegmentId(true)) {
        case SegmentId.INVESTOR:
          newLink = `${this.appStateService.getMyFundsDomain()}/InternationalInvestorPortal/dashboard`;
          break;
        case SegmentId.DISTRIBUTOR: // fall through
        case SegmentId.FINANCIAL_PROFESSIONALS:
          newLink = `${this.appStateService.getMyFundsDomain()}/InternationalAdvisorPortal/dashboard`;
          break;
        // NOTE: No default case. Only changes url if in specific roles
      }
    }
    return newLink;
  }

  /**
   * Rewrites absolute home page url to relative
   * @param url - string to check
   */
  private relativeHomeURL(url: string): string {
    // Logic moved to service.
    return this.appStateService.relativeHomeURL(url);
  }

  /**
   * opens role selector modal
   */
  public showRoleSelectorDialog(): void {
    this.analyticsService.trackEvent({
      event: 'lightbox_display',
      action: 'role_select',
      label: this.currentSegmentLabel,
    });

    this.analyticsService.trackEvent({
      event: 'lightbox_impression',
      detailed_event: 'Lightbox Impression',
      event_data: {
        display_type: 'role_select',
        heading: this.currentSegmentLabel,
      },
    });

    this.analyticsService.trackEvent({
      event: 'utility_navigation',
      detailed_event: 'Utility Navigation',
      link_url: '',
      link_text: this.currentSegmentLabel,
      event_data: {
        category: '',
        link_id: '',
        link_text: this.currentSegmentLabel,
        link_type: this.analyticsService.getLinkType(''),
        link_url: '',
      },
    });

    this.roleSelectorService.openRoleSelectorModal();
  }

  // doesn't appear to be used anymore
  // private goToCart(): void {
  //   this.cartService.navigateToUrl(`yourcart`);
  // }

  public onSubmit(signIn: SignInEvent) {
    logger.debug('SignInEvent', signIn);
    const submitObject: AnalyticsLogin = {
      signInForm: this.signInForm,
      analyticsKey: 'Header',
    };
    if (this.siteConfigService.hideLoginForm()) {
      this.signInService.onSubmit(submitObject);
    } else {
      this.signInForm.value.userId = signIn.userName;
      this.signInForm.value.password = signIn.password;
      // Commented now according to NGC-8586
      // this.signInForm.value.rememberMe = signIn.rememberMe ? REMEMBER_ME_YES : REMEMBER_ME_NO;
      if (!signIn.userName && !signIn.password) {
        this.signInComponentContent.userName.invalid = true;
        this.signInComponentContent.password.invalid = true;
      } else if (!signIn.userName && signIn.password) {
        this.signInComponentContent.userName.invalid = true;
        this.signInComponentContent.password.invalid = false;
      } else if (signIn.userName && !signIn.password) {
        this.signInComponentContent.userName.invalid = false;
        this.signInComponentContent.password.invalid = true;
      } else {
        this.signInComponentContent.userName.invalid = false;
        this.signInComponentContent.password.invalid = false;
        this.isSubmitButtonDisabled = true;
        this.signInService.onSubmit(submitObject);
      }
    }
  }

  public signOut(): void {
    logger.debug('sign out');
    if (this.cartSize > 0) {
      this.modalService.open('confirmationModal', {
        modalId: 'cart-confirmation-modal',
        type: 'simple',
        eventName: 'Cart confirmation',
        eventAction: 'Click',
      });
    } else {
      this.signInService.signOut();
    }
  }

  public setSearchButton(searchActive: boolean): void {
    this.isSearchActive = searchActive;
  }

  // clear search history on close search.
  public clearSearchResults(): void {
    this.searchComponent.closeSearch();
  }

  @HostListener('click', ['$event'])
  onClick(event: Event): void {
    this.signInService.trackLogin(event);
  }

  public onSkipToEvent(value: boolean) {
    // Get the current value of the boolean from the service
    this.skiptoHeaderService.setSkipToHeaderBoolean(value);
  }

  /**
   * Fast Track Registration modal open
   */
  public fastTrackRegistration() {
    if (this.signInParams?.fastTrackResendConfirmation) {
      this.fastTrackRegistrationService.setResendActivationEmailFlag$(true);
    }
    this.fastTrackRegistrationService.setIsRegistrationModalVisible$(true);
  }

  /**
   * Trigger legacy button click
   * @param linkEvent - AdditionalLegacyLink
   */
  public legacyBtnclick(linkEvent: AdditionalLegacyLink): void {
    this.signInService.openLegacyLink(linkEvent);
  }

  /**
   * Updates the navigation data in the site navigation with information
   * from the header document.
   *
   * NOTE: For now, there is only one image card for each main nav
   * TODO: update logic to support multiple cards
   */
  private updateHeaderDocInSiteNav(): void {
    const doc = this.component?.getParameters()?.document;
    const headerDocument =
      doc && this.page?.getContent(doc)?.getData<HeaderDocument>();
    if (this.siteNavData && headerDocument?.headerCards) {
      this.siteNavData.forEach((nav: SiteNavItem, index) => {
        const headerCards = headerDocument.headerCards[index];

        if (headerCards) {
          this.updateNavWithHeaderCard(nav, headerCards);
        }
      });
    }
  }

  /**
   * Updates a navigation item with data from a header card.
   * @param nav - The navigation item to be updated.
   * @param headerCards - The header card containing data to update the navigation item.
   */
  private updateNavWithHeaderCard(
    nav: SiteNavItem,
    headerCards: HeaderCards
  ): void {
    const { cards } = headerCards;
    const cardLink = cards?.link?.linkCollection?.[0];
    const footerLink = cards?.footerLink?.linkCollection?.[0];

    nav.sectionContent = cards?.content;
    nav.cardImageUrl = this.widenService.getWidenAssetUrl(
      cards?.image?.widenAsset
    );
    nav.cardLinkName = cardLink?.displayText;
    nav.cardUrl = this.linkService.getCTALink(
      this.page,
      cardLink,
      this.appStateService?.getHomePageUrl()
    );
    nav.customHtml = cards?.customHtml;
    nav.footerLinkUrl = this.linkService.getCTALink(
      this.page,
      footerLink,
      this.appStateService?.getHomePageUrl()
    );
    nav.footerLinkName = footerLink?.displayText;
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
