import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SEARCH_STORAGE_NAME_KEY } from '@search/services/ftsearch.service';
import { StorageService } from '@services/storage.service';
import { Subject } from 'rxjs';
import { FtPreSearchResultsItem } from '@search/interfaces/search.interface';

@Component({
  selector: 'ft-empty-search',
  templateUrl: './empty-search.component.html',
})
export class EmptySearchComponent implements OnInit {
  recentSearchItems: Array<string> = [];
  searchTerm: string;
  @Input() clearRecent: boolean;
  @Input() quickLinks: FtPreSearchResultsItem[];
  @Output() submitted = new EventEmitter<string>();
  @Output() clearRecentSearchOn = new EventEmitter<boolean>();
  constructor(private storageService: StorageService) {}

  /**
   *  Getting the value of local storage key for recent search items
   *
   */

  ngOnInit(): void {
    this.storageService
      .retrieve(SEARCH_STORAGE_NAME_KEY)
      .then((value: string) => {
        this.recentSearchItems = JSON.parse(value);
      });
  }
  /**
   *   On clicking recent search items search should be performed
   *
   */
  searchItem(searchTerm: string, event) {
    event.preventDefault();
    this.submitted.emit(searchTerm);
  }
  /**
   *   To clear the recent search from localstorage
   *
   */
  clearRecentSearch() {
    this.clearRecentSearchOn.emit(true);
    this.recentSearchItems = [];
  }
}
