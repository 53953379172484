<main>
  <section edsGrid edsSpacing="lg">
    <div class="container container--wide">
      <div edsSpacing="md" edsRow edsFlexbox justifyContent="space-between">
        <div edsCol edsSubGridGap="md" edsSubGridTabletGap="md">
          <eds-enhanced-filter
            [filters]="filters"
            [chipsFilter]="chipsFilters"
            [resetText]="'common.reset-filter' | translate"
            (dropdownSelected)="onSelectFilter($event)"
            (resetTriggered)="resetFilter($event)"
            (chipRemovedTriggered)="chipRemoved($event)"
          ></eds-enhanced-filter>

          <div edsPadding [edsPaddingTop]="'md'">
            <div *ngIf="filteredEvents.length > 0">
              <ng-container
                [ngTemplateOutlet]="eventCard"
                [ngTemplateOutletContext]="{ eventDetails: filteredEvents }"
              ></ng-container>
            </div>
            <!-- TODO Component for no event-->
            <div *ngIf="filteredEvents.length === 0">
              {{ "common.no-event" | translate }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>

<!--
  Event Card Templete
-->
<ng-template #eventCard let-eventDetails="eventDetails">
  <div edsRow edsSpacing="md">
    <div edsCol>
      <div
        edsSubGrid="layout-2"
        edsSubGridTabletGap="sm"
        edsSubGridTablet="layout-1"
        edsSubGridGap="sm"
        edsSubGridRowGap="sm"
      >
        <div *ngFor="let event of eventDetails">
          <ft-event-info [event]="event"></ft-event-info>
        </div>
      </div>
    </div>
  </div>
</ng-template>
