<!-- New create/edit button -->
<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <!-- edit button -->
  <ng-container
    *ngIf="isEditMode"
    [brManageContentButton]="document"
    parameter="document"
    [relative]="false"
    root=""
  >
  </ng-container>
  <div
    *ngIf="quickLinksCard"
    edsSpacing="{{ spacingBottom }}"
    edsPadding
    edsPaddingTop="lg"
  >
    <eds-card-index-image
      [cardsData]="quickLinksCard"
      [cardsTitle]="quickLinksHeading"
      [cardsPreTitle]="quickLinksPreTitle"
      [theme]="titleColor"
      [hasImage]="hasImage"
    >
    </eds-card-index-image>
  </div>

  <!-- No Document -->
  <div *ngIf="!document && isEditMode" class="no-document" edsPadding>
    <div edsGrid class="no-document__component">
      <div class="no-document__overlay">
        Click to configure <strong>{{ componentLabel }}</strong>
      </div>
    </div>
  </div>
</div>
