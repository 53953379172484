<div class="modal">
  <div class="modal__contents">
    <div
      class="modal__header"
      [ngClass]="{
        'modal__header--grey': isGrey,
        'modal__header--border': headerBottomBorder
      }"
    >
      <button
        type="button"
        class="modal__close"
        (click)="close()"
        aria-label="Close"
        *ngIf="showCloseButton"
      >
        <span aria-hidden="true" [innerHTML]="closeLabel"></span>
      </button>
      <h4
        *ngIf="title"
        class="modal__title"
        [ngClass]="{ 'modal__header--small': size === 'small' }"
        [innerHTML]="title"
      ></h4>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div class="modal__background"></div>
