import { Component, Input, OnInit } from '@angular/core';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk/';
import {
  TableCreation,
  TableData,
  TableHeader,
} from './table-selection.interface';
import groupBy from 'lodash/groupBy';
import { processLinkCollection } from '@utils/link-utils';
import { ResponsiveService } from '@frk/eds-components';
import { Observable } from 'rxjs';

@Component({
  selector: 'ft-table-selection',
  templateUrl: './table-selection.component.html',
  styleUrls: ['./table-selection.component.scss'],
})
export class TableSelectionComponent
  extends AbstractBaseComponent
  implements OnInit {
  /**
   * The Bloomreach component
   */
  @Input() component!: BrComponent;

  /**
   * The Bloomreach page
   */
  @Input() page!: Page;

  /**
   * Content from the Br to show on page
   */
  @Input() tableData: TableData;

  /**
   * to check if drop shadow should be added or not around the table
   */
  hasDropShadows: boolean;

  /**
   * to check if table rows should be striped or not
   */
  isTableStriped: boolean;

  /**
   * to check if table header should be themed or not
   */
  tableTheme: string;

  /**
   * container or container--wide will get from BR
   */
  containerClass: string;

  /**
   * margin bottom for the table from BR
   */
  spacingBottom: string;

  /**
   * table rows data
   */
  tableCreation: Array<TableCreation>;

  // to control the view in different screen
  isHandheld$: Observable<boolean>;

  /**
   * table header data
   */
  tableHeader: Array<TableHeader>;

  constructor(protected responsiveService: ResponsiveService) {
    super();
  }

  ngOnInit(): void {
    this.isHandheld$ = this.responsiveService.isHandheld$();
    this.setTableData();
  }

  /**
   * creating the table data from the BR content
   */
  private setTableData(): void {
    if (!this.tableData) {
      const data = this.document?.getData();
      this.tableData = data?.tableStyleCompound;
      const componentParams = this.page
        ?.getComponent('main-content')
        ?.getComponent('table-selection')
        ?.getParameters();
      this.containerClass = componentParams?.containerClass;
      this.spacingBottom = componentParams?.marginBottom;
    }
    this.hasDropShadows = this.tableData?.dropShadows;
    this.isTableStriped = this.tableData?.tableStriped;
    this.tableTheme = this.tableData?.tableTheme;
    this.tableHeader = this.tableData?.tableStyleHeader;
    if (this.tableData?.tableCreation) {
      const groupData = Object.values(
        groupBy(this.tableData?.tableCreation, 'rowGroupName')
      );
      this.tableCreation = groupData.map((rowGroup: TableCreation[]) => {
        const rowData = [];
        for (const row of rowGroup) {
          for (const col of row.tableStyleRow) {
            if (col.linkCompound.linkCollection.length) {
              col.linkCompound.linkCollection[0] = processLinkCollection(
                col.linkCompound.linkCollection[0],
                this.page
              );
              col.linkCompound.externalLinkClass = this.getExternalLink(
                col.linkCompound.linkCollection[0].external,
                col.linkCompound.linkCollection[0].externalSuffix
              );
            }
          }
          rowData.push(row.tableStyleRow);
        }
        return {
          rowGroupName: rowGroup[0].rowGroupName,
          tableStyleRow: rowData,
        };
      });
    }
  }

  /**
   * Set external link class
   * @param external - is link external true|false
   * @param externalSuffix - suffix to add to external link
   */
  private getExternalLink(external: boolean, externalSuffix: string): string {
    if (external) {
      const externalLink = 'external-link';
      if (externalSuffix) {
        // Adding external link suffix
        return externalLink + '--' + externalSuffix;
      }
      return externalLink;
    }
  }
}
