import { Injectable, OnDestroy } from '@angular/core';
import {
  BMPerformanceDetail,
  PerformanceDetail,
  Product,
  ShareClass,
} from '@models';
import { SiteConfigService } from '@services/site-config.service';
import {
  API_DATE_FORMAT,
  EMDASH,
} from '@products/utils/constants/product.constants';
import {
  AllShareClassesDiscreteReturnsData,
  DateRange,
  FundPerformanceDiscreteReturnsPeriodEndData,
  FundPerformanceDiscreteReturnsState,
  FundPerformanceDiscreteReturnsStateData,
  FundPerformanceDiscreteReturnsTableData,
  PerformanceSuppressionSet,
  PerformanceType,
  PerformanceView,
  ProductDetailConfiguration,
} from '@types';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import fundPerformanceDiscreteReturnsQuery from '@graphql/fund-performance/discrete-returns.graphql';
import { BaseFundPerformanceService } from './base-fund-performance.service';
import { getPreviousYearsDateRange } from '@utils/text/date-utils';
import { FundDetailService } from '@products/services/fund-detail.service';
import { stringCompare } from '@utils/sort-utils';
import kebabCase from 'lodash/kebabCase';
import { TranslateService } from '@shared/translate/translate.service';
import { hasValue } from '@products/utils/mappers/mapper.utils';
import { HighchartsThemeService } from '@frk/eds-components';
import { PEER_RANKINGS_KEY } from '@utils/app.constants';
import { ProfileService } from '@services';
import { takeUntil } from 'rxjs/operators';
import { PerformanceComponentId } from '../performance-component-id.enum';

const logger = Logger.getLogger('FundPerformanceDiscreteReturnsService');

@Injectable({
  providedIn: 'root',
})
export class FundPerformanceDiscreteReturnsService
  extends BaseFundPerformanceService
  implements OnDestroy {
  private state: FundPerformanceDiscreteReturnsState = {};
  private fundPerformanceStateSubject: Subject<FundPerformanceDiscreteReturnsState>;
  private product: Product;
  private currentShareClass: ShareClass;
  private hideCurrentShareClassPerformanceData: boolean;
  private maxNumberOfBenchmarksToShow: number;
  private benchmarkOrder: string[];
  private suppressedBenchmarks: string[];
  // private mifidIncepStd: string;
  private asOfDateStd: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  private initialColumns = [
    'oneYear',
    'twoYear',
    'threeYear',
    'fourYear',
    'fiveYear',
    'sixYear',
    'sevenYear',
    'eightYear',
    'nineYear',
    'tenYear',
  ];
  isLoggedIn: boolean;

  constructor(
    private fundDetailService: FundDetailService,
    private config: SiteConfigService,
    translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {
    super(translateService, highchartsTheme, profileService);
    this.fundPerformanceStateSubject = new Subject();
  }

  public get fundPerformanceState$(): Subject<FundPerformanceDiscreteReturnsState> {
    return this.fundPerformanceStateSubject;
  }

  /**
   * Call register method of fund detail service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.product = null;
    this.currentShareClass = null;
    this.hideCurrentShareClassPerformanceData = false;
    this.maxNumberOfBenchmarksToShow = null;
    this.benchmarkOrder = null;
    this.suppressedBenchmarks = null;

    this.isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.fundDetailService
      .register(fundPerformanceDiscreteReturnsQuery, {
        fundId: productDetailConfiguration.fundId,
      })
      .subscribe(
        (product) => {
          logger.debug(product);
          this.currentShareClass = this.findShareClassByShareClassCode(
            product,
            productDetailConfiguration.shareClassCode
          );
          if (
            this.currentShareClass &&
            this.isPerformanceDataAvailable(this.currentShareClass)
          ) {
            this.mapState(product);
            this.state.isError = false;
          } else {
            logger.error(`No Fund Performance Discrete returns data found for fund id ${productDetailConfiguration.fundId}
           and share class ${productDetailConfiguration.shareClassCode} `);
            this.state.isError = true;
          }
          this.fundPerformanceStateSubject.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance Discrete returns data for fund id ${productDetailConfiguration.fundId}
        and share class ${productDetailConfiguration.shareClassCode} `,
            error
          );
          this.state.isError = true;
          this.fundPerformanceStateSubject.next(this.state);
        }
      );
  }

  /**
   * Map the fund performance response.
   * @param product received product performance details
   */
  mapState(product: Product) {
    this.state.perfStatusCaveat = this.getPerfStatusCaveat(
      this.currentShareClass
    );
    this.state.heading = this.translateService.instant(
      'products.performance-discrete-returns-heading' +
        (this.config.getMifidAge(
          this.currentShareClass.performanceInceptionDateStd
        ) || ''),
      product.fundId
    );
    this.product = product;
    this.hideCurrentShareClassPerformanceData = this.config.mustHidePerformanceData(
      this.currentShareClass.performanceInceptionDateStd,
      this.isLoggedIn
    );
    this.maxNumberOfBenchmarksToShow = this.config.getMaxNumberOfBenchmarksToShow(
      this.product.fundId
    );
    this.benchmarkOrder = this.config.getBenchmarkOrder(
      this.product.productTaxonomy
    );
    this.suppressedBenchmarks = this.config.getSuppressedBenchmarks(
      this.product.productTaxonomy
    );
    this.state.config = {};
    this.state.data = this.mapPerformanceDetailsToState();
    this.populateConfig();
  }

  private populateConfig() {
    this.state.config.periodEnds = this.config.getPeriodEnds(
      this.product.productType,
      PerformanceType.DISCRETE
    );
    this.state.config.periodEnd = this.state.config.periodEnds[0];
    this.state.config.showCurrency = !this.config.hidePerformanceCurrency(
      this.product.productTaxonomy
    );
    this.state.config.showMifid = this.hideCurrentShareClassPerformanceData;
    this.setHideChartConfig();
  }

  private setHideChartConfig() {
    const suppressionSet: PerformanceSuppressionSet = this.config.getPerformanceComponentSuppressionSet(
      PerformanceComponentId.DISCRETE,
      this.product.fundId
    );

    if (suppressionSet || this.hideCurrentShareClassPerformanceData) {
      this.state.config.hideChart = true;
    } else {
      const tableData = this.state.data.monthEnd.tableData;
      if (
        !tableData ||
        tableData.length === 0 ||
        this.isDataNotAvailable(tableData[0].oneYear)
      ) {
        this.state.config.hideChart = true;
      }
    }
  }

  setPeriodEnd(periodEnd: string) {
    this.state.config.periodEnd = periodEnd;
    this.fundPerformanceStateSubject.next(this.state);
  }

  /**
   * Removes Dot for the given Dates
   * @param dates received an array of Dates
   * @returns modified array of Dates
   */
  removeDotFromDateRange(dates: DateRange[]): DateRange[] {
    return dates.map((date) => {
      return {
        startDate: date.startDate.replace('.', ''),
        endDate: date.endDate.replace('.', ''),
      };
    });
  }

  mapPerformanceDetailsToState(): FundPerformanceDiscreteReturnsStateData {
    const monthEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.monthEnd,
      this.currentShareClass.benchmarkPerformance.monthEnd,
      'products.month-end'
    );
    const quarterEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.quarterEnd,
      this.currentShareClass.benchmarkPerformance.quarterEnd,
      'products.quarter-end'
    );

    if (
      !this.config.hideAdditionalShareClassLinks(this.product.productTaxonomy)
    ) {
      const allShareClassData = this.getAllShareClassesPerformancePeriodEndData();
      monthEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.MONTHLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          ),
        this.currentShareClass.performance.monthEnd[0].performanceAsOfDateStd
      );

      quarterEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.QUARTERLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          ),
        this.currentShareClass.performance.quarterEnd[0].performanceAsOfDateStd
      );
    }
    return {
      fundName: this.product.fundName,
      monthEnd: monthEndPerformanceData,
      quarterEnd: quarterEndPerformanceData,
    };
  }

  private createAllShareClassData(
    rows: FundPerformanceDiscreteReturnsTableData[],
    asOfDateStd: string
  ): AllShareClassesDiscreteReturnsData {
    // get column to remove
    let clmsToRemove: string[] = this.getColumnsToRemove(rows);

    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemove);

    let chartColumns: string[] = this.getLabelsForChart(clmsToRemove);

    const mifidIncepStd = this.config.isSiteDynamicLabels()
      ? this.currentShareClass.performanceInceptionDateStd
      : null;

    const years = getPreviousYearsDateRange(
      10,
      asOfDateStd,
      API_DATE_FORMAT,
      this.config.getPdsLanguage(),
      'MMM-YY',
      mifidIncepStd
    );
    // Remove descrete year by Index of chart cloumns deleted index
    const yearToRemove: DateRange[] = [];
    this.initialColumns.forEach((cl, index) => {
      if (!chartColumns.includes(cl)) {
        yearToRemove.push(years[index]);
      }
    });
    const currentYears = years.filter((cl) => {
      return !yearToRemove.includes(cl);
    });
    // if german/Austria daterange depending on inception date data to show.
    if (mifidIncepStd !== null) {
      clmsToRemove = this.getYearColumnsToRemove(currentYears.length);

      // Remove colums from Data
      rows = this.removeColumnsFromData(rows, clmsToRemove);
      chartColumns = this.getLabelsForChart(clmsToRemove);
    }

    return {
      years: currentYears,
      tableCols: chartColumns,
      tableData: rows,
    };
  }

  private getAllShareClassesPerformancePeriodEndData(): Map<
    PerformanceView,
    FundPerformanceDiscreteReturnsTableData[]
  > {
    const allShareClassesData: Map<
      PerformanceView,
      FundPerformanceDiscreteReturnsTableData[]
    > = new Map();
    allShareClassesData.set(PerformanceView.MONTHLY, []);
    allShareClassesData.set(PerformanceView.QUARTERLY, []);
    this.product.shareClasses.forEach((shareclass) => {
      if (
        shareclass.identifiers.shareClassCode !==
        this.currentShareClass.identifiers.shareClassCode
      ) {
        const hidePerformanceData = this.config.mustHidePerformanceData(
          shareclass.performanceInceptionDateStd,
          this.isLoggedIn
        );
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        if (shareclass.performance.monthEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.monthEnd
          );
          allShareClassesData
            .get(PerformanceView.MONTHLY)
            .push(performanceData);
        }
        if (shareclass.performance.quarterEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.quarterEnd
          );
          allShareClassesData
            .get(PerformanceView.QUARTERLY)
            .push(performanceData);
        }
      }
    });
    return allShareClassesData;
  }

  private getPerformanceData(
    shareclass: ShareClass,
    defaultCalcType: string,
    hidePerformanceData: boolean,
    performances: PerformanceDetail[]
  ) {
    let performance = performances[0];
    performances.forEach((perf) => {
      if (perf.calcTypeStd === defaultCalcType) {
        performance = perf;
      }
    });
    const performanceData = this.getSHTableRow(
      performance,
      shareclass,
      hidePerformanceData,
      false
    );
    performanceData.link = shareclass.link;
    return performanceData;
  }

  sortRowsOnName(
    performanceData: FundPerformanceDiscreteReturnsTableData[]
  ): FundPerformanceDiscreteReturnsTableData[] {
    return performanceData.sort((current, next) => {
      return stringCompare(current.name, next.name, null, null, false);
    });
  }

  private getCurrentShareClassPerformancePeriodEndData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    label: string
  ): FundPerformanceDiscreteReturnsPeriodEndData {
    const inceptionDate = this.currentShareClass.performanceInceptionDate;
    const inceptionDateStd = this.currentShareClass.performanceInceptionDateStd;
    const asOfDate = performanceArray[0]?.performanceAsOfDate;
    this.asOfDateStd = performanceArray[0]?.performanceAsOfDateStd;

    // NGC-6135: filter bmArray to restrict duplicate entries.
    const filteredBenchmarkArray: BMPerformanceDetail[] = [];
    benchmarkArray.forEach((benchmarkObj: BMPerformanceDetail) => {
      const index: number = filteredBenchmarkArray.findIndex(
        (filteredBenchmarkObj) =>
          filteredBenchmarkObj.benchmarkLabel === benchmarkObj.benchmarkLabel
      );

      if (index === -1) {
        if (benchmarkObj.calcTypeStd === PEER_RANKINGS_KEY) {
          if (
            this.config.showPeerGroupCategoryAverageAddition(
              this.product.productTaxonomy
            )
          ) {
            filteredBenchmarkArray.push(benchmarkObj);
          }
        } else {
          filteredBenchmarkArray.push(benchmarkObj);
        }
      }
    });
    // Filter peer Rankings from performance data
    const filteredPerformanceArray: PerformanceDetail[] = performanceArray.filter(
      (perf: PerformanceDetail) => perf.calcTypeStd !== PEER_RANKINGS_KEY
    );

    let rows: FundPerformanceDiscreteReturnsTableData[] = this.getPerformanceTableData(
      filteredPerformanceArray,
      filteredBenchmarkArray
    );
    // get column to remove
    let clmsToRemove: string[] = this.getColumnsToRemove(rows);

    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemove);

    let graphData = null;
    let chartColumns: string[] = this.getLabelsForChart(clmsToRemove);

    if (!this.hideCurrentShareClassPerformanceData) {
      graphData = this.convertTableToChartData(rows, chartColumns);
      graphData = this.convertToHighchartsColumnSeries(graphData);
    }

    // NGC-7604 Germany+Austria require diffierent date range start
    const mifidIncepStd = this.config.isSiteDynamicLabels()
      ? inceptionDateStd
      : null;

    const yearsC = getPreviousYearsDateRange(
      10,
      this.asOfDateStd,
      API_DATE_FORMAT,
      this.config.getPdsLanguage(),
      'MMM-YY',
      mifidIncepStd
    );

    // Remove descrete year by Index of chart cloumns deleted index
    const yearToRemove: DateRange[] = [];
    this.initialColumns.forEach((cl, index) => {
      if (!chartColumns.includes(cl)) {
        yearToRemove.push(yearsC[index]);
      }
    });
    const currentYears = yearsC.filter((cl) => {
      return !yearToRemove.includes(cl);
    });
    // if german/Austria daterange depending on inception date data to show.
    if (mifidIncepStd !== null) {
      clmsToRemove = this.getYearColumnsToRemove(currentYears.length);

      // Remove colums from Data
      rows = this.removeColumnsFromData(rows, clmsToRemove);
      graphData = null;
      chartColumns = this.getLabelsForChart(clmsToRemove);
      if (!this.hideCurrentShareClassPerformanceData) {
        graphData = this.convertTableToChartData(rows, chartColumns);
        graphData = this.convertToHighchartsColumnSeries(graphData);
      }
    }

    return {
      graphData,
      tableLabel: label,
      tableData: rows,
      years: currentYears,
      tableCols: chartColumns,
      performanceDates: {
        asOfDate,
        asOfDateStd: this.asOfDateStd,
        inceptionDate,
        inceptionDateStd,
      },
    };
  }

  private getPerformanceTableData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[]
  ): FundPerformanceDiscreteReturnsTableData[] {
    const rows: FundPerformanceDiscreteReturnsTableData[] = [];
    const filteredPfArray = this.config.isSouthAfrica()
      ? this.filterPerformanceArrayByCurrencyCode(
          performanceArray,
          this.currentShareClass.shareClassCurrency
        )
      : performanceArray;
    this.addShareClassRows(filteredPfArray, rows);
    this.addBenchmarkRows(benchmarkArray, rows);
    this.addChartColors(rows);
    return rows;
  }
  private getColumnsToRemove(
    rows: FundPerformanceDiscreteReturnsTableData[]
  ): string[] {
    const clmToRemove = [];
    // Search in data if the particular column has no data
    this.initialColumns.forEach((clm: any) => {
      if (
        !rows.some((row) => {
          return hasValue(row, clm);
        })
      ) {
        clmToRemove.push(clm);
      }
    });

    return clmToRemove;
  }
  private getYearColumnsToRemove(columnSize): string[] {
    const intColumns = [...this.initialColumns];
    intColumns.splice(0, columnSize);
    return intColumns;
  }
  private getLabelsForChart(clmToRemove: string[]): string[] {
    // Remove columns
    const columns = [];
    this.initialColumns.forEach((clm) => {
      if (!clmToRemove.includes(clm)) {
        columns.push(clm);
      }
    });

    return columns;
  }

  private removeColumnsFromData(
    rows: FundPerformanceDiscreteReturnsTableData[],
    clmToRemove: string[]
  ): FundPerformanceDiscreteReturnsTableData[] {
    // Remove empty columns from row
    rows.forEach((row) => {
      clmToRemove.forEach((clm) => {
        delete row[clm];
      });
    });
    return rows;
  }

  private addShareClassRows(
    performanceArray: PerformanceDetail[],
    rows: FundPerformanceDiscreteReturnsTableData[]
  ) {
    if (performanceArray) {
      if (performanceArray.length > 0) {
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        const defaultCalcTypeRow = performanceArray.find(
          (perf) => perf.calcTypeStd === defaultCalcType
        );

        if (defaultCalcTypeRow) {
          performanceArray.splice(
            performanceArray.indexOf(defaultCalcTypeRow),
            1
          );
          performanceArray.unshift(defaultCalcTypeRow);
        }
        performanceArray.forEach((perf) => {
          rows.push(
            this.getSHTableRow(
              perf,
              this.currentShareClass,
              this.hideCurrentShareClassPerformanceData
            )
          );
        });
      }
    }
  }

  private addBenchmarkRows(
    benchmarkArray: BMPerformanceDetail[],
    rows: FundPerformanceDiscreteReturnsTableData[]
  ) {
    if (benchmarkArray) {
      if (this.maxNumberOfBenchmarksToShow !== 0) {
        benchmarkArray.sort((bm1, bm2) =>
          this.compareBenchmarks(bm1, bm2, this.benchmarkOrder)
        );
      }
      benchmarkArray = benchmarkArray.slice(
        0,
        this.maxNumberOfBenchmarksToShow
      );
      benchmarkArray.forEach((benchmark, index) => {
        const bmRow = this.getBMTableRow(
          benchmark,
          rows[0],
          this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
          0, // TODO For Germany
          false, // TODO For Germany
          index
        );
        rows.push(bmRow);
      });
    }
  }

  private getSHTableRow(
    performanceData: PerformanceDetail,
    shareClass: ShareClass,
    hideShareClassPerformanceData: boolean,
    hideOnChart: boolean = false
  ): FundPerformanceDiscreteReturnsTableData {
    const basicShareClass = this.extractBasicShareClassFromPerformanceRow(
      performanceData,
      this.product.fundName,
      shareClass
    );
    const oneYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear1;
    const twoYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear2;
    const threeYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear3;
    const fourYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear4;
    const fiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear5;
    const sixYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear6;
    const sevenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear7;
    const eightYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear8;
    const nineYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear9;
    const tenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.discreteAnnualRollingYear10;

    const fundPerformanceTableData: FundPerformanceDiscreteReturnsTableData = Object.assign(
      basicShareClass,
      {
        oneYear,
        twoYear,
        threeYear,
        fourYear,
        fiveYear,
        sixYear,
        sevenYear,
        eightYear,
        nineYear,
        tenYear,
        asOfDate: performanceData.performanceAsOfDate,
        hideOnChart,
        placement: BaseFundPerformanceService.fundPerformancePlacement,
        calcTypeLabel: `products.${this.getProductTypeLabel(
          this.product.productType
        )}-performance-calctype-${kebabCase(performanceData.calcTypeStd)}`,
      }
    );

    return fundPerformanceTableData;
  }

  private getBMTableRow(
    bmPerformanceData: BMPerformanceDetail,
    fundRow: FundPerformanceDiscreteReturnsTableData,
    suppressBMData: boolean,
    yearsSinceStartDate: number,
    suppressYearsBeforeStartDate: boolean,
    index
  ): FundPerformanceDiscreteReturnsTableData {
    const name = bmPerformanceData.benchmarkName;
    const oneYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.oneYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 1)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear1;
    const twoYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.twoYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 2)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear2;
    const threeYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.threeYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 3)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear3;
    const fourYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.fourYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 4)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear4;
    const fiveYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.fiveYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 5)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear5;
    const sixYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.sixYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 6)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear6;
    const sevenYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.sevenYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 7)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear7;
    const eightYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.eightYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 8)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear8;
    const nineYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.nineYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 9)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear9;
    const tenYear =
      (suppressBMData && this.isDataNotAvailable(fundRow.tenYear)) ||
      (suppressYearsBeforeStartDate && yearsSinceStartDate < 10)
        ? EMDASH
        : bmPerformanceData.discreteAnnualRollingYear10;
    return {
      name,
      oneYear,
      twoYear,
      threeYear,
      fourYear,
      fiveYear,
      sixYear,
      sevenYear,
      eightYear,
      nineYear,
      tenYear,
      asOfDate: bmPerformanceData.performanceAsOfDate,
      currency: bmPerformanceData.currencyCode,
      inceptionDate: EMDASH,
      placement: this.getBemchmarkPerformancePlacement(index),
    };
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
