import { Observable, of } from 'rxjs';
import { Logger } from '@utils/logger';
import { ConfigurationId, FundId, ShareClassCode } from '@types';
import { Apollo } from 'apollo-angular';
import { DocumentNode } from '@apollo/client/core';
import { getOperationName } from '@apollo/client/utilities';
import { Injectable } from '@angular/core';
import { delay, mergeMap, shareReplay, retryWhen, tap } from 'rxjs/operators';
import { SiteConfigService } from '@services/site-config.service';
import { PageMonitor } from '@services/page-monitor';

const logger = Logger.getLogger('GraphQLFundDataService');

export enum ProductTypeParameter {
  MUTUAL_FUNDS = 'mf',
  CLOSED_END_FUNDS = 'cef',
  VARIABLE_INSURANCES = 'variable-insurance',
  MONEY_MARKET_FUNDS = 'mmf',
  PORTFOLIOS_529 = '529',
  ETFS = 'etf',
  TIFs = 'tif',
  SMAs = 'sma',
  INTERVAL_FUNDS = 'if',
  TENDER_OFFER_FUNDS = 'tof',
  UAE_FEEDER_FUNDS = 'uaff',
}

export interface FundDataServiceParams {
  configurationName?: ConfigurationId;
  productType?: ProductTypeParameter;
  fundId?: FundId;
  shareClassCode?: ShareClassCode;
  fromDate?: string;
  toDate?: string;
  year?: number;
  asofdate?: number;
  startdate?: number;
  enddate?: number;
  asofdatequarterly?: number; // Specifically, for attribution.
  asofdateyearly?: number; // Specifically, for attribution.
}

@Injectable({
  providedIn: 'root',
})
export class GraphQLFundDataService {
  constructor(
    protected apollo: Apollo,
    protected siteConfigService: SiteConfigService
  ) {}

  public register(
    gqlQuery: DocumentNode,
    variablesObj: Record<string, any> = {}
  ): Observable<any> {
    const req = PageMonitor.startAjaxRequest(getOperationName(gqlQuery));
    return this.apollo
      .query<any>({
        query: gqlQuery,
        variables: variablesObj,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        // in case of error 0 or 500 from the server we retry
        // TODO this needs to be refactored when we do Angular Upgrade
        // DEPRECATED retryWhen is deprecated and needs to be replaced with retry with RetryConfig (new parameters since ver 9)
        retryWhen((error) => {
          return error.pipe(
            mergeMap((lastError, index) => {
              if (
                index < 1 &&
                (lastError?.networkError?.status === 0 ||
                  lastError?.networkError?.status === 500)
              ) {
                logger.debug(index + ' - inside retry GraphQL ...');
                return of(lastError).pipe(delay(500));
              }
              throw lastError;
            })
          );
        }),
        tap(() => {
          PageMonitor.endAjaxRequest(req);
        }),
        shareReplay(1)
      );
  }

  public getCountry() {
    return this.siteConfigService.getPdsCountry();
  }

  public getLanguage() {
    return this.siteConfigService.getPdsLanguage();
  }

  public getDateFormat() {
    return this.siteConfigService.common.dateFormat;
  }
}
