<ng-container *ngIf="page">
  <div *ngIf="tableData">
    <ng-container *ngIf="brConfig?.fundId; else previewBlock">
      <ng-container *ngIf="tableData">
        <div edsRow>
          <div edsCol>
            <eds-section-header
              [title]="
                'products.performance-monthly-total-returns'
                  | translate: brConfig.fundId
              "
              [level]="3"
              [size]="'h3'"
              theme="basic"
              [asOfLabel]="'products.as-of' | translate"
              [asOfDate]="currentAsOfDate"
              caveatPlacement="Performance:PerformanceMonthlyTotalReturns"
              edsSpacing="md"
            ></eds-section-header>
          </div>
        </div>

        <div
          edsSpacing="sm"
          edsFlexbox
          flexWrap="wrap"
          alignItems="center"
          justifyContent="space-between"
        >
          <div edsGrid>
            <!-- Top proximal caveat -->
            <ng-container *ngIf="tableData">
              <eds-proximal
                placement="PerformanceMonthlyTotalReturnsProximalTop"
              ></eds-proximal>
            </ng-container>

            <!-- Table/Chart toggle -->
            <div edsFlexbox justifyContent="right">
              <eds-toggle
                [toggleButtons]="toggleButtons"
                (toggleActive)="onToggleActive($event)"
              ></eds-toggle>
            </div>
          </div>
        </div>

        <!-- Chart section -->
        <div [hidden]="selectedTab === 'table'" edsSpacing="sm">
          <ft-fund-performance-monthly-total-returns-chart
            [highChartOptions]="highChartOptions"
          ></ft-fund-performance-monthly-total-returns-chart>
        </div>

        <!-- Table section -->
        <div *ngIf="selectedTab === 'table'" edsSpacing="sm">
          <ft-fund-performance-monthly-total-returns-table
            [tableData]="tableData"
            [shareClass]="shareClass"
            [monthlyTotalReturnsTableTotalPages]="
              monthlyTotalReturnsTableTotalPages
            "
            (selectedPage)="onPageChanged($event)"
          ></ft-fund-performance-monthly-total-returns-table>
        </div>

        <!-- Bottom Proximal Caveat -->
        <ng-container *ngIf="tableData">
          <eds-proximal
            placement="PerformanceMonthlyTotalReturnsProximalBottom"
          ></eds-proximal>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</ng-container>

<ng-template #previewBlock>
  <h5>Monthly Total Returns Chart</h5>
</ng-template>
