import { Injectable, OnDestroy } from '@angular/core';
import {
  BMPerformanceDetail,
  PerformanceDetail,
  Product,
  ShareClass,
} from '@models';
import { SiteConfigService } from '@services/site-config.service';
import {
  API_DATE_FORMAT,
  EMDASH,
} from '@products/utils/constants/product.constants';
import {
  AllShareClassesCalendarData,
  FundId,
  FundPerformanceCalendarYearPeriodEndData,
  FundPerformanceCalendarYearState,
  FundPerformanceCalendarYearStateData,
  FundPerformanceCalendarYearTableData,
  PerformanceSuppressionSet,
  PerformanceType,
  PerformanceView,
  ProductDetailConfiguration,
  ShareClassCode,
} from '@types';
import { Logger } from '@utils/logger';
import { Subject } from 'rxjs';
import fundPerformanceCalendarYearQuery from '@graphql/fund-performance/calendar-year.graphql';
import { BaseFundPerformanceService } from './base-fund-performance.service';
import { getDateMonth, getDateYear } from '@utils/text/date-utils';
import { FundDetailService } from '@products/services/fund-detail.service';
import { stringCompare } from '@utils/sort-utils';
import kebabCase from 'lodash/kebabCase';
import { TranslateService } from '@shared/translate/translate.service';
import { hasValue } from '@products/utils/mappers/mapper.utils';
import { HighchartsThemeService } from '@frk/eds-components';
import { PEER_RANKINGS_KEY } from '@utils/app.constants';
import { takeUntil } from 'rxjs/operators';
import { ProfileService } from '@services';
import { PerformanceComponentId } from '../performance-component-id.enum';

const logger = Logger.getLogger('FundPerformanceCalendarYearService');

@Injectable({
  providedIn: 'root',
})
export class FundPerformanceCalendarYearService
  extends BaseFundPerformanceService
  implements OnDestroy {
  private state: FundPerformanceCalendarYearState = {};
  private fundPerformanceStateSubject: Subject<FundPerformanceCalendarYearState>;
  private product: Product;
  private currentShareClass: ShareClass;
  private hideCurrentShareClassPerformanceData: boolean;
  private maxNumberOfBenchmarksToShow: number;
  private benchmarkOrder: string[];
  private suppressedBenchmarks: string[];
  private initialColumns = [
    'yearToDate',
    'oneYear',
    'twoYear',
    'threeYear',
    'fourYear',
    'fiveYear',
    'sixYear',
    'sevenYear',
    'eightYear',
    'nineYear',
    'tenYear',
  ];
  // private asOfDate: string;
  // private asOfDateStd: string;
  private unsubscribe$: Subject<void> = new Subject<void>();
  isLoggedIn: boolean;

  constructor(
    private fundDetailService: FundDetailService,
    private config: SiteConfigService,
    translateService: TranslateService,
    protected highchartsTheme: HighchartsThemeService,
    protected profileService: ProfileService
  ) {
    super(translateService, highchartsTheme, profileService);
    this.fundPerformanceStateSubject = new Subject();
  }

  public get fundPerformanceState$(): Subject<FundPerformanceCalendarYearState> {
    return this.fundPerformanceStateSubject;
  }

  /**
   * Call register method of fund Detail service.
   */
  public populate(
    productDetailConfiguration: ProductDetailConfiguration
  ): void {
    this.product = null;
    this.currentShareClass = null;
    this.hideCurrentShareClassPerformanceData = false;
    // this.hidePerformanceData = false;
    this.maxNumberOfBenchmarksToShow = null;
    this.benchmarkOrder = null;
    this.suppressedBenchmarks = null;

    this.isLoggedIn$()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((isLoggedIn: boolean) => {
        this.isLoggedIn = isLoggedIn;
      });

    this.fundDetailService
      .register(fundPerformanceCalendarYearQuery, {
        fundId: productDetailConfiguration.fundId,
        shareClassCode: productDetailConfiguration.shareClassCode,
      })
      .subscribe(
        (product) => {
          logger.debug(product);
          this.currentShareClass = this.findShareClassByShareClassCode(
            product,
            productDetailConfiguration.shareClassCode
          );
          if (
            this.currentShareClass &&
            this.isPerformanceDataAvailable(this.currentShareClass)
          ) {
            this.mapState(product);
            this.state.isError = false;
          } else {
            logger.error(`No Fund Performance CalendarYear data found for fund id ${productDetailConfiguration.fundId}
           and share class ${productDetailConfiguration.shareClassCode} `);
            this.state.isError = true;
          }
          this.fundPerformanceStateSubject.next(this.state);
        },
        (error) => {
          logger.error(
            `Error in getting Fund Performance CalendarYear data for fund id ${productDetailConfiguration.fundId}
        and share class ${productDetailConfiguration.shareClassCode} `,
            error
          );
          this.state.isError = true;
          this.fundPerformanceStateSubject.next(this.state);
        }
      );
  }

  /**
   * Map the fund performance response.
   * @param product received product performance details
   */
  mapState(product: Product) {
    this.state.perfStatusCaveat = this.getPerfStatusCaveat(
      this.currentShareClass
    );
    this.state.heading = this.translateService.instant(
      'products.performance-calendar-year-heading' +
        (this.config.getMifidAge(
          this.currentShareClass.performanceInceptionDateStd
        ) || '')
    );
    this.product = product;
    this.hideCurrentShareClassPerformanceData = this.config.mustHidePerformanceData(
      this.currentShareClass.performanceInceptionDateStd,
      this.isLoggedIn
    );
    this.maxNumberOfBenchmarksToShow = this.config.getMaxNumberOfBenchmarksToShow(
      this.product.fundId
    );
    this.benchmarkOrder = this.config.getBenchmarkOrder(
      this.product.productTaxonomy
    );
    this.suppressedBenchmarks = this.config.getSuppressedBenchmarks(
      this.product.productTaxonomy
    );
    this.state.config = {};
    this.state.data = this.mapPerformanceDetailsToState();
    this.populateConfig();
  }
  private populateConfig() {
    this.state.config.periodEnds = this.config.getPeriodEnds(
      this.product.productType,
      PerformanceType.CALENDAR_YEAR
    );
    this.state.config.periodEnd = this.state.config.periodEnds[0];
    this.state.config.showCurrency = !this.config.hidePerformanceCurrency(
      this.product.productTaxonomy
    );
    this.state.config.showMifid = this.hideCurrentShareClassPerformanceData;
    this.setHideChartConfig();
  }

  private setHideChartConfig() {
    const suppressionSet: PerformanceSuppressionSet = this.config.getPerformanceComponentSuppressionSet(
      PerformanceComponentId.CALENDAR_YEAR,
      this.product.fundId
    );

    if (suppressionSet || this.hideCurrentShareClassPerformanceData) {
      this.state.config.hideChart = true;
    } else {
      const tableData = this.state.data.monthEnd.tableData;
      if (
        !tableData ||
        tableData.length === 0 ||
        this.isDataNotAvailable(tableData[0].oneYear)
      ) {
        this.state.config.hideChart = true;
      }
    }
  }

  setPeriodEnd(periodEnd: string) {
    this.state.config.periodEnd = periodEnd;
    this.fundPerformanceStateSubject.next(this.state);
  }

  mapPerformanceDetailsToState(): FundPerformanceCalendarYearStateData {
    const monthEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.monthEnd,
      this.currentShareClass.benchmarkPerformance.monthEnd,
      'products.month-end'
    );
    const quarterEndPerformanceData = this.getCurrentShareClassPerformancePeriodEndData(
      this.currentShareClass.performance.quarterEnd,
      this.currentShareClass.benchmarkPerformance.quarterEnd,
      'products.quarter-end'
    );
    if (
      !this.config.hideAdditionalShareClassLinks(this.product.productTaxonomy)
    ) {
      const allShareClassData = this.getAllShareClassesPerformancePeriodEndData();

      monthEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.MONTHLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          ),
        this.currentShareClass.performance.monthEnd[0].performanceAsOfDateStd
      );

      quarterEndPerformanceData.allShareClassesTableData = this.createAllShareClassData(
        allShareClassData
          .get(PerformanceView.QUARTERLY)
          .sort((current, next) =>
            stringCompare(current.name, next.name, null, null, false)
          ),
        this.currentShareClass.performance.quarterEnd[0].performanceAsOfDateStd
      );
    }

    return {
      fundName: this.product.fundName,
      monthEnd: monthEndPerformanceData,
      quarterEnd: quarterEndPerformanceData,
    };
  }

  private createAllShareClassData(
    rows: FundPerformanceCalendarYearTableData[],
    asOfDateStd: string
  ): AllShareClassesCalendarData {
    // get column to remove
    const clmsToRemove = this.getColumnsToRemove(rows);
    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemove);
    const chartColumns = this.getLabelsForChart(clmsToRemove);
    const yearColumns = chartColumns.filter((col) => col !== 'yearToDate');
    const calendarYears = this.getCalendarYears(
      getDateYear(asOfDateStd, API_DATE_FORMAT),
      getDateMonth(asOfDateStd, API_DATE_FORMAT),
      true,
      yearColumns
    );

    const showYtd =
      !this.config.product.performance?.hideYTDForCumulativeAndCalendarYear &&
      chartColumns.includes('yearToDate');
    return {
      years: calendarYears,
      tableCols: yearColumns,
      showYearToDate: showYtd,
      tableData: rows,
    };
  }

  private getAllShareClassesPerformancePeriodEndData(): Map<
    PerformanceView,
    FundPerformanceCalendarYearTableData[]
  > {
    const allShareClassesData: Map<
      PerformanceView,
      FundPerformanceCalendarYearTableData[]
    > = new Map();
    allShareClassesData.set(PerformanceView.MONTHLY, []);
    allShareClassesData.set(PerformanceView.QUARTERLY, []);
    this.product.shareClasses.forEach((shareclass) => {
      if (
        shareclass.identifiers.shareClassCode !==
        this.currentShareClass.identifiers.shareClassCode
      ) {
        const hidePerformanceData = this.config.mustHidePerformanceData(
          shareclass.performanceInceptionDateStd,
          this.isLoggedIn
        );
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        if (shareclass.performance.monthEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.monthEnd
          );
          allShareClassesData
            .get(PerformanceView.MONTHLY)
            .push(performanceData);
        }
        if (shareclass.performance.quarterEnd?.length > 0) {
          const performanceData = this.getPerformanceData(
            shareclass,
            defaultCalcType,
            hidePerformanceData,
            shareclass.performance.quarterEnd
          );
          allShareClassesData
            .get(PerformanceView.QUARTERLY)
            .push(performanceData);
        }
      }
    });
    return allShareClassesData;
  }

  private getPerformanceData(
    shareclass: ShareClass,
    defaultCalcType: string,
    hidePerformanceData: boolean,
    performances: PerformanceDetail[]
  ) {
    let performance = performances[0];
    performances.forEach((perf) => {
      if (perf.calcTypeStd === defaultCalcType) {
        performance = perf;
      }
    });
    const performanceData = this.getSHTableRow(
      performance,
      shareclass,
      hidePerformanceData
    );
    performanceData.link = shareclass.link;
    return performanceData;
  }

  sortRowsOnName(
    performanceData: FundPerformanceCalendarYearTableData[]
  ): FundPerformanceCalendarYearTableData[] {
    return performanceData.sort((current, next) => {
      return stringCompare(current.name, next.name, null, null, false);
    });
  }

  private getCurrentShareClassPerformancePeriodEndData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    label: string
  ): FundPerformanceCalendarYearPeriodEndData {
    const inceptionDate = this.currentShareClass.performanceInceptionDate;
    const inceptionDateStd = this.currentShareClass.performanceInceptionDateStd;
    // const asOfDate = performanceArray[0]?.performanceAsOfDate;
    // const asOfDateStd = performanceArray[0]?.performanceAsOfDateStd;
    const asOfDate = performanceArray[0]?.performanceAsOfDate;
    const asOfDateStd = performanceArray[0]?.performanceAsOfDateStd;
    // NGC-6135: filter bmArray to restrict duplicate entries.
    const filteredBenchmarkArray: BMPerformanceDetail[] = [];
    benchmarkArray.forEach((benchmarkObj) => {
      const index: number = filteredBenchmarkArray.findIndex(
        (filteredBenchmarkObj) =>
          filteredBenchmarkObj.benchmarkLabel === benchmarkObj.benchmarkLabel
      );

      if (index === -1) {
        filteredBenchmarkArray.push(benchmarkObj);
      }
    });

    let rows: FundPerformanceCalendarYearTableData[] = this.getPerformanceTableData(
      performanceArray,
      filteredBenchmarkArray,
      this.product.fundId,
      this.currentShareClass.shareClassCode
    );
    // get column to remove
    const clmsToRemove = this.getColumnsToRemove(rows);

    // Remove colums from Data
    rows = this.removeColumnsFromData(rows, clmsToRemove);
    let graphData = null;
    const chartColumns = this.getLabelsForChart(clmsToRemove);
    const yearColumns = chartColumns.filter((col) => col !== 'yearToDate');
    const calendarYears = this.getCalendarYears(
      getDateYear(asOfDateStd, API_DATE_FORMAT),
      getDateMonth(asOfDateStd, API_DATE_FORMAT),
      true,
      yearColumns
    );
    const showYtd =
      !this.config.product.performance?.hideYTDForCumulativeAndCalendarYear &&
      chartColumns.includes('yearToDate');
    if (!this.hideCurrentShareClassPerformanceData) {
      graphData = this.convertTableToChartData(rows, yearColumns);
      graphData = this.convertToHighchartsColumnSeries(graphData);
    }
    return {
      graphData,
      years: calendarYears,
      tableCols: yearColumns,
      showYearToDate: showYtd,
      tableData: rows,
      tableLabel: label,
      performanceDates: {
        asOfDate,
        asOfDateStd,
        inceptionDate,
        inceptionDateStd,
      },
    };
  }
  private getLabelsForChart(clmToRemove: string[]): string[] {
    // Remove columns
    const columns = [];
    this.initialColumns.forEach((clm) => {
      if (!clmToRemove.includes(clm)) {
        columns.push(clm);
      }
    });

    return columns;
  }

  private getPerformanceTableData(
    performanceArray: PerformanceDetail[],
    benchmarkArray: BMPerformanceDetail[],
    fundId: FundId,
    shareClassCode: ShareClassCode
  ): FundPerformanceCalendarYearTableData[] {
    const rows: FundPerformanceCalendarYearTableData[] = [];
    let filteredPfArray = this.config.isSouthAfrica()
      ? this.filterPerformanceArrayByCurrencyCode(
          performanceArray,
          this.currentShareClass.shareClassCurrency
        )
      : performanceArray;

    // WDE-4201 remove POP rows for CPREIF and for TOF(product type)
    if (
      this.config.hidePerformanceTableRowPop(
        shareClassCode,
        this.product.productType
      )
    ) {
      filteredPfArray = this.filterOutCalcType(filteredPfArray, 'POP');
    }

    this.addShareClassRows(filteredPfArray, rows);
    this.addBenchmarkRows(benchmarkArray, rows);
    this.addChartColors(rows);
    this.movePeerRankingsToEnd(rows);
    return rows;
  }

  private getColumnsToRemove(
    rows: FundPerformanceCalendarYearTableData[]
  ): string[] {
    const clmToRemove = [];
    // Search in data if the particular column has no data
    this.initialColumns.forEach((clm: any) => {
      if (
        !rows.some((row) => {
          return hasValue(row, clm);
        })
      ) {
        clmToRemove.push(clm);
      }
    });

    return clmToRemove;
  }

  private removeColumnsFromData(
    rows: FundPerformanceCalendarYearTableData[],
    clmToRemove: string[]
  ): FundPerformanceCalendarYearTableData[] {
    // Remove empty columns from row
    rows.forEach((row) => {
      if (row !== undefined) {
        clmToRemove.forEach((clm) => {
          delete row[clm];
        });
      }
    });
    return rows;
  }

  private addShareClassRows(
    performanceArray: PerformanceDetail[],
    rows: FundPerformanceCalendarYearTableData[]
  ) {
    if (performanceArray) {
      if (performanceArray.length > 0) {
        const defaultCalcType = this.config.getDefaultCalcType(
          this.product.productType
        );
        const defaultCalcTypeRow = performanceArray.find(
          (perf) => perf.calcTypeStd === defaultCalcType
        );

        if (defaultCalcTypeRow) {
          performanceArray.splice(
            performanceArray.indexOf(defaultCalcTypeRow),
            1
          );
          performanceArray.unshift(defaultCalcTypeRow);
        }
        performanceArray.forEach((perf: PerformanceDetail) => {
          if (perf.calcTypeStd !== PEER_RANKINGS_KEY) {
            // Filter peer Rankings from performance data
            rows.push(
              this.getSHTableRow(
                perf,
                this.currentShareClass,
                this.hideCurrentShareClassPerformanceData
              )
            );
          }
        });
      }
    }
  }

  private addBenchmarkRows(
    benchmarkArray: BMPerformanceDetail[],
    rows: FundPerformanceCalendarYearTableData[]
  ) {
    if (benchmarkArray) {
      if (this.maxNumberOfBenchmarksToShow !== 0) {
        benchmarkArray.sort((bm1, bm2) =>
          this.compareBenchmarks(bm1, bm2, this.benchmarkOrder)
        );
      }
      benchmarkArray = benchmarkArray.slice(
        0,
        this.maxNumberOfBenchmarksToShow
      );
      benchmarkArray.forEach(
        (benchmark: BMPerformanceDetail, index: number) => {
          if (benchmark.calcTypeStd === PEER_RANKINGS_KEY) {
            // Display peer rankings only for funds for which it has been configured to show
            if (
              this.config.showPeerGroupCategoryAverageAddition(
                this.product.productTaxonomy
              )
            ) {
              const bmRow = this.getBMTableRow(
                benchmark,
                rows,
                this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
                index
              );
              rows.push(bmRow);
            }
          } else {
            const bmRow = this.getBMTableRow(
              benchmark,
              rows,
              this.suppressedBenchmarks.includes(benchmark.benchmarkLabel),
              index
            );
            rows.push(bmRow);
          }
        }
      );
    }
  }

  private movePeerRankingsToEnd(
    rows: FundPerformanceCalendarYearTableData[]
  ): void {
    const index: number = rows.findIndex(
      (row: FundPerformanceCalendarYearTableData) => row.isPeerRankingsRow
    );
    rows.push(rows.splice(index, 1)[0]);
  }

  private getSHTableRow(
    performanceData: PerformanceDetail,
    shareClass: ShareClass,
    hideShareClassPerformanceData: boolean,
    hideOnChart: boolean = false
  ): FundPerformanceCalendarYearTableData {
    const basicShareClass = this.extractBasicShareClassFromPerformanceRow(
      performanceData,
      this.product.fundName,
      shareClass
    );
    const oneYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn1Year;
    const twoYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn2Year;
    const threeYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn3Year;
    const fourYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn4Year;
    const fiveYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn5Year;
    const sixYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn6Year;
    const sevenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn7Year;
    const eightYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn8Year;
    const nineYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn9Year;
    const tenYear = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.calendarYearReturn10Year;
    const yearToDate = hideShareClassPerformanceData
      ? EMDASH
      : performanceData.cummulativeReturnYearToDate;

    const fundPerformanceTableData: FundPerformanceCalendarYearTableData = Object.assign(
      basicShareClass,
      {
        oneYear,
        twoYear,
        threeYear,
        fourYear,
        fiveYear,
        sixYear,
        sevenYear,
        eightYear,
        nineYear,
        tenYear,
        yearToDate,
        asOfDate: performanceData.performanceAsOfDate,
        hideOnChart,
        placement: BaseFundPerformanceService.fundPerformancePlacement,
        calcTypeLabel: `products.${this.getProductTypeLabel(
          this.product.productType
        )}-performance-calctype-${kebabCase(performanceData.calcTypeStd)}`,
        isPeerRankingsRow: performanceData.calcTypeStd === PEER_RANKINGS_KEY,
      }
    );

    return fundPerformanceTableData;
  }

  private getBMTableRow(
    bmPerformanceData: BMPerformanceDetail,
    fundRows: FundPerformanceCalendarYearTableData[],
    suppressBMData: boolean,
    index: number
  ): FundPerformanceCalendarYearTableData {
    const name = bmPerformanceData.benchmarkName;
    const oneYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'oneYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn1Year;
    const twoYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'twoYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn2Year;
    const threeYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'threeYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn3Year;
    const fourYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'fourYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn4Year;
    const fiveYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'fiveYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn5Year;
    const sixYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'sixYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn6Year;
    const sevenYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'sevenYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn7Year;
    const eightYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'eightYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn8Year;
    const nineYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'nineYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn9Year;
    const tenYear =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'tenYear'))
        ? EMDASH
        : bmPerformanceData.calendarYearReturn10Year;
    const yearToDate =
      suppressBMData && !fundRows.some((row) => hasValue(row, 'yearToDate'))
        ? EMDASH
        : bmPerformanceData.cummulativeReturnYearToDate;

    return {
      name,
      oneYear,
      twoYear,
      threeYear,
      fourYear,
      fiveYear,
      sixYear,
      sevenYear,
      eightYear,
      nineYear,
      tenYear,
      yearToDate,
      asOfDate: bmPerformanceData.performanceAsOfDate,
      inceptionDate: EMDASH,
      currency: bmPerformanceData.currencyCode,
      placement: this.getBemchmarkPerformancePlacement(index),
    };
  }

  private getCalendarYears(
    lastYear: number,
    lastMonth: number,
    isReversed: boolean,
    chartColumns: string[]
  ) {
    let startYear = lastYear - 10;
    let yearAry = [];
    if (!isReversed) {
      while (startYear < lastYear) {
        yearAry.push(startYear);
        startYear++;
      }
    } else {
      // COREWEB-3916 / COREWEB-3769: if month december, then need to add year
      if (lastMonth < 11) {
        lastYear = lastYear - 1;
        startYear = startYear - 1;
      }
      while (lastYear >= startYear + 1) {
        yearAry.push(lastYear);
        lastYear--;
      }
    }

    // Remove calander year by Index of chart cloumn's deleted index
    const yearToRemove = [];
    this.initialColumns
      .filter((col) => {
        return col !== 'yearToDate';
      })
      .forEach((cl, index) => {
        if (!chartColumns.includes(cl)) {
          yearToRemove.push(yearAry[index]);
        }
      });
    yearAry = yearAry.filter((cl) => {
      return !yearToRemove.includes(cl);
    });

    return yearAry;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
