import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import { InvestmentTeam } from '@models';
import { InvestmentTeamDTO } from '@types';

export class InvestmentTeamsMapper extends Mapper<InvestmentTeam[]> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(investmentTeamsDto: InvestmentTeamDTO[]): InvestmentTeam[] {
    const investmentTeamMapper = this.createMapper(InvestmentTeamMapper);
    return investmentTeamsDto
      .map((investmentTeamDto) =>
        investmentTeamMapper.toDomain(investmentTeamDto)
      )
      .sort((a, b) => a.managerOrder - b.managerOrder);
  }
}

export class InvestmentTeamMapper extends Mapper<InvestmentTeam> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(investmentTeamDto: InvestmentTeamDTO): InvestmentTeam {
    return {
      fundId: investmentTeamDto.fundid,
      employeeId: investmentTeamDto.mngrempid,
      countryCode: investmentTeamDto.cntrycode,
      languageCode: investmentTeamDto.langcode,
      managerName: investmentTeamDto.mngrname,
      managerOrder: investmentTeamDto.mngrorder
        ? Number(investmentTeamDto.mngrorder)
        : Number.MAX_VALUE,
      startDateFundManagement: investmentTeamDto.strtdtfundmngmt,
      startDateFundManagementStd: investmentTeamDto.strtdtfundmngmtstd,
      managerCity: investmentTeamDto.mngrcity,
      managerDesignation: investmentTeamDto.mngrdesignation,
      yearsOfService: investmentTeamDto.yrssvc,
      yearsInIndustry: investmentTeamDto.yrsindustry,
      yearsOfServiceEffectiveDate: investmentTeamDto.yrssvceffdte,
      cfa: investmentTeamDto.cfa,
      mba: investmentTeamDto.mba,
      phd: investmentTeamDto.phd,
      cpa: investmentTeamDto.cpa,
      frm: investmentTeamDto.frm,
    };
  }
}
