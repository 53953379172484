import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { PerformanceDetail, Product, ShareClass } from '@models';
import { FundOverviewService } from '@products/services/fund-overview.service';
import { forkJoin, Observable } from 'rxjs';
import {
  FavouriteFund,
  HypoRootObject,
  SalesTeamRootObject,
} from './my-dashboard.interface';
import fundFavoriteQuery from '@graphql/overview/fund-favourite.graphql';
import { ConfigurationId, FundId, ProductType, ShareClassCode } from '@types';
import { EMDASH } from '@products/utils/constants/product.constants';
import { SiteConfigService } from '@services/site-config.service';
import { hasValue } from '@products/utils/mappers/mapper.utils';

@Injectable({
  providedIn: 'root',
})
export class MyDashboardService {
  private favoritesData: FavouriteFund[] = [];
  constructor(
    private http: HttpClient,
    private fundOverviewService: FundOverviewService,
    private siteConfigService: SiteConfigService
  ) {}

  getSavedHypos$(
    yyyToken: string,
    hypoUrl: string
  ): Observable<HypoRootObject> {
    const url = hypoUrl + yyyToken;
    return this.http.get<HypoRootObject>(url);
  }

  getSalesTeamContact$(
    dxpMrkDomain: string,
    expressNum: string
  ): Observable<SalesTeamRootObject> {
    const url = `${dxpMrkDomain}${expressNum}`;
    return this.http.get<SalesTeamRootObject>(url);
  }

  populateFavourite(favourites: string[]): Observable<FavouriteFund[]> {
    this.favoritesData = [];
    favourites.forEach((favourite) => {
      const favObj = favourite?.split('-');
      this.favoritesData.push({
        fundId: favObj[0] as FundId,
        shareClassCode: favObj[1] as ShareClassCode,
        type: 'card',
      });
    });

    const favoritesObservables: Array<
      Observable<FavouriteFund>
    > = this.favoritesData.map(
      (item: FavouriteFund): Observable<FavouriteFund> =>
        this.fundOverviewService
          .register(fundFavoriteQuery, {
            fundId: item.fundId,
            shareClassCode: item.shareClassCode,
          })
          .pipe(
            map((product: Product) => {
              if (product && product?.shareClasses.length > 0) {
                item.productType = product?.productType as ConfigurationId;
                const shareClass: ShareClass = product?.shareClasses[0];
                item.asOfDate = shareClass?.nav?.navAsOfDate;
                item.name = this.getFundName(product);
                item.fundName = product.fundName;
                item.url = shareClass?.isSoftLaunch ? '' : shareClass?.link;
                item.type = 'card';
                item.items = [];
                item.items[0] = {
                  label: '',
                  val: '',
                };
                let ytdValue = shareClass?.nav?.navYearToDateReturn;
                if (
                  item.productType === ConfigurationId.ETF ||
                  item.productType === ConfigurationId.CEF
                ) {
                  ytdValue = shareClass?.nav?.marketPriceYearToDateReturn;
                  item.asOfDate = shareClass?.nav?.marketPriceAsOfDate;
                }
                if (item.productType === ConfigurationId.SMA) {
                  const defaultCalcType = this.siteConfigService.getDefaultCalcType(
                    item.productType as ProductType
                  );
                  const performanceData = this.fetchPerformanceData(
                    shareClass?.performance?.quarterEnd,
                    defaultCalcType
                  );
                  ytdValue = performanceData?.cummulativeReturnYearToDate;
                  item.asOfDate = performanceData?.performanceAsOfDate;
                }
                item.items[0].val = ytdValue || EMDASH;
              }
              return item;
            })
          )
    );
    return forkJoin(favoritesObservables);
  }

  fetchPerformanceData(
    performanceData: PerformanceDetail[],
    defaultCalcType: string
  ): PerformanceDetail {
    performanceData.sort(
      (first, second) =>
        [defaultCalcType].indexOf(second.calcTypeStd) -
        [defaultCalcType].indexOf(first.calcTypeStd)
    );
    return performanceData[0];
  }

  public getFundName(product: Product): string {
    const shareClassSuffix = this.getShareClassSuffix(product);
    const identifierSuffix = this.getIdentifierSuffix(product);
    let fundName = product.fundName;
    if (shareClassSuffix) {
      fundName = fundName + ' - ' + shareClassSuffix;
    }
    if (identifierSuffix) {
      fundName = fundName + ' - ' + identifierSuffix;
    }
    return fundName;
  }

  private getShareClassSuffix(product: Product): string {
    const shareClass = product.shareClasses[0];
    return shareClass?.shareClassName && !shareClass.isSingleShareClass
      ? `${shareClass.shareClassName}`
      : '';
  }

  private getIdentifierSuffix(product: Product): string {
    const shareClass = product.shareClasses[0];
    const identifierKey = product.productTaxonomy
      ? this.siteConfigService.getDefaultIdentifierKey(product.productTaxonomy)
      : null;
    const identifierSuffix: string =
      identifierKey in shareClass.identifiers &&
      hasValue(shareClass.identifiers, identifierKey)
        ? `${shareClass.identifiers?.[identifierKey]}`
        : '';
    return identifierSuffix;
  }
}
