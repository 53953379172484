import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';

import { FullResultsComponent } from '../../full-results.component';
import {
  ItemHeader,
  LiteratureData,
} from '@search/interfaces/search.interface';

import { SimpleModalConfig } from '@frk/eds-components';

import { CartActionPerformed, ModalId, SegmentId } from '@types';

import { Logger } from '@utils/logger';
import { EMDASH } from '@products/utils/constants/product.constants';
const logger = Logger.getLogger('FullResultsLiteratureComponent');
@Component({
  selector: 'ft-full-results-literature',
  templateUrl: './full-results-literature.component.html',
  styleUrls: [
    '../../full-results.component.scss',
    './full-results-literature.component.scss',
  ],
})
export class FullResultsLiteratureComponent
  extends FullResultsComponent
  implements OnChanges, OnInit, OnDestroy {
  @Input() literatureHeader: ItemHeader;
  @Input() literatureItems: object[]; // TODO set proper type
  @Input() literatureData: LiteratureData[];
  @Input() fundData: any; // TODO set proper type
  @Input() isSiteInternational?: boolean;
  @Output() viewMoreClickedLiterature = new EventEmitter<string>();

  showFinra = false;
  ModalId = ModalId; // this is to use ModaiId in component / see NGC-7598 changes
  public modalId = 'searchCartModal';

  ngOnInit() {
    super.ngOnInit();
  }

  /**
   * we need to pre-process data to simplify template actions
   * we will also check which literature document is in the cart
   */
  ngOnChanges() {
    // show finra column only for FA user
    // this may require refactoring after insights is implemented
    // search needs to reload config, after segment is changed
    this.showFinra = false;
    if (
      SegmentId.FINANCIAL_PROFESSIONALS === this.configService.currentSegment
    ) {
      this.showFinra = true;
    }
  }

  openModal(modelId: string) {
    const config: SimpleModalConfig = {
      modalId: modelId,
      type: 'simple',
      themeTitle: 'dark',
    };
    this.modalService.open(modelId, config);
  }

  /**
   *  this may require refactoring
   * there is an issue with inheritance of Output
   * https://github.com/angular/angular/issues/5415
   *
   */
  onViewMore($tabName: string) {
    this.activeTab = $tabName;
    this.viewMoreClickedLiterature.emit($tabName);
  }

  updateCart(data) {
    const docID = data.litCode !== EMDASH ? data.litCode : data.customFilePath;
    if (this.cartItems.includes(docID)) {
      this.deleteFromCart(docID);
      return;
    }
    this.addToCart(data);
  }

  /**
   * deletes product from cart
   * @param docId literature code
   */
  deleteFromCart(docId: string) {
    this.cartHandlerService.removeFromCart(
      [docId],
      CartActionPerformed.DELETED_FROM_SEARCH
    );
    this.cartItems = this.cartItems.filter((item) => item !== docId);
  }
}
