<div edsRow>
  <div edsCol>
    <ng-container *ngFor="let button of buttonsCollection">
      <a
        [href]="button.href"
        [target]="button.target"
        [ngClass]="button.externalLinkClass"
        class="ft-button-block"
        [edsButton]="button.buttonStyle"
        [attr.data-signin-required]="button.signInRequired"
        [attr.data-doc-id]="button.dataDocId"
        [attr.data-title]="button?.dataTitle"
        [attr.data-document-title]="button?.dataTitle"
        [attr.data-external-id]="button?.externalId"
        [attr.rel]="button?.noindex ? 'noindex nofollow' : null"
        (click)="
          checkSignIn($event, button.linkCompound, button.downloadLocation)
        "
      >
        {{ button.ctaText }}
        <eds-icon
          *ngIf="
            button.signInRequired && !button.hideLockIcon
              | ftIsSignInRequired
              | async
          "
          edsPadding
          edsPaddingLeft="xs"
          edsTooltip
          [tooltipContent]="'common.locked-link-tooltip' | translate"
          tooltipType="medium"
          tooltipPositionDefault="top"
          tooltipBehavior="fixed"
          size="small"
          type="lock"
        ></eds-icon>
      </a>
    </ng-container>
  </div>
</div>
