import { Mapper, MapperParams } from './type.mapper';
import { MapperFactory } from '@products/utils/mappers/mapper-factory';
import {
  BMPerformanceCurrentDTO,
  BMPerformanceDTO,
  BMPerformanceSnapshotDTO,
  CurrencyCode,
} from '@types';
import { BMPerformance, BMPerformanceDetail } from '@models';

export class BMPerformanceMapper extends Mapper<BMPerformance> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(bmPerformanceDto: BMPerformanceDTO): BMPerformance {
    return {
      monthEnd: bmPerformanceDto.month_end?.map((perf) =>
        this.createMapper(BMPerformanceDetailMapper).toDomain(perf)
      ),
      quarterEnd: bmPerformanceDto.quarter_end?.map((perf) =>
        this.createMapper(BMPerformanceDetailMapper).toDomain(perf)
      ),
      historical: bmPerformanceDto.snapshot?.map((perf) =>
        this.createMapper(HistoricalBMPerformanceDetailMapper).toDomain(perf)
      ),
    };
  }
}

export class BMPerformanceDetailMapper extends Mapper<BMPerformanceDetail> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    bmPerformanceDetailDto: BMPerformanceCurrentDTO
  ): BMPerformanceDetail {
    return {
      benchmarkName: bmPerformanceDetailDto.bmname,
      benchmarkLabel: bmPerformanceDetailDto.bmlabel,
      currencyCode: bmPerformanceDetailDto.bmcurrcode as CurrencyCode,
      performanceAsOfDate: bmPerformanceDetailDto.asofdate,
      performanceAsOfDateStd: bmPerformanceDetailDto.asofdatestd,
      calcType: bmPerformanceDetailDto.calcname,
      calcTypeStd: bmPerformanceDetailDto.calcnamestd,
      avgAnnual1YearReturn: bmPerformanceDetailDto.bmavgannret1yr,
      avgAnnual2YearReturn: bmPerformanceDetailDto.bmavgannret2yr,
      avgAnnual3YearReturn: bmPerformanceDetailDto.bmavgannret3yr,
      avgAnnual4YearReturn: bmPerformanceDetailDto.bmavgannret4yr,
      avgAnnual5YearReturn: bmPerformanceDetailDto.bmavgannret5yr,
      avgAnnual7YearReturn: bmPerformanceDetailDto.bmavgannret7yr,
      avgAnnual10YearReturn: bmPerformanceDetailDto.bmavgannret10yr,
      avgAnnual15YearReturn: bmPerformanceDetailDto.bmavgannret15yr,
      avgAnnual20YearReturn: bmPerformanceDetailDto.bmavgannret20yr,
      avgAnnual25YearReturn: bmPerformanceDetailDto.bmavgannret25yr,
      avgAnnual35YearReturn: bmPerformanceDetailDto.bmavgannret35yr,
      avgAnnualYearToDateReturn: bmPerformanceDetailDto.bmavgannretytd,
      avgAnnualSinceInceptionReturn: bmPerformanceDetailDto.bmavgannretactinc,
      cummulativeReturn1Month: bmPerformanceDetailDto.bmcummret1mth,
      cummulativeReturn3Month: bmPerformanceDetailDto.bmcummret3mth,
      cummulativeReturn6Month: bmPerformanceDetailDto.bmcummret6mth,
      cummulativeReturn1Year: bmPerformanceDetailDto.bmcummret1yr,
      cummulativeReturn3Year: bmPerformanceDetailDto.bmcummret3yr,
      cummulativeReturn5Year: bmPerformanceDetailDto.bmcummret5yr,
      cummulativeReturn10Year: bmPerformanceDetailDto.bmcummret10yr,
      cummulativeReturn15Year: bmPerformanceDetailDto.bmcummret15yr,
      cummulativeReturnYearToDate: bmPerformanceDetailDto.bmcummretytd,
      cummulativeReturnSinceInception: bmPerformanceDetailDto.bmcummretactinc,
      discreteAnnualRollingYear1: bmPerformanceDetailDto.bmdiscrannualrollyr1,
      discreteAnnualRollingYear2: bmPerformanceDetailDto.bmdiscrannualrollyr2,
      discreteAnnualRollingYear3: bmPerformanceDetailDto.bmdiscrannualrollyr3,
      discreteAnnualRollingYear4: bmPerformanceDetailDto.bmdiscrannualrollyr4,
      discreteAnnualRollingYear5: bmPerformanceDetailDto.bmdiscrannualrollyr5,
      discreteAnnualRollingYear6: bmPerformanceDetailDto.bmdiscrannualrollyr6,
      discreteAnnualRollingYear7: bmPerformanceDetailDto.bmdiscrannualrollyr7,
      discreteAnnualRollingYear8: bmPerformanceDetailDto.bmdiscrannualrollyr8,
      discreteAnnualRollingYear9: bmPerformanceDetailDto.bmdiscrannualrollyr9,
      discreteAnnualRollingYear10: bmPerformanceDetailDto.bmdiscrannualrollyr10,
      calendarYearReturn1Year: bmPerformanceDetailDto.bmcalendaryrret1yr,
      calendarYearReturn2Year: bmPerformanceDetailDto.bmcalendaryrret2yr,
      calendarYearReturn3Year: bmPerformanceDetailDto.bmcalendaryrret3yr,
      calendarYearReturn4Year: bmPerformanceDetailDto.bmcalendaryrret4yr,
      calendarYearReturn5Year: bmPerformanceDetailDto.bmcalendaryrret5yr,
      calendarYearReturn6Year: bmPerformanceDetailDto.bmcalendaryrret6yr,
      calendarYearReturn7Year: bmPerformanceDetailDto.bmcalendaryrret7yr,
      calendarYearReturn8Year: bmPerformanceDetailDto.bmcalendaryrret8yr,
      calendarYearReturn9Year: bmPerformanceDetailDto.bmcalendaryrret9yr,
      calendarYearReturn10Year: bmPerformanceDetailDto.bmcalendaryrret10yr,
      performanceYear1YearBack: bmPerformanceDetailDto.performance1yr,
      performanceYear2YearBack: bmPerformanceDetailDto.performance2yr,
      performanceYear3YearBack: bmPerformanceDetailDto.performance3yr,
      performanceYear4YearBack: bmPerformanceDetailDto.performance4yr,
      performanceYear5YearBack: bmPerformanceDetailDto.performance5yr,
      performanceYear6YearBack: bmPerformanceDetailDto.performance6yr,
      performanceYear7YearBack: bmPerformanceDetailDto.performance7yr,
      performanceYear8YearBack: bmPerformanceDetailDto.performance8yr,
      performanceYear9YearBack: bmPerformanceDetailDto.performance9yr,
      performanceYear10YearBack: bmPerformanceDetailDto.performance10yr,
    };
  }
}

export class HistoricalBMPerformanceDetailMapper extends Mapper<BMPerformanceDetail> {
  constructor(mapperParams: MapperParams, mapperFactory: MapperFactory) {
    super(mapperParams, mapperFactory);
  }

  toDomain(
    bmPerformanceDetailDto: BMPerformanceSnapshotDTO
  ): BMPerformanceDetail {
    return {
      benchmarkName: bmPerformanceDetailDto.bmname,
      benchmarkLabel: bmPerformanceDetailDto.bmlabel,
      currencyCode: bmPerformanceDetailDto.bmcurrcode as CurrencyCode,
      performanceAsOfDate: bmPerformanceDetailDto.asofdate,
      performanceAsOfDateStd: bmPerformanceDetailDto.asofdatestd,
      calcType: bmPerformanceDetailDto.calcname,
      calcTypeStd: bmPerformanceDetailDto.calcnamestd,
      avgAnnual1YearReturn: bmPerformanceDetailDto.bmavgannret1yr,
      avgAnnual2YearReturn: bmPerformanceDetailDto.bmavgannret2yr,
      avgAnnual3YearReturn: bmPerformanceDetailDto.bmavgannret3yr,
      avgAnnual4YearReturn: bmPerformanceDetailDto.bmavgannret4yr,
      avgAnnual5YearReturn: bmPerformanceDetailDto.bmavgannret5yr,
      avgAnnual7YearReturn: bmPerformanceDetailDto.bmavgannret7yr,
      avgAnnual10YearReturn: bmPerformanceDetailDto.bmavgannret10yr,
      avgAnnual15YearReturn: bmPerformanceDetailDto.bmavgannret15yr,
      avgAnnual20YearReturn: bmPerformanceDetailDto.bmavgannret20yr,
      avgAnnual25YearReturn: bmPerformanceDetailDto.bmavgannret25yr,
      avgAnnual35YearReturn: bmPerformanceDetailDto.bmavgannret35yr,
      avgAnnualYearToDateReturn: bmPerformanceDetailDto.bmavgannretytd,
      avgAnnualSinceInceptionReturn: bmPerformanceDetailDto.bmavgannretactinc,
      cummulativeReturn1Month: bmPerformanceDetailDto.bmcummret1mth,
      cummulativeReturn3Month: bmPerformanceDetailDto.bmcummret3mth,
      cummulativeReturn6Month: bmPerformanceDetailDto.bmcummret6mth,
      cummulativeReturn1Year: bmPerformanceDetailDto.bmcummret1yr,
      cummulativeReturn3Year: bmPerformanceDetailDto.bmcummret3yr,
      cummulativeReturn5Year: bmPerformanceDetailDto.bmcummret5yr,
      cummulativeReturn10Year: bmPerformanceDetailDto.bmcummret10yr,
      cummulativeReturn15Year: bmPerformanceDetailDto.bmcummret15yr,
      cummulativeReturnYearToDate: bmPerformanceDetailDto.bmcummretytd,
      cummulativeReturnSinceInception: bmPerformanceDetailDto.bmcummretactinc,
      discreteAnnualRollingYear1: bmPerformanceDetailDto.bmdiscrannualrollyr1,
      discreteAnnualRollingYear2: bmPerformanceDetailDto.bmdiscrannualrollyr2,
      discreteAnnualRollingYear3: bmPerformanceDetailDto.bmdiscrannualrollyr3,
      discreteAnnualRollingYear4: bmPerformanceDetailDto.bmdiscrannualrollyr4,
      discreteAnnualRollingYear5: bmPerformanceDetailDto.bmdiscrannualrollyr5,
      calendarYearReturn1Year: bmPerformanceDetailDto.bmcalendaryrret1yr,
      calendarYearReturn2Year: bmPerformanceDetailDto.bmcalendaryrret2yr,
      calendarYearReturn3Year: bmPerformanceDetailDto.bmcalendaryrret3yr,
      calendarYearReturn4Year: bmPerformanceDetailDto.bmcalendaryrret4yr,
      calendarYearReturn5Year: bmPerformanceDetailDto.bmcalendaryrret5yr,
      calendarYearReturn6Year: bmPerformanceDetailDto.bmcalendaryrret6yr,
      calendarYearReturn7Year: bmPerformanceDetailDto.bmcalendaryrret7yr,
      calendarYearReturn8Year: bmPerformanceDetailDto.bmcalendaryrret8yr,
      calendarYearReturn9Year: bmPerformanceDetailDto.bmcalendaryrret9yr,
      calendarYearReturn10Year: bmPerformanceDetailDto.bmcalendaryrret10yr,
    };
  }
}
