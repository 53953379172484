import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FloatingActionButtonComponent } from './floating-action-button.component';
import {
  QuicklinksModule,
  FloatingActionButtonModule as EdsFloatingActionButtonModule,
  DividerModule,
  IconModule,
  FlexboxModule,
  PaddingModule,
  SpacingModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { BrSdkModule } from '@bloomreach/ng-sdk';

@NgModule({
  declarations: [FloatingActionButtonComponent],
  imports: [
    CommonModule,
    QuicklinksModule,
    BrSdkModule,
    EdsFloatingActionButtonModule,
    DividerModule,
    IconModule,
    FlexboxModule,
    PaddingModule,
    SpacingModule,
    TranslateModule,
  ],
  exports: [FloatingActionButtonComponent],
})
export class FloatingActionButtonModule {}
