import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonModule,
  FlexboxModule,
  GridModule,
  IconModule,
  ImageModule,
  LinkItemModule,
  NotificationsModule,
  PaddingModule,
  ScrollModule,
  SimpleModalModule,
  SpacingModule,
  TextInputModule,
  TitleModule,
} from '@frk/eds-components';
import { TranslateModule } from '@shared/translate/translate.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { LiteratureDialogComponent } from './literature-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    GridModule,
    IconModule,
    TitleModule,
    LinkItemModule,
    TextInputModule,
    ImageModule,
    ButtonModule,
    FlexboxModule,
    SpacingModule,
    PaddingModule,
    TranslateModule,
    SimpleModalModule,
    ScrollModule,
    NotificationsModule,
    NgxSpinnerModule,
  ],
  exports: [LiteratureDialogComponent],
  declarations: [LiteratureDialogComponent],
})
export class LiteratureDialogModule {}
