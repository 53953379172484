import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
} from '@angular/core';
import { FullResultsComponent } from '../../full-results.component';
import { ItemHeader } from '@search/interfaces/search.interface';
import { LinkClickEvent } from '@frk/eds-components';

@Component({
  selector: 'ft-full-results-general',
  templateUrl: './full-results-general.component.html',
  styleUrls: [
    '../../full-results.component.scss',
    './full-results-general.component.scss',
  ],
})
export class FullResultsGeneralComponent
  extends FullResultsComponent
  implements OnInit, OnChanges {
  @Input() generalItems: [];
  @Input() generalHeader: ItemHeader;
  @Input() searchTerm: string;
  @Output() viewMoreClickedGeneral = new EventEmitter<string>();

  ngOnInit() {
    // do not remove this
    // when removed, it will call parent OnInit and try to get undefined properties, for example 'result'
    // can be refactored when 'result' is set in service, not set from component
  }
  ngOnChanges() {
    // do not remove this
    // when removed, it will call parent OnInit and try to get undefined properties, for example 'result'
    // can be refactored when 'result' is set in service, not set from component
  }
  /**
   *  this may require refactoring
   * there is an issue with inheritance of Output
   * https://github.com/angular/angular/issues/5415
   *
   */
  onViewMore($tabName: string) {
    this.activeTab = $tabName;
    this.viewMoreClickedGeneral.emit($tabName);

    // TODO to verify this position after we introduce filters
    // this.clickedElemBottom = document.getElementById($event.target.id).getBoundingClientRect().top + document.documentElement.scrollTop;
  }

  public onLinkClicked(event: LinkClickEvent, itemData: object): void {
    this.trackSelectorItem(event, itemData);
  }

  public trackSelectorItem(event: LinkClickEvent, itemData): void {
    this.analyticsService.trackEvent({
      event: 'select_content',
      detailed_event: 'Select Content',
      event_data: {
        content_type: 'General',
        facets: '',
        index: '',
        link_text: itemData?.title,
        link_url: itemData?.titleLink,
        search_category: 'General',
        search_term: this.searchTerm,
      },
    });
  }
}
