import { Component, OnInit } from '@angular/core';
import { Logger } from '@utils/logger';
import { AbstractBaseComponent } from '@shared/abstract-base/abstract-base.component';

/**
 * Logger
 */
const logger = Logger.getLogger('PageTitleComponent');

/**
 * Page Title component
 *
 * This component is used at the top of a template.
 *
 * Selector: `ft-page-title`
 *
 * Exported as: `PageTitleComponent`
 */
@Component({
  selector: 'ft-page-title',
  templateUrl: './page-title.component.html',
})
export class PageTitleComponent
  extends AbstractBaseComponent
  implements OnInit {
  // Display Options
  paddingVar = 'var(--space-xxl) 0';

  /**
   * Constructor
   */
  constructor() {
    super();
  }

  /**
   * Get the page title
   */
  get pageTitle() {
    let title = this.component.getModels()?.pageTitle?.title;
    if (!title) {
      title = this.page.getTitle();
    }
    return title;
  }

  /**
   * Init method
   */
  ngOnInit(): void {}
}
