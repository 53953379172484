import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { Logger } from '@utils/logger';
import { BaseProductDetailComponent } from '@products/base-product-detail.component';
import { Page, Component as BrComponent } from '@bloomreach/spa-sdk';
import { FundPerformanceComponentConfig } from '../fund-performance-component.config';
import { TranslateService } from '@shared/translate/translate.service';
import {
  FundPerformanceDiscreteReturnsPeriodEndData,
  FundPerformanceDiscreteReturnsState,
  FundPerformanceDiscreteReturnsStateConfig,
  PerformanceView,
  ProductDetailConfiguration,
} from '@types';
import { FundPerformanceDiscreteReturnsService } from '../services/fund-performance-discrete-returns.service';
import { SiteConfigService } from '@services';
import { ToggleButton } from '@frk/eds-components';
import { PERIODS_MAPPER } from '../services/fund-performance.config';

const logger = Logger.getLogger('FundPerformanceDiscreteReturnsComponent');

@Component({
  selector: 'ft-fund-performance-discrete-returns',
  templateUrl: './fund-performance-discrete-returns.component.html',
  styleUrls: ['./fund-performance-discrete-returns.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FundPerformanceDiscreteReturnsComponent
  extends BaseProductDetailComponent
  implements OnDestroy {
  dataSubscription: Subscription;
  isError = false;
  fundPerformanceData: FundPerformanceDiscreteReturnsPeriodEndData;
  fundPerformanceConfig: FundPerformanceDiscreteReturnsStateConfig;
  periodEndToggle: ToggleButton[];
  xAxisCategories: string[];
  performanceStatusCaveat: string;
  heading: string;

  constructor(
    private fundPerformanceService: FundPerformanceDiscreteReturnsService,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private siteConfigService: SiteConfigService
  ) {
    super();
  }

  populate(
    componentConfig: FundPerformanceComponentConfig,
    brConfig: ProductDetailConfiguration,
    component: BrComponent,
    page: Page
  ) {
    this.initialize(brConfig, component, page);
    logger.debug(page, this.page);
    if (this.brConfig.fundId) {
      this.fundPerformanceService.setConfigurationName(
        componentConfig.configurationName
      );
      this.dataSubscription = this.fundPerformanceService.fundPerformanceState$.subscribe(
        (newState: FundPerformanceDiscreteReturnsState) => {
          if (newState.isError) {
            this.isError = true;
          } else {
            this.mapState(newState);
          }
          this.changeDetector.detectChanges();
        }
      );
      this.fundPerformanceService.populate(brConfig);
    }
    this.changeDetector.detectChanges();
  }

  /**
   * Called when state changes.
   * @param fundPerformanceState the new state returned by fund performance service
   */
  private mapState(fundPerformanceState: FundPerformanceDiscreteReturnsState) {
    this.performanceStatusCaveat = fundPerformanceState.perfStatusCaveat;
    this.heading = fundPerformanceState.heading;
    if (fundPerformanceState.config.periodEnd === PerformanceView.QUARTERLY) {
      this.fundPerformanceData = fundPerformanceState.data.quarterEnd;
      // WDE-3635: Remove Dot from the Date for DE
      if (this.siteConfigService.getPdsCountry() === 'DE') {
        this.fundPerformanceData.years = this.fundPerformanceService.removeDotFromDateRange(
          fundPerformanceState?.data?.quarterEnd?.years
        );
      }
    } else {
      this.fundPerformanceData = fundPerformanceState.data.monthEnd;
      // WDE-3635: Remove Dot from the Date for DE
      if (this.siteConfigService.getPdsCountry() === 'DE') {
        this.fundPerformanceData.years = this.fundPerformanceService.removeDotFromDateRange(
          fundPerformanceState?.data?.monthEnd?.years
        );
      }
    }
    this.fundPerformanceConfig = fundPerformanceState.config;
    this.periodEndToggle = this.getPeriodEnds(fundPerformanceState);
    this.xAxisCategories = this.getXAxisCategories();
  }

  private getPeriodEnds(
    fundPerformanceState: FundPerformanceDiscreteReturnsState
  ): ToggleButton[] {
    return fundPerformanceState.config.periodEnds
      .map((periodEnd) => {
        const period = PERIODS_MAPPER[periodEnd];
        const hasData = fundPerformanceState.data[period].tableData.some(
          (data) => data !== undefined
        );
        if (hasData) {
          return {
            text: this.translateService.instant(`products.${periodEnd}`),
            active: periodEnd === fundPerformanceState.config.periodEnd,
            value: periodEnd,
          };
        }
      })
      .filter((period: ToggleButton) => period !== undefined);
  }

  getXAxisCategories(): string[] {
    const dateRange: string[] = [];
    this.fundPerformanceData?.years?.map((dates) => {
      dateRange.push(dates.startDate + '/' + dates.endDate);
    });
    return dateRange;
  }

  /**
   * Update the performance view as per type selected.
   */
  onPeriodEndUpdate(selectedToggleOption: ToggleButton) {
    this.fundPerformanceService.setPeriodEnd(selectedToggleOption.value);
  }

  ngOnDestroy() {
    // clean up subscription to avoid memory leaks
    this.dataSubscription?.unsubscribe();
  }
}
