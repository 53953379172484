<!-- EDS HEADER -->
<div [ngClass]="{ 'has-edit-button': isEditMode }">
  <ng-container [brManageMenuButton]="menuModel"></ng-container>
  <!-- Initializing ft-us-servicing-registration-modal required for sign-in -->
  <ft-us-servicing-registration-modal
    *ngIf="headerStyle !== 'basic'"
  ></ft-us-servicing-registration-modal>

  <ng-container [ngSwitch]="headerStyle">
    <eds-header
      *ngSwitchCase="'basic'"
      [hasLanguageMenu]="langItems?.length > 0"
      [langTitle]="languageLabel"
      [langItems]="langItems"
      [logoImgSrc]="headerLogo"
      [logoMobImgSrc]="mobileHeaderLogo || headerLogo"
      [logoAltText]="logoAltText"
      [logoHref]="homeUrl"
      [hasLogoLink]="false"
      [hasWideLogo]="hasWideLogo"
      [isMicrosite]="isMicrosite"
      [hasTextLogo]="micrositeTextLogo"
      [micrositeTextLogo]="micrositeTextLogo"
      [micrositeLogoHref]="micrositeLogoHref"
      [hasGlobalNav]="false"
      [hasSiteNav]="false"
      [hasNavigation]="true"
      [screenReaderSignInButtonText]="'signin.signIn' | translate"
      [screenReaderSignOutButtonText]="'signin.signOutMobile' | translate"
      [screenReaderSearchBtn]="'signin.search' | translate"
      [screenReaderNavToggleBtn]="'signin.screenReaderNavToggleBtn' | translate"
      [smallHeader]="true"
      [countryHub]="countryHub"
      [hasMaskedIcon]="true"
      [isSearchActive]="isSearchActive"
      (searchClosed)="clearSearchResults()"
      [skipTo]="skipLinkPath"
      [skipToText]="'common.skip-to-content' | translate"
      (onSkipTo)="onSkipToEvent(true)"
      [isCustomMegaMenu]="isCustomMegaMenu"
    >
    </eds-header>
    <!-- Unidentified Gateway User-->
    <eds-header
      *ngSwitchCase="'basic-with-login'"
      [hasLanguageMenu]="langItems?.length > 0"
      [langTitle]="languageLabel"
      [langItems]="langItems"
      [logoImgSrc]="headerLogo"
      [logoMobImgSrc]="mobileHeaderLogo || headerLogo"
      [logoAltText]="logoAltText"
      [logoHref]="homeUrl"
      [hasLogoLink]="false"
      [hasWideLogo]="hasWideLogo"
      [hasGlobalNav]="true"
      [hasSiteNav]="false"
      [hasNavigation]="true"
      [signInIsDisabled]="isSubmitButtonDisabled"
      [hasSignInBtn]="true"
      [showLegacySignInOption]="showLegacySignInOption"
      [showLegacyContent]="showLegacyContent"
      [isLogIn]="isLogIn"
      [screenReaderSignInButtonText]="'signin.signIn' | translate"
      [screenReaderSignOutButtonText]="'signin.signOutMobile' | translate"
      [selectedRole]="currentSegmentLabel"
      [signInNavData]="signInNavItems"
      [signInComponentContent]="signInComponentContent"
      [signInText]="'signin.signInRegister' | translate"
      [signOutText]="'signin.signOut' | translate"
      [signInFormAction]="signInFormAction"
      [signInHiddenInputs]="signInHiddenInputs"
      (onSignIn)="onSubmit($event)"
      [smallHeader]="true"
      [countryHub]="countryHub"
      [hasMaskedIcon]="true"
      [showMaskedText]="'signin.show' | translate"
      [hideMaskedText]="'signin.hide' | translate"
      [isSearchActive]="isSearchActive"
      (searchClosed)="clearSearchResults()"
      [skipTo]="skipLinkPath"
      [skipToText]="'common.skip-to-content' | translate"
      (onSkipTo)="onSkipToEvent(true)"
      (fastTrackRegistrationClick)="fastTrackRegistration()"
      (legacyBtnEmiter)="legacyBtnclick($event)"
      [isCustomMegaMenu]="isCustomMegaMenu"
    >
    </eds-header>
    <eds-header
      *ngSwitchDefault
      [hasLanguageMenu]="langItems?.length > 0"
      [langTitle]="languageLabel"
      [langItems]="langItems"
      [hasGlobalNav]="globalItems?.length > 0 || hasSignInBtn"
      [globalNavItems]="globalItems"
      [hasSiteNav]="siteNavData?.length > 0"
      [siteNavData]="siteNavData"
      [logoImgSrc]="headerLogo"
      [logoMobImgSrc]="mobileHeaderLogo || headerLogo"
      [logoAltText]="logoAltText"
      [logoHref]="homeUrl"
      [hasWideLogo]="hasWideLogo"
      [isMicrosite]="isMicrosite"
      [hasTextLogo]="micrositeTextLogo"
      [micrositeTextLogo]="micrositeTextLogo"
      [micrositeLogoHref]="micrositeLogoHref"
      [hasSignInBtn]="hasSignInBtn"
      [showLegacySignInOption]="showLegacySignInOption"
      [showLegacyContent]="showLegacyContent"
      [isLogIn]="isLogIn"
      [screenReaderSignInButtonText]="'signin.signIn' | translate"
      [screenReaderSignOutButtonText]="'signin.signOutMobile' | translate"
      [signInNavData]="signInNavItems"
      [hasAccountsFlyoutNav]="accountsNav.length > 0"
      [accountsNav]="accountsNav"
      [hasSignInFlyoutNav]="signedInUserNav.length > 0"
      [signedInUserName]="signedInUserName"
      [signedInUserNav]="signedInUserNav"
      [signInComponentContent]="signInComponentContent"
      [signInText]="'signin.signInRegister' | translate"
      [signOutText]="'signin.signOut' | translate"
      [signInFormAction]="signInFormAction"
      [signInHiddenInputs]="signInHiddenInputs"
      [hasSearch]="hasSearch && (isHideSiteSearchForFirm$ | async) === false"
      [searchLabel]="'signin.search' | translate"
      [searchPlaceholder]="'common.search.placeholder-mobile' | translate"
      [searchClearText]="'common.search.clear' | translate"
      [searchFieldId]="searchFieldId"
      [selectedRole]="currentSegmentLabel"
      [signinId]="'signInID'"
      [cartCount]="cartSize"
      [screenReaderSearchBtn]="'signin.search' | translate"
      [screenReaderNavToggleBtn]="'signin.screenReaderNavToggleBtn' | translate"
      [signInIsDisabled]="isSubmitButtonDisabled"
      (onSignIn)="onSubmit($event)"
      (onSignOut)="signOut()"
      (showRoleSelectorDialog)="showRoleSelectorDialog()"
      [smallHeader]="siteNavData?.length === 0"
      [hasMaskedIcon]="true"
      [showMaskedText]="'signin.hide' | translate"
      [hideMaskedText]="'signin.show' | translate"
      [isSearchActive]="isSearchActive"
      (searchClosed)="clearSearchResults()"
      [skipTo]="skipLinkPath"
      [skipToText]="'common.skip-to-content' | translate"
      (onSkipTo)="onSkipToEvent(true)"
      (fastTrackRegistrationClick)="fastTrackRegistration()"
      (legacyBtnEmiter)="legacyBtnclick($event)"
      [isCustomMegaMenu]="isCustomMegaMenu"
    ></eds-header>
  </ng-container>
</div>
<!-- /EDS HEADER TO COME-->
<ft-search
  *ngIf="hasSearch && (isHideSiteSearchForFirm$ | async) === false"
  [headerStyle]="headerStyle"
  (isSearchActive)="setSearchButton($event)"
  id="ftSearch"
></ft-search>
