import { GraphQLFundDataService } from '@products/services/graphql-fund-data.service';
import { Apollo } from 'apollo-angular';
import { SiteConfigService } from '@services/site-config.service';
import labelQuery from '@graphql/labels/labels.graphql';
import { map, mergeMap, take, takeWhile } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { LabelLoader } from '@utils/label-loader';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PdsLabelLoaderService extends GraphQLFundDataService {
  constructor(
    apollo: Apollo,
    siteConfigService: SiteConfigService,
    private labelLoader: LabelLoader
  ) {
    super(apollo, siteConfigService);
  }

  loadPdsLabels$(): Observable<boolean> {
    return this.siteConfigService
      .getIsPopulated$()
      .pipe(takeWhile((val) => !val, true))
      .pipe(
        mergeMap((isPopulated) => {
          return this.register(labelQuery, {
            countrycode: this.getCountry(),
            languagecode: this.getLanguage(),
          }).pipe(
            map((data) => {
              const pdsLabels = data.data.Labels;
              const translated = {};
              pdsLabels.forEach((label) => {
                translated[`${label.pdssrvcfieldname}`] = label.transvalue;
              });
              this.labelLoader.loadPdsTranslations(translated);
              return true;
            }),
            take(1)
          );
        })
      );
  }
}
