import { NgModule } from '@angular/core';
import { Logger } from '@utils/logger';

const logger = Logger.getLogger('ModuleLoaderConfig');
// type defined here: https://github.com/bloomreach/spa-sdk/blob/main/packages/ng-sdk/src/lib/br-page/br-page.component.ts
export type ComponentMapping = any; // Record<keyof any, Type<BrProps>>;

export interface DynamicModule extends NgModule {
  getComponentMapping(): ComponentMapping;
}
export enum Dependency {
  PDS_LABELS = 'PDS_LABELS',
}

export class ModuleLoaderConfig {
  static readonly DEBUG_PAGEMODEL_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "debug-page-model" */ '../ft-components/shared/debug-page-model/debug-page-model.module'
      )
  );
  static readonly ACCORDION_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "accordion" */ '../ft-components/marketing/accordion/accordion.module'
      )
  );
  static readonly ARTICLE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "article" */ '../ft-components/marketing/article/article.module'
      )
  );
  static readonly BANNER_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "banner" */ '../ft-components/marketing/banner/banner.module'
      )
  );
  static readonly BANNER_MULTI_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "banner" */ '../ft-components/marketing/banner-multi-promo/banner-multi-promo.module'
      )
  );
  static readonly CALCULATOR_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "calculator" */ '../ft-components/marketing/calculator/calculator.module'
      )
  );
  static readonly CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "cards" */ '../ft-components/marketing/cards/cards.module'
      )
  );

  static readonly CAVEATS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "caveats" */ '../ft-components/products/caveats/caveats.module'
      )
  );
  static readonly CONTENT_BLOCKS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "content-blocks" */ '../ft-components/marketing/content-blocks/content-blocks.module'
      )
  );
  static readonly CTA_BANNER_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "cta-banner" */ '../ft-components/marketing/cta-banner/cta-banner.module'
      )
  );
  static readonly SALES_TEAM_CTA_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "sales-team-widget" */ '../ft-components/marketing/sales-team-cta/sales-team-cta.module'
      )
  );
  static readonly DYNAMIC_CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "dynamic-cards" */ '../ft-components/marketing/dynamic-cards/dynamic-cards.module'
      )
  );
  static readonly IMAGE_GRID_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "image-grid" */ '../ft-components/marketing/image-grid/image-grid.module'
      )
  );
  static readonly SIDEBAR_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "side-bar" */ '../ft-components/marketing/side-bar/side-bar.module'
      )
  );
  static readonly INDEX_CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "index-cards" */ '../ft-components/marketing/index-cards/index-cards.module'
      )
  );
  static readonly INSIGHTS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "insights" */ '../ft-components/marketing/insights/insights.module'
      )
  );
  static readonly MARKETO_BUTTON_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "marketo-form" */ '../ft-components/marketing/marketo-button/marketo-button.module'
      )
  );
  static readonly MARKETO_FORM_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "marketo-form" */ '../ft-components/marketing/marketo-form/marketo-form.module'
      )
  );
  static readonly MARKETO_MODAL_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "marketo-form" */ '../ft-components/marketing/marketo-modal/marketo-modal.module'
      )
  );
  static readonly ON_PAGE_SEARCH_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "on-page-search" */ '../ft-components/marketing/on-page-search/on-page-search.module'
      )
  );
  static readonly PRODUCT_DETAILS_LAYOUT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "product-detail-layout" */ '../ft-components/products/product-detail-layout/product-detail-layout.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly PRODUCT_DETAILS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "product-details" */ '../ft-components/products/product-details.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly SECTION_NAV_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "section-nav" */ '../ft-components/marketing/section-nav/section-nav.module'
      )
  );
  static readonly TABLE_WITH_FILTERS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "table-with-filters" */ '../ft-components/shared/table-with-filters/table-with-filters.module'
      )
  );
  static readonly FUND_LISTING_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-listing" */ '../ft-components/products/fund-listing/fund-listing.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly LATEST_DIVIDENDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "Latest Dividends" */ '../ft-components/products/latest-dividends/latest-dividends.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly FUND_CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-cards" */ '../ft-components/products/fund-cards/fund-cards.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly FUND_COMPARE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-compare" */ '../ft-components/products/fund-compare/fund-compare.module'
      )
  );
  static readonly FUND_COMPARISON_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-comparison" */ '../ft-components/products/fund-comparison/fund-comparison.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly STRATEGY_SPOTLIGHT = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ '../ft-components/products/strategy-spotlight/strategy-spotlight.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly FILTERS_FOR_AVATARS = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "strategy-spotlight" */ '../ft-components/products/filter-avatars/filter-avatars.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly MORNINGSTAR_TABLE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "morningstar-table" */ '../ft-components/products/morningstar-table/morningstar-table.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly LITERATURE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "literature" */ '../ft-components/literature/literature.module'
      )
  );

  static readonly LINK_LIST_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "link-list" */ '../ft-components/shared/link-list/link-list.module'
      )
  );

  static readonly MARKETING_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "marketing" */ '../ft-components/marketing/marketing.module'
      )
  );
  static readonly PAGE_SECTION_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "page-section" */ '../ft-components/marketing/page-section/page-section.module'
      )
  );
  static readonly FUND_PRESS_RELEASES_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-press-releases" */ '../ft-components/products/fund-press-releases/fund-press-releases.module'
      )
  );

  static readonly PROFILE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "profile" */ '../ft-components/marketing/profile/profile.module'
      )
  );
  static readonly TAX_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "tax" */ '../ft-components/products/tax/tax.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly INTERACTIVE_CONTENT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "interactive-content" */ '../ft-components/interactive-content/interactive-content.module'
      )
  );
  static readonly FUND_ATTRIBUTION_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "fund-attribution" */ '../ft-components/products/fund-attribution/fund-attribution.module'
      ),
    [Dependency.PDS_LABELS]
  );
  static readonly ROLE_SELECTOR_CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "role-selector-cards" */ '../ft-components/marketing/role-selector-cards/role-selector-cards.module'
      )
  );

  static readonly US_INDEX_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "us-index" */ '../ft-components/interactive-content/us-index/us-index-layout/us-index-layout.module'
      ),
    [Dependency.PDS_LABELS]
  );

  static readonly NJBEST_TREASURY_MANAGED_LAYOUT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "njbest-treasurry-managed-layout" */ '../ft-components/interactive-content/njbest/treasury-managed-layout/treasury-managed-layout.module'
      ),
    [Dependency.PDS_LABELS]
  );

  static readonly SFDR_PRODUCT_LISTING_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "sfdr-product-listing" */ '../ft-components/products/sfdr-product-listing/sfdr-product-listing.module'
      ),
    [Dependency.PDS_LABELS]
  );

  static readonly INTERACTIVE_MEDIA_CARDS_GRID_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "interactive-media-cards-grid" */ '../ft-components/marketing/interactive-media-cards-grid/interactive-media-cards-grid.module'
      )
  );

  static readonly US_CAPITAL_GAINS_LAYOUT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "us-capital-gains-layout" */ '../ft-components/layouts/us-capital-gains-layout/us-capital-gains-layout.module'
      )
  );

  static readonly TABLE_SELECTION_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "table-selection" */ '../ft-components/marketing/table-selection/table-selection.module'
      )
  );

  static readonly TABS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "horizontal-vertical-tabs" */ '../ft-components/marketing/tabs/tabs.module'
      )
  );

  static readonly DOCUMENT_VIEWER_MODULE = new ModuleLoaderConfig(
    () => import('../ft-components/marketing/doc-viewer/doc-viewer.module')
  );

  static readonly DOC_LIBRARY_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "doc-library" */ '../ft-components/doc-library/doc-library.module'
      )
  );

  static readonly SIMILAR_PRODUCT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "similar-product" */ '../ft-components/products/similar-product/similar-product.module'
      )
  );
  static readonly FEATURED_FUNDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "similar-product" */ '../ft-components/products/featured-funds/featured-funds.module'
      )
  );

  static readonly BLOGS_MODULE = new ModuleLoaderConfig(
    () =>
      /* webpackChunkName: "similar-product" */
      import('../ft-components/marketing/blog-widget/blog-widget.module')
  );

  static readonly MOST_VIEWED = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "most-viewed-funds" */ '../ft-components/marketing/most-viewed/most-viewed.module'
      )
  );

  // Alts
  static readonly ALTS_NAVIGATION_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-navigation" */ '../external/ft-alts/navigation.module'
      )
  );
  static readonly ALTS_BANNERS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-banners" */ '../external/ft-alts/banners.module'
      )
  );
  static readonly ALTS_CARDS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-cards" */ '../external/ft-alts/cards.module'
      )
  );
  static readonly ALTS_TABS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-tabs" */ '../external/ft-alts/tabs.module'
      )
  );

  static readonly ALTS_MKT_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-tabs" */ '../external/ft-alts/marketing.module'
      )
  );

  static readonly ALTS_CAROUSELS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-carousels" */ '../external/ft-alts/carousels.module'
      )
  );

  static readonly ALTS_PORTFOLIO_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-carousels" */ '../external/ft-alts/portfolio.module'
      )
  );

  static readonly ALTS_PRODUCTS_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-products" */ '../external/ft-alts/products.module'
      )
  );

  static readonly ALTS_PERFORMANCE_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-performance" */ '../external/ft-alts/performance.module'
      )
  );

  static readonly ALTS_LISTING_MODULE = new ModuleLoaderConfig(
    () =>
      import(
        /* webpackChunkName: "alts-listing" */ '../external/ft-alts/listing.module'
      )
  );

  private static readonly componentToModuleMap: {
    [name: string]: ModuleLoaderConfig;
  } = {
    // Alts Navigation
    'Alts Header': ModuleLoaderConfig.ALTS_NAVIGATION_MODULE,
    'Alts Footer': ModuleLoaderConfig.ALTS_NAVIGATION_MODULE,
    'Alts Section Anchor': ModuleLoaderConfig.ALTS_NAVIGATION_MODULE,
    'Alts Back to Top': ModuleLoaderConfig.ALTS_NAVIGATION_MODULE,
    'Alts Floating Action Button': ModuleLoaderConfig.ALTS_NAVIGATION_MODULE,

    // Alts Banners
    'Alts Banner': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Hero': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Hero v2': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Hero v3': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Hero With Form': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Hero With Stats': ModuleLoaderConfig.ALTS_BANNERS_MODULE,
    'Alts Podcast Hero': ModuleLoaderConfig.ALTS_BANNERS_MODULE,

    //  Alts Cards
    'Alts Card Grid': ModuleLoaderConfig.ALTS_CARDS_MODULE,
    'Alts Featured Cards': ModuleLoaderConfig.ALTS_CARDS_MODULE,
    'Alts Staggered Cards': ModuleLoaderConfig.ALTS_CARDS_MODULE,
    'Alts Logo Grid': ModuleLoaderConfig.ALTS_CARDS_MODULE,
    'Alts Media Cards': ModuleLoaderConfig.ALTS_CARDS_MODULE,

    // Alts Tabs
    'Alts Document Table': ModuleLoaderConfig.ALTS_TABS_MODULE,
    'Alts Specialist Tabs': ModuleLoaderConfig.ALTS_TABS_MODULE,
    'Alts Sticky Tabs': ModuleLoaderConfig.ALTS_TABS_MODULE,
    'Alts Tabs': ModuleLoaderConfig.ALTS_TABS_MODULE,
    'Alts Product Documents': ModuleLoaderConfig.ALTS_TABS_MODULE,
    'Alts Explore Tabs': ModuleLoaderConfig.ALTS_TABS_MODULE,

    // Alts Marketing
    'Alts Glossary': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Html Block': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Contact Us': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Custom Chart': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Key Features': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Fly In': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Portfolio Managers': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Invest Info': ModuleLoaderConfig.ALTS_MKT_MODULE,

    'Alts Knowledge Hub': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Info Circle': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Knowledge Hub Carousel': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Knowledge Hub Card Grid': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Video': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Product Overview': ModuleLoaderConfig.ALTS_PRODUCTS_MODULE,
    'Alts Info Graphic Circle': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Custom Table': ModuleLoaderConfig.ALTS_MKT_MODULE,
    'Alts Interactive Map Tabs': ModuleLoaderConfig.ALTS_MKT_MODULE,

    // Alts Carousels
    'Alts Timeline': ModuleLoaderConfig.ALTS_CAROUSELS_MODULE,
    'Alts Investment Strategies': ModuleLoaderConfig.ALTS_CAROUSELS_MODULE,
    'Alts Property Carousel': ModuleLoaderConfig.ALTS_CAROUSELS_MODULE,
    'Alts Stages Slider': ModuleLoaderConfig.ALTS_CAROUSELS_MODULE,
    'Alts Content Slider': ModuleLoaderConfig.ALTS_CAROUSELS_MODULE,

    // Alts Products
    'Alts Product Hero': ModuleLoaderConfig.ALTS_PRODUCTS_MODULE,

    // Alts Performance
    'Alts Performance Header': ModuleLoaderConfig.ALTS_PERFORMANCE_MODULE,

    // Alts Portfolio Impact
    'Alts Portfolio Impact': ModuleLoaderConfig.ALTS_PORTFOLIO_MODULE,

    // Alts Portfolio
    'Alts Portfolio': ModuleLoaderConfig.ALTS_PORTFOLIO_MODULE,

    // Alts Listings
    'Alts Podcast Listing': ModuleLoaderConfig.ALTS_LISTING_MODULE,
    'Alts Podcast Details': ModuleLoaderConfig.ALTS_LISTING_MODULE,
    'Alts Related Podcasts': ModuleLoaderConfig.ALTS_LISTING_MODULE,
    'Alts Article Details': ModuleLoaderConfig.ALTS_LISTING_MODULE,

    // Accordion
    Accordion: ModuleLoaderConfig.ACCORDION_MODULE,

    // Article Module
    Article: ModuleLoaderConfig.ARTICLE_MODULE,

    // Fund Listing Module
    'Fund Listing': ModuleLoaderConfig.FUND_LISTING_MODULE,

    // SFDR Fund Listing Module
    'SFDR Fund Listing': ModuleLoaderConfig.SFDR_PRODUCT_LISTING_MODULE,

    // Latest Dividends Module
    'Latest Dividends': ModuleLoaderConfig.LATEST_DIVIDENDS_MODULE,

    // Product Details Layout Module
    'product-detail': ModuleLoaderConfig.PRODUCT_DETAILS_LAYOUT_MODULE,

    // Fund Details Module
    'Fund Overview': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Content': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Portfolio': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Manager Roster / Allocation': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Header': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Fund Performance': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,
    'Price Distribution': ModuleLoaderConfig.PRODUCT_DETAILS_MODULE,

    // Tax Module
    'Fund Tax': ModuleLoaderConfig.TAX_MODULE,

    // Cards Module
    'Fund Press Releases': ModuleLoaderConfig.FUND_PRESS_RELEASES_MODULE,

    // Footnote Module
    'Legal/Footnote Caveats': ModuleLoaderConfig.CAVEATS_MODULE,

    // Cards Module
    'Fund Cards': ModuleLoaderConfig.FUND_CARDS_MODULE,
    'Fund Attribution': ModuleLoaderConfig.FUND_ATTRIBUTION_MODULE,

    // Fund compare Module
    'Fund Compare': ModuleLoaderConfig.FUND_COMPARE_MODULE,
    'Fund Compare Global': ModuleLoaderConfig.FUND_COMPARISON_MODULE,
    'Strategy Spotlight': ModuleLoaderConfig.STRATEGY_SPOTLIGHT,
    'Filters For Avatars': ModuleLoaderConfig.FILTERS_FOR_AVATARS,
    // Morningstar Table Module
    'Fund Morningstar': ModuleLoaderConfig.MORNINGSTAR_TABLE_MODULE,

    // Literature Module
    Literature: ModuleLoaderConfig.LITERATURE_MODULE,
    'Literature Banner': ModuleLoaderConfig.LITERATURE_MODULE,
    'Fund Document': ModuleLoaderConfig.LITERATURE_MODULE,
    'Fund Commentary': ModuleLoaderConfig.LITERATURE_MODULE,
    'Literature Item Details': ModuleLoaderConfig.LITERATURE_MODULE,
    'Literature Order History Detail': ModuleLoaderConfig.LITERATURE_MODULE,
    'Cart Summary': ModuleLoaderConfig.LITERATURE_MODULE,
    'Literature Complementary Mandatory': ModuleLoaderConfig.LITERATURE_MODULE,
    'Interactive Content': ModuleLoaderConfig.INTERACTIVE_CONTENT_MODULE,
    'SmarshBot Listing': ModuleLoaderConfig.LITERATURE_MODULE,

    // Banner Module
    Banner: ModuleLoaderConfig.BANNER_MODULE,
    'Banner (with nav)': ModuleLoaderConfig.BANNER_MODULE,
    'Banner Impact': ModuleLoaderConfig.BANNER_MODULE,
    'Banner Impact VI': ModuleLoaderConfig.BANNER_MODULE,
    'Banner Video': ModuleLoaderConfig.BANNER_MODULE,
    'Standard Banner Image/Gradient': ModuleLoaderConfig.BANNER_MODULE,
    'Banner 50/50': ModuleLoaderConfig.BANNER_MODULE,
    // Multi Promo Banner
    'Banner Multi Promo': ModuleLoaderConfig.BANNER_MULTI_MODULE,

    // Card Module
    Calculator: ModuleLoaderConfig.CALCULATOR_MODULE,

    // Card Module
    Card: ModuleLoaderConfig.CARDS_MODULE,
    'Card Featured': ModuleLoaderConfig.CARDS_MODULE,

    // Content Blocks Module
    'Content Blocks': ModuleLoaderConfig.CONTENT_BLOCKS_MODULE,

    // CTA Banner
    'CTA Banner': ModuleLoaderConfig.CTA_BANNER_MODULE,

    // Sales team Cta
    'Sales Team Widget': ModuleLoaderConfig.SALES_TEAM_CTA_MODULE,

    // Dynamic Cards
    'Dynamic Cards': ModuleLoaderConfig.DYNAMIC_CARDS_MODULE,

    // Insights Listings
    'Article Collection': ModuleLoaderConfig.INSIGHTS_MODULE,
    'Article Hub': ModuleLoaderConfig.INSIGHTS_MODULE,
    'Insights Cards': ModuleLoaderConfig.INSIGHTS_MODULE,
    'Insights Library': ModuleLoaderConfig.INSIGHTS_MODULE,

    // Index Cards
    'Index Cards': ModuleLoaderConfig.INDEX_CARDS_MODULE,

    // Image Grid
    'Image Grid': ModuleLoaderConfig.IMAGE_GRID_MODULE,

    // Link List
    'Link List': ModuleLoaderConfig.LINK_LIST_MODULE,

    // Marketo Button
    'Marketo Button': ModuleLoaderConfig.MARKETO_BUTTON_MODULE,

    // Marketo Forms
    'Marketo Form': ModuleLoaderConfig.MARKETO_FORM_MODULE,

    // Marketo Modal
    'Marketo Modal': ModuleLoaderConfig.MARKETO_MODAL_MODULE,

    // On Page Search
    'On Page Search': ModuleLoaderConfig.ON_PAGE_SEARCH_MODULE,

    // Page Section Module
    'Page Section': ModuleLoaderConfig.PAGE_SECTION_MODULE,

    // Profile Modile
    'Profile List': ModuleLoaderConfig.PROFILE_MODULE,
    Profile: ModuleLoaderConfig.PROFILE_MODULE,

    // Section Nav
    'Section Anchor': ModuleLoaderConfig.SECTION_NAV_MODULE,
    'Section Nav': ModuleLoaderConfig.SECTION_NAV_MODULE,

    // Sidebar
    Sidebar: ModuleLoaderConfig.SIDEBAR_MODULE,

    // Merge all tables?
    'Table with Filters': ModuleLoaderConfig.TABLE_WITH_FILTERS_MODULE,

    // Table Selection
    'Table Selection': ModuleLoaderConfig.TABLE_SELECTION_MODULE,

    // Tabs Module
    'Horizontal Vertical Tabs': ModuleLoaderConfig.TABS_MODULE,

    // Marketing Module
    Code: ModuleLoaderConfig.MARKETING_MODULE,
    'Custom Form': ModuleLoaderConfig.MARKETING_MODULE,
    Gateway: ModuleLoaderConfig.MARKETING_MODULE,
    'Important Legal': ModuleLoaderConfig.MARKETING_MODULE,
    Dashboard: ModuleLoaderConfig.MARKETING_MODULE,
    'My Dashboard': ModuleLoaderConfig.MARKETING_MODULE,
    'Page Metadata': ModuleLoaderConfig.MARKETING_MODULE,
    'Page Title': ModuleLoaderConfig.MARKETING_MODULE,
    'Rich Text': ModuleLoaderConfig.MARKETING_MODULE,
    'Role Selector Cards': ModuleLoaderConfig.ROLE_SELECTOR_CARDS_MODULE,
    'Segment Banner': ModuleLoaderConfig.MARKETING_MODULE,
    'Utility Bar': ModuleLoaderConfig.MARKETING_MODULE,
    Video: ModuleLoaderConfig.MARKETING_MODULE,
    'us-index': ModuleLoaderConfig.US_INDEX_MODULE,
    'Video Content Blocks': ModuleLoaderConfig.MARKETING_MODULE,
    'njbest-treasury-managed-layout':
      ModuleLoaderConfig.NJBEST_TREASURY_MANAGED_LAYOUT_MODULE,
    'us-capital-gains-layout':
      ModuleLoaderConfig.US_CAPITAL_GAINS_LAYOUT_MODULE,
    'Sales Contact Widget': ModuleLoaderConfig.MARKETING_MODULE,
    'Interactive Media Card':
      ModuleLoaderConfig.INTERACTIVE_MEDIA_CARDS_GRID_MODULE,
    'event-info': ModuleLoaderConfig.MARKETING_MODULE,
    'Product Contact Info': ModuleLoaderConfig.MARKETING_MODULE,

    'Quick Links': ModuleLoaderConfig.MARKETING_MODULE,
    // Floating Action Button
    'Floating Action Button': ModuleLoaderConfig.MARKETING_MODULE,

    // Document Viewer
    'Document Viewer': ModuleLoaderConfig.DOCUMENT_VIEWER_MODULE,

    // Doc Library
    'Doc Library Listing': ModuleLoaderConfig.DOC_LIBRARY_MODULE,
    'Doc Library Recently Added': ModuleLoaderConfig.DOC_LIBRARY_MODULE,
    'Doc Library Profile': ModuleLoaderConfig.DOC_LIBRARY_MODULE,

    'Similar Products': ModuleLoaderConfig.SIMILAR_PRODUCT_MODULE,

    'Most Viewed Funds': ModuleLoaderConfig.MOST_VIEWED,
    'Featured Funds': ModuleLoaderConfig.FEATURED_FUNDS_MODULE,
    'Blog Widget': ModuleLoaderConfig.BLOGS_MODULE,
  };

  readonly moduleLoader: () => Promise<any>;
  readonly dependencies: Dependency[];
  private loaded = false;

  private constructor(
    moduleLoader: () => Promise<any>,
    dependencies?: Dependency[]
  ) {
    this.moduleLoader = moduleLoader;
    this.dependencies = dependencies;
  }

  public static getRequiredModules(
    componentNames: string[],
    isDebugPageModel: boolean
  ): ModuleLoaderConfig[] {
    const requiredModules: ModuleLoaderConfig[] = [];
    if (isDebugPageModel && !ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE.loaded) {
      ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE.loaded = true;
      requiredModules.push(ModuleLoaderConfig.DEBUG_PAGEMODEL_MODULE);
    } else {
      componentNames.forEach((componentName) => {
        const module = this.componentToModuleMap[componentName];
        if (module && !module.loaded) {
          requiredModules.push(module);
          module.loaded = true; // has been requested, but may not be completely loaded yet
        }
      });
    }
    return requiredModules;
  }
}
