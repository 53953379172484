import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@ng-web-apis/common';
import { Channel, OneFranklinId, Segment } from '@types';
import {
  ENDPOINT,
  EXTERNAL,
  FP,
  FPGROUP,
  LOGOUT_TRUE,
  LOGOUT_TYPE_M,
  ROLE_REDIRECT,
  SEGMENT_TYPE,
  USER_GROUP,
  USER_TYPE_COOKIE,
} from '@utils/app.constants';
import { Logger } from '@utils/logger';
import { from, Observable, of } from 'rxjs';
import { map, switchMap, take, tap, timeout } from 'rxjs/operators';
import { AppStateService } from './app-state.service';
import { AccountsAccess, IUserProfileInfo } from './profile.interface';
import { StorageService } from './storage.service';
import OktaAuth from '@okta/okta-auth-js';
import { OktaFranklinIds, OktaSessionNames } from '../types/okta.type';
import {
  Auth0Client,
  Auth0ClientOptions,
  createAuth0Client,
  RedirectLoginResult,
} from '@auth0/auth0-spa-js';
import { SiteConfigService } from '@services';
import { ServerCookieService } from './server-cookie.service';
import { OAUTH_TOKEN } from '@utils/app.constants';

const logger = Logger.getLogger('SessionService');

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    'If-Modified-Since': '0',
  }),
  withCredentials: true,
  observe: 'response' as 'response',
};

// codes returned from accounts api calls
export enum SessionCodes {
  NOT_LOGGED_IN = 'PROFINTL100138',
  // TODO: add more codes as required
}

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  renewTimeout: number;
  private oktaClient;
  private auth0Client: Promise<Auth0Client>;

  constructor(
    private http: HttpClient,
    private appStateService: AppStateService,
    @Inject(WINDOW) readonly windowRef: Window,
    private storageService: StorageService,
    private siteConfig: SiteConfigService,
    private serverCookieService: ServerCookieService
  ) {}

  /**
   * Function to validate Token
   */
  public validateSession$(
    requestTimeout: number
  ): Observable<IUserProfileInfo> {
    if (this.checkOktaValidation()) {
      return this.oktaValidate();
    }
    if (this.appStateService.isAuth0Login()) {
      return this.auth0Validate();
    }
    const validateTokenUrl = `${this.appStateService.getAccountsApiUrl()}${
      ENDPOINT.validateSession.link
    }`;
    logger.debug('Triggering Validate Session API');
    return this.http.post(validateTokenUrl, {}, httpOptions).pipe(
      timeout(requestTimeout),
      switchMap((sessionData: any) => {
        logger.debug(
          'Session Service - validateSession - Success',
          sessionData
        );
        let user = sessionData.body?.result as IUserProfileInfo; // try myfunds response first
        if (!user) {
          user = sessionData.body;
        }
        if (user?.userId && user.userSysNo) {
          // logged in US user
          this.storageService.store<string>(USER_GROUP, user.userGroup, true);

          // valid token
          // need to get full profile data
          return this.getProfileDetails$(user.expiryTimeInSeconds);
        }
        return of(user).pipe(
          map((userProfile) => {
            if (userProfile?.userSysNo) {
              // logged in myfunds user
              userProfile.userId = userProfile.uid; // map myfunds response to match us accounts
              if (this.appStateService.getChannel() === 'de-de') {
                userProfile.role =
                  userProfile.userGroup === 'INVESTOR'
                    ? 'INVESTOR'
                    : 'distributor';
                userProfile.webExperience =
                  userProfile.userGroup === 'INVESTOR'
                    ? 'investor'
                    : 'distributor';
              } else {
                userProfile.role = userProfile.userGroup;
                userProfile.webExperience =
                  userProfile.userGroup === 'INVESTOR' ? 'investor' : 'FA';
              }
            }
            return userProfile;
          })
        );
      })
    );
  }

  /**
   * Function to get the profile details
   */
  private getProfileDetails$(expiryTimeInSeconds: string): Observable<any> {
    const userDetailUrl = `${this.appStateService.getAccountsApiUrl()}${
      ENDPOINT.getProfile.link
    }`;
    logger.debug('Triggering Profile Data API');
    return this.http.get(userDetailUrl, httpOptions).pipe(
      timeout(7000),
      map((res) => res.body),
      tap((profile: IUserProfileInfo) => {
        if (profile.accountsAccess !== AccountsAccess.ACCESS) {
          // WDE-1552 - maximize login time for marketing only users.
          this.setRenewTokenTimer(expiryTimeInSeconds);
        }
      })
    );
  }

  /**
   * calls the authentication API to authenticate user
   * @param payload string: credentials are sent as a single string as payload for the POST API
   */
  public authenticateUser$(payload: any): Observable<any> {
    const loginHttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
      withCredentials: true,
      observe: 'response' as 'response', // Required to pass the location back
    };
    return this.http.post(this.getLoginUrl(), payload, loginHttpOptions);
  }

  public getLoginUrl(): string {
    switch (this.appStateService.getAuthenticationType()) {
      case 'myfunds':
        const idpUrl = this.appStateService.getMyFundsIdpUrl();
        return this.siteConfig.hideLoginForm()
          ? idpUrl
          : `${this.appStateService.getAccountsApiUrl()}/login`;
      case 'canada':
        return `${this.appStateService.getAccountsApiUrl()}`;
      default:
        // us
        return `${this.appStateService.getAccountsApiUrl()}${
          ENDPOINT.authenticate.link
        }`;
    }
  }

  private setRenewTokenTimer(expiryTimeInSeconds: string) {
    if (expiryTimeInSeconds) {
      if (this.appStateService.getAuthenticationType() === 'myfunds') {
        this.renewToken(
          `${this.appStateService.getAccountsApiUrl()}/renew`,
          httpOptions,
          expiryTimeInSeconds
        );
      } else if (this.appStateService.getAuthenticationType() === 'us') {
        this.serverCookieService
          .getOauthToken$()
          .pipe(take(1))
          .subscribe((authToken: string) => {
            this.renewToken(
              `${this.appStateService
                .getAccountsApiUrl()
                .replace('v1', '')}renewal`, // renewal is only service which doesnt have v1
              {
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  'Cache-Control': 'no-cache',
                  Pragma: 'no-cache',
                  'If-Modified-Since': '0',
                  Authorization: authToken ? `Bearer ${authToken}` : '',
                }),
                withCredentials: true,
                observe: 'response' as 'response',
              },
              expiryTimeInSeconds
            );
          });
      }
    }
  }

  private renewToken(renewTokenUrl, renewalHttpOptions, expiryTimeInSeconds) {
    this.renewTimeout = window.setTimeout(() => {
      logger.debug('extending auth token');
      this.http.get(renewTokenUrl, renewalHttpOptions).subscribe(
        () => {
          logger.debug('successfully renewed');
          // need to get the new expiry time.
          this.serverCookieService.clearOauthToken();
          this.validateSession$(7000)
            .pipe(take(1))
            .subscribe(() => {
              logger.debug('new expiry time set');
            });
          return true;
        },
        () => {
          logger.error('error when trying to renew');
          return false;
        }
      );
    }, Number(expiryTimeInSeconds) * 1000 - 30000); // renew 30 seconds before the token expires.
  }

  public logout(segment?: Segment) {
    if (this.renewTimeout) {
      clearTimeout(this.renewTimeout);
    }
    logger.debug('Entering logout', segment);
    switch (this.appStateService.getAuthenticationType()) {
      case 'myfunds':
        logger.debug('Triggering logout for MyFunds', segment);
        this.http
          .delete(
            `${this.appStateService.getAccountsApiUrl()}/logout`,
            httpOptions
          )
          .subscribe(
            () => {
              //   this.windowRef.location.href = `${this.appStateService.getHomePageUrl()}?${LOGOUT_TRUE}${LOGOUT_TYPE_M}`;
              // Is segment available in logout url, need to be check and apply the segment.
              if (segment) {
                // Is selected segment is externel one need to be redirect
                if (segment?.externalLink) {
                  logger.debug(
                    'redirect to segment external link',
                    segment.externalLink
                  );
                  this.windowRef.location.href = `${this.appStateService.getHomePageUrl()}?${LOGOUT_TRUE}${LOGOUT_TYPE_M}&${ROLE_REDIRECT}${
                    segment.id
                  }&${SEGMENT_TYPE}${EXTERNAL}`;
                } else {
                  // set the selected segment in logout url
                  this.windowRef.location.href = `${this.appStateService.getHomePageUrl()}?${LOGOUT_TRUE}${LOGOUT_TYPE_M}&${ROLE_REDIRECT}${
                    segment.id
                  }`;
                }
              } else {
                // Is segment not available in logout url, need to be set normal logout url
                this.windowRef.location.href = `${this.appStateService.getHomePageUrl()}?${LOGOUT_TRUE}${LOGOUT_TYPE_M}`;
              }
            },
            (e) => {
              logger.error('Unable to log out', e);
            }
          );
        break;

      case 'canada':
        logger.debug('Triggering logout for Canada');
        // Set checkCookie to allow remove not only local-storage cookies.
        this.storageService.setCheckCookie(true);
        // Set empty cookie value to avoid get cookie form browser cache (for HttpOnly cookie)
        // this.serverCookieService.set(USER_TYPE_COOKIE, { cookieValue: '' });
        // Remove userType cookie before redirect to Canada accounts sign out.
        this.storageService.remove(USER_TYPE_COOKIE, false, USER_TYPE_COOKIE);
        this.storageService.remove(
          'user_firm_channel',
          false,
          'user_firm_channel'
        );
        // Sett segment cookie for Canada language channels
        for (const channel of [Channel.CANADA_ENGLISH, Channel.CANADA_FRENCH]) {
          this.storageService.remove(`isLoggedIn_${channel}`, true);
          this.storageService.remove(`profile_${channel}`);
          this.setSegmentOnSignOut(channel, segment);
        }
        this.storageService.setCheckCookie(false);
        // Go to accounts sign out API to finish sign-out process.
        this.windowRef.location.href = this.appStateService.getCanadaSignOutApiURL();
        break;

      case OneFranklinId.APAC: // fall through
      case OneFranklinId.EMEA: // fall through
      case OneFranklinId.US:
        logger.debug('Triggering logout for oneFranklinId');
        this.getAuth0Client()?.then((auth0Client) => {
          auth0Client.logout({
            logoutParams: {
              returnTo: this.appStateService.getHomePageUrl(),
            },
          });
        });
        break;

      default:
        // us
        // Is segment available in logout url, need to be check and apply the segment.
        logger.debug('Triggering logout for US');
        if (segment) {
          // Is selected segment is externel one need to be redirect
          if (segment?.externalLink) {
            logger.debug(
              'redirect to segment external link',
              segment.externalLink
            );
            this.windowRef.location.href = `${this.appStateService.getAccountsApiUrl()}${
              ENDPOINT.signOut.link
            }${LOGOUT_TYPE_M}&${ROLE_REDIRECT}${
              segment.id
            }&${SEGMENT_TYPE}${EXTERNAL}`;
          } else {
            // set the selected segment in logout url
            this.windowRef.location.href = `${this.appStateService.getAccountsApiUrl()}${
              ENDPOINT.signOut.link
            }${LOGOUT_TYPE_M}&${ROLE_REDIRECT}${segment.id}`;
          }
        } else {
          // Is segment not available in logout url, need to be set normal logout url
          this.windowRef.location.href = `${this.appStateService.getAccountsApiUrl()}${
            ENDPOINT.signOut.link
          }${LOGOUT_TYPE_M}`;
        }
    }
  }

  /**
   * Set segment during SignOut
   * @param channel - site channel string
   * @param segment - selected segment string
   */
  private setSegmentOnSignOut(channel: Channel, segment: Segment): void {
    if (!segment) {
      this.storageService.retrieve(`segment_${channel}`).then((segmentId) => {
        // Set segment to FP if it is internal
        if (segmentId === 'internal') {
          this.storageService.store(
            `segment_${channel}`,
            'financial-professionals',
            false,
            `segment_${channel}`
          );
        }
      });
    } else {
      this.storageService.store(
        `segment_${channel}`,
        segment.id,
        false,
        `segment_${channel}`
      );
    }
  }

  public canadaValidateSession$(): Observable<object> {
    return this.http.post(
      this.appStateService.getCanadaValidateSessionUrl(),
      {},
      httpOptions
    );
  }

  /**
   * Gets Okta client
   * @param oktaApp - AppState AuthenticationType
   */
  public getOktaClient(oktaApp: string): OktaAuth {
    if (!this.oktaClient) {
      // Initiating ApiUrls before Okta Authentication. It is required to getHomePageUrl correctly.
      this.appStateService.getApiUrls();
      // Bootstrap the AuthJS Client
      this.oktaClient = new OktaAuth({
        issuer: this.appStateService.getEnvConfig()[oktaApp].issuer,
        // OpenID Connect APP Client ID
        clientId: this.appStateService.getEnvConfig()[oktaApp].key,
        // Trusted Origin Redirect URI
        redirectUri: `${this.appStateService.getHomePageUrl()}/login/callback`,
      });
    }
    return this.oktaClient;
  }

  private oktaValidate(): Observable<IUserProfileInfo> {
    const oktaClient = this.getOktaClient(
      this.appStateService.getAuthenticationType()
    );
    if (
      this.appStateService.getAuthenticationType() ===
      OktaFranklinIds.INTERNAL_ID
    ) {
      // Internal users Okta login defined as 'internalFranklinId' in channel configuration
      logger.debug('Internal Okta verification');
      this.oktaInternalSignIn(oktaClient).then(() => {
        logger.debug('Okta SignIn');
      });
    }
    return from(this.storageService.getOktaProfile());
  }

  /**
   * Internal Okta sign in process
   * @param oktaClient - OktaAuth object
   */
  private async oktaInternalSignIn(oktaClient: OktaAuth): Promise<void> {
    const isOktaSession = this.storageService.getInternalOktaLogin(
      OktaSessionNames.PROFILE
    );
    const oktaLoginTrigger = this.storageService.getInternalOktaLogin(
      OktaSessionNames.TRIGGER
    );
    if (oktaLoginTrigger && isOktaSession) {
      // If login was already triggered and Okta session is set, do not sign-in again.
      this.storageService.removeOktaCallbackRoute();
      return;
    }
    const oktaCallbackRoute =
      this.windowRef.location.pathname + this.windowRef.location.search;
    await oktaClient.signInWithRedirect().then(() => {
      this.storageService.setInternalOktaLogin(OktaSessionNames.TRIGGER);
      this.storageService.saveOktaCallbackRoute(oktaCallbackRoute);
    });
  }

  /**
   * Checks if Okta validation is required
   * We dont need Okta validation inside BR
   * As BR SPA page.isPreview() cant't be used in the service
   * checking isPreview base on URL parameters.
   */
  private checkOktaValidation(): boolean {
    const isPreview =
      this.windowRef.location.search.includes('token=') &&
      this.windowRef.location.search.includes('endpoint=');
    return this.appStateService.isOktaLogin() && !isPreview;
  }

  public getAuth0Client(): Promise<Auth0Client> {
    if (!this.auth0Client) {
      this.appStateService.getApiUrls();
      // Bootstrap the AuthJS Client
      const auth0AppConfig = this.appStateService.getAuth0Config();
      if (auth0AppConfig) {
        const params: Auth0ClientOptions = {
          domain: auth0AppConfig.domain,
          // OpenID Connect APP Client ID
          clientId: auth0AppConfig.clientId,
          authorizationParams: {
            redirect_uri: `${this.appStateService.getHomePageUrl()}/customerlogin/callback`,
          },
        };
        this.auth0Client = createAuth0Client(params);
      } else {
        return Promise.reject('auth0 not configured');
      }
    }
    return this.auth0Client;
  }

  public auth0Authorize(requestParams: any, returnUri?: string) {
    if (!returnUri) {
      returnUri = this.windowRef.location.pathname;
    }
    this.storageService.saveAuth0CallbackRoute(returnUri);
    this.getAuth0Client()?.then(async (auth0Client) => {
      const redirectUri = `${this.appStateService.getHomePageUrl()}/customerlogin/callback`;
      auth0Client.loginWithRedirect({
        authorizationParams: Object.assign(
          {
            redirect_uri: redirectUri,
          },
          requestParams
        ),
      });
    });
  }

  private auth0Validate(): Observable<IUserProfileInfo> {
    return from(
      this.getAuth0Client().then((auth0Client) => {
        return this.loadAuth0Profile(auth0Client);
      })
    );
  }

  private loadAuth0Profile(
    auth0Client: Auth0Client
  ): Promise<IUserProfileInfo> {
    return auth0Client.getUser().then((profile) => {
      if (profile) {
        const data = profile['https://franklintempleton.com/profile'];
        // attempt to set cookie
        auth0Client.getTokenSilently().then((accessToken) =>
          this.serverCookieService.set(OAUTH_TOKEN, {
            cookieValue: accessToken,
          })
        );
        return {
          accountsAccess: data.userGroup === FPGROUP ? 'access' : 'no-access',
          additionalRoles: data.additionalRoles, // new solution to have flexible access per user
          // addressLine1: data.addressLine1,
          businessKey: data.app_metadata.expressNo,
          // city: data.city,
          // dashboardUrl: data.dashboardUrl,
          // dealerNo: data.dealerNo,
          displayName: data.displayName,
          email: data.email,
          // firm: data.firm,
          firstName: data.app_metadata.firstName,
          // hypotool: data.hypotool,
          lastName: data.app_metadata.lastName,
          loginName: data.username,
          // newApplnStatus: data.newApplnStatus,
          // pcsAcceptFlag: data.pcsAcceptFlag,
          // phoneNumber: data.phoneNumber,
          // remindMeUpUpgrade: data.remindMeUpgrade,
          role: FP,
          // ssotool: data.ssotool,
          // state: data.state,
          userId: data.user_id,
          userSysNo: data.app_metadata.expressNo,
          // webExperience: data.webExperience,
          // zip: data.zip,
        };
      } else {
        return {};
      }
    });
  }
}
