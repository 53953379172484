import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, OnDestroy } from '@angular/core';
import { Component as BrComponent, Page } from '@bloomreach/spa-sdk';
import { ProductDetailLoadingService } from '@products/product-detail-layout/product-detail-loading.service';
import { Logger } from '@utils/logger';
import {
  BehaviorSubject,
  combineLatest,
  forkJoin,
  fromEvent,
  merge,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { ASYNC_COMPONENTS } from './async-content-manager.config';

const logger = Logger.getLogger('AsyncContentManagerService');

export interface AsyncPageSection {
  // corresponds to a section anchor i.e. <div id="some-anchor"> on the page
  id: string;
  // any child anchors within the section e.g. (normal) tabs or accordion panels
  // a child id can't be scrolled to until the whole section is ready
  childIds?: string[];
}

export interface AsyncComponent {
  // BR component getId() e.g. "r12_r6"
  id: string;
  // BR component getName() e.g. "allocations"
  name: string;
  isAsync: boolean;
  ready: boolean;
  sectionId?: string;
  startTime?: number;
  readyTime?: number;
  duration?: number;
}

/**
 * This service monitors components that render async e.g. they wait until data is loaded before rendering
 * It's used to manage things like initial #fragment scrolling
 * It should be injected into each async component, which will then call methods when status changes
 */
@Injectable({ providedIn: 'root' })
export class AsyncContentManagerService implements OnDestroy {
  private unsubscribe$: Subject<void> = new Subject<void>();

  // by default, scrolling will be delayed until PAGE is fully loaded
  // some pages (i.e. Product Detail) can scroll as soon as the section is ready, i.e. before the full page is ready
  private isSectionLoading = false;

  // page sections to monitor rendering progress for
  // sections can't be scrolled to until they are marked ready
  private pageSections: AsyncPageSection[] = [];

  // stream containing an array representing the BR components currently on the page
  private pageComponents$: ReplaySubject<AsyncComponent[]> = new ReplaySubject<
    AsyncComponent[]
  >(1);

  // static copy of components
  private pageComponents: AsyncComponent[] = [];

  // observable emits false when images are loading, and true when loaded (or error loading)
  // see this explanation of logic: https://stackoverflow.com/a/54543327
  private imagesReadyGate$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    true
  );

  constructor(
    @Inject(DOCUMENT) private documentRef: Document,
    private productDetailLoadingService: ProductDetailLoadingService
  ) {
    this.pageComponents$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((cmps: AsyncComponent[]): void => {
        logger.debug('pageComponents$', cmps);
        this.pageComponents = cmps;
      });
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * This is used to register sticky tab sections on page, and relate any normal tab ids to particular sections
   * @param sections sections of the page. Usually this corresponds to sticky tabs if they exist
   * @param isSectionLoading Product page only needs individual section ready to scroll to it
   * All other pages wait for full page to finish rendering before scrolling to section
   */
  public registerSections(
    sections: AsyncPageSection[],
    isSectionLoading = false
  ): void {
    logger.debug('sections registered', sections, isSectionLoading);
    // this resets the product page sections loading status object
    this.productDetailLoadingService.resetSections();
    this.isSectionLoading = isSectionLoading;
    this.pageSections = sections;
  }

  /**
   * Matches a #fragment to the page (sticky tab) section it's anchor belongs to
   * This is used to match anchors within a section (e.g. normal tabs or accordion panels)
   * @param id #fragment of anchor to find
   * @returns matching AsyncPageSection or undefined if not found
   */
  public getSectionFromId(id: string): AsyncPageSection {
    return this.pageSections.find(
      (section: AsyncPageSection): boolean =>
        section.id === id || section.childIds?.includes(id)
    );
  }

  /**
   * components should call this when they are created
   */
  public registerComponents(page: Page): void {
    logger.debug('components registered', page);
    const rootCmp: BrComponent = page.getComponent();
    const pageComponents: AsyncComponent[] = this.mapComponent(rootCmp);
    logger.debug('mapped components', pageComponents);
    this.pageComponents$.next(pageComponents);
  }

  /**
   * components should call this when they have rendered on the page
   */
  public update(
    id: string, // component id or name to match on
    ready: boolean,
    isAsync?: boolean,
    sectionId?: string
  ): void {
    logger.debug('component update', id, ready, isAsync, sectionId);

    // try to match on id first
    let cmp: AsyncComponent = this.pageComponents.find(
      (pageCmp: AsyncComponent): boolean => pageCmp.id === id
    );

    // failing that, try to match on name
    if (!cmp) {
      cmp = this.pageComponents.find(
        (pageCmp: AsyncComponent): boolean => pageCmp.name === id
      );
    }

    if (cmp) {
      cmp.ready = ready;
      if (isAsync) {
        cmp.isAsync = isAsync;
      }
      if (sectionId) {
        cmp.sectionId = sectionId;
      }
      if (ready) {
        cmp.readyTime = Date.now();
        cmp.duration = cmp.readyTime - cmp.startTime;
      }
      this.pageComponents$.next(this.pageComponents);
    }
  }

  /**
   * Emits when the anchor is ready to be scrolled to
   * For most pages, this will be when all images and components have fully rendered
   * For Product page, this will be when the anchor's parent section has rendered
   * @param anchor the anchor we want to check. It may be the top of a section, or an element within
   * @param minDelay minimum delay in ms before first emit. This gives extra time for DOM to render
   * @returns emits true when anchor ready. emits false if anchor not found in DOM
   */
  public getAnchorReady$(anchor: string, minDelay = 10): Observable<boolean> {
    logger.debug('getAnchorReady$()', anchor, minDelay);

    // run a check for any images that are still loading
    this.checkImages();

    if (this.isSectionLoading) {
      // this will emit as soon as the target section is ready
      const matchedSection: AsyncPageSection = this.getSectionFromId(anchor);
      logger.debug('matched section', matchedSection);
      if (matchedSection) {
        return this.isSectionReady$(matchedSection);
      }
      // section not found
      // just return once images loaded
      // if id still exists in DOM, hope it scrolls ok
      return this.getImagesReady$();
    } else {
      // this will only emit once the full page is ready
      return this.isPageReady$();
    }
  }

  // emits true when images and matching section on page loaded
  private isSectionReady$(section: AsyncPageSection): Observable<boolean> {
    return combineLatest([
      this.productDetailLoadingService.isSectionReady$(section.id),
      this.getImagesReady$(),
    ]).pipe(
      map(
        ([sectionReady, imagesReady]: [boolean, boolean]): boolean =>
          sectionReady && imagesReady
      ),
      tap((ready: boolean): void =>
        logger.debug('isSectionReady$() after', section, ready)
      ),
      filter((ready: boolean): boolean => ready)
    );
  }

  // emits true when images and all async components on page loaded
  public isPageReady$(): Observable<boolean> {
    return combineLatest([
      this.getComponentsReady$(),
      this.getImagesReady$(),
    ]).pipe(
      map(
        ([componentsReady, imagesReady]: [boolean, boolean]): boolean =>
          componentsReady && imagesReady
      ),
      tap((ready: boolean): void => logger.debug('isPageReady$()', ready))
    );
  }

  // emits the loading stats of all async components on the page
  public getPageComponentsStatus$(): Observable<AsyncComponent[]> {
    return this.pageComponents$.pipe(
      map((pageComponents: AsyncComponent[]): AsyncComponent[] =>
        pageComponents.sort(
          (a: AsyncComponent, b: AsyncComponent): number =>
            Number(a.ready) - Number(b.ready)
        )
      )
    );
  }

  // emits when all async components have finished loading
  public getComponentsReady$(): Observable<boolean> {
    return this.pageComponents$.pipe(
      map((pageComponents: AsyncComponent[]): boolean =>
        pageComponents.every((cmp: AsyncComponent): boolean => cmp.ready)
      ),
      tap((ready: boolean): void =>
        logger.debug('getComponentsReady$()', ready)
      )
    );
  }

  // emits when all images have finshed loading
  public getImagesReady$(): Observable<boolean> {
    return this.imagesReadyGate$.pipe(
      tap((ready: boolean): void => logger.debug('getImagesReady$()', ready))
    );
  }

  /*
    this kicks off a process to look for all loading images
    once all images have completed (loaded or failed), the images ready status gate is opened
  */
  public checkImages(): void {
    const images: HTMLImageElement[] = Array.from(
      this.documentRef.querySelectorAll<HTMLImageElement>('img')
    );
    const loadingImages: HTMLImageElement[] = images.filter(
      (img: HTMLImageElement): boolean => !img.complete
    );
    const imagesLoaded: boolean = loadingImages.length <= 0;
    logger.debug(
      'checkImages(). images loaded:',
      imagesLoaded,
      images.length,
      loadingImages.length
    );
    this.imagesReadyGate$.next(imagesLoaded); // not ready if 1 or more incomplete images

    // this listens to 'load' and 'error' events on all loading images
    // forkJoin means subscribe() won't run until ALL images are complete
    forkJoin(
      loadingImages.map((img: HTMLImageElement) =>
        merge(fromEvent(img, 'load'), fromEvent(img, 'error')).pipe(first())
      )
    )
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((result: Event[]) => {
        logger.debug('combined image loading result', result);
        this.imagesReadyGate$.next(true); // images ready as all complete
      });
  }

  // used to map a nested BR component array into a flattened array of Async Components
  private mapComponent = (cmp: BrComponent): AsyncComponent[] => {
    let name: string = cmp.getName();
    if (name === 'interactive-content' || name === 'interactive-content2') {
      // if interactive component, work out name from componentType
      name = cmp.getParameters()?.componentType || name;
    }
    const isAsync: boolean = !!ASYNC_COMPONENTS.find(
      (cmpName: string): boolean => name?.startsWith(cmpName)
    );
    const asyncCmp: AsyncComponent = {
      id: cmp.getId(),
      name,
      isAsync,
      ready: !isAsync, // assume sync components are ready by default
      startTime: Date.now(),
    };
    const children: AsyncComponent[] =
      cmp.getChildren().flatMap(this.mapComponent) || [];
    return [asyncCmp].concat(children);
  };
}
