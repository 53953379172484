import { Pipe, PipeTransform } from '@angular/core';
import { getLeavingSiteClass } from '../../marketing/marketing-utils';

@Pipe({
  name: 'leavingSite',
})
export class LeavingSitePipe implements PipeTransform {
  transform(menuItem?: any): string {
    return getLeavingSiteClass(menuItem);
  }
}
