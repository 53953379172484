export interface PortfolioDTO {
  assetallocation?: PortfolioAssetAllocationDTO[];
  countryallocation?: PortfolioGeographicAllocationDTO[];
  couponallocation?: PortfolioCouponAllocationDTO[];
  currencyallocation?: PortfolioCurrencyAllocationDTO[];
  dailyholdings?: (PortfolioHoldingDTO | null)[] | null;
  fullholdings?: (PortfolioHoldingDTO | null)[] | null;
  hedgeallocation?: PortfolioHedgeAllocationDTO[];
  hedgemanagerallocation?: PortfolioHedgeManagerAllocationDTO[];
  impactpillar?: PortfolioImpactPillarDTO[];
  marketcapallocation?: PortfolioMarketCapitalisationDTO[];
  portfoliochars?: PortfolioCharsDTO[];
  portfoliocharshistory?: PortfolioCharsHistoryDTO[];
  portfolioallocationfi?: PortfolioAllocationFIDTO[];
  qualityallocation?: PortfolioQualityAllocationDTO[];
  riskreturnanalysis?: PortfolioRiskReturnAnalysisDTO[];
  sectorallocation?: PortfolioSectorAllocationDTO[];
  securityexposure?: PortfolioSecurityExposureDTO[];
  topholdings?: PortfolioTopHoldingsDTO[];
  toptenholdingsmoneyfunds?: PortfolioTopTenHoldingMoneyFundsDTO[];
  impactfocus?: ImpactFocusDTO[];
}

// common properties for all Portfolio DTO types
interface PortfolioBaseDTO {
  fundid?: string | null;
  cntrycode?: string | null;
  langcode?: string | null;
  asofdate?: string | null;
  asofdatestd?: string | null;
  frequency?: string | null;
}

export interface PortfolioCharsDTO extends PortfolioBaseDTO {
  bmname?: string | null;
  bmno?: string | null;
  currcode?: string | null;
  elemsysno?: string | null;
  elemname?: string | null;
  elemdispname?: string | null;
  elemtransname?: string | null;
  elemvaluestd?: string | null;
  elemvaluelocal?: string | null;
  elemvaluestdbm?: string | null;
  elemvaluelocalbm?: string | null;
  hascountry?: string | null;
  hascurrency?: string | null;
  hasderivatives?: string | null;
  hasquality?: string | null;
  hassector?: string | null;
}

export interface PortfolioCharsHistoryDTO extends PortfolioBaseDTO {
  asofdate?: string | null;
  asofdatestd?: string | null;
  elemsysno?: string | null;
  elemname?: string | null;
  elemdispname?: string | null;
  elemtransname?: string | null;
  elemvaluestd?: string | null;
  elemvaluelocal?: string | null;
  frequency?: string | null;
}

// properties common to portfolio allocations
interface PortfolioAllocationBaseDTO extends PortfolioBaseDTO {
  allocflag?: string | null;
  allocheadinglocal?: string | null;
  allocheadingstd?: string | null;
  brkdwnpct?: string | null;
  brkdwnpctstd?: string | null;
  calcbasislocal?: string | null;
  calcbasisstd?: string | null;
  calcname?: string | null;
  calcnamestd?: string | null;
  equal100?: string | null;
  // optional fimes flags
  hascurrency?: string | null;
  hasderivatives?: string | null;
  // used to set order
  rank?: string | null;
}

// properties common to nested allocations
interface PortfolioAllocationNestedDTO extends PortfolioAllocationBaseDTO {
  parent?: string | null;
  rolluplevel?: string | null;
}

export type PortfolioAllocationDTO =
  | PortfolioAllocationFIDTO
  | PortfolioAssetAllocationDTO
  | PortfolioCouponAllocationDTO
  | PortfolioCurrencyAllocationDTO
  | PortfolioGeographicAllocationDTO
  | PortfolioHedgeAllocationDTO
  | PortfolioImpactPillarDTO
  | PortfolioMarketCapitalisationDTO
  | PortfolioQualityAllocationDTO
  | PortfolioRiskReturnAnalysisDTO
  | PortfolioSectorAllocationDTO
  | PortfolioSecurityExposureDTO
  | PortfolioTopHoldingsDTO;

// ======================================

export interface PortfolioAllocationFIDTO extends PortfolioAllocationBaseDTO {
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  calcbasislocal?: string | null;
  calcname?: string | null;
  rangedesc?: string | null;
  bmname?: string | null;
}

export interface PortfolioAssetAllocationDTO
  extends PortfolioAllocationBaseDTO {
  assetalloccatg?: string | null;
  assetalloccatgstd?: string | null;
  calctypelocal?: string | null;
  calctypestd?: string | null;
  fulldatarcvdflg?: string | null;
  vldnsuccessfulflg?: string | null;
}

export interface PortfolioCouponAllocationDTO
  extends PortfolioAllocationBaseDTO {
  brkdwntype?: string | null;
  calctypelocal?: string | null;
  calctypestd?: string | null;
  rangefrom?: string | null;
  rangeto?: string | null;
  rangedesc?: string | null;
  rolluptype?: string | null;
}

export interface PortfolioImpactPillarDTO extends PortfolioAllocationBaseDTO {
  assetalloccatg?: string | null;
  assetalloccatgstd?: string | null;
  calctypelocal?: string | null;
  calctypestd?: string | null;
  fulldatarcvdflg?: string | null;
  vldnsuccessfulflg?: string | null;
}

export interface PortfolioCurrencyAllocationDTO
  extends PortfolioAllocationNestedDTO {
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  brkdwnname?: string | null;
  brkdwncode?: string | null;
  elemtype?: string | null;
  rolluptype?: string | null;
  bmname?: string | null;
}

export interface PortfolioGeographicAllocationDTO
  extends PortfolioAllocationNestedDTO {
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  brkdwncode?: string | null;
  brkdwnname?: string | null;
  elemtype?: string | null;
  hascountry?: string | null;
  bmname?: string | null;
}

export interface PortfolioHedgeAllocationDTO extends PortfolioBaseDTO {
  contribytd?: string | null;
  contrib1mo?: string | null;
  contrib3mo?: string | null;
  contrib1yr?: string | null;
  elemtype?: string | null;
  elemtypestd?: string | null;
  rank?: string | null;
  strtgyallocbrkdwnname?: string | null;
  strtgyallocbrkdwnnamestd?: string | null;
  strtgyallocbrkdwnpct?: string | null;
  tgtallocbrkdwnmin?: string | null;
  tgtallocbrkdwnmax?: string | null;
  tgtallocbrkdwnrange?: string | null;
}

export interface PortfolioHedgeManagerAllocationDTO extends PortfolioBaseDTO {
  mngrroster?: string | null;
  mngrrosterstrtgy?: string | null;
  mngrrosterstrtgystd?: string | null;
  allocation?: string | null;
  allocationstd?: string | null;
  forceorder?: string | null;
}

export interface PortfolioHoldingDTO extends PortfolioBaseDTO {
  secticker?: string | null;
  isinsecnbr?: string | null;
  secname?: string | null;
  indstrycode?: string | null;
  sctrsysno?: string | null;
  sctrname?: string | null;
  sctrnamestd?: string | null;
  sctrlvlnbr?: string | null;
  pctofnetassets?: string | null;
  pctofnetassetsstd?: string | null;
  mktvalue?: string | null;
  notionalmktvalue?: string | null;
  quantityshrpar?: string | null;
  secexpdate?: string | null;
  assetclasscatg?: string | null;
  mktcurr?: string | null;
  sedolnbr?: string | null;
  secnbr?: string | null;
  cusipnbr?: string | null;
  origcouponrate?: string | null;
  contracts?: string | null;
  unrealizedgainlossbase?: string | null;
  finalmaturitydate?: string | null;
  investmentcategory?: string | null;
}

export interface PortfolioMarketCapitalisationDTO
  extends PortfolioAllocationBaseDTO {
  alloctype?: string | null;
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  brkdwntype?: string | null;
  calctypelocal?: string | null;
  calctypestd?: string | null;
  currcode?: string | null;
  rangedesc?: string | null;
  rangefrom?: string | null;
  rangeto?: string | null;
  bmname?: string | null;
}

export interface PortfolioQualityAllocationDTO
  extends PortfolioAllocationBaseDTO {
  allocheadinglocal?: string | null;
  bmqalcpct?: string | null;
  bmqalcpctstd?: string | null;
  calcbasislocal?: string | null;
  calcname?: string | null;
  cashinclude?: string | null;
  qalcbrkwnvalue?: string | null;
  qalcpct?: string | null;
  qalcpctstd?: string | null;
  bmname?: string | null;
}

export interface PortfolioRiskReturnAnalysisDTO
  extends PortfolioAllocationNestedDTO {
  perfcurrency?: string | null;
  calctype?: string | null;
  rolluplevel?: string | null;
  startdate?: string | null;
  enddate?: string | null;
  factorl1?: string | null;
  factorl2?: string | null;
  factorl3?: string | null;
  factorl4?: string | null;
  portbeinwgt?: string | null;
  portavgwgt?: string | null;
  portendwgt?: string | null;
  portcontrib?: string | null;
  counter?: string | null;
  contribriskhist?: string | null;
  contribriskforecast?: string | null;
  fperiod?: string | null;
}

export interface PortfolioSectorAllocationDTO
  extends PortfolioAllocationNestedDTO {
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  bmnbr?: string | null;
  brkdwntype?: string | null;
  brkdwnpctlong?: string | null;
  brkdwnpctshort?: string | null;
  elemtype?: string | null;
  hassector?: string | null;
  parentsectorname?: string | null;
  primflag?: string | null;
  sectorlevelnbr?: string | null;
  sectorlevelname?: string | null;
  sectorname?: string | null;
  sectornamestd?: string | null;
  bmname?: string | null;
}

export interface PortfolioSecurityExposureDTO extends PortfolioBaseDTO {
  brkdwnpct: string | null;
  brkdwnpctstd: string | null;
  exposuretype?: string | null;
  exposuretypestd?: string | null;
  exposurecategory?: string | null;
  exposurecategorystd?: string | null;
  primflag?: string | null;
  rank: string | null;
}

export interface PortfolioTopHoldingsDTO extends PortfolioAllocationBaseDTO {
  aggpct?: string | null;
  assetclassname?: string | null;
  calctypelocal?: string | null;
  calctypestd?: string | null;
  geocode?: string | null;
  geoname?: string | null;
  hldngname?: string | null;
  hldnglvl?: string | null;
  issuername?: string | null;
  sectorname?: string | null;
  totalbrkdwnpctstd?: string | null;
}

export interface PortfolioTopTenHoldingMoneyFundsDTO extends PortfolioBaseDTO {
  securityname?: string | null;
  cntryofissue?: string | null;
  hldngvalue?: string | null;
  hldngvaluestd?: string | null;
  maturitydate?: string | null;
  maturitydatestd?: string | null;
  assettype?: string | null;
  assettypestd?: string | null;
}
export interface ImpactFocusDTO extends PortfolioBaseDTO {
  bmnbr?: string | null;
  calcname?: string | null;
  calcnamestd?: string | null;
  elemtype?: string | null;
  sectorname?: string | null;
  sectornamestd?: string | null;
  brkdwntype?: string | null;
  brkdwnpct?: string | null;
  brkdwnpctstd?: string | null;
  bmbrkdwnpct?: string | null;
  bmbrkdwnpctstd?: string | null;
  sectorlevelnbr?: string | null;
  sectorlevelname?: string | null;
  rolluplevel?: string | null;
  rank?: string | null;
  primflag?: string | null;
  calcbasisstd?: string | null;
  calcbasislocal?: string | null;
  allocheadingstd?: string | null;
  allocheadinglocal?: string | null;
  allocflag?: string | null;
  hasderivatives?: string | null;
  equal100?: string | null;
  hassector?: string | null;
  parentsectorname?: string | null;
  brkdwnpctlong?: string | null;
  brkdwnpctshort?: string | null;
  bmname?: string | null;
}
