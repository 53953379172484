import { Injectable } from '@angular/core';
import { LiteratureDocument } from '@types';

@Injectable({ providedIn: 'root' })
export class LiteratureRulesHelperService {
  /**
   *
   * @param data literature document
   * set the metaData based on rules (metadata info)
   * allow show/hide different alert msg on listing/detail/fundDocuments/cart modal/cart page
   * global used function to control some rules
   */
  public checkLiteratureRules(data: LiteratureDocument): void {
    if (!data.clientUse || data.isOrdItself === 'N' || data.isInv === 'N') {
      data.hardCopyNotAvailable = true;
    }
    if (data.mandatoryMaterial?.length > 0) {
      data.isMandatoryMaterial = true;
    }
    if (data.restrictedDealer?.length > 0) {
      data.litDealerUse = true;
    }
  }
}
