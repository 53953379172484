import {
  FlexboxModule,
  GridModule,
  ImageModule,
  PaddingModule,
  SpacingModule,
  TitleModule,
} from '@frk/eds-components';

import { BrSdkModule } from '@bloomreach/ng-sdk';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ImageBlockComponent } from './image-block.component';

@NgModule({
  declarations: [ImageBlockComponent],
  imports: [
    BrSdkModule,
    CommonModule,
    FlexboxModule,
    ImageModule,
    PaddingModule,
    SpacingModule,
    TitleModule,
    GridModule,
  ],
  exports: [ImageBlockComponent],
  entryComponents: [ImageBlockComponent],
})

/**
 * Image Block Module
 */
export class ImageBlockModule {}
