import { Pipe, PipeTransform } from '@angular/core';
import { ProfileService } from '@services/profile.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';

@Pipe({
  name: 'ftIsSignInRequired',
})
export class IsSignInRequiredPipe implements PipeTransform {
  constructor(private profileService: ProfileService) {}

  /**
   * Check config setting as well as logged-in status through profile service to return final flag value.
   * @param isSignInEnabledInConfig config received from BR to enable the sign-in functionality
   * @returns flag value as observable to enable sign in or not
   */
  transform(isSignInEnabledInConfig: boolean): Observable<boolean> {
    return this.profileService
      .isLoggedIn$()
      .pipe(
        map(
          (isLoggedIn: boolean): boolean =>
            isSignInEnabledInConfig && !isLoggedIn
        )
      );
  }
}
