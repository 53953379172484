import { Type } from '@angular/core';
import { ConfigurationId } from '@types';
import { FundPerformanceAnnualizedComponent } from './fund-performance-annualized/fund-performance-annualized.component';
import { FundPerformanceCalendarYearComponent } from './fund-performance-calendar-year/fund-performance-calendar-year.component';
import { FundPerformanceCumulativeComponent } from './fund-performance-cumulative/fund-performance-cumulative.component';
import { FundPerformanceDiscreteReturnsComponent } from './fund-performance-discrete-returns/fund-performance-discrete-returns.component';
import { FundPerformanceDisplayComponent } from './fund-performance-display-component.interface';
import { RiskMeasuresComponent } from './risk-measures/risk-measures.component';
import { RiskReturnProfileComponent } from './risk-return-profile/risk-return-profile.component';
import { FundPerformanceMonthlyTotalReturnsComponent } from './fund-performance-monthly-total-returns/fund-performance-monthly-total-returns.component';

export class FundPerformanceComponentConfig {
  static readonly FUND_PERFORMANCE_ANNUALIZED = new FundPerformanceComponentConfig(
    'Annualized',
    FundPerformanceAnnualizedComponent,
    ConfigurationId.GW,
    'annualized'
  );
  static readonly FUND_PERFORMANCE_CUMULATIVE = new FundPerformanceComponentConfig(
    'Cumulative',
    FundPerformanceCumulativeComponent,
    ConfigurationId.GW,
    'cumulative'
  );
  static readonly FUND_PERFORMANCE_DISCRETE_RETURNS = new FundPerformanceComponentConfig(
    'Discrete',
    FundPerformanceDiscreteReturnsComponent,
    ConfigurationId.GW,
    'discrete'
  );
  static readonly FUND_PERFORMANCE_CALENDAR_YEAR = new FundPerformanceComponentConfig(
    'Calendar Year',
    FundPerformanceCalendarYearComponent,
    ConfigurationId.GW,
    'calendar-year'
  );
  static readonly RISK_MEASURES = new FundPerformanceComponentConfig(
    'Risk Statistics',
    RiskMeasuresComponent,
    ConfigurationId.GW,
    'risk-measures'
  );
  static readonly RISK_RETURN_PROFILE = new FundPerformanceComponentConfig(
    'Risk Return Profile',
    RiskReturnProfileComponent,
    ConfigurationId.GW,
    'risk-return-profile'
  );
  static readonly MONTHLY_TOTAL_RETURNS = new FundPerformanceComponentConfig(
    'Monthly Total Returns',
    FundPerformanceMonthlyTotalReturnsComponent,
    ConfigurationId.GW,
    'monthly-total-returns'
  );
  static allConfigs: FundPerformanceComponentConfig[] = [
    FundPerformanceComponentConfig.FUND_PERFORMANCE_ANNUALIZED,
    FundPerformanceComponentConfig.FUND_PERFORMANCE_CUMULATIVE,
    FundPerformanceComponentConfig.FUND_PERFORMANCE_DISCRETE_RETURNS,
    FundPerformanceComponentConfig.FUND_PERFORMANCE_CALENDAR_YEAR,
    FundPerformanceComponentConfig.RISK_MEASURES,
    FundPerformanceComponentConfig.RISK_RETURN_PROFILE,
    FundPerformanceComponentConfig.MONTHLY_TOTAL_RETURNS,
  ];

  // returns matching config or undefined
  static getConfig(
    key: string,
    configurationName?: ConfigurationId
  ): FundPerformanceComponentConfig {
    return this.allConfigs.find(
      (config: FundPerformanceComponentConfig): boolean =>
        configurationName
          ? config.key === key && config.configurationName === configurationName // if config is GW or ETF specific,make sure it matches
          : config.key === key
    ); // otherwise just match on key
  }

  private constructor(
    private readonly key: string,
    public readonly component: Type<FundPerformanceDisplayComponent>,
    public readonly configurationName?: ConfigurationId,
    public readonly fragmentId?: string
  ) {}
}
