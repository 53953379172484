import { Injectable } from '@angular/core';
import { AppStateService } from '@services/app-state.service';
import { SiteConfigService } from '@services/site-config.service';
import { GlobalConfigService } from '@services/global-config-service';
import { map, switchMap } from 'rxjs/operators';
import { CaveatCollection, FundId, FundShareClassId } from '@types';
import { from, Observable } from 'rxjs';
import { TranslateService } from '@shared/translate/translate.service';
import { initialize, Page } from '@bloomreach/spa-sdk';

@Injectable({
  providedIn: 'root',
})
export class CaveatDataService {
  constructor(
    private appState: AppStateService,
    private globalConfig: GlobalConfigService,
    private siteConfig: SiteConfigService,
    private translateService: TranslateService
  ) {}

  private static mapAlert(
    alertMap: object = {},
    fund: FundId | FundShareClassId,
    alert,
    ppssMap: {}
  ) {
    if (alertMap[fund] === undefined) {
      alertMap[fund] = [alert];
    } else {
      alertMap[fund].push(alert);
    }
    if (alert.ppssMessage && !ppssMap[fund]) {
      ppssMap[fund] = alert;
    }
  }

  getData$(): Observable<CaveatCollection> {
    return this.globalConfig.getGlobalConfigSubject$().pipe(
      switchMap(() => {
        const lang = this.siteConfig.common?.taxonomyLanguage;
        return from(
          initialize({
            ...this.appState.getBrConfiguration(),
            path: `/caveats?language=${lang}`,
          })
        ).pipe(
          map((page) => this.applyDynamicLabels(page)),
          map(this.processAlerts)
        );
      })
    );
  }

  private processAlerts(page: Page): CaveatCollection {
    const caveats = page.getComponent().getModels().caveats;
    if (caveats) {
      const alertMap = {};
      const ppssMap = {};
      // map fund and share classes to alerts
      caveats.alerts.forEach((alert) => {
        alert.fl.forEach((fund) => {
          CaveatDataService.mapAlert(alertMap, fund, alert, ppssMap);
        });
        alert.fscl.forEach((fundShareClass) => {
          CaveatDataService.mapAlert(alertMap, fundShareClass, alert, ppssMap);
        });
      });
      caveats.alerts = {
        alertMap,
        ppssMap,
      };
      return caveats;
    }
    return {
      footnotes: [],
      legals: [],
      proximals: [],
      alerts: { alertMap: {}, ppssMap: {} },
    };
  }

  private applyDynamicLabels(page: Page): Page {
    // Loop through caveats type
    const caveats = page.getComponent().getModels().caveats;
    const labelRegex = /\$LABEL(\(|\[)(\w+(?:.\w+)*)(\)|\])/g;
    for (const caveatsKey in caveats) {
      // Excluding 'alerts' to avoid unnecessary processing
      if (caveatsKey !== 'alerts') {
        // loop through each caveat
        caveats[caveatsKey].forEach((caveat) => {
          const token: RegExpMatchArray = caveat.content?.match(labelRegex);
          if (token) {
            caveat.content = caveat.content?.replace(
              labelRegex,
              (m, g1, g2) => {
                return this.translateService.instant(g2);
              }
            );
          }
        });
      }
    }
    return page;
  }
}
