<eds-simple-modal
  *ngIf="(isVisible$ | async) === true"
  [modalId]="'roleSelectorModal'"
  [showModal]="true"
  [showClose]="isSegmentSet$ | async"
  [disableCloseOnBackground]="true"
  (onClose)="onModalClose()"
  edsTrapFocus
  [config]="simpleModalConfig"
  modalAriaLabelledby="roleSelectorTitle"
  modalAriaDescribedby="roleSelectorDescription"
>
  <div modal-body>
    <eds-banner-role-selector-variation-two
      *ngIf="(isVisible$ | async) === true"
      [title]="title"
      [preTitle]="preTitle"
      [summary]="summary"
      [languageOptions]="languageOptions"
      [roles]="roles"
      [isEmbeddedInOtherComponent]="true"
      [imageSrc]="imageSrc"
      [imageTransparentBg]="imageTransparentBg"
      [transparencyRatio]="transparencyRatio"
      (roleClicked)="roleClicked($event)"
    >
    </eds-banner-role-selector-variation-two>
  </div>
</eds-simple-modal>
