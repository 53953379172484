import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { isElementVisible, fadeIn, fadeOut } from '@utils/dom-utils';

@Component({
  selector: 'ft-visual-hint',
  templateUrl: './visual-hint.component.html',
  styleUrls: ['./visual-hint.component.scss'],
})
export class VisualHintComponent implements OnDestroy, AfterViewInit {
  isDisplayed = false;
  isDisabled = false;
  shouldDisplay = false;
  boundDisplayHint = null;
  visualHint: Element;
  @Input() parentContainer: Element;
  boundFadeOutHint = null;

  constructor(private element: ElementRef, private renderer: Renderer2) {}

  /**
   * Called when the state changes, see if hint should be displayed
   */
  // mapState(newState) {
  //   this.displayHint();
  // }

  shouldBeDisplayed(): boolean {
    if (!this.parentContainer) {
      return false;
    }
    const isParentVisible = isElementVisible(this.parentContainer);
    const isParentCrushed =
      this.parentContainer[0].scrollWidth > this.parentContainer[0].clientWidth;
    return isParentVisible && isParentCrushed;
  }

  /**
   * Checks if parent container is crushed, if so displays visual hint
   */
  displayHint() {
    if (this.isDisabled) {
      return;
    }
    this.shouldDisplay = this.shouldBeDisplayed();
    if (this.shouldDisplay) {
      this.updateVisualHintPosition();
    }

    if (this.isDisplayed !== this.shouldDisplay) {
      const isElementInViewport = isElementVisible(
        this.element.nativeElement.parentElement
      );
      if (this.shouldDisplay && isElementInViewport) {
        fadeIn(this.visualHint);
      } else {
        fadeOut(this.visualHint);
      }
      this.isDisplayed = this.shouldDisplay;
    }
  }

  /**
   * Checks if parent container is crushed, if so displays visual hint
   */
  fadeOutHint() {
    if (this.isDisabled) {
      return;
    }
    this.shouldDisplay = this.shouldBeDisplayed();
    if (this.shouldDisplay) {
      this.updateVisualHintPosition();
      this.isDisplayed = false;
      this.isDisabled = true;
      fadeOut(this.visualHint);
    }
  }

  updateVisualHintPosition() {
    const visualHintHeight = this.visualHint.clientHeight;
    const isElementInViewport = isElementVisible(
      this.element.nativeElement.parentElement
    );
    const isElementIntersecting = this.elementsIntersecting(
      this.element.nativeElement,
      this.element.nativeElement.parentElement
    );
    if (isElementInViewport) {
      const rect1 = this.element.nativeElement.parentElement.getBoundingClientRect();
      let position;
      const topPosition = rect1.top;
      const bottomPosition = rect1.bottom - visualHintHeight;
      const isBelow = visualHintHeight > bottomPosition;
      if (!isElementIntersecting) {
        position = topPosition;
      } else if (isBelow) {
        position = bottomPosition;
      } else {
        position = visualHintHeight;
      }
      this.renderer.setStyle(this.visualHint, 'display', 'block');
      this.renderer.setStyle(this.visualHint, 'position', 'fixed');
      this.renderer.setStyle(this.visualHint, 'top', position);
    } else {
      this.renderer.setStyle(this.visualHint, 'display', 'none');
    }
  }

  elementsIntersecting(el1: Element, el2: Element): boolean {
    const rect1 = el1.getBoundingClientRect();
    const rect2 = el2.getBoundingClientRect();
    const vhh = this.visualHint.clientHeight;
    const overlap =
      rect1.bottom - vhh < el2.scrollTop || el1.scrollTop > rect2.bottom - vhh;

    return overlap;
  }

  ngAfterViewInit() {
    this.isDisplayed = false;
    this.isDisabled = false;
    this.visualHint = this.element.nativeElement.querySelector(
      '.visual-hint-container'
    );

    if (this.shouldBeDisplayed()) {
      this.shouldDisplay = true;
      this.isDisplayed = true;
    }
    if (this.parentContainer) {
      // TODO: replace with throttled scroll Obs$
      this.parentContainer.addEventListener(
        'click scroll',
        this.boundFadeOutHint
      );
    }
  }

  ngOnDestroy() {
    if (this.parentContainer) {
      // TODO: replace with throttled scroll Obs$
      this.parentContainer.removeEventListener(
        'click scroll',
        this.boundFadeOutHint
      );
    }
  }
}
